import React from 'react';
import TabbedPageWithRouting from 'components/TabbedPageWithRouting';
import Loader from 'components/Loader';
import { APP_PATHS } from 'utils/systemConsts';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import CdScan from './CdScan';
import Plugins from './Plugins';
import Images, { IMAGE_PAGE_PATH } from './Images';
import CodeZips, { CODE_ZIPS_PAGE_PATH } from './CodeZips';
import TrustedSigners, { TRUESTED_SIGNERS_PAGE_PATH } from './TrustedSigners';

import './ci-cd.scss';

const CI_CD_PATH = APP_PATHS.CI_CD.path;

const CiCd = () => {
    const {loadingDisplay, showServerlessSecurity} = useDisplayConfigState();
    
    if (loadingDisplay) {
        return <Loader />;
    }

    return (
        <TabbedPageWithRouting
            className="cicd-page"
            redirectFrom={CI_CD_PATH}
            redirectTo={IMAGE_PAGE_PATH}
            items={[
                {to: IMAGE_PAGE_PATH, title: "Images", component: Images},
                ...(!showServerlessSecurity ? [] : [{to: CODE_ZIPS_PAGE_PATH, title: "Code zip", component: CodeZips}]),
                {to: `${CI_CD_PATH}/cdscan`, title: "CD", component: CdScan},
                {to: `${CI_CD_PATH}/plugins`, title: "Plugins", component: Plugins},
                {to: TRUESTED_SIGNERS_PAGE_PATH, title: "Trusted Signers", component: TrustedSigners},
            ]}
        />
    );
}

export default CiCd;