import React, { useEffect } from 'react';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { usePrevious } from 'hooks';
import { useFormikContext, validators, RadioDescriptionField } from 'components/Form';
import { getRecommendedRuleNameById } from '../utils';
import { CUSTOM_RULE_ID, EMPTY_USER_ITEM, EMPTY_RESOURCE_ITEM } from './utils';

const FormSelectRule = ({recommendedRules, isRecommendedRuleClusterScopeOnly, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();

    const {recommendedRuleId} = values;
    const prevRecommendedRuleId = usePrevious(recommendedRuleId);
    
    const typeItems = recommendedRules.map(({id, name, description}) => ({value: id, label: name, description}));
    typeItems.unshift({value: CUSTOM_RULE_ID, label: "Custom", description: "Create a custom rule for Kubernetes API access"});

    useEffect(() => {
        if (prevRecommendedRuleId === recommendedRuleId || !prevRecommendedRuleId) {
            return;
        }

        const isCustomRule = recommendedRuleId === CUSTOM_RULE_ID;
        const isClusterScopeOnly = isRecommendedRuleClusterScopeOnly(recommendedRuleId);

        setFieldValue("name", isCustomRule ? "" : getRecommendedRuleNameById(recommendedRules, recommendedRuleId));
        setFieldValue("action", RULE_ACTIONS.DETECT);
        setFieldValue("users", [EMPTY_USER_ITEM]);
        setFieldValue("excludedUsers", [EMPTY_USER_ITEM]);
        setFieldValue("resources", [EMPTY_RESOURCE_ITEM]);
        setFieldValue("apiActions", []);
        setFieldValue("isClusterScopeOnly", isClusterScopeOnly);
    }, [prevRecommendedRuleId, recommendedRuleId, setFieldValue, recommendedRules, isRecommendedRuleClusterScopeOnly]);

    return (
        <React.Fragment>
            <RadioDescriptionField
                name="recommendedRuleId"
                items={typeItems}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
        </React.Fragment>
    )
}

export default FormSelectRule;