import React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import { TooltipWrapper } from 'components/Tooltip';
import Icon from 'components/Icon';

import './mini-dashboard-wrapper.scss';

export const DashboardItem = ({id, count, icon, tooltipText, color, iconClassName}) => (
    <TooltipWrapper id={`mini-dashboard-item-${id}`} text={tooltipText}>
        <div className="mini-dashboard-item">
            <Icon
                name={icon}
                small={true}
                style={{opacity: count === 0 ? 0.4 : 1, ...(!!color? {color} : {})}}
                className={iconClassName}
            />
            <div className="item-count">{count}</div>
        </div>
    </TooltipWrapper>
)

export const RiskDashboardItem = ({id, count, icon, risk, customLabel}) => {
    const className = risk?.value?.toLowerCase();
    
    return (
        <DashboardItem
            id={`${id}-${className}`}
            count={count}
            icon={icon}
            iconClassName={classnames(className, "global-risk-color", "vulnerability-icon")}
            tooltipText={`${!!customLabel ? customLabel : risk.label}: ${count}`}
        />
    )
}

const MiniDashboardWrapper = ({totalCount, totalTooltipId, children, className}) => {
    const totalText = `Total: ${totalCount}`;

    return (
        <div className={classnames("mini-dashboard-wrapper", {[className]: !!className})}>
            {!isUndefined(totalCount) &&
                <TooltipWrapper id={totalTooltipId} text={totalText}>
                    <div className="total-container" data-tip data-for={totalTooltipId}>{totalText}</div>
                </TooltipWrapper>
            }
            <div className="summary-items-container">
                {children}
            </div>
        </div>
    )
}

export default MiniDashboardWrapper;