import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { FETCH_METHODS, useFetch, usePrevious } from 'hooks';
import Loader from 'components/Loader';
import Text, { TEXT_TYPES } from 'components/Text';
import FormWrapper, { SelectField, TextField, validators, TimeField, MultiselectField, useFormikContext } from 'components/Form';
import { valueToValueLabel } from 'utils/apiUtils';
import { VALIDATION_TYPE_ITEMS } from '../utils';

import './cloud-account-config-form.scss';

const REPETITIVE_TYPES = {
    HOUR: "ServerlessByHoursPeriodicJobExpression",
    DAY: "ServerlessByDaysPeriodicJobExpression"
};

const FormFields = ({regionItems, isAwsAccount}) => {
    const {values, setFieldValue} = useFormikContext();

    const {periodicJobExpression} = values;
    const {intervalType} = periodicJobExpression || {};
    const prevIntervalType = usePrevious(intervalType);

    useEffect(() => {
        if (intervalType === prevIntervalType || !prevIntervalType) {
            return;
        }

        if (intervalType === REPETITIVE_TYPES.HOUR) {
            setFieldValue("periodicJobExpression.intervalTime", "");
        }
    }, [intervalType, prevIntervalType, setFieldValue]);
    
    return (
        <React.Fragment>
                <React.Fragment>
                    <SelectField
                        name="validateFunction"
                        label="Function validation"
                        items={Object.values(VALIDATION_TYPE_ITEMS)}
                        clearable={false}
                        validate={validators.validateRequired}
                    />
                    <div className="time-config-wrapper">
                        <TextField
                            type="number"
                            name="periodicJobExpression.interval"
                            label="Repeats every"
                            validate={validators.validateRequired}
                            className="interval-input-field"
                        />
                        <SelectField
                            name="periodicJobExpression.intervalType"
                            className="interval-type-input-field"
                            items={[
                                {value: REPETITIVE_TYPES.HOUR, label: "Hours"},
                                {value: REPETITIVE_TYPES.DAY, label: "Days"}
                            ]}
                            clearable={false}
                            validate={validators.validateRequired}
                        />
                        {intervalType === REPETITIVE_TYPES.DAY && <TimeField name="periodicJobExpression.intervalTime" label="On" />}
                    </div>
                </React.Fragment>
            <MultiselectField
                name="regions"
                label="Regions"
                items={regionItems}
            />
        </React.Fragment>
    )
}

const CloudAccountConfigForm = ({initialData: {id, regions, periodicJobExpression, validateFunction}, onFormSubmitSuccess, onDirtyChanage, isAwsAccount}) => {
    const [{loading, data, error}] = useFetch(`cloudAccounts/regions/${isAwsAccount ? "AWS" : "Azure"}`);

    if (loading) {
        return <Loader absolute={false}/>
    }

    if (error) {
        return null;
    }

    const initialValues = {
        id: "",
        regions: [],
        periodicJobExpression: {
            intervalTime: "",
            intervalType: "",
            interval: 1,
        },
        validateFunction: null,
        ...{id, regions, periodicJobExpression, validateFunction}
    }

    const {PeriodicJobType, hoursInterval, daysInterval, timeOfDay} = periodicJobExpression || {};
    const {hour, minute} = timeOfDay || {};

    initialValues.periodicJobExpression.intervalType = PeriodicJobType || REPETITIVE_TYPES.DAY;
    initialValues.periodicJobExpression.intervalTime = PeriodicJobType === REPETITIVE_TYPES.DAY ?
        `${`0${hour}`.slice(-2)}:${`0${minute}`.slice(-2)}` : "";
    initialValues.periodicJobExpression.interval = hoursInterval || daysInterval || 1;

    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withBottomMargin withTopMargin>Edit configuration</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl="cloudAccounts"
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
                className="cloud-account-form-wrapper"
                getSubmitParams={formValues => {
                    const {id, ...submitData} = formValues;
    
                    let {interval, intervalType, intervalTime} = formValues.periodicJobExpression;
    
                    submitData.periodicJobExpression.PeriodicJobType = intervalType;
    
                    if (intervalType === REPETITIVE_TYPES.HOUR) {
                        submitData.periodicJobExpression.hoursInterval = interval;
                        delete submitData.periodicJobExpression.daysInterval
                        delete submitData.periodicJobExpression.timeOfDay
                    } else {
                        submitData.periodicJobExpression.daysInterval = interval;
                        if (isEmpty(intervalTime)) {
                            intervalTime = "00:00"
                        }
                        const [hour, minute] = intervalTime.split(":");
                        submitData.periodicJobExpression.timeOfDay = {
                            hour: parseInt(hour, 10),
                            minute: parseInt(minute, 10)
                        };
                        delete submitData.periodicJobExpression.hoursInterval
                    }
    
                    delete submitData.periodicJobExpression.interval;
                    delete submitData.periodicJobExpression.intervalTime;
                    delete submitData.periodicJobExpression.intervalType;

                    return { //only edit form
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
            >
                <FormFields
                    regionItems={isAwsAccount ? data.map(({code, name}) => ({value: code, label: name})) : valueToValueLabel(data)}
                    isAwsAccount={isAwsAccount}
                />
            </FormWrapper>
        </div>
    );
};

export default CloudAccountConfigForm;
