import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { isNull, isEmpty } from 'lodash';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import { useAdminState } from 'context/AdminProvider';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { APP_TELEMETRY_STATUSES } from 'utils/systemConsts';
import Table, { tableUtils, useTimeFilter, TimeFilter } from 'components/Table';
import VulnerabilityIcon from 'components/VulnerabilityIcon';
import InfoIcon from 'components/InfoIcon';
import Icon, { ICON_NAMES } from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Loader from 'components/Loader';
import RiskTag from 'components/RiskTag';
import ToggleButton from 'components/ToggleButton';
import { formatDate } from 'utils/generalUtils';
import { WORKLOAD_RISK_TYPE_ITEMS } from 'utils/systemConsts';
import ProtectedStatus from 'layout/Runtime/ProtectedStatus';
import WorkloadNameDisplay from 'layout/Runtime/WorkloadNameDisplay';
import TokenInjectionStatus from 'layout/Runtime/Workloads/TokenInjectionStatus';
import { WorkloadRuleResultDisplay, ViolationReasonsDisplay } from 'layout/Runtime/Workloads/utils';
import { DEPLOYMNET_ENVIRONMENTS_PAGE_PATH } from 'layout/Deployments/Enviornments';
import { FiltersForm, FilterContext, RUNTIME_WORKLOAD_FILTER_KEYS } from '../Filter';

import './workloads-table.scss';

const SORT_KEYS = {
    WORKLOAD_RISK: "workloadRisk"
}

const ReasonsTooltipText = ({reasons}) => (
    <span>{reasons.map((reason, index) => <React.Fragment key={index}>{reason}<br /></React.Fragment>)}</span>
);

const HighestVulnerabilityIcon = ({tooltipId, podName, highestVulnerability, disabled}) => {
    if (!highestVulnerability || !podName) {
        return "";
    }

    return (
        <div className={classnames("higest-vulnerability-icon-wrapper", {disabled})}>
            <div data-tip data-for={tooltipId}>
                <VulnerabilityIcon
                    severity={highestVulnerability}
                    showSuccess={false}
                    showUnknown={true}
                />
            </div>
            {!disabled && <Tooltip id={tooltipId} text={`The workload has a vulnerability of severity HIGH or CRITICAL`} placement="top" />}
        </div>
    )
}

const Workloads = () => {
    const {showApiSecurity, loadingDisplay} = useDisplayConfigState();
    const {assignedAccountId} = useAdminState();
    const {isGlobalAdmin} = useAuthState();

    const {path} = useRouteMatch();
	const history = useHistory();

    const columns = useMemo(() => [
        {
            Header: 'Workload',
            id: "appName",
            Cell: ({row}) => {
                const {id, workloadType, pod, expansion} = row.original;
                
                return (
                    <WorkloadNameDisplay id={id} workloadType={workloadType} pod={pod} expansion={expansion} withIcon={true} />
                );
            },
            defaultCanSort: true,
            alwaysShow: true
        },
        {
            Header: "Labels",
            Cell: ({row}) => <tableUtils.KeyValueRenderer items={row.original.pod.labels} />,
            disableSortBy: true,
            hide: true
        },
        {
            Header: "Workload Risk",
            Cell: ({row}) => {
                const {risk} = row.original;
                const {level: riskLevel} = risk;

                return (
                    <RiskTag risk={riskLevel} />
                )
            },
            id: SORT_KEYS.WORKLOAD_RISK,
            defaultCanSort: true,
            width: 140
        },
        {
            Header: "Security Threats",
            id: "securityThreats",
            Cell: ({row}) => {
                const {id: rowId, original} = row;
                const {pod, highestSecurityContextRisk, risk, violation, podIdentification} = original;
                const {reasons} = risk;
                const {unidentifiedPodReasons} = violation || {};
                const diffs = podIdentification.diffSection.diffs || [];
                
                let threatsItems = [
                    {type: WORKLOAD_RISK_TYPE_ITEMS.UNIDENTIFIED.value, alwaysShow: true, text: isEmpty(unidentifiedPodReasons) ? "Unidentified" : <ReasonsTooltipText reasons={unidentifiedPodReasons} />},
                    {type: WORKLOAD_RISK_TYPE_ITEMS.PUBLIC_FACING.value, alwaysShow: true, text: "Public-facing", disabledText: "Non public-facing", ignoredText: "Public facing risk ignored"},
                    {type: WORKLOAD_RISK_TYPE_ITEMS.TEMPLATE_DIFF.value, alwaysShow: true, text: `${diffs.length} template diffs`},
                    {
                        type: WORKLOAD_RISK_TYPE_ITEMS.VULNERABILITY.value,
                        customIcon: ({risk, disabled}) => (
                            <HighestVulnerabilityIcon
                                tooltipId={`tooltip-${WORKLOAD_RISK_TYPE_ITEMS.VULNERABILITY.value}-${rowId}`}
                                podName={pod.name}
                                highestVulnerability={risk}
                                disabled={disabled}
                            />
                        )
                    },
                    ...(!highestSecurityContextRisk ? [] : [{
                        type: "securityContextRisk",
                        alwaysShow: true,
                        text: "Security context risk",
                        icon: ICON_NAMES.SECURITY_CONTEXT,
                        alwaysEnabled: true,
                        risk: highestSecurityContextRisk
                    }]),
                    {type: WORKLOAD_RISK_TYPE_ITEMS.DOCKERFILE_SCAN.value, text: "Highest dockerfile scan result is FATAL"},
                    ...(!showApiSecurity ? [] : [
                        {type: WORKLOAD_RISK_TYPE_ITEMS.API.value, text: "API risk"}
                    ])
                ];

                threatsItems = threatsItems.map(item => {
                    const {type, alwaysShow, text, alwaysEnabled, risk} = item;

                    const riskReasonItem = reasons.find(reason => reason.type === type);
                    const calculatedRisk = !!risk ? risk : (!!riskReasonItem ? riskReasonItem.level : null);
                    const isIgnored = !!riskReasonItem ? riskReasonItem.isIgnored : false;

                    return {
                        ...item,
                        show: alwaysShow || !!riskReasonItem,
                        text: text,
                        disabled: (alwaysShow && !riskReasonItem && !alwaysEnabled) || isIgnored,
                        riskReasonItem: riskReasonItem,
                        risk: !!calculatedRisk ? calculatedRisk.toLowerCase() : null,
                        isIgnored
                    }
                }).filter(item => item.show);

                return (
                    <div className="security-threats-container">
                        {
                            threatsItems.map(({type, icon, text, disabled, disabledText, ignoredText, isIgnored, customIcon: CustomIcon, risk}) => {
                                if (!!CustomIcon) {
                                    return <CustomIcon key={type} risk={risk} disabled={disabled} />
                                }

                                const iconTooltipId = `tooltip-${type}-${rowId}`;

                                let tooltipText = disabled ? disabledText : text;
                                tooltipText  = isIgnored ? ignoredText : tooltipText;
                                
                                return (
                                    <React.Fragment key={type}>
                                        <div data-tip data-for={iconTooltipId}>
                                            <Icon
                                                name={!!icon ? icon : WORKLOAD_RISK_TYPE_ITEMS[type].icon}
                                                className={classnames("security-threats-icon", "global-risk-color", {[risk]: !!risk})}
                                                disabled={disabled}
                                            />
                                        </div>
                                        {(!disabled || !!disabledText) && <Tooltip id={iconTooltipId} placement="top" text={tooltipText} />}
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                )
            },
            disableSortBy: true,
            width: showApiSecurity ? 320 : 280,
            disableResizing: true,
            alwaysShow: true
        },
        {
            Header: "Environment",
            accessor: original => original.environment.name,
            id: "environmentName"
        },
        {
            Header: "Namespace",
            accessor: original => !!original.namespace && original.namespace.name,
            disableSortBy: true,
            hide: true
        },
        {
            Header: "Cluster",
            accessor: original => !!original.cluster && original.cluster.name,
            disableSortBy: true
        },
        {
            Header: "Status",
            disableSortBy: true,
            Cell: ({row}) => {
                const {id: rowId, original} = row;
                const {status, statusReason} = original.state;
                const UnhealthyStatusText = "Unhealthy";
                const rendererProps = status !== APP_TELEMETRY_STATUSES.UNHEALTHY.key ?
                    {isActive: status === APP_TELEMETRY_STATUSES.ACTIVE.key} :
                    {isWarning: true, enabledText: UnhealthyStatusText, disabledText: UnhealthyStatusText};
                    
                return (
                <div style={{display: "flex", alignItems: "center"}}>
                    <tableUtils.StatusRenderer {...rendererProps} />
                    {!!statusReason &&
                        <InfoIcon
                            tooltipId={`status${rowId}${statusReason}`}
                            text={`Kubernetes reported status: "${statusReason}"`}
                            placement="left"
                        />}
                </div>
                );
            },
            width: 110
        },
        {
            Header: "Start time",
            id: "startTime",
            accessor: original => formatDate(original.state.startTime)
        },
        {
            Header: "Last seen",
            id: "finishTime",
            accessor: original => formatDate(original.state.lastSeen),
            hide: true
        },
        {
            Header: "Result",
            id: "result",
            Cell: ({row}) => {
                const {id: rowId, original} = row;
   
                return (
                    <WorkloadRuleResultDisplay tooltipId={String(rowId)} violation={original.violation} />
                )
            },
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Violation reason",
            Cell: ({row}) => (
                <ViolationReasonsDisplay violation={row.original.violation} />
            ),
            hide: true,
            disableSortBy: true
        },
        ...(!showApiSecurity ? [] : [{
            Header: "API Token Injection",
            Cell: ({row}) => <TokenInjectionStatus id={row.original.id} status={row.original.tokenInjectionStatus} />,
            disableSortBy: true,
            width: 100
        }]),
        {
            Header: "Protected",
            Cell: ({row}) => {
                const {isPodProtected, expansion} = row.original;
                
                return (
                    <ProtectedStatus isProtected={!!expansion ? expansion.isProtected : isPodProtected} />
                );
            },
            disableSortBy: true,
            width: 100
        }
    ], [showApiSecurity]);

    const location = useLocation();
    const {filters: queryFilters, timeRange} = location.query || {};

    const [{selected, endTime, startTime, loading}, refreshTimes, selectTime] = useTimeFilter(timeRange);

    const filters = FilterContext.useFilterState();
    const filterDispatch = FilterContext.useFilterDispatch();

    const [showSystemPods, setShowSystemPods] = useState(false);

    const mounted = useRef(true);
    const inititalRefreshTimes = useRef(false);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!inititalRefreshTimes.current) {
            inititalRefreshTimes.current = true;
            refreshTimes();
        }
    }, [refreshTimes]);

    if (loadingDisplay || (isGlobalAdmin && !assignedAccountId)) {
        return <Loader />;
    }

    const namespacesFilter = filters[RUNTIME_WORKLOAD_FILTER_KEYS.NAMESPACES];

    const systemPodsTooltipId = "system-pods-tooltip";
    const systemPodsTooltipText = (
        <span>
            "System pods" uses system environments to<br />
            filter results. <span onClick={() => history.push(DEPLOYMNET_ENVIRONMENTS_PAGE_PATH)} style={{textDecoration: "underline", cursor: "pointer"}}>See list of system environments</span>
        </span>
    );
    
    return (
        <div className="runtime-workloads-page">
            <div className="table-filters-container">
                <TimeFilter
                    defaultSelected={selected}
                    onTimeFilterChange={selectTime}
                    startTime={startTime}
                    endTime={endTime}
                    style={{marginRight: "10px"}}
                />
                <div className="table-filters-line">
                    {!isNull(startTime) && 
                        <FiltersForm
                            filters={filters}
                            queryFilters={queryFilters}
                            setFilters={updatedFilters => {
                                if (!mounted.current) {
                                    return;
                                }
                                
                                FilterContext.setFilters(filterDispatch, updatedFilters);
                            }}
                        />
                    }
                    <div className="runtime-system-pods-toggle-wrapper">
                        <Tooltip id={systemPodsTooltipId} clickable delayHide={300} text={systemPodsTooltipText} />
                        <div data-tip data-for={systemPodsTooltipId}>
                            <ToggleButton
                                title="System pods"
                                checked={showSystemPods}
                                onChange={() => setShowSystemPods(!showSystemPods)}
                                width={105}
                                small
                                
                            
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Table
                url="appTelemetries"
                name="runtime-workloads"
                columns={columns}
                filters={{...filters, namespacesFilter: btoa(JSON.stringify(namespacesFilter)), startTime, endTime, showSystemPods}}
                defaultSortBy={[{id: SORT_KEYS.WORKLOAD_RISK, desc: true}]}
                customOnRefresh={refreshTimes}
                exportToExcel={true}
                isLoading={loading || filters.filterLoading}
                onLineClick={({id}) => history.push(`${path}/${id}`)}
            />
        </div>
    )
}

export default Workloads;