import { useReducer, useEffect } from 'react';
import { isNull } from 'lodash';
import { useFetch, usePrevious } from 'hooks';

export const SEARCH_LOADER_ACTIONS = {
    SET_SEARCH_FILTER: "SET_SEARCH_FILTER",
    SET_SELECTED_RULE_IDS: "SET_SELECTED_RULE_IDS"
}

const initialState = {
    isLoading: false,
    selectedSearchFilter: null,
    selectedRuleIDs: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case SEARCH_LOADER_ACTIONS.SET_SEARCH_FILTER: {
            const selectedSearchFilter = action.payload;

            if (isNull(selectedSearchFilter)) {
                return {
                    ...initialState
                }
            }
            
            return {
                ...state,
                isLoading: true,
                selectedSearchFilter,
                selectedRuleIDs: null
            };
        }
        case SEARCH_LOADER_ACTIONS.SET_SELECTED_RULE_IDS: {
            return {
                ...state,
                isLoading: false,
                selectedRuleIDs: action.payload
            }
        }
        default:
            return state;
    }
}

function useRulesSearchLoaderReducer({policyUrl}) {
    const [{isLoading, selectedSearchFilter, selectedRuleIDs}, dispatch] = useReducer(reducer, initialState);

    const [{loading, data}, doSearchRequest] = useFetch(policyUrl, {loadOnMount: false});
    const prevLoading = usePrevious(loading);

    useEffect(() => {
        if (isNull(selectedSearchFilter)) {
            return;
        }
        
        doSearchRequest({queryParams: {policyFilter: btoa(selectedSearchFilter)}});
    }, [selectedSearchFilter, doSearchRequest]);

    useEffect(() => {
        if (prevLoading && !loading) {
            const fileredRuleIds = data.userRules.map(item => item.id);
        
            dispatch({
                type: SEARCH_LOADER_ACTIONS.SET_SELECTED_RULE_IDS,
                payload: fileredRuleIds
            });
        }
    }, [loading, prevLoading, data]);

    return [{searching: isLoading, selectedSearchFilter, selectedRuleIDs}, dispatch];
}

export default useRulesSearchLoaderReducer;