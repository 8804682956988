import React, { useMemo, useState, useRef, useEffect } from 'react';
import { isNull } from 'lodash';
import { TextField } from 'components/Form';
import Table, { useStanaloneTimeFilter, TimeFilter, FilterFormWrapper } from 'components/Table';
import RuleActionDisplay from 'components/RuleActionDisplay';
import MiniDashboardWrapper from 'components/MiniDashboardWrapper';
import { formatDate } from 'utils/generalUtils';
import ScanResourcesModal from './ScanResourcesModal';
import CdScanSummary from './CdScanSummary';

import './cd-scan.scss';

const SORT_KEYS = {
    TIME: "time"
};

const CdScan = () => {
    const [selectedScanData, setSelectedScanData] = useState(null);

    const columns = useMemo(() => [
        {
            Header: "Deployment Name",
            id: "deploymentName",
            accessor: "deploymentName",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Deployment Source",
            id: "deploymentSource",
            accessor: "deploymentSource",
            disableSortBy: true
        },
        {
            Header: "Time",
            id: SORT_KEYS.TIME,
            accessor: original => formatDate(original.time)
        },
        {
            Header: "Deployment Version",
            id: "deploymentVersion",
            accessor: "deploymentVersion",
            hide: true,
            disableSortBy: true
        },
        {
            Header: "Result",
            id: "result",
            Cell: ({row}) => {
                const {result} = row.original;

                return (
                    <RuleActionDisplay action={result} />
                )
            },
            disableSortBy: true
        },
        {
            Header: "Security Finding",
            id: "securityFinding",
            Cell: ({row}) => {
                const {securityFinding, id} = row.original;
                const totalCount = securityFinding.reduce((acc, curr) => {
                    return acc + curr.value.count;
                }, 0);
                
                return (
                    <MiniDashboardWrapper totalCount={totalCount} totalTooltipId={`${id}-total`} className="security-findings-summary">
                        <CdScanSummary id={id} securityFinding={securityFinding} />
                    </MiniDashboardWrapper>
                )
            },
            width: 250,
            disableSortBy: true,
            alwaysShow: true
        }
    ], []);

    const [{selected, endTime, startTime}, refreshTimes, selectTime] = useStanaloneTimeFilter();

    const [filters, setFilters] = useState({
        resourceName: ""
    });

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    return (
        <div className="cd-scan-page">
            <div className="table-header-container">
                <TimeFilter
                    defaultSelected={selected}
                    onTimeFilterChange={selectTime}
                    startTime={startTime}
                    endTime={endTime}
                    style={{marginRight: "10px"}}
                />
                {!isNull(startTime) &&
                    <FilterFormWrapper
                        initialValues={{...filters}}
                        onChange={updatedFilters => {
                            if (!mounted.current) {
                                return;
                            }
    
                            setFilters(updatedFilters);
                        }}
                    >
                        <TextField name="resourceName" label="Deployment name" />
                    </FilterFormWrapper>
                }
            </div>
            <Table
                url="cd"
                name="cd-scan"
                columns={columns}
                filters={{...filters, startTime, endTime}}
                defaultSortBy={[{id: SORT_KEYS.TIME, desc: true}]}
                onLineClick={rowData => setSelectedScanData(rowData)}
                customOnRefresh={refreshTimes}
                emptyMessage="No CD Scans"
            />
            {!isNull(selectedScanData) &&
                <ScanResourcesModal id={selectedScanData.id} onClose={() => setSelectedScanData(null)} />
            }
        </div>
    );
}

export default CdScan;