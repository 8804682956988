import Vulnerabilities from 'layout/CiCd/Vulnerabilities';

import './tab-vulnerabilities.scss';

const TabVulnerabilities = ({functionId}) => (
    <div className="function-vulnerabilities-tab-wrapper">
        <Vulnerabilities url="serverless/functions" id={functionId} />
    </div>
)

export default TabVulnerabilities;