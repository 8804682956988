import React from 'react';
import { TextField, validators, TextAreaField, ArrayField } from 'components/Form';

export const EMPTY_VALUE = {name: "", key: ""};

const FormStep2 = () => (
    <ArrayField
        name="keys"
        label="List of keys"
        horizontal={false}
        withBackground
        firstFieldProps={{
            component: TextField,
            key: "name",
            label: "Name",
            placeholder: "name",
            validate: (value, valueIndex, values) => {
                const valueError = validators.validateRequired(value);

                if (valueError) {
                    return valueError;
                }

                const otherValues = [
                    ...values.slice(0, valueIndex),
                    ...values.slice(valueIndex + 1)
                ].map(({name}) => name);

                return otherValues.includes(value) ? "Key name must be unique" : undefined;
            }
        }}
        secondFieldProps={{
            component: TextAreaField,
            key: "key",
            label: "Key",
            placeholder: "key",
            validate: validators.validateRequired
        }}
    />
)

export default FormStep2;