import React, { useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import { useFetch, FETCH_METHODS, usePrevious } from 'hooks'; 
import Table from 'components/Table'; 
import Icon, { ICON_NAMES } from 'components/Icon';
import IconWithTitle from 'components/IconWithTitle';

import './image-cis.scss';

const SEVERITY_MAP = {
    FATAL: {
        title: "Fatal",
        className: "fatal"
    },
    WARN: {
        title: "Warning",
        className: "warning"
    },
    INFO: {
        title: "Info",
        className: "info"
    }
}

const ImageCIS = ({id, showIgnoredOnly, onUpdate, withIgnore}) => {
    const columns = useMemo(() => [
        {
            Header: "Name",
            id: "name",
            accessor: "name",
            disableSortBy: true
        },
        {
            Header: "Title",
            id: "title",
            accessor: "title",
            disableSortBy: true
        },
        {
            Header: "Findings",
            id: "severity",
            Cell: ({row}) => {
                const {severity} = row.original;
                const {title, className} = SEVERITY_MAP[severity];
                
                return (
                    <div className={classnames("cis-severity-wrapper", className)}>
                        <Icon name={ICON_NAMES.ALERT_CIS} />
                        <div>{title}</div>
                    </div>
                )
            },
            disableSortBy: true
        },
        {
            Header: "Description",
            id: "description",
            accessor: "description",
            disableSortBy: true
        }
    ], []);

    const {isReadOnlyUser} = useAuthState();

    const [{loading}, updateIgnoreCis] = useFetch(`/images/${id}/dockerfileScanResults/ignore?actionType=${showIgnoredOnly ? "REMOVE" : "ADD"}`, {loadOnMount: false});
    const preLoading = usePrevious(loading);

    const doCisUpdate = (selectedIds) => updateIgnoreCis({
        method: FETCH_METHODS.POST,
        submitData: {uuidList: selectedIds}
    });

    useEffect(() => {
        if (preLoading && !loading) {
            onUpdate();
        }
    }, [loading, preLoading, onUpdate]);

    return (
        <Table
            url={`images/${id}/dockerfileScanResults`}
            name="image-cis"
            columns={columns}
            filters={{isIgnored: showIgnoredOnly}}
            withMultiSelect={!isReadOnlyUser && withIgnore}
            rowSelectActionComponent={({selectedIds, disabled}) => {
                if (withIgnore) {
                    return (
                        <IconWithTitle
                            name={showIgnoredOnly ? ICON_NAMES.BELL : ICON_NAMES.BELL_CROSSED}
                            title={showIgnoredOnly ? "Activate" : "Ignore"}
                            onClick={() => doCisUpdate(selectedIds)}
                            disabled={disabled}
                        />
                    )
                }

                return null;
            }}
            isLoading={loading}
        />
    )
}

export default ImageCIS;