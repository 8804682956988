import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { isNull, isEmpty } from 'lodash';
import Loader from 'components/Loader';
import Icon, { ICON_NAMES } from 'components/Icon';
import IconWithTitle from 'components/IconWithTitle';
import { useAuthState } from "context/AuthProvider";
import FormModal from 'components/FormModal';
import ModalConfirmation from 'components/ModalConfirmation';
import { useFetch, useDelete, usePrevious } from 'hooks';
import RegistryForm from './RegistryForm';

import './registries.scss';

export const DEPLOYMNET_REGISTRIES_PAGE_PATH = "/deployments/registries";

const RegistryItem = ({url, clusterIds, clustersData, onEdit, onDelete, isSelected, isReadOnlyUser}) => (
    <div className={classnames("registry-item", {selected: isSelected})} onDoubleClick={onEdit}>
        <div className="registry-header">
            <div className="registry-name">{url}</div>
        </div>
        <div className="registry-content">
            <div className="content-title">Cluster:</div>
            <div className="content-clusters-list">
                {
                    clusterIds.map(clusterId => {
                        const cluster = clustersData.find(item => item.id === clusterId);
                        
                        return (
                            <div key={clusterId} className="cluster-item">{!!cluster ? cluster.name : clusterId}</div>
                        )
                    })
                }
            </div>
        </div>
        <div className="registry-actions">
            <Icon name={ICON_NAMES.EDIT}  disabled={isReadOnlyUser} onClick={onEdit} />
            <Icon name={ICON_NAMES.DELETE} disabled={isReadOnlyUser} onClick={onDelete} />
        </div>
    </div>
)

const Registries = () => {
    const [{loading: loadingClusters, data: clustersData}, fetchClusters] = useFetch("leanKubernetesClusters");
    const prevLoadingClusters = usePrevious(loadingClusters);
    const {isReadOnlyUser} = useAuthState();

    const [{loading, data}, fetchRegistries] = useFetch("registries", {loadOnMount: false});

    const [{deleting}, deleteRegistry] = useDelete("registries");
    const prevDeleting = usePrevious(deleting);

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const selectedRegistryId = urlSearchParams.get("selectedRegistryId");

    useEffect(() => {
        const selectedRegistryItems = document.getElementsByClassName("registry-item selected");

        if (!isEmpty(selectedRegistryItems)) {
            selectedRegistryItems[0].scrollIntoView({behavior: "smooth"});
        }
    }, [selectedRegistryId]);

    useEffect(() => {
        if (prevDeleting && !deleting) {
            fetchRegistries();
        }
    }, [deleting, prevDeleting, fetchRegistries]);

    useEffect(() => {
        if (prevLoadingClusters && !loadingClusters) {
            fetchRegistries();
        }
    }, [loadingClusters, prevLoadingClusters, fetchRegistries]);

    const [selectedRegistry, setSelectedRegistry] = useState(null);
    const closeRegistryForm = () => setSelectedRegistry(null);

    const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationId(null);

    if (loading || loadingClusters || deleting) {
        return <Loader />
    }

    return (
        <div className="registries-page">
            <div className="registries-header">
                <IconWithTitle name={ICON_NAMES.ADD} title="New Registry" disabled={isReadOnlyUser} onClick={() => setSelectedRegistry({})} />
                <IconWithTitle name={ICON_NAMES.REFRESH} title="Refresh" onClick={fetchClusters} />
            </div>
            <div className="registries-content">
                {
                    !!data && data.map(item => (
                        <RegistryItem
                            key={item.id}
                            clustersData={clustersData}
                            {...item}
                            onEdit={() => setSelectedRegistry(item)}
                            onDelete={() => setDeleteConfirmationId(item.id)}
                            isSelected={item.id === selectedRegistryId}
                            isReadOnlyUser={isReadOnlyUser}
                        />
                    ))
                }
            </div>
            {!isNull(selectedRegistry) &&
                <FormModal
                    onClose={closeRegistryForm}
                    formComponent={RegistryForm}
                    formProps={{
                        initialData: {...selectedRegistry},
                        onFormSubmitSuccess: () => {
                            closeRegistryForm();
                            fetchRegistries();
                        },
                        clustersData: clustersData
                    }}
                />
            }
            {!isNull(deleteConfirmationId) &&
                <ModalConfirmation 
                    title="Are you sure?"
                    message="This operation cannot be reverted"
                    confirmTitle="Delete"
                    onCancel={closeDeleteConfirmation}
                    onConfirm={() => {
                        closeDeleteConfirmation();
                        deleteRegistry(deleteConfirmationId);
                    }}
                    confirmAlert
                />
            }
        </div>
    )
}

export default Registries;