import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { isNull } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useAuthState } from 'context/AuthProvider';
import { useFetch, FETCH_METHODS, usePrevious } from 'hooks';
import { TextField } from 'components/Form';
import Table, { FilterFormWrapper } from 'components/Table';
import IconWithTitle from 'components/IconWithTitle';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES} from 'components/Icon';
import FormModal from 'components/FormModal';
import EnvironmentForm from './EnvironmentForm';
import InnerTable from './InnerTable';
import EnvironmentsAdvisor from './EnvironmentsAdvisor';
import DeleteEnvironmentModal from "./DeleteEnvironmentModal";
import './environments.scss';

const SORT_KEYS = {
    NAME: "name"
}

export const DEPLOYMNET_ENVIRONMENTS_PAGE_PATH = "/deployments/environments";

const Environments = () => {
    const columns = useMemo(() => [
        {
            Header: "Environment Name",
            id: SORT_KEYS.NAME,
            Cell: ({row}) => {
                const {id, name, isSystemEnv} = row.original;
                
                return (
                    <div className="environment-name-wrapper">
                        {isSystemEnv &&
                            <IconWithTooltip
                                name={ICON_NAMES.SYSTEM}
                                tooltipId={`system-env-tooltip-${id}`}
                                tooltipText="System environment"
                            />
                        }
                        <div>{name}</div>
                    </div>
                )
            },
            alwaysShow: true
        }
    ], []);

    const {isReadOnlyUser} = useAuthState();

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = useCallback(() => setRefreshTimestamp(Date()), []);

    const [selectedEnvironmentData, setSelectedEnvironmentData] = useState(null);
    const closeEnvironmentModal = () => setSelectedEnvironmentData(null);

    const [deleteEnvironmentConfirmationData, setDeleteEnvironmentConfirmationData] = useState(null);
    const closeDeleteConfirmation = () => setDeleteEnvironmentConfirmationData(null);

    const [{loading: advisorLoading}, submitAdvisorData] = useFetch("environments/batch", {loadOnMount: false});
    const prevAdvisorLoading = usePrevious(advisorLoading);

    useEffect(() => {
        if (prevAdvisorLoading && !advisorLoading) {
            refreshTableData();
        }
    }, [advisorLoading, prevAdvisorLoading, refreshTableData]);

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);

    const [filters, setFilters] = useState({
        name: urlSearchParams.get("name") || ""
    });

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    return (
        <div className="deployments-environments-page">
            <div className="table-header-container">
                <IconWithTitle
                    name={ICON_NAMES.ADD}
                    title="New Environment"
                    className="add-new-button"
                    onClick={() => setSelectedEnvironmentData({})}
                    disabled={isReadOnlyUser}
                />
                <FilterFormWrapper
                    initialValues={{...filters}}
                    onChange={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                >
                    <TextField name="name" label="Name" />
                </FilterFormWrapper>
            </div>
            <Table
                url="environments"
                name="environments"
                columns={columns}
                defaultSortBy={[{id: SORT_KEYS.NAME, desc: false}]}
                actionsComponent={({original}) => {
                    const {id} = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;

                    return (
                        <div className="envs-row-actions">
                            <IconWithTooltip
                                name={ICON_NAMES.EDIT}
                                onClick={() => setSelectedEnvironmentData(original)}
                                tooltipId={editTooltipId}
                                tooltipText="Edit environment"
                                disabled={isReadOnlyUser}
                            />
                            <IconWithTooltip
                                name={ICON_NAMES.DELETE}
                                onClick={() => setDeleteEnvironmentConfirmationData(original)}
                                tooltipId={deleteTooltipId}
                                tooltipText="Delete environment"
                                disabled={isReadOnlyUser}
                            />
                        </div>
                    );
                }}
                actionsCellWidth={80}
                withPagination={false}
                refreshTimestamp={refreshTimestamp}
                exportToExcel={true}
                innerRowComponenet={InnerTable}
                filters={filters}
            />
            {!isNull(selectedEnvironmentData) &&
            <FormModal
                onClose={closeEnvironmentModal}
                formComponent={EnvironmentForm}
                formProps={{
                    initialData: {...selectedEnvironmentData},
                    onFormSubmitSuccess: () => {
                        closeEnvironmentModal();
                        refreshTableData();
                    }
                }}
            />
            }
            {!isNull(deleteEnvironmentConfirmationData) &&
            <DeleteEnvironmentModal onClose={closeDeleteConfirmation} onDeleteSuccess={refreshTableData}
                                    data={deleteEnvironmentConfirmationData}/>}
            <EnvironmentsAdvisor submitAdvisorData={data => submitAdvisorData({submitData: data, method: FETCH_METHODS.POST})}/>
        </div>
    )
}

export default Environments;