import React, { useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import { useFetch } from 'hooks';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import BackRouteButton from 'components/BackRouteButton';
import Text, { TEXT_TYPES } from 'components/Text';
import Icon, { ICON_NAMES } from 'components/Icon';
import IconWithTitle from 'components/IconWithTitle';
import { TooltipWrapper, TOOLTIP_PLACEMENTS } from 'components/Tooltip';
import Loader from 'components/Loader';
import FormModal from 'components/FormModal';
import DoublePaneDisplay from 'components/DoublePaneDisplay';
import DisplaySection from 'components/DisplaySection';
import DetailsPageContentWrapper from 'components/DetailsPageContentWrapper';
import InputWithCopy from 'components/InputWithCopy';
import TableSimple from 'components/TableSimple';
import { VALIDATION_TYPE_ITEMS } from 'utils/systemConsts';
import TrustedSignerForm from '../TrustedSignerForm';
import { hasActiveValidation } from '../utils';

import './trusted-signers-deatils.scss';

const PanelTitle = ({children, className}) => <div className={classnames("signer-details-title", className)}>{children}</div>;

const KeyDisplay = ({id, keyValue}) => (
    <InputWithCopy className="trusted-signer-key-display" value={keyValue} tooltipId={id} tooltipText="Copy key" />
);

const ScopeItemsDisplay = ({title, items}) => (
    <div className="scope-items-wrapper">
        <PanelTitle className="scope-items-title">{title}</PanelTitle>
        <TableSimple
            headers={["Name", "Validation"]}
            rows={items.map(({id, name, validation}) =>
                ([
                    hasActiveValidation(validation) ? name :
                        <div className="no-item-validation-name-wrapper">
                            {name}
                            <TooltipWrapper id={`item-validation-alert-${id}`} text="Please switch to signature validation" placement={TOOLTIP_PLACEMENTS.RIGHT}>
                                <Icon name={ICON_NAMES.ALERT} />
                            </TooltipWrapper>
                        </div>,
                    VALIDATION_TYPE_ITEMS[validation]?.label
                ]))}
            emptyText={`No assigned ${title.toLowerCase()}`}
        />
    </div>
)

const TrustedSignersDetails = () => {
    const {showServerlessSecurity} = useDisplayConfigState();

    const {url} = useRouteMatch();
    const params = useParams();
    const {id} = params;

    const [showEditForm, setShowEditForm] = useState(false);
    const closeEditForm = () => setShowEditForm(false);

    const [{loading, data}, fetchSignerData] = useFetch(`trustedSigners/${id}`);

    const {name, keys, trustedSignerClusters, trustedSignerCloudAccounts} = data || {};
    
    return (
        <div className="trusted-signer-details-page-wrapper">
            <BackRouteButton title="All Trust Profiles" path={url.replace(`/${id}`, "")} />
            {loading ? <Loader /> :
                <React.Fragment>
                    <div className="signer-details-header">
                        <Text type={TEXT_TYPES.TITLE_MEDIUM}>{name}</Text>
                        <IconWithTitle name={ICON_NAMES.EDIT} title="Edit" onClick={() => setShowEditForm(true)} />
                    </div>
                    <DetailsPageContentWrapper>
                        <DoublePaneDisplay
                            className="signer-details-content"
                            leftPane={() => (
                                <React.Fragment>
                                    <PanelTitle>Development CI Keys</PanelTitle>
                                    <div className="signer-details-keys-wrapper">
                                        {
                                            keys.map(({name, key}, index) => (
                                                <DisplaySection 
                                                    key={index}
                                                    title={<IconWithTitle name={ICON_NAMES.KEY_DIAGONAL} title={name} />}
                                                    allowClose
                                                    initialIsClosed={index !== 0}
                                                    noPadding
                                                >
                                                    <KeyDisplay id={`signer-key-${index}`} keyValue={key} />
                                                </DisplaySection>
                                            ))
                                        }
                                    </div>
                                </React.Fragment>
                            )}
                            rightPane={() => (
                                
                                <React.Fragment>
                                    <ScopeItemsDisplay title="Clusters" items={trustedSignerClusters} />
                                    {showServerlessSecurity && <ScopeItemsDisplay title="Cloud accounts" items={trustedSignerCloudAccounts} />}
                                </React.Fragment>
                            )}
                        />
                    </DetailsPageContentWrapper>
                </React.Fragment>
            }
            {showEditForm &&
                <FormModal
                    onClose={closeEditForm}
                    formComponent={TrustedSignerForm}
                    formProps={{
                        initialData: {...data},
                        onFormSubmitSuccess: () => {
                            closeEditForm();
                            fetchSignerData();
                        }
                    }}
                />
            }
        </div>
    )
}

export default TrustedSignersDetails;