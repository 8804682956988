import React, { useEffect } from 'react';
import { isNull, get, set } from 'lodash';
import { ACCOUNT_TIERS_ITEMS, ACCOUNT_MODES_ITEMS, REGULAR_USER_ROLES_ITEMS } from 'utils/systemConsts';
import { FETCH_METHODS, usePrevious } from 'hooks';
import FormWrapper, { validators, TextField, RadioField, TextAreaField, useFormikContext } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';

const ENABLE_STATUS_ITEMS = {
    ENABLED: {
        value: "ENABLED",
        label: "Active"
    },
    DISABLED: {
        value: "DISABLED",
        label: "Disabled"
    }
};

const EMPTY_NODES_VALUE = {maxNodes: ""};

const FormCreateDefaultUser = () => (
    <React.Fragment>
        <TextField name="fullName" label="Default User Full Name" validate={validators.validateRequired} />
        <TextField type="email" name="email" label="Default User Email" validate={validators.validateEmail} />
    </React.Fragment>
);

const getIsEnterpriseAccount = accountTier => accountTier === ACCOUNT_TIERS_ITEMS.ENTERPRISE.value;

const getFieldNameConverted = isEditForm => name => isEditForm ? name : `account.${name}`;

const FormFields = ({isEditForm}) => {
    const {values, setFieldValue} = useFormikContext();

    const getFieldName = getFieldNameConverted(isEditForm);

    const accountTierFieldName = getFieldName("accountTier");
    const accountTier = get(values, accountTierFieldName);
    const prevAccountTier = usePrevious(accountTier);

    const accountLimitsFieldName = getFieldName("accountUsageLimits");

    useEffect(() => {
        if (!!prevAccountTier && prevAccountTier !== accountTier) {
            setFieldValue(accountLimitsFieldName, EMPTY_NODES_VALUE);
        }
    }, [prevAccountTier, accountTier, accountLimitsFieldName, setFieldValue]);

    return (
        <React.Fragment>
            <TextField
                    name={getFieldName("name")}
                    label="Account Name"
                    validate={validators.validateRequired}
                />
                <RadioField
                    name={getFieldName("status")}
                    label="Account Status"
                    items={Object.values(ENABLE_STATUS_ITEMS)}
                    horizontal={true}
                />
                <TextAreaField
                    name={getFieldName("description")}
                    label="Info (optional)"
                />
                <RadioField
                    name={getFieldName("permissionsMode")}
                    label="Mode"
                    items={Object.values(ACCOUNT_MODES_ITEMS)}
                    horizontal={true}
                />
                <RadioField
                    name={accountTierFieldName}
                    label="Tier"
                    items={Object.values(ACCOUNT_TIERS_ITEMS)}
                    horizontal={true}
                />
                <TextField
                    type="number"
                    name={`${accountLimitsFieldName}.maxNodes`}
                    label="Nodes"
                    disabled={!getIsEnterpriseAccount(accountTier)}
                />
                <RadioField
                    name={getFieldName("serverlessSecurity")}
                    label="Serverless Security"
                    items={Object.values(ENABLE_STATUS_ITEMS)}
                    horizontal={true}
                />
                {!isEditForm && <FormCreateDefaultUser />}
        </React.Fragment>
    )
}

const AccountForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const isEditForm = initialData && initialData.id;

    const accountInitialValues = {
        name: "",
        description: "",
        status: ENABLE_STATUS_ITEMS.ENABLED.value,
        permissionsMode: ACCOUNT_MODES_ITEMS.NORMAL.value,
        accountTier: ACCOUNT_TIERS_ITEMS.ENTERPRISE.value,
        serverlessSecurity: ENABLE_STATUS_ITEMS.ENABLED.value
    };

    const initialValues = isEditForm ? {...accountInitialValues, ...initialData} : {
        account: {...accountInitialValues},
        fullName: "",
        email: ""
    }

    if (isNull(initialValues.description)) {
        initialValues.description = "";
    }

    if (isEditForm) {
        initialValues.accountUsageLimits = (!!initialValues.accountUsageLimits && !!initialValues.accountUsageLimits.maxNodes) ?
            initialValues.accountUsageLimits : EMPTY_NODES_VALUE;

    } else {
        initialValues.account.accountUsageLimits = (!!initialValues.account.accountUsageLimits && !!initialValues.account.accountUsageLimits.maxNodes) ?
            initialValues.account.accountUsageLimits : EMPTY_NODES_VALUE;
    }

    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{`${isEditForm ? "Edit" : "New"} Account`}</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl="admin/accounts"
                getSubmitParams={formValues => {
                    const {id, ...submitData} = formValues;

                    if (!isEditForm) {
                        submitData.role = REGULAR_USER_ROLES_ITEMS.ACCOUNT_ADMIN.value;
                        submitData.status = ENABLE_STATUS_ITEMS.ENABLED.value;
                    }

                    const getFieldName = getFieldNameConverted(isEditForm);
                    const accountTier = get(submitData, getFieldName("accountTier"));

                    if (!getIsEnterpriseAccount(accountTier)) {
                        set(submitData, getFieldName("accountUsageLimits"), null);
                    }

                    delete submitData.createdTime;
                    delete submitData.trialExpiratiomTime;

                    if (isEditForm) {
                        delete submitData.apiSecurity;
                    }

                    return !isEditForm ? {submitData} : {
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <FormFields isEditForm={isEditForm} />
            </FormWrapper>
        </div>
    )
}

export default AccountForm;