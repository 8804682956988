import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import Table, { useStanaloneTimeFilter, TimeFilter } from 'components/Table';
import { formatDate } from 'utils/generalUtils';
import { APP_PATHS } from 'utils/systemConsts';
import FiltersForm from './FiltersForm';

import './audit-log.scss';

const SORT_KEYS = {
    TIME: "time"
}

const AuditLog = () => {
    const location = useLocation();
    const {pathname} = location;

    const {isGlobalAdmin} = useAuthState();

    const isGlobalAdminDisplay = isGlobalAdmin && pathname.includes(APP_PATHS.GLOBAL_ADMIN_AUDIT.path);
    
    const columns = useMemo(() => [
        {
            Header: "Time",
            id: SORT_KEYS.TIME,
            accessor: original => formatDate(original.time)
        },
        {
            Header: "User",
            id: "user",
            accessor: "user",
            disableSortBy: true
        },
        {
            Header: "Action",
            id: "actions",
            accessor: "action"
        },
        {
            Header: "Object Type",
            id: "objectType",
            accessor: "objectType"
        },
        {
            Header: "Object Name",
            id: "objectName",
            accessor: "objectName",
            disableSortBy: true
        },
        {
            Header: "Module",
            id: "module",
            accessor: "module",
            disableSortBy: true
        },
        ...(!isGlobalAdminDisplay ? [] : [{
            Header: "Account",
            id: "account",
            accessor: "account"
        }])
    ], [isGlobalAdminDisplay]);

    const [{selected, endTime, startTime}, refreshTimes, selectTime] = useStanaloneTimeFilter();

    const [filters, setFilters] = useState({
        filterLoading: true
    });

    let url = "auditLogs";
    let tableName = "audit-log-table";
    if (isGlobalAdminDisplay) {
        url = `admin/${url}`;
        tableName = "admin-audit-log-table";
    }

    const mounted = useRef(true);
    const inititalRefreshTimes = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, [refreshTimes]);

    useEffect(() => {
        if (!inititalRefreshTimes.current) {
            inititalRefreshTimes.current = true;
            refreshTimes();
        }
    }, [refreshTimes]);

    return (
        <div className={classnames("audit-page", {"global-admin-page": isGlobalAdminDisplay})}>
            <div className="table-filters-container">
                <TimeFilter
                    defaultSelected={selected}
                    onTimeFilterChange={selectTime}
                    startTime={startTime}
                    endTime={endTime}
                    style={{marginRight: "10px"}}
                />
                <FiltersForm
                    filters={filters}
                    setFilters={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                />
            </div>
            <Table
                url={url}
                name={tableName}
                columns={columns}
                filters={{...filters, startTime, endTime}}
                defaultSortBy={[{id: SORT_KEYS.TIME, desc: true}]}
                customOnRefresh={refreshTimes}
                exportToExcel={true}
                isLoading={filters.filterLoading}
            />
        </div>
    )
}

export default AuditLog;
