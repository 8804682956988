import React from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';

import './round-icon-container.scss';

const RoundIconContainer = ({ name, onClick, className, small = false, large = false, disabled, iconStyle }) => {
    const containerClassName = classnames(
        "round-icon-container",
        { clickable: !!onClick },
        { [className]: className },
        { disabled: disabled },
        { small, large }
    );

    return (
        <div className={containerClassName} onClick={event => disabled || !onClick ? undefined : onClick(event)}>
            <Icon name={name} style={iconStyle} />
        </div>
    )
}

export default RoundIconContainer;