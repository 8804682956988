import React from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

import './icon-with-tooltip.scss';

const IconWithTooltip = ({tooltipId, tooltipText, placement="top", wrapperClassName, ...props}) => (
    <React.Fragment>
        <div className={classnames("icon-with-tooltip-wrapper", {[wrapperClassName]: !!wrapperClassName})} data-tip data-for={tooltipId}>
            <Icon {...props} />
        </div>
        <Tooltip id={tooltipId} text={tooltipText} placement={placement} />
    </React.Fragment>
);

export default IconWithTooltip;