import React from 'react';
import { isEmpty } from 'lodash';
import { TextElement, InfoMessage, InnerTitle, LinksList, NotificationMessage, SectionSeparator } from 'components/DeleteWizardModal';
import { DEPLOYMNET_ENVIRONMENTS_PAGE_PATH } from 'layout/Deployments/Enviornments';
import { CONNECTIONS_POLICY_URL } from 'layout/Policies/ConnectionsPolicy';
import { DEPLOYMENT_POLICY_URL } from 'layout/Policies/DeploymentPolicy';
import { EVENTS_POLICY_URL } from 'layout/Policies/KubernetesPolicy';
import { getConnectionRuleItems, getDeploymentRuleItems, getClusterEventsRuleItems, CONNECTION_RULES_NAME,
    DEPLOYMENT_RULES_NAME, CLUSTER_EVENTS_RULES_NAME } from './utils';

const RulesSection = ({name, pathname, items}) => (
    <React.Fragment>
        <InnerTitle>{`${name} Rules`}</InnerTitle>
        <TextElement>{`The Environment will be detached from the following ${name} Rules.`}</TextElement>
        <InfoMessage>{`If a ${name} Rule is based on once of the listed Environments, it will be deleted.`}</InfoMessage>
        <LinksList
            pathname={pathname}
            items={items}
        />
    </React.Fragment>
)

const EnvironmentsStep = ({stepData, stepProps}) => {
    const {environments, connectionRules, deploymentRules, clusterEventRules} = stepData;
    
    return (
        <div className="delete-cluster-environments">
            <React.Fragment>
                <InnerTitle>Environments</InnerTitle>
                <TextElement>The cluster will be detached from the following Environments.</TextElement>
                <InfoMessage>{`If an Environment is only attached to ${stepProps.deleteItemName}, it will be deleted.`}</InfoMessage>
                <LinksList
                    pathname={DEPLOYMNET_ENVIRONMENTS_PAGE_PATH}
                    items={environments.map(({name}) => ({name, searchParams: {name}}))}
                />
            </React.Fragment>
            {(!isEmpty(connectionRules) || !isEmpty(deploymentRules) || !isEmpty(clusterEventRules))  &&
                <NotificationMessage className="environment-rules-message" >
                    Deleting these environments will have an impact on the following elements:
                </NotificationMessage>
            }
            {!isEmpty(connectionRules) &&
                <RulesSection
                    name={CONNECTION_RULES_NAME}
                    pathname={CONNECTIONS_POLICY_URL}
                    items={getConnectionRuleItems(connectionRules)}
                />
            }
            {!isEmpty(connectionRules) && !isEmpty(deploymentRules) && <SectionSeparator />}
            {!isEmpty(deploymentRules) &&
                <RulesSection
                    name={DEPLOYMENT_RULES_NAME}
                    pathname={DEPLOYMENT_POLICY_URL}
                    items={getDeploymentRuleItems(deploymentRules)}
                />
            }
            {!isEmpty(deploymentRules) && !isEmpty(clusterEventRules) && <SectionSeparator />}
            {!isEmpty(clusterEventRules) &&
                <RulesSection
                    name={CLUSTER_EVENTS_RULES_NAME}
                    pathname={EVENTS_POLICY_URL}
                    items={getClusterEventsRuleItems(clusterEventRules)}
                />
            }
        </div>
    )
}

export default EnvironmentsStep;