import React, { useEffect, useState } from 'react';
import { isEqual, isNull } from 'lodash';
import { valueToValueLabel } from 'utils/apiUtils';
import { usePrevious } from 'hooks';
import { useFormikContext, validators, SelectField, MultiselectField, ArrayField, KeyValuesWithAllField } from 'components/Form';
import ModalConfirmation from 'components/ModalConfirmation';
import { ALL_KEY, USER_TYPES } from '../utils';
import { CUSTOM_RULE_ID } from './utils';

const ALL_ITEM = {value: ALL_KEY, label: "All"};

const UsersField = ({name, label, kubernetesUsersData, allItemLabel="All", disabled}) => (
    <KeyValuesWithAllField
        ALL_KEY={ALL_KEY}
        name={name}
        label={label}
        keyName="userType"
        keyItems={[
            {value: USER_TYPES.USER, label: "User"},
            {value: USER_TYPES.GROUP, label: "Group"},
            {value: USER_TYPES.SERVICEACCOUNT, label: "Service Account"}
        ]}
        keyPlaceholder="type"
        allItemLabel={allItemLabel}
        valuesName="userName"
        disabled={disabled}
        getValuesProps={({userType}) => ({
            creatable: true,
            showValueTooltip: true,
            placeholder: userType === USER_TYPES.SERVICEACCOUNT ? "namespace/name" : "name",
            items: kubernetesUsersData[userType] || []
        })}
    />
)

const ClearFieldConfirmation = ({allLabel, onCancel, onConfirm}) => (
    <ModalConfirmation
        title="Unsaved changes"
        message={`When choosing '${allLabel}' you are deleting all the data related to this field. Do you want to continue?`}
        confirmTitle="Ok"
        onCancel={onCancel}
        onConfirm={onConfirm}
    />
)

const CustomRuleForm = ({kubernetesResources, kubernetesUsersData, isReadOnlyUser}) => {
    const [prevConfirmFieldValue, setPrevConfirmFieldValue] = useState(null);
    const closeConfirmationModal = () => setPrevConfirmFieldValue(null);

    const {values, setFieldValue} = useFormikContext();

    const {resources} = values;
    const resourcesJSON = JSON.stringify(resources);
    const prevResourcesJSON = usePrevious(resourcesJSON);

    const inUseKinds = resources.map(item => item.kind).filter(item => item !== "");
    const resourcesKinds = kubernetesResources.map(item => item.kind);
    const newKinds = inUseKinds.filter(item => !resourcesKinds.includes(item));
    const resourcesKindItems = [
        ...resourcesKinds.map(kind => ({value: kind, label: kind, isDisabled: inUseKinds.includes(kind)})),
        ...newKinds.map(kind => ({value: kind, label: kind, isDisabled: true}))
    ];
    
    const resourcesData = kubernetesResources.reduce((acc, curr) => {
        const {kind, groups} = curr;

        acc[kind] = [ALL_ITEM, ...valueToValueLabel(groups)];

        return acc;
    }, {});

    useEffect(() => {
        if (resourcesJSON === prevResourcesJSON || !prevResourcesJSON) {
            return;
        }

        const prevResources = JSON.parse(prevResourcesJSON);
        const formattedFieldValue = JSON.parse(resourcesJSON).map((item, index, items) => {
            const {kind, group} = item;

            if (kind === ALL_KEY && items.length > 1) {
                setPrevConfirmFieldValue(prevResources);

                return item;
            }

            const prevResourceItem = prevResources.find(item => item.kind === kind);
            const prevGroup = !!prevResourceItem ? prevResourceItem.group : [];

            if (isEqual(prevGroup, group)) {
                return item;
            }
            
            const prevHasAll = !!prevGroup.find(item => item === ALL_KEY);
            const currHasAll = !!group.find(item => item === ALL_KEY);

            let updatedItem = item;
            if (group.length > 1) {
                if (prevHasAll) {
                    //new non All item was added => remove the ALL item:
                    const nonAllgroups = group.filter(name => name !== ALL_KEY);
                    updatedItem = {...updatedItem, group: nonAllgroups};
    
                } else if (currHasAll) {
                    //All item was added => remove the non ALL items:
                    updatedItem = {...updatedItem, group:[ALL_KEY]};
                }
            }

            return updatedItem;
        });
        setFieldValue("resources", formattedFieldValue);

    }, [resourcesJSON, prevResourcesJSON, setFieldValue]);

    return (
        <React.Fragment>
            <UsersField
                name="users"
                label="Users"
                kubernetesUsersData={kubernetesUsersData}
                disabled={isReadOnlyUser}
            />
            <ArrayField
                name="resources"
                label="Resources"
                firstFieldProps={{
                    component: SelectField,
                    key: "kind",
                    placeholder: "kind",
                    creatable: true,
                    clearable: false,
                    items: [
                        ALL_ITEM,
                        ...resourcesKindItems
                    ]
                }}
                secondFieldProps={{
                    component: MultiselectField,
                    key: "group",
                    emptyValue: [],
                    placeholder: "group",
                    creatable: true,
                    items: [],
                    getDependentFieldProps: ({kind}) => ({
                        disabled: kind === ALL_KEY || kind === "",
                        validate: kind === ALL_KEY ? undefined : validators.validateRequired,
                        items: resourcesData[kind] || []
                    })
                }}
                disabled={isReadOnlyUser}
            />
            <MultiselectField
                name="apiActions"
                label="API Actions"
                items={[
                    {value: "CREATE", label: "Create"},
                    {value: "UPDATE", label: "Update"},
                    {value: "DELETE", label: "Delete"},
                    {value: "CONNECT", label: "Connect"}
                ]}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {!isNull(prevConfirmFieldValue) &&
                <ClearFieldConfirmation
                    allLabel={ALL_ITEM.label}
                    onConfirm={() => {
                        setFieldValue("resources", [{kind: ALL_KEY, group: []}]);
                        closeConfirmationModal();
                    }}
                    onCancel={() => {
                        setFieldValue("resources", prevConfirmFieldValue);
                        closeConfirmationModal();
                    }}
                />
            }
        </React.Fragment>
    );
}

const RecommendedRuleForm = ({kubernetesUsersData, isReadOnlyUser}) => (
    <UsersField
        name="excludedUsers"
        label="Excluded Users"
        allItemLabel="None"
        kubernetesUsersData={kubernetesUsersData}
        disabled={isReadOnlyUser}
    />
);

const FormKubernetesApi = ({kubernetesUsers, kubernetesResources, isReadOnlyUser}) => {
    const {values} = useFormikContext();
    const {recommendedRuleId} = values;
    
    const kubernetesUsersData = kubernetesUsers.reduce((acc, curr) => {
        const {userType, users} = curr;

        const subItems = valueToValueLabel(users.map(({userName, userNamespace}) =>
            !!userNamespace ? `${userNamespace}/${userName}` : userName));
        acc[userType] = [ALL_ITEM, ...subItems];

        return acc;
    }, {});

    return (
        <React.Fragment>
            {recommendedRuleId === CUSTOM_RULE_ID ?
                <CustomRuleForm kubernetesUsersData={kubernetesUsersData} kubernetesResources={kubernetesResources} isReadOnlyUser={isReadOnlyUser} /> :
                <RecommendedRuleForm kubernetesUsersData={kubernetesUsersData} isReadOnlyUser={isReadOnlyUser} />
            }
        </React.Fragment>
    )
}

export default FormKubernetesApi;