import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import { useFetch, usePrevious } from 'hooks';
import FormWrapper, { ArrayField, useFormikContext, SelectField, MultiselectField, CheckboxField } from 'components/Form';
import Loader from 'components/Loader';
import Text, { TEXT_TYPES } from 'components/Text';

const EMPTY_OPTION_VALUE = {optionName: "", prefixes: []};

const FormFields = ({gatherInfoOptions}) => {
    const {values, setFieldValue} = useFormikContext();

    const {options, selectAll} = values;
    const prevOptions = usePrevious(options);
    const prevSelectAll = usePrevious(selectAll);

    const inUseOptions = options.map(item => item.optionName).filter(item => item !== "");
    const formattedItems = gatherInfoOptions.sort().map(item => ({value: item, label: item, isDisabled: inUseOptions.includes(item)}));

    useEffect(() => {
        if (prevSelectAll === selectAll) {
            return;
        }

        if (!prevSelectAll && selectAll) {
            const updatedOptions = [
                ...options.filter(item => item.optionName !== ""),
                ...gatherInfoOptions.filter(item => !inUseOptions.includes(item)).map(item => ({optionName: item, prefixes: []}))
            ];
            
            setFieldValue("options", updatedOptions);
        } else if (prevSelectAll && !selectAll) {
            setFieldValue("options", [EMPTY_OPTION_VALUE]);
        }
    }, [selectAll, prevSelectAll, setFieldValue, options, gatherInfoOptions, inUseOptions]);

    useEffect(() => {
        if (isEqual(options, prevOptions)) {
            return;
        }

        if (options.length === 1 && options[0].optionName === "") {
            //on option name clear (only available when single item):
            setFieldValue("options", [EMPTY_OPTION_VALUE]);
        }
    }, [options, prevOptions, setFieldValue]);

    

    return (
        <React.Fragment>
            <CheckboxField name="selectAll" title="Select All" />
            <ArrayField
                name="options"
                firstFieldProps={{
                    component: SelectField,
                    key: "optionName",
                    items: formattedItems,
                    clearable: options.length === 1
                }}
                secondFieldProps={{
                    component: MultiselectField,
                    key: "prefixes",
                    emptyValue: [],
                    creatable: true,
                    items: [],
                    getDependentFieldProps: ({optionName}) => ({
                        disabled: optionName === ""
                    })
                }}
            />
        </React.Fragment>
    )
}

const GatherInformationForm = ({agentId, onDirtyChanage, onFormSubmitSuccess}) => {
    const [{loading, data: gatherInfoOptions}] = useFetch("admin/gatherInfoOptions");

    if (loading) {
        return <Loader />
    }

    const initialValues = {
        selectAll: false,
        options: [EMPTY_OPTION_VALUE]
    };

    return (
        <React.Fragment>
            <Text type={TEXT_TYPES.TITLE_LARGE} withBottomMargin withTopMargin>Gether Information</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl={`admin/agents/gatherInformation/${agentId}`}
                getSubmitParams={formValues => {
                    const submitData = formValues.options.filter(item => item.optionName !== "");
                    
                    return {submitData};
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
                saveButtonTitle="Generate Reports"
            >
                <FormFields gatherInfoOptions={gatherInfoOptions} />
            </FormWrapper>
        </React.Fragment>
    )
}

export default GatherInformationForm;