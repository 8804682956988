import React, { useMemo, useRef, useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import Table from 'components/Table';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import RiskTag from 'components/RiskTag';
import { FUNCTION_RISK_TYPES_MAPPING, getSecretsRisk, getUnusedRisk } from 'layout/Serverless/utils';
import VulnerabilitiesSummary from 'components/VulnerabilitiesSummary';
import RuleActionDisplay from 'components/RuleActionDisplay';
import { SYSTEM_RISKS, SECRET_FINDINGS_RISKS } from 'utils/systemConsts';
import { formatDate } from 'utils/generalUtils';
import { FiltersForm, FilterContext } from '../Filter';
import FunctionNameDisplay from './FunctionNameDisplay';

import './functions-table.scss';

const SORT_KEYS = {
    RISK: "risk"
}

const FunctionsTable = () => {
    const {path} = useRouteMatch();
    const history = useHistory();

    const columns = useMemo(() => [
        {
            Header: 'Function name',
            id: "name",
            Cell: ({row}) => {
                const {name, cloudAccount} = row.original;

                return (
                    <FunctionNameDisplay functionName={name} withIcon={true} provider={cloudAccount?.cloudProvider} />
                );
            },
            defaultCanSort: true,
            alwaysShow: true
        },
        {
            Header: "Function Risk",
            Cell: ({row}) => {
                const {risk} = row.original;
                const {functionRisk: riskLevel} = risk;

                return (
                    <RiskTag risk={riskLevel} />
                )
            },
            id: SORT_KEYS.RISK,
            defaultCanSort: true,
            width: 140
        },
        {
            Header: "Security Threats",
            id: "securityThreats",
            Cell: ({row}) => {
                const {id: rowId, original} = row;
                const {secretsRisk, policyRisk, publiclyAccessibleRisk, isUnusedFunction, dataAccessRisk, violation} = original;

                const threatsItems = [
                    {
                        type: FUNCTION_RISK_TYPES_MAPPING.PUBLICLY_ACCESSIBLE.value,
                        text: "Publicly accessible",
                        disabledText: "Function isn't publicly accessible",
                        risk: publiclyAccessibleRisk
                    },
                    {
                        type: FUNCTION_RISK_TYPES_MAPPING.POLICY.value,
                        text: "Permissions",
                        disabledText: "No permissions risks",
                        risk: policyRisk
                    },
                    {
                        type: FUNCTION_RISK_TYPES_MAPPING.SECRETS.value,
                        text: "Secrets",
                        disabledText: "No secret risks",
                        risk: getSecretsRisk(secretsRisk)
                    },
                    {
                        type: FUNCTION_RISK_TYPES_MAPPING.UNUSED_FUNCTION.value,
                        text: "Unused function",
                        risk: getUnusedRisk(isUnusedFunction)
                    },
                    {
                        type: FUNCTION_RISK_TYPES_MAPPING.DATA_ACCESS.value,
                        text: "Data access",
                        risk: dataAccessRisk
                    },
                    {
                        type: FUNCTION_RISK_TYPES_MAPPING.UNIDENTIFIED.value,
                        text: "Function is not identified",
                        risk: !!violation?.unidentifiedServerlessRule?.action ? SYSTEM_RISKS.LOW.value : null
                    }
                ];

                return (
                    <div className="security-threats-container">
                        {
                            threatsItems.map(({type, icon, text, disabledText, risk}) => {
                                const iconTooltipId = `tooltip-${type}-${rowId}`;

                                const disableIcon = isEmpty(risk) || risk === SYSTEM_RISKS.NO_RISK.value || risk === SECRET_FINDINGS_RISKS.NO_KNOWN_RISK.value;

                                return (
                                    <React.Fragment key={type}>
                                        <div data-tip data-for={iconTooltipId}>
                                            <Icon
                                                name={!!icon ? icon : FUNCTION_RISK_TYPES_MAPPING[type].icon}
                                                className={classnames("global-risk-color", {[risk?.toLowerCase()]: !disableIcon && !!risk})}
                                                disabled={disableIcon}
                                            />
                                        </div>
                                        {(!disableIcon || !!disabledText) &&
                                            <Tooltip id={iconTooltipId} placement="top" text={disableIcon ? disabledText : text}/>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                )
            },
            disableSortBy: true,
            alwaysShow: true,
            width: 230
        },
        {
            Header: "Vulnerabilities",
            id: "vulnerabilities",
            Cell: ({row}) => {
                const {id, vulnerabilitiesSummary: summaryData} = row.original;
                const {total=0, critical=0, high=0, medium=0, low=0, unknown=0} = summaryData || {};
                const summaryProps = {id, total, critical, high, medium, low, unknown};

                return (
                    <VulnerabilitiesSummary {...summaryProps} />
                );
            },
            width: 350
        },
        {
            Header: "Runtime",
            accessor: original => original.runtime,
            id: "runtime"
        },
        {
            Header: "Version",
            accessor: original => original.version,
            id: "version"
        },
        {
            Header: "Cloud Account Name",
            accessor: original => original.cloudAccount.name,
            id: "cloudAccountName"
        },
        {
            Header: "Region",
            accessor: original => original.region,
            id: "region"
        },
        {
            Header: "Provider",
            accessor: original => original.cloudAccount.cloudProvider,
            id: "provider"
        },
        {
            Header: "Last scanned",
            id: "lastScanned",
            accessor: original => formatDate(original.cloudAccount.lastScanned),
            hide: true
        },
        {
            Header: "Result",
            id: "result",
            Cell: ({row}) => {
                const {ruleAction} = row.original.violation || {};

                return (
                    <RuleActionDisplay action={ruleAction} />
                );
            }
        }
    ], []);

    const location = useLocation();
    const {filters: queryFilters} = location.query || {};

    const filters = FilterContext.useFilterState();
    const filterDispatch = FilterContext.useFilterDispatch();
    
    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    return (
        <div className="functions-page">
            <div className="table-filters-container">
                <FiltersForm
                    filters={filters}
                    queryFilters={queryFilters}
                    setFilters={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }
                        
                        FilterContext.setFilters(filterDispatch, updatedFilters);
                    }}
                />
            </div>
            <Table
                url="serverless/functions"
                name="functions-page"
                columns={columns}
                filters={filters}
                defaultSortBy={[{id: SORT_KEYS.RISK, desc: true}]}
                exportToExcel={true}
                onLineClick={({id}) => history.push(`${path}/${id}`)}
                isLoading={filters.filterLoading}
            />
        </div>
    )
}

export default FunctionsTable;