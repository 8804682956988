import React, { useRef, useState, useEffect } from "react";
import { isEqual } from "lodash";
import { CLUSTER_ORCHESTRATION_TYPES, IN_PROGRESS_CIS_SCAN_STATES } from "utils/systemConsts";
import { formatDate } from "utils/generalUtils";
import IconWithTitle from "components/IconWithTitle";
import Icon, { ICON_NAMES } from "components/Icon";
import { KeyValueItem } from "components/KeyValueList";
import { TooltipWrapper } from "components/Tooltip";
import InfoIcon from "components/InfoIcon";
import { usePrevious, useFetch } from "hooks";
import { ScanResultsCount, ActionItemsStatusIcon } from '../utils';
import ScanProgressBar from '../ScanProgressBar';

import COLORS from 'utils/scss_variables.module.scss';

const getCompareData = ({scanState, numberOfNodes, numberOfScannedNodes}) => 
	({scanState, numberOfNodes, numberOfScannedNodes});

const ScanProgressDisplay = ({url, updateTable, initialData}) => {
	const fetcherRef = useRef(null);

	useEffect(() => {
		return function cleanup() {
			if (fetcherRef.current) {
				clearTimeout(fetcherRef.current);
			}
		};
	}, []);

	const [displayData, setDisplayData] = useState(initialData);

	const [{loading, data}, pollData] = useFetch(url, {});
	const prevLoading = usePrevious(loading);

	const {scanState, numberOfNodes, numberOfScannedNodes} = displayData || {};

	useEffect(() => {
		if (prevLoading && !loading) {
			setDisplayData(data || {});

			if (fetcherRef.current) {
				clearTimeout(fetcherRef.current);
			}
			
			if (IN_PROGRESS_CIS_SCAN_STATES.includes(data.scanState)) {
				fetcherRef.current = setTimeout(pollData, 4000);
			}
		}
	}, [prevLoading, data, loading, pollData]);

	const tooptipText = (
        <span>New scan result available. <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={updateTable}>Update</span></span>
    );

	return (
		<KeyValueItem
			label={(
				<>
					<span>Scan progress</span>
					{!isEqual(getCompareData(initialData), getCompareData(displayData)) &&
						<TooltipWrapper id="cis-benchmark-update-tooltip" text={tooptipText} clickable delayHide={300} style={{marginLeft: "5px"}}>
							<Icon name={ICON_NAMES.ALERT} style={{color: COLORS["color-main-light"], minWidth: "14px", width: "14px"}} />
						</TooltipWrapper>
					}
				</>
			)}
			value={(
				<ScanProgressBar
					scanState={scanState}
					numberOfNodes={numberOfNodes || 0}
					numberOfScannedNodes={numberOfScannedNodes || 0}
				/>
			)}
		/>
	)
}

const ClusterDetailsSummery = ({ clusterData, url, updateTable }) => {
	const {
		scanTime,
		kubernetesVersion,
		compliance,
		numberOfItemsPassed,
		numberOfItemsFailed,
		numberOfActionItems,
		numberOfInfoItems,
		orchestration
	} = clusterData || {};
	
	return (
		<div style={{display: "flex", width: "1100px", marginTop: "10px"}}>
			<KeyValueItem
				label="Scan Time"
				value={formatDate(scanTime)}
			/>
			<KeyValueItem
				label="Compliance"
				value={(
					<div style={{display: "flex", alignItems: "center"}}>
						${compliance || 0}%
						<InfoIcon
							tooltipId="cis-benchmark-compliance-tooltip"
							text={(
								<span>
									Percentage of tests passed, out of the total number of tests that either passed or failed.<br />
									Only tests that have passed or failed benchmark assessment are counted toward the compliance percentage.
								</span>
							)}
						/>
					</div>
				)}
			/>
			<KeyValueItem
				label="Scan results"
				value={(
					<ScanResultsCount
						failedCount={numberOfItemsFailed}
						passedCount={numberOfItemsPassed}
						infoCount={numberOfInfoItems}
					/>
				)}
			/>
			<KeyValueItem
				label="Action items"
				value={(
					<ActionItemsStatusIcon
						count={numberOfActionItems}
						tooltipText="Action items tests will only be calculated in the compliance percentage after pass/fail mark"
						tooltipId="clutser-details-summary-action-items"
					/>
				)}
			/>
			<ScanProgressDisplay url={url} updateTable={updateTable} initialData={clusterData} />
			<KeyValueItem
				label="Orchestration"
				value={!orchestration ? null : (
					<a
						href={CLUSTER_ORCHESTRATION_TYPES[orchestration]?.link}
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconWithTitle
							name={ICON_NAMES.EXTERNAL_LINK}
							title={CLUSTER_ORCHESTRATION_TYPES[orchestration]?.label}
							reverseOrder
						/>
					</a>
				)}
			/>
			<KeyValueItem
				label="Kubernetes version"
				value={kubernetesVersion}
			/>
		</div>
	)
};

export default ClusterDetailsSummery;
