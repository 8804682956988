import React from 'react';
import { NotAvailable } from './utils';

const CVSSSection = ({label, items = [], data = {}}) => (
    <React.Fragment>
        <div className="section-label">{label}</div>
        <div className="section-items">
            {
                items.map(({label, key, map}) => {
                    const isValid = data && data[key] && map[data[key]];

                    return (
                        <div key={key} className="section-item">
                            <div className="item-label">{label}</div>
                            <div className="item-value">{isValid ? map[data[key]] : <NotAvailable/>}</div>
                        </div>
                    )
                })
            }
        </div>
    </React.Fragment>
)

export default CVSSSection;