import React, { useEffect, useCallback } from 'react';
import { FETCH_METHODS, useFetch, usePrevious } from 'hooks';
import FormWrapper, { validators, TextField, SelectField, useFormikContext, ArrayField } from 'components/Form';
import Loader from 'components/Loader';
import Text, { TEXT_TYPES } from 'components/Text';
import { nameIdToValueLabel } from 'utils/apiUtils';

const MIN_PORT = 0;
const MAX_PORT = 65535;
const ERROR_MSG = "Invalid IP(/FQDN):port";
const ipPortValidator = (value) => {
    if (value === "") {
        return null;
    }

    const [fqdn, port] = value.split(":");

    const portNumber = Number(port);
    if (isNaN(portNumber) || port < MIN_PORT || port > MAX_PORT) {
        return ERROR_MSG;
    }

    return !!fqdn ? null : ERROR_MSG; //not validating the ip/fqdn
}

const FormFields = ({isEditForm, cluseterItems}) => {
    const {values: formValues} = useFormikContext();

    const [{loading, data: clusterNamespaces}, loadNamespacesData] = useFetch("kubernetesClusters", {loadOnMount: false});

    const loadClusterNamespacesData = clusterId => loadNamespacesData({formatUrl: url => `${url}/${clusterId}/namespaces`});
    const doLoadClusterNamespacesData = useCallback(loadClusterNamespacesData, [loadNamespacesData]);
    
    const {clusterId} = formValues;
    const prevClusterId = usePrevious(clusterId);

    useEffect(() => {
        if (!!clusterId && clusterId !== prevClusterId) {
            doLoadClusterNamespacesData(clusterId);
        }
    }, [clusterId, prevClusterId, doLoadClusterNamespacesData]);

    return (
        <React.Fragment>
            <TextField name="name" label="Name" validate={validators.validateRequired} />
            <ArrayField
                name="labels"
                label="Labels"
                firstFieldProps={{
                    component: TextField,
                    key: "key",
                    placeholder: "Key",
                    validate: validators.validateRequired
                }}
                secondFieldProps={{
                    component: TextField,
                    key: "value",
                    placeholder: "Value",
                    validate: validators.validateRequired
                }}
            />
            <SelectField 
                name="clusterId"
                label="Cluster"
                items={cluseterItems}
                disabled={isEditForm}
                validate={validators.validateRequired}
                clearable={false}
            />
            <SelectField
                name="namespaceId"
                label="Namespace"
                items={nameIdToValueLabel(clusterNamespaces || [])}
                disabled={isEditForm || !clusterId}
                validate={validators.validateRequired}
                clearable={false}
                loading={loading}
            />
            <ArrayField
                name="workloadAddresses"
                label="Workload Addresses"
                firstFieldProps={{
                    component: SelectField,
                    key: "networkProtocol",
                    validate: validators.validateRequired,
                    clearable: false,
                    items: [
                        {value: "TCP", label: "TCP"},
                        {value: "HTTP", label: "HTTP"}
                    ]
                }}
                secondFieldProps={{
                    component: TextField,
                    key: "address",
                    placeholder: "address",
                    validate: value => {
                        const requiredError = validators.validateRequired(value);

                        if (!!requiredError) {
                            return requiredError;
                        }

                        return ipPortValidator(value);
                    }
                }}
            />
        </React.Fragment>
    )
}

const ExpansionForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const [{loading, data: clusters, error}] = useFetch("leanKubernetesClusters");

    if (loading) {
        return <Loader absolute={false} />;
    }

    if (error) {
        return null;
    }

    const initialValues = {
        name: "",
        clusterId: "",
        namespaceId: "",
        labels: [{key: "", value: ""}],
        workloadAddresses: [{networkProtocol: "", address: ""}],
        ...initialData
    };
    
    const isEditForm = initialValues && initialValues.id;
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{`${isEditForm ? "Edit" : "New"} Expansion`}</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl="expansions"
                getSubmitParams={formValues => {
                    const {id, ...submitData} = formValues;

                    submitData.labels = submitData.labels.filter(item => item.key !== "" && item.value !== "");
                    submitData.workloadAddresses =
                        submitData.workloadAddresses.filter(item => item.networkProtocol !== "" && item.address !== "");
                        
                    if (!isEditForm) {
                        return {submitData};
                    }

                    delete submitData.clusterId;
                    delete submitData.namespaceId;
                    delete submitData.instanceId;

                    return {
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <FormFields
                    isEditForm={isEditForm}
                    cluseterItems={nameIdToValueLabel(clusters, item => !item.kubernetesSecurity)}
                />
            </FormWrapper>
        </div>
    )
}

export default ExpansionForm;