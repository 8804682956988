import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { SelectField, TextField } from 'components/Form';
import Table, { FilterFormWrapper } from 'components/Table';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';
import { PermissionsVulnerabilitiesSummary, PERMISSIONS_PAGE_PATH, getPermissionsInfoCountByRisk,
    PERMISSIONS_RISK_ITEMS, BREADCRUMBS_TITLE, FILTER_KEYS, SYSTEM_OWNER_TYPE_MAP } from './utils';
import SystemOwnersToggle from './SystemOwnersToggle';

const PERMISSIONS_COLUMN_ID = "permissionRisk";
const OWNER_TYPES_MAP = {
    USER: "User",
    GROUP: "Group",
    SERVICEACCOUNT: "Service Account"
};

const ClusterPermissions = () => {
    const columns = useMemo(() => [
        {
            Header: "Owner",
            id: "owner",
            Cell: ({row}) => {
                const {owner, id, systemDefaultOwnerType} = row.original;
                const tooltipId = `owner-system-permissions-${id}`;
                const {icon, ownerTitle} = SYSTEM_OWNER_TYPE_MAP[systemDefaultOwnerType];

                return (
                    <div className="cluster-permissions-owner">
                        <div data-tip data-for={tooltipId}><Icon name={icon} /></div>
                        <div>{owner}</div>
                        <Tooltip id={tooltipId} text={ownerTitle} placement="top" />
                    </div>
                )
            },
            alwaysShow: true
        },
        {
            Header: "Owner Type",
            id: "ownerType",
            accessor: d => OWNER_TYPES_MAP[d.ownerType]
        },
        {
            Header: "Namespace",
            id: "namespace",
            accessor: "namespace",
            disableSortBy: true
        },
        {
            Header: "Permissions",
            id: PERMISSIONS_COLUMN_ID,
            Cell: ({row}) => {
                const {permissionInfo, id} = row.original;
                const high = getPermissionsInfoCountByRisk(permissionInfo, PERMISSIONS_RISK_ITEMS.HIGH.value);
                const medium = getPermissionsInfoCountByRisk(permissionInfo, PERMISSIONS_RISK_ITEMS.MEDIUM.value);
                const no_risk = getPermissionsInfoCountByRisk(permissionInfo, PERMISSIONS_RISK_ITEMS.NO_RISK.value);
                const approved = getPermissionsInfoCountByRisk(permissionInfo, PERMISSIONS_RISK_ITEMS.APPROVED.value);
                const total = high + medium + no_risk + approved;
                
                return (
                    <PermissionsVulnerabilitiesSummary
                        total={total}
                        high={high}
                        medium={medium}
                        no_risk={no_risk}
                        approved={approved}
                        id={id}
                    />
                );
            },
            defaultCanSort: true,
            minWidth: 250
        }
    ], []);

    const [clusterName, setClusterName] = useState(null);
    const nameIsSetRef = useRef(false);

    const history = useHistory();

    const location = useLocation();
    const {filters: queryFilters} = location.query || {};

    const params = useParams();
    const {clusterId} = params;

    const {permissionsIncludeSystemOwners: includeSystemOwners} = useDisplayConfigState();

    const [filters, setFilters] = useState({
        [FILTER_KEYS.PERMISSION_RISK]: "",
        [FILTER_KEYS.OWNER]: "",
        ownerType: "",
        includeSystemOwners, ...queryFilters
    });
    
    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            return;
        }

        setFilters(filters => ({...filters, includeSystemOwners}));
    }, [includeSystemOwners]);

    return (
        <div className="permissions-page">
            <BreadcrumbsHeader
                title={BREADCRUMBS_TITLE}
                baseUrl={PERMISSIONS_PAGE_PATH}
                backPath="/"
                breadcrumbs={[
                    {title: `${clusterName} cluster`}
                ]}
                hideBreadcrumbs={!clusterName}
            />
            <FilterFormWrapper
                initialValues={{...filters}}
                onChange={updatedFilters => {
                    if (!mounted.current) {
                        return;
                    }

                    setFilters(updatedFilters);
                }}
            >
                <TextField name={FILTER_KEYS.OWNER} label="Owner" />
                <TextField name="ownerType" label="Owner Type" />
                <SelectField
                    name={FILTER_KEYS.PERMISSION_RISK}
                    label="Permission Risk"
                    items={[
                        {value: "", label: "All"},
                        ...Object.values(PERMISSIONS_RISK_ITEMS)
                    ]}
                    clearable={false}
                    small
                />
            </FilterFormWrapper>
            <SystemOwnersToggle />
            <Table
                url={`riskAssessment/permissions/${clusterId}`}
                name="cluster-risk-assessment-permissions"
                columns={columns}
                filters={filters}
                defaultSortBy={[{id: PERMISSIONS_COLUMN_ID, desc: true}]}
                onLineClick={({id}) => history.push({pathname: `${PERMISSIONS_PAGE_PATH}/${clusterId}/${id}`})}
                onFetchError={() => history.push({pathname: PERMISSIONS_PAGE_PATH})}
                formatFetchedData={data => {
                    const {owners} = data || {};

                    return owners;
                }}
                onFetchedDataChange={data => {
                    const {clusterName} = data || {};

                    if (!!clusterName && !nameIsSetRef.current) {
                        setClusterName(clusterName);
                        nameIsSetRef.current = true;
                    }
                }}
            />
        </div>
    )
}

export default ClusterPermissions;