import React from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import classnames from 'classnames';
import { dataMultiFetcher, asyncDataFetcher } from 'utils/apiUtils';
import { useNotificationDispatch, showNotification, NOTIFICATION_TYPES } from 'context/NotificationProvider';

import './search-field-select.scss';

const SearchFieldSelect = (props) => {
    const {selected, name, className, placeholder="Select...", disabled, labelComponent: LabelComponent,
        getUrlsData, url, getQueryParams, formatData, onChange} = props;

    const notificationDispatch = useNotificationDispatch();
    const showErrorToaster = () => {
        showNotification(notificationDispatch, {
            message: "An error occurred when trying to load data",
            type: NOTIFICATION_TYPES.ERROR
        });
    }

    const promiseOptions = inputValue => {
        const isSingleFetch = !!url;
        const fetcher = isSingleFetch ? asyncDataFetcher : dataMultiFetcher;
        
        return new Promise (resolve => fetcher({
            url, // asyncDataFetcher
            queryParams: isSingleFetch ? getQueryParams(inputValue) : null, //asyncDataFetcher
            urlsData: isSingleFetch ? null : getUrlsData(inputValue), //dataMultiFetcher
            successCallback: (data) => {
                resolve(formatData(data, inputValue));
            },
            errorCallback: (error) => {
                showErrorToaster();

                resolve([]);
            },
            authenticationErrorCallback: () => {
                showErrorToaster();

                resolve([]);
            }
        }))
    }

    return (
        <AsyncSelect
            value={selected}
            name={name}
            className={classnames("search-field-select", className)}
            classNamePrefix="search-field-select"
            onChange={selected => onChange(selected)}
            loadOptions={promiseOptions}
            placeholder={placeholder}
            formatGroupLabel={group => <div className="search-group-label">{group.label}</div>}
            getOptionLabel={option => !!LabelComponent ? <LabelComponent {...option} /> : option}
            isClearable={true}
            isDisabled={disabled}
            noOptionsMessage={({inputValue}) => {
                if (inputValue === "") {
                    return placeholder;
                }
                
                return "No options";
            }}
            components={{
                SingleValue: ({data,...props}) =>
                    <components.SingleValue {...props}>{data.label}</components.SingleValue>
            }}
        />
    );
}

export default SearchFieldSelect;