import React from 'react';
import { TextField, validators, TextAreaField } from 'components/Form';

const FormStep1 = () => {
    return (
        <React.Fragment>
            <TextField name="name" label="Environment Name" validate={validators.validateRequired} />
            <TextAreaField name="description" label="Description" />
        </React.Fragment>
    )
}

export default FormStep1;