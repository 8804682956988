import React, { Component } from 'react';
import Tabs from 'components/Tabs';

import './tabbed-page-no-routing.scss';

class TabbedPageNoRouting extends Component {
    state = {
        selected: this.props.defaultSelected || this.props.items[0].id
    }

    getSelectedItem = (selected) => this.props.items.find(item => item.id === selected)

    onTabClick = (selected) => {
        this.setState({selected}, () => {
            const {menual, onChange} = this.props;
            if (menual) {
                onChange(selected);
            }
        })
    }

    render() {
        const {items, data, className, defaultSelected, menual, embedded, secondary} = this.props;
        const selected = menual ? defaultSelected : this.state.selected;
        const ContentComponenet = this.getSelectedItem(selected).component;
        
        return (
            <div className={className}>
                <Tabs
                    items={items}
                    isRouter={false}
                    defaultSelected={selected}
                    onChange={this.onTabClick}
                    embedded={embedded}
                    secondary={secondary}
                />
                <div className="tab-content">
                    <ContentComponenet data={data} />
                </div>
            </div>
        );
    }
}

export default TabbedPageNoRouting;