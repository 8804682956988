import React from 'react';
import { isEmpty, pickBy } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { useFetch } from 'hooks';
import Loader from 'components/Loader';
import Wizard from 'components/Wizard';
import { valueToValueLabel } from 'utils/apiUtils';
import { POLICY_TYPE } from '../utils';
import FormRuleProperties from './FormRuleProperties';
import FormFunctions, { RULE_TYPE_ITEMS, ALL_KEY } from './FormFunctions';

const UserRuleForm = ({initialData, onDirtyChanage, onDone}) => {
    const {isReadOnlyUser} = useAuthState();

    const [{loading, data: cloudAccounts, error}] = useFetch("cloudAccounts", {queryParams: {noPagination: true}});
    
    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const isEditForm = !!initialData && initialData.id;

    const initialValues = {
        name: "",
        action: RULE_ACTIONS.ALLOW,
        ...(initialData || {}),
    };

    initialValues.rule = {
        serverlessRuleType: RULE_TYPE_ITEMS.NAME.value,
        names: [],
        arns: [],
        ...(initialValues.rule || {})
    }

    initialValues.rule.serverlessFunctionValidation = {
        risk: "",
        vulnerability: "",
        secretsRisk: "",
        policyRisk: "",
        publiclyAccessibleRisk: "",
        dataAccessRisk: "",
        isUnusedFunction: "",
        ...(initialValues.rule.serverlessFunctionValidation || {})
    }

    initialValues.scope = isEmpty(initialValues.scope) ? [{cloudAccount: ALL_KEY, regions: []}] : initialValues.scope;
    initialValues.scope = initialValues.scope.map(scopeItem => ({...scopeItem, regions: isEmpty(scopeItem.regions) ? [ALL_KEY] : scopeItem.regions}))

    if (!isEmpty(initialValues.rule.names)) {
        initialValues.rule.names = valueToValueLabel(initialValues.rule.names); //AsyncSelectField needs [{value,label}] initital value
    }

    if (!isEmpty(initialValues.rule.arns)) {
        initialValues.rule.arns = valueToValueLabel(initialValues.rule.arns); //AsyncSelectField needs [{value,label}] initital value
    }

    const ruleTitle = `${POLICY_TYPE} Rule`;
    
    return (
        <Wizard
            className="deployment-policy-user-rule-form"
            initialValues={initialValues}
            title={isReadOnlyUser ? `${ruleTitle} (Audit mode)` : `${isEditForm ? "Edit" : "New"} ${ruleTitle}`}
            disableSubmit={isReadOnlyUser}
            steps={[
                {
                    title: "Rule Properties",
                    component: FormRuleProperties
                },
                {
                    title: "Functions",
                    component: FormFunctions
                }
            ]}
            formProps={{
                cloudAccounts,
                isReadOnlyUser
            }}
            doCustomSubmit={formValues => {
                formValues.rule.names = formValues.rule.names.map(item => item.value); //format AsyncSelectField value from [{value, label}] to [value]
                formValues.rule.arns = formValues.rule.arns.map(item => item.value); //format AsyncSelectField value from [{value, label}] to [value]

                if (isEmpty(formValues.rule.names)) {
                    delete formValues.rule.names;
                }

                if (isEmpty(formValues.rule.arns)) {
                    delete formValues.rule.arns;
                }

                formValues.rule.serverlessFunctionValidation = pickBy(formValues.rule.serverlessFunctionValidation, value => value !== "");

                formValues.scope = formValues.scope.map(item => ({...item, regions: item.regions.includes(ALL_KEY) ? [] : item.regions})); //clean "ALL" regions

                if (formValues.scope.length === 1 && formValues.scope[0].cloudAccount === ALL_KEY) { //  clean "ALL" scope
                    formValues.scope = null;
                }
                
                onDone(formValues);
            }}
            onDirtyChanage={onDirtyChanage}
        />
    );
};

export default UserRuleForm;