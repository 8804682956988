import React from 'react';
import { RULE_ACTIONS, IGNORE_ACTION_ITEM } from 'utils/systemConsts';
import { useFormikContext, validators, RadioField, TextField } from 'components/Form';
import { CUSTOM_RULE_ID } from './utils';

const FormRuleProperties = ({isReadOnlyUser}) => {
    const {values} = useFormikContext();
    const {recommendedRuleId} = values;
    const isCustom = recommendedRuleId === CUSTOM_RULE_ID;

    const actionItems = [
        {
            value: RULE_ACTIONS.DETECT,
            label: "Detect Kubernetes APIs invoked on the specified environments"
        },
        {
            value: RULE_ACTIONS.BLOCK,
            label: "Block Kubernetes APIs from being invoked on the specified environments"
        }
    ];

    if (isCustom) {
        actionItems.push({
            value: IGNORE_ACTION_ITEM.value,
            label: "Ignore Kubernetes APIs from being invoked on the specified environments"
        });

        actionItems.unshift({
            value: RULE_ACTIONS.ALLOW,
            label: "Allow Kubernetes APIs to be invoked on the specified environments"
        });
    }

    return (
        <React.Fragment>
            <TextField
                name="name"
                label="Rule Name"
                validate={validators.validateRequired}
                disabled={!isCustom || isReadOnlyUser}
            />
            <RadioField
                name="action"
                label="Action"
                items={actionItems}
                horizontal={true}
                disabled={isReadOnlyUser}
            />
        </React.Fragment>
    )
}

export default FormRuleProperties;