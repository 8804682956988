import React from 'react';
import { isEmpty } from 'lodash';
import { useFetch, FETCH_METHODS } from 'hooks';
import Wizard from 'components/Wizard';
import Loader from 'components/Loader';
import FormGeneralDetails from './FormGeneralDetails';
import FormPspProperties, { EMPTY_RANGE_ITEM } from './FormPspProperties';
import { DEFAULT_RULE } from './utils';

import './psp-profile-form.scss';

const isNotEmptyMinMax = item => !!item || String(item) === "0";
const formatRange = (ranges) => 
    ranges.filter(({min, max}) => isNotEmptyMinMax(min) && isNotEmptyMinMax(max));
const formatRuleMinMaxField = ({rule, ranges=[]}) => ({rule, ranges: formatRange(ranges)});

const PspProfileForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const [{loading, data, error}] = useFetch("seccompProfiles");

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const isEditForm = !!initialData && initialData.id;
    const wizardSteps = [
        {
            title: "General Details",
            component: FormGeneralDetails
        },
        {
            title: "Pod Security Standards Properties",
            component: FormPspProperties
        }
    ];

    
    const initialValues = {
        name: "",
        description: "",
        privileged: false,
        readOnlyRootFileSystem: false,
        allowPrivilegeEscalation: false,
        defaultAllowPrivilegeEscalation: false,
        hostNetwork: false,
        hostPID: false,
        hostIPC: false,
        volumes: [],
        requiredDropCapabilities: [],
        allowedCapabilities: [],
        allowedProcMountTypes: [],
        forbiddenSysctls: [],
        allowedUnsafeSysctls: [],
        ...initialData
    }

    const initialRunAsUser = initialValues.runAsUser || {};
    initialValues.runAsUser = {
        rule: initialRunAsUser.rule || DEFAULT_RULE,
        ranges: isEmpty(initialRunAsUser.ranges) ? [EMPTY_RANGE_ITEM] : initialRunAsUser.ranges
    };

    const initialFsGroup = initialValues.fsGroup || {};
    initialValues.fsGroup = {
        rule: initialFsGroup.rule || DEFAULT_RULE,
        ranges: isEmpty(initialFsGroup.ranges) ? [EMPTY_RANGE_ITEM] : initialFsGroup.ranges
    };

    const initialSupplementalGroups = initialValues.supplementalGroups || {};
    initialValues.supplementalGroups = {
        rule: initialSupplementalGroups.rule || DEFAULT_RULE,
        ranges: isEmpty(initialSupplementalGroups.ranges) ? [EMPTY_RANGE_ITEM] : initialSupplementalGroups.ranges
    };

    const initialRunAsGroup = initialValues.runAsGroup || {};
    initialValues.runAsGroup = {
        rule: initialRunAsGroup.rule || DEFAULT_RULE,
        ranges: isEmpty(initialRunAsGroup.ranges) ? [EMPTY_RANGE_ITEM] : initialRunAsGroup.ranges
    };

    initialValues.seccompProfile = initialValues.seccompProfile || ""; //form field must not be null
    initialValues.hostPorts = isEmpty(initialValues.hostPorts) ? [EMPTY_RANGE_ITEM] : initialValues.hostPorts;
    initialValues.allowedHostPaths = isEmpty(initialValues.allowedHostPaths) ? [{pathPrefix: "", readOnly: false}] : initialValues.allowedHostPaths;
    
    return (
        <Wizard
            className="psp-profile-form"
            initialValues={initialValues}
            title={`${isEditForm ? "Edit" : "New"} Pod Security Standards Profile`}
            steps={wizardSteps}
            submitUrl="podSecurityPolicyProfiles"
            formProps={{
                seccompItems: data.map(({id, name}) => ({value: id, label: name}))
            }}
            getSubmitParams={formValues => {
                const {id, ...submitData} = formValues;

                submitData.runAsUser = formatRuleMinMaxField(submitData.runAsUser);
                submitData.fsGroup = formatRuleMinMaxField(submitData.fsGroup);
                submitData.supplementalGroups = formatRuleMinMaxField(submitData.supplementalGroups);
                submitData.runAsGroup = !!submitData.runAsGroup ? formatRuleMinMaxField(submitData.runAsGroup) : null;
                submitData.hostPorts = formatRange(submitData.hostPorts || []);

                delete submitData.isSecurecnDefaultProfile;

                return !isEditForm ? {submitData} : {
                    method: FETCH_METHODS.PUT,
                    formatUrl: url => `${url}/${id}`,
                    submitData
                }
            }}
            onSubmitSuccess={onFormSubmitSuccess}
            onDirtyChanage={onDirtyChanage}
        />
    );
}

export default PspProfileForm;