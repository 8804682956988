import React from 'react';
import { isEmpty, isNull } from 'lodash';
import SearchFieldSelect from 'components/SearchFieldSelect';

import './search-policy-field.scss';

const SEARCH_TYPES = {
    ENV: "EnvPolicyFilter",
    POD: "PodPolicyFilter"
}

const SearchItem = ({label, searchData}) => {
    const {filterType, labels} = searchData;
    const displayLabels = filterType === SEARCH_TYPES.POD && !isEmpty(labels);

    return (
        <div className="search-field-item">
            <div className="item-title">{label}</div>
            {displayLabels &&
                <div className="item-labels">
                    {labels.map(({key, value}) =>
                        <div key={key} className="item-label">{`${key}:${value}`}</div>)}
                </div>}
        </div>
    );
}

const SearchPolicyField = ({disabled, selectedSearch, onSelectSearch}) => (
    <SearchFieldSelect
        className="policy-search-field"
        url="appsPolicy/searchOptions"
        name="policySearch"
        placeholder="Search for Deployment/Pod"
        onChange={selected => {
            const value = isNull(selected) ? selected : JSON.stringify(selected.searchData);
            if (value !== selectedSearch) {
                onSelectSearch(value);
            }
        }}
        disabled={disabled}
        formatData={data => ([
            {
                label: "Pods",
                options: data.pods.map(({name, labels}) => ({
                    value: name,
                    label: name,
                    searchData: {filterType: SEARCH_TYPES.POD, name, labels}
                }))
            },
            {
                label: "Environments",
                options: data.envs.map(name => ({
                    value: name,
                    label: name,
                    searchData: {filterType: SEARCH_TYPES.ENV, name}
                }))
            }
        ])}
        getQueryParams={searchValue => ({nameFilter: searchValue})}
        labelComponent={SearchItem}
    />
);

export default SearchPolicyField;