import React, { useEffect } from 'react';
import { isUndefined } from 'lodash';
import { usePrevious } from 'hooks';
import { useFormikContext, validators, MultiselectField, SelectField, RadioField } from 'components/Form';
import { RULE_SCOPE_ITEMS } from './utils';

export const ENVIRONMENT_BY_ITEMS = {
    NAME: {
        value: "NameEnvironmentRuleType",
        label: "By Name"
    },
    ANY: {
        value: "AnyEnvironmentRuleType",
        label: "Any"
    }
};

const EnvironmentForm = ({environmentNames, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {environment} = values.ruleScope;
    const {environmentRuleType} = environment || {};
    const prevEnvironmentRuleType = usePrevious(environmentRuleType);

    useEffect(() => {
        if (!environmentRuleType) {
            setFieldValue("ruleScope.environment.environmentRuleType", ENVIRONMENT_BY_ITEMS.NAME.value);
        }

        if (prevEnvironmentRuleType === environmentRuleType || !prevEnvironmentRuleType) {
            return;
        }

        setFieldValue("ruleScope.environment.names", []);
    }, [prevEnvironmentRuleType, environmentRuleType, setFieldValue]);

    return (
        <React.Fragment>
            <RadioField 
                name="ruleScope.environment.environmentRuleType"
                items={Object.values(ENVIRONMENT_BY_ITEMS)}
                horizontal={true}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {environmentRuleType === ENVIRONMENT_BY_ITEMS.NAME.value &&
                <MultiselectField
                    name="ruleScope.environment.names"
                    items={environmentNames}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />
            }
        </React.Fragment>
    );
}

const FormRuleScope = ({clusterNames, environmentNames, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {ruleScope, isClusterScopeOnly} = values;
    const {scopePartType} = ruleScope;
    const prevScopePartType = usePrevious(scopePartType);

    const ruleScopeItems = [RULE_SCOPE_ITEMS.CLUSTER];
    if (!isClusterScopeOnly) {
        ruleScopeItems.push(RULE_SCOPE_ITEMS.ENVIRONMENT);
    }
    ruleScopeItems.push(RULE_SCOPE_ITEMS.ANY);

    useEffect(() => {
        if (isUndefined(prevScopePartType) && isClusterScopeOnly) {
            setFieldValue("ruleScope.scopePartType", RULE_SCOPE_ITEMS.CLUSTER.value);
            setFieldValue("ruleScope.environment.names", []);
        }
        
        if (prevScopePartType === scopePartType || !prevScopePartType) {
            return;
        }
        
        setFieldValue("ruleScope.environment.names", []);
        setFieldValue("ruleScope.clusters", []);
    }, [prevScopePartType, scopePartType, setFieldValue, isClusterScopeOnly]);

    return (
        <React.Fragment>
            <SelectField 
                name="ruleScope.scopePartType"
                label="Select an option to identify the target of the rule"
                items={ruleScopeItems}
                validate={validators.validateRequired}
                clearable={false}
                disabled={isReadOnlyUser}
            />
            {scopePartType === RULE_SCOPE_ITEMS.ENVIRONMENT.value && <EnvironmentForm environmentNames={environmentNames} isReadOnlyUser={isReadOnlyUser} />}
            {scopePartType === RULE_SCOPE_ITEMS.CLUSTER.value &&
                <MultiselectField name="ruleScope.clusters" items={clusterNames} validate={validators.validateRequired} disabled={isReadOnlyUser} />}
        </React.Fragment>
    )
}

export default FormRuleScope;