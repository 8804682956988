import React from 'react';
import { isEmpty } from 'lodash';
import Modal from 'components/Modal';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';

const AssignedDisplay = ({podSecurityPolicies}) => (
    <div className="seccomp-assigned-pspp-container">
        <span>{`Runs on `}</span>
        {
            podSecurityPolicies.map((item, index, items) => {
                const isLast = items.length - 1 === index;
                
                return (
                    <React.Fragment key={index}>
                        <span className="profile-name">{item}</span>
                        {!isLast && <span>{` and `}</span>}
                    </React.Fragment>
                )
            })
        }
    </div>
);

const SeccompProfileModal = ({id, name, data: jsonData, podSecurityPolicies, onClose, onDelete, onCopy, onEdit}) => {
    const data = JSON.parse(jsonData);
    const copyTooltipId = `seccomp-modal-copy-${id}`;
    const editTooltipId = `seccomp-modal-edit-${id}`;
    const deleteTooltipId = `seccomp-modal-delete-${id}`;
    const disableDelete = !isEmpty(podSecurityPolicies);

    return (
        <Modal className="seccomp-item-modal" center={true} onClose={onClose}>
            <div className="seccomp-item-title">{name}</div>
            <div className="seccomp-item-actions">
                <IconWithTooltip name={ICON_NAMES.DUPLICATE} onClick={onCopy} tooltipId={copyTooltipId} tooltipText="Duplicate" placement="bottom" />
                <IconWithTooltip name={ICON_NAMES.EDIT} onClick={onEdit} tooltipId={editTooltipId} tooltipText="Edit" placement="bottom" />
                <IconWithTooltip
                    name={ICON_NAMES.DELETE}
                    onClick={onDelete}
                    tooltipId={deleteTooltipId}
                    tooltipText={disableDelete ? "Profile in use" : "Delete"}
                    disabled={disableDelete}
                    placement="bottom"
                />
            </div>
            <div className="seccomp-item-header">
                <AssignedDisplay podSecurityPolicies={podSecurityPolicies} />
            </div>
            <div className="seccomp-item-content">
                <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
        </Modal>
    )
}

export default SeccompProfileModal;

export {
    AssignedDisplay
}