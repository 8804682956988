import React from 'react';
import classnames from 'classnames';

export const DEFAULT_RULE_NAME = "Default rule";

export const DEFAULT_RULE_ACTIONS = {
    ALLOW_ALL: "ALLOW_ALL",
    DETECT_ALL: "DETECT_ALL",
    BLOCK_ALL: "BLOCK_ALL"
}

export const getRuleValueByType = (type, isBlocked) => {
    if (type === DEFAULT_RULE_ACTIONS.ALLOW_ALL) {
        return (
            <span>Any workload <span className="relationship allowed">can</span> run on any environment unless specifically restricted by a previous rule</span>
        );
    }

    return (
        <span>A workload <span className={classnames("relationship", {blocked: isBlocked})}>can't</span> run on any environment unless specifically allowed in a previous rule</span>
    );
}