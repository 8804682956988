import React from 'react';
import DeleteWizardModal from 'components/DeleteWizardModal';
import { DEPLOYMENT_POLICY_URL } from 'layout/Policies/DeploymentPolicy';
import RulesStep from 'layout/Deployments/Clusters/DeleteClusterModal/RulesStep';
import { getDeploymentRuleItems, DEPLOYMENT_RULES_NAME} from 'layout/Deployments/Clusters/DeleteClusterModal/utils';
import { APPLICATION_NAME } from 'utils/systemConsts';

const DeploymentsRulesStep = props =>
    <RulesStep {...props} name={DEPLOYMENT_RULES_NAME} pathname={DEPLOYMENT_POLICY_URL} getRuleItems={getDeploymentRuleItems} />

const DeleteTokenModal = ({data, onClose, onDeleteSuccess}) => {
    const {id, name} = data;
    const deleteItemName = `${name} Token`;

    return (
        <DeleteWizardModal
            url={`tokens/${id}/deleteDependencies`}
            deleteUrl="tokens"
            onClose={onClose}
            onDeleteSuccess={onDeleteSuccess}
            deleteSteps={[
                {id: 1, title: "Deployment Rules", dataKey: "deploymentRules", component: DeploymentsRulesStep}
            ]}
            title="Delete token"
            description={`Deleting ${deleteItemName} will have an impact on other ${APPLICATION_NAME} elements. Once deleted, the action cannot be reverted`}
            deleteItemName={deleteItemName}
            deleteItemId={id}
        />
    )
}

export default DeleteTokenModal;