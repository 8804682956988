import React from 'react';
import classnames from 'classnames';
import { ICON_NAMES } from 'components/Icon';
import IconWithTooltip from 'components/IconWithTooltip';
import { WORKLOAD_RISK_TYPE_ITEMS } from 'utils/systemConsts';
import { AndList, BoldText } from 'utils/generalUtils';
import { IGNORE_RISK_TYPES } from './RiskForm'

const getListSuffix = (list, suffix) => ` ${suffix}${list.length > 1 ? "s" : ""}`;

const getNameById = (id, items) => {
    const itemData = items.find(item => item.id === id);

    return !!itemData ? itemData.name : id;
}

const getScopePhrase = ({ignoredRiskType, workloadNames, clusterIds, environmentIds, clustersData, environmentsData}) => {
    let phrase = "";

    switch (ignoredRiskType) {
        case IGNORE_RISK_TYPES.AnyEnvironmentIgnoredRisk:
            phrase = <span><BoldText>any</BoldText> environment</span>;
            break;
        case IGNORE_RISK_TYPES.AnyClusterIgnoredRisk:
            phrase = <span><BoldText>any</BoldText> cluster</span>;
            break;
        case IGNORE_RISK_TYPES.EnvironmentIgnoredRisk:
            phrase = (
                <React.Fragment>
                    {AndList(environmentIds.map(id => getNameById(id, environmentsData)))}
                    {getListSuffix(environmentIds, "environment")}
                </React.Fragment>
            );
            break;
        case IGNORE_RISK_TYPES.ClusterIgnoredRisk:
            phrase = (
                <React.Fragment>
                    {AndList(clusterIds.map(id => getNameById(id, clustersData)))}
                    {getListSuffix(clusterIds, "cluster")}
                </React.Fragment>
            );
            break;
        case IGNORE_RISK_TYPES.WorkloadIgnoredRisk:
            phrase = <React.Fragment>{AndList(workloadNames)}{getListSuffix(workloadNames, "workload")}</React.Fragment>;
            break;
        default:
            break;
    }
    
    return phrase;
}

const RiskItemDisplay = (props) => {
    const {id, ignoredRiskType, workloadRisksType, workloadNames, clusterIds, environmentIds, disabled, onDelete, onEdit,
        clustersData, environmentsData} = props;

    return (
        <div className={classnames("risk-item-wrapper", {disabled})} onDoubleClick={onEdit}>
            <div className="risk-item-description">
                {`Ignore `}
                {AndList(workloadRisksType.map(type => `"${WORKLOAD_RISK_TYPE_ITEMS[type].label}"`))}
                {` on `}
                {getScopePhrase({ignoredRiskType, workloadNames, clusterIds, environmentIds, clustersData, environmentsData})}
            </div>
            <div className="risk-item-actions">
                <IconWithTooltip
                    name={ICON_NAMES.DELETE}
                    onClick={onDelete}
                    disabled={disabled}
                    tooltipId={`risk-delete-tooltip-${id}`}
                    tooltipText="Delete"
                />
                <IconWithTooltip
                    name={ICON_NAMES.EDIT}
                    onClick={onEdit}
                    disabled={disabled}
                    tooltipId={`risk-edit-tooltip-${id}`}
                    tooltipText="Edit"
                />
            </div>
        </div>
    );
}

export default RiskItemDisplay;