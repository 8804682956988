import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import { FETCH_METHODS, useFetch, usePrevious } from 'hooks';
import FormWrapper, { validators, TextField, SelectField, MultiselectField, DateField, utils, useFormikContext } from 'components/Form';
import Loader from 'components/Loader';
import Text, { TEXT_TYPES } from 'components/Text';
import Button from 'components/Button';
import { ICON_NAMES } from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { DEPLOYMENT_POLICY_URL } from 'layout/Policies/DeploymentPolicy';
import { DEPLOYMENT_USER_RULE_TYPES } from 'layout/Policies/utils';

import './token-form.scss';

const NEW_API_FIELD_NAME = "newApiName";
const SELECTED_APIS_FIELD_NAME = "apis";

const ApiFormFields = ({onCancel, onCreate}) => {
    const {values} = useFormikContext();

    const [{loading, data, error}, fetchApis] = useFetch("apiSecurity/api", {loadOnMount: false});
    const prevLoading = usePrevious(loading);
    const submitData = newApiName => fetchApis({submitData: {name: newApiName}, method: FETCH_METHODS.POST});
    const {identifier} = data || {};
    
    useEffect(() => {
        if (prevLoading && !loading && !error) {
            onCreate(identifier);
        }
    }, [prevLoading, loading, error, identifier, onCreate]);

    if (loading || !!data) {
        return <div style={{marginBottom: "10px"}}>Saving...</div>;
    }

    return (
        <div className="inner-api-form-wrapper">
            <TextField name={NEW_API_FIELD_NAME} label="API URL" validate={validators.validateRequired} />
            <div className="api-action-buttons">
                <Button secondary onClick={onCancel}>Cancel</Button>
                <Button className="api-submit-btn" onClick={() => submitData(values[NEW_API_FIELD_NAME])} disabled={false}>Create</Button>
            </div>
        </div>
    )
}

const FormFields = ({apiItems}) => {
    const {values, setFieldValue} = useFormikContext();
    const selectedApis = values[SELECTED_APIS_FIELD_NAME];

    const [isOpenApiForm, setIsOpenApiForm] = useState(false);
    const closeApiForm = () => setIsOpenApiForm(false);

    const [newApiId, setNewApiId] = useState(null);

    const [{loading, data}, fetchApis] = useFetch("apiSecurity/externalCatalog", {loadOnMount: false});
    const prevLoading = usePrevious(loading);

    const updatedApiItems = !!data ? data?.items?.map(({identifier, name}) => ({value: identifier, label: name})) : apiItems;
    
    useEffect(() => {
        if (prevLoading && !loading) {
            setFieldValue(SELECTED_APIS_FIELD_NAME, [...selectedApis, newApiId]);
        }
    }, [prevLoading, loading, newApiId, selectedApis, setFieldValue]);

    return (
        <React.Fragment>
            <TextField name="name" label="Name" validate={validators.validateRequired} />
            <TextField
                name="vaultSecretPath"
                label="Vault secret path"
                validate={validators.validateRequired}
                placeholder="secret/data/test#testkey"
                tooltipText={
                    <span>
                        The path should be composed as follows:
                        <ul style={{margin: 0}}>
                            <li>The engine</li>
                            <li>The constant /data/</li>
                            <li>The value of Path for this secret</li>
                            <li>The character '#' followed by the key of the pair that you specified in the "Secret data" section</li>
                        </ul>
                        For example: secret/data/test#testkey
                    </span>
                }
            />
            
            <div className="token-apis-wrapper">
                <MultiselectField
                    className="token-apis-select"
                    name={SELECTED_APIS_FIELD_NAME}
                    label="APIs"
                    items={updatedApiItems}
                    disabled={isOpenApiForm}
                    loading={loading}
                />
                <div className="api-connector">or</div>
                <Button tertiary onClick={() => setIsOpenApiForm(true)} disabled={isOpenApiForm}>
                    Create new
                </Button>
            </div>
            <utils.InnerFieldWrapper title="API creation" isVisable={isOpenApiForm}>
                <ApiFormFields
                    onCancel={closeApiForm}
                    onCreate={id => {
                        closeApiForm();
                        setNewApiId(id);
                        fetchApis();
                    }}
                />
            </utils.InnerFieldWrapper>
            <DateField name="expirationDate" label="Expiration date" displayFormat="MMM Do YYYY" placeholder="Select date" isFullWidth />
            <div className="token-attribute-fields-wrapper">
                <SelectField 
                    name="attributeType"
                    label="Attribute type"
                    items={[
                        {value: "REQUEST_HEADER", label: "Request header"},
                        {value: "QUERY_PARAM", label: "Query param"}
                    ]}
                />
                <TextField name="attributeName" label="Attribute name" />
            </div>
            <TextField name="httpPath" label="Http path" />
        </React.Fragment>
    );
}

const TokenForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const history = useHistory();

    const [injectionButtonClicked, setInjectionButtonClicked] = useState(false);

    const [{loading, data: apis, error}] = useFetch("apiSecurity/externalCatalog");

    if (loading) {
        return <Loader absolute={false} />;
    }

    if (error) {
        return null;
    }

    const initialValues = {
        name: "",
        apis: [],
        expirationDate: "",
        attributeType: "",
        attributeName: "",
        httpPath: "",
        vaultSecretPath: "",
        [NEW_API_FIELD_NAME]: "",
        ...initialData
    };
    
    const isEditForm = initialValues && initialValues.id;

    const apiItems = isNull(apis) ? [] : apis.items.map(item => ({value: item.identifier, label: item.name}));
    const injectionButtonTooltipId = "injection-button-tooltip-id";
    
    return (
        <div className="api-token-form-wrapper">
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{`${isEditForm ? "Edit" : "New"} Token`}</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl="tokens"
                getSubmitParams={formValues => {
                    const {id, ...submitData} = formValues;
                    
                    const {attributeType} = submitData;
                    submitData.attributeType = !!attributeType ? attributeType : null;

                    delete submitData[NEW_API_FIELD_NAME];

                    return !isEditForm ? {submitData} : {
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={({id}) => {
                    if (injectionButtonClicked) {
                        history.push({
                            pathname: DEPLOYMENT_POLICY_URL,
                            query: {ruleInitialData: {ruleTypeProperties: {ruleType: DEPLOYMENT_USER_RULE_TYPES.INJECTION, tokens: [{tokenId: id, envVariable: ""}]}}}
                        });
                    }

                    onFormSubmitSuccess();
                }}
                onSubmitError={() => setInjectionButtonClicked(false)}
                onDirtyChanage={onDirtyChanage}
                customSubmitButton={({onClick, disabled}) => (
                    <React.Fragment>
                        <div className="token-injection-button-wrapper" data-tip data-for={injectionButtonTooltipId}>
                            <Button
                                secondary
                                onClick={() => {
                                    setInjectionButtonClicked(true);

                                    onClick();
                                }}
                                disabled={disabled}
                                icon={ICON_NAMES.KEY_DIAGONAL}
                            >
                                Token Injection
                            </Button>
                        </div>
                        <Tooltip id={injectionButtonTooltipId} text={<span>Rule defining the usage of API<br />tokens in workloads.</span>} placement="top" />
                    </React.Fragment>
                )}
            >
                <FormFields apiItems={apiItems} />
            </FormWrapper>
        </div>
    )
}

export default TokenForm;