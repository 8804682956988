import React, { useEffect } from 'react';
import { cloneDeep, has, isEmpty, isEqual, debounce } from 'lodash';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useAuthState } from 'context/AuthProvider';
import { FETCH_METHODS, useFetch, usePrevious } from 'hooks';
import FormWrapper, { TextField, validators, useFormikContext, SelectField, ArrayField, ListField, utils } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';
import Loader from 'components/Loader';
import { ICON_NAMES } from 'components/Icon';
import IconWithTooltip from 'components/IconWithTooltip';
import { IMAGE_PAGE_PATH, IMAGES_FILTER_KEYS } from 'layout/CiCd/Images';
import { CONTROLLER_TYPES } from 'utils/systemConsts';

import './pod-form.scss';

export const POD_DEPLOYMENT_TYPE_ITEMS = [
    {value: "ReplicaSet", label: "ReplicaSet"},
    {value: "Deployment", label: "Deployment"},
    {value: "Job", label: "Job"},
    {value: "CronJob", label: "CronJob"},
    {value: "DaemonSet", label: "DaemonSet"},
    {value: "StatefulSet", label: "StatefulSet"},
    {value: "Pod", label: "Pod"}
];

export const POD_TEMPLATE_SUBMIT_URL = "podDefinitions";

export const formatSubmitParams = (formValues, isEditForm) => {
    const {id, ...formData} = formValues;

    const submitData = formatContainersData(formData);

    submitData.labels = submitData.labels.filter(item => item.key !== "" && item.value !== "");
    submitData.containers = submitData.containers.filter(item => item !== "");

    if (isEditForm && has(submitData, "isIdentified") && !submitData.isIdentified) {
        submitData.podDefinitionSource = "MANUAL";
    }

    if (!isEditForm) {
        submitData.podDefinitionSource = "MANUAL";
    }

    delete submitData.isIdentified;
    delete submitData.clusterName;
    delete submitData.isProtected;
    delete submitData.createdAt;
    delete submitData.permissionOwnerName;

    return !isEditForm ? {submitData} : {
        method: FETCH_METHODS.PUT,
        formatUrl: url => `${url}/${id}`,
        submitData
    }
}

const containerValidator = (value) => {
    const pattern = /^[0-9A-Za-z./-]+:[0-9A-Za-z./-]+$/;
    return !!value && pattern.test(value) ? null :
        ["Invalid Image. Please follow the syntax: 'repository:tag'"];
}

const formatContainersData = data => {
    const formattedData = cloneDeep(data);
    formattedData.containers = formattedData.containers.map(item => {
        const [repository, tag] = item.split(":");

        return {image: {repository, tag}};
    });
    if (formattedData.initContainers.length === 1 && isEmpty(formattedData.initContainers[0])) {
        delete formattedData.initContainers;
    } else {
        formattedData.initContainers = formattedData.initContainers.map(item => {
            const [repository, tag] = item.split(":");
    
            return {image: {repository, tag}};
        });
    }

    return formattedData;
}

const getImageName = image => !!image ? `${image.repository}:${image.tag}` : "";

const FormFields = ({isEditForm, clusterItems, limitDisplay, disabled, withImageLink, containersData, initialContainersData, onChange}) => {
    const {values, isSubmitting, isValid} = useFormikContext();
    const {clusterId} = values;

    const showAlert = !isEditForm && !limitDisplay && !!clusterId && clusterItems.find(item => item.value === clusterId).showAlert;
    const showImageLink = !!withImageLink && !!disabled && !!containersData;

	const prevValues = usePrevious(values);
    const disableSubmit = isSubmitting || !isValid;
    const prevDisableSubmit = usePrevious(disableSubmit);

	useEffect(() => {
		if (!!onChange && (!isEqual(values, prevValues) || prevDisableSubmit !== disableSubmit)) {
			debounce(onChange, 100)({values, disableSubmit});
		}
	}, [values, prevValues, disableSubmit, prevDisableSubmit, onChange]);

    return (
        <React.Fragment>
            {!limitDisplay &&
                <TextField name="name" label="Name" component={TextField} validate={validators.validateRequired} disabled={disabled} />}
            <SelectField
                name="kind"
                label="Deployment Type"
                validate={validators.validateRequired}
                items={POD_DEPLOYMENT_TYPE_ITEMS}
                disabled={disabled}
            />
            <SelectField
                name="clusterId"
                label="Cluster"
                placeholder="Select"
                items={clusterItems}
                validate={validators.validateRequired}
                disabled={disabled}
            />
            {showAlert && <utils.FieldAlert message="This cluster is not in CD mode and the Pod will not be downloaded" />}
            <ArrayField
                name="labels"
                label="Labels"
                firstFieldProps={{
                    component: TextField,
                    key: "key",
                    placeholder: "Key",
                    validate: validators.validateRequired
                }}
                secondFieldProps={{
                    component: TextField,
                    key: "value",
                    placeholder: "Value",
                    validate: validators.validateRequired
                }}
                disabled={disabled}
            />
            <ListField
                name="containers"
                label="Container Images"
                className={classnames("containers-list-field", {"with-link": showImageLink})}
                fieldComponent={TextField}
                fieldProps={{
                    placeholder: "repository:tag",
                    validate: containerValidator,
                    customComponenet: ({value}) => {
                        if (!showImageLink) {
                            return null;
                        }
                        
                        const imageData = containersData.find(({image}) => getImageName(image) === value);
                        const {repository, hash, tag} = imageData.image;
                        
                        return (
                            <Link className="link-to-image" to={{pathname: IMAGE_PAGE_PATH, query: {filters: {
                                [IMAGES_FILTER_KEYS.IMAGE_NAME]: repository || "",
                                [IMAGES_FILTER_KEYS.IMAGE_HASH]: hash || "",
                                [IMAGES_FILTER_KEYS.IMAGE_TAG]: tag || ""
                            }}}} >
                                <IconWithTooltip
                                    name={ICON_NAMES.CI_CD}
                                    tooltipId={`container-${value}`}
                                    tooltipText="See more details"
                                />
                            </Link>
                        )
                    }
                }}
                disabled={disabled}
            />
            {!(disabled && isEmpty(initialContainersData)) &&
                <ListField
                    name="initContainers"
                    label="Init Container Images"
                    fieldComponent={TextField}
                    fieldProps={{
                        placeholder: "repository:tag",
                        validate: (value) => {
                            if (value === "") {
                                return null;
                            }

                            return containerValidator(value);
                        }
                    }}
                    disabled={disabled}
                />
            }
        </React.Fragment>
    )
}

/*
Form props:
-----------
disabled=true - used in the runtime table, all fields disabled
limitDisplay=false - used in runtime table to limit display of fields not available in runtime data
withImageLink=true - used in the runtime table, to link to the images page
onChange - function used in the runtime table
*/
const PodForm = ({initialData, onFormSubmitSuccess, onDirtyChanage, limitDisplay, withImageLink, disabled, hideSubmitButton, withTitle, onChange}) => {
    const [{loading, data: clustersData, error}] = useFetch("leanKubernetesClusters");

    const {isReadOnlyUser} = useAuthState();

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const initialValues = {
        name: "",
        kind: "",
        clusterId: "",
        labels: [{key: "", value: ""}],
        containers: [""],
        initContainers: [""],
        ...initialData
    };

    if (isEmpty(initialValues.containers)) {
        initialValues.containers = [""];
    }
    if (isEmpty(initialValues.initContainers)) {
        initialValues.initContainers = [""];
    }

    initialValues.containers = initialValues.containers.map(({image}) => getImageName(image));
    initialValues.initContainers = initialValues.initContainers.map(({image}) => getImageName(image));

    const isEditForm = initialValues && initialValues.id;
    const disabledForm = disabled || isReadOnlyUser;

    const clusterItems = clustersData.map(({id, name, clusterPodDefinitionSource, kubernetesSecurity}) =>
        ({value: id, label: name, showAlert: clusterPodDefinitionSource === CONTROLLER_TYPES.KUBERNETES, isDisabled: !kubernetesSecurity}));

    return (
        <div>
            {withTitle && <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{`${isEditForm ? "Edit" : "New"} Pod Template`}</Text>}
            <FormWrapper
                hideSubmitButton={hideSubmitButton}
                className="pod-form-container"
                initialValues={initialValues}
                submitUrl={POD_TEMPLATE_SUBMIT_URL}
                getSubmitParams={formValues => formatSubmitParams(formValues, isEditForm)}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <FormFields
                    isEditForm={isEditForm}
                    clusterItems={clusterItems}
                    disabled={disabledForm}
                    limitDisplay={limitDisplay}
                    withImageLink={withImageLink}
                    containersData={initialData.containers}
                    initialContainersData={initialData.initContainers}
                    onChange={onChange}
                />
            </FormWrapper>
        </div>
    )
}

PodForm.defaultProps = {
    disabled: false,
    limitDisplay: false,
    hideSubmitButton: false,
    withTitle: true,
    withImageLink: false
};

export default PodForm;