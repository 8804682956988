import React, { useState, useMemo, useRef, useEffect } from 'react';
import { isNull, isUndefined } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useAdminState } from 'context/AdminProvider';
import { useAuthState } from 'context/AuthProvider';
import { checkIsAgentAccessAllowed } from 'context/DisplayConfigProvider';
import { useDelete, useMountMultiFetch } from 'hooks';
import { CONTROLLER_STATUS_ITEMS } from 'utils/systemConsts';
import { TextField, SelectField } from 'components/Form';
import Table, { tableUtils, FilterFormWrapper } from 'components/Table';
import IconWithTitle from 'components/IconWithTitle';
import Button from 'components/Button';
import Modal from 'components/Modal';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';
import Loader from 'components/Loader';
import FormModal from 'components/FormModal';
import ModalConfirmation from 'components/ModalConfirmation';
import ControllerVersionDisplay from 'layout/controller-tools/ControllerVersionDisplay';
import ControllerStatusDisplay from 'layout/controller-tools/ControllerStatusDisplay';
import ControllerEnableToggleButton from 'layout/controller-tools/ControllerEnableToggleButton';
import ExpansionForm from './ExpansionForm';
import InstallData from './InstallData';

import './expansions.scss';

export const DEPLOYMNET_EXPANSIONS_PAGE_PATH = "/deployments/expansions";

const EXPENTION_CONTROLLER_API_PATH = "agents";

const SORT_KEYS = {
    NAME: "name"
}

const findItemNameById = (items=[], keyValue) => {
    const found = items.find(item => item.id === keyValue);

    return !!found ? found.name : null;
}

const Expansions = ({clusters, namespaces}) => {
    const clustersJSON = JSON.stringify(clusters);
    const namespacesJSON = JSON.stringify(namespaces);

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);

    const {isReadOnlyUser, permissionsMode} = useAuthState();
    const {permissionsMode: assignedPermissionsMode, assignedAccountId} = useAdminState();

    const isAgentAccessAllowed = checkIsAgentAccessAllowed(isNull(assignedAccountId) || isUndefined(assignedAccountId) ? permissionsMode : assignedPermissionsMode);
    
    const columns = useMemo(() => [
        {
            Header: "Expansion controller name",
            id: SORT_KEYS.NAME,
            accessor: "name",
            alwaysShow: true
        },
        {
            Header: "Labels",
            id: "labels",
            Cell: ({row}) => <tableUtils.KeyValueRenderer items={row.original.labels || []} />,
            disableSortBy: true
        },
        {
            Header: "Cluster",
            id: "clusterName",
            Cell: ({row}) => findItemNameById(JSON.parse(clustersJSON), row.original.clusterId),
            disableSortBy: true
        },
        {
            Header: "Namespace",
            id: "namespaceName",
            Cell: ({row}) => findItemNameById(JSON.parse(namespacesJSON), row.original.namespaceId),
            disableSortBy: true
        },
        {
            Header: "Wokload Addresses",
            id: "workloadAddresses",
            Cell: ({row}) => (
                <React.Fragment>
                    {
                        row.original.workloadAddresses.map(({networkProtocol, address}, index) =>
                            <div key={index}>{`${networkProtocol}:${address}`}</div>)
                    }
                </React.Fragment>
            ),
            disableSortBy: true
        },
        {
            Header: "Controller version",
            id: "controllerVersion",
            Cell: ({row}) => {
                const {controllerId, controllerStatus, controllerVersion, controllerIsUpdateEnabled, controllerEnabled} = row.original;

                return (
                    <ControllerVersionDisplay
                        id={controllerId}
                        version={controllerVersion}
                        status={controllerStatus}
                        enabled={controllerEnabled}
                        isUpdateEnabled={controllerIsUpdateEnabled}
                        agentsUrl={EXPENTION_CONTROLLER_API_PATH}
                        isAgentAccessAllowed={isAgentAccessAllowed}
                    />
                )
            },
            disableSortBy: true
        },
        {
            Header: "Status",
            id: "startus",
            Cell: ({row}) => {
                const {controllerId, controllerStatus, controllerLastActive} = row.original;

                return (
                    <ControllerStatusDisplay
                        id={controllerId}
                        status={controllerStatus}
                        lastActive={controllerLastActive}
                    />
                )
            },
            disableSortBy: true
        }
    ], [clustersJSON, namespacesJSON, isAgentAccessAllowed]);

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = () => setRefreshTimestamp(Date());

    const [selectedExpansionData, setSelectedExpansionData] = useState(null);
    const closeExpansionModal = () => setSelectedExpansionData(null);

    const [installExpansionData, setInstallExpansionData] = useState(null);
    const closeInstallModal = () => setInstallExpansionData(null);

    const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationId(null);

    const [{deleting}, deleteExpansion] = useDelete("expansions");

    const [filters, setFilters] = useState({
        name: urlSearchParams.get("name") || "",
        clusterName: "",
        namespaceName: "",
        controllerVersion: "",
        controllerStatus: ""
    });

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);
    
    return (
        <div className="deployments-expansions-page">
            <div className="table-header-container">
                <IconWithTitle
                    name={ICON_NAMES.ADD}
                    title="Connect Expansion"
                    className="add-new-button"
                    onClick={() => setSelectedExpansionData({})}
                    disabled={isReadOnlyUser}
                />
                <FilterFormWrapper
                    initialValues={{...filters}}
                    onChange={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                >
                    <TextField name="name" label="Name" />
                    <TextField name="clusterName" label="Cluster" />
                    <TextField name="namespaceName" label="Namespace" />
                    <TextField name="controllerVersion" label="Controller version" />
                    <SelectField
                        name="controllerStatus"
                        label="Status"
                        items={Object.values(CONTROLLER_STATUS_ITEMS)}
                        small
                    />
                </FilterFormWrapper>
            </div>
            <Table
                url="expansions"
                name="expansions"
                defaultSortBy={[{id: SORT_KEYS.NAME, desc: false}]}
                columns={columns}
                actionsComponent={({original}) => {
                    const {id, controllerId, controllerEnabled} = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;
                    const installTooltipId = `${id}-install`;

                    return (
                        <div className="expansions-row-actions">
                            <IconWithTooltip
                                name={ICON_NAMES.INSTALL}
                                onClick={() => setInstallExpansionData(original)}
                                tooltipId={installTooltipId}
                                tooltipText="Install"
                            />
                            <IconWithTooltip
                                name={ICON_NAMES.EDIT}
                                onClick={() => setSelectedExpansionData(original)}
                                tooltipId={editTooltipId}
                                tooltipText="Edit expansion"
                                disabled={isReadOnlyUser}
                            />
                            <IconWithTooltip
                                name={ICON_NAMES.DELETE}
                                onClick={() => setDeleteConfirmationId(original.id)}
                                tooltipId={deleteTooltipId}
                                tooltipText="Delete expansion"
                                disabled={isReadOnlyUser}
                            />
                            <ControllerEnableToggleButton
                                id={controllerId}
                                enabled={controllerEnabled}
                                agentsUrl={EXPENTION_CONTROLLER_API_PATH}
                                isReadOnlyUser={isReadOnlyUser}
                                onUpdateDone={() => refreshTableData()}
                            />
                        </div>
                    );
                }}
                actionsCellWidth={110}
                isLoading={deleting}
                refreshTimestamp={refreshTimestamp}
                filters={filters}
            />
            {!isNull(selectedExpansionData) &&
                <FormModal
                    onClose={closeExpansionModal}
                    formComponent={ExpansionForm}
                    formProps={{
                        initialData: {...selectedExpansionData},
                        onFormSubmitSuccess: () => {
                            closeExpansionModal();
                            refreshTableData();
                        }
                    }}
                />
            }
            {!isNull(installExpansionData) && 
                <Modal className="expansion-install-modal" center={true} onClose={closeInstallModal}>
                    <InstallData {...installExpansionData} />
                    <Button onClick={closeInstallModal}>Done</Button>
                </Modal>
            }
            {!isNull(deleteConfirmationId) &&
                <ModalConfirmation 
                    title="Are you sure?"
                    message="This operation cannot be reverted"
                    confirmTitle="Delete"
                    onCancel={closeDeleteConfirmation}
                    onConfirm={() => {
                        closeDeleteConfirmation();
                        deleteExpansion(deleteConfirmationId);
                    }}
                    confirmAlert
                />
            }
        </div>
    )
}

const ExpansionsWrapper = () => {
    const [{loading, data}] = useMountMultiFetch([
        {key: "namespaces", url: "namespaces"},
        {key: "clusters", url: "leanKubernetesClusters"}
    ]);
    
    if (loading) {
        return <Loader />;
    }

    return (
        <Expansions {...data} />
    )
}

export default ExpansionsWrapper;