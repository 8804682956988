import React from 'react';
import { LinkToRule } from 'layout/Policies';

const isDeleted = status => (status === "DELETED");

const RuleNamesLinks = ({pathname, defaultRule, implicitRule, userRule, encryptRule}) => {
    return (
        <div className="runtime-rule-links-wrapper">
            {!!defaultRule && <LinkToRule pathname={pathname} ruleName="Default rule" isDefault={true} />}
            {!!implicitRule && <LinkToRule pathname={pathname} ruleName={implicitRule.name} isPortshitRule={true} />}
            {!!userRule && <LinkToRule pathname={pathname} ruleName={userRule.name} id={userRule.id} deleted={isDeleted(userRule.status) || userRule.isDeleted} />}
            {!!encryptRule && <LinkToRule pathname={pathname} ruleName={encryptRule.name} id={encryptRule.id} deleted={isDeleted(encryptRule.status)} />}
        </div>
    )
}

export default RuleNamesLinks;