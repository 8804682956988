import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RISKS_SCAN_PAGE_PATH } from './utils';
import GeneralRiskAssessment from './GeneralRiskAssessment';
import ClusterRiskAssessment from './ClusterRiskAssessment';

import './risks-scan.scss';

export {
    RISKS_SCAN_PAGE_PATH
}

const RisksScan = ({setHideTabs}) => (
    <Switch>
        <Route path={`${RISKS_SCAN_PAGE_PATH}/:clusterId`} component={() => <ClusterRiskAssessment setHideTabs={setHideTabs}/>} />
        <Route path={RISKS_SCAN_PAGE_PATH} component={GeneralRiskAssessment} />
    </Switch>
)

export default RisksScan;