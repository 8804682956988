import React from 'react';
import ContainerItems from 'components/ContainerItems';
import InstructionsItem from 'components/InstructionsItem';
import Icon, { ICON_NAMES } from 'components/Icon';
import { APPLICATION_NAME, DOCUMENTATION_URL } from 'utils/systemConsts';
import { SERVICE_USER_TITLE } from './utils';

const CliContent = () => (
    <React.Fragment>
        <InstructionsItem title={SERVICE_USER_TITLE}/>
        <InstructionsItem 
            title={<span>{`Download the ${APPLICATION_NAME} CLI `}<a href={`${window.location.origin}/tools/cli/securecn_deployment_cli`} download>here</a>, and verify it has execute permissions</span>}
        />
        <InstructionsItem
            title=" Run the following command to see CLI usage"
            inputText="./securecn_deployment_cli --help"
        />
    </React.Fragment>
);

const HelmDownloadLink = ({osLinkParam, osTitle, icon}) => (
    <a href={`${window.location.origin}/tools/${osLinkParam}/plugins/securecn_plugin.tar.gz`} className="helm-download-link" download>
        <Icon name={icon} />
        <div>{`Download package for ${osTitle}`}</div>
    </a>
)

const HelmDownload = ()  => (
    <React.Fragment>
        <InstructionsItem title={SERVICE_USER_TITLE}/>
        <InstructionsItem title={`Download the ${APPLICATION_NAME} Helm plugin:`} />
        <div className="helm-download-links">
            <HelmDownloadLink osLinkParam="darwin-amd64" osTitle="IOS" icon={ICON_NAMES.IOS} />
            <HelmDownloadLink osLinkParam="linux-amd64" osTitle="Linux" icon={ICON_NAMES.LINUX} />
        </div>
    </React.Fragment>
);

const Helm2Content = () => (
    <React.Fragment>
        <HelmDownload />
        <InstructionsItem
            title="Copy the plugin to your Helm plugin directory"
            inputText="cp securecn_plugin.tar.gz $(helm home)/plugins"
        />
        <InstructionsItem
            className="instructions-item-command"
            title="Run the following command on your helm machine"
            inputText={"cd $(helm home)/plugins\ntar -xzvf securecn_plugin.tar.gz"}
        />
        <InstructionsItem
            title={`Replace helm commands for charts you want ${APPLICATION_NAME} to approve, per the example below. Use token information of the created service user: “Access Key” for the --access-key parameter and Controller Secret Key” for the --secret-key parameter.`}
        />
        <InstructionsItem
            title="Before:"
            inputText="helm install --name tomcat stable/tomcat"
        />
        <InstructionsItem
            title="Use the Access Key and Controller Secret Key from the token for the service user, below"
            inputText="helm securecn --command 'install --name tomcat stable/tomcat' --access-key <access key> --secret-key <secret key> --controller-secret-key <controller secret key>"
        />
        <InstructionsItem
            title="To run a security check, add --run-security-check, and set the max risk level with --max-risk <NO RISK|MEDIUM|HIGH>"
        />
        <InstructionsItem
            title="Updated command:"
            inputText="helm securecn --command 'install --name tomcat stable/tomcat' --access-key <access key> --secret-key <secret key> --controller-secret-key <controller secret key> --run-security-check --max-risk <NO RISK|MEDIUM|HIGH>"
            isInnerTitle={true}
            withBullet={false}
        />
        <InstructionsItem
            title="Run the following command to see plugin usage"
            inputText="tar -xzvf securecn_plugin.tar.gz"
        />
    </React.Fragment>
);

const Helm3Content = () => (
    <React.Fragment>
        <HelmDownload />
        <InstructionsItem
            title="Run the following commands in your Helm machine"
            inputText={`tar -xzvf securecn_plugin.tar.gz\nhelm plugin install securecn`}
            className="helm3-machine-commands"
        />
        <InstructionsItem
            title="In order to use the SecureCNPolicy with Helm, move the crds folder to the root location of the helm chart (next to templates) by running the command:"
            inputText="cp -r crds <CHART_FOLDER>"
        />
        <InstructionsItem
            title={`Replace every helm command for charts you want ${APPLICATION_NAME} to approve like the example below. Use token information of the created service user: “Access Key” for the --access-key parameter and Controller Secret Key” for the --secret-key parameter.`}
        />
        <InstructionsItem
            title="Before:"
            inputText="helm install tomcat stable/tomcat"
        />
        <InstructionsItem
            title="After:"
            inputText="helm securecn --command 'install tomcat stable/tomcat' --access-key <access key> --secret-key <secret key> --controller-secret-key <controller secret key>"
        />
        <InstructionsItem
            title="To run a security check, add --run-security-check, and set the max risk level with --max-risk <NO RISK|MEDIUM|HIGH>"
        />
        <InstructionsItem
            title="Run the following command to see plugin usage:"
            inputText="helm securecn --help"
        />
    </React.Fragment>
);

const TerraformContent = () => (
    <React.Fragment>
        <InstructionsItem title={SERVICE_USER_TITLE}/>
        <InstructionsItem 
            title={<span>{`Setup the ${APPLICATION_NAME} Terraform provider from the `}<a href="https://registry.terraform.io/providers/Portshift/securecn/latest" download>Terraform Registry</a></span>}
        />
        <InstructionsItem title="Run 'terraform init' to initialize the provider plugin" />
        <InstructionsItem
                    title={<span>See the Terraform <a href={`${DOCUMENTATION_URL}/docs/terraform`} target="_blank" rel="noopener noreferrer">admin guide</a> for usage</span>}
                />
    </React.Fragment>
);

const CdPlugins = () => {
    const items = [
        {id: "helm2", title: "HELM2", componenet: Helm2Content},
        {id: "helm3", title: "HELM3", componenet: Helm3Content},
        {id: "cli", title: "CLI", componenet: CliContent},
        {id: "terraform", title: "Terraform", componenet: TerraformContent}
    ];

    return (
        <ContainerItems className="cd-plugins-container" title="CD plugins" items={items} />
    );
}

export default CdPlugins;