import React from 'react';
import { sortBy } from 'lodash';
import DropdownSelect from 'components/DropdownSelect';
import VulnerabilityIcon from 'components/VulnerabilityIcon';
import Advisor, { ContentItemDisplay, ADVISOR_TYEPS } from 'layout/Advisor';
import { POD_WORKLOAD_RULE_TYPES_ITEMS } from './UserRuleForm';
import {RULE_SCOPE_ITEMS} from "./UserRuleForm/FormScope";

const ADVISOR_ACTION_ITEMS = [
    {value: "ALLOW", label: "Allow workloads to run only on the specified environments", labelShort: "Allow"},
    {value: "DETECT", label: "Detect workloads running on the specified environments", labelShort: "Detect"},
    {value: "BLOCK", label: "Block workloads from running on the specified environments",labelShort: "Block"}
];

const ADVISOR_TYPES_MAP = {
    [POD_WORKLOAD_RULE_TYPES_ITEMS.NAME.value]: "By Pod, by name",
    [POD_WORKLOAD_RULE_TYPES_ITEMS.LABEL.value]: "By Pod, by label",
    [POD_WORKLOAD_RULE_TYPES_ITEMS.ANY.value]: "By Pod, any"
};

const ADVISOR_SCOPE_TYPES_MAP = {
    [RULE_SCOPE_ITEMS.ENVIRONMENT.value]: "By Environment, by name",
    [RULE_SCOPE_ITEMS.CLUSTER.value]: "By Environment, by risk",
    [RULE_SCOPE_ITEMS.ANY.value]: "By any cluster"
};

const onChangeAction = (data, onItemEdit) => {
    return action => {
        data.ruleTypeProperties.action = action;
        onItemEdit(data);
    };
}

const AdvisorActionSelector = ({data, onItemEdit}) => (
    <div className="suggestion-action-content" style={{marginBottom: "15px"}}>
        <div className="suggestion-content-title">Action</div>
        <div className="suggestion-content-label">Select action</div>
        <DropdownSelect
            items={ADVISOR_ACTION_ITEMS}
            defaultselected={data.ruleTypeProperties.action}
            name="action"
            onChange={onChangeAction(data, onItemEdit)}
            className="action-select"
        />
    </div>
);

const AdvisorHeaderActionSelector = ({data, onItemEdit}) => (
    <div className="suggestion-action-header" style={{width: "105px"}}>
        <DropdownSelect
            items={ADVISOR_ACTION_ITEMS.map(({value, labelShort}) => ({value, label: labelShort}))}
            defaultselected={data.ruleTypeProperties.action}
            name="action"
            onChange={onChangeAction(data, onItemEdit)}
            className="action-select"
        />
    </div>
);

const AdvisorWorkloadDisplay = ({data}) => (
    <div style={{display: "flex"}}>
        <ContentItemDisplay
            data={data}
            title="Workloads"
            subTitle={ADVISOR_TYPES_MAP[data.app.workloadRuleType]}
            displayData={[
                {title: "Names", valueKey: "app.names", isList: true},
                {
                    title: "Labels",
                    valueKey: "app.labels",
                    isList: true,
                    formatListItem: ({key, value}) => `${key}:${value}`
                },
                {
                    title: "Highest Vulnerability",
                    valueKey: "app.podValidation.vulnerability.highestVulnerabilityAllowed"
                }
            ]}
        />
        <ContentItemDisplay
            data={data}
            title="Rule Scope"
            subTitle={ADVISOR_SCOPE_TYPES_MAP[data.scope.workloadRuleScopeType]}
            displayData={[
                {title: "Names", valueKey: "scope.names", isList: true}
            ]}
        />
    </div>
);

const isCriticalVulnerability = (recommendation) => {
    const {podValidation} = recommendation.environemntRule.app;

    if (!podValidation) {
        return false;
    }

    const {vulnerability} = podValidation;
    
    return !!vulnerability && vulnerability.highestVulnerabilityAllowed === "CRITICAL";
}

const formatData = data => {
    return data.map(item => ({
        recommendations: sortBy(item.environmentRulesRecommendations, recommendation => {
            return isCriticalVulnerability(recommendation) ? -1 : 1;
        })
        .map(recommendation => {
            const environemntRule = recommendation.environemntRule;
            const isCritical = isCriticalVulnerability(recommendation);
            environemntRule.critical = isCritical;
            environemntRule.icon = !isCritical ? null : () => <VulnerabilityIcon severity="CRITICAL" />;

            return ({
                id: recommendation.id,
                affected: recommendation.numberOfAffectedWorkloads,
                recommendation: environemntRule
            });
        }),
        total: item.totalWorkloads,
        timePeriod: item.timePeriod
    }));
}

const DeploymentAdvisor = ({onAddSuggestions, initialExpanded, isReadOnly}) => (
    <Advisor
        emptyMessage="No rules suggestions"
        applyTitle="Apply Rule"
        buttonTitle="Policy Advisor"
        advisorType={ADVISOR_TYEPS.DEPLOYMENT_RULES}
        onItemsAdd={onAddSuggestions}
        url="advisor/environmentRules"
        title="workloads"
        formatData={formatData}
        contentDisplayItems={[
            AdvisorActionSelector,
            AdvisorWorkloadDisplay
        ]}
        headerDisplayItem={AdvisorHeaderActionSelector}
        initialExpanded={initialExpanded}
        isReadOnly={isReadOnly}
    />
)

export default DeploymentAdvisor;