import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import { RULE_ACTIONS, RULE_ACTIONS_MAP } from 'utils/systemConsts';
import { formatDate } from 'utils/generalUtils';
import Table, { useStanaloneTimeFilter, TimeFilter } from 'components/Table';
import InfoIcon from 'components/InfoIcon';
import IconWithTooltip from 'components/IconWithTooltip';
import Tooltip from 'components/Tooltip';
import Icon, { ICON_NAMES } from 'components/Icon';
import { LinkToRule } from 'layout/Policies';
import { EVENTS_POLICY_URL, RULE_SCOPE_ITEMS, ENVIRONMENT_BY_ITEMS } from 'layout/Policies/KubernetesPolicy';
import FiltersForm from './FiltersForm';

import './events.scss';

const Column_IDS = {
    LAST_SEEN: "lastSeen"
}

const RuleActionDisplay = ({id, violation, withComment=true}) => {
    if (isNull(violation)) {
        return "";
    }

    const {action, comment} = violation;

    const blocked = action === RULE_ACTIONS.BLOCK;
    const detected = action === RULE_ACTIONS.DETECT;

    return (
        <div className="events-rule-action-container">
            <span className={classnames("rule-action", {blocked}, {detected})}>{RULE_ACTIONS_MAP[action]}</span>
            {withComment && !!comment &&
                <React.Fragment>
                    <div data-tip data-for={id}>
                        <Icon name={ICON_NAMES.EXCLAMATION_MARK_ROUND} className={classnames({blocked}, {detected})} />
                    </div>
                    <Tooltip id={id} text={comment} placement="left" />
                </React.Fragment>
            }
        </div>
    );
}


const RuleNameDisplay = ({violation}) => {
    if (!violation) {
        return "";
    }
    
    const {isDefaultRule, userRule} = violation;
    const {ruleId, ruleName, isDeleted} = userRule || {};

    return (
        <LinkToRule
            pathname={EVENTS_POLICY_URL}
            deleted={isDeleted}
            ruleName={ruleName || "Default rule"}
            id={ruleId}
            isDefault={isDefaultRule}
            isPortshitRule={false}
        />
    )
}

const Events = () => {
    const columns = useMemo(() => [
        {
            Header: "Cluster Name",
            id: "clusterName",
            accessor: "clusterName",
            disableSortBy: true
        },
        {
            Header: "Namespace",
            id: "namespaceName",
            accessor: "namespace",
            hide: true,
            disableSortBy: true
        },
        {
            Header: "Environment",
            id: "environmentName",
            accessor: "environmentName",
            disableSortBy: true
        },
        {
            Header: "Action",
            id: "kubernetesAuditAction",
            accessor: "action",
            disableSortBy: true
        },
        {
            Header: "Resource Name",
            id: "resourceName",
            accessor: "resourceName",
            disableSortBy: true
        },
        {
            Header: "Resource Kind",
            id: "resourceKind",
            accessor: "resourceKind",
            hide: true,
            disableSortBy: true
        },
        {
            Header: "Resource Group",
            id: "resourceGroup",
            accessor: "resourceGroup",
            hide: true,
            disableSortBy: true
        },
        {
            Header: "User",
            id: "user",
            accessor: "user",
            width: 120
        },
        {
            Header: "User type",
            id: "userType",
            accessor: "userType",
            hide: true,
            disableSortBy: true
        },
        {
            Header: "First Seen",
            id: "firstSeen",
            accessor: original => formatDate(original.firstSeen),
            width: 120
        },
        {
            Header: "Last Seen",
            id: Column_IDS.LAST_SEEN,
            accessor: original => formatDate(original.lastSeen),
            width: 120
        },
        {
            Header: "Total",
            id: "total",
            accessor: "total",
            hide: true,
            disableSortBy: true
        },
        {
            Header: "Result",
            id: "result",
            Cell: ({row}) => {
                const {original, id: rowId} = row;
                const {violation} = original;
                const {potentialRiskDescription} = violation.matchedRecommendedRule || {};

                return (
                    <div>
                        <div style={{display: "flex"}}>
                            <RuleActionDisplay id={rowId} violation={violation} />
                            {!!potentialRiskDescription &&
                                <InfoIcon
                                    tooltipId={`events-coutcome-${rowId}`}
                                    text={potentialRiskDescription}
                                    placement="left"
                                />
                            }
                        </div>
                        <RuleNameDisplay violation={violation} />
                    </div>
                )
            },
        }
    ], []);

    const {isReadOnlyUser} = useAuthState();

    const location = useLocation();
    const {filters: queryFilters, timeRange} = location.query || {};

    const history = useHistory();

    const [{selected, endTime, startTime, loading}, refreshTimes, selectTime] = useStanaloneTimeFilter(timeRange);

    const [filters, setFilters] = useState({
        filterLoading: true
    });

    const handleRuleAdd = ({user, userType, userNamespace, resourceKind, resourceGroup, action, namespace, environmentName, clusterId}) => {
        const ruleScope = {
            scopePartType: !!namespace ? RULE_SCOPE_ITEMS.ENVIRONMENT.value : RULE_SCOPE_ITEMS.CLUSTER.value
        }

        if (ruleScope.scopePartType === RULE_SCOPE_ITEMS.ENVIRONMENT.value  && !!environmentName) {
            ruleScope.environment = {
                environmentRuleType: ENVIRONMENT_BY_ITEMS.NAME.value,
                names: [environmentName]
            };
        } else if (ruleScope.scopePartType === RULE_SCOPE_ITEMS.CLUSTER.value && !!clusterId) {
            ruleScope.clusters = [clusterId];
        }

        const rule = {
            action: RULE_ACTIONS.DETECT,
            users: [{
                userName: !!userNamespace ? `${userNamespace}/${user}` : user,
                userType
            }],
            resources: [{
                kind: resourceKind,
                group: resourceGroup
            }],
            apiActions: [action],
            ruleScope
        };

        history.push({pathname: EVENTS_POLICY_URL, query: {ruleInitialData: rule}});
    }

    const mounted = useRef(true);
    const inititalRefreshTimes = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!inititalRefreshTimes.current) {
            inititalRefreshTimes.current = true;
            refreshTimes();
        }
    }, [refreshTimes]);

    return (
        <div className="runtime-events-page">
            <div className="table-filters-container">
                <TimeFilter
                    defaultSelected={selected}
                    onTimeFilterChange={selectTime}
                    startTime={startTime}
                    endTime={endTime}
                    style={{marginRight: "10px"}}
                />
                {!isNull(startTime) && 
                    <FiltersForm
                        filters={filters}
                        queryFilters={queryFilters}
                        setFilters={updatedFilters => {
                            if (!mounted.current) {
                                return;
                            }
    
                            setFilters(updatedFilters);
                        }}
                    />
                }
            </div>
            <Table
                url="auditLogs/kubernetes"
                name="runtime-events"
                columns={columns}
                filters={{...filters, startTime, endTime}}
                defaultSortBy={[{id: Column_IDS.LAST_SEEN, desc: true}]}
                customOnRefresh={refreshTimes}
                exportToExcel={true}
                isLoading={loading || filters.filterLoading}
                actionsComponent={({original}) => {
                    const {id} = original;
                    const createRuleTooltipId = `${id}-create-rule`;

                    return (
                        <IconWithTooltip
                            className={classnames("events-add-rule-icon", {"audit-mode": isReadOnlyUser})}
                            name={ICON_NAMES.ADD_RULE}
                            onClick={() => handleRuleAdd(original)}
                            tooltipId={createRuleTooltipId}
                            tooltipText="Create new rule"
                        />
                    );
                }}
                actionsCellWidth={50}
            />
        </div>
    )
}

export default Events;
