import React from 'react';
import { APPLICATION_NAME } from 'utils/systemConsts';
import DeleteWizardModal from 'components/DeleteWizardModal';
import { CONNECTIONS_POLICY_URL } from 'layout/Policies/ConnectionsPolicy';
import { EVENTS_POLICY_URL } from 'layout/Policies/KubernetesPolicy';
import { DEPLOYMENT_POLICY_URL } from 'layout/Policies/DeploymentPolicy';
import RulesStep from 'layout/Deployments/Clusters/DeleteClusterModal/RulesStep';
import { getConnectionRuleItems, CONNECTION_RULES_NAME, getClusterEventsRuleItems, CLUSTER_EVENTS_RULES_NAME , getDeploymentRuleItems, DEPLOYMENT_RULES_NAME} from 'layout/Deployments/Clusters/DeleteClusterModal/utils';

const ConnectionRulesStep = props =>
    <RulesStep {...props} name={CONNECTION_RULES_NAME} pathname={CONNECTIONS_POLICY_URL} getRuleItems={getConnectionRuleItems} />

const ClusterEventsRulesStep = props =>
    <RulesStep {...props} name={CLUSTER_EVENTS_RULES_NAME} pathname={EVENTS_POLICY_URL} getRuleItems={getClusterEventsRuleItems} />

const DeploymentsRulesStep = props =>
    <RulesStep {...props} name={DEPLOYMENT_RULES_NAME} pathname={DEPLOYMENT_POLICY_URL} getRuleItems={getDeploymentRuleItems} />

const DeleteEnvironmentModal = ({data, onClose, onDeleteSuccess}) => {
    const {id, name} = data;
    const deleteItemName = `${name} Environment`;

    return (
        <DeleteWizardModal
            url={`environments/${id}/deleteDependencies`}
            deleteUrl="environments"
            onClose={onClose}
            onDeleteSuccess={onDeleteSuccess}
            deleteSteps={[
                {id: 1, title: "Deployment Rules", dataKey: "deploymentRules", component: DeploymentsRulesStep},
                {id: 2, title: "Connection rules", dataKey: "connectionRules", component: ConnectionRulesStep},
                {id: 3, title: "Cluster event rules", dataKey: "clusterEventRules", component: ClusterEventsRulesStep}
            ]}
            title="Delete environment"
            description={`Deleting ${deleteItemName} will have an impact on other ${APPLICATION_NAME} elements. Once deleted, the action cannot be reverted`}
            deleteItemName={deleteItemName}
            deleteItemId={id}
        />
    )
}

export default DeleteEnvironmentModal;