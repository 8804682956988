import ClusterDetails from "./ClusterPage/clusterDetails";
import CisBenchmarkCompliance from "./ClustersPage/cisBenchmarkCompliance";
import { APP_PATHS } from "utils/systemConsts";
import { Switch, Route } from "react-router-dom";

import "./cis-benchmark.scss";

const CisBenchmark = () => (
  <Switch>
    <Route
      path={`${APP_PATHS.CIS_BENCHMARK.path}/:clusterId`}
      component={ClusterDetails}
    />
    <Route path={APP_PATHS.CIS_BENCHMARK.path} component={CisBenchmarkCompliance} />
  </Switch>
);

export default CisBenchmark;
