import React, { useMemo, useRef, useEffect } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { isNull, isEmpty } from 'lodash';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import Table, { tableUtils, useTimeFilter, TimeFilter } from 'components/Table';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';
import { formatDate } from 'utils/generalUtils';
import { L7_PROTOCOLS, ENVIRONMENT_TYPES_ITEMS, IP_TYPES_ITEMS, POD_TYPES_ITEMS,
    CONNECTIONS_POLICY_URL } from 'layout/Policies/ConnectionsPolicy';
import WorkloadNameDisplay from 'layout/Runtime/WorkloadNameDisplay';
import RuleActionsDisplay from 'layout/Runtime/RuleActionsDisplay';
import { FiltersForm, FilterContext, RUNTIME_CONNECTION_FILTER_KEYS } from '../Filter';

import './connections-table.scss';

const SORT_KEYS = {
    LAST_SEEN: "lastSeen"
}

export const WOKLOAD_TYPES_MAP = {
    POD: "Pod",
    EXPANSION: "Expansion",
    EXTERNAL: "External"
};

const formatEndpointPolicyData = ({environment, fqdn, ip, pod}) => {
    const endpointData = {};
    const {name: envName} = environment;

    if (!!pod.name) {
        endpointData.names = [pod.name];
        endpointData.environments = !!envName ? [envName] : [];
        endpointData.connectionRulePartType = POD_TYPES_ITEMS.NAME.value;
    } else if (!!envName) {
        endpointData.environments = [envName];
        endpointData.connectionRulePartType = ENVIRONMENT_TYPES_ITEMS.NAME.value;
    } else if (!!fqdn) {
        endpointData.fqdnAddresses = [fqdn];
        endpointData.connectionRulePartType = IP_TYPES_ITEMS.FQDN.value;
    } else {
        endpointData.networks = [ip];
        endpointData.connectionRulePartType = IP_TYPES_ITEMS.IP_RANGE.value;
    }

    return endpointData;
}

const formatLayer7PolicyData = (layer7Attributes) => {
    const attributesMap = layer7Attributes.reduce((acc, curr) => {
        const {key, values} = curr;

        acc[key.toLowerCase()] = values;

        return acc;
    }, {});
    
    const methods = attributesMap.method;
    const paths = attributesMap.path;

    return (!methods && !paths) ? null : {layer7Protocol: L7_PROTOCOLS.HTTP, methods, paths};
}

const AddressRenderer = ({ip, fqdn}) => (
    <tableUtils.WithSubtitleRenderer title={!!fqdn ? fqdn : ip} subTitle={!!fqdn && !!ip ? ip : ""} />
);

const ConnectionsTable = () => {
    const columns = useMemo(() => [
        {
            Header: "Source", columns: [
                {
                    Header: 'Workload',
                    id: "sourceAppName",
                    Cell: ({row}) => {
                        const {id, source} = row.original;
                        const {pod, expansion, fqdn, ip, workloadType} = source;
                        
                        if (!!pod.id || !!expansion.id) {
                            return (
                                <WorkloadNameDisplay id={`source-${id}`} pod={pod} expansion={expansion} workloadType={workloadType} />
                            );
                        }
                        
                        return (
                            <AddressRenderer ip={ip} fqdn={fqdn} />
                        );
                    },
                    defaultCanSort: true,
                    alwaysShow: true
                },
                {
                    Header: "Labels",
                    id: "sourceLabels",
                    Cell: ({row}) => <tableUtils.KeyValueRenderer items={row.original.source.pod.labels} />,
                    disableSortBy: true,
                    hide: true,
                    width: 120
                },
                {
                    Header: "Workload Type",
                    id: "sourceWorkloadType",
                    accessor: original => WOKLOAD_TYPES_MAP[original.source.workloadType],
                    disableSortBy: true
                },
                {
                    Header: "Environment",
                    id: "sourceEnvironmentName",
                    accessor: "source.environment.name"
                },
                {
                    Header: "Cluster",
                    id: "sourceHostName",
                    accessor: original => !!original.source.instance && original.source.instance.name
                },
                {
                    Header: "Namespace",
                    id: "sourceNamespace",
                    accessor: original => !!original.source.instance && original.source.instance.namespace,
                    disableSortBy: true
                }
            ]
        },
        {
            Header: "Destination", columns: [
                {
                    Header: 'Workload',
                    id: "targetAppName",
                    Cell: ({row}) => {
                        const {id, target} = row.original;
                        const {pod, expansion, fqdn, ip, workloadType} = target;
                        
                        if (!!pod.id || !!expansion.id) {
                            return (
                                <WorkloadNameDisplay id={`target-${id}`} pod={pod} expansion={expansion} workloadType={workloadType} />
                            );
                        }
                        
                        return (
                            <AddressRenderer ip={ip} fqdn={fqdn} />
                        );
                    },
                    defaultCanSort: true,
                    alwaysShow: true
                },
                {
                    Header: "Labels",
                    id: "targetLabels",
                    Cell: ({row}) => <tableUtils.KeyValueRenderer items={row.original.target.pod.labels} />,
                    disableSortBy: true,
                    hide: true,
                    width: 120
                },
                {
                    Header: "Workload Type",
                    id: "targetWorkloadType",
                    accessor: original => WOKLOAD_TYPES_MAP[original.target.workloadType],
                    disableSortBy: true
                },
                {
                    Header: "Environment",
                    id: "targetEnvironmentName",
                    accessor: "target.environment.name"
                },
                {
                    Header: "Cluster",
                    id: "targetHostName",
                    accessor: original => !!original.target.instance && original.target.instance.name
                },
                {
                    Header: "Namespace",
                    id: "targetNamespace",
                    accessor: original => !!original.target.instance && original.target.instance.namespace,
                    disableSortBy: true
                }
            ]
        },
        {
            Header: "Connections", columns: [
                {
                    Header: "Status",
                    id: "status",
                    Cell: ({row}) => {
                        const {isOpen} = row.original.state;
                                
                        return (
                            <tableUtils.StatusRenderer isActive={isOpen} customText={isOpen ? "Established" : "Ended"} />
                        )
                    }
                },
                {
                    Header: "Start time",
                    id: "startTime",
                    accessor: original => formatDate(original.state.startTime),
                    width: 130
                },
                {
                    Header: "Last seen",
                    id: SORT_KEYS.LAST_SEEN,
                    accessor: original => formatDate(original.state.lastSeen),
                    width: 130
                },
                {
                    Header: "Total",
                    id: "total",
                    accessor: "state.count",
                    alwaysShow: true
                },
                {
                    Header: "Result",
                    id: "result",
                    Cell: ({row}) => {
                        const {id: rowId, original} = row;
                        const {defaultRule, directPodRule, userRule, encryptRule, encryptionReason} = original.violation;

                        return (
                            <RuleActionsDisplay
                                tooltipId={String(rowId)}
                                defaultRule={defaultRule}
                                implicitRule={directPodRule}
                                userRule={userRule}
                                encryptRule={encryptRule}
                                encryptionReason={encryptionReason}
                            />
                        )
                    },
                    hide: true
                },
                {
                    Header: "Protocol",
                    id: "protocol",
                    accessor: "state.protocol",
                    hide: true,
                    disableSortBy: true
                },
                {
                    Header: "Last violation",
                    id: "lastViolation",
                    accessor: original => original.violation ? formatDate(original.violation.lastViolation) : "",
                    hide: true,
                    width: 130
                },
                {
                    Header: "Layer 7 Attributes",
                    id: "layer7Attributes",
                    hide: true,
                    Cell: ({row}) => (
                        row.original.state.layer7Attributes.map(({key, values}) => 
                            <div key={key}>{`${key} : ${values.join(", ")}`}</div>)
                    ),
                    width: 120
                },
            ]
        }
    ], []);

    const {isReadOnlyUser} = useAuthState();

    const location = useLocation();
    const {filters: queryFilters, timeRange} = location.query || {};

    const history = useHistory();
    const {path} = useRouteMatch();

    const [{selected, endTime, startTime, loading}, refreshTimes, selectTime] = useTimeFilter(timeRange);

    const filters = FilterContext.useFilterState();
    const filterDispatch = FilterContext.useFilterDispatch();
    
    const handleRuleAdd = ({source, target, state}) => {
        const rule = {
            source: formatEndpointPolicyData(source),
            destination: formatEndpointPolicyData(target),
            action: "DETECT"
        };

        const {layer7Attributes} = state;

        if (!isEmpty(layer7Attributes)) {
            const layer7Settings = formatLayer7PolicyData(layer7Attributes);

            if (!!layer7Settings) {
                rule.layer7Settings = layer7Settings;
            }
        }

        history.push({pathname: CONNECTIONS_POLICY_URL, query: {ruleInitialData: rule}});
    }

    const mounted = useRef(true);
    const inititalRefreshTimes = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!inititalRefreshTimes.current) {
            inititalRefreshTimes.current = true;
            refreshTimes();
        }
    }, [refreshTimes]);

    const sourceNamespacesFilter = filters[RUNTIME_CONNECTION_FILTER_KEYS.SOURCE_NAMESPCASES];
    const targetNamespacesFilter = filters[RUNTIME_CONNECTION_FILTER_KEYS.TARGET_NAMESPCASES];
    
    return (
        <div className="runtime-connections-page">
            <div className="table-filters-container">
                <TimeFilter
                    defaultSelected={selected}
                    onTimeFilterChange={selectTime}
                    startTime={startTime}
                    endTime={endTime}
                    style={{marginRight: "10px"}}
                />
                {!isNull(startTime) &&
                    <FiltersForm
                        filters={filters}
                        queryFilters={queryFilters}
                        setFilters={updatedFilters => {
                            if (!mounted.current) {
                                return;
                            }
    
                            FilterContext.setFilters(filterDispatch, updatedFilters);
                        }}
                    />
                }
            </div>
            <Table
                url="connectionTelemetries"
                name="runtime-connections"
                columns={columns}
                filters={{
                    ...filters,
                    sourceNamespacesFilter: btoa(JSON.stringify(sourceNamespacesFilter)),
                    targetNamespacesFilter: btoa(JSON.stringify(targetNamespacesFilter)),
                    startTime,
                    endTime
                }}
                defaultSortBy={[{id: SORT_KEYS.LAST_SEEN, desc: true}]}
                customOnRefresh={refreshTimes}
                exportToExcel={true}
                isLoading={loading || filters.filterLoading}
                actionsComponent={({original}) => {
                    const {id} = original;
                    const createRuleTooltipId = `${id}-create-rule`;

                    return (
                        <IconWithTooltip
                            className={classnames("connections-add-rule-icon", {"audit-mode": isReadOnlyUser})}
                            name={ICON_NAMES.ADD_RULE}
                            onClick={event => {
                                event.stopPropagation();
                                event.preventDefault();
                                
                                handleRuleAdd(original);
                            }}
                            tooltipId={createRuleTooltipId}
                            tooltipText="Create new rule"
                        />
                    );
                }}
                actionsCellWidth={50}
                onLineClick={({id}) => history.push({
                    pathname: `${path}/${id}`,
                    search: `?startTime=${startTime}&endTime=${endTime}`
                })}
            />
        </div>
    )
}

export default ConnectionsTable;
