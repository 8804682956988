import React from 'react';
import { isEmpty } from 'lodash';
import DisplaySection from 'components/DisplaySection';
import InnerTitle from '../InnerTitle';
import UnorderedList from '../UnorderedList';

const DIFF_TYPES = {
    ADDED: "ADDED",
    DELETED: "DELETED",
    MODIFIED: "MODIFIED"
}

const DiffsSection = ({title, diffs}) => {
    if (isEmpty(diffs)) {
        return null;
    }

    const ItemDisplay = ({title, value}) => <div><span style={{fontWeight: "bold"}}>{`${title}: `}</span>{value}</div>
    
    return (
        <div className="diffs-section-wrapper">
            <InnerTitle>{title}</InnerTitle>
            <UnorderedList items={diffs.map(({path, oldValue, value}) => (
                <div>
                    <ItemDisplay title="Path" value={path} />
                    {!!oldValue && <ItemDisplay title="Original value" value={oldValue} />}
                    {!!value && <ItemDisplay title="Updated value" value={value} />}
                </div>
            ))} />
        </div>
    )
}

const DiffsDisplay = ({diffs}) => {
    const getDiffsByType = type => diffs.filter(diff => diff.diffType === type);

    if (isEmpty(diffs)) {
        return (
            <DisplaySection title="Diffs">
                No diffs detected
            </DisplaySection>
        )
    }
    
    return (
        <DisplaySection title="Diffs" allowClose={true}>
            <DiffsSection title={DIFF_TYPES.ADDED} diffs={getDiffsByType(DIFF_TYPES.ADDED)} />
            <DiffsSection title={DIFF_TYPES.DELETED} diffs={getDiffsByType(DIFF_TYPES.DELETED)} />
            <DiffsSection title={DIFF_TYPES.MODIFIED} diffs={getDiffsByType(DIFF_TYPES.MODIFIED)} />
        </DisplaySection>
    )
}

export default DiffsDisplay;