import React from 'react';
import { VULNERABILITY_LEVELS, CI_CD_ENFORCEMENT_OPTIONS, DOCKERFILE_SCAN_SEVERITY } from 'utils/systemConsts';
import ListValueItemsDisplay from 'components/ListValueItemsDisplay';
import BaseUnorderedPolicyItem from '../../BaseUnorderedPolicyItem';

const CdItemDisplay = ({data, disabled, onEdit, onDelete}) => {
    const {id, name, description, vulnerabilityCiPolicy, dockerfileScanCiPolicy} = data;

    let displayItems = [];

    if (!!vulnerabilityCiPolicy.enforcementOption) {
        displayItems = [
            ...displayItems,
            {name: "Permissive Vulnerability Level", values: [VULNERABILITY_LEVELS[vulnerabilityCiPolicy.permissibleVulnerabilityLevel].label]},
            {name: "Vulnerability Enforcement Option", values: [CI_CD_ENFORCEMENT_OPTIONS[vulnerabilityCiPolicy.enforcementOption].label]}
        ]
    }

    if (!!dockerfileScanCiPolicy.enforcementOption) {
        displayItems = [
            ...displayItems,
            {name: "Permissive Dockerfile Scan Severity", values: [DOCKERFILE_SCAN_SEVERITY[dockerfileScanCiPolicy.permissibleDockerfileScanSeverity].label]},
            {name: "Dockerfile Enforcement Option", values: [CI_CD_ENFORCEMENT_OPTIONS[dockerfileScanCiPolicy.enforcementOption].label]}
        ]
    }

    return (
        <BaseUnorderedPolicyItem
            id={id}
            name={name}
            description={description}
            disabled={disabled}
            canEditDelete={true}
            canCopy={false}
            onEdit={onEdit}
            onDelete={onDelete}
            alwaysOpen={true}
        >
            <ListValueItemsDisplay items={displayItems} />
        </BaseUnorderedPolicyItem>
    )
}

export default CdItemDisplay;