import React from 'react';
import { isNull } from 'lodash';
import { Link } from 'react-router-dom';
import DisplaySection from 'components/DisplaySection';
import DoublePaneDisplay from 'components/DoublePaneDisplay';
import TableSimple from 'components/TableSimple';
import RiskTag from 'components/RiskTag';
import { LinkToRule } from 'layout/Policies';
import { DEPLOYMENT_POLICY_URL } from 'layout/Policies/DeploymentPolicy';
import { EXTERNAL_API_PAGE_PATH } from 'layout/Apis/ExternalApis';
import { INTERNAL_API_PAGE_PATH } from 'layout/Apis/InternalApis';
import { TokenNameLink } from 'layout/Runtime/utils';
import TokenInjectionStatus, { INJECTION_STATUSES } from 'layout/Runtime/Workloads/TokenInjectionStatus';

const TYPE_TO_PATH = {
    INTERNAL: INTERNAL_API_PAGE_PATH,
    EXTERNAL: EXTERNAL_API_PAGE_PATH
}

const TabApi = ({tokensData, risksData}) => (
    <DoublePaneDisplay
        leftPane={() => (
            <DisplaySection title="API tokens">
                <TableSimple
                    headers={["API Token", "ENV VAriable", "API Token Injection", "API Token Injection Rules"]}
                    rows={tokensData.map(({tokenName, envVariable, ruleName, ruleId, isRuleDeleted, isTokenDeleted, error}) =>
                        ([
                            <TokenNameLink name={tokenName} isDeleted={isTokenDeleted} />,
                            envVariable,
                            <TokenInjectionStatus
                                id={tokenName}
                                message={isNull(error) ? "Injected" : error}
                                status={isNull(error) ? INJECTION_STATUSES.SUCCESS : INJECTION_STATUSES.ERROR}
                            />,
                            <LinkToRule pathname={DEPLOYMENT_POLICY_URL} deleted={isRuleDeleted} ruleName={ruleName} id={ruleId} />
                        ]))}
                    emptyText="No tokens were injected"
                />
            </DisplaySection>
        )}
        rightPane={() => (
            <DisplaySection title="API risks">
                <TableSimple
                    headers={["API Name", "Risk"]}
                    rows={risksData.map(({serviceName, serviceRisk, serviceType}) =>
                        ([
                            <Link to={{pathname: TYPE_TO_PATH[serviceType], query: {filters: {name: serviceName}}}} >{serviceName}</Link>,
                            <RiskTag risk={serviceRisk} />
                        ]))}
                    emptyText="No APIs risks"
                />
            </DisplaySection>
        )}
    />
)

export default TabApi;