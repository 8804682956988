import React from 'react';
import { isEmpty } from 'lodash';
import { FETCH_METHODS, useMountMultiFetch } from 'hooks';
import Wizard from 'components/Wizard';
import Loader from 'components/Loader';
import { nameIdToValueLabel } from 'utils/apiUtils';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import FormStep1 from './FormStep1';
import FormStep2, { EMPTY_VALUE } from './FormStep2';
import FormStep3 from './FormStep3';

const TrustedSignerForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const {showServerlessSecurity} = useDisplayConfigState();

    const [{loading, data}] = useMountMultiFetch([
        {key: "clusters", url: "leanKubernetesClusters"},
        ...(showServerlessSecurity ? [{key: "cloudAccounts", url: "cloudAccounts", queryParams: {noPagination: true}}] : [])
    ]);

    if (loading) {
        return <Loader />
    }

    const isEditForm = !!initialData && initialData.id;
    const wizardSteps = [
        {
            title: "Profile Name",
            component: FormStep1
        },
        {
            title: "Development CI Keys",
            component: FormStep2
        },
        {
            title: "Scope",
            component: FormStep3
        }
    ];
    
    const initialValues = {
        name: "",
        keys: [EMPTY_VALUE],
        trustedSignerClusters: [],
        trustedSignerCloudAccounts: [],
        ...initialData
    }

    initialValues.trustedSignerClusters = initialValues.trustedSignerClusters.map(({id}) => id);
    initialValues.trustedSignerCloudAccounts = initialValues.trustedSignerCloudAccounts.map(({id}) => id);

    const {clusters, cloudAccounts} = data || {};

    return (
        <Wizard
            initialValues={initialValues}
            title={`${isEditForm ? "Edit" : "New"} Trust Profile`}
            steps={wizardSteps}
            submitUrl="trustedSigners"
            formProps={{
                clusterItems: nameIdToValueLabel(clusters),
                cloudAccountsItems: nameIdToValueLabel(cloudAccounts),
                showServerlessSecurity
            }}
            getSubmitParams={formValues => {
                const {id, ...submitData} = formValues;

                submitData.trustedSignerClusters = submitData.trustedSignerClusters.map(id => ({id}));
                submitData.trustedSignerCloudAccounts = submitData.trustedSignerCloudAccounts.map(id => ({id}));

                if (isEmpty(submitData.trustedSignerClusters)) delete submitData.trustedSignerClusters;
                if (isEmpty(submitData.trustedSignerCloudAccounts)) delete submitData.trustedSignerCloudAccounts;

                return !isEditForm ? {submitData} : {
                    method: FETCH_METHODS.PUT,
                    formatUrl: url => `${url}/${id}`,
                    submitData
                }
            }}
            onSubmitSuccess={onFormSubmitSuccess}
            onDirtyChanage={onDirtyChanage}
        />
    );
}

export default TrustedSignerForm;