import React, { useLayoutEffect } from 'react';
import classnames from 'classnames';
import { isEmpty, isNull } from 'lodash';
import { useFetch, useDelete, usePrevious } from 'hooks';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import { ConfirmationWizard, TextElement, InfoMessage, InnerTitle, LinksList, NotificationMessage, SectionSeparator } from 'components/ConfirmationWizardModal';

import './delete-wizard-modal.scss';

export {
    TextElement,
    InfoMessage,
    InnerTitle,
    LinksList,
    NotificationMessage,
    SectionSeparator
}

const ConfirmationComponent = ({stepProps}) =>
    <NotificationMessage>{`Are you sure you want to delete ${stepProps.deleteItemName} and detach/delete its dependencies?`}</NotificationMessage>

const CONFIRMATION_STEP_ID = "CONFIRMATION_STEP_ID";
const CONFIRMATION_STEP = {id: CONFIRMATION_STEP_ID, title: "Confirmation", dataKey: null, component: ConfirmationComponent};

const DeleteWizard = ({data, defaultDeleteSteps, title, description, deleteItemName, deleteItemId, deleteUrl, onDeleteSuccess, onClose}) => {
    const deleteSteps = [];
    defaultDeleteSteps.forEach(step => {
        const stepData = data[step.dataKey];

        if (!isEmpty(stepData)) {
            deleteSteps.push({...step, stepNumber: deleteSteps.length + 1});
        }
    });
    deleteSteps.push({...CONFIRMATION_STEP, stepNumber: deleteSteps.length + 1});

    const [{deleting, error}, deleteItem] = useDelete(deleteUrl);
    const prevDeleting = usePrevious(deleting);

    useLayoutEffect(() => {
        if (prevDeleting && !deleting) {
            if (isNull(error)) {
                onDeleteSuccess();
            }
            
            onClose();
        }
    }, [prevDeleting, deleting, error, onClose, onDeleteSuccess]);

    return (
        <ConfirmationWizard
            data={data}
            wizardSteps={deleteSteps}
            title={title}
            description={description}
            stepProps={{deleteItemName}}
            loading={deleting}
            onConfirm={() => deleteItem(deleteItemId)}
            confirmText="Detach / Delete All"
        />
    )
}

const DeleteWizardModal = ({deleteSteps, data, url, className, ...props}) => {
    const [{loading, data: modalData}] = useFetch(url, {loadOnMount: !!url});

    return (
        <Modal className={classnames("delete-modal", {[className]: !!className})} center={true} onClose={props.onClose}>
            {loading ? <Loader /> : <DeleteWizard {...props} data={!!url ? modalData : data} defaultDeleteSteps={deleteSteps} />}
        </Modal>
    )
}

export default DeleteWizardModal;