import React, { useState } from 'react';
import { isEmpty, isNull } from 'lodash';
import { Link } from 'react-router-dom';
import { CONNECTION_ENDPOINT_TYPES } from 'utils/systemConsts';
import { useTimeRangeDispatch, TIME_RANGE_ACTIONS } from 'context/TimeRangeProvider';
import DropdownSelect from 'components/DropdownSelect';
import Modal from 'components/Modal';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Text, { TEXT_TYPES } from 'components/Text';
import AdvisorAffectedConnections from './AdvisorAffectedConnections';
import { RUNTIME_CONNECTIONS_PAGE_PATH } from 'layout/Runtime/utils';
import { RUNTIME_CONNECTION_FILTER_KEYS } from 'layout/Runtime/Connections';
import Advisor, { ContentItemDisplay, ADVISOR_TYEPS } from 'layout/Advisor';
import { ENVIRONMENT_TYPES_ITEMS, IP_TYPES_ITEMS, POD_TYPES_ITEMS } from '../RuleSubForms';
import { ENDPOINT_TYPE_ITEMS } from '../utils';

import './connections-policy-advisor.scss';

const ADVISOR_ACTION_ITEMS = [
    {value: "ALLOW", label: "Allow only the connection between the source and destination", labelShort: "Allow"},
    {value: "DETECT", label: "Detect the connection between the source and destination", labelShort: "Detect"},
    {value: "BLOCK", label: "Block the connection between the source and destination", labelShort: "Block"}
];
const ADVISOR_ENCRYPT_ACTION_ITEM = {
    value: "ENCRYPT",
    label: "Encrypt connections between the source and destination",
    labelShort: "Encrypt"
}
const ADVISOR_TYPES_MAP = {
    [POD_TYPES_ITEMS.NAME.value]: "By Pod, by name",
    [ENVIRONMENT_TYPES_ITEMS.NAME.value]: "By Environment, by name",
    [IP_TYPES_ITEMS.FQDN.value]: "By Address",
    [IP_TYPES_ITEMS.IP_RANGE.value]: "By IP Range",
    [ENDPOINT_TYPE_ITEMS.EXTERNAL.value]: "By External",
    [POD_TYPES_ITEMS.ANY.value]: "By Pod, any"
};

const getAdvisorActions = suggestionData => {
    return suggestionData.source.connectionRulePartType === POD_TYPES_ITEMS.NAME.value ?
        [...ADVISOR_ACTION_ITEMS] : [...ADVISOR_ACTION_ITEMS, ADVISOR_ENCRYPT_ACTION_ITEM];
}

const AdvisorActionSelector = ({data, onItemEdit}) => (
    <div className="suggestion-action-content" style={{marginBottom: "15px"}}>
        <div className="suggestion-content-title">Action</div>
        <div className="suggestion-content-label">Select action</div>
        <DropdownSelect
            items={getAdvisorActions(data)}
            defaultselected={data.action}
            name="action"
            onChange={action => onItemEdit({...data, action})}
            className="action-select"
        />
    </div>
);

const AdvisorHeaderActionSelector = ({data, onItemEdit}) => (
    <div className="suggestion-action-header" style={{width: "110px"}}>
        <DropdownSelect
            items={getAdvisorActions(data).map(({value, labelShort}) => ({value, label: labelShort}))}
            defaultselected={data.action}
            name="action"
            onChange={action => onItemEdit({...data, action})}
            className="action-select"
        />
    </div>
);

const AdvisorEndpointsDisplay = ({data}) => (
    <div style={{display: "flex"}}>
        <ContentItemDisplay
            data={data}
            title="Source"
            subTitle={ADVISOR_TYPES_MAP[data.source.connectionRulePartType]}
            displayData={[
                {title: "Names", valueKey: "source.names", isList: true},
                {title: "Environments", valueKey: "source.environments", isList: true},
                {title: "Addresses", valueKey: "source.fqdnAddresses", isList: true},
                {title: "Ranges", valueKey: "source.networks", isList: true},
                {title: "Risks", valueKey: "source.risks", isList: true},
                {title: "Highest Vulnerability", valueKey: "source.vulnerabilitySeverityLevel"}
            ]}
        />
        <ContentItemDisplay
            data={data}
            title="Destination"
            subTitle={ADVISOR_TYPES_MAP[data.destination.connectionRulePartType]}
            displayData={[
                {title: "Names", valueKey: "destination.names", isList: true},
                {title: "Environments", valueKey: "destination.environments", isList: true},
                {title: "Addresses", valueKey: "destination.fqdnAddresses", isList: true},
                {title: "Ranges", valueKey: "destination.networks", isList: true},
                {title: "Risks", valueKey: "destination.risks", isList: true},
                {title: "Highest Vulnerability", valueKey: "destination.vulnerabilitySeverityLevel"}
            ]}
        />
    </div>
);

const formatData = data => {
    return data.map(item => ({
        recommendations: item.connectionsRuleRecommendations.map(recommendation => ({
            id: recommendation.id,
            affected: recommendation.numberOfAffectedConnections,
            recommendation: recommendation.connectionRule
        })),
        total: item.totalConnections,
        timePeriod: item.timePeriod
    }));
}

const getEndpointFilter = (endpoint, prefix) => {
    const filtersData = [];
    const {names, environments, fqdnAddresses, networks} = endpoint;
    const workloadFilterKey = prefix === CONNECTION_ENDPOINT_TYPES.SOURCE ? RUNTIME_CONNECTION_FILTER_KEYS.SOURCE_WORKLOAD_NAME : RUNTIME_CONNECTION_FILTER_KEYS.TARGET_WORKLOAD_NAME;
    const environmentFilterKey = prefix === CONNECTION_ENDPOINT_TYPES.SOURCE ? RUNTIME_CONNECTION_FILTER_KEYS.SOURCE_ENVIRONMENT_NAME : RUNTIME_CONNECTION_FILTER_KEYS.TARGET_ENVIRONMENT_NAME;

    if (!isEmpty(names)) {
        filtersData[workloadFilterKey] = names[0];
    } else if (!isEmpty(fqdnAddresses)) {
        filtersData[workloadFilterKey] = fqdnAddresses[0].replace("*", "");
    } else if (!isEmpty(networks)) {
        filtersData[workloadFilterKey] = networks[0];
    }

    if (!isEmpty(environments)) {
        filtersData[environmentFilterKey] = environments[0];
    }
    
    return filtersData;
}


const ConnectionsAdvisor = ({onAddSuggestions, initialExpanded, isReadOnly}) => {
    const dispatch = useTimeRangeDispatch();

    const [selectedAffectedConnectionData, setSelectedAffectedConnectionData] = useState(null);
    const closeAffectedConnections = () => setSelectedAffectedConnectionData(null);

    const getConnectionsFilter = () => {
        const {recommendation, startDate, endDate} = selectedAffectedConnectionData;
    
        return {
            ...getEndpointFilter(recommendation.source, "source"),
            ...getEndpointFilter(recommendation.destination, "target"),
            startTime: startDate,
            endTime: endDate
        };
    }

    const {recommendation, count, selectedRange} = selectedAffectedConnectionData || {};
    const tableFilters = !!selectedAffectedConnectionData ? getConnectionsFilter() : [];

    return (
        <React.Fragment>
            <Advisor
                advisorType={ADVISOR_TYEPS.CONNECTION_RULES}
                url="advisor/connectionRules"
                title="Connections"
                emptyMessage="No rules suggestions"
                applyTitle="Apply Rule"
                buttonTitle="Policy Advisor"
                formatData={formatData}
                onItemsAdd={onAddSuggestions}
                contentDisplayItems={[
                    AdvisorActionSelector,
                    AdvisorEndpointsDisplay
                ]}
                headerDisplayItem={AdvisorHeaderActionSelector}
                onAffectedDescClick={selectedData => setSelectedAffectedConnectionData(selectedData)}
                initialExpanded={initialExpanded}
                isReadOnly={isReadOnly}
            />
            {!isNull(selectedAffectedConnectionData) &&
                <Modal onClose={closeAffectedConnections} className="affected-connections-modal">
                    <div className="affected-header">
                        <Text type={TEXT_TYPES.H2}>
                            {`${recommendation.name} - ${count} Affected Connection${count > 1 ? "s" : ""}`}
                        </Text>
                        <Link
                            to={{pathname: RUNTIME_CONNECTIONS_PAGE_PATH,
                            query: {filters: tableFilters, violationsOnly: true, selectedTime: selectedRange}}}
                        >
                            <IconWithTitle
                                className="all-connections-link"
                                name={ICON_NAMES.SMALL_ARROW_LEFT}
                                title="View all connections"
                                onClick={() => {
                                    dispatch({type: TIME_RANGE_ACTIONS.TIME_RANGE_SET, payload: {selected: selectedRange}});
                                }}
                            />
                        </Link>
                    </div>
                    <AdvisorAffectedConnections filters={tableFilters} />
                </Modal>}
        </React.Fragment>
    );
};

export default ConnectionsAdvisor;