import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { cloneDeep, isNull, isEmpty } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { RULE_ACTIONS } from 'utils/systemConsts';
import Loader from 'components/Loader';
import FormModal from 'components/FormModal';
import { ICON_NAMES } from 'components/Icon';
import { useMountMultiFetch } from 'hooks';
import useGroupedRulesPolicyReducer, { POLICY_ACTIONS, formatUserRulesForSubmit } from 'layout/Policies/policyHooks/useGroupedRulesPolicyReducer';
import useRulesSearchLoaderReducer, { SEARCH_LOADER_ACTIONS } from 'layout/Policies/policyHooks/useRulesSearchLoaderReducer';
import BaseGroupedPolicy, { BaseGroupedRulesList } from '../BaseGroupedPolicy';
import { formatUserRulesForDisplay } from '../utils';
import { DefaultRuleForm, DefaultRuleItem } from './DefaultRule';
import { PortshiftRuleForm, PortshiftRuleItem } from './PortshiftRule';
import UserRuleForm, { L7_PROTOCOLS } from './UserRuleForm';
import EncryptRuleForm from './EncryptRuleForm';
import BasePolicyHistoryButtonModal from '../BasePolicyHistoryButtonModal';
import { POLICY_TYPE, ENDPOINT_TYPE_ITEMS } from './utils';
import { ENVIRONMENT_TYPES_ITEMS, EXPANSION_TYPE_ITEMS, IP_TYPES_ITEMS, POD_TYPES_ITEMS } from './RuleSubForms';
import ConnectionsAdvisor from './ConnectionsAdvisor';
import UserRuleContent from './UserRuleContent';

export {
    L7_PROTOCOLS,
    ENDPOINT_TYPE_ITEMS,
    ENVIRONMENT_TYPES_ITEMS,
    EXPANSION_TYPE_ITEMS,
    IP_TYPES_ITEMS,
    POD_TYPES_ITEMS
}

export const CONNECTIONS_POLICY_URL =  "/policies/connections";

const POLICY_URL = "connectionsPolicy";

const IMPLICIT_RULE_KEY =  "directPodRule";

const formatSubmitData = data => {
    const {userRules, defaultRule, implicitRule} = data;
    const formattedUserRules = formatUserRulesForSubmit(userRules);

    return {
        defaultRule: {
            type: defaultRule.type,
            action: defaultRule.action
        },
        [IMPLICIT_RULE_KEY]: {action: implicitRule.action, isDisabled: implicitRule.isDisabled},
        userRules: formattedUserRules
    }
}

const ConnectionsPolicy = ({displayParamsData}) => {
    const {isReadOnlyUser} = useAuthState();

    const location = useLocation();
    const {openAdvisor, ruleInitialData} = location.query || {};

    const [showDefaultRuleForm, setShowDefaultRuleForm] = useState(false);
    const closeDefaultRuleForm = () => setShowDefaultRuleForm(false);

    const [showImplicitRuleForm, setShowImplicitRuleForm] = useState(false);
    const closeImplicitRuleForm = () => setShowImplicitRuleForm(false);

    const [encryptRuleFormData, setEncryptRuleFormData] = useState(null);
    const closeEncryptRuleForm = () => setEncryptRuleFormData(null);

    const [connectionRuleFormData, setConnectionRuleFormData] = useState(isEmpty(ruleInitialData) ? null : ruleInitialData);
    const closeConnectionRuleForm = () => setConnectionRuleFormData(null);

    const showUserRuleForm = ruleData => {
        const encryptActions = [RULE_ACTIONS.ENCRYPT, RULE_ACTIONS.ENCRYPT_DIRECT];

        if (encryptActions.includes(ruleData.action)) {
            setEncryptRuleFormData(ruleData);
        } else {
            setConnectionRuleFormData(ruleData);
        }
    };
    
    const [{policyState, loading}, dispatch] = useGroupedRulesPolicyReducer({policyUrl: POLICY_URL, implicitRuleKey: IMPLICIT_RULE_KEY});
    const {userRules, defaultRule, implicitRule, withUnsavedEdits, markedUserRuleIds, markDefault, markImplicit} = cloneDeep(policyState);

    const [{searching, selectedSearchFilter, selectedRuleIDs}, searchDispatch] = useRulesSearchLoaderReducer({policyUrl: POLICY_URL});

    useEffect(() => {
        dispatch({type: POLICY_ACTIONS.SET_MARKED_USER_RULES, payload: selectedRuleIDs});
    }, [selectedRuleIDs, dispatch]);

    const reloadPolicyData = () => dispatch({type: POLICY_ACTIONS.LOAD_POLICY_DATA});

    const newRuleTitle = `New ${POLICY_TYPE} rule`;
    const onAddRuleClick = () => {
        setConnectionRuleFormData({})
    }

    const newEncryptionRuleTitle = "New Encryption rule";
    const onAddEncryptionRuleClick = () => {
        setEncryptRuleFormData({});
    }

    const formButtonsItems = [
        {id: "add", icon: ICON_NAMES.ADD, title: newRuleTitle, onClick: onAddRuleClick},
        {id: "addEncrypt", icon: ICON_NAMES.KEY_DIAGONAL, title: newEncryptionRuleTitle, onClick: onAddEncryptionRuleClick},
        {id: "edit", icon: ICON_NAMES.EDIT, title: "Edit default rule", onClick: () => setShowDefaultRuleForm(true)}
    ];

    if (loading) {
        return <Loader />;
    }

    const defaultRuleTitle = `${POLICY_TYPE} Default Rule`;
    const implicitRuleTitle = `${POLICY_TYPE} Implicit Rule`;

    return (
        <React.Fragment>
            <BaseGroupedPolicy
                policyTypeName={POLICY_TYPE}
                userRules={userRules}
                isReadOnly={isReadOnlyUser}
                formButtonsItems={formButtonsItems}
                markedIds={markedUserRuleIds}
                withUnsavedEdits={withUnsavedEdits}
                topPanelRuleComponent={() =>
                    <PortshiftRuleItem
                        isReadOnly={isReadOnlyUser}
                        implicitRule={implicitRule}
                        onRuleEdit={() => setShowImplicitRuleForm(true)}
                        isMarked={markImplicit}
                        onToggleStatus={() =>
                            dispatch({type: POLICY_ACTIONS.TOGGLE_IMPLICIT_RULE_STATUS})}
                    />}
                bottomPanelRuleComponent={() =>
                    <DefaultRuleItem
                        isReadOnly={isReadOnlyUser}
                        defaultRule={defaultRule}
                        onRuleEdit={() => setShowDefaultRuleForm(true)}
                        isMarked={markDefault}
                    />}
                historyButtonComponent={() =>
                    <BasePolicyHistoryButtonModal
                        url={POLICY_URL}
                        formatUserRules={formatUserRulesForDisplay}
                        formatSubmitData={data => formatSubmitData({...data, implicitRule: data[IMPLICIT_RULE_KEY]})}
                        policyDisplayComponent={({data}) => (
                            <BaseGroupedRulesList
                                policyTypeName={POLICY_TYPE}
                                userRules={data.userRules}
                                isReadOnly={true}
                                disableViewRule={true}
                                toggleGroupOpenInternal={true}
                                topPanelRuleComponent={() =>
                                    <PortshiftRuleItem
                                        isReadOnly={true}
                                        implicitRule={data[IMPLICIT_RULE_KEY]}
                                    />
                                }
                                bottomPanelRuleComponent={() =>
                                    <DefaultRuleItem
                                        isReadOnly={true}
                                        defaultRule={data.defaultRule}
                                    />
                                }
                                userRuleContentComponent={props =>
                                    <UserRuleContent {...props} {...displayParamsData} />
                                }
                            />)
                        }
                        onClose={reloadPolicyData}
                    />
                }
                userRuleContentComponent={props =>
                    <UserRuleContent {...props} {...displayParamsData} />
                }
                selectedSearch={selectedSearchFilter}
                onSelectSearch={selectedSearch => searchDispatch({type: SEARCH_LOADER_ACTIONS.SET_SEARCH_FILTER, payload: selectedSearch})}
                isSearching={searching}
                onRuleCopy={ruleData => {
                    const {id, name, groupName, status, isDragged, inGroup,
                        isRuleActive, ...ruleCopyData} = ruleData;

                        showUserRuleForm(ruleCopyData);
                }}
                onRuleEdit={ruleData => showUserRuleForm(ruleData)}
                onReorder={reorderedRules =>
                    dispatch({type: POLICY_ACTIONS.UPDATE_RULES_ORDER, payload: {reorderedRules}})}
                onGroupNameChange={(groupName, newName) =>
                    dispatch({type: POLICY_ACTIONS.UPDATE_GROUP_NAME, payload: {groupName, newName}})}
                onGroupStatusToggle={(groupName, enable) =>
                    dispatch({type: POLICY_ACTIONS.UPDATE_GROUP_STATUS, payload: {groupName, enable}})}
                onGroupDelete={(groupName) =>
                    dispatch({type: POLICY_ACTIONS.DELETE_GROUP, payload: {groupName}})}
                onRuleDelete={ruleId => dispatch({type: POLICY_ACTIONS.DELETE_RULE, payload: {ruleId}})}
                onRuleToggleStatus={ruleId =>
                    dispatch({type: POLICY_ACTIONS.TOGGLE_RULE_STATUS, payload: {ruleId}})}
                onRemoveFromGroup={ruleId =>
                    dispatch({type: POLICY_ACTIONS.REMOVE_FROM_GROUP, payload: {ruleId}})}
                onRevertPolicy={reloadPolicyData}
                onPolicySave={() =>
                    dispatch({type: POLICY_ACTIONS.SUBMIT_DATA, payload: formatSubmitData({userRules, defaultRule, implicitRule})})}
            />
            {showDefaultRuleForm && 
                <FormModal
                    onClose={closeDefaultRuleForm}
                    formComponent={DefaultRuleForm}
                    formProps={{
                        initialData: {...defaultRule},
                        onDone: ruleData => {
                            dispatch({type: POLICY_ACTIONS.EDIT_DEFAULT_RULE, payload: {ruleData}});
                            closeDefaultRuleForm();
                        },
                        title: isReadOnlyUser ? `${defaultRuleTitle} (Audit mode)` : `Edit ${defaultRuleTitle}`
                    }}
                />
            }
            {showImplicitRuleForm && 
                <FormModal
                    onClose={closeImplicitRuleForm}
                    formComponent={PortshiftRuleForm}
                    formProps={{
                        initialData: {...implicitRule},
                        onDone: ruleData => {
                            dispatch({type: POLICY_ACTIONS.EDIT_IMPLICIT_RULE, payload: {ruleData}});
                            closeImplicitRuleForm();
                        },
                        title: isReadOnlyUser ? `${implicitRuleTitle} (Audit mode)` : `Edit ${implicitRuleTitle}`
                    }}
                />
            }
            {!isNull(connectionRuleFormData) && 
                <FormModal
                    onClose={closeConnectionRuleForm}
                    formComponent={UserRuleForm}
                    formProps={{
                        initialData: {...connectionRuleFormData},
                        onDone: ruleData => {
                            dispatch({type: POLICY_ACTIONS.ADD_OR_EDIT_RULE, payload: {ruleData}});
                            closeConnectionRuleForm();
                        }
                    }}
                />
            }
            {!isNull(encryptRuleFormData) &&
                <FormModal
                    onClose={closeEncryptRuleForm}
                    formComponent={EncryptRuleForm}
                    formProps={{
                        initialData: {...encryptRuleFormData},
                        onDone: ruleData => {
                            dispatch({type: POLICY_ACTIONS.ADD_OR_EDIT_RULE, payload: {ruleData}});
                            closeEncryptRuleForm()
                        }
                    }}
                />
            }
            <ConnectionsAdvisor
                initialExpanded={openAdvisor}
                onAddSuggestions={suggested =>
                    dispatch({type: POLICY_ACTIONS.ADD_RULE_SUGGESTIONS, payload: {suggested}})}
                isReadOnly={isReadOnlyUser}
            />
        </React.Fragment>
    );
};

const ConnectionsPolicyWithApiSecurityWrapper = () => {
    const [{loading, data}] = useMountMultiFetch([
        {key: "internalCatalog", url: "apiSecurity/internalCatalog", queryParams: {noPagination: true, includeServiceWithNoSpec: false}},
        {key: "externalCatalog", url: "apiSecurity/externalCatalog", queryParams: {noPagination: true, includeServiceWithNoSpec: false}}
    ]);

    if (loading) {
        return <Loader />;
    }

    return <ConnectionsPolicy displayParamsData={data} />;
}

const ConnectionsPolicyWrapper = () => {
    const {loadingDisplay, showApiSecurity} = useDisplayConfigState();

    if (loadingDisplay) {
        return <Loader />;
    }

    return showApiSecurity ? <ConnectionsPolicyWithApiSecurityWrapper /> : <ConnectionsPolicy />
}

export default ConnectionsPolicyWrapper;