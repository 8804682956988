import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { useFetch, usePrevious, FETCH_METHODS } from 'hooks';
import { useAuthState } from 'context/AuthProvider';
import { useDisplayConfigState, useDisplayConfigDispatch, DISPLAY_CONFIG_ACTIONS } from 'context/DisplayConfigProvider';
import Icon, { ICON_NAMES } from 'components/Icon';
import IconWithTitle from 'components/IconWithTitle';
import RoundIconContainer from 'components/RoundIconContainer';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Loader from 'components/Loader';
import { WORKLOAD_RISK_TYPE_ITEMS } from 'utils/systemConsts';
import { PERMISSIONS_PAGE_PATH, FILTER_KEYS as PERMISSIONS_FILTER_KEYS } from 'layout/RiskAssessment/Permissions/utils';
import { IGNORE_RISK_TYPES } from 'layout/RiskAssessment/RiskManagement/RiskForm';
import { RISK_MANAGEMENT_PAGE_PATH } from 'layout/RiskAssessment/RiskManagement';

const RiskReasonsDisplay = ({permissionOwnerName, clusterId, podName, telemetryId}) => {
    const [{loading, data, error}, refreshData] = useFetch(`appTelemetries/${telemetryId}`);

    const {isReadOnlyUser} = useAuthState();

    const history = useHistory();

    const displayConfigDispatch = useDisplayConfigDispatch();
    const {permissionsIncludeSystemOwners} = useDisplayConfigState();
    const handleRiskyRoleClick = () => {
        if (!permissionsIncludeSystemOwners) {
            displayConfigDispatch({type: DISPLAY_CONFIG_ACTIONS.TOGGLE_PERMISSIONS_INCLUDE_SYSTEM_OWNERS});
        }
        
        history.push({
            pathname: `${PERMISSIONS_PAGE_PATH}/${clusterId}`,
            query: {filters: {[PERMISSIONS_FILTER_KEYS.OWNER]: permissionOwnerName}}
        })
    }

    const [editView, setEditView] = useState(false);
    const [selectedRiskReasonTypes, setSelectedRiskReasonTypes] = useState([]);
    const closeEditView = () => {
        setEditView(false);
        setSelectedRiskReasonTypes([]);
    };

    const onRiskReasonSelect = (type, checked) => {
        if (checked) {
            setSelectedRiskReasonTypes([...selectedRiskReasonTypes, type]);
        } else {
            setSelectedRiskReasonTypes(selectedRiskReasonTypes.filter(item => item !== type));
        }
    }

    const [{loading: submitting, error: submitError}, submitIgnoreRisk] = useFetch("riskAssessment/ignoredRisks", {loadOnMount: false});
	const prevSubmitting = usePrevious(submitting);

    const handleIgnore = () => {
        const ruleData = {
            ignoredRiskType: IGNORE_RISK_TYPES.WorkloadIgnoredRisk,
            workloadNames: [podName],
            workloadRisksType: [...selectedRiskReasonTypes],
            currentWorkloadId: telemetryId
        }

        submitIgnoreRisk({submitData: ruleData, method: FETCH_METHODS.POST});
    }

    useEffect(() => {
        if (prevSubmitting && !submitting && !submitError) {
            refreshData();
            closeEditView();
        }
    }, [prevSubmitting, submitting, submitError, refreshData]);

    if (loading || submitting) {
        return <Loader absolute={false} />;
    }

    if (error) {
        return null;
    }

    const {risk} = data || {};
    const {reasons: riskReasons} = risk;

    const noRiskReasons = isEmpty(riskReasons);
    const withIgnoreRisk = !!podName; //ignore risk only supported for pod workloads

    return (
        <div className="risk-reasons-wrapper">
            <div className="risk-reasons-actions-wrapper">
                {!editView ? <IconWithTitle title="Edit" name={ICON_NAMES.EDIT} onClick={() => setEditView(true)} disabled={noRiskReasons || isReadOnlyUser || !withIgnoreRisk} /> :
                    <React.Fragment>
                        <Button tertiary className="cancel-button" onClick={closeEditView} disabled={loading}>Cancel</Button>
                        <Button icon={ICON_NAMES.BELL_CROSSED} onClick={handleIgnore} disabled={isEmpty(selectedRiskReasonTypes) || loading}>Ignore</Button>
                    </React.Fragment> 
                }
            </div>
            {
                noRiskReasons ? <div className="empty-risk-reasons">No risk reasons found</div> :
                    <React.Fragment>
                        {loading && <Loader absolute />}
                        {
                            riskReasons.map(({level, type, text, isIgnored}, index) => {
                                const isRiskyRole = type === "RISKY_ROLE";
        
                                const RiskReasonDisplay = () => (
                                    <div key={index} className={classnames("risk-reason", {ignored: isIgnored})}>
                                        <Icon className={classnames("global-risk-color", level.toLowerCase())} name={WORKLOAD_RISK_TYPE_ITEMS[type].icon} />
                                        <div className="reason-text">
                                            {text}
                                            {isRiskyRole && <span>{`: `}<span className="risky-role" onClick={handleRiskyRoleClick}>{permissionOwnerName}</span></span>}
                                        </div>
                                    </div>
                                )
                                
                                return (
                                    <div key={index} className="risk-reason-wrapper">
                                        {!editView ? <RiskReasonDisplay /> :
                                            <Checkbox
                                                title={<RiskReasonDisplay />}
                                                checked={!!selectedRiskReasonTypes.includes(type)}
                                                onChange={(event) => onRiskReasonSelect(type, event.target.checked)}
                                                disabled={isIgnored}
                                            />
                                        }
                                    </div>
                                )
                            })
                        }
                    </React.Fragment>
                    
            }
            <div className="edit-page-link-notification">
                <RoundIconContainer name={ICON_NAMES.INFO} small />
                <div>
                    <div>You may edit the ignore risk rules on the <Button tertiary onClick={() => history.push(RISK_MANAGEMENT_PAGE_PATH)}>Risk Management page</Button></div>
                    <div>Please note that it will take few minutes to update the risk table</div>
                </div>
            </div>
        </div>
    )
}

export default RiskReasonsDisplay;