import { ICON_NAMES } from "components/Icon";
import Colors from "utils/scss_variables.module.scss";

export const APPLICATION_NAME = "Panoptica";

export const EMPTY_VALUE = "N/A";

export const DOCUMENTATION_URL = "https://docs.panoptica.app/v1.0/";

export const FREE_TRIAL_URL = "https://panoptica.app";

export const SLACK_CHANNEL_URL = "https://join.slack.com/t/emergingtechcommunity/shared_invite/zt-1tngr8seu-SNRaInn7y28IRJxRaV~CEQ";

export const SUPPORT_EMAIL = "panoptica-support@cisco.com";

export const LOGIN_PATH = "/login";
export const LOGIN_CALLBACK_PATH = "/login/callback";
export const LOGIN_REDIRECT_URI = `${window.location.origin}${LOGIN_CALLBACK_PATH}`;

export const APP_PATHS = {
    DASHBOARD: { path: "/dashboard", title: "Dashboard" },
    RUNTIME: { path: "/runtime", title: "Runtime" },
    NAVIGATOR: { path: "/map", title: "Navigator" },
    DEPLOYMENTS: { path: "/deployments", title: "Deployments" },
    POLICIES: { path: "/policies", title: "Policies" },
    RISK_ASSESSMENT: { path: "/riskAssessment", title: "Risk Assessment" },
    CIS_BENCHMARK: { path: "/cisbenchmark", title: "Compliance" },
    CI_CD: { path: "/cicd", title: "CI/CD" },
    APIS: { path: "/catalog", title: "APIs" },
    SYSTEM: { path: "/system", title: "System" },
    AUDIT_LOG: { path: "/auditLog", title: "Audit" },
    SERVERLESS: { path: "/serverless", title: "Serverless" },
    REVIEWER: { path: "/reviewer" },
    HELP: { path: "/help" },
    //global admin paths:
    MANAGE_ACCOUNTS: { path: "/manageAccounts", title: "Manage Accounts" },
    MANAGE_ADMIN_USERS: { path: "/manageAdminUsers", title: "Manage Users" },
    GLOBAL_ADMIN_AUDIT: { path: "/globalAdminAudit", title: "Audit" },
    GLOBAL_ADMIN_CONTROLLERS: { path: "/globalAdminControllers", title: "K8s Controllers" }
}

export const GLOBAL_ADMIN_CONTROLLERS_EXPANSIONS_PATH = `${APP_PATHS.GLOBAL_ADMIN_CONTROLLERS.path}/expantions`;
export const DEFAULT_USER_PAGE = APP_PATHS.DASHBOARD.path;
export const MAIN_GLOBAL_ADMIN_PAGE = APP_PATHS.MANAGE_ACCOUNTS.path;
export const GLOBAL_ADMIN_URLS = [
  APP_PATHS.MANAGE_ACCOUNTS.path,
  APP_PATHS.MANAGE_ADMIN_USERS.path,
  APP_PATHS.GLOBAL_ADMIN_AUDIT.path,
  APP_PATHS.GLOBAL_ADMIN_CONTROLLERS.path,
  GLOBAL_ADMIN_CONTROLLERS_EXPANSIONS_PATH
];

export const RULE_ACTIONS = {
  DETECT: "DETECT",
  BLOCK: "BLOCK",
  ALLOW: "ALLOW",
  ENCRYPT: "ENCRYPT",
  ENCRYPT_DIRECT: "ENCRYPT_DIRECT",
};

export const DETECT_ACTION_ITEM = {
  value: RULE_ACTIONS.DETECT,
  label: "Detect",
};
export const BLOCK_ACTION_ITEM = { value: RULE_ACTIONS.BLOCK, label: "Block" };
export const ALLOW_ACTION_ITEM = { value: RULE_ACTIONS.ALLOW, label: "Allow" };
export const ENCRYPT_ACTION_ITEM = {
  value: RULE_ACTIONS.ENCRYPT,
  label: "Encrypt",
};
export const ENCRYPT_DIRECT_ACTION_ITEM = {
  value: RULE_ACTIONS.ENCRYPT_DIRECT,
  label: "Encrypt",
};
export const RISKY_ACTION_ITEM = { value: "RISKY", label: "Risky" };
export const IGNORE_ACTION_ITEM = { value: "IGNORE", label: "Ignore" };
export const ENFORCE_ACTION_ITEM = { value: "ENFORCE", label: "Enforce" };

export const RULE_ACTIONS_MAP = {
  [RULE_ACTIONS.DETECT]: "Detected",
  [RULE_ACTIONS.BLOCK]: "Blocked",
  [RULE_ACTIONS.ALLOW]: "Allowed",
  [RULE_ACTIONS.ENCRYPT]: "Encrypted",
};

export const RULE_STATUSES = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

export const SYSTEM_RISKS = {
  CRITICAL: {
    value: "CRITICAL",
    label: "Critical",
    color: Colors["color-risk-critical"],
  },
  HIGH: {
    value: "HIGH",
    label: "High",
    color: Colors["color-risk-high"],
  },
  MEDIUM: {
    value: "MEDIUM",
    label: "Medium",
    color: Colors["color-risk-medium"],
  },
  LOW: {
    value: "LOW",
    label: "Low",
    color: Colors["color-risk-low"],
  },
  UNKNOWN: {value: "UNKNOWN", label: "Unknown", color: Colors["color-risk-unknown"] },
  NO_RISK: { value: "NO_RISK", label: "No known risk", color:Colors["color-risk-no-known"] },
  APPROVED: { value: "APPROVED", label: "Approved", color: Colors["color-risk-approved"] },
  NEUTRAL: { value: "NEUTRAL", label: "Neutral", color: Colors["color-risk-approved"] },
  INFO: { value: "INFO", label: "Info" },
};

export const VULNERABILITY_LEVELS = {
  [SYSTEM_RISKS.CRITICAL.value]: SYSTEM_RISKS.CRITICAL,
  [SYSTEM_RISKS.HIGH.value]: SYSTEM_RISKS.HIGH,
  [SYSTEM_RISKS.MEDIUM.value]: SYSTEM_RISKS.MEDIUM,
  [SYSTEM_RISKS.LOW.value]: SYSTEM_RISKS.LOW,
  [SYSTEM_RISKS.UNKNOWN.value]: SYSTEM_RISKS.UNKNOWN,
};

export const SEVERITIES = {
  [SYSTEM_RISKS.CRITICAL.value]: SYSTEM_RISKS.CRITICAL,
  [SYSTEM_RISKS.HIGH.value]: SYSTEM_RISKS.HIGH,
  [SYSTEM_RISKS.MEDIUM.value]: SYSTEM_RISKS.MEDIUM,
  [SYSTEM_RISKS.LOW.value]: SYSTEM_RISKS.LOW,
  [SYSTEM_RISKS.NO_RISK]: SYSTEM_RISKS.NO_RISK
}

export const CLUSTER_ORCHESTRATION_TYPES = {
  OPENSHIFT: {
    value: "OPENSHIFT",
    label: "OpenShift",
  },
  RANCHER: {
    value: "RANCHER",
    label: "Rancher",
  },
  GKE: {
    value: "GKE",
    label: "Google Kubernetes Engine",
    link: "https://cloud.google.com/kubernetes-engine/docs/concepts/cis-benchmarks"
  },
  AKS: {
    value: "AKS",
    label: "Azure Kubernetes Service",
    link: "https://learn.microsoft.com/en-us/azure/aks/cis-kubernetes"
  },
  EKS: {
    value: "EKS",
    label: "Amazon Elastic Kubernetes Service",
    link: "https://www.cisecurity.org/benchmark/amazon_web_services"
  },
  KUBERNETES: {
    value: "KUBERNETES",
    label: "Generic Kubernetes Cluster",
  },
  IKS: {
    value: "IKS",
    label: "Cisco Intersight Kubernetes Service",
  },
};

export const CLUSTER_SOURCES = {
  KUBERNETES: {
    value: "KUBERNETES",
    label: "Kubernetes",
  },
  CD: {
    value: "CD",
    label: "CD",
  },
};

export const CI_CD_ENFORCEMENT_OPTIONS = {
  FAIL: {
    value: "FAIL",
    label: "Block",
  },
  IGNORE: {
    value: "IGNORE",
    label: "Detect",
  },
};

export const CD_PIPELINE_FINDINGS_RISKS = {
  [SYSTEM_RISKS.NO_RISK.value]: SYSTEM_RISKS.NO_RISK,
  [SYSTEM_RISKS.HIGH.value]: SYSTEM_RISKS.HIGH,
  [SYSTEM_RISKS.MEDIUM.value]: SYSTEM_RISKS.MEDIUM,
};
export const SECRET_FINDINGS_RISKS = {
  NO_KNOWN_RISK: { value: "NO_KNOWN_RISK", label: "No Known Risk" },
  RISK_IDENTIFIED: { value: "RISK_IDENTIFIED", label: "Risk Identified" },
};

export const DOCKERFILE_SCAN_SEVERITY = {
  FATAL: {
    value: "FATAL",
    label: "Fatal",
  },
  WARN: {
    value: "WARN",
    label: "Warning",
  },
  INFO: {
    value: "INFO",
    label: "Info",
  },
};

export const WORKLOAD_RISKS = {
  [SYSTEM_RISKS.CRITICAL.value]: SYSTEM_RISKS.CRITICAL,
  [SYSTEM_RISKS.HIGH.value]: SYSTEM_RISKS.HIGH,
  [SYSTEM_RISKS.MEDIUM.value]: SYSTEM_RISKS.MEDIUM,
  [SYSTEM_RISKS.LOW.value]: SYSTEM_RISKS.LOW,
};

export const FUNCTION_RISKS = {
  [SYSTEM_RISKS.CRITICAL.value]: SYSTEM_RISKS.CRITICAL,
  [SYSTEM_RISKS.HIGH.value]: SYSTEM_RISKS.HIGH,
  [SYSTEM_RISKS.MEDIUM.value]: SYSTEM_RISKS.MEDIUM,
  [SYSTEM_RISKS.LOW.value]: SYSTEM_RISKS.LOW,
};

export const APP_TELEMETRY_STATUSES = {
  UNHEALTHY: {
    key: "UNHEALTHY",
    value: "Unhealthy",
  },
  ACTIVE: {
    key: "ACTIVE",
    value: "Active",
  },
  INACTIVE: {
    key: "INACTIVE",
    value: "Inactive",
  },
};

export const CONNECTION_ENDPOINT_TYPES = {
  SOURCE: "source",
  DESTINATION: "destination",
};

export const REGISTRY_TYPES_ITEMS = {
  AWS: {
    value: "AWS",
    label: "AWS",
  },
  GCP: {
    value: "GCP",
    label: "GCP",
  },
  AZURE: {
    value: "AZURE",
    label: "Azure",
  },
  JFROG: {
    value: "JFROG",
    label: "JFrog",
  },
  OTHER: {
    value: "OTHER",
    label: "Other",
  },
};

export const RISK_SCAN_STATUSES = {
  NOT_SCANNED: "NOT_SCANNED",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  AGENT_NOT_READY: "AGENT_NOT_READY",
};

export const CONNECTIONS_PROTOCOLS = {
  HTTP: "HttpPerformanceMetrics",
  TCP: "TcpPerformanceMetrics",
};

export const ACCOUNT_TIERS_ITEMS = {
  REVEAL: {
    value: "REVEAL",
    label: "Reveal",
  },
  ENTERPRISE: {
    value: "CONNECT",
    label: "Enterprise",
  },
  PRO: {
    value: "PRO",
    label: "Pro",
  },
  TRIAL: {
    value: "TRIAL",
    label: "Free",
  },
};

export const ACCOUNT_MODES_ITEMS = {
  NORMAL: {
    value: "NORMAL",
    label: "Normal",
  },
  MANAGED: {
    value: "MANAGED",
    label: "Managed",
  },
};

export const API_RISK_CONFIDENCE_ITEMS = {
  [SYSTEM_RISKS.HIGH.value]: SYSTEM_RISKS.HIGH,
  [SYSTEM_RISKS.MEDIUM.value]: SYSTEM_RISKS.MEDIUM,
  [SYSTEM_RISKS.LOW.value]: SYSTEM_RISKS.LOW,
};

export const API_RISK_ITEMS = {
  [SYSTEM_RISKS.CRITICAL.value]: SYSTEM_RISKS.CRITICAL,
  [SYSTEM_RISKS.HIGH.value]: SYSTEM_RISKS.HIGH,
  [SYSTEM_RISKS.MEDIUM.value]: SYSTEM_RISKS.MEDIUM,
  [SYSTEM_RISKS.LOW.value]: SYSTEM_RISKS.LOW,
  [SYSTEM_RISKS.NEUTRAL.value]: SYSTEM_RISKS.NEUTRAL,
  [SYSTEM_RISKS.UNKNOWN.value]: SYSTEM_RISKS.UNKNOWN,
  [SYSTEM_RISKS.NO_RISK.value]: SYSTEM_RISKS.NO_RISK,
};

export const API_TREND_ITEMS = {
  IMPROVING: {
    value: "IMPROVING",
    label: "Improving",
  },
  STABLE: {
    value: "STABLE",
    label: "Stable",
  },
  DEGRADING: {
    value: "DEGRADING",
    label: "Degrading",
  },
};

export const RULE_ORIGINS = {
  USER: "USER",
  AUTOMATED_POLICY: "AUTOMATED_POLICY",
  SYSTEM: "SYSTEM",
};

export const RULE_ENCRYPTION_REASON_ITEMS = {
  CLUSTER_OUTBOUND: {
    value: "CLUSTER_OUTBOUND",
    label: "Encrypt all traffic between clusters",
  },
};

export const PRODUCTION_ADDRESS = {
  DOMAIN: "appsecurity.cisco.com",
  IP: "34.74.85.197",
};

export const REGULAR_USER_ROLES_ITEMS = {
  ACCOUNT_ADMIN: { value: "ACCOUNT_ADMIN", label: "Administrator" },
  SERVICE: { value: "SERVICE", label: "Service User" },
  ACCOUNT_AUDITOR: { value: "ACCOUNT_AUDITOR", label: "Account Auditor" },
  CI_CD_SCANNER: { value: "CI_CD_SCANNER", label: "CI-CD Scanner" },
};

export const GLOBAL_ADMIN_USER_ROLES_ITEMS = {
  PORTSHIFT_ADMIN: { value: "PORTSHIFT_ADMIN", label: "Global Administrator" },
  PORTSHIFT_AUDITOR: { value: "PORTSHIFT_AUDITOR", label: "Global Auditor" },
  SELF_PROVISIONING: { value: "SELF_PROVISIONING", label: "Self Provisioning" },
  CISCO_SUBSCRIBER: { value: "CISCO_SUBSCRIBER", label: "Cisco Subscriber" },
  LIGHTSPIN_ADMIN: { value: "LIGHTSPIN_ADMIN", label: "LightSpin Admin" },
  K8S_CLUSTERS_MANAGER: { value: "K8S_CLUSTERS_MANAGER", label: "Kubernetes Clusters Manager" },
  API_SEC_BACKEND: { value: "API_SEC_BACKEND", label: "API Security Backend" },
  LIGHTSPIN_BACKEND: { value: "LIGHTSPIN_BACKEND", label: "Lightspin Backend" }
};

export const WORKLOAD_RISK_TYPE_ITEMS = {
  VULNERABILITY: {
    value: "VULNERABILITY",
    label: "Vulnerability",
    icon: ICON_NAMES.BEETLE_ROUND,
  },
  RISKY_ROLE: {
    value: "RISKY_ROLE",
    label: "Risky role",
    icon: ICON_NAMES.SECURITY_CONTEXT,
  },
  PRIVILEGED: {
    value: "PRIVILEGED",
    label: "Privileged",
    icon: ICON_NAMES.SECURITY_CONTEXT,
  },
  RUN_AS_ROOT: {
    value: "RUN_AS_ROOT",
    label: "Run as root",
    icon: ICON_NAMES.SECURITY_CONTEXT,
  },
  ALLOWED_HOST_PATH: {
    value: "ALLOWED_HOST_PATH",
    label: "Allowed host path",
    icon: ICON_NAMES.SECURITY_CONTEXT,
  },
  ALLOWED_RISKY_CAPABILITIES: {
    value: "ALLOWED_RISKY_CAPABILITIES",
    label: "Allowed risky capabilities",
    icon: ICON_NAMES.SECURITY_CONTEXT,
  },
  HOST_NETWORK: {
    value: "HOST_NETWORK",
    label: "Host network",
    icon: ICON_NAMES.SECURITY_CONTEXT,
  },
  HOST_PID: {
    value: "HOST_PID",
    label: "Host PID",
    icon: ICON_NAMES.SECURITY_CONTEXT,
  },
  HOST_IPC: {
    value: "HOST_IPC",
    label: "Host IPC",
    icon: ICON_NAMES.SECURITY_CONTEXT,
  },
  ALLOW_PRIVILEGE_ESCALATION: {
    value: "ALLOW_PRIVILEGE_ESCALATION",
    label: "Allowed privileged escalation",
    icon: ICON_NAMES.SECURITY_CONTEXT,
  },
  PUBLIC_FACING: {
    value: "PUBLIC_FACING",
    label: "Public facing",
    icon: ICON_NAMES.EARTH,
  },
  UNIDENTIFIED: {
    value: "UNIDENTIFIED",
    label: "Unidentified",
    icon: ICON_NAMES.IMAGE_UNIDENTIFIED,
  },
  RUNNING_SSH_SERVER: {
    value: "RUNNING_SSH_SERVER",
    label: "Running SSH server",
    icon: ICON_NAMES.LOCK,
  },
  K8S_DASHBOARD: {
    value: "K8S_DASHBOARD",
    label: "K8s dashboard",
    icon: ICON_NAMES.DASHBOARD,
  },
  TEMPLATE_DIFF: {
    value: "TEMPLATE_DIFF",
    label: "Template diff",
    icon: ICON_NAMES.DIFF,
  },
  DOCKERFILE_SCAN: {
    value: "DOCKERFILE_SCAN",
    label: "Dockerfile scan",
    icon: ICON_NAMES.ALERT_CIS,
  },
  API: { value: "API", label: "API", icon: ICON_NAMES.API_FULL },
};

export const VALIDATION_TYPE_ITEMS = {
  SIGNATURE: { value: "SIGNATURE", label: "Signature" },
  HASH: { value: "HASH", label: "Hash" },
  NONE: { value: "NONE", label: "Off" },
};

export const CONTROLLER_STATUS_ITEMS = {
  ACTIVE: {
      value: "ACTIVE",
      label: "Active"
  },
  INACTIVE: {
      value: "INACTIVE",
      label: "Inactive"
  },
  STOPPED: {
      value: "STOPPED",
      label: "Stopped"
  },
  TERMINATED: {
      value: "TERMINATED",
      label: "Terminated"
  },
  PENDING_INSTALL: {
      value: "PENDING_INSTALL",
      label: "Pending Install"
  },
  UNKNOWN: {
      value: "UNKNOWN",
      label: "Unknown"
  },
  WAITING_FOR_USER_UPDATE: {
    value: "WAITING_FOR_USER_UPDATE",
    label: "Pending reinstall"
  },
  AUTO_UPGRADE_IN_PROGRESS: {
    value: "AUTO_UPGRADE_IN_PROGRESS",
    label: "Upgrade in progress"
  },
  AUTO_UPDATING_CONFIGURATION_IN_PROGRESS: {
    value: "AUTO_UPDATING_CONFIGURATION_IN_PROGRESS",
    label: "Update in progress"
  }
}

export const ACTIVE_CONTROLLER_STATUSES = [
  CONTROLLER_STATUS_ITEMS.ACTIVE.value,
  CONTROLLER_STATUS_ITEMS.WAITING_FOR_USER_UPDATE.value,
  CONTROLLER_STATUS_ITEMS.AUTO_UPGRADE_IN_PROGRESS.value,
  CONTROLLER_STATUS_ITEMS.AUTO_UPDATING_CONFIGURATION_IN_PROGRESS.value,
]

export const CONTROLLER_TYPES = {
  AWS_INSTANCE: "AWS_INSTANCE",
  KUBERNETES: "KUBERNETES",
  EXPANSION: "EXPANSION"
}

export const CIS_SCAN_STATES= {
  READY: {value: "READY", label: "Ready"},
  STARTING: {value: "STARTING", label: "Starting"},
  ERROR: {value: "ERROR", label: "Error"},
  IN_PROGRESS: {value: "IN_PROGRESS", label: "In progress"}
}

export const IN_PROGRESS_CIS_SCAN_STATES = [CIS_SCAN_STATES.IN_PROGRESS.value, CIS_SCAN_STATES.STARTING.value];

export const CIS_TEST_STATUSES = {
  PASS: {value: "PASS", label: "Pass"},
  WARN: {value: "WARN", label: "Warn"},
  FAIL: {value: "FAIL", label: "Fail"},
  INFO: {value: "INFO", label: "Info"}
}