import React from 'react';
import CVSSSection from './CVSSSection';
import PackageDetails from './PackageDetails';
import PackagesModal from './PackagesModal';

import './vulnerabilities-inner-table.scss';

const CVSS_LEVELS = {
    CRITICAL: "Critical",
    HIGH: "High",
    MEDIUM: "Medium",
    LOW: "Low",
    NONE: "None"
}

const CVSS_USER_INTERACTIONS = {
    NONE: "None",
    REQUIRED: "Required"
}

const CVSS_SCOPES = {
    UNCHANGED: "Unchanged",
    CHANGED: "Changed",
}

const CVSS_ATTACK_VECTORS = {
    NETWORK: "Network",
    ADJACENT_NETWORK: "Adjacent Network",
    LOCAL: "Local",
    PHYSICAL: "Physical"
}

const CVSS_PROPERTIES = [
    {label: "Attack Vector", key: 'attackVector', map: CVSS_ATTACK_VECTORS},
    {label: "Attack Complexity", key: 'attackComplexity', map: CVSS_LEVELS},
    {label: "Privileges Required", key: 'privilegesRequired', map: CVSS_LEVELS},
    {label: "User Interaction", key: 'userInteraction', map: CVSS_USER_INTERACTIONS},
]

const CVSS_CONSEQUENCES = [
    {label: "Scope", key: 'scope', map: CVSS_SCOPES},
    {label: "Confidentiality", key: 'confidentialityImpact', map: CVSS_LEVELS},
    {label: "Integrity", key: 'integrityImpact', map: CVSS_LEVELS},
    {label: "Availability", key: 'availabilityImpact', map: CVSS_LEVELS},
]

const VulnerabilitiesInnerTable = ({data = {}}) =>  {
    const [modalData, setModalData] = React.useState(false);

    const {cvss, vulnerabilitySources = {sources: []}} = data;
    const {sources} = vulnerabilitySources;

    const firstPackage = sources.length > 0 ? sources[0] : false;
    const secondPackage = sources.length > 1 ? sources[1] : false;
    const hasAdditionalPackages = sources.length > 2;

    const showModal = React.useCallback((e) => {
        e.preventDefault();
        setModalData(sources);
    }, [sources])

    return (
       <div className="vulnerabilities-inner-container">
           <div className="packages-container">
                {firstPackage && <PackageDetails dataId="package1" data={firstPackage}/>}
                {secondPackage && <PackageDetails dataId="package2" data={secondPackage}/>}
                {hasAdditionalPackages &&
                    <div className="more-container">
                    <a href="/" rel="noopener norefferer" onClick={showModal}>{sources.length - 2} More</a>
                    </div>
                }
                {modalData && <PackagesModal handleClose={() => setModalData(null)} data={modalData}/>}
           </div>
           <div className="cvss-container">
                {!cvss ? <div className="no-cvss-data">No data available</div> :
                    <React.Fragment>
                        <CVSSSection label="Properties" items={CVSS_PROPERTIES} data={cvss}/>
                        <CVSSSection label="Consequences" items={CVSS_CONSEQUENCES} data={cvss}/>
                    </React.Fragment>
                }
           </div>
       </div>
    );
}

export default VulnerabilitiesInnerTable;