import React, { useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useFetch, usePrevious } from 'hooks';
import { validators, RadioField, SelectField, useFormikContext } from 'components/Form';

export const API_TYPES_ITEMS = {
    INTERNAL: {value: "INTERNAL", label: "Internal"},
    EXTERNAL: {value: "EXTERNAL", label: "External"}
};

const formatApiUrl = (url, type) => `${url}/${type.toLowerCase()}Catalog`

const ApiForm = ({endpoint, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();

    const apiTypeFieldName = `${endpoint}.type`;
    const type = get(values, apiTypeFieldName);
    const previousType = usePrevious(type);

    const apiFieldName = `${endpoint}.api`;
    const api = get(values, apiFieldName);
    const previousApi = usePrevious(api);

    const [{loading, data}, fetchApisData] = useFetch("apiSecurity", {loadOnMount: !!type, formatUrl: url => formatApiUrl(url, type), queryParams: {noPagination: true, includeServiceWithNoSpec: false}});
    const doFetchApisData = useCallback(type => fetchApisData({formatUrl: url => formatApiUrl(url, type), queryParams: {noPagination: true, includeServiceWithNoSpec: false}}), [fetchApisData]);

    const dataItems = !!data ? data.items : [];
    
    useEffect(() => {
        if (!type) {
            const defaultType = API_TYPES_ITEMS.INTERNAL.value;
            setFieldValue(`${endpoint}.type`, defaultType);
            doFetchApisData(defaultType);

            return;
        }

        if (!!previousType && type !== previousType) {
            doFetchApisData(type);
            setFieldValue(`${endpoint}.api`, "");
        }
    }, [type, previousType, doFetchApisData, setFieldValue, endpoint]);

    useEffect(() => {
        if (!!previousApi && previousApi !== api) {
            setFieldValue("layer7Settings.tags", []);
            setFieldValue("layer7Settings.methods", []);
        }
    }, [previousApi, api, setFieldValue]);
    
    return (
        <React.Fragment>
            <RadioField
                name={apiTypeFieldName}
                horizontal={true}
                items={Object.values(API_TYPES_ITEMS)}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            <SelectField
                name={`${endpoint}.api`}
                label="API"
                items={dataItems.map(({identifier, name}) => ({value: identifier, label: name}))}
                validate={validators.validateRequired}
                loading={loading}
                tooltipText="Only services with specs are shown"
                disabled={isReadOnlyUser}
            />
        </React.Fragment>
    );
}

export default ApiForm;