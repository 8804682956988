import React, { useRef } from 'react';
import classnames from 'classnames';
import { ICON_NAMES } from 'components/Icon';
import IconWithTooltip from 'components/IconWithTooltip';

import './input-with-copy.scss';

const InputWithCopy = ({value, tooltipId="input-with-copy-tooltip-id", tooltipText="Copy code", className}) => {
    const inputRef = useRef();

    const copyToClipBoard = (ref) => {
        ref.current.select();
        document.execCommand('copy');
    }
    
    return (
        <div className={classnames("input-with-copy-wrapper", className)}>
            <textarea className="form-control ps-form-field" ref={inputRef} defaultValue={value} />
            <IconWithTooltip
                tooltipId={tooltipId}
                tooltipText={tooltipText}
                name={ICON_NAMES.COPY}
                onClick={() => copyToClipBoard(inputRef)}
            />
        </div>
    )
}

export default InputWithCopy;