import React from 'react';
import classnames from 'classnames';
import { RULE_ACTIONS } from 'utils/systemConsts';
import BaseRuleItem from '../../BaseRuleItem';

const PortshiftRuleItem = ({implicitRule, isReadOnly, onRuleEdit, isMarked}) => {
    const {name, action} = implicitRule;
    const blocked = action === RULE_ACTIONS.BLOCK;
    const RuleContent = () => <span>Unidentified workloads <span className={classnames("relationship", {blocked})}>can't</span> run on any environment</span>;

    return (
        <BaseRuleItem 
            name={name}
            type={action}
            onEdit={onRuleEdit}
            onDoubleClick={onRuleEdit}
            contentRenderer={RuleContent}
            className={classnames({selected: isMarked})}
            withActions={false}
            withPortshiftTag={true}
            blocked={blocked}
            allowed={false}
            isReadOnly={isReadOnly}
        />
    ); 
}

export default PortshiftRuleItem;