import React from 'react';
import { useFetch } from 'hooks';
import { TextField, SelectField, ViolationsFilterField, ClustersNamespacesFilterField } from 'components/Form';
import { FilterFormWrapper } from 'components/Table';
import Loader from 'components/Loader';
import { RULE_ACTIONS, RULE_ACTIONS_MAP } from 'utils/systemConsts';
import { nameIdToValueLabel } from 'utils/apiUtils';

import COLORS from 'utils/scss_variables.module.scss';

const RESULT_FILTER_ITEMS = [
    {value: RULE_ACTIONS.ALLOW, label: RULE_ACTIONS_MAP[RULE_ACTIONS.ALLOW], color: COLORS["color-success"]},
    {value: RULE_ACTIONS.ENCRYPT, label: RULE_ACTIONS_MAP[RULE_ACTIONS.ENCRYPT]},
    {value: RULE_ACTIONS.DETECT, label: RULE_ACTIONS_MAP[RULE_ACTIONS.DETECT], color: COLORS["color-warning"], isViolation: true},
    {value: RULE_ACTIONS.BLOCK, label: RULE_ACTIONS_MAP[RULE_ACTIONS.BLOCK], color: COLORS["color-error"], isViolation: true}
];

export const LOGICAL_OPERATOR_ITEMS = {
    AND: {value: "and", label: "And"},
    OR: {value: "or", label: "Or"}
}

export const FILTER_KEYS = {
    RESULT: "result",
    SOURCE_NAMESPCASES: "sourceNamespacesFilter",
    SOURCE_ENVIRONMENT_NAME: "sourceEnvironmentName",
    SOURCE_WORKLOAD_NAME: "sourceAppName",
    TARGET_NAMESPCASES: "targetNamespacesFilter",
    TARGET_ENVIRONMENT_NAME: "targetEnvironmentName",
    TARGET_WORKLOAD_NAME: "targetAppName",
    LOGICAL_OPERATOR: "logicalOperator",
}

export const initialData = {
    [FILTER_KEYS.SOURCE_NAMESPCASES]: [],
    [FILTER_KEYS.SOURCE_ENVIRONMENT_NAME]: "",
    [FILTER_KEYS.SOURCE_WORKLOAD_NAME]: "",
    [FILTER_KEYS.TARGET_NAMESPCASES]: [],
    [FILTER_KEYS.TARGET_ENVIRONMENT_NAME]: "",
    [FILTER_KEYS.TARGET_WORKLOAD_NAME]: "",
    [FILTER_KEYS.RESULT]: RESULT_FILTER_ITEMS.map(item => item.value),
    [FILTER_KEYS.LOGICAL_OPERATOR]: LOGICAL_OPERATOR_ITEMS.AND.value,
    filterLoading: false
};

const LogicalFieldsWrapper = ({children}) => (
    <div className="runtime-conn-logical-fields-wrapper">
        {children}
    </div>
);

const FilterForm = ({filters, queryFilters={}, setFilters}) => {
    const [{loading, data: clusters, error}] = useFetch("leanKubernetesClusters");

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const initialValues = {
        ...initialData,
        ...filters,
        ...queryFilters,
        filterLoading: false
    };
    
    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={setFilters}
            className="runtime-connections-table-filter-form"
        >
            <ViolationsFilterField
                name={FILTER_KEYS.RESULT}
                label="Violations only"
                items={RESULT_FILTER_ITEMS}
            />
            <LogicalFieldsWrapper>
                <TextField name={FILTER_KEYS.SOURCE_ENVIRONMENT_NAME} label="Source Environment" />
                <TextField name={FILTER_KEYS.SOURCE_WORKLOAD_NAME} label="Source Workload" />
                <ClustersNamespacesFilterField
                    name={FILTER_KEYS.SOURCE_NAMESPCASES}
                    label="Source Clusters & Namespaces"
                    items={nameIdToValueLabel(clusters)}
                />
            </LogicalFieldsWrapper>
            <SelectField
                name={FILTER_KEYS.LOGICAL_OPERATOR}
                items={Object.values(LOGICAL_OPERATOR_ITEMS)}
                clearable={false}
                className="logical-selector-field"
                small
            />
            <LogicalFieldsWrapper>
                <TextField name={FILTER_KEYS.TARGET_ENVIRONMENT_NAME} label="Destination Environment" />
                <TextField name={FILTER_KEYS.TARGET_WORKLOAD_NAME} label="Destination Workload" />
                <ClustersNamespacesFilterField
                    name={FILTER_KEYS.TARGET_NAMESPCASES}
                    label="Destination Clusters & Namespaces"
                    items={nameIdToValueLabel(clusters)}
                    className="target-namespaces-filter"
                />
            </LogicalFieldsWrapper>
        </FilterFormWrapper>
    )
};

export default FilterForm;