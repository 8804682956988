import React from 'react';
import Icon, { ICON_NAMES } from 'components/Icon';
import { TooltipWrapper } from 'components/Tooltip';

import './workload-name-display.scss';

const WORKLOAD_TYPES = {
    POD: {icon: ICON_NAMES.POD},
    SYSTEM_POD: {icon: ICON_NAMES.SYSTEM, tooltip: "System workload"},
    EXPANSION: {icon: ICON_NAMES.EXPANSION}
};

const WorkloadNameDisplay = ({id, workloadType, pod, expansion, withIcon=false}) => {
    const {icon, tooltip} = WORKLOAD_TYPES[workloadType];

    const IconDisplay = withIcon && <Icon name={icon} className="workload-icon" />;

    return (
        <div className="workload-cell-wrapper">
            {!!tooltip ? <TooltipWrapper id={`workload-type-tooltip-${id}`} text={tooltip}>{IconDisplay}</TooltipWrapper> : IconDisplay}
            {!!pod.name && <div>{pod.name}</div>}
            {!!expansion && <div>{expansion.name}</div>}
        </div>
    )
}

export default WorkloadNameDisplay;