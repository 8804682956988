import React from 'react';
import classnames from 'classnames';
import { SYSTEM_RISKS } from 'utils/systemConsts';
import Icon, { ICON_NAMES } from 'components/Icon';

export const VULNERABILITIES = {
    [SYSTEM_RISKS.CRITICAL.value]: SYSTEM_RISKS.CRITICAL.value,
    [SYSTEM_RISKS.HIGH.value]: SYSTEM_RISKS.HIGH.value,
    [SYSTEM_RISKS.MEDIUM.value]: SYSTEM_RISKS.MEDIUM.value,
    [SYSTEM_RISKS.LOW.value]: SYSTEM_RISKS.LOW.value,
    [SYSTEM_RISKS.UNKNOWN.value]: SYSTEM_RISKS.UNKNOWN.value,
    SUCCESS: "SUCCESS",
};

const VulnerabilityIcon = ({severity, onClick, showSuccess=true, showUnknown=false}) => {
    const severityClass = severity.toLowerCase();
    const isSuccess = severityClass === VULNERABILITIES.SUCCESS.toLowerCase();
    const isUnknown = severityClass === VULNERABILITIES.UNKNOWN.toLowerCase();
    
    if (isSuccess && !showSuccess) {
        return null;
    }

    if (isUnknown && !showUnknown) {
        return null;
    }
    
    return (
        <Icon
            name={isSuccess ? ICON_NAMES.SUCCESS_ROUND : ICON_NAMES.BEETLE_ROUND}
            onClick={onClick}
            className={classnames("vulnerability-icon", "global-risk-color", {[severityClass]: !!severityClass}, {success: isSuccess})}
        />
    );
}

export default VulnerabilityIcon