import { ICON_NAMES } from "components/Icon";
import { InnerLabelContent } from "components/PieChartWithCenteredText";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { SYSTEM_RISKS } from "utils/systemConsts";
import Colors from "utils/scss_variables.module.scss";

export const API_DASHBOARD_LINE_TYPES = {
  [SYSTEM_RISKS.CRITICAL.value]: {
    ...SYSTEM_RISKS.CRITICAL,
  },
  [SYSTEM_RISKS.HIGH.value]: {
    ...SYSTEM_RISKS.HIGH,
  },
  [SYSTEM_RISKS.MEDIUM.value]: {
    ...SYSTEM_RISKS.MEDIUM,
  },
  [SYSTEM_RISKS.LOW.value]: {
    ...SYSTEM_RISKS.LOW,
  },
  [SYSTEM_RISKS.NO_RISK.value]: {
    ...SYSTEM_RISKS.NO_RISK,
  },
};

export const APIS_TYPES = {
  SPEC: {
    value: "apisWithSpecs",
    label: "APIs with specs",
    color: Colors["color-main-light"],
  },
  NO_SPEC: {
    value: "apisWithoutSpecs",
    label: "APIs without specs",
    color: Colors["color-neutral-grey"],
  },
};

export const NoDataDisplay = () => {
  const data = [{ name: "No data", value: 1, color: DIFF_TYPES.NO_DIFF.color }];
  return (
    <ResponsiveContainer width="100%" height={170}>
      <PieChart>
        <Pie
          stroke="none"
          data={data}
          dataKey="value"
          innerRadius={50}
          outerRadius={60}
          paddingAngle={0}
        >
          {data.map(({ name, color }) => (
            <Cell key={name} fill={color} />
          ))}
          <Label
            position="center"
            content={<InnerLabelContent count={0} title={"No data"} />}
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export const apiRiskFilterValues = {
  [SYSTEM_RISKS.LOW.value]: { ...SYSTEM_RISKS.LOW },
  [SYSTEM_RISKS.MEDIUM.value]: { ...SYSTEM_RISKS.MEDIUM },
  [SYSTEM_RISKS.HIGH.value]: { ...SYSTEM_RISKS.HIGH },
  [SYSTEM_RISKS.CRITICAL.value]: { ...SYSTEM_RISKS.CRITICAL },
  [SYSTEM_RISKS.NO_RISK.value]: { ...SYSTEM_RISKS.NO_RISK },
};

export const DIFF_TYPES = {
  ZOMBIE: {
    value: "zombieDiffs",
    label: "Zombie operations",
    color: Colors["color-risk-critical"],
    iconName: ICON_NAMES.ZOMBIE,
  },
  SHADOW: {
    value: "shadowDiffs",
    label: "Shadow operations",
    color: Colors["color-risk-high"],
    iconName: ICON_NAMES.SHADOW,
  },
  GENERAL: {
    value: "generalDiffs",
    label: "Rogue operations",
    color: Colors["color-warning"],
    iconName: ICON_NAMES.ALERT,
  },
  NO_DIFF: {
    value: "operationsWithoutDiffs",
    label: "Valid operations",
    color: Colors["color-success"],
  },
};

export const SYSTEM_RISKS_WITH_ACCESSORS = {
  [SYSTEM_RISKS.CRITICAL.value]: {
    ...SYSTEM_RISKS.CRITICAL,
    accessor: "critical",
  },
  [SYSTEM_RISKS.HIGH.value]: {
    ...SYSTEM_RISKS.HIGH,
    accessor: "high",
  },
  [SYSTEM_RISKS.MEDIUM.value]: {
    ...SYSTEM_RISKS.MEDIUM,
    accessor: "medium",
  },
  [SYSTEM_RISKS.LOW.value]: {
    ...SYSTEM_RISKS.LOW,
    accessor: "low",
  },
  [SYSTEM_RISKS.NO_RISK.value]: {
    ...SYSTEM_RISKS.NO_RISK,
    accessor: "noKnownRisk",
  },
};
