
import React from 'react';
import DisplaySection from 'components/DisplaySection';
import UnorderedList from '../UnorderedList';

const PspViolationReasonsDisplay = ({pspViolationReasons}) => (
    <DisplaySection
        title="Pod security standards violations"
        className="psp-violation-reasons"
        allowClose={true}
        initialIsClosed={false}
    >
        <UnorderedList items={pspViolationReasons} alert />
    </DisplaySection>
)

export default PspViolationReasonsDisplay;