import React from 'react';
import classnames from 'classnames';
import Arrow, { ARROW_NAMES } from 'components/Arrow';

import './list-display.scss';

const ListDisplay = ({ items, itemDisplay: ItemDisplay, selectedId, onSelect, selectUpdatesArrow = false, displayArrows = true }) => (
    <div className="list-display-wrapper">
        {
            items.map(item => {
                const { id, disabled = false } = item;
                const isSelected = id === selectedId;

                return (
                    <div
                        key={id}
                        className={classnames("list-display-item-wrapper", { selected: isSelected }, { disabled })}
                        onClick={disabled ? undefined : () => onSelect(item)}
                    >
                        <div className="list-display-item"><ItemDisplay {...item} /></div>
                        {!disabled && displayArrows && <Arrow name={(selectUpdatesArrow && isSelected) ? ARROW_NAMES.LEFT : ARROW_NAMES.RIGHT} small />}
                    </div>
                )
            })
        }
    </div>
);

export default ListDisplay;