import React from 'react';
import { useMountMultiFetch } from 'hooks';
import Loader from 'components/Loader';
import { TextField, MultiselectCheckboxField, SelectField, ViolationsFilterField, ClustersNamespacesFilterField } from 'components/Form';
import { tableUtils, FilterFormWrapper } from 'components/Table';
import { VULNERABILITY_LEVELS, RULE_ACTIONS, RULE_ACTIONS_MAP, WORKLOAD_RISKS } from 'utils/systemConsts';
import { valueToValueLabelFromProp, nameIdToValueLabel } from 'utils/apiUtils';
import { PROTECTED_PODS_ITEMS } from 'layout/Runtime/ProtectedStatus';

import COLORS from 'utils/scss_variables.module.scss';

const RESULT_FILTER_ITEMS = [
    {value: RULE_ACTIONS.ALLOW, label: RULE_ACTIONS_MAP[RULE_ACTIONS.ALLOW], color: COLORS["color-success"]},
    {value: RULE_ACTIONS.DETECT, label: RULE_ACTIONS_MAP[RULE_ACTIONS.DETECT], color: COLORS["color-warning"], isViolation: true},
    {value: RULE_ACTIONS.BLOCK, label: RULE_ACTIONS_MAP[RULE_ACTIONS.BLOCK], color: COLORS["color-error"], isViolation: true}
];

export const FILTER_KEYS = {
    RESULT: "result",
    HIGHEST_VULNERABILITY: "highestVulnerability",
    WORKLOAD_RISKS: "workloadRisks",
    IS_IDENTIFIED: "isIdentified",
    PROTECTION_STATUS: "protectionStatus",
    CLUSTER_NAME: "host",
    WORKLOAD_NAME: "appName",
    ENVIRONMENT_NAME: "environmentName",
    NAMESPACES: "namespacesFilter"
}

export const initialData = {
    [FILTER_KEYS.WORKLOAD_NAME]: "",
    [FILTER_KEYS.HIGHEST_VULNERABILITY]: [],
    [FILTER_KEYS.WORKLOAD_RISKS]: [],
    [FILTER_KEYS.IS_IDENTIFIED]: "",
    [FILTER_KEYS.ENVIRONMENT_NAME]: [],
    [FILTER_KEYS.CLUSTER_NAME]: "",
    [FILTER_KEYS.RESULT]: RESULT_FILTER_ITEMS.map(item => item.value),
    [FILTER_KEYS.PROTECTION_STATUS]: PROTECTED_PODS_ITEMS.ALL.value,
    [FILTER_KEYS.NAMESPACES]: [],
    filterLoading: true,
    filtersInit: false
}

const FilterForm = ({filters, queryFilters={}, setFilters}) => {
    const [{loading, data, error}] = useMountMultiFetch([
        {key: "environments", url: "environments"},
        {key: "clusters", url: "leanKubernetesClusters"}
    ]);

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const {environments, clusters} = data || {};
    const environmentItems = valueToValueLabelFromProp(environments, "name");
    
    const initialValues = {
        ...initialData,
        ...filters,
        environmentName: filters.filtersInit ? filters.environmentName : [],
        ...queryFilters,
        filterLoading: false,
        filtersInit: true
    };
    
    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={setFilters}
        >
            <ViolationsFilterField
                name={FILTER_KEYS.RESULT}
                label="Violations only"
                items={RESULT_FILTER_ITEMS}
            />
            <MultiselectCheckboxField
                name={FILTER_KEYS.HIGHEST_VULNERABILITY}
                label="Highest Vulnerability"
                items={Object.values(VULNERABILITY_LEVELS)} 
            />
            <MultiselectCheckboxField
                name={FILTER_KEYS.WORKLOAD_RISKS}
                label="Workload risk"
                items={Object.values(WORKLOAD_RISKS)} 
            />
            <SelectField
                name={FILTER_KEYS.IS_IDENTIFIED}
                label="Identified"
                items={[
                    {value: "", label: "All"},
                    {value: "true", label: "Identified"},
                    {value: "false", label: "Unidentified"}
                ]}
                clearable={false}
                small
            />
            <SelectField
                name={FILTER_KEYS.PROTECTION_STATUS}
                label="Protected"
                items={Object.values(PROTECTED_PODS_ITEMS)}
                clearable={false}
                small
            />
            <ClustersNamespacesFilterField
                name={FILTER_KEYS.NAMESPACES}
                label="Clusters & Namespaces"
                items={nameIdToValueLabel(clusters)}
            />
            <MultiselectCheckboxField
                name={FILTER_KEYS.ENVIRONMENT_NAME}
                label="Environment"
                items={tableUtils.getFilterItemsWithUndefined(environmentItems)}
            />
            <TextField name={FILTER_KEYS.WORKLOAD_NAME} label="Workload" />
        </FilterFormWrapper>
    )
};

export default FilterForm;