import React from 'react';
import { DayPicker } from 'react-day-picker';
import { stringToUtcDate } from 'utils/generalUtils';

import 'react-day-picker/dist/style.css';
import './calendar.scss';

export const MODES = {
    RANGE: "range",
    SINGLE: "single"
}

const Calendar = ({singleTime, startTime, endTime, disabled, onChange, mode=MODES.RANGE, numberOfMonths=2, fromDate}) => {
    const formattedSingleTime = stringToUtcDate(singleTime);
    const formattedStartTime = stringToUtcDate(startTime);
    
    return (
        <DayPicker
            mode={mode}
            selected={mode === MODES.RANGE ? {from: formattedStartTime, to: stringToUtcDate(endTime)} : formattedSingleTime}
            onSelect={selected =>
                onChange(mode === MODES.RANGE ?
                    {startTime: stringToUtcDate(selected.from).toISOString(), endTime: stringToUtcDate(selected.to).toISOString()} :
                    stringToUtcDate(selected).toISOString())
            }
            numberOfMonths={numberOfMonths}
            disabled={disabled}
            disableNavigation={disabled}
            fromDate={fromDate}
            defaultMonth={(mode === MODES.RANGE ? formattedStartTime : formattedSingleTime)}
            className="ps-calendar"
        />
    )
}

export default Calendar;