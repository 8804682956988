import React, { useMemo } from 'react';
import Table, { tableUtils } from 'components/Table';
import { RULE_ACTIONS } from 'utils/systemConsts';

const WorkloadCell = ({endpoint}) => {
    const {app, pod, fqdn, ip} = endpoint;
    const executable = "Dsds";
    
    if (!!pod.id) {
        return (
            <div style={{margin: "auto"}}>
                {!!app.name && <div>{app.name}</div>}
                <div className="cell-sub-title">{executable}</div>
                {!!pod.name && <div className="cell-title">{pod.name}</div>}
            </div>
        );
    }

    return (
        <tableUtils.WithSubtitleRenderer title={!!fqdn ? fqdn : ip} subTitle={!!fqdn && !!ip ? ip : ""} />
    );
};

const AdvisorAffectedConnections = ({filters}) => {
    const columns = useMemo(() => [
        {
            Header: "Source", columns: [
                {
                    Header: "Workload",
                    id: "sourceAppName",
                    Cell: ({row}) => <WorkloadCell endpoint={row.original.source} />,
                    alwaysShow: true,
                    disableSortBy: true
                },
                {
                    Header: "Environment",
                    id: "sourceEnvironmentName",
                    accessor: original => original.source.environment.name,
                    disableSortBy: true
                },
                {
                    Header: "Host / Cluster",
                    id: "sourceHostName",
                    Cell: ({row}) => {
                        const {id, name} = row.original.source.instance;

                        return (
                            <tableUtils.WithSubtitleRenderer title={name} subTitle={id} />
                        )
                    },
                    disableSortBy: true
                }
            ]
        },
        {
            Header: "Destination", columns: [
                {
                    Header: "Workload",
                    id: "targetAppName",
                    Cell: ({row}) => <WorkloadCell endpoint={row.original.target} />,
                    alwaysShow: true,
                    disableSortBy: true
                },
                {
                    Header: "Environment",
                    id: "targetEnvironmentName",
                    accessor: original => original.target.environment.name,
                    disableSortBy: true
                },
                {
                    Header: "Host / Cluster",
                    id: "targetHostName",
                    Cell: ({row}) => {
                        const {id, name} = row.original.target.instance;

                        return (
                            <tableUtils.WithSubtitleRenderer title={name} subTitle={id} />
                        )
                    },
                    disableSortBy: true
                }
            ]
        },
        {
            Header: "Connections", columns: [
                {
                    Header: "Total",
                    id: "total",
                    accessor: "state.count",
                    alwaysShow: true,
                }
            ]
        }
    ], []);

    return (
        <Table
            url="connectionTelemetries"
            name="affectedConnections"
            defaultSortBy={[{id: "lastSeen", desc: true}]}
            columns={columns}
            withPagination={false}
            filters={{...filters, offset: 0, maxResults: 50, result: [RULE_ACTIONS.DETECT, RULE_ACTIONS.BLOCK]}}
        />
    );
}

export default AdvisorAffectedConnections;
