import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { APP_PATHS } from 'utils/systemConsts';
import ImagesTable from './ImagesTable';
import { IMAGES_FILTER_KEYS } from './Filter';
import ImageDetails from './ImageDetails';
import { FilterContext } from './Filter';

export {
    IMAGES_FILTER_KEYS
}

export const IMAGE_PAGE_PATH = `${APP_PATHS.CI_CD.path}/images`;

const Images = () => (
    <FilterContext.FilterProvider>
        <Switch>
            <Route path={`${IMAGE_PAGE_PATH}/:imageId`} component={() => <ImageDetails withIgnore />} />
            <Route path={IMAGE_PAGE_PATH} component={ImagesTable} />
        </Switch>
    </FilterContext.FilterProvider>
)

export default Images;