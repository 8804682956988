import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useMountMultiFetch } from 'hooks';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import Loader from 'components/Loader';
import DetailsTabbedPage from 'components/DetailsTabbedPage';
import TabApi from './TabApi';
import TabDetails from './TabDetails';
import TabPodTemplate from './TabPodTemplate';
import TabSecurityContext from './TabSecurityContext';
import TabLicenses from './TabLicenses';

const RUNTIME_WORKLOAD_TABS = {
    DETAILS: "DETAILS",
    POD_TEMPLATES: "POD_TEMPLATES",
    SECURITY_CONTEXT: "SECURITY_CONTEXT",
    API: "API",
    LICENSES: "LICENSES"
}

const WorkloadDetails = ({showApiSecurity}) => {
    const params = useParams();
    const {telemetryId} = params;

    const [{loading, data, error}] = useMountMultiFetch([
        {key: "general", url: `appTelemetries/${telemetryId}`},
        {key: "imagePackages", url: `appTelemetries/${telemetryId}/imagePackages`},
        ...(!showApiSecurity ? [] : [
            {key: "tokensData", url: `appTelemetries/${telemetryId}/injectionInfo`},
            {key: "risksData", url: `appTelemetries/${telemetryId}/apiRiskInfo`}
        ])
    ]);

    const {general, tokensData, risksData, imagePackages} = data || {};
    const {pod, expansion} = general || {};
    const {name: podName} = pod || {};
    const {name: expansionName, id: expansionId} = expansion || {};

    const [selectedTab, setSelectedTab] = useState(RUNTIME_WORKLOAD_TABS.DETAILS);

    return (
        <DetailsTabbedPage
            loading={loading}
            error={error}
            id={telemetryId}
            title={podName || expansionName}
            backTitle="Workloads"
            tabItems={[
                {
                    id: RUNTIME_WORKLOAD_TABS.DETAILS,
                    title: "Details & Risk",
                    component: () => (
                        <TabDetails
                            telemetryId={telemetryId}
                            data={general}
                            openSecurityContextTab={() => setSelectedTab(RUNTIME_WORKLOAD_TABS.SECURITY_CONTEXT)}
                        />
                    )
                },
                ...(!!expansionId ? [] : [
                    {id: RUNTIME_WORKLOAD_TABS.POD_TEMPLATES, title: "Pod Template", component: () => <TabPodTemplate telemetryId={telemetryId} />},
                    {id: RUNTIME_WORKLOAD_TABS.SECURITY_CONTEXT, title: "Security Context", component: () => <TabSecurityContext data={general} />},
                ]),
                ...(showApiSecurity ? [{id: RUNTIME_WORKLOAD_TABS.API, title: "API", component: () => <TabApi tokensData={tokensData} risksData={risksData} />, disabled: isEmpty(tokensData) && isEmpty(risksData)}] : []),
                {id: RUNTIME_WORKLOAD_TABS.LICENSES, title: "Licenses", disabled: isEmpty(imagePackages), component: () => <TabLicenses imagePackages={imagePackages} />},
                
            ]}
            defaultSelectedTab={selectedTab}
            onChange={selected => setSelectedTab(selected)}
            menual
        />
    )
}

const WorkloadDetailsWrapper = () => {
    const {showApiSecurity, loadingDisplay} = useDisplayConfigState();

    if (loadingDisplay) {
        return <Loader />;
    }

    return (
        <WorkloadDetails showApiSecurity={showApiSecurity} />
    )
}

export default WorkloadDetailsWrapper;