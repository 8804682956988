import React from 'react';
import classnames from 'classnames';
import { isUndefined } from 'lodash';

import COLORS from 'utils/scss_variables.module.scss';

import './line-loader.scss';

const LineLoader = ({className, done, total, title, calculatedPercentage, displayItems=false, displayPercent=false, small=false, color=COLORS["color-main-light"]}) => {
    const percentageProvided = !isUndefined(calculatedPercentage);
    const percent = percentageProvided ? calculatedPercentage : done/total*100;

    return (
        <div className={classnames("line-loader-container", {small}, {[className]: className})}>
            <div className={classnames("line-loader-filler", {done: percent === 100})} style={{backgroundColor: color, width: `${(done === 0 && !percentageProvided) ? 0.1 : percent}%`}}></div>
            {displayItems && <div className="line-loader-title">{`${done} of ${total} ${title}`}</div>}
            {displayPercent && <div className="line-loader-title">{`${percent}%`}</div>}
        </div>
    );
}

export default LineLoader;