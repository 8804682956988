import React from 'react';
import ListValueItemsDisplay from 'components/ListValueItemsDisplay';
import Advisor, { ADVISOR_TYEPS } from 'layout/Advisor';
import { getPspDataItems } from './utils';

const AdvisorEndpointsDisplay = ({data}) => (
    <div className="psp-advisor-content-wrapper">
        <ListValueItemsDisplay items={getPspDataItems(data)} />
    </div>
);

const getFormatData = () => {
    return data => {
        return data.map(item => ({
            recommendations: item.podSecurityPolicyRecommendations.map(recommendation => ({
                id: recommendation.id,
                affected: recommendation.numberOfAffectedWorkloads,
                recommendation: {
                    ...recommendation.podSecurityPolicy
                }
            })),
            total: item.totalWorkloads,
            timePeriod: item.timePeriod
        }));
    }
};

const PodPolicyAdvisor = ({submitAdvisorData, isReadOnly}) => (
    <Advisor
        className="psp-advisor"
        url="advisor/podSecurityPolicy"
        advisorType={ADVISOR_TYEPS.POD_SECURITY_STANDARD}
        formatData={getFormatData()}
        contentDisplayItems={[
            AdvisorEndpointsDisplay
        ]}
        emptyMessage="No Pod policies suggestions"
        applyTitle="Create Pod Policy"
        buttonTitle="Pod Standards Advisor"
        title="Workloads"
        onItemsAdd={data => {
            const submitData = data.map(item => {
                const {id, isSecurecnDefaultProfile, ...other} = item;

                return other
            });

            return submitAdvisorData(submitData);
        }}
        isReadOnly={isReadOnly}
    />
);

export default PodPolicyAdvisor