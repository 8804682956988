import React from 'react';
import { isEmpty } from 'lodash';
import { useFetch, FETCH_METHODS } from 'hooks';
import Wizard from 'components/Wizard';
import { validators } from 'components/Form';
import Loader from 'components/Loader';
import { nameIdToValueLabel } from 'utils/apiUtils';
import FormStep1 from './FormStep1';
import FormStep2, { KUBERNETES_ENV_BY_ITEMS, INITITAL_KEBERNETES_ENV_DATA } from './FormStep2';

import './environment-form.scss';

const EnvironmentForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const [{loading, data, error}] = useFetch("leanKubernetesClusters");

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const isEditForm = !!initialData && initialData.id;
    const wizardSteps = [
        {
            title: "General Details",
            component: FormStep1
        },
        {
            title: "Infrastructure Configuration",
            component: FormStep2
        }
    ];

    
    const initialValues = {
        name: "",
        description: "",
        kubernetesEnvironments: [
            {
                ...INITITAL_KEBERNETES_ENV_DATA
            }
        ],
        ...initialData
    }

    initialValues.description = initialValues.description || "";
    initialValues.kubernetesEnvironments = initialValues.kubernetesEnvironments.map(item => {
        const {kubernetesClusterName, ...envData} = item;

        envData.by = (!isEmpty(envData.namespaceLabels) && !isEmpty(envData.namespaceLabels[0].key)) ?
        KUBERNETES_ENV_BY_ITEMS.BY_LABEL.value : KUBERNETES_ENV_BY_ITEMS.BY_NAME.value;

        return {
            ...INITITAL_KEBERNETES_ENV_DATA,
            ...envData
        }
    });
    
    return (
        <Wizard
            initialValues={initialValues}
            title={`${isEditForm ? "Edit" : "New"} Environment`}
            wizardAlert={initialData?.isSystemEnv ? "You are editing a system environment" : null}
            steps={wizardSteps}
            submitUrl="environments"
            formProps={{
                clusterItems: nameIdToValueLabel(data, item => !item.kubernetesSecurity)
            }}
            getSubmitParams={formValues => {
                const {id, ...submitData} = formValues;

                submitData.kubernetesEnvironments = submitData.kubernetesEnvironments.map(envItem => {
                    const {by, namespaceLabels, preserveOriginalSourceIp, ...envData} = envItem;

                    return {
                        ...envData,
                        namespaceLabels: namespaceLabels.filter(item => item.key !== "" && item.value !== "")
                    }
                });

                return !isEditForm ? {submitData} : {
                    method: FETCH_METHODS.PUT,
                    formatUrl: url => `${url}/${id}`,
                    submitData
                }
            }}
            validate={(values) => {
                const errors = {}; 
                const {kubernetesEnvironments} = values;
                
                const invalidKubernetesEnv = kubernetesEnvironments.find(item => !item.kubernetesCluster);
                if (kubernetesEnvironments.length > 1 && !!invalidKubernetesEnv) {
                    errors.kubernetesEnvironments = validators.ERROR_MSG_IS_REQUIRED;
                }
                
                return errors;
            }}
            onSubmitSuccess={onFormSubmitSuccess}
            onDirtyChanage={onDirtyChanage}
        />
    );
}

export default EnvironmentForm;