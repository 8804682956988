import React, { useState, Suspense } from 'react';
import { Route, Switch, Redirect, Link } from 'react-router-dom';
import classnames from 'classnames';
import { useAuthState, useAuthDispatch, AUTH_ACTIONS } from 'context/AuthProvider';
import { TimeRangeProvider } from 'context/TimeRangeProvider';
import { useDisplayConfigState, useDisplayConfigDispatch, DISPLAY_CONFIG_ACTIONS } from 'context/DisplayConfigProvider';
import { useAccountParamsState } from 'context/AccountParamsProvider';
import { MAIN_GLOBAL_ADMIN_PAGE, DEFAULT_USER_PAGE, APP_PATHS, FREE_TRIAL_URL } from 'utils/systemConsts';
import { isProductionUrl, isDemoPortalUrl } from 'utils/generalUtils';
import Icon, { ICON_NAMES } from 'components/Icon';
import IconWithTooltip from 'components/IconWithTooltip';
import Tooltip from 'components/Tooltip';
import Loader from 'components/Loader';
import CloseButton from 'components/CloseButton';
import Sidebar from 'layout/Sidebar';
import Runtime from 'layout/Runtime';
import Deployments from 'layout/Deployments';
import Accounts from 'layout/Accounts';
import Users from 'layout/Users';
import System from 'layout/System';
import AuditLog from 'layout/AuditLog';
import RiskAssessment from 'layout/RiskAssessment';
import CiCd from 'layout/CiCd';
import Serverless from 'layout/Serverless';
import GlobalAdminSelect from './GlobalAdminSelect';
import UserMenu from './UserMenu';
import LicenseModal from './LicenseModal';
import CisBenchmark from 'layout/CisBenchmark';

import './app.scss';

import upgradeStartIcon from './images/upgrade-star.svg';

const NetworkMap = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "map" */ 'layout/NetworkMap'));
const Agents = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "agents" */ 'layout/AdminControllers'));
const Dashboard = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "dashboard" */ 'layout/Dashboard'));
const Policies = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "policies" */ 'layout/Policies'));
const SwaggerUI = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "swaggerUI" */ 'layout/SwaggerUI'));
const Reviewer = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "reviewer" */ 'layout/Reviewer'));
const Apis = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "apis" */ 'layout/Apis'));
const Help = React.lazy(() => import(/* webpackPrefetch: true, webpackChunkName: "apis" */ 'layout/Help'));

const ApiGuideLink = () => {
    const tooltipId = "api-guide-tooltip";

    return (
        <React.Fragment>
            <a
                className="api-link"
                href={`${window.location.origin}/api/swagger-ui.html`}
                target="_blank"
                rel="noopener noreferrer"
                data-tip data-for={tooltipId}
            >
                API
            </a>
            <Tooltip id={tooltipId} text="API Guide" />
        </React.Fragment>
    )
}

const SuspenseWrapper = Wrapped => (
    props => (
        <Suspense fallback={<Loader />}>
            <Wrapped {...props} />
        </Suspense>
    )
);

const SuspenseAgents = SuspenseWrapper(Agents);

const getAuthenticatedRoutes = ({isReadOnlyUser, isGlobalAdmin}) => ([
    {
        ...APP_PATHS.DASHBOARD,
        component: SuspenseWrapper(Dashboard)
    },
    {
        ...APP_PATHS.RUNTIME,
        component: Runtime
    },
    {
        ...APP_PATHS.NAVIGATOR,
        component: SuspenseWrapper(NetworkMap)
    },
    {
        ...APP_PATHS.DEPLOYMENTS,
        component: Deployments
    },
    {
        ...APP_PATHS.POLICIES,
        component: SuspenseWrapper(Policies)
    },
    {
        ...APP_PATHS.RISK_ASSESSMENT,
        component: RiskAssessment
    },
    {
        ...APP_PATHS.CIS_BENCHMARK,
        component: CisBenchmark
    },
    {
        ...APP_PATHS.CI_CD,
        component: CiCd
    },
    {
        ...APP_PATHS.APIS,
        component: SuspenseWrapper(Apis)
    },
    {
        ...APP_PATHS.SYSTEM,
        component: System,
        forbidden: isReadOnlyUser
    },
    {
        ...APP_PATHS.AUDIT_LOG,
        component: AuditLog
    },
    {
        ...APP_PATHS.SERVERLESS,
        component: Serverless
    },
    {
		...APP_PATHS.REVIEWER,
		component: SuspenseWrapper(Reviewer),
	},
    {
		...APP_PATHS.HELP,
		component: SuspenseWrapper(Help),
	},
    //----------GLOBAL ADMIN---------
    {
        ...APP_PATHS.MANAGE_ACCOUNTS,
        component: Accounts,
        forbidden: !isGlobalAdmin
    },
    {
        ...APP_PATHS.MANAGE_ADMIN_USERS,
        component: Users,
        forbidden: !isGlobalAdmin
    },
    {
        ...APP_PATHS.GLOBAL_ADMIN_AUDIT,
        component: AuditLog,
        forbidden: !isGlobalAdmin
    },
    {
        ...APP_PATHS.GLOBAL_ADMIN_CONTROLLERS,
        component: SuspenseAgents,
        forbidden: !isGlobalAdmin
    }
]);

const UpgradeAccountButton = () => {
    const {isTrialAccount, usageStatus, hideTrialErrorNotification} = useDisplayConfigState();
    const displayConfigDispatch = useDisplayConfigDispatch();
    const {upgradeUrl} = useAccountParamsState();

    const {currentClustersUsage=0, maxClusters, currentNodesUsage=0, maxNodes} = usageStatus;
    const showTrialErrorNotification = isTrialAccount && 
        ((!!maxClusters && maxClusters < currentClustersUsage) || (!!maxNodes && maxNodes < currentNodesUsage));

    if (showTrialErrorNotification && !hideTrialErrorNotification) {
        return (
            <div className="upgrade-account-limits-exceeded-notification">
                <span>
                    You have exceeded your account limits. Please consider <a href={upgradeUrl} target="_blank" rel="noopener noreferrer">upgrading your account.</a>
                </span>
                <Icon name={ICON_NAMES.ERROR} onClick={() => displayConfigDispatch({type: DISPLAY_CONFIG_ACTIONS.HIDE_TRIAL_ERROR_NOTIFICATION})} />
            </div>
        );
    }

    if (!isTrialAccount) {
        return null;
    }
    
    return (
        <a className="upgrade-account-link" href={upgradeUrl} target="_blank" rel="noopener noreferrer">
            <img src={upgradeStartIcon} alt="upgrade account" />
            <span>Upgrade account</span>
        </a>
    )
}

const DemoPortalBannerDisplay = () => {
    const authDispatch = useAuthDispatch();
    const {hideDemoPortalBanner} = useAuthState();

    if (hideDemoPortalBanner) {
        return null
    }

    return (
        <div className="demo-portal-banner">
            <CloseButton onClick={() => authDispatch({type: AUTH_ACTIONS.HIDE_DEMO_PORTAL_BANNER})} />
            <span>Welcome to a better. faster and safer development journey.</span>
            <a href={FREE_TRIAL_URL} target="_blank" rel="noopener noreferrer">Start your free trial</a>
        </div>
    )
}

const App = () => {
    const {isReadOnlyUser, isGlobalAdmin, shouldDisplayEula} = useAuthState();
    
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const routes = getAuthenticatedRoutes({isReadOnlyUser, isGlobalAdmin}).filter(route => !route.forbidden);

    return (
        <Switch>
            <Route path="/swagger/:catalogId" component={SuspenseWrapper(SwaggerUI)} />
            <TimeRangeProvider>
                {isDemoPortalUrl() && <DemoPortalBannerDisplay />}
                <div className="app-wrapper">
                    <Sidebar isOpen={isSidebarOpen} onToggleOpen={() => setIsSidebarOpen(isOpen => !isOpen)} />
                    <main role="main" className={classnames({"open": isSidebarOpen})}>
                        <div className="top-header">
                            <div className="header-content-main">
                                <Switch>
                                    {routes.map((route, index) => {
                                        return <Route key={index} path={route.path} exact={route.exact} component={() => (
                                            <div className="top-title">{route.title}</div>
                                        )} />
                                    })}
                                </Switch>
                                <div className="header-menu-items">
                                    <UpgradeAccountButton />
                                    <Link to={APP_PATHS.HELP.path} className="help-link">
                                        <IconWithTooltip
                                            name={ICON_NAMES.QUESTION}
                                            tooltipId="readme-link-tooltip"
                                            tooltipText="Online Help"
                                        />
                                    </Link>
                                    <ApiGuideLink />
                                    {isGlobalAdmin && <GlobalAdminSelect />}
                                    <UserMenu />
                                </div>
                                {isGlobalAdmin && isProductionUrl() && <div className="production-notification">Production</div>}
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <Switch>
                                {routes.map((route, index) =>{
                                    return (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                })}
                                {isGlobalAdmin ? <Redirect to={MAIN_GLOBAL_ADMIN_PAGE} /> : <Redirect to={DEFAULT_USER_PAGE} />}
                            </Switch>
                        </div>
                        {shouldDisplayEula && <LicenseModal />}
                    </main>
                </div>
            </TimeRangeProvider>
        </Switch>
    );
}

export default App;