import React from 'react';
import Icon, { ICON_NAMES } from 'components/Icon';
import DotLoader from 'components/DotLoader';
import { TooltipWrapper } from 'components/Tooltip';

import COLORS from 'utils/scss_variables.module.scss';

export const STAUSES_MAPPING = {
    NOT_AVAILABLE: {value: "NOT_AVAILABLE", icon: ICON_NAMES.STROKE, color: COLORS["color-grey"], tooltip: "No specs available"},
    AVAILABLE: {value: "AVAILABLE", icon: ICON_NAMES.SUCCESS, color: COLORS["color-success"], tooltip: "Available"},
    COLLECTING_DATA: {value: "COLLECTING_DATA", color: COLORS["color-main"], isLoader: true, tooltip: "Collecting data"},
    REVIEW_REQUIRED: {value: "REVIEW_REQUIRED", icon: ICON_NAMES.EXCLAMATION_MARK_ROUND, color: COLORS["color-warning"], tooltip: "To be reviewed"},
    APPROVING: {value: "APPROVING", isLoader: true, color: COLORS["color-main"], tooltip: "Approving"},
    ABORTING: {value: "ABORTING", isLoader: true, color: COLORS["color-error"], tooltip: "Deleting"},
    ERROR: {value: "ERROR", icon: ICON_NAMES.ERROR, color: COLORS["color-error"], tooltip: <b>Error message for specs</b>},
}

const SpecsStatusIcon = ({id, status, message}) => {
    const {icon, color, tooltip, isLoader} = STAUSES_MAPPING[status] || {};
    
    return (
        <div>
            <TooltipWrapper id={`specs-status-${id}`} text={<span>{tooltip}{!!message ? ` ${message}` : ""}</span>}>
                {isLoader ? <DotLoader color={color} /> : <Icon name={icon} style={{color}} /> }
            </TooltipWrapper>
        </div>
    )
}

export default SpecsStatusIcon;


