import React, { useEffect } from 'react';
import { usePrevious } from 'hooks';
import { isEmpty } from 'lodash';
import {useFormikContext, RadioField, SelectField, validators, ArrayField, TextField, VulnerabilityField,
    AsyncSelectField, MultiselectField} from 'components/Form';
import { valueToValueLabelFromProp } from 'utils/apiUtils';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { RULE_ACTIONS, ENFORCE_ACTION_ITEM, DETECT_ACTION_ITEM, BLOCK_ACTION_ITEM } from 'utils/systemConsts';

export const POD_WORKLOAD_RULE_TYPES_ITEMS = {
    NAME: {
        value: "PodNameWorkloadRuleType",
        label: "By Name"
    },
    LABEL: {
        value: "PodLabelWorkloadRuleType",
        label: "By Label"
    },
    ANY: {
        value: "PodAnyWorkloadRuleType",
        label: "Any"
    }
}

export const SIDECAR_TYPES_ITEMS = {
    ALLOW_ALL: {value: "ALLOW_ALL", label: "Allow all"},
    NONE: {value: "NONE", label: "None"},
    ALLOW_SPECIFIC: {value: "ALLOW_SPECIFIC", label: "Specific images"},
};

export const WORKLOAD_RULE_TYPE = {
    name: "tempWorkloadRuleType",
    value: "POD_WORDLOAD_RULE_TYPE",
    label: "By Identified Pod"
};

export const EMPTY_LABEL_ITEM = {key: "", value: ""};
export const EMPTY_VULNERABILIY_VALUE = {highestVulnerabilityAllowed: "", onViolationAction: ""};
export const EMPTY_PSP_VALUE = {podSecurityPolicyId: "", onViolationAction: ""};
export const EMPTY_API_PROFILE_VALUE = {apiSecurityPolicyId: "", onViolationAction: ""};
export const EMPTY_SIDECAR_VALUE = {type: SIDECAR_TYPES_ITEMS.ALLOW_ALL.value, images: []};

const ViolationActionField = ({name, withEnforce=false, disabled=false, validate}) => {
    const items = [DETECT_ACTION_ITEM, BLOCK_ACTION_ITEM];

    if (withEnforce) {
        items.push(ENFORCE_ACTION_ITEM);
    }

    return (
        <SelectField
            name={name}
            placeholder="Select"
            label="On Violation Action"
            items={items}
            disabled={disabled}
            validate={validate}
        />
    );
}

const FormSubActionsFields = ({pspProfiles, apiSecurityProfiles, isReadOnlyUser}) => {
    const {showApiSecurity} = useDisplayConfigState();

    const {values, setFieldValue, validateForm} = useFormikContext();
    const {app, ruleTypeProperties} = values;
    const {action} = ruleTypeProperties;
    const {vulnerability, podSecurityPolicy, apiSecurityPolicy, allowedInjectedSidecars} = app.podValidation || {};
    const {type: allowedInjectedSidecarsType, images: allowedInjectedSidecarsImages} = allowedInjectedSidecars || {};
    const {highestVulnerabilityAllowed} = vulnerability || {};
    const {podSecurityPolicyId} = podSecurityPolicy || {};
    const {apiSecurityPolicyId} = apiSecurityPolicy || {};

    const isAllowRule = action === RULE_ACTIONS.ALLOW;
    const specificSidecars = allowedInjectedSidecarsType === SIDECAR_TYPES_ITEMS.ALLOW_SPECIFIC.value;
    
    useEffect(() => {
        validateForm();

        if (highestVulnerabilityAllowed === "") {
            setFieldValue("app.podValidation.vulnerability.onViolationAction", "");
        }

        if (!!highestVulnerabilityAllowed && !isAllowRule) {
            setFieldValue("app.podValidation.vulnerability.onViolationAction", action);
        }
    }, [highestVulnerabilityAllowed, isAllowRule, validateForm, setFieldValue, action]);

    useEffect(() => {
        validateForm();

        if (podSecurityPolicyId === "") {
            setFieldValue("app.podValidation.podSecurityPolicy.onViolationAction", "");
        }

        if (!!podSecurityPolicyId && !isAllowRule) {
            setFieldValue("app.podValidation.podSecurityPolicy.onViolationAction", action);
        }
    }, [podSecurityPolicyId, isAllowRule, validateForm, setFieldValue, action]);

    useEffect(() => {
        validateForm();

        if (apiSecurityPolicyId === "") {
            setFieldValue("app.podValidation.apiSecurityPolicy.onViolationAction", "");
        }

        if (!!apiSecurityPolicyId && !isAllowRule) {
            setFieldValue("app.podValidation.apiSecurityPolicy.onViolationAction", action);
        }
    }, [apiSecurityPolicyId, isAllowRule, validateForm, setFieldValue, action]);

    useEffect(() => {
        validateForm();

       if (allowedInjectedSidecarsType !== SIDECAR_TYPES_ITEMS.ALLOW_SPECIFIC.value && !isEmpty(allowedInjectedSidecarsImages)) {
            setFieldValue("app.podValidation.allowedInjectedSidecars.images", []);
        }
    }, [allowedInjectedSidecarsType, allowedInjectedSidecarsImages, setFieldValue, validateForm]);

    return (
        <React.Fragment>
            <div className="pod-validation-field-container">
                <VulnerabilityField
                    name="app.podValidation.vulnerability.highestVulnerabilityAllowed"
                    label="Vulnerability Level"
                    disabled={isReadOnlyUser}
                />
                <ViolationActionField
                    name="app.podValidation.vulnerability.onViolationAction"
                    disabled={!isAllowRule || !highestVulnerabilityAllowed || isReadOnlyUser}
                    validate={isAllowRule && !!highestVulnerabilityAllowed ? validators.validateRequired : undefined}
                />
            </div>
            <div className="pod-validation-field-container">
                <SelectField
                    name="app.podValidation.podSecurityPolicy.podSecurityPolicyId"
                    placeholder="Select"
                    label="Pod Security Standards Profile"
                    items={pspProfiles}
                    disabled={isReadOnlyUser}
                />
                <ViolationActionField
                    name="app.podValidation.podSecurityPolicy.onViolationAction"
                    withEnforce={true}
                    disabled={!isAllowRule || !podSecurityPolicyId || isReadOnlyUser}
                    validate={isAllowRule && !!podSecurityPolicyId ? validators.validateRequired : undefined}
                />
            </div>
            {showApiSecurity &&
                <div className="pod-validation-field-container">
                    <SelectField
                        name="app.podValidation.apiSecurityPolicy.apiSecurityPolicyId"
                        placeholder="Select"
                        label="API Policy Profile"
                        items={apiSecurityProfiles}
                        disabled={isReadOnlyUser}
                    />
                    <ViolationActionField
                        name="app.podValidation.apiSecurityPolicy.onViolationAction"
                        disabled={!isAllowRule || !apiSecurityPolicyId || isReadOnlyUser}
                        validate={isAllowRule && !!apiSecurityPolicyId ? validators.validateRequired : undefined}
                    />
                </div>
            }
            {!isAllowRule &&
                <div className="sidecars-field-container">
                    <SelectField
                        name="app.podValidation.allowedInjectedSidecars.type"
                        placeholder="Select"
                        label="Allowed Sidecars"
                        items={Object.values(SIDECAR_TYPES_ITEMS)}
                        disabled={isReadOnlyUser}
                        clearable={false}
                    />
                    <MultiselectField
                        name="app.podValidation.allowedInjectedSidecars.images"
                        label="Images"
                        items={[]}
                        creatable={true}
                        disabled={isReadOnlyUser || !specificSidecars}
                        validate={specificSidecars ? validators.validateRequired : undefined}
                        showValueTooltip={true}
                    />
                </div>
            }
        </React.Fragment>
    )
};

const FormWorkloads = ({pspProfiles, apiSecurityProfiles, isInjectionRule, isReadOnlyUser}) => {
    const {values, setFieldValue, validateForm} = useFormikContext();
    const {workloadRuleType} = values.app || {};
    const prevWorkloadRuleType = usePrevious(workloadRuleType);

    useEffect(() => {
        if (workloadRuleType === prevWorkloadRuleType) {
            return;
        }

        validateForm();
        
        if (prevWorkloadRuleType === POD_WORKLOAD_RULE_TYPES_ITEMS.NAME.value) {
            setFieldValue("app.names", []);
        }
        
        if (prevWorkloadRuleType === POD_WORKLOAD_RULE_TYPES_ITEMS.LABEL.value) {
            setFieldValue("app.labels", [EMPTY_LABEL_ITEM]);
        }
    }, [prevWorkloadRuleType, workloadRuleType, validateForm, setFieldValue]);

    return (
        <React.Fragment>
            <SelectField 
                name={`app.${WORKLOAD_RULE_TYPE.name}`}
                label="Select an option"
                items={[
                    {value: WORKLOAD_RULE_TYPE.value, label: WORKLOAD_RULE_TYPE.label},
                ]}
                disabled={true}
            />
            <RadioField
                name="app.workloadRuleType"
                horizontal={true}
                items={Object.values(POD_WORKLOAD_RULE_TYPES_ITEMS)}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {workloadRuleType === POD_WORKLOAD_RULE_TYPES_ITEMS.NAME.value &&
                <AsyncSelectField
                    isMulti
                    name="app.names"
                    label="Names"
                    placeholder="Type to search"
                    creatable={true}
                    url="podDefinitions"
                    formatData={data => valueToValueLabelFromProp(data, "name")}
                    getQueryParams={inputValue => ({name: inputValue, offset: 0, maxResults: 50})}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />
            }
            {workloadRuleType === POD_WORKLOAD_RULE_TYPES_ITEMS.LABEL.value &&
                <ArrayField
                    name="app.labels"
                    label="Labels"
                    firstFieldProps={{
                        component: TextField,
                        key: "key",
                        placeholder: "Key",
                        validate: validators.validateRequired
                    }}
                    secondFieldProps={{
                        component: TextField,
                        key: "value",
                        placeholder: "Value",
                        validate: validators.validateRequired
                    }}
                    disabled={isReadOnlyUser}
                />
            }
            {!isInjectionRule &&
                <FormSubActionsFields pspProfiles={pspProfiles} apiSecurityProfiles={apiSecurityProfiles} isReadOnlyUser={isReadOnlyUser} />
            }
        </React.Fragment>
    )
}

export default FormWorkloads;