import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { cloneDeep, isNull, isEmpty } from 'lodash';
import { Formik, Form, useFormikContext } from 'formik';
import { useFetch, usePrevious, FETCH_METHODS } from 'hooks';
import Icon, { ICON_NAMES } from 'components/Icon';
import IconWithTitle from 'components/IconWithTitle';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Text, { TEXT_TYPES } from 'components/Text';
import Tooltip, { TooltipWrapper } from 'components/Tooltip';

import 'components/Form/form.scss';
import './wizard.scss';

const TooltipWrappedButton = ({tooltipId, tooltipText, children}) => {
    if (!tooltipId) {
        return (<>{children}</>)
    }

    return (
        <TooltipWrapper id={tooltipId} text={<div style={{width: "200px"}}>{tooltipText}</div>}>
            {children}
        </TooltipWrapper>
    )
}

const Wizard = (props) => {
    const {setSnapshot, activeStep, setActiveStep, formProps, steps, submitUrl, onDirtyChanage, onSubmitSuccess, getSubmitParams,
        doCustomSubmit, disableSubmit, disableSubmitTooltip, disableStepChangeValidation, backClickOnlyOnValid=false} = props;
    const {values, isSubmitting, isValidating, setSubmitting, status, setStatus, dirty, isValid, setErrors, validateForm} = useFormikContext();
    const prevDirty = usePrevious(dirty);

	const [{loading, data, error}, submitFormData] = useFetch(submitUrl, {loadOnMount: false});
	const prevLoading = usePrevious(loading);

	useEffect(() => {
		if (prevLoading && !loading) {
			setSubmitting(false);
			setStatus(null);

			if (isNull(error)) {
				if (!!onSubmitSuccess) {
					onSubmitSuccess(data);
				}
			} else {
				const {message, errors} = error;

				if (!!message) {
					setStatus(message);
				}

				if (!isEmpty(errors)) {
					setErrors(errors);
				}
			}
		}
	}, [prevLoading, loading, error, data, setSubmitting, setStatus, onSubmitSuccess, setErrors]);

	useEffect(() => {
		if (prevDirty === dirty) {
			return;
		}

		if (dirty && !!onDirtyChanage) {
			onDirtyChanage(true);
		}
    }, [dirty, prevDirty, onDirtyChanage]);

    useEffect(() => {
        validateForm();
    }, [activeStep, validateForm]);

	if (isSubmitting || loading) {
		return <Loader />;
	}

    const goToStep = (newStep) => {
        setSnapshot(values);
        setActiveStep(newStep);
    };

    const totalSteps = steps.length;
    const goToNext = (stepTitle) => {
        goToStep(activeStep + 1);
    }

    const handleSubmit = (stepTitle) => {
        if (!!doCustomSubmit) {
            doCustomSubmit(cloneDeep(values));

            return;
        }

        const submitQueryParams = !!getSubmitParams ? getSubmitParams(cloneDeep(values)) : {};
        submitFormData({method: FETCH_METHODS.POST, submitData: values, ...submitQueryParams});
    }

    return (
        <Form>
            {!!status && <div className="main-error-message">{status}</div>}
            {steps.map((step, index) => {
                const {title, component: StepComponent, allowFinish, isOptional, finishButtonClass="step-finish-button",
                    nextButtonClass="step-next-button"} = step;

                const stepIndex = index + 1;
                let isActive = false;
                let isDone = false;
                let iconName = ICON_NAMES.WAITING;
                const isLast = stepIndex === totalSteps;

                if (activeStep === stepIndex) {
                    isActive = true;
                    iconName = ICON_NAMES.EDIT;
                } else if (activeStep > stepIndex) {
                    isDone = true;
                    iconName = ICON_NAMES.SUCCESS;
                }

                const disableStepDone = isSubmitting || isValidating || !isValid;
                const disableStepClick = isSubmitting || isValidating || (!isValid && backClickOnlyOnValid);
                const allowStepClick = (!disableStepClick || disableStepChangeValidation) && isDone;
                const onStepClick = () =>  allowStepClick ? goToStep(stepIndex) : undefined;
                const disabledStepTooltipId = `step-${stepIndex}-tooltip-id`;
                const disabledSubmitTooltipId = "wizard-submit-tooltiop-id";

                return (
                    <div className={classnames("wizard-step", {done: isDone}, {active: isActive}, {last: isLast})} key={index} >
                        {disableStepClick && isDone &&
                            <Tooltip id={disabledStepTooltipId} text={<span>Please complete the current step<br />before moving to the next</span>} placement="right" />}
                        <div
                            className={classnames("step-icon-container", {disabled: !allowStepClick})}
                            onClick={onStepClick}
                            data-tip
                            data-for={disabledStepTooltipId}
                        >
                            <Icon name={iconName} />
                        </div>
                        <div className="step-content">
                            <div className="step-index">{`STEP ${stepIndex} ${isOptional ? "(Optional)" : ""}`}</div>
                            <h2
                                className={classnames("step-title", {disabled: !allowStepClick})}
                                onClick={onStepClick}
                                data-tip
                                data-for={disabledStepTooltipId}
                            >{title}</h2>
                            <div className="step-form">
                                {isActive &&
                                    <React.Fragment>
                                        <StepComponent {...formProps} formValues={values} />
                                        <div className="step-buttons-wrapper">
                                            <TooltipWrappedButton
                                                tooltipId={isLast && disableSubmit && disableSubmitTooltip ? disabledSubmitTooltipId : null}
                                                tooltipText={disableSubmitTooltip}
                                            >
                                                <Button
                                                    type="button"
                                                    secondary={!isLast}
                                                    disabled={(disableStepDone && !disableStepChangeValidation) || (isLast && disableSubmit)}
                                                    onClick={() => isLast ? handleSubmit(title) : goToNext(title)}
                                                    className={isLast ? finishButtonClass : nextButtonClass}
                                                >{isLast ? "Finish" : "Next"}</Button>
                                            </TooltipWrappedButton>
                                            {allowFinish &&
                                                <TooltipWrappedButton
                                                    tooltipId={disableSubmit && disableSubmitTooltip ? disabledSubmitTooltipId : null}
                                                    tooltipText={disableSubmitTooltip}
                                                >
                                                    <Button
                                                        type="button"
                                                        disabled={disableStepDone || disableSubmit}
                                                        onClick={() => handleSubmit(title)}
                                                        className={finishButtonClass}
                                                    >Finish</Button>
                                                </TooltipWrappedButton>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </Form>
    );
}

const WizardWrapper = ({initialValues, className, title, validate, startOnStep, steps, wizardAlert, ...props}) => {
    const [snapshot, setSnapshot] = useState(initialValues);
    const [activeStep, setActiveStep] = useState(startOnStep || 1);

    return (
        <div className={classnames("wizard-content", className)}>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{title}</Text>
            {!!wizardAlert && <div className="wizard-general-alert"><IconWithTitle name={ICON_NAMES.INFO_ROUND} title={wizardAlert} /></div>}
            <Formik initialValues={snapshot} validate={validate} validationSchema={steps[activeStep-1].validationSchema}>
                <Wizard
                    snapshot={snapshot}
                    setSnapshot={setSnapshot}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    steps={steps}
                    {...props}
                />
            </Formik>
        </div>
    )
}

export default WizardWrapper;