import React, { useEffect } from 'react';
import { get } from 'lodash';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { valueToValueLabelFromProp } from 'utils/apiUtils';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { usePrevious } from 'hooks';
import { validators, MultiselectField, AsyncSelectField, ArrayField, RadioField, TextField,
    VulnerabilityField, useFormikContext, utils, SelectField } from 'components/Form';
import { getNoSourceIpEnvAlert, ENDPOINT_TYPE_FIELD_NAME } from './utils';

export const POD_TYPES_ITEMS = {
    NAME: {
        value: "PodNameConnectionRulePart",
        label: "By Name"
    },
    LABEL: {
        value: "PodLablesConnectionRulePart",
        label: "By Label"
    },
    ANY: {
        value: "PodAnyConnectionRulePart",
        label: "Any"
    }
}

const PodForm = ({endpoint, displayEnvAlert, environments, environmentNames, apiSecurityPolicyNames, isReadOnlyUser}) => {
    const {showApiSecurity} = useDisplayConfigState();

    const {values, setFieldValue} = useFormikContext();
    const {destination, action, layer7Settings} = values;
    const {isIntercept} = layer7Settings;
    
    const isAllowRule = action === RULE_ACTIONS.ALLOW;

    const typeFieldName = `${endpoint}.${ENDPOINT_TYPE_FIELD_NAME}`;
    const typeFieldValue = get(values, typeFieldName);
    const prevTypeFieldValue = usePrevious(typeFieldValue);

    let envAlert = null;
    if (displayEnvAlert) {
        envAlert = getNoSourceIpEnvAlert(destination.environments || [], environments);
    }

    useEffect(() => {
        if (typeFieldValue === "") {
            setFieldValue(typeFieldName, isIntercept ? POD_TYPES_ITEMS.LABEL.value : POD_TYPES_ITEMS.NAME.value);
        }

        if (typeFieldValue === POD_TYPES_ITEMS.NAME.value && isIntercept) {
            setFieldValue(typeFieldName, POD_TYPES_ITEMS.LABEL.value);
        }

        if (prevTypeFieldValue === typeFieldValue || !prevTypeFieldValue) {
            return;
        }

        setFieldValue(`${endpoint}.names`, []);
        setFieldValue(`${endpoint}.labels`, [{key: "", value: ""}]);
    }, [typeFieldValue, prevTypeFieldValue, typeFieldName, setFieldValue, endpoint, isIntercept]);

    return (
        <React.Fragment>
            <RadioField
                name={typeFieldName}
                horizontal={true}
                items={isIntercept ? [POD_TYPES_ITEMS.LABEL, POD_TYPES_ITEMS.ANY] : Object.values(POD_TYPES_ITEMS)}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {typeFieldValue === POD_TYPES_ITEMS.NAME.value &&
                <AsyncSelectField
                    isMulti
                    name={`${endpoint}.names`}
                    label="Names"
                    placeholder="Type to search"
                    url="podDefinitions"
                    formatData={data => valueToValueLabelFromProp(data, "name")}
                    getQueryParams={inputValue => ({name: inputValue, offset: 0, maxResults: 50})}
                    creatable={true}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />}
            {typeFieldValue === POD_TYPES_ITEMS.LABEL.value &&
                <ArrayField
                    name={`${endpoint}.labels`}
                    label="Labels"
                    firstFieldProps={{
                        component: TextField,
                        key: "key",
                        placeholder: "Key",
                        validate: validators.validateRequired
                    }}
                    secondFieldProps={{
                        component: TextField,
                        key: "value",
                        placeholder: "Value",
                        validate: validators.validateRequired
                    }}
                    disabled={isReadOnlyUser}
                />}
            <VulnerabilityField
                name={`${endpoint}.vulnerabilitySeverityLevel`}
                label={`Vulnerability level ${isAllowRule ? "lower" : "higher"} than`}
                disabled={isReadOnlyUser}
            />
            {showApiSecurity && 
                <SelectField
                    name={`${endpoint}.apiSecurityProfile`}
                    label={`${isAllowRule ? "Complies" : "Does not comply"} to API policy profile`}
                    items={apiSecurityPolicyNames}
                    disabled={isReadOnlyUser}
                />}
            <MultiselectField
                name={`${endpoint}.environments`}
                label="Runtime environment"
                items={environmentNames}
                disabled={isReadOnlyUser}
            />
            {displayEnvAlert && !!envAlert && <utils.FieldAlert message={envAlert} />}
        </React.Fragment>
    );
}

export default PodForm;