import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { useField } from 'formik';
import { useFetch, usePrevious } from 'hooks';
import DropdownButton from 'components/DropdownButton';
import Checkbox from 'components/Checkbox';
import Arrow, { ARROW_NAMES } from 'components/Arrow';
import { TooltipWrapper } from 'components/Tooltip';
import Loader from 'components/Loader';
import { nameIdToValueLabel } from 'utils/apiUtils';
import { FieldLabel, FieldError, SearchInput, EmptyFilterMessage } from '../utils';

import './clusters-namespaces-filter-field.scss';

const FieldInput = ({name, value, placeholder, clusters, namespacesData}) => {
    const getdisplayList = () => {
        return value.map(({clusterId, namespaceIds=[]}) => {
            const clusterName = clusters.find(({value}) => value === clusterId)?.label || clusterId;
            const clusterNamespaces = namespacesData[clusterId] || [];
            const namespacesNames = clusterNamespaces.length === namespaceIds.length ? [] :
            namespaceIds?.map(namespaceId => clusterNamespaces.find(({value}) => value === namespaceId)?.label || namespaceId) || [];
            
            return `${clusterName}${isEmpty(namespacesNames) ? "" : " - "}${namespacesNames.join(", ")}`;
        })
    }

    const selectedList = getdisplayList();
    const tooltipDisplay = (<div>{isEmpty(value) ? placeholder : selectedList.map((item, index) => <div key={index}>{item}</div>)}</div>);

    return (
        <TooltipWrapper id={`clusters-namespcases-filter-field-${name}`} text={tooltipDisplay} style={{display: "block"}}>
            <div className="input-field">
                {isEmpty(value) ? <span className="placeholder">{placeholder}</span> : selectedList.join("; ")}
                <Arrow name="bottom" small className="open-menu-icon" />
            </div>
        </TooltipWrapper>
    )
}

const ItemsDisplay = ({items=[], value, selectAllSeffix, loading, selectedCount, onItemDrilldown, onItemClick, onAllItemsClick}) => {
    const [filteredItems, setFilteredItems] = useState(items);
    const prevItems = usePrevious(items);
    
    useEffect(() => {
        if (items?.length !== prevItems?.length) {
            setFilteredItems(items);
        }
    }, [items, prevItems]);

    const doFilterItems = substring => {
        const filteredItems = items.filter(({label}) => label.toLowerCase().includes(substring.toLowerCase()));
        
        setFilteredItems(filteredItems);
    }

    const onAllClick = (event) => {
        const {checked} = event.target;
        const selectedItems = checked ? items.map(({value}) => value) : [];
        
        onAllItemsClick(selectedItems);
    }

    const allSelected = selectedCount === items.length && items.length > 0;
    const halfSelected = selectedCount > 0 && !allSelected;
    const showAllSelected = allSelected || halfSelected;

    return (
        <div className="multiselect-content-container">
            <React.Fragment>
                <SearchInput name="items-search" onChange={doFilterItems} disabled={loading} />
                <Checkbox
                    value="multiselect-show-all"
                    name="multiselect-show-all"
                    title={`Select all ${selectAllSeffix}`}
                    checked={showAllSelected}
                    onChange={onAllClick}
                    halfSelected={halfSelected}
                    disabled={items.length === 0 || items.length !== filteredItems.length || loading}
                    small
                />
                {loading ? <div style={{margin: "10px"}}><Loader small absolute={false} /></div> :
                    <div className="multiselect-items-container">
                        {
                            filteredItems.map((item) => {
                                
                                return (
                                    <div key={item.value} style={{display: "flex", justifyContent: "space-between"}}>
                                        <Checkbox
                                            name="multiselect-item"
                                            value={item.value}
                                            title={item.label}
                                            checked={value.includes(item.value)}
                                            onChange={onItemClick}
                                            small
                                        />
                                        {!!onItemDrilldown && <Arrow name={ARROW_NAMES.RIGHT} small onClick={() => onItemDrilldown(item)} />}
                                    </div>
                                )
                            })
                        }
                        {filteredItems.length === 0 && <EmptyFilterMessage />}
                    </div>
                }
            </React.Fragment>
        </div>
    )
}

const NamespacesItemsDisplay = ({clusterId, value, backTitle, onBackClick, setValue, onNamespacesFetch}) => {
    const [{loading, data}] = useFetch(`/kubernetesClusters/${clusterId}/namespaces`);
    const prevLoading = usePrevious(loading);
    const namespacesItems = nameIdToValueLabel(data);

    useEffect(() => {
        if (prevLoading && !loading) {
            onNamespacesFetch(namespacesItems);
        }
    }, [loading, prevLoading, namespacesItems, onNamespacesFetch]);

    const selectedItemIndex = value.findIndex(item => item.clusterId === clusterId);
    const namespacesValue = value[selectedItemIndex]?.namespaceIds || [];

    const doSetValue = (selectedNamespaces) => setValue([
        ...value.slice(0, selectedItemIndex),
        {clusterId, namespaceIds: selectedNamespaces},
        ...value.slice(selectedItemIndex + 1)
    ]);
    
    const onNamespaceItemClick = (event) => {
        const {checked, value: clickedValue} = event.target;
        let selectedItems = [...namespacesValue];
        
        if (checked) {
            selectedItems.push(clickedValue);
        } else {
            selectedItems = selectedItems.filter(item => item !== clickedValue);
        }
        
        doSetValue(selectedItems);
    }

    return (
        <div className="namespaces-multiselect-content-wrapper">
            <div onClick={onBackClick} className="namespace-back-button-wrapper">
                <Arrow name={ARROW_NAMES.LEFT} small />
                <div>{backTitle}</div>
            </div>
            <ItemsDisplay
                items={namespacesItems}
                onItemClick={onNamespaceItemClick}
                value={namespacesValue}
                selectedCount={namespacesValue.length}
                selectAllSeffix="namespaces"
                loading={loading}
                onAllItemsClick={selectedNamespaces => doSetValue(selectedNamespaces)}
            />
        </div>
    )
}

const ClustersNamespacesFilterField = (props) => {
    const {className, label, tooltipText, items} = props;
    const [field, meta, helpers] = useField(props);
    const {name} = field;
    const value = [...field.value];
    const {setValue} = helpers;

    const [isOpen, setIsOpen] = useState(false); 

    const [selectedClusterForDrilldown, setSelectedClusterForDrilldown] = useState(null);

    const [fetchedNamespacesData, setFetchedNamespacesData] = useState({});

    const fieldRef = useRef();

    const handleClick = ({target}) => {
        if (fieldRef.current.contains(target)) {
            return;
        }

        setIsOpen(false);
    };

    const LISTENER_EVENT_NAME = "mousedown";
    
    useEffect(() => {
        const removeListener = () => document.removeEventListener(LISTENER_EVENT_NAME, handleClick);

        if (isOpen) {
            document.addEventListener(LISTENER_EVENT_NAME, handleClick);
        } else {
            removeListener();
        }
    
        return () => {
            removeListener();
        };
      }, [isOpen]);

    const onClusterItemClick = (event) => {
        const {checked, value: clickedValue} = event.target;
        let selectedItems = value;

        if (checked) {
            selectedItems.push({clusterId: clickedValue});
        } else {
            selectedItems = selectedItems.filter(item => item.clusterId !== clickedValue);
        }
        
        setValue(selectedItems);
    }

    const selectedClusterIds = value.map(item => item.clusterId);
    
    return (
        <div ref={fieldRef} className={classnames("ps-field-wrapper", "ps-clusters-namespaces-filter-field-wrapper", {[className]: className})}>
            {!isEmpty(label) && <FieldLabel tooltipId={name} tooltipText={tooltipText}>{label}</FieldLabel>}
            <div className="selector-wrapper">
                <DropdownButton
                    toggleButton={<FieldInput name={name} clusters={items} namespacesData={fetchedNamespacesData} value={value} placeholder="Select..." />}
                    isOpen={isOpen}
                    onToggle={() => setIsOpen(!isOpen)}
                    manualOpen
                >
                    {!selectedClusterForDrilldown ?
                        <ItemsDisplay
                            items={items}
                            onItemClick={onClusterItemClick}
                            onAllItemsClick={selectedItems => setValue(selectedItems.map(clusterId => ({clusterId})))}
                            value={selectedClusterIds}
                            onItemDrilldown={cluster => setSelectedClusterForDrilldown(cluster)}
                            selectAllSeffix="clusters"
                            selectedCount={value.filter(item => !!item.clusterId).length}
                        /> :
                        <NamespacesItemsDisplay
                            clusterId={selectedClusterForDrilldown.value}
                            value={value}
                            backTitle={selectedClusterForDrilldown.label}
                            onBackClick={() => setSelectedClusterForDrilldown(null)}
                            setValue={setValue}
                            onNamespacesFetch={namespaces => setFetchedNamespacesData({...fetchedNamespacesData, [selectedClusterForDrilldown.value]: namespaces})}
                        />
                    }
                </DropdownButton>
            </div>
            {meta.touched && meta.error && <FieldError>{meta.error}</FieldError>}
        </div>
    )
}

export default ClustersNamespacesFilterField;