import React from 'react';
import { sortBy, isNull, isEmpty } from 'lodash';
import Text, { TEXT_TYPES } from 'components/Text';
import Button from 'components/Button';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Modal from 'components/Modal';
import { formatDateBy } from 'utils/generalUtils';
import { useAuthState } from 'context/AuthProvider';
import VersionsTable from './VersionsTable';
import usePolicyHistoryReducer, { HISTORY_ACTIONS, usePrevious } from './usePolicyHistoryReducer';

import './policy-history.scss';

const BasePolicyHistoryButtonModal = (props) => {
    const {url, onClose, policyDisplayComponent: PolicyDisplayComponent, formatUserRules, formatSubmitData} = props;
    const [{historyState, loading}, dispatch] = usePolicyHistoryReducer(url);
    const {historyData, selectedData, restoreSuccess, showHistory} = historyState;

    const prevRestoreSuccess = usePrevious(restoreSuccess);
    const {isReadOnlyUser} = useAuthState();

    const toggleHistoryShow = (show) =>
        dispatch({type: HISTORY_ACTIONS.TOGGLE_HISTORY_SHOW, payload: show});

    const handleRestore = () => {
        dispatch({type: HISTORY_ACTIONS.RESTORE_VERSION, payload: formatSubmitData(selectedData.policy)});
    }

    if (!prevRestoreSuccess && restoreSuccess && showHistory) {
        toggleHistoryShow(false);

        onClose();
    }

    const sortedItems = sortBy(historyData, "updateTime").reverse();
    const lastUpdate = !isEmpty(sortedItems) ? formatDateBy(sortedItems[0].updateTime, "DD/MM/YY") : "-";

    const onDataSelect = selectedData => {
        if (!selectedData) {
            return;
        }
        
        const {policy} = selectedData;

        dispatch({type: HISTORY_ACTIONS.SET_SELECTED_POLICY_DATA, payload: {
            ...selectedData,
            policy: {...policy, userRules: formatUserRules(policy.userRules)}
        }});
    }

    return (
        <React.Fragment>
            <IconWithTitle
                name={ICON_NAMES.HISTORY}
                title="Policy version history"
                onClick={() => toggleHistoryShow(true)}
            />
            {showHistory &&
                <Modal centerLarge={true} loading={loading} onClose={() => toggleHistoryShow(false)}>
                    <div className="policy-history-container">
                        <div className="title-container">
                            <Text type={TEXT_TYPES.TITLE_MEDIUM} withTopMargin>Policy Version History</Text>
                            <div className="sub-title">Last update:<span>{lastUpdate}</span></div>
                        </div>
                        <div className="version-content-container">
                            <div className="versions-list-container">
                                <VersionsTable
                                    data={sortedItems.map((item, index) => ({...item, id: index}))}
                                    onSelect={onDataSelect}
                                />
                            </div>
                            <div className="version-display-container">
                                {!isNull(selectedData) &&
                                    <React.Fragment>
                                        <Button className="restore-btn" disabled={isReadOnlyUser} onClick={handleRestore}>
                                            Restore Version
                                        </Button>
                                        <div className="selected-data-wrapper">
                                            <PolicyDisplayComponent data={selectedData.policy} />
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            
        </React.Fragment>
    );
}

export default BasePolicyHistoryButtonModal;