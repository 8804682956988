import React from 'react';
import { isEmpty, isNull } from 'lodash'
import DisplaySection from 'components/DisplaySection';
import ListValueItemsDisplay from 'components/ListValueItemsDisplay';
import { getBooleanValue } from './utils';

const ContainerSpecsDisplay = (props) => {
    const {name, allowPrivilegeEscalation, privileged, readOnlyRootFilesystem, runAsNonRoot, runAsGroup,
        runAsUser, procMount, capabilitiesAdd, capabilitiesDrop, title, allowClose} = props;

    const items = [
        {name: "allowPrivilegeEscalation", values: [getBooleanValue(allowPrivilegeEscalation)]},
        {name: "privileged", values: [getBooleanValue(privileged)]},
        {name: "readOnlyRootFilesystem", values: [getBooleanValue(readOnlyRootFilesystem)]},
        {name: "runAsNonRoot", values: [getBooleanValue(runAsNonRoot)]},
        {name: "runAsGroup", values: isNull(runAsGroup) ? [] : [runAsGroup]},
        {name: "runAsUser", values: isNull(runAsUser) ? [] : [runAsUser]},
        {name: "procMount", values: [procMount]}
    ];

    if (!isEmpty(capabilitiesAdd)) {
        items.push({name: "capabilitiesAdd", values: capabilitiesAdd});
    }

    if (!isEmpty(capabilitiesDrop)) {
        items.push({name: "capabilitiesDrop", values: capabilitiesDrop});
    }

    return (
        <DisplaySection title={`${title} properties: ${name}`} allowClose={allowClose}>
            <ListValueItemsDisplay items={items} />
        </DisplaySection>
    )
}

export default ContainerSpecsDisplay;