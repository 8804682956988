import React, { useEffect } from 'react';
import { Route, BrowserRouter, useLocation, Redirect } from 'react-router-dom';
import { AuthProvider, useAuthState, useAuthDispatch, loginUser } from 'context/AuthProvider';
import { AccountParamsProvider, useAccountParamsState } from 'context/AccountParamsProvider';
import { AdminProvider } from 'context/AdminProvider';
import { DisplayConfigProvider, useDisplayConfigDispatch, DISPLAY_CONFIG_ACTIONS } from 'context/DisplayConfigProvider';
import { NotificationProvider, useNotificationState, useNotificationDispatch, removeNotification } from 'context/NotificationProvider';
import { usePrevious } from 'hooks';
import { LOGIN_PATH, LOGIN_CALLBACK_PATH } from 'utils/systemConsts';
import Login from 'layout/Login';
import App from 'layout/App';
import Loader from 'components/Loader';
import Notification from 'components/Notification';

import './root.scss';

const LoginCallback = () => {
	const authDispatcher = useAuthDispatch();
	const {isAuthenticated, isAuthenticateError} = useAuthState();
	const {isLoadingError, oktaAuth} = useAccountParamsState();

	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location.search);
	const sessionRequest = urlSearchParams.get("request");

	if (!!sessionRequest && !!oktaAuth) {
		oktaAuth.token.getWithRedirect({
              state: JSON.stringify({sessionRequest})
            });
	}

	try {
		if (!!oktaAuth && oktaAuth.isLoginRedirect()) {
			oktaAuth.token.parseFromUrl().then(async function(res) {
				const {accessToken} = res.tokens.accessToken || {};
				
				loginUser(authDispatcher, accessToken);
			})
		}
	} catch (authError) {
		console.log(authError);
	}
	
	if (isAuthenticated || isLoadingError || isAuthenticateError) {
		return <Redirect to="/" />;
	}

	return <Loader />;
}

const PrivateRoute = ({ component, ...rest }) => {
	const location = useLocation();

	const { isAuthenticated, apiSecurity, accountTier, permissionsMode, isLoading, serverlessSecurity, usageStatus } = useAuthState();
	const prevIsAuthenticated = usePrevious(isAuthenticated);

	const displayConfigDispatch = useDisplayConfigDispatch();

	useEffect(() => {
		if (!prevIsAuthenticated && isAuthenticated) {
			displayConfigDispatch({
				type: DISPLAY_CONFIG_ACTIONS.SET_DISPLAY_CONFIG,
				payload: { apiSecurity, accountTier, permissionsMode, serverlessSecurity, usageStatus }
			})
		}
	}, [isAuthenticated, prevIsAuthenticated, displayConfigDispatch, apiSecurity, serverlessSecurity, accountTier, permissionsMode, usageStatus]);

	if (isLoading) {
		return <Route {...rest} render={Loader} />
	}

	if ([LOGIN_PATH, LOGIN_CALLBACK_PATH].includes(location.pathname)) {
		return null;
	}

	return isAuthenticated ? <Route {...rest} component={component} /> : <Redirect to={LOGIN_PATH} />;
}

const ConnectedNotification = () => {
	const {message, type} = useNotificationState();
	const dispatch = useNotificationDispatch()

	if (!message) {
		return null;
	}

	return <Notification message={message} type={type} onClose={() => removeNotification(dispatch)} />
}

const RoutesWrapper = () => {
	return (
		<React.Fragment>
			<Route path={LOGIN_CALLBACK_PATH} component={LoginCallback} />
			<Route path={LOGIN_PATH} exact={true} component={Login} />
			<PrivateRoute path="/" component={App} />
		</React.Fragment>
	)
}

const AuthRoot = () => {
	const {isGlobalAdmin} = useAuthState();

	return (
		<AdminProvider isGlobalAdmin={isGlobalAdmin}>
			<BrowserRouter>
				<AccountParamsProvider>
					<RoutesWrapper />
				</AccountParamsProvider>
			</BrowserRouter>
		</AdminProvider>
	)
}

const Root = () => (
	<NotificationProvider>
		<ConnectedNotification />
		<DisplayConfigProvider>
			<AuthProvider>
				<AuthRoot />
			</AuthProvider>
		</DisplayConfigProvider>
	</NotificationProvider>
)

export default Root;