import React, { useEffect } from 'react';
import { usePrevious } from 'hooks';
import { useFormikContext, SelectField, validators, AsyncSelectField, KeyValuesWithAllField } from 'components/Form';
import { valueToValueLabel } from 'utils/apiUtils';
import { VULNERABILITY_LEVELS, SYSTEM_RISKS, SECRET_FINDINGS_RISKS } from 'utils/systemConsts';

export const RULE_TYPE_ITEMS = {
    NAME: {value: "FunctionNameServerlessRuleType", label: "By name"},
    ARN: {value: "FunctionArnServerlessRuleType", label: "By ARN / Resource ID"},
    ANY: {value: "FunctionAnyServerlessRuleType", label: "Any"}
}

export const ALL_KEY = "ALL";

const FormFunctions = ({isReadOnlyUser, cloudAccounts}) => {
    const {values, setFieldValue, validateForm} = useFormikContext();
    const {serverlessRuleType} = values.rule || {};
    const prevServerlessRuleType = usePrevious(serverlessRuleType);

    useEffect(() => {
        if (!!prevServerlessRuleType && prevServerlessRuleType !== serverlessRuleType) {
            setFieldValue("rule.names", []);
            setFieldValue("rule.arns", []);
        }

        validateForm();
    }, [prevServerlessRuleType, serverlessRuleType, setFieldValue, validateForm]);
    
    return (
        <React.Fragment>
            <SelectField
                name="rule.serverlessRuleType"
                label="Select an option"
                items={Object.values(RULE_TYPE_ITEMS)}
                validate={validators.validateRequired}
                clearable={false}
                disabled={isReadOnlyUser}
            />
            {serverlessRuleType === RULE_TYPE_ITEMS.NAME.value &&
                <AsyncSelectField
                    isMulti
                    name="rule.names"
                    placeholder="Type to search"
                    url="serverless/functions/names"
                    formatData={data => {
                        const {names} = data || {};
                        
                        return valueToValueLabel(names || []);
                    }}
                    getQueryParams={inputValue => ({funcName: inputValue, offset: 0, maxResults: 50})}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />
            }
            {serverlessRuleType === RULE_TYPE_ITEMS.ARN.value &&
                <AsyncSelectField
                    isMulti
                    name="rule.arns"
                    placeholder="Type to search"
                    url="serverless/functions/arns"
                    formatData={data => {
                        const {arns} = data || {};
                        
                        return valueToValueLabel(arns || []);
                    }}
                    getQueryParams={inputValue => ({funcArn: inputValue, offset: 0, maxResults: 50})}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />
            }
            <KeyValuesWithAllField
                ALL_KEY={ALL_KEY}
                name="scope"
                label="Scope"
                keyName="cloudAccount"
                keyItems={cloudAccounts.map(({name}) => ({value: name, label: name}))}
                keyPlaceholder="Cloud account"
                allItemLabel="All"
                valuesName="regions"
                disabled={isReadOnlyUser}
                getValuesProps={({cloudAccount}) => ({
                    placeholder: "Regions",
                    items: (cloudAccount === ALL_KEY || cloudAccount === "") ? [] : [
                        {value: ALL_KEY, label: "All"},
                        ...valueToValueLabel(cloudAccounts.find(({name}) => name === cloudAccount).regions || [])
                    ]
                })}
            />
            <SelectField
                name="rule.serverlessFunctionValidation.risk"
                label="Total risk level"
                items={Object.values(VULNERABILITY_LEVELS)}
                disabled={isReadOnlyUser}
            />
            <SelectField
                name="rule.serverlessFunctionValidation.vulnerability"
                label="Vulnerability level"
                items={Object.values(VULNERABILITY_LEVELS)}
                disabled={isReadOnlyUser}
            />
            <SelectField
                name="rule.serverlessFunctionValidation.secretsRisk"
                label="Visible secrets risk"
                items={Object.values(SECRET_FINDINGS_RISKS)}
                disabled={isReadOnlyUser}
            />
            <SelectField
                name="rule.serverlessFunctionValidation.functionPermissionRisk"
                label="Function Permissions risk"
                items={[
                    SYSTEM_RISKS.CRITICAL,
                    SYSTEM_RISKS.HIGH,
                    SYSTEM_RISKS.MEDIUM,
                    SYSTEM_RISKS.LOW,
                    SYSTEM_RISKS.NO_RISK
                ]}
                disabled={isReadOnlyUser}
            />
            <SelectField
                name="rule.serverlessFunctionValidation.publiclyAccessibleRisk"
                label="Publicly accessible risk"
                items={[
                    SYSTEM_RISKS.MEDIUM,
                    SYSTEM_RISKS.LOW,
                    SYSTEM_RISKS.NO_RISK
                ]}
                disabled={isReadOnlyUser}
            />
            <SelectField
                name="rule.serverlessFunctionValidation.dataAccessRisk"
                label="Data access"
                items={[
                    {value: SYSTEM_RISKS.LOW.value, label: "Yes"},
                    {value: SYSTEM_RISKS.NO_RISK.value, label: "No"}
                ]}
                disabled={isReadOnlyUser}
                tooltipText="Serverless functions that access data sources"
            />
            <SelectField
                name="rule.serverlessFunctionValidation.isUnusedFunction"
                label="Unused function"
                items={[
                    {value: true, label: "Yes"},
                    {value: false, label: "No"}
                ]}
                disabled={isReadOnlyUser}
                tooltipText="Functions which were not in use for more than 30 days"
            />
        </React.Fragment>
    )
}

export default FormFunctions;