import React from 'react';
import Icon, { ICON_NAMES } from 'components/Icon';
import Loader from 'components/Loader';

export const EmptyDataIcon = ({title}) => (
    <div className="empty-scan-policy-icon-wrapper">
        <div className="empty-data-title">{title}</div>
        <Icon name={ICON_NAMES.EMPTY_DATA} />
    </div>
);

export const ScanPolicyLoader = () => (
    <div className="scan-policy-loader-wrapper">
        <Loader absolute={false} />
    </div>
)