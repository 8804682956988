import React from 'react';

const WORKLOADS_DISPLAY_SIZE = 3;
export const WorkloadsDisplay = ({workloadItems}) => {
    const workloadsCount = workloadItems.length;
    const hideWorkloads = workloadsCount > WORKLOADS_DISPLAY_SIZE;
    const workloadsItemsToDisplay = hideWorkloads ? workloadItems.slice(0, WORKLOADS_DISPLAY_SIZE - 1) : workloadItems;
    const hiddenCount = workloadsCount - workloadsItemsToDisplay.length;

    return (
        <div className="api-workloads-wrapper">
            {
                workloadsItemsToDisplay.map(({workloadName}, index) => <div key={index}>{workloadName}</div>)
            }
            {hideWorkloads && <div className="hidden-count">{`...${hiddenCount} more`}</div>}
        </div>
    )
}
