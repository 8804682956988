import React, { useEffect } from 'react';
import { isUndefined } from 'lodash';
import { OktaAuth } from '@okta/okta-auth-js';
import { LOGIN_REDIRECT_URI } from 'utils/systemConsts';

const initialState = {
    oktaAuth: null,
    iamLoginUrl: null,
    isDevLogin: false,
    upgradeUrl: null,
    isLoading: true,
    isLoadingError: false,
    latestAgentVersion: null,
    latestIstioVersion: null,
    minK8sVersion: null,
    minControllerAutoUpdateVersion: null
};

export const AUTH_ACTIONS = {
    AUTH_PARAMS_LOADED: "AUTH_PARAMS_LOADED",
    AUTH_PARAMS_LOADING_ERROR: "AUTH_PARAMS_LOADING_ERROR"
};

const reducer = (state, action) => {
    switch (action.type) {
        case AUTH_ACTIONS.AUTH_PARAMS_LOADED: {
            const {authServer, clientId, iamUrl, latestAgentVersion,
                latestIstioVersion, minK8sVersion, minControllerAutoUpdateVersion, upgradeUrl} = action.payload;

            const oktaAuth = new OktaAuth({
                clientId: clientId,
                scopes: ["openid", "email", "profile"],
                issuer: authServer,
                redirectUri: LOGIN_REDIRECT_URI
            });
            
            return {
                ...state,
                isLoading: false,
                oktaAuth,
                iamLoginUrl: `${iamUrl}/login`,
                isDevLogin: !iamUrl,
                latestAgentVersion,
                latestIstioVersion,
                minK8sVersion,
                minControllerAutoUpdateVersion,
                upgradeUrl
            };
        }
        case AUTH_ACTIONS.AUTH_PARAMS_LOADING_ERROR: {
            return {
                ...state,
                isLoading: false,
                isLoadingError: true
            };
        }
        default:
            return state;
    }
}

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const useAccountParamsState = () => {
    const context = React.useContext(StateContext);

    if (isUndefined(context)) {
        throw Error("useState is not within the AccountParamsProvider provider")
    }

    return context;
}

const AccountParamsProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
        isLoading: true
    });

    const loadUserData = () => fetch("/api/params", {})
        .then(response => {
            if (!response.ok) {
                throw Error("Loading error");
            }

            return response;
        })
        .then(response => response.status === 204 ? {} : response.json())
        .then(data => {
            dispatch({type: AUTH_ACTIONS.AUTH_PARAMS_LOADED, payload: data});
        }).catch(() => {
            dispatch({type: AUTH_ACTIONS.AUTH_PARAMS_LOADING_ERROR});
        });
    
    useEffect(() => {
        loadUserData();
    }, []);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

export {
    AccountParamsProvider,
    useAccountParamsState
};