import React, { useEffect } from 'react';
import { useAuthState } from 'context/AuthProvider';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { usePrevious } from 'hooks';
import FormWrapper, { validators, TextField, RadioField, useFormikContext } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';
import { getRuleValueByAction, DEFAULT_RULE_NAME } from './utils';

const FormFields = ({isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {action} = values;
    const prevAction= usePrevious(action);

    useEffect(() => {
        if (action === prevAction || !prevAction) {
            return;
        }

        setFieldValue("denyType", RULE_ACTIONS.DETECT);
    }, [prevAction, action, setFieldValue]);

    return (
        <React.Fragment>
            <TextField name="name" label="Rule Name" disabled />
            <RadioField
                name="action"
                label="Type"
                items={[
                    {value: RULE_ACTIONS.ALLOW, label: getRuleValueByAction(RULE_ACTIONS.ALLOW)},
                    {value: RULE_ACTIONS.DETECT, label: getRuleValueByAction(RULE_ACTIONS.DETECT)}
                ]}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {action === RULE_ACTIONS.DETECT &&
                <RadioField
                    name="denyType"
                    label="On Detection"
                    horizontal={true}
                    items={[
                        {value: RULE_ACTIONS.DETECT, label: "Detect", tooltip: "Allow but alert on the violation"},
                        {value: RULE_ACTIONS.BLOCK, label: "Block", tooltip: "Block the violation"}
                    ]}
                    disabled={isReadOnlyUser}
                />
            }
        </React.Fragment>
    );
}

const FormDefaultRule = ({initialData, title, onDone, onDirtyChanage}) => {
    const {isReadOnlyUser} = useAuthState();

    const initialValues = {
        ...initialData,
        name: DEFAULT_RULE_NAME,
        action: initialData.action === RULE_ACTIONS.BLOCK ? RULE_ACTIONS.DETECT : initialData.action,
        denyType: initialData.action,
        
    };

    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{title}</Text>
            <FormWrapper
                initialValues={initialValues}
                onDirtyChanage={onDirtyChanage}
                disableSubmitButton={isReadOnlyUser}
                doCustomSubmit={formValues => {
                    const {action, denyType} = formValues;

                    onDone({action: action === RULE_ACTIONS.ALLOW ? action : denyType});
                }}
            >
                <FormFields isReadOnlyUser={isReadOnlyUser} />
            </FormWrapper>
        </div>
    )
}

export default FormDefaultRule;