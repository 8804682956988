import React from 'react';
import { TextField, SelectField } from 'components/Form';
import { FilterFormWrapper } from 'components/Table';
import { PROTECTION_STATUSES } from './utils';

const PROTECTED_FILTER_ITEMS = {
    ALL: {value: "ALL",label: "All"},
    ...PROTECTION_STATUSES
}

const FilterForm = ({filters, setFilters}) => {
    const initialValues = {
        ...filters,
        namespaceName: "",
        clusterName: "",
        protectionStatus: PROTECTED_FILTER_ITEMS.ALL.value,
        filterLoading: true
    };
    
    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={filters => {
                setFilters({
                    ...filters,
                    filterLoading: false
                })
            }}
        >
            <TextField name="namespaceName" label="Namespace Name" placeholder="Search..." />
            <TextField name="clusterName" label="Cluster Name" placeholder="Search..." />
            <SelectField
                name="protectionStatus"
                label="Protected"
                items={Object.values(PROTECTED_FILTER_ITEMS)}
                clearable={false}
                small
            />
        </FilterFormWrapper>
    )
};

export default FilterForm;