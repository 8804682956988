export const CONNECTION_RULES_NAME = "Connection";
export const DEPLOYMENT_RULES_NAME = "Deployment";
export const CLUSTER_EVENTS_RULES_NAME = "Cluster Events";

export const getConnectionRuleItems = rules => (
    rules.map(({name, id, relatedEnvironments}) => ({
        name: `${CONNECTION_RULES_NAME} rule: ${name}`,
        searchParams: {selectedRuleId: id},
        textItems: relatedEnvironments
    }))
);

export const getDeploymentRuleItems = rules => (
    rules.map(({name, id, relatedEnvironments}) => ({
        name: `${DEPLOYMENT_RULES_NAME} rule: ${name}`,
        searchParams: {selectedRuleId: id},
        textItems: relatedEnvironments
    }))
);

export const getClusterEventsRuleItems = rules => (
    rules.map(({name, id, relatedEnvironments}) => ({
        name: `${CLUSTER_EVENTS_RULES_NAME} rule: ${name}`,
        searchParams: {selectedRuleId: id},
        textItems: relatedEnvironments
    }))
);