import React from 'react';
import { NotAvailable, PackageDisplayWrapper } from './utils';

const PackageDetails = ({dataId, data = {}}) => {
    const {packageName, packageVersion, fixVersion } = data;

    return (
        <div className="package-details">
            <div className="package-item">
                <div className="item-label">Package Name</div>
                <div className="item-value"><PackageDisplayWrapper itemId={`${dataId}-name`} text={packageName} /></div>
            </div>
            <div className="package-item">
                <div className="item-label">Image Package Version</div>
                <div className="item-value"><PackageDisplayWrapper itemId={`${dataId}-version`} text={packageVersion} width={80} /></div>
            </div>
            <div className="package-item">
                <div className="item-label">Fix Version</div>
                <div className="item-value">
                    {!fixVersion ? <NotAvailable/> : <PackageDisplayWrapper itemId={`${dataId}-fix`} text={fixVersion} />}
                </div>
            </div>
        </div>
    )
}

export default PackageDetails;