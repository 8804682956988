import React from 'react';
import { TextElement, InnerTitle, LinksList } from 'components/DeleteWizardModal';
import { DEPLOYMNET_EXPANSIONS_PAGE_PATH } from 'layout/Deployments/Expansions';

const ExpansionsStep = ({stepData: expansions}) => (
    <div className="delete-cluster-expansions">
        <React.Fragment>
            <InnerTitle>Expansions</InnerTitle>
            <TextElement>The following expansions will be deleted.</TextElement>
            <LinksList
                pathname={DEPLOYMNET_EXPANSIONS_PAGE_PATH}
                items={expansions.map(({name}) => ({name, searchParams: {name}}))}
            />
        </React.Fragment>
    </div>
);

export default ExpansionsStep;