import React from 'react'
import RoundIconContainer from 'components/RoundIconContainer'
import { TooltipWrapper } from 'components/Tooltip'
import COLORS from 'utils/scss_variables.module.scss';

import './custom-header-with-tooltip.scss'

export default function CustomHeaderWithTooltip({
    headerTitle,
    icon,
    placement,
    text,
    id
}) {
    return (
        <TooltipWrapper id={id} text={text} placement={placement}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '5px' }}>{headerTitle}</div>
                <RoundIconContainer className={'table-header-icon'} name={icon} iconStyle={{
                    color: COLORS["color-grey-dark"],
                    height: '12px',
                    width: '12px'
                }} />
            </div>
        </TooltipWrapper>
    )
}
