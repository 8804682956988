import React from 'react';
import Icon, { ICON_NAMES } from 'components/Icon';
import { DETECT_ACTION_ITEM, BLOCK_ACTION_ITEM, ALLOW_ACTION_ITEM, ENCRYPT_ACTION_ITEM, ENCRYPT_DIRECT_ACTION_ITEM } from 'utils/systemConsts';

import './rule-action-display.scss';

const ACTIONS = {
    [DETECT_ACTION_ITEM.value]: {...DETECT_ACTION_ITEM, icon: ICON_NAMES.ALERT, className: "detect"},
    [BLOCK_ACTION_ITEM.value]: {...BLOCK_ACTION_ITEM, icon: ICON_NAMES.RESTRICT, className: "block"},
    [ALLOW_ACTION_ITEM.value]: {...ALLOW_ACTION_ITEM, icon: ICON_NAMES.SUCCESS, className: "allow"},
    [ENCRYPT_ACTION_ITEM.value]: {...ENCRYPT_ACTION_ITEM, icon: ICON_NAMES.LOCK_ROUNDED, className: "encrypt"},
    [ENCRYPT_DIRECT_ACTION_ITEM.value]: {...ENCRYPT_DIRECT_ACTION_ITEM, icon: ICON_NAMES.LOCK_ROUNDED, className: "encrypt"},
}

const RuleActionDisplay = ({action, actionsMap=ACTIONS}) => {
    const {label, className, icon} = actionsMap[action] || {};
    
    if (!action) {
        return null;
    }

    return (
        <div className="rule-action-display-wrapper">
            <Icon name={icon} className={className} />
            <span>{label}</span>
        </div>
    )
};

export default RuleActionDisplay;