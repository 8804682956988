import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { FETCH_METHODS, usePrevious, useMountMultiFetch, useMultiFetch, useFetch } from 'hooks';
import FormWrapper, { validators, SelectField, useFormikContext, YesNoToggleField, utils, RadioField } from 'components/Form';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import Text, { TEXT_TYPES } from 'components/Text';
import { ICON_NAMES } from 'components/Icon';
import { CI_CD_POLICY_URL } from 'layout/Policies/CiCdScanPolicy';
import { nameIdToValueLabel } from 'utils/apiUtils';
import { DEPLOYER_TYPE_ITEMS, isOperatorDeployer } from './utils';

const OperatorFormFields = ({ cluseterItems }) => {
    const { values, setFieldValue } = useFormikContext();
    const { clusterId, namespaceId } = values;
    const prevClusterId = usePrevious(clusterId);
    const prevNamespaceId = usePrevious(namespaceId);

    const [{ loading: clusterDataLoading, data: clusterData }, fetchClusterData] = useMultiFetch();
    const loadClusterDependentData = clusterId => fetchClusterData([
        { key: "clusterNamespaces", url: "kubernetesClusters", formatUrl: url => `${url}/${clusterId}/namespaces` },
        { key: "clusterDeployers", url: "deployers/serviceAccounts", queryParams: { kubernetesClusterId: clusterId } }
    ]);
    const doLoadClusterDependentData = useCallback(loadClusterDependentData, [fetchClusterData]);

    const { clusterNamespaces = [], clusterDeployers } = clusterData || {};
    const selectedNamespace = clusterNamespaces.find(item => item.id === namespaceId);
    const selectedNamespaceName = !!selectedNamespace ? selectedNamespace.name : null;

    const [{ loading: namespaceDeployerLoading, data: namespaceDeployerNames }, loadNamespacesDeployers] = useFetch("deployers/serviceAccounts", { loadOnMount: false });
    const loadNamespacesDeployersData = ({ clusterId, namespaceName }) => {
        const queryParams = { kubernetesClusterId: clusterId };
        if (!!namespaceName) {
            queryParams.namespaceName = namespaceName;
        }

        loadNamespacesDeployers({
            key: "clusterDeployers",
            url: "deployers/serviceAccounts",
            queryParams
        })
    };
    const doLoadNamespacesDeployersData = useCallback(loadNamespacesDeployersData, [loadNamespacesDeployers]);

    useEffect(() => {
        if (!!clusterId && clusterId !== prevClusterId) {
            doLoadClusterDependentData(clusterId);
        }

        if (!isUndefined(prevClusterId) && clusterId !== prevClusterId) {
            setFieldValue("namespaceId", "");
            setFieldValue("deployerId", "");
        }
    }, [clusterId, prevClusterId, doLoadClusterDependentData, setFieldValue]);

    useEffect(() => {
        if (!!clusterId && namespaceId !== prevNamespaceId) {
            doLoadNamespacesDeployersData({ clusterId, namespaceName: selectedNamespaceName });
        }

        if (!isUndefined(prevNamespaceId) && namespaceId !== prevNamespaceId) {
            setFieldValue("deployerId", "");
        }
    }, [clusterId, namespaceId, prevNamespaceId, selectedNamespaceName, doLoadNamespacesDeployersData, setFieldValue]);

    const deployerNames = !!namespaceId ? namespaceDeployerNames : clusterDeployers;
    const deployerItemsData = nameIdToValueLabel(deployerNames || []);

    return (
        <React.Fragment>
            <SelectField
                name="clusterId"
                label="Cluster"
                items={cluseterItems}
                validate={validators.validateRequired}
                clearable={false}
            />
            <SelectField
                name="namespaceId"
                label="Namespace"
                items={nameIdToValueLabel(clusterNamespaces || [])}
                disabled={!clusterId}
                loading={clusterDataLoading}
            />
            <SelectField
                name="deployerId"
                label="Deployer"
                items={deployerItemsData || []}
                clearable={false}
                validate={validators.validateRequired}
                disabled={!clusterId}
                loading={clusterDataLoading || namespaceDeployerLoading}
            />
            <YesNoToggleField
                name="securityCheck"
                label="Security checks on deployment"
            />
        </React.Fragment>
    )
}

const SecureCnFormFields = ({ serviceUsersItems }) => {
    const { values } = useFormikContext();
    const { deployerType } = values;

    return (
        <SelectField
            name="deployerId"
            label={deployerType === DEPLOYER_TYPE_ITEMS.SecureCnDeployer.value ? "Service account" : "Deployer"}
            items={serviceUsersItems}
            clearable={false}
            validate={validators.validateRequired}
        />
    );
}

const FormFields = ({ cluseterItems, serviceUsersItems }) => {
    const { values, setFieldValue } = useFormikContext();

    const { deployerType } = values;
    const prevDeployerType = usePrevious(deployerType);

    useEffect(() => {
        if (!!prevDeployerType && prevDeployerType !== deployerType) {
            setFieldValue("deployerId", "");

            if (isOperatorDeployer(prevDeployerType)) {
                setFieldValue("clusterId", "");
                setFieldValue("namespaceId", "");
                setFieldValue("securityCheck", false);
                setFieldValue("ruleCreation", false);
            }
        }
    }, [prevDeployerType, deployerType, setFieldValue]);

    return (
        <React.Fragment>
            <RadioField
                name="deployerType"
                label="Deployer Type"
                items={Object.values(DEPLOYER_TYPE_ITEMS)}
                clearable={false}
                validate={validators.validateRequired}
            />
            {isOperatorDeployer(deployerType) && <OperatorFormFields cluseterItems={cluseterItems} />}
            {deployerType === DEPLOYER_TYPE_ITEMS.SecureCnDeployer.value && <SecureCnFormFields serviceUsersItems={serviceUsersItems} />}
            <utils.FormNotificationMessage>
                All future workloads deployed by this deployer will be automatically identified in our system
            </utils.FormNotificationMessage>
        </React.Fragment>
    )
}

const DeployerForm = ({ initialData, onFormSubmitSuccess, onDirtyChanage }) => {
    const [{ loading, data, error }] = useMountMultiFetch([
        { key: "users", url: "users?noPagination=true" },
        { key: "clusters", url: "leanKubernetesClusters" },
    ]);

    const history = useHistory();

    const [deploymentScanButtonClicked, setDeploymentScanButtonClicked] = useState(false);

    if (loading) {
        return <Loader absolute={false} />;
    }

    if (error) {
        return null;
    }

    const { clusters, users } = data || {};
    const serviceUsersItems = users.filter(item => item.role === "SERVICE").map(({ id, fullName }) => ({ value: id, label: fullName }));

    const initialValues = {
        deployerId: "",
        deployerType: DEPLOYER_TYPE_ITEMS.OperatorDeployer.value,
        clusterId: "",
        namespaceId: "",
        securityCheck: false,
        ruleCreation: false,
        ...initialData
    };

    const isEditForm = initialValues && initialValues.id;
    const deploymentScanButtonTooltipId = "deployment-scan-button-tooltip-id";

    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{`${isEditForm ? "Edit" : "New"} Deployer`}</Text>
            <FormWrapper
                className="deployers-form"
                initialValues={initialValues}
                submitUrl="deployers"
                validate={values => {
                    const errors = {};

                    if (!values.deployerId) {
                        errors.deployerId = validators.ERROR_MSG_IS_REQUIRED;
                    }

                    return errors;
                }}
                getSubmitParams={formValues => {
                    const { id, ...submitData } = formValues;

                    delete submitData.cluster;
                    delete submitData.namespace;

                    if (!isOperatorDeployer(submitData.deployerType)) {
                        delete submitData.clusterId;
                        delete submitData.namespaceId;
                        delete submitData.ruleCreation;
                        delete submitData.securityCheck;
                    }
                    return !isEditForm ? { submitData } : {
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={({ id }) => {
                    if (deploymentScanButtonClicked) {
                        history.push({
                            pathname: CI_CD_POLICY_URL,
                            query: { deploymentPolicyData: { deployers: [id] } }
                        });
                    }

                    onFormSubmitSuccess();
                }}
                onSubmitError={() => setDeploymentScanButtonClicked(false)}
                onDirtyChanage={onDirtyChanage}
                customSubmitButton={({ onClick, disabled }) => (
                    <React.Fragment>
                        <div className="deployment-scan-button-wrapper" data-tip data-for={deploymentScanButtonTooltipId}>
                            <Button
                                secondary
                                onClick={() => {
                                    setDeploymentScanButtonClicked(true);
                                    onClick();
                                }}
                                disabled={disabled}
                                icon={ICON_NAMES.POLICY}
                            >
                                Deployment Scan
                            </Button>
                        </div>
                        <Tooltip id={deploymentScanButtonTooltipId} text={<span>Rule defining the usage of<br />deployers in workloads.</span>} placement="top" />
                    </React.Fragment>
                )}
            >
                <FormFields
                    cluseterItems={nameIdToValueLabel(clusters, item => !item.kubernetesSecurity)}
                    serviceUsersItems={serviceUsersItems}
                />
            </FormWrapper>
        </div>
    )
}

export default DeployerForm;