import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RUNTIME_CONNECTIONS_PAGE_PATH } from '../utils';
import ConnectionsTable from './ConnectionsTable';
import ConnectionDetails from './ConnectionDetails';
import { FilterContext, RUNTIME_CONNECTION_FILTER_KEYS, LOGICAL_OPERATOR_ITEMS } from './Filter';

export {
    RUNTIME_CONNECTION_FILTER_KEYS,
    LOGICAL_OPERATOR_ITEMS
}

const Connections = () => (
    <FilterContext.FilterProvider>
        <Switch>
            <Route path={`${RUNTIME_CONNECTIONS_PAGE_PATH}/:telemetryId`} component={ConnectionDetails} />
            <Route path={RUNTIME_CONNECTIONS_PAGE_PATH} component={ConnectionsTable} />
        </Switch>
    </FilterContext.FilterProvider>
)

export default Connections;