import React, { useState, useEffect } from 'react';
import { isEmpty, keyBy, isEqual } from 'lodash';
import classnames from 'classnames';
import { useFetch, usePrevious } from 'hooks';
import ModalButton from 'components/ModalButton';
import Loader from 'components/Loader';
import AdvisorScanIndicator, { ADVISOR_TYEPS } from './AdvisorScanIndicator';
import AdvisorContent, { TIME_RANGES, EmptyNotification } from './AdvisorContent';
import ContentItemDisplay from './SuggestionItem/ContentItemDisplay';

import './advisor.scss';

export {
    ContentItemDisplay,
    ADVISOR_TYEPS
}

const ExpandedContent = (props) => {
    const {data, emptyMessage} = props;

    if (isEmpty(data)) {
        return <EmptyNotification message={emptyMessage} />;
    }

    return <AdvisorContent {...props} items={data || []} />;
};

const ExpandedContentWrapper = ({refreshData, ...props}) => (
    <div className="advisor-extended-content-wrapper">
        <div className="advisor-run-container">
            <AdvisorScanIndicator advisorType={props.advisorType} refreshData={refreshData} />
        </div>
        <ExpandedContent {...props} />
    </div>
)

const Advisor = (props) => {
    const {url, formatData, buttonTitle, className, initialExpanded: advisorExpanded, onItemsAdd} = props;

    const [initialExpanded, setInitialExpanded] = useState(advisorExpanded || false)

    const [appliedRecommendations, setAppliedRecommendations] = useState([]);
    const prevAppliedRecommendations = usePrevious(appliedRecommendations);

    const [{loading, data}, refreshData] = useFetch(url);

    useEffect(() => {
        if (!!prevAppliedRecommendations && !isEqual(prevAppliedRecommendations, appliedRecommendations)) {
            const addedRecommendations = appliedRecommendations.filter(item => {
                const prevItem = prevAppliedRecommendations.find(appliedItem => appliedItem.id === item.id);

                return !prevItem;
            });
            
            onItemsAdd(addedRecommendations);
        }
    }, [appliedRecommendations, prevAppliedRecommendations, onItemsAdd]);

    if (loading && !initialExpanded) {
        return null;
    }

    const onRecommendationsApply = itemsData => {
        const formattedItemsData = itemsData.map(itemData => {
            const {critical, icon, ...data} = itemData;
            
            return data;
        });

        setAppliedRecommendations([...appliedRecommendations, ...formattedItemsData]);
    }

    const advisorData = formatData(data || []);
    const filteredRecommendations = advisorData.map(({recommendations, ...props}) => ({
        ...props,
        recommendations: recommendations.filter(item => {
            const appliedItem = appliedRecommendations.find(appliedItem => appliedItem.id === item.id);
            
            return !appliedItem;
        })
    }));
    const formattedData = keyBy(filteredRecommendations, "timePeriod");
    const dayRecommendations = isEmpty(formattedData) ? null :
        formattedData[TIME_RANGES.LAST_DAY.value].recommendations;
    const count = isEmpty(dayRecommendations) ? null : dayRecommendations.length;

    return (
        <ModalButton
            className={classnames("advisor-modal", {[className]: !!className})}
            buttonName="star"
            buttonTitle={buttonTitle}
            expandedContent={() => {
                if (loading) {
                    return <Loader />
                }

                return (
                    <ExpandedContentWrapper
                        {...props}
                        data={formattedData}
                        onRecommendationsApply={onRecommendationsApply}
                        refreshData={() => {
                            setInitialExpanded(true);
                            refreshData();
                        }}
                    />
                )
            }}
            notificationText={count}
            initialExpanded={initialExpanded}
        />
    );
}

export default Advisor;