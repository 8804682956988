import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import { isUndefined } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import Icon from 'components/Icon';
import InfoIcon from 'components/InfoIcon';
import { TooltipWrapper, TOOLTIP_PLACEMENTS } from 'components/Tooltip';

import './dropdown-select.scss';

const DropdownSelectBese = (props) => {
    const {selectComponent: SelectComponent=Select, name="name", className, small=false, clearable=false, placeholder="Select...", disabled,
        selectedValueTooltipId, selectedValueTooltipPlacement=TOOLTIP_PLACEMENTS.TOP, ...selectProps} = props;

    const [isOpen, setIsOpen] = useState(false);

    const selectHeight = small ? 20 : 36;
    const fontSize = small ? "10px" : "14px";
    const lineHeight = small ? "10px" : "18px";

    return (
        <SelectComponent
            {...selectProps}
            className={classnames("ps-single-select", className, {open: isOpen}, {small})}
            classNamePrefix="single-select"
            name={name}
            isClearable={clearable}
            components={{
                Option: ({ children, innerProps, data, isDisabled }) => {
                    const { icon: iconName, iconClass, tooltipText } = data;

                    return (
                        <div className={classnames("single-select-option", { disabled: isDisabled })} {...innerProps}>
                            {!!iconName && <Icon name={iconName} className={iconClass} />}
                            <div className="single-select-option-text">{children}</div>
                            {
                                !!tooltipText && <TooltipWrapper id={tooltipText} text={tooltipText} placement={TOOLTIP_PLACEMENTS.TOP}>
                                    <InfoIcon />
                                </TooltipWrapper>
                            }
                        </div>
                    )
                },
                SingleValue: ({ data, children, isDisabled }) => {
                    const { icon: iconName, iconClass, label } = data;

                    const SelectedDisplay = () => (
                        <div style={{ display: "flex" }}>
                            {!!iconName && <Icon name={iconName} className={iconClass} />}
                            <div className="value-content">{children}</div>
                        </div>
                    )

                    const SelectedDisplayWrapper = ({ children }) => (
                        <div className={classnames("single-select-value", { disabled: isDisabled })}>
                            {children}
                        </div>
                    )

                    if (!!selectedValueTooltipId && !!label) {
                        return (
                            <SelectedDisplayWrapper>
                                <TooltipWrapper id={selectedValueTooltipId} text={label} placement={selectedValueTooltipPlacement}>
                                    <SelectedDisplay />
                                </TooltipWrapper>
                            </SelectedDisplayWrapper>
                        )
                    }

                    return (
                        <SelectedDisplayWrapper><SelectedDisplay /></SelectedDisplayWrapper>
                    )
                }
            }}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            isDisabled={disabled}
            placeholder={placeholder}
            styles={{
                control: (provided) => ({
                    ...provided,
                    minHeight: selectHeight,
                    borderRadius: 2,
                    boxShadow: "none",
                    backgroundColor: "inherit",
                    cursor: "pointer",
                    fontSize: fontSize,
                    lineHeight: lineHeight
                }),
                menu: (provided) => ({
                    ...provided,
                    borderRadius: 2,
                    fontSize: fontSize,
                    lineHeight: lineHeight
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    height: selectHeight
                }),
                indicatorSeparator: () => ({
                    display: "none"
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    height: selectHeight
                })
            }}
        />
    )
}

const DropdownSelect = (props) => {
    const {defaultselected, onChange, clearable, creatable, items=[], name, className, disabled, placeholder, small,
        selectedValueTooltipId, selectedValueTooltipPlacement} = props;
    
    const [value, setValue] = useState(defaultselected);

    useEffect(() => {
        if (!isUndefined(defaultselected) && defaultselected !== value) {
            setValue(defaultselected);
        }
    }, [defaultselected, value]);

    const selectedItem = items.find(item => item.value === value) || null;
    const SelectComponent = creatable ? CreatableSelect : Select;

    return (
        <DropdownSelectBese
            selectComponent={SelectComponent}
            name={name}
            className={className}
            small={small}
            clearable={clearable}
            placeholder={placeholder}
            disabled={disabled}
            selectedValueTooltipId={selectedValueTooltipId}
            selectedValueTooltipPlacement={selectedValueTooltipPlacement}
            //additional select props:
            value={selectedItem}
            options={items}
            onChange={(selected) => onChange(selected ? selected.value : "")}
        />
    )
}

export default DropdownSelect;

export {
    DropdownSelectBese
}