import React from 'react';
import { TextField, AsyncSelectField } from 'components/Form';
import { FilterFormWrapper } from 'components/Table';
import { valueToValueLabel } from 'utils/apiUtils';

import './form.scss';

export const FILTER_KEYS = {
    IMAGE_NAME: "IMAGE_NAME",
    IMAGE_TAG: "IMAGE_TAG",
    IMAGE_HASH: "IMAGE_HASH",
    IMAGE_CVE: "vulnerabilityName"
}

export const initialData = {
    [FILTER_KEYS.IMAGE_NAME]: "",
    [FILTER_KEYS.IMAGE_TAG]: "",
    [FILTER_KEYS.IMAGE_HASH]: "",
    [FILTER_KEYS.IMAGE_CVE]: ""
}

const FilterForm = ({filters, queryFilters={}, setFilters}) => {
    const initialValues = {
       ...initialData,
       ...filters,
       ...queryFilters,
       filterLoading: true
    };

    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={filters => {
                setFilters({
                    ...filters,
                    [FILTER_KEYS.IMAGE_CVE]: filters[FILTER_KEYS.IMAGE_CVE]?.value,
                    [FILTER_KEYS.IMAGE_HASH]: filters[FILTER_KEYS.IMAGE_HASH]?.value,
                    filterLoading: false
                })
            }}
            className="images-filters-form"
        >
            <TextField name={FILTER_KEYS.IMAGE_NAME} label="Image name" />
            <TextField name={FILTER_KEYS.IMAGE_TAG} label="Image tag" />
            <AsyncSelectField
                className="image-hash-select"
                name={FILTER_KEYS.IMAGE_HASH}
                label="Image ID"
                placeholder="Type to search"
                url="images/imagesHash"
                formatData={data => valueToValueLabel(data)}
                getQueryParams={inputValue => ({imageHash: inputValue})}
                clearable
                small
            />
            <AsyncSelectField
                name={FILTER_KEYS.IMAGE_CVE}
                label="CVE ID"
                placeholder="Type to search"
                url="vulnerabilities"
                formatData={data => valueToValueLabel(data)}
                getQueryParams={inputValue => ({vulName: inputValue})}
                clearable
                small
            />
        </FilterFormWrapper>
    )
};

export default FilterForm;