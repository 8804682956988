import React from 'react';
import { useDisplayConfigState, useDisplayConfigDispatch, DISPLAY_CONFIG_ACTIONS } from 'context/DisplayConfigProvider';
import ToggleButton from 'components/ToggleButton';

const SystemOwnersToggle = ({isDark}) => {
    const displayConfigDispatch = useDisplayConfigDispatch();
    const {permissionsIncludeSystemOwners} = useDisplayConfigState();

    return (
        <ToggleButton
            className="permissions-system-owners-toggle"
            title="Show system roles"
            checked={permissionsIncludeSystemOwners}
            onChange={() => displayConfigDispatch({type: DISPLAY_CONFIG_ACTIONS.TOGGLE_PERMISSIONS_INCLUDE_SYSTEM_OWNERS})}
            width={140}
            small
            isDark={isDark}
        />
    );
}

export default SystemOwnersToggle;