import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import classNames from 'classnames';
import { isNull } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { useAdminState } from 'context/AdminProvider';
import { FETCH_METHODS, useMountMultiFetch, useMultiFetch, usePrevious } from 'hooks';
import ContainerTitle from 'components/ContainerTitle';
import Loader from 'components/Loader';
import Button from 'components/Button';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import FormWrapper, { useFormikContext } from 'components/Form';
import truncateOldWithSettingsConnection from './truncateOldWithSettingsConnection';
import AgentDuration from './AgentDuration';

import './settings.scss';

const TruncateOldWorkloads = truncateOldWithSettingsConnection({
    truncationName: "workloadsTruncation",
    title: "pod templates"
});

const TruncateOldImages= truncateOldWithSettingsConnection({
    truncationName: "imagesTruncation",
    title: "images"
});

const SettingsFormFields = ({items, onSave}) => {
    const {values, isValid, dirty} = useFormikContext();
    const saveDisabled = !dirty || !isValid;

    return (
        <React.Fragment>
            <div className="settings-wrapper">
                {
                    items.map(({id, title, componenets}) => (
                        <React.Fragment key={id}>
                            <ContainerTitle>{title}</ContainerTitle>
                            <React.Fragment>
                            {
                                componenets.map((ItemComponenet, index) => (
                                    <div key={index} className="setting-container">
                                        <div className="setting-content-container">
                                            <ItemComponenet />
                                        </div>
                                    </div>
                                ))
                            }
                            </React.Fragment>
                        </React.Fragment>
                    ))
                }
                <Button className={classNames("save-settings-btn", {"error": !isValid})} onClick={() => onSave(values)} disabled={saveDisabled}>
                    <IconWithTitle name={ICON_NAMES.SUCCESS} title="Save settings" disabled={saveDisabled} />
                </Button>
            </div>
            <Prompt
                when={dirty}
                message="You made changes to the settings. If you will exit without applying these changes, you will lose them."
            />
        </React.Fragment>
    )
}

const Settings = ({isGlobalAdmin, assignedAccount}) => {
    const loadUrlsData = [
        {key: "workloadsTruncation", url: "truncation/workloads", method: FETCH_METHODS.POST, getData: data => data.workloadsTruncation},
        {key: "imagesTruncation", url: "truncation/images", method: FETCH_METHODS.POST, getData: data => data.imagesTruncation},
        {key: "agentsUpdate", url: "settings/agentsUpdate", method: FETCH_METHODS.PUT, getData: data => ({isManualUpdate: data.agentsUpdate.isManualUpdate})},
    ];
    if (isGlobalAdmin) {
        loadUrlsData.push({key: "globalSettings", url: `admin/accounts/${assignedAccount}/settings`, method: FETCH_METHODS.PUT, getData: data => data.globalSettings});
    }

    const [{loading, data, error}, fetchSettingsData] = useMountMultiFetch(loadUrlsData.map(({key, url}) => ({key, url})));

    const [{loading: submitLoading, error: submitError}, submitSettingsData] = useMultiFetch();
    const prevSubmitLoading = usePrevious(submitLoading);

    useEffect(() => {
        if (prevSubmitLoading && !submitLoading && isNull(submitError)) {
            fetchSettingsData();
        }
    }, [prevSubmitLoading, submitLoading, submitError, fetchSettingsData]);

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const initialValues = {
        ...data
    }
    
    return (
        <FormWrapper
            className="settings-form"
            hideSubmitButton={true}
            initialValues={initialValues}
        >
            {submitLoading ? <Loader /> :
                <SettingsFormFields
                    items={[
                        {id: 1, title: "Remove unused items", componenets: [TruncateOldWorkloads, TruncateOldImages]},
                        ...(isGlobalAdmin ? [{id: 2, title: "K8S controller", componenets: [AgentDuration]}] : [])
                    ]}
                    onSave={(formValues) => submitSettingsData(loadUrlsData.map(({getData, ...item}) => ({...item, data: getData(formValues)})))}
                />
            }
        </FormWrapper>
    )
}

const SettingsWrapper = props => {
    const {assignedAccountId} = useAdminState();
    const {isGlobalAdmin} = useAuthState();

    if (isGlobalAdmin && !assignedAccountId) {
        return <Loader />;
    }

    return (
        <Settings {...props} isGlobalAdmin={isGlobalAdmin} assignedAccount={assignedAccountId} />
    )
}

export default SettingsWrapper;