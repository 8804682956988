import React from 'react';
import Button from 'components/Button';

import './no-policies-item.scss';

const NoUserRulesItem = ({onClick, disabled}) => (
    <div className="no-policies-wrapper">
        <div className="no-policies-item">
            <div className="main-title">No Rules Defined</div>
            <Button onClick={onClick} disabled={disabled}>New rule</Button>
        </div>
    </div>
);


export default NoUserRulesItem;