import React, { useEffect } from 'react';
import { get } from 'lodash';
import { CONNECTION_ENDPOINT_TYPES } from 'utils/systemConsts';
import { usePrevious } from 'hooks';
import { SelectField, validators, useFormikContext } from 'components/Form';
import { EnvironmentForm, KubernetesServiceForm } from '../RuleSubForms';
import { ENDPOINT_TYPE_ITEMS, KUBERNETES_SERVICE_TYPE } from '../utils'
import { MASTER_TYPE_FIELD_NAME } from './utils';

const ENDPOINT = CONNECTION_ENDPOINT_TYPES.DESTINATION;

export const EMPTY_DESTINATION_DATA = {
    environments: [],
    risks: [],
    clusterId: "",
    services: []
}

const FormDestination = (props) => {
    const {isReadOnlyUser} = props;
    const {values, setFieldValue} = useFormikContext();
    
    const masterType = get(values, `${ENDPOINT}.${MASTER_TYPE_FIELD_NAME}`);
    const prevMasterType = usePrevious(masterType);

    useEffect(() => {
        if (masterType === prevMasterType || !prevMasterType) {
            return;
        }

        setFieldValue(`${ENDPOINT}.connectionRulePartType`, "");
        
        Object.keys(EMPTY_DESTINATION_DATA).forEach(key => {
            const emptyValue = EMPTY_DESTINATION_DATA[key];

            setFieldValue(`${ENDPOINT}.${key}`, emptyValue);
        });

    }, [masterType, prevMasterType, setFieldValue]);

    return (
        <React.Fragment>
            <SelectField 
                name={`${ENDPOINT}.${MASTER_TYPE_FIELD_NAME}`}
                label="Select an option to identify the destination"
                items={[
                    ENDPOINT_TYPE_ITEMS.ENVIRONMENT,
                    KUBERNETES_SERVICE_TYPE
                ]}
                validate={validators.validateRequired}
                clearable={false}
                disabled={isReadOnlyUser}
            />
            {masterType === ENDPOINT_TYPE_ITEMS.ENVIRONMENT.value && <EnvironmentForm {...props} endpoint={ENDPOINT} />}
            {masterType === KUBERNETES_SERVICE_TYPE.value && <KubernetesServiceForm {...props} endpoint={ENDPOINT} />}
        </React.Fragment>
    );
}

export default FormDestination;