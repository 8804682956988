import React, { useEffect } from 'react';
import { useAdminState } from 'context/AdminProvider';
import { usePrevious } from 'hooks';
import FormWrapper, { TextAreaField, useFormikContext } from 'components/Form';
import Loader from 'components/Loader';

const FormFields = () => {
    const {setFieldValue, isSubmitting} = useFormikContext();
    const prevIsSubmitting = usePrevious(isSubmitting);

    useEffect(() => {
        if (prevIsSubmitting && !isSubmitting) {
            setFieldValue("command", "");
        }
    }, [prevIsSubmitting, isSubmitting, setFieldValue]);


    return (
        <div className="form-fields-wrapper">
            {isSubmitting ? <Loader /> : <TextAreaField name="command" placeholder="Enter..." />}
        </div>
    )
}

const AgentCommandForm = ({agentId, onSubmitSuccess}) => {
    const {assignedAccountId} = useAdminState();

    const initialValues = {
        accountId: assignedAccountId,
        command: ""
    };

    return (
        <div>
            <FormWrapper
                className="agent-command-form"
                initialValues={initialValues}
                submitUrl={`admin/agents/runCommand/${agentId}`}
                saveButtonTitle="Run"
                getSubmitParams={formValues => ({submitData: formValues})}
                onSubmitSuccess={() => {
                    onSubmitSuccess();
                }}
                withLoader={false}
            >
                <FormFields test="test" />
            </FormWrapper>
        </div>
    )
}

export default AgentCommandForm;