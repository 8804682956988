import React from 'react';
import Icon from 'components/Icon';
import { PROVIDER_ITEMS } from 'layout/Serverless/CloudAccount';

import './function-name-display.scss';

const FunctionNameDisplay = ({functionName, withIcon=false, provider}) => (
    <div className="function-cell-wrapper">
        {withIcon && <Icon name={PROVIDER_ITEMS[provider].icon} className="function-icon" />}
        <div>{functionName}</div>
    </div>
)

export default FunctionNameDisplay;