import { API_TREND_ITEMS, SYSTEM_RISKS } from 'utils/systemConsts';
import { formatDate, hexToRgba } from 'utils/generalUtils';
import { ICON_NAMES } from 'components/Icon';
import { SYSTEM_RISKS_WITH_ACCESSORS } from './Dashboard/utils';

import COLORS from 'utils/scss_variables.module.scss';

export const MAX_DURATION_IN_DAYS = 7;
export const MAX_DURATION_IN_HOURS = MAX_DURATION_IN_DAYS * 24;
export const MAX_DURATION_IN_MINUTES = MAX_DURATION_IN_HOURS * 60;

export const DURATION_INTERVALS = {
    DAY: { value: "DAY", label: "Day", momentFormat: "days", maxDuration: MAX_DURATION_IN_DAYS },
    HOUR: { value: "HOUR", label: "Hour", momentFormat: "hours", maxDuration: MAX_DURATION_IN_HOURS },
    MINUTE: { value: "MINUTE", label: "Minute", momentFormat: "minutes", maxDuration: MAX_DURATION_IN_MINUTES }
}

export const API_TYPE_ITEMS = {
    INTERNAL: { value: "INTERNAL", label: "Internal" },
    EXTERNAL: { value: "EXTERNAL", label: "External" }
};

export const DASHBOARD_SOURCE_FILTER = {
  INTERNAL: {
    value: "INTERNAL",
    label: "Internal APIs",
  },
  EXTERNAL: {
    value: "EXTERNAL",
    label: "Third-party APIs",
  },
  ALL: {
    value: "",
    label: "Internal & Third-party APIs",
  },
};

export const colorSchema = {
    [SYSTEM_RISKS.CRITICAL.value]: COLORS['color-risk-critical'],
    [SYSTEM_RISKS.HIGH.value]: COLORS['color-risk-high'],
    [SYSTEM_RISKS.MEDIUM.value]: COLORS['color-risk-medium'],
    [SYSTEM_RISKS.LOW.value]: COLORS['color-risk-low'],
    [SYSTEM_RISKS.UNKNOWN.value]: COLORS['color-grey'],
    [SYSTEM_RISKS.NO_RISK.value]: COLORS['color-risk-no-known'],
};

export function getTrendIconName(trend) {
    switch (trend) {
        case API_TREND_ITEMS.IMPROVING.value:
            return ICON_NAMES.TREND_ARROW_UP;
        case API_TREND_ITEMS.DEGRADING.value:
            return ICON_NAMES.TREND_ARROW_DOWN;
        case API_TREND_ITEMS.STABLE.value:
            return ICON_NAMES.TREND_ARROW_STABLE;
        default:
            return null;
    }
}

export const buildAreaChartData = (countersHistory) => {
    const chartAreaData = [];

    if (countersHistory) {
        countersHistory.timestamp.forEach((value, index) => {
            const chartAreaDataFragment = {
                date: value,
            };

            if (countersHistory.critical && countersHistory.critical[index]) chartAreaDataFragment[SYSTEM_RISKS.CRITICAL.value] = countersHistory.critical[index]
            if (countersHistory.high && countersHistory.high[index]) chartAreaDataFragment[SYSTEM_RISKS.HIGH.value] = countersHistory.high[index]
            if (countersHistory.medium && countersHistory.medium[index]) chartAreaDataFragment[SYSTEM_RISKS.MEDIUM.value] = countersHistory.medium[index]
            if (countersHistory.low && countersHistory.low[index]) chartAreaDataFragment[SYSTEM_RISKS.LOW.value] = countersHistory.low[index]
            if (countersHistory.unclassified && countersHistory.unclassified[index]) chartAreaDataFragment[SYSTEM_RISKS.NO_RISK.value] = countersHistory.unclassified[index]

            chartAreaData.push(chartAreaDataFragment);
        });
    }

    return chartAreaData;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const buildRiskCategoriesChartData = (categories) => {
    return Object.values(categories || {}).map((category) => {

        const riskCategoriesChartDataFragment = {
            name: capitalizeFirstLetter(CATEGORIES[category.name].label),
        }

        if (category.critical && category.critical.count) riskCategoriesChartDataFragment[SYSTEM_RISKS.CRITICAL.value] = category.critical.count
        if (category.high && category.high.count) riskCategoriesChartDataFragment[SYSTEM_RISKS.HIGH.value] = category.high.count
        if (category.medium && category.medium.count) riskCategoriesChartDataFragment[SYSTEM_RISKS.MEDIUM.value] = category.medium.count
        if (category.low && category.low.count) riskCategoriesChartDataFragment[SYSTEM_RISKS.LOW.value] = category.low.count
        if (category.unclassified && category.unclassified.count) riskCategoriesChartDataFragment[SYSTEM_RISKS.NO_RISK.value] = category.unclassified.count

        return riskCategoriesChartDataFragment
    })
};

export const calculateMaxDomain = (areaChartData) => {
    let maxDomain = 10;
    let maxCounts = 0;

    areaChartData?.forEach((dataFragment) => {
        const total = dataFragment[SYSTEM_RISKS.CRITICAL.value] || 0 +
            dataFragment[SYSTEM_RISKS.HIGH.value] || 0 +
            dataFragment[SYSTEM_RISKS.MEDIUM.value] || 0 +
            dataFragment[SYSTEM_RISKS.LOW.value] || 0 +
            dataFragment[SYSTEM_RISKS.UNKNOWN.value] || 0;

        if (maxCounts < total) {
            maxCounts = total;
        }
    })

    // If there are more counts then the maxDomain, we take the number and create a maxdomain from the number length
    if (maxCounts > maxDomain) {
        // Deserves a better name but I couldn't find it
        const powerOfTenMaxCounts = Math.pow(10, maxCounts.toString().length - 1)
        maxDomain = Math.ceil(
            maxCounts * 1.3 /
            powerOfTenMaxCounts
        ) *
            powerOfTenMaxCounts;
    }

    return maxDomain;
}

export const customChartLegend = ({ payload }) => {

    return (
        <div style={{
            display: 'flex',
            fontWeight: 'bold',
            fontSize: '12px',
        }}>
            {
                payload.map((entry, index) => (
                    <div style={{
                        display: 'flex',
                    }} key={`item-${index}`}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginInline: '0.8rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'clip',
                            }}
                        >
                            <div
                                style={{
                                    marginInline: '0.4rem',
                                    borderRadius: '50%',
                                    backgroundColor: entry.color,
                                    height: '0.6rem',
                                    width: '0.6rem'
                                }}
                            />
                            <span>
                                {SYSTEM_RISKS[entry.dataKey].label}
                            </span>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export const CustomChartToolTipContent = ({payload, active}) => {
    if (!active || !payload) {
        return null;
    }

    const riskTotal = payload.reduce((total, item) => {

        if (item?.payload?.counts) {
            const accessor = item.dataKey.split('.')[1]

            total = total + item?.payload?.counts[accessor] || 0;
        } else {
            total = total + item?.payload[item?.dataKey] || 0;
        }

        return total;
    }, 0);

    return (
        <div className="custom-tooltip"
            style={{
                color: COLORS["color-text-white"],
                backgroundColor: hexToRgba(COLORS["color-main"], 0.9),
                minWidth: '100px',
                padding: '10px',
                fontSize: '11px',
                fontWeight: 'bold',
                boxShadow: '0px 0px 10px rgba(34, 43, 54, 0.12)'
        }}>
            <div className="label"
                style={{
                    borderBottom: `solid 1px ${COLORS['color-neutral-grey-light']}`,
                    paddingBottom: '5px',
                    marginBottom: '5px'
                }}>
                Total: {riskTotal}
            </div>
            {payload.map((item) => {
                const {dataKey} = item
                if (dataKey.includes('.')) {
                    const { name, payload, dataKey } = item || {};
                    const accessor = dataKey.split('.')[1]
                    let itemTotal;

                    if (!accessor) {
                        itemTotal = 0
                    }

                    const systemRisk = Object.values(SYSTEM_RISKS_WITH_ACCESSORS).find((systemRisk) => systemRisk.accessor === accessor)

                    itemTotal = payload?.counts[accessor];
                    return (
                        <div key={name} style={{ color: systemRisk.color }}>
                            {`${systemRisk?.label} ${itemTotal}`}
                        </div>
                    );
                } else {
                    const payloadElem = payload.find((elem) => elem.dataKey === dataKey)

                    const itemTotal = payloadElem.payload[dataKey]

                    const systemRisk = Object.values(SYSTEM_RISKS_WITH_ACCESSORS).find((systemRisk) => systemRisk.value === dataKey)

                    return (
                        <div key={systemRisk?.label} style={{ color: systemRisk.color }}>
                            {`${systemRisk?.label} ${itemTotal}`}
                        </div>
                    );
                }
            })}
        </div>
    );
};

export const UnfuzzableMessageDisplay = () => (
    <span>Test option disabled in<br />deployments &#62; cluster<br />configuration</span>
)

export const FUZZING_STATUS_ITEMS = {
    IN_PROGRESS: {
        value: "IN_PROGRESS",
        label: "In progress",
        color: COLORS["color-warning-low"],
        getTooltip: ({ progress, startTime }) => (
            <span>
                <b>{`Test in progress ${progress || 0}%`}</b><br />{`started at ${formatDate(startTime)}`}
            </span>
        )
    },
    READY: {
        value: "READY",
        label: "Test-ready",
        color: COLORS["color-main"]
    },
    DONE: {
        value: "DONE",
        label: "Completed",
        color: COLORS["color-success"],
        getTooltip: ({ endTime }) => (
            <span>
                <b>Test completed</b>{` at`}<br />{formatDate(endTime)}
            </span>
        )
    },
    ERROR: {
        value: "ERROR",
        label: "Failed",
        color: COLORS["color-error"],
        getTooltip: ({ statusMessage, endTime }) => (
            <span>
                <b>Test failed</b>{` at ${formatDate(endTime)}`}<br />{statusMessage}
            </span>
        )
    },
    UNFUZZABLE: {
        value: "UNFUZZABLE",
        label: "Not enabled",
        color: COLORS["color-warning"],
        getTooltip: UnfuzzableMessageDisplay
    },
    CANCELLED: {
        value: "CANCELLED",
        label: "Cancelled by user",
        color: COLORS["color-main"]
    },
    STOP_IN_PROGRESS: {
        value: "STOP_IN_PROGRESS",
        label: "Stopping...",
        color: COLORS["color-main"]
    }
};

export const FUZZING_STATUS_IN_PROGRESS = [FUZZING_STATUS_ITEMS.IN_PROGRESS.value, FUZZING_STATUS_ITEMS.STOP_IN_PROGRESS.value];

export const CATEGORIES = {
  user: {
    label: "User",
  },
  email: {
    label: "Email",
  },
  network: {
    label: "Network",
  },
  application: {
    label: "Application",
  },
  patch: {
    label: "Patch",
  },
  system: {
    label: "System",
  },
  mobile: {
    label: "Mobile",
  },
  dns: {
    label: "DNS",
  },
  data_incident: {
    label: "Data Incident",
  },
  "api-specification": {
    label: "Api-Spec",
  },
  authentication: {
    label: "Authentication",
  },
  "server-workload-security": {
    label: "Server Workload Security",
  },
  authorization: {
    label: "Authorization",
  },
};

export const GATEWAY_STATUS_TYPE = {
  PENDING_INSTALL: {
    value: "PENDING_INSTALL",
    label: "Pending install",
    iconColor: COLORS["color-warning"],
  },
  ENABLED: {
    value: "ENABLED",
    label: 'Enabled',
    iconColor: COLORS["color-alpha-green"],
  },
  DELETED: {
    value: "DELETED",
    label: 'Deleted',
    iconColor: COLORS["color-grey"]
  },
  ACTIVE: {
    value: "ACTIVE",
    label: "Active",
    iconColor: COLORS["color-success"],
  },
  INACTIVE: {
    value: "INACTIVE",
    label: "Inactive",
    iconColor: COLORS["color-error"],
  },
  STOPPED: {
    value: "STOPPED",
    label: "Stopped",
    iconColor: COLORS["color-error-dark"],
  },
  TERMINATED: {
    value: "TERMINATED",
    label: "Terminated",
    iconColor: COLORS["color-error-dark"],
  },
  UNKNOWN: {
    value: "TERMINATED",
    label: "Terminated",
    iconColor: COLORS["color-grey"],
  },
};

export const GATEWAY_TYPES = {
  APIGEE_X: {
    value: "APIGEE_X",
    label: "Apigee X",
  },
  F5_BIG_IP: {
      value: "F5_BIG_IP",
      label: "F5 Big-IP",
  },
  KONG_INTERNAL: {
    value: "KONG_INTERNAL",
    label: "Kong (Kubernetes)",
  },
  TYK_INTERNAL: {
    value: "TYK_INTERNAL",
    label: "Tyk (Kubernetes)",
  },
};
