export const POLICY_TYPE = "Cluster Events";

export const ALL_KEY = "ALL";

export const USER_TYPES = {
    USER: "USER",
    GROUP: "GROUP",
    SERVICEACCOUNT: "SERVICEACCOUNT"
};

export const getRecommendedRuleNameById = (recommendedRules, id) =>
    recommendedRules.find(item => item.id === id).name;