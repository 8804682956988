import React from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import Icon, { ICON_NAMES } from 'components/Icon';
import Arrow from 'components/Arrow';
import Tooltip from 'components/Tooltip';
import Checkbox from 'components/Checkbox';
import { formatDateBy, formatNumber } from 'utils/generalUtils';

import './suggestion-item.scss';

const formatDate = date => formatDateBy(date, "D MMM YYYY");

const SuggestionItemContent = (props) => {
    const {id, recommendation, affected, total, timePeriod, title, contentDisplayItems,
        onItemEdit, onAffectedDescClick, applyTitle, onItemAdd, hideStatsForReccomendation=()=>{}, isReadOnly} = props;
    const {startDate, endDate, selectedRange} = timePeriod;
    
    return (
        <div className="suggested-item-content">
            
            {
                contentDisplayItems.map((ContentDisplayItem, index) => (
                    <React.Fragment key={index}>
                        <hr className="separator" />
                        <ContentDisplayItem data={recommendation} onItemEdit={onItemEdit} />
                    </React.Fragment>
                ))
            }
            {!hideStatsForReccomendation(recommendation) &&
                <React.Fragment>
                    <hr className="separator" />
                    <div className="suggestion-stats-content">
                        <div className="stats-item">
                            <div className="suggestion-content-title">Based on Time Period</div>
                            <div className="time-period-display">
                                <div className="time-item"><Icon name={ICON_NAMES.START_TIME} />{formatDate(startDate)}</div>
                                <div className="time-connector">to</div>
                                <div className="time-item"><Icon name={ICON_NAMES.END_TIME} />{formatDate(endDate)}</div>
                            </div>
                        </div>
                        <div className="stats-item">
                            <div className="suggestion-content-title">{`Affected ${title}`}</div>
                            <div className="affected-display">
                                <div className="affected-item">
                                    <span className="affected">{formatNumber(affected)}</span>
                                    <span className="total">{`out of ${formatNumber(total)}`}</span>
                                </div>
                                <div
                                    className={classnames("affected-desctiption", {"is-link": !!onAffectedDescClick})}
                                    onClick={() => !!onAffectedDescClick ?
                                        onAffectedDescClick({recommendation, startDate, endDate, count: affected, selectedRange}) : undefined}
                                >
                                    {`${title} could be affected during this time period`}
                                    {!!onAffectedDescClick && <Arrow name="right" small />}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
            <Button className="apply-button" onClick={() => onItemAdd({...recommendation, id})} disabled={isReadOnly}>
                {applyTitle}
            </Button>
        </div>
    );
}

const SuggestionItem = ({isOpen, isSelected, onSelectChange, onOpenClick, aboveSelected, ...props}) => {
    const {id, recommendation, affected, total, title, headerDisplayItem: HeaderDisplayItem, onItemEdit,
        hideStatsForReccomendation=()=>{}, customSubTitleComponent: CustomSubTitleComponent} = props;
    const {name, critical, icon: RecommendationIcon} = recommendation;

    return (
        <div className={classnames("suggestion-item", {"is-open": isOpen}, {"above-selected": aboveSelected})} >
            <div className="suggestion-header">
                <div className="suggested-item-title-with-icon-wrapper">
                    <div className="suggested-item-title-wrapper">
                        <div data-tip data-for={id} className="title-container">
                            <Checkbox
                                value={`select-suggestion-${id}`}
                                name={`select-suggestion-${id}`}
                                className={classnames("large", "select-suggestion", {critical})}
                                checked={isSelected}
                                title={name}
                                onChange={onSelectChange}
                                small
                            />
                        </div>
                        <Tooltip id={id} text={name} placement="left" />
                        {!isOpen && !hideStatsForReccomendation(recommendation) &&
                            <div className={classnames("affected-title", {critical})}>
                                {`Affected ${title} `}<span className="affected-total">{`${formatNumber(affected)} out of ${formatNumber(total)}`}</span>
                            </div>}
                        {!!CustomSubTitleComponent && <CustomSubTitleComponent data={recommendation} />}
                    </div>
                    {!!RecommendationIcon && <RecommendationIcon />}
                </div>
                <div className="suggestion-title-actions-wrapper">
                    {!isOpen && !!HeaderDisplayItem &&
                        <div className="header-display-item-container">
                            <HeaderDisplayItem data={recommendation} onItemEdit={onItemEdit} />
                        </div>}
                    <Arrow
                        name={isOpen ? "top" : "bottom"}
                        className="expand-icon"
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            
                            onOpenClick();
                        }}
                    />
                </div>
            </div>
            {isOpen && <SuggestionItemContent {...props} />}
        </div>
    );
}

export default SuggestionItem;