import { useReducer, useEffect, useRef, useCallback } from 'react';
import { useAuthDispatch, AUTH_ACTIONS } from 'context/AuthProvider';
import { useNotificationDispatch, showNotification, NOTIFICATION_TYPES } from 'context/NotificationProvider';
import { formatFetchOptions, FETCH_METHODS, AUTHENTICATION_ERROR_CODES, AUTHENTICATION_ERROR_INDICATOR } from './fetchUtils';

const DELETING = "DELETING";
const DELETE_SUCCESS = "DELETE_SUCCESS";
const DELETE_ERROR = "DELETE_ERROR";
const UPDATE_DELETE_PARAMS = "UPDATE_DELETE_PARAMS";
const DELETE_GENERAL_ERROR_MESSAGE = "An error occurred when trying to delete data";

function reducer(state, action) {
    switch (action.type) {
        case DELETING:
            return {...state, deleting: true, error: null, callDelete: false};
        case DELETE_SUCCESS:
            return {...state, deleting: false, callDelete: false};
        case DELETE_ERROR:
            return {...state, deleting: false, error: action.payload, callDelete: false};
        case UPDATE_DELETE_PARAMS:
            return {
                ...state,
                callDelete: true,
                formattedUrl: `/api/${state.baseUrl}/${action.payload}${state.urlSuffix}`
            };
        default:
            return {...state};
    }
}
const useDelete = (url, options) => {
    const authDispatcher = useAuthDispatch();
    const notificationDispatch = useNotificationDispatch();

    const {urlSuffix, showServerError} = options || {};
    const [state, dispatch] = useReducer(reducer, {
        deleting: false,
        error: null,
        baseUrl: url,
        formattedUrl: null,
        urlSuffix: urlSuffix || "",
        callDelete: false
    });

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    const {error, deleting, formattedUrl, callDelete} = state;
    
    const doDelete = useCallback(async () => {
        const options = formatFetchOptions({method: FETCH_METHODS.DELETE});

        dispatch({type: DELETING});

        let isError = false;
        const showErrorMessage = (message) => showNotification(notificationDispatch, {message: showServerError && !!message ? message : DELETE_GENERAL_ERROR_MESSAGE, type: NOTIFICATION_TYPES.ERROR});

        fetch(formattedUrl, options)
            .then(response => {
                if (AUTHENTICATION_ERROR_CODES.includes(response.status)) {
                    throw Error(AUTHENTICATION_ERROR_INDICATOR);
                }

                isError = !response.ok;

                return response;
            })
            .then(response => response.status === 204 ? {} : response.json())
            .then(data => {
                if (isError) {
                    dispatch({type: DELETE_ERROR, payload: data});

                    showErrorMessage(data.message);

                    return;
                }

                if (!mounted.current) {
                    return;
                }

                dispatch({type: DELETE_SUCCESS, payload: data});
            })
            .catch(error => {
                if (!mounted.current) {
                    return;
                }
                
                if (!!error && error.message === AUTHENTICATION_ERROR_INDICATOR) {
                    authDispatcher({type: AUTH_ACTIONS.RESET_AUTHENTICATION});

                    return;
                }

                showErrorMessage();

                dispatch({type: DELETE_ERROR, payload: error});
            });
    }, [formattedUrl, authDispatcher, showServerError, notificationDispatch]);

    useEffect(() => {
        if (!formattedUrl || !callDelete) {
            return;
        }

        doDelete();
    }, [doDelete, formattedUrl, callDelete]);

    const deleteData = deleteId => dispatch({type: UPDATE_DELETE_PARAMS, payload: deleteId});
    
    return [{error, deleting}, deleteData];
}

export default useDelete;