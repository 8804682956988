
import React from "react";
import { tableUtils } from "components/Table";
import { ICON_NAMES } from "components/Icon";
import Icon from "components/Icon";
import LineLoader from "components/LineLoader";
import { CIS_SCAN_STATES,} from "utils/systemConsts";

import COLORS from 'utils/scss_variables.module.scss';

import './scan-progress-bar.scss';

const ScanProgressBar = ({scanState, numberOfNodes, numberOfScannedNodes}) => {
    let percentage = 0;
    if ([CIS_SCAN_STATES.READY.value, CIS_SCAN_STATES.ERROR.value].includes(scanState)) {
        if (numberOfNodes) {
            percentage = (numberOfScannedNodes * 100) / numberOfNodes;
        } else {
            percentage = 100;
        }
    }
    const iconDict = {
        [CIS_SCAN_STATES.READY.value]: (
            <Icon name={ICON_NAMES.SUCCESS} style={{color: COLORS["color-success"]}} />
        ),
        [CIS_SCAN_STATES.ERROR.value]: (
            <Icon name={ICON_NAMES.ERROR} style={{color: COLORS["color-error"]}} />
        ),
        [CIS_SCAN_STATES.IN_PROGRESS.value]: (
            <tableUtils.StatusRenderer isWarning customText=" " />
        ),
        [CIS_SCAN_STATES.STARTING.value]: (
            <tableUtils.StatusRenderer isWarning customText=" " />
        ),
    };

    return (
        <div className="cis-scan-progress">
            <LineLoader calculatedPercentage={percentage} className="scan-progress-bar" />
            <span className="scan-progress-percentage">{`${percentage}%`}</span>
            {iconDict[scanState]}
        </div>
    );
}

export default ScanProgressBar;