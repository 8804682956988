import React from 'react';
import { isEmpty, get } from 'lodash';
import Tooltip from 'components/Tooltip';
import Scrollbar from 'components/Scrollbar';

const NamesList = ({names, formatListItem}) => (
    <div className="names-container">
        {names.map(name =>
            {
                const formattedName = !!formatListItem ? formatListItem(name) : name;
                return (
                    <React.Fragment key={formattedName}>
                        <div data-tip data-for={formattedName} className="name-item-wrapper">
                            <div className="name-item">{formattedName}</div>
                        </div>
                        <Tooltip id={formattedName} text={formattedName} placement="left" />
                    </React.Fragment>
                )
            }
        )}
    </div>
);

const ContentItemDisplay = ({displayData, title, subTitle, data}) => (
    <Scrollbar className="content-display-item">
        <div className="suggestion-content-title">{title}</div>
        <div className="suggestion-content-sub-title suggestion-content-value">{subTitle}</div>
        {displayData.map(({title, valueKey, isList, formatListItem}) => {
            const value = get(data, valueKey);

            if (isEmpty(value)) {
                return null;
            }

            return (
                <React.Fragment key={title}>
                    <div className="suggestion-content-label">{title}</div>
                    {isList ? <NamesList names={value} formatListItem={formatListItem} /> :
                        <div className="suggestion-content-value">{value}</div>}
                </React.Fragment>
            );
        })}
    </Scrollbar>
);

export default ContentItemDisplay;