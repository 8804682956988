import React from 'react';
import DeleteWizardModal, { TextElement, InnerTitle, LinksList, InfoMessage } from 'components/DeleteWizardModal';
import { TOKENS_PAGE_PATH } from 'layout/Apis/Tokens';
import { CONNECTIONS_POLICY_URL } from 'layout/Policies/ConnectionsPolicy';
import { APPLICATION_NAME } from 'utils/systemConsts';

const ConnectionRulesStep = ({stepData: rules, stepProps}) => {
    const deleteItemName = stepProps.deleteItemName;
    
    return (
        <div className="delete-api-connection-rules">
            <React.Fragment>
                <InnerTitle>Connection rules</InnerTitle>
                <TextElement>{`${deleteItemName} will be detached from the following connection rules.`}</TextElement>
                <InfoMessage>{`If the connection rule is only attached to ${deleteItemName}, it will be deleted.`}</InfoMessage>
                <LinksList
                    pathname={CONNECTIONS_POLICY_URL}
                    items={rules.map(({name, id}) => ({
                        name,
                        searchParams: {selectedRuleId: id}
                    }))}
                />
            </React.Fragment>
        </div>
    );
}

const TokensStep = ({stepData: tokens, stepProps}) => {
    const deleteItemName = stepProps.deleteItemName;
    
    return (
        <div className="delete-api-cd-policies">
            <React.Fragment>
                <InnerTitle>Tokens</InnerTitle>
                <TextElement>{`${deleteItemName} will be detached from the following tokens.`}</TextElement>
                <LinksList
                    pathname={TOKENS_PAGE_PATH}
                    items={tokens.map(({name}) => ({name, searchParams: {tokenName: name}}))}
                />
            </React.Fragment>
        </div>
    );
}

const DeleteApiModal = ({data, onClose, onDeleteSuccess}) => {
    const {identifier, name} = data;
    const deleteItemName = `${name} API`;
    
    return (
        <DeleteWizardModal
            className="delete-api-modal"
            url={`apiSecurity/${identifier}/deleteDependencies`}
            deleteUrl="apiSecurity/api"
            onClose={onClose}
            onDeleteSuccess={onDeleteSuccess}
            deleteSteps={[
                {id: 1, title: "Tokens", dataKey: "tokens", component: TokensStep},
                {id: 1, title: "Connection Rules", dataKey: "connectionRules", component: ConnectionRulesStep}
            ]}
            title="Delete API"
            description={`Deleting ${deleteItemName} will have an impact on other ${APPLICATION_NAME} elements. Once deleted, the action cannot be reverted`}
            deleteItemName={deleteItemName}
            deleteItemId={identifier}
        />
    )
}

export default DeleteApiModal;