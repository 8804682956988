import React from 'react';
import classnames from 'classnames';
import InfoIcon from 'components/InfoIcon';
import RoundIconContainer from 'components/RoundIconContainer';
import { ICON_NAMES } from 'components/Icon';

import COLORS from 'utils/scss_variables.module.scss';

export const FieldLabel = ({children, tooltipId, tooltipText, className}) => (
    <div className={classnames("ps-field-label-wrapper", {[className]: !!className})}>
        <label htmlFor={tooltipId} className="form-label">{children}</label>
        {!!tooltipText && <InfoIcon tooltipId={tooltipId} text={tooltipText} />}
    </div>
);

export const FieldDescription = ({children}) => (
    <div className="ps-field-description">{children}</div>
);

export const FieldError = ({children}) => (
    <div className="ps-field-error">{children}</div>
)

export const SearchInput = ({name, disabled, onChange}) => (
    <input
        name={name}
        className="search-input"
        onChange={event => onChange(event.target.value)}
        disabled={disabled}
        style={{
            width: "100%",
            height: "20px",
            color: COLORS["color-text-white"],
            outline: "none",
            border: `1px solid ${COLORS["color-neutral-grey-light"]}`,
            backgroundColor: COLORS["color-background"],
            borderRadius: "2px",
            fontWeight: "400",
            fontSize: "10px",
            lineHeight: "14px"
        }}
    />
)

export const EmptyFilterMessage = () => <div style={{fontSize: "10px"}}>- no match found -</div>

export const AddIcon = props => <RoundIconContainer name={ICON_NAMES.ADD} {...props} className="ps-form-add-icon" />;
export const RemoveIcon = props => <RoundIconContainer name={ICON_NAMES.MINUS} {...props} className="ps-form-remove-icon" />;