import React from 'react';
import moment from 'moment';
import { isNull } from 'lodash';
import { compareVersions } from 'compare-versions';
import { PRODUCTION_ADDRESS } from 'utils/systemConsts';

export const getNow = () => moment().format();

export const formatDateBy = (date, format) => !!date ? moment(date).format(format) : "";
export const formatDate = (date) => formatDateBy(date, "h:mm:ss A MMM Do, YYYY");

export const formatNumber = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

const getUserDataFromLocalStorage = (id) => {
    const userData = window.localStorage.getItem(`portshift${id}`);
    return isNull(userData) ? null : JSON.parse(userData);
}

export const saveInLocalStorage = (id, propName, data) => {
    let userData = getUserDataFromLocalStorage(id);

    if (!userData) {
        userData = {};
    }

    userData[propName] = data;
    localStorage.setItem(`portshift${id}`, JSON.stringify(userData));
}

export const getFromLocalStorage = (id, propName) => {
    const userData = getUserDataFromLocalStorage(id);

    if (!userData) {
        return null;
    }

    return userData[propName];
}

export const BoldText = ({ children }) => <span style={{ fontWeight: "bold" }}>{children}</span>;

export const AndList = (items, withAnd = true) => items.map((item, index, items) => (
    <React.Fragment key={index}>
        <BoldText>{item}</BoldText>
        {index < items.length - 2 ? ", " : ""}
        {index === items.length - 2 && (withAnd ? " and " : ", ")}
    </React.Fragment>
));

export const isProductionUrl = () => {
    const locationOrigin = window.location.origin;

    return locationOrigin.includes(PRODUCTION_ADDRESS.DOMAIN) || locationOrigin.includes("securecn.cisco.com");
};

export const isDemoPortalUrl = () => window.location.origin.includes("//demo.panoptica.app");

export const stringToUtcDate = dateString => {
    const date = !!dateString ? new Date(dateString) : new Date();

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

export const hexToRgba = (hex, opacity) => (
    `rgba(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(hex.slice(3, 5), 16)}, ${parseInt(hex.slice(5, 7), 16)}, ${opacity})`
)

export const isYamlController = ({version, minControllerAutoUpdateVersion}) => {
    if (!version || !minControllerAutoUpdateVersion) {
        return false;
    }
    
    return compareVersions(version, minControllerAutoUpdateVersion) < 0
}