import React, {useState} from "react";
import {isNull} from "lodash";
import {ICON_NAMES} from "components/Icon";
import IconWithTooltip from "components/IconWithTooltip";
import Text, {TEXT_TYPES} from "components/Text";
import UnprotectedPodDataModal from './UnprotectedPodDataModal';

import './unprotected-pod-reason.scss';

const UnprotectedPodReason = ({unprotectedPodReasons, podName, namespace}) => {
    const [unprotectedPodReasonData, setUnprotectedPodReasonData] = useState(null);
    const closeUnprotectedPodReasonModal = () => setUnprotectedPodReasonData(null);

    return (
        <div className="protected-pod-value-display-wrapper">
            <IconWithTooltip
                wrapperClassName="protect-icon"
                name={ICON_NAMES.SHIELD_CHECK}
                tooltipText="Protect pod instructions"
            />
            <Text className="text" type={TEXT_TYPES.LINKS} onClick={() => setUnprotectedPodReasonData(unprotectedPodReasons)}>Protect</Text>
            {!isNull(unprotectedPodReasonData) &&
                <UnprotectedPodDataModal
                    unprotectedPodReasons={unprotectedPodReasons}
                    podName={podName}
                    namespace={namespace}
                    onClose={closeUnprotectedPodReasonModal}
                />
            }
        </div>
    )
};

export default UnprotectedPodReason;