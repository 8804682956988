import React from 'react';
import classnames from 'classnames';
import Arrow from 'components/Arrow';

import './pagination.scss';

const Pagination = ({ canPreviousPage, previousPage, nextPage, pageIndex, pageSize, setPageSize, page, loading }) => {

    return (
        <div className="pagination-container">
            <div className="pagination-navigation">
                <span className="pagination-title">Page</span>
                <Arrow name="left" disabled={!canPreviousPage || loading} onClick={previousPage} />
                <span className="page-number">{pageIndex + 1}</span>
                <Arrow name="right" disabled={page.length < pageSize || loading} onClick={nextPage} />
            </div>
            <div className="pagination-results">
                {[50, 100, 200].map(item => (
                    <span key={item} className={classnames("result-item", { selected: item === pageSize })} onClick={() => setPageSize(item)}>
                        {item}
                    </span>))
                }
                <span className="pagination-title">Per page</span>
            </div>
        </div>
    );
}

export default Pagination;