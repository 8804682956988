import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { TIME_RANGER_MAP, checkIsManualRange } from 'context/TimeRangeProvider';
import Button from 'components/Button';
import RadioButtons from 'components/RadioButtons';
import Calendar from 'components/Calendar';
import Icon, { ICON_NAMES } from 'components/Icon';
import Arrow from 'components/Arrow';
import useTimeFilter from './useTimeFilter';
import useStanaloneTimeFilter from './useStanaloneTimeFilter';

import './time-filter.scss';

export {
    useTimeFilter,
    useStanaloneTimeFilter
}

const TimeFilter = ({defaultSelected, startTime, endTime, disabled, style, onTimeFilterChange}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selected, setSelected] = useState(defaultSelected);
    const [selectedCalendarRange, setSelectedCalendarRange] = useState({startTime, endTime});

    const closeDropdown = () => setDropdownOpen(false);
    
    const handleFilterSelect = () => {    
        const {startTime, endTime} = checkIsManualRange(selected) ? selectedCalendarRange : TIME_RANGER_MAP[selected].calc();;

        onTimeFilterChange(selected, startTime, endTime);
        closeDropdown();
    }

    return (
        <ButtonDropdown
            className="time-filter-dropdown-new"
            isOpen={dropdownOpen}
            toggle={() => {
                if (dropdownOpen) {
                    setSelected(defaultSelected);
                    setSelectedCalendarRange({startTime, endTime});
                }

                setDropdownOpen(!dropdownOpen);
            }}
            disabled={disabled}
            style={style || {}}
        >
            <DropdownToggle disabled={disabled} caret>
                <Icon name={ICON_NAMES.START_TIME} />
                <div>{defaultSelected && TIME_RANGER_MAP[defaultSelected].label}</div>
                <Arrow name="bottom" className="caret-icon" small />
            </DropdownToggle>
            {dropdownOpen &&
                <DropdownMenu>
                    <div className="content-container">
                        <div className="left-pane">
                            <RadioButtons
                                name="timeRadio"
                                items={Object.values(TIME_RANGER_MAP).map(({value, label}) => ({value, label}))}
                                selected={selected}
                                onChange={(selected) => setSelected(selected)}
                                small={true}
                            />
                        </div>
                        <div className="right-pane">
                            {!!defaultSelected && 
                                <Calendar
                                    startTime={selectedCalendarRange.startTime}
                                    endTime={selectedCalendarRange.endTime}
                                    onChange={selectedCalendarRange => setSelectedCalendarRange(selectedCalendarRange)}
                                    disabled={selected && !checkIsManualRange(selected)}
                                />}
                        </div>
                    </div>
                    <div className="buttons-container">
                        <Button tertiary onClick={closeDropdown}>Cancel</Button>
                        <Button onClick={handleFilterSelect}>Done</Button>
                    </div>
                </DropdownMenu>
            }
        </ButtonDropdown>
    )
}

export default TimeFilter;