import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { isNull, isArray } from 'lodash';
import classnames from 'classnames';
import { formatDate } from 'utils/generalUtils';
import { APP_PATHS, APPLICATION_NAME, SYSTEM_RISKS } from 'utils/systemConsts';
import { useAuthState } from 'context/AuthProvider';
import { FETCH_METHODS, useDelete, useFetch } from 'hooks';
import Table, { tableUtils } from 'components/Table';
import IconWithTitle from 'components/IconWithTitle';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';
import FormModal from 'components/FormModal';
import VulnerabilitiesSummary from 'components/VulnerabilitiesSummary';
import MiniDashboardWrapper, { RiskDashboardItem } from 'components/MiniDashboardWrapper';
import EmptyDataNotificationMessage from 'components/EmptyDataNotificationMessage';
import ModalConfirmation from 'components/ModalConfirmation';
import { FUNCTION_RISK_TYPES_MAPPING, getSecretsRisk, getUnusedRisk } from '../utils';
import { SERVERLESS_FUNCTIONS_PAGE_PATH, SERVERLESS_FUNCTION_FILTER_KEYS } from '../Functions';
import FiltersForm from './FiltersForm';
import CloudAccountConfigForm from './CloudAccountConfigForm';
import AccountInstallDataModal, { PROVIDER_ITEMS } from './AccountInstallDataModal';
import { VALIDATION_TYPE_ITEMS } from './utils';

import './serverless.scss';

export {
    PROVIDER_ITEMS
}

export const SERVERLESS_CLOUD_ACCOUNT_PAGE_PATH = `${APP_PATHS.SERVERLESS.path}/cloudAccount`;

const INSTALLATION_STATUSES = {
    INSTALLED: {value: "INSTALLED", label: "Installed"},
    PENDING_INSTALLATION: {value: "PENDING_INSTALLATION", label: "Pending installation"},
    FAILED: {value: "FAILED", label: "Failed"}
}

const getIsAwsAccount = cloudProvider => cloudProvider === PROVIDER_ITEMS.AWS.value;

const AwsDeleteNotification = () => (
    <div>
        <div>{`Please delete the ${APPLICATION_NAME} CloudFormation stack on your AWS account in order to complete the deletion:`}</div>
        <a href="https://console.aws.amazon.com/cloudformation" target="_blank" rel="noopener noreferrer">CloudFormation page</a>
    </div>
);

const AzureDeleteNotification = () => (
    <div className="azure-delete-account-confirmation-message">
        <div>To delete Panoptica from your Azure cloud account:</div>
        <ol>
            <li>
                <span>Remove Role Assignment: Go to the&nbsp;</span>
                <a href="https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBlade" target="_blank" rel="noopener noreferrer">Subscriptions page</a>
                <span>&nbsp;&#8594; Choose subscription &#8594; Access control (IAM) &#8594; Role assignments &#8594; Remove panopticaUserAssignedIdentity.</span>
            </li>
            <li>
                <span>Remove Diagnostic Settings: Go to the&nbsp;</span>
                <a href="https://portal.azure.com/#view/Microsoft_Azure_Billing/SubscriptionsBlade" target="_blank" rel="noopener noreferrer">Subscriptions page</a>
                <span>&nbsp;&#8594; Choose subscription &#8594; Activity log &#8594; Export Activity Logs &#8594; Remove panopticaDiagnosticExport. (Edit settings and click delete.)</span>
            </li>
            <li>
                <span>Delete the Resource Group: Go to the&nbsp;</span>
                <a href="https://portal.azure.com/#view/HubsExtension/BrowseResourceGroups" target="_blank" rel="noopener noreferrer">Resource groups page</a>
                <span>&nbsp;&#8594; Open the resource group you created for Panoptica &#8594; Select Delete resource group.</span>
            </li>
        </ol>
    </div>
);

const DeleteConfirmationModal = ({deleteConfirmationData, onCancel, onDelete}) => {
    if (isNull(deleteConfirmationData)) {
        return null;
    }

    const {cloudProvider} = deleteConfirmationData || {};
    const isAwsAccount = getIsAwsAccount(cloudProvider);
    
    return (
        <ModalConfirmation
            className={classnames("serverless-account-delete-confirmation", {"is-large": !isAwsAccount})}
            title="Are you sure?"
            message={isAwsAccount ? <AwsDeleteNotification /> : <AzureDeleteNotification />}
            confirmTitle={isAwsAccount ? "Delete anyway" : "Delete"}
            onCancel={onCancel}
            onConfirm={onDelete}
        /> 
    )
}

const ThreatItemdisplay = ({id, title, count, risk, icon}) => (
    <RiskDashboardItem
        id={`${id}-${title}`}
        count={count || 0}
        icon={icon}
        risk={count === 0 ? null : SYSTEM_RISKS[risk]}
        customLabel={title}
    />
)

const CloudAccount = () => {
    const columns = useMemo(() => [
        {
            Header: "Name",
            id: "name",
            accessor: "name",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Provider",
            id: "cloudProvider",
            accessor: original => PROVIDER_ITEMS[original.cloudProvider]?.label,
            disableSortBy: true
        },
        {
            Header: "Security threats",
            id: "securityThreats",
            Cell: ({row}) => {
                const {id, securityThreats} = row.original;
                const {dataAccessRisk, dataAccessRiskCount, isUnusedFunction, unusedFunctionCount, policyRisk, policyRiskCount,
                    publiclyAccessibleRisk, publiclyAccessibleRiskCount, secretsRisk, secretsRiskCount} = securityThreats || {};

                return (
                    <MiniDashboardWrapper>
                        <ThreatItemdisplay
                            id={id}
                            title="Publicly accessible"
                            count={publiclyAccessibleRiskCount}
                            risk={publiclyAccessibleRisk}
                            icon={FUNCTION_RISK_TYPES_MAPPING.PUBLICLY_ACCESSIBLE.icon}
                        />
                        <ThreatItemdisplay
                            id={id}
                            title="Policy"
                            count={policyRiskCount}
                            risk={policyRisk}
                            icon={FUNCTION_RISK_TYPES_MAPPING.POLICY.icon}
                        />
                        <ThreatItemdisplay
                            id={id}
                            title="Secrets"
                            count={secretsRiskCount}
                            risk={getSecretsRisk(secretsRisk)}
                            icon={FUNCTION_RISK_TYPES_MAPPING.SECRETS.icon}
                        />
                        <ThreatItemdisplay
                            id={id}
                            title="Unused function"
                            count={unusedFunctionCount}
                            risk={getUnusedRisk(isUnusedFunction)}
                            icon={FUNCTION_RISK_TYPES_MAPPING.UNUSED_FUNCTION.icon}
                        />
                        <ThreatItemdisplay
                            id={id}
                            title="Data access"
                            count={dataAccessRiskCount}
                            risk={dataAccessRisk}
                            icon={FUNCTION_RISK_TYPES_MAPPING.DATA_ACCESS.icon}
                        />
                    </MiniDashboardWrapper>
                );
            },
            minWidth: 280
        },
        {
            Header: "Vulnerabilities",
            id: "vulnerabilities",
            Cell: ({row}) => {
                const {id, vulnerabilitiesSummary: summaryData} = row.original;
                const {total, critical, high, medium, low, unknown} = summaryData || {};
                const summaryProps = {
                    id,
                    total: total || 0,
                    critical: critical || 0,
                    high: high || 0,
                    medium: medium || 0,
                    low: low || 0,
                    unknown: unknown || 0
                };

                return (
                    <VulnerabilitiesSummary {...summaryProps} />
                );
            },
            minWidth: 350
        },
        {
            Header: "Installation status",
            id: "installationStatus ",
            accessor: original => {
                const {installationStatus} = original;
                const statusItem = INSTALLATION_STATUSES[installationStatus] || {};

                return statusItem.label || installationStatus;
            },
            disableSortBy: true
        },
        {
            Header: "Validation type",
            id: "validateFunction",
            accessor: original => VALIDATION_TYPE_ITEMS[original.validateFunction].label,
            disableSortBy: true
        },
        {
            Header: "Account ID",
            id: "cloudAccountId",
            accessor: "cloudAccountId",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Regions",
            id: "regions",
            disableSortBy: true,
            hide: true,
            Cell: ({row}) => (
                <tableUtils.ExpendableTagsCell items={row.original.regions} />
            )
        },
        {
            Header: "Last scanned",
            id: "lastScanned",
            accessor: original => formatDate(original.lastScanned),
        },

    ], []);

    const {isReadOnlyUser} = useAuthState();

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = () => setRefreshTimestamp(Date());

    const [selectedCloudAccountData, setSelectedCloudAccountData] = useState(null);
    const closeUserModal = () => setSelectedCloudAccountData(null);

    const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationData(null);

    const [scanNowConfirmationId, setScanNowConfirmationId] = useState(null);
    const closeScanNowConfirmation = () => setScanNowConfirmationId(null);

    const [openInsatllModal, setOpenInsatllModal] = useState(false);

    const [accountsCount, setAccountsCount] = useState(0);

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    const history = useHistory();

    const location = useLocation();
    const {filters: queryFilters} = location.query || {};
    const [filters, setFilters] = useState({
        ...queryFilters
    });

    // eslint-disable-next-line no-unused-vars
    const [scanState, scanNow] = useFetch('cloudAccounts/scan', {loadOnMount: false});

    const doScanNow = (id) =>
        scanNow({
        method: FETCH_METHODS.POST,
        submitData: {cloudAccounts: [id]}
    });
    const [{deleting}, deleteCloudAccount] = useDelete("cloudAccounts");

    return (
        <div className="cloud-account-serverless-page">
            <div className="table-header-container">
                {accountsCount > 0 &&
                    <React.Fragment>
                        <div className="create-account-actions-wrapper">
                            <IconWithTitle
                                name={ICON_NAMES.ADD}
                                title="Connect account"
                                onClick={() => setOpenInsatllModal(true)}
                                disabled={isReadOnlyUser}
                            />
                        </div>
                        <FiltersForm
                            filters={filters}
                            queryFilters={queryFilters}
                            setFilters={updatedFilters => {
                                if (!mounted.current) {
                                    return;
                                }
                                setFilters(updatedFilters);
                            }}
                        />
                    </React.Fragment>
                }
            </div>
            <Table
                url="cloudAccounts"
                name="cloudAccounts"
                filters={filters}
                columns={columns}
                actionsComponent={({original}) => {
                    const {id, installationStatus, cloudProvider} = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;
                    const scanTooltipId = `${id}-scan`;

                    const isPendingInstallation = installationStatus === INSTALLATION_STATUSES.PENDING_INSTALLATION.value;
                    const isAwsAccount = getIsAwsAccount(cloudProvider);

                    const onActionClick = (event, callback) => {
                        event.preventDefault();
                        event.stopPropagation();

                        callback();
                    }

                    return (
                        <div className="cloud-account-row-actions-wrapper" style={{width: "100%", display: "flex", alignItems: "flex-end", flexDirection: "column"}}>
                            <div className="cloud-account-row-actions" style={isAwsAccount ? {} : {width: "50px"}}>
                                <IconWithTooltip
                                    name={ICON_NAMES.SCAN_NOW}
                                    onClick={event => onActionClick(event, () => setScanNowConfirmationId(original.id))}
                                    tooltipId={scanTooltipId}
                                    tooltipText="Rediscover"
                                    disabled={isReadOnlyUser || isPendingInstallation}
                                />
                                <IconWithTooltip
                                    name={ICON_NAMES.EDIT}
                                    onClick={event => onActionClick(event, () => setSelectedCloudAccountData(original))}
                                    tooltipId={editTooltipId}
                                    tooltipText="Edit configuration"
                                    disabled={isReadOnlyUser}
                                />
                                <IconWithTooltip
                                    name={ICON_NAMES.DELETE}
                                    onClick={event => onActionClick(event, () => setDeleteConfirmationData(original))}
                                    tooltipId={deleteTooltipId}
                                    tooltipText="Delete cloud account"
                                    disabled={isReadOnlyUser}
                                />
                            </div>
                        </div>
                    );
                }}
                actionsCellWidth={110}
                withPagination={false}
                isLoading={deleting}
                refreshTimestamp={refreshTimestamp}
                onFetchedDataChange={(data) => {
                    if (isArray(data)) {
                        setAccountsCount(data.length);
                    }

                    return data;
                }}
                emptyTableCustomDisplayComponent={() => (
                    <EmptyDataNotificationMessage
                        message={(
                            <React.Fragment>
                                <div>No cloud accounts detected.</div>
                                <div>Add new account.</div>
                            </React.Fragment>
                        )}
                        mainTitle="Connect account"
                        onMainClick={() => setOpenInsatllModal(true)}
                    />
                )}
                onLineClick={({id}) => history.push({
                    pathname: SERVERLESS_FUNCTIONS_PAGE_PATH,
                    query: {filters: {[SERVERLESS_FUNCTION_FILTER_KEYS.ACCOUNT_IDS]: [id]}}
                })} />
            <DeleteConfirmationModal
                deleteConfirmationData={deleteConfirmationData}
                onCancel={closeDeleteConfirmation}
                onDelete={() => {
                    closeDeleteConfirmation();
                    deleteCloudAccount(deleteConfirmationData.id);
                }}
            />
            {!isNull(selectedCloudAccountData) &&
                <FormModal
                    onClose={closeUserModal}
                    formComponent={CloudAccountConfigForm}
                    formProps={{
                        initialData: {...selectedCloudAccountData},
                        onFormSubmitSuccess: () => {
                            closeUserModal();
                            refreshTableData();
                        },
                        isAwsAccount: getIsAwsAccount(selectedCloudAccountData.cloudProvider)
                    }}
                />
            }
            {!isNull(scanNowConfirmationId) &&
                <ModalConfirmation
                    title="Do you want to run a scan now?"
                    message="This might take several minutes"
                    confirmTitle="Scan now"
                    onCancel={closeScanNowConfirmation}
                    onConfirm={() => {
                        closeScanNowConfirmation();
                        doScanNow(scanNowConfirmationId);
                    }}
                />
            }
            {openInsatllModal &&
                <AccountInstallDataModal
                    onClose={() => {
                        setOpenInsatllModal(false);
                        refreshTableData();
                    }}
                />
            }
        </div>
    )
}

export default CloudAccount;