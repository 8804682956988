import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { CD_PIPELINE_FINDINGS_RISKS, EMPTY_VALUE } from 'utils/systemConsts';
import { formatDateBy } from 'utils/generalUtils';
import { useFetch } from 'hooks';
import Modal from 'components/Modal';
import Table from 'components/Table';
import Loader from 'components/Loader';
import Icon from 'components/Icon';
import Text, { TEXT_TYPES } from 'components/Text';
import RuleActionDisplay from 'components/RuleActionDisplay';
import { CI_CD_POLICY_URL } from 'layout/Policies/CiCdScanPolicy';
import CdScanSummary from './CdScanSummary';
import { FINDINGS_TYPES_MAP } from './utils';

import './cd-scan.scss';

const ScanResourcesModal = ({id: scanId, onClose}) => {
    const columns = useMemo(() => [
        {
            Header: "Resource Name",
            id: "resourceName",
            accessor: "resourceName",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Security Threats",
            id: "securityThreats",
            Cell: ({row}) => {
                const {risk, type} = row.original;
                const {label, icon, value} = FINDINGS_TYPES_MAP[type];
                const iconClass = risk === CD_PIPELINE_FINDINGS_RISKS.NO_RISK.value ? "" : risk.toLowerCase();

                const isSecret = value === FINDINGS_TYPES_MAP.SECRETS.value;
                const isNoRisk = risk === CD_PIPELINE_FINDINGS_RISKS.NO_RISK.value;

                return (
                    <div className="cd-scan-resources-wrapper">
                        <div>{label}</div>
                        <div className={classnames("icon-container", iconClass, {"is-secret": isSecret}, {"is-no-risk": isNoRisk})}>
                            <Icon name={icon} />
                            <div>{(isSecret && !isNoRisk) ? "Has risk" : CD_PIPELINE_FINDINGS_RISKS[risk].label}</div>
                        </div>
                    </div>
                )
            },
            width: 300,
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Reasons",
            id: "reasons",
            Cell: ({row}) => {
                const {reasons} = row.original;

                return (
                    <div>
                        {reasons.map((reason, index) => <div key={index} style={{whiteSpace: "initial"}}>{reason}</div>)}
                    </div>
                )
            },
            width: 500,
            disableSortBy: true,
            alwaysShow: true
        }
    ], []);

    const [{loading, data}] = useFetch(`cd/${scanId}`);

    const {deploymentInfo, resources} = data || {};
    const {deploymentName, deploymentVersion, deploymentSource, result, securityFinding, time, policyName, policyId} = deploymentInfo || {};

    const headerItems = [
        {label: "Version", value: deploymentVersion},
        {label: "Source", value: deploymentSource},
        {label: "Result", componenet: () => <RuleActionDisplay action={result} />},
        {
            label: "Policy name",
            componenet: () => {
                if (!policyId) {
                    return EMPTY_VALUE;
                }

                const searchParamsUrl = new URLSearchParams({selectedScanId: policyId});
                
                return (
                    <Link to={{pathname: CI_CD_POLICY_URL, search: `?${searchParamsUrl.toString()}`}}>{policyName}</Link>
                )
            }
        },
        {label: "Security Threats", componenet: () => <CdScanSummary id={deploymentName} securityFinding={securityFinding} />},
    ];
    
    return (
        <Modal className="cd-scan-resources-modal" onClose={onClose} centerLarge={true}>
            {loading ? <Loader /> :
                <React.Fragment>
                    <Text type={TEXT_TYPES.TITLE_MEDIUM} withTopMargin>{deploymentName}</Text>
                    <div className="sub-title">Last update: <span>{formatDateBy(time, "DD/MM/YY")}</span></div>
                    <div className="cd-scan-resources-header">
                        {headerItems.map(({label, value, componenet: Componenet}) => (
                            <div key={label}>
                                <div className="label">{label}</div>
                                <div className="value">
                                    {!!Componenet ? <Componenet /> : value}
                                </div>
                            </div>
                        ))}
                    </div>
                    <Table
                        name="cd-scan-resources"
                        data={resources}
                        columns={columns}
                    />
                </React.Fragment>
            }
        </Modal>
    );
}

export default ScanResourcesModal;