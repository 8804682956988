import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { TIME_RANGER_MAP } from 'context/TimeRangeProvider';
import DropdownSelect from 'components/DropdownSelect';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import SuggestionItem from './SuggestionItem';

export const EmptyNotification = ({message}) => <div className="empty-suggestions">{message}</div>;

export const TIME_RANGES = {
    LAST_DAY: {
        value: "LAST_DAY",
        label: "Last day",
        subtractTime: "day",
        timeFilterValue: TIME_RANGER_MAP.DAY.value
    },
    LAST_WEEK: {
        value: "LAST_WEEK",
        label: "Last week",
        subtractTime: "week",
        timeFilterValue: TIME_RANGER_MAP.WEEK.value
    }
}

export default class AdvisorContent extends Component {
    state = {
        selectedRange: TIME_RANGES.LAST_DAY.value,
        openItem: null,
        items: this.props.items,
        selectedRecommendations: []
    }

    toggleOpenItem = (id) => {
        const {openItem} = this.state;

        this.setState({openItem: id === openItem ? null : id});
    }

    handleItemAdd = (itemData) => {
        this.props.onRecommendationsApply([itemData]);
    }

    handleSelectedItemsAdd = () => {
        const {items, selectedRange, selectedRecommendations} = this.state;
        const {recommendations} = items[selectedRange];
        
        const itemsData = selectedRecommendations.map(id => ({
            ...recommendations.find(item => item.id === id).recommendation,
            id
        }));
        
        this.props.onRecommendationsApply(itemsData);
    }

    handleItemEdit = (id, updatedItemData) => {
        const {items, selectedRange} = this.state;

        const selectedRangeItem = items[selectedRange];
        const recommendations = selectedRangeItem.recommendations;
        const itemIndex = recommendations.findIndex(item => item.id === id)
        const updatedRecommendations = [
            ...recommendations.slice(0, itemIndex),
            {...recommendations[itemIndex], recommendation: updatedItemData},
            ...recommendations.slice(itemIndex + 1)
        ];

        this.setState({items: {
            ...items,
            [selectedRange]: {
                ...selectedRangeItem,
                recommendations: updatedRecommendations}
        }});
    }

    handleSelectAllClicked = () => {
        const {selectedRecommendations, selectedRange, items} = this.state;
        const {recommendations} = items[selectedRange];

        const updatedSelected = selectedRecommendations.length === recommendations.length ?
            [] : recommendations.map(({id}) => id);

        this.setState({selectedRecommendations: updatedSelected});
    }

    handleSelectItemChange = (id) => {
        const {selectedRecommendations} = this.state;
        let updatedSelected = [];

        if (selectedRecommendations.includes(id)) {
            updatedSelected = selectedRecommendations.filter(item => item !== id);
        } else {
            updatedSelected = [...selectedRecommendations, id];
        }

        this.setState({selectedRecommendations: updatedSelected})
    }

    render() {
        const {emptyMessage, applyTitle, title, contentDisplayItems, onAffectedDescClick, headerDisplayItem, hideStatsForReccomendation,
            isReadOnly} = this.props;
        const {selectedRange, openItem, items, selectedRecommendations} = this.state;
        const {recommendations, total} = items[selectedRange];
        const emptyRecommendations = isEmpty(recommendations);
        const selectedRangeItem = TIME_RANGES[selectedRange];
        const timePeriod = {
            startDate: moment().subtract(1, selectedRangeItem.subtractTime).toISOString(),
            endDate: moment().toISOString(),
            selectedRange: selectedRangeItem.timeFilterValue
        };

        return (
            <div className="advisor-container">
                <div className="time-range-wrapper">
                    <div className="time-range-label">Based on</div>
                    <DropdownSelect
                        items={Object.values(TIME_RANGES)}
                        defaultselected={selectedRange}
                        name="timeRangeSelect"
                        onChange={selectedRange => this.setState({selectedRange, openItem: null})}
                        className="time-range-select"
                    />
                </div>
                {!emptyRecommendations &&
                    <Checkbox
                        value="multiselect-select-all"
                        name="multiselect-select-all"
                        title={`Select All (${recommendations.length})`}
                        className="select-all"
                        checked={selectedRecommendations.length === recommendations.length}
                        onChange={this.handleSelectAllClicked}
                        small
                    />}
                <div className="suggestions-container">
                    {emptyRecommendations && <EmptyNotification message={emptyMessage} />}
                    {openItem === 0 && <div className="item-shadow" />}
                    {
                        recommendations.map((suggestion) => {
                            const {id} = suggestion;
                            const isSelected = selectedRecommendations.includes(id);
                            
                            return (
                                <SuggestionItem
                                    key={id}
                                    {...suggestion}
                                    isOpen={id === openItem}
                                    onOpenClick={() => this.toggleOpenItem(id)}
                                    total={total}
                                    timePeriod={timePeriod}
                                    aboveSelected={openItem > 0 && id === openItem - 1}
                                    onItemAdd={this.handleItemAdd}
                                    onItemEdit={itemData => this.handleItemEdit(id, itemData)}
                                    applyTitle={applyTitle}
                                    title={title}
                                    contentDisplayItems={contentDisplayItems}
                                    headerDisplayItem={headerDisplayItem}
                                    onAffectedDescClick={onAffectedDescClick}
                                    isSelected={isSelected}
                                    onSelectChange={() => this.handleSelectItemChange(id)}
                                    hideStatsForReccomendation={hideStatsForReccomendation}
                                    isReadOnly={isReadOnly}
                                />
                            );
                        })
                    }
                </div>
                <Button
                    className="apply-all-button"
                    onClick={this.handleSelectedItemsAdd}
                    disabled={isEmpty(selectedRecommendations) || isReadOnly}
                >
                    Apply Selected
                </Button>
            </div>
        );
    }
}