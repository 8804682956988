import React from 'react';
import { isEmpty } from 'lodash';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { CD_PIPELINE_FINDINGS_RISKS, CI_CD_ENFORCEMENT_OPTIONS, SECRET_FINDINGS_RISKS } from 'utils/systemConsts';
import ListValueItemsDisplay from 'components/ListValueItemsDisplay';
import BaseUnorderedPolicyItem from '../../BaseUnorderedPolicyItem';

const CdItemDisplay = ({data, disabled, onEdit, onDelete, apiPolicyData, deployersData, isMarked}) => {
    const {showApiSecurity} = useDisplayConfigState();
    
    const {id, name, description, permissionCDPolicy, securityContextCDPolicy, secretCDPolicy, apiSecurityCdPolicy,
        deployers: deployersIds} = data;

    let displayItems = [];

    if (!!permissionCDPolicy.enforcementOption) {
        displayItems = [
            ...displayItems,
            {name: "Permissive Permission Risk Level", values: [CD_PIPELINE_FINDINGS_RISKS[permissionCDPolicy.permissibleVulnerabilityLevel].label]},
            {name: "Permission Enforcement Option", values: [CI_CD_ENFORCEMENT_OPTIONS[permissionCDPolicy.enforcementOption].label]}
        ]
    }

    if (!!securityContextCDPolicy.enforcementOption) {
        displayItems = [
            ...displayItems,
            {name: "Permissive Security Context Risk Level", values: [CD_PIPELINE_FINDINGS_RISKS[securityContextCDPolicy.permissibleVulnerabilityLevel].label]},
            {name: "Security Context Enforcement Option", values: [CI_CD_ENFORCEMENT_OPTIONS[securityContextCDPolicy.enforcementOption].label]}
        ]
    }

    if (!!secretCDPolicy.enforcementOption) {
        displayItems = [
            ...displayItems,
            {name: "Permissive Secret Risk Level", values: [SECRET_FINDINGS_RISKS[secretCDPolicy.permissibleVulnerabilityLevel].label]},
            {name: "Secret Enforcement Option", values: [CI_CD_ENFORCEMENT_OPTIONS[secretCDPolicy.enforcementOption].label]}
        ]
    }

    if (showApiSecurity && !!apiSecurityCdPolicy.apiSecurityProfile) {
        const policyId = apiSecurityCdPolicy.apiSecurityProfile;
        const policyItem = apiPolicyData.find(item => item.id === policyId);

        displayItems = [
            ...displayItems,
            {name: "Permissive API Security Profile", values: [!!policyItem ? policyItem.name : policyId]},
            {name: "API Security Profile Enforcement Option", values: [CI_CD_ENFORCEMENT_OPTIONS[apiSecurityCdPolicy.enforcementOption].label]}
        ]
    }

    if (!isEmpty(deployersIds)) {
        displayItems.push({
            name: "Deployers",
            values: deployersIds.map(deployerId => {
                const deployerItem = deployersData.find(item => item.id === deployerId);
                
                return !!deployerItem ? deployerItem.deployer : deployerId;
            })
        });
    }

    return (
        <BaseUnorderedPolicyItem
            id={id}
            name={name}
            description={description}
            disabled={disabled}
            canEditDelete={true}
            canCopy={false}
            onEdit={onEdit}
            onDelete={onDelete}
            alwaysOpen={true}
            isMarked={isMarked}
        >
            <ListValueItemsDisplay items={displayItems} />
        </BaseUnorderedPolicyItem>
    )
}

export default CdItemDisplay;