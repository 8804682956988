import React from 'react';
import Icon, { ICON_NAMES } from 'components/Icon';
import Tooltip from 'components/Tooltip';

import './error-icon-notification.scss';

const ErrorIconNotification = ({tooltipId, text}) => (
    <div className="error-icon-notification">
        <div data-tip data-for={tooltipId}>
            <Icon name={ICON_NAMES.EXCLAMATION_MARK_ROUND} />
        </div>
        <Tooltip id={tooltipId} text={text} placement="top" />
    </div>
);

export default ErrorIconNotification;