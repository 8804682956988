import React from 'react';
import { TextField, validators, TextAreaField } from 'components/Form';

const FormGeneralDetails = () => {
    return (
        <React.Fragment>
            <TextField name="name" label="Profile Name" validate={validators.validateRequired} />
            <TextAreaField name="description" label="Description" />
        </React.Fragment>
    )
}

export default FormGeneralDetails;