import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { CI_CD_ENFORCEMENT_OPTIONS, CD_PIPELINE_FINDINGS_RISKS, SECRET_FINDINGS_RISKS } from 'utils/systemConsts';
import { FETCH_METHODS, usePrevious, useFetch } from 'hooks';
import FormWrapper, { validators, TextField, SelectField, TextAreaField, useFormikContext, MultiselectField } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';
import Loader from 'components/Loader';

import './cd-scan-policy-form.scss';

const ENFORCEMENT_REQUIRED_ERROR = "You must select one of the enforcement options";
const EMPTY_VULNERABILITY_ITEM = {permissibleVulnerabilityLevel: "", enforcementOption: ""};
const EMPTY_API_POLICY_ITEM = {apiSecurityProfile: "", enforcementOption: ""};

const VulnerabilityField = ({parentFieldName, vulnerabilityFieldLabel, tooltipText, riskItems=Object.values(CD_PIPELINE_FINDINGS_RISKS)}) => {
    const {values, setFieldValue} = useFormikContext();
    const {permissibleVulnerabilityLevel} = values[parentFieldName];
    const prevPermissibleVulnerabilityLevel = usePrevious(permissibleVulnerabilityLevel);

    useEffect(() => {
        if (permissibleVulnerabilityLevel === prevPermissibleVulnerabilityLevel) {
            return;
        }

        if (permissibleVulnerabilityLevel === "") {
            setFieldValue(`${parentFieldName}.enforcementOption`, "");
        }
    }, [permissibleVulnerabilityLevel, prevPermissibleVulnerabilityLevel, setFieldValue, parentFieldName]);

    return (
        <div className="cd-policy-item-container">
            <SelectField 
                name={`${parentFieldName}.permissibleVulnerabilityLevel`}
                label={vulnerabilityFieldLabel}
                items={riskItems}
                clearable={true}
                tooltipText={tooltipText}
            />
            <SelectField 
                name={`${parentFieldName}.enforcementOption`}
                label="Enforcement Option"
                items={Object.values(CI_CD_ENFORCEMENT_OPTIONS)}
                clearable={true}
                disabled={!permissibleVulnerabilityLevel}
            />
        </div>
    );
}

const FormFields = ({showApiSecurity, apiSecurityPolicyItems, deployersItems}) => {
    const {values, setFieldValue} = useFormikContext();
    const {apiSecurityProfile} = values.apiSecurityCdPolicy;
    const prevApiSecurityProfile = usePrevious(apiSecurityProfile);

    useEffect(() => {
        if (apiSecurityProfile === prevApiSecurityProfile) {
            return;
        }

        if (apiSecurityProfile === "") {
            setFieldValue("apiSecurityCdPolicy.enforcementOption", "");
        }
    }, [apiSecurityProfile, prevApiSecurityProfile, setFieldValue]);
    
    return (
        <React.Fragment>
            <TextField name="name" label="Name" component={TextField} validate={validators.validateRequired} />
            <TextAreaField name="description" label="Description" />
            <MultiselectField
                name="deployers"
                label="Deployers"
                items={deployersItems}
                validate={validators.validateRequired}
            />
            <VulnerabilityField
                parentFieldName="permissionCDPolicy"
                vulnerabilityFieldLabel="Permissive Permission Risk Level"
                tooltipText="The specified level is permitted, enforce on riskier levels"
            />
            <VulnerabilityField
                parentFieldName="securityContextCDPolicy"
                vulnerabilityFieldLabel="Permissive Security Context Risk Level"
                tooltipText="The specified level is permitted, enforce on riskier levels"
            />
            <VulnerabilityField
                parentFieldName="secretCDPolicy"
                vulnerabilityFieldLabel="Permissive Secret Risk Level"
                tooltipText="The specified level is permitted, enforce on riskier levels"
                riskItems={Object.values(SECRET_FINDINGS_RISKS)}
            />
            {showApiSecurity &&
                <div className="cd-policy-item-container">
                    <SelectField 
                        name="apiSecurityCdPolicy.apiSecurityProfile"
                        label="API Security Policy"
                        items={apiSecurityPolicyItems}
                        clearable={true}
                    />
                    <SelectField 
                        name="apiSecurityCdPolicy.enforcementOption"
                        label="Enforcement Option"
                        items={Object.values(CI_CD_ENFORCEMENT_OPTIONS)}
                        clearable={true}
                        disabled={!apiSecurityProfile}
                    />
                </div>
            }
        </React.Fragment>
    )
}

const CdScanPolicyForm = ({initialData, onFormSubmitSuccess, onDirtyChanage, apiPolicyData}) => {
    const {showApiSecurity} = useDisplayConfigState();

    const [{loading, data: deployersData}] = useFetch("deployers");

    if (loading) {
        return <Loader />;
    }

    const initialValues = {
        name: "",
        description: "",
        deployers: [],
        ...initialData
    };

    initialValues.name = initialValues.name || ""; //name should not be null
    initialValues.description = initialValues.description || ""; //description should not be null
    initialValues.deployers = initialValues.deployers || []; //deployers should not be null

    initialValues.permissionCDPolicy = !isEmpty(initialValues.permissionCDPolicy) ? initialValues.permissionCDPolicy : {...EMPTY_VULNERABILITY_ITEM};
    initialValues.securityContextCDPolicy = !isEmpty(initialValues.securityContextCDPolicy) ? initialValues.securityContextCDPolicy : {...EMPTY_VULNERABILITY_ITEM};
    initialValues.secretCDPolicy = !isEmpty(initialValues.secretCDPolicy) ? initialValues.secretCDPolicy : {...EMPTY_VULNERABILITY_ITEM};
    initialValues.apiSecurityCdPolicy = !isEmpty(initialValues.apiSecurityCdPolicy) ? initialValues.apiSecurityCdPolicy : {...EMPTY_API_POLICY_ITEM};

    const apiSecurityPolicyItems = isEmpty(apiPolicyData) ? [] : apiPolicyData.map(({name, id}) => ({value: id, label: name}));
    const apiPolicyExists = apiSecurityPolicyItems.map(item => item.value).includes(initialValues.apiSecurityCdPolicy.apiSecurityProfile);
    
    if (!apiPolicyExists) {
        initialValues.apiSecurityCdPolicy = {...EMPTY_API_POLICY_ITEM};
    }

    const isEditForm = initialValues && initialValues.id;
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{`${isEditForm ? "Edit" : "New"} Deployment Scan`}</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl="cdPolicy"
                validate={formValues => {
                    const formErrors = {};

                    const {permissionCDPolicy, securityContextCDPolicy, secretCDPolicy, apiSecurityCdPolicy} = formValues;

                    if (!permissionCDPolicy.permissibleVulnerabilityLevel && !securityContextCDPolicy.permissibleVulnerabilityLevel &&
                            !secretCDPolicy.permissibleVulnerabilityLevel && !apiSecurityCdPolicy.apiSecurityProfile) {
                        formErrors.permissionCDPolicy = {permissibleVulnerabilityLevel: ENFORCEMENT_REQUIRED_ERROR};
                        formErrors.securityContextCDPolicy = {permissibleVulnerabilityLevel: ENFORCEMENT_REQUIRED_ERROR};
                        formErrors.secretCDPolicy = {permissibleVulnerabilityLevel: ENFORCEMENT_REQUIRED_ERROR};
                    }
                    
                    if (!!permissionCDPolicy.permissibleVulnerabilityLevel && !permissionCDPolicy.enforcementOption) {
                        formErrors.permissionCDPolicy = {enforcementOption: validators.ERROR_MSG_IS_REQUIRED};
                    }

                    if (!!securityContextCDPolicy.permissibleVulnerabilityLevel && !securityContextCDPolicy.enforcementOption) {
                        formErrors.securityContextCDPolicy = {enforcementOption: validators.ERROR_MSG_IS_REQUIRED};
                    }

                    if (!!secretCDPolicy.permissibleVulnerabilityLevel && !secretCDPolicy.enforcementOption) {
                        formErrors.secretCDPolicy = {enforcementOption: validators.ERROR_MSG_IS_REQUIRED};
                    }

                    if (!!apiSecurityCdPolicy.apiSecurityProfile && !apiSecurityCdPolicy.enforcementOption) {
                        formErrors.apiSecurityCdPolicy = {enforcementOption: validators.ERROR_MSG_IS_REQUIRED};
                    }

                    return formErrors;
                }}
                getSubmitParams={formValues => {
                    const {id, ...submitData} = formValues;

                    const {permissionCDPolicy, securityContextCDPolicy, secretCDPolicy, apiSecurityCdPolicy} = submitData;
    
                    if (!permissionCDPolicy.permissibleVulnerabilityLevel) {
                        delete submitData.permissionCDPolicy;
                    }

                    if (!securityContextCDPolicy.permissibleVulnerabilityLevel) {
                        delete submitData.securityContextCDPolicy;
                    }

                    if (!secretCDPolicy.permissibleVulnerabilityLevel) {
                        delete submitData.secretCDPolicy;
                    }

                    if (!apiSecurityCdPolicy.apiSecurityProfile || !showApiSecurity) {
                        delete submitData.apiSecurityCdPolicy;
                    }

                    return !isEditForm ? {submitData} : {
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <FormFields
                    showApiSecurity={showApiSecurity}
                    apiSecurityPolicyItems={apiSecurityPolicyItems}
                    deployersItems={deployersData.map(item => ({value: item.id, label: item.deployer}))}
                />
            </FormWrapper>
        </div>
    )
}

export default CdScanPolicyForm;