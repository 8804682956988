import React from 'react';
import { ToggleField, useFormikContext, DurationField } from 'components/Form';

const MIN_DURATION = 1;
const MAX_DURATION = 999;

const truncateOldWithSettingsConnection = ({truncationName, title}) => {
    return () => {
        const {values} = useFormikContext();
        const truncationValues = values[truncationName];
        
        return (
            <div className="truncate-form-fields">
                <div className="truncate-toggle-field">
                    <ToggleField name={`${truncationName}.isTruncationEnabled`} isDark />
                </div>
                <div className="truncate-value-field">
                    <DurationField
                        name={`${truncationName}.truncateTimeInDays`}
                        min={MIN_DURATION}
                        max={MAX_DURATION}
                        duration="days"
                        durationPrefix={`Automatically remove unused ${title} after`}
                        validate={value => (
                            value >= MIN_DURATION && value <= MAX_DURATION ? undefined : `Duration must be between ${MIN_DURATION} and ${MAX_DURATION} days`
                        )}
                        disabled={!truncationValues.isTruncationEnabled}
                    />
                </div>
            </div>
        )
    }
}

export default truncateOldWithSettingsConnection;