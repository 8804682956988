import { isEmpty } from 'lodash';

export const DEPLOYMENT_USER_RULE_TYPES = {
    INJECTION: "InjectionRuleType",
    VIOLATION: "ViolationRuleType"
}

export const getGroupToUpdateByRuleId = (userRules, ruleId) => {
    const groupIndex = userRules.findIndex(item => !!item.subItems.find(rule => rule.id === ruleId));
    const groupToUpdate = userRules[groupIndex];
    
    const ruleIndex = groupToUpdate.subItems.findIndex(rule => rule.id === ruleId);

    return {
        groupIndex,
        groupToUpdate,
        ruleIndex
    }
}

export const getUpdatedList = (listToUpdate, itemIndex, updatedItems) => {
    return [
        ...listToUpdate.slice(0, itemIndex),
        ...updatedItems,
        ...listToUpdate.slice(itemIndex + 1)
    ];
}

export const getUserRulesWithUpdatedItem = (userRules, ruleId, updatedData) => {
    const {groupIndex, groupToUpdate, ruleIndex} = getGroupToUpdateByRuleId(userRules, ruleId);
    const updatedSubItems = getUpdatedList(groupToUpdate.subItems, ruleIndex, !!updatedData ? [updatedData]: []);

    return getUpdatedList(userRules, groupIndex, [{...groupToUpdate, subItems: updatedSubItems}]);
}

export const getUniqueGroupName = (userRules, groupName) => {
    const uiGroupNameExists = !!userRules.find(item => item.groupName === groupName);
        
    return uiGroupNameExists ? `${groupName}${Date.now()}` : groupName;
}

export const formatUserRulesForSubmit = userRules => {
    let formattedRules = [];

    userRules.forEach(({groupName, subItems, isActualGroup}) => {
        const formattedGroupName = isActualGroup ? groupName : null;
        const formattedItems= subItems.map(rule => ({...rule, groupName: formattedGroupName}));

        formattedRules = [...formattedRules, ...formattedItems];
    });
    
    return formattedRules;
}

export const formatUserRulesForDisplay = data => {
    const formattedData = [];

    data.forEach(item => {
        const isActualGroup = !!item.groupName;
        const uiGroupName = isActualGroup ? item.groupName : item.name;
        const formattedGroupItem = formattedData.find(item => item.groupName === uiGroupName);

        if (!!formattedGroupItem) {
            formattedGroupItem.subItems.push(item);
        } else {
            formattedData.push({groupName: uiGroupName, subItems: [item], isActualGroup})
        }
    });
    
    return formattedData;
}

export const scrollToFirstMarked = () => {
    const selectedPolicyItems = document.getElementsByClassName("policy-item-container selected");

    if (!isEmpty(selectedPolicyItems)) {
        selectedPolicyItems[0].scrollIntoView({behavior: "smooth"});
    }
}