import React from 'react';
import { useFetch } from 'hooks';
import { LabelsDisplay } from 'components/LabelTag';
import TableSimple from 'components/TableSimple';
import Loader from 'components/Loader';

import './package-licenses.scss';

const PackageLicenses = ({id, url}) => {
    const [{loading, data}] = useFetch(`${url}/${id}/packages`);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="package-licenses-wrapper">
            <TableSimple
                headers={["Name", "Licenses", "Version"]}
                rows={data.map(({name, licenses, version}) =>
                    ([
                        name,
                        <LabelsDisplay labels={licenses} wrapLabels />,
                        version
                    ]))}
                emptyText="No results"
            />
        </div>
    )
}

export default PackageLicenses;