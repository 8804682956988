import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'context/AuthProvider';
import { useAdminState, useAdminDispatch, assignAccount } from 'context/AdminProvider';
import { useNotificationDispatch, showNotification, NOTIFICATION_TYPES } from 'context/NotificationProvider';
import { useDisplayConfigDispatch, DISPLAY_CONFIG_ACTIONS } from 'context/DisplayConfigProvider';
import { usePrevious } from 'hooks';
import { DropdownSelectBese } from 'components/DropdownSelect';
import { ICON_NAMES } from 'components/Icon';
import { TOOLTIP_PLACEMENTS } from 'components/Tooltip';
import { asyncDataFetcher } from 'utils/apiUtils';
import { MAIN_GLOBAL_ADMIN_PAGE, DEFAULT_USER_PAGE, GLOBAL_ADMIN_URLS } from 'utils/systemConsts';

import './global-admin-select.scss';

const MAIN_GLOBAL_ADMIN_SELECT_ITEM = {value: -1, label: "Global Admin", icon: ICON_NAMES.STAR};

const getAccountItem = (id, name) => ({value: id, label: name, icon: ICON_NAMES.COMPANY});

const AsyncDropdownSelect = ({selected, setSelected}) => {
    const {accountId} = useAuthState();

    const notificationDispatch = useNotificationDispatch();
    const showErrorToaster = () => {
        showNotification(notificationDispatch, {
            message: "An error occurred when trying to load data",
            type: NOTIFICATION_TYPES.ERROR
        });
    }

    const formatData = (data) => {
        return [
            MAIN_GLOBAL_ADMIN_SELECT_ITEM,
            ...(data || []).filter(item => item.id !== accountId).map(item => getAccountItem(item.id, item.name))
        ]
    }

    const promiseOptions = inputValue => {
        return new Promise (resolve => asyncDataFetcher({
            url: "admin/accounts",
            queryParams: {
                name: inputValue,
                offset: 0,
                maxResults: 10
            },
            successCallback: (data) => {
                resolve(formatData(data));
            },
            errorCallback: () => {
                showErrorToaster();

                resolve([]);
            },
            authenticationErrorCallback: () => {
                showErrorToaster();

                resolve([]);
            }
        }))
    }

    return (
        <div className="global-admin-select-container">
            <DropdownSelectBese
                selectComponent={AsyncSelect}
                value={selected}
                defaultOptions
                loadOptions={promiseOptions}
                onChange={newSelected => {
                    if (newSelected.value === selected.value) {
                        return null;
                    }
                    
                    setSelected({
                        assignedAccountId: newSelected.value,
                        assignedAccountName: newSelected.label
                    })
                }}
                selectedValueTooltipId="global-admin-account-select"
                selectedValueTooltipPlacement={TOOLTIP_PLACEMENTS.LEFT}
            />
        </div>
    )
}

const GlobalAdminSelectWrapper = () => {
    const history = useHistory();

    const displayConfigDispatch = useDisplayConfigDispatch();

    const {accountId, ...accountState} = useAuthState();

    const {isLoading, assignedAccountId, assignedAccountName, ...assignedAccountState} = useAdminState();
    const prevAssignedAccountId = usePrevious(assignedAccountId);

    const adminDispatch = useAdminDispatch();

    const onSelect = ({assignedAccountId, assignedAccountName}) => {
        assignAccount(adminDispatch, {
            assignedAccountId: assignedAccountId === MAIN_GLOBAL_ADMIN_SELECT_ITEM.value ? accountId : assignedAccountId,
            assignedAccountName
        });
    }

    useEffect(() => {
        if (!isLoading && prevAssignedAccountId !== assignedAccountId) {
            const isGlobalAdminPage = GLOBAL_ADMIN_URLS.includes(history.location.pathname);

            if (!assignedAccountId) {
                if (!isGlobalAdminPage) {
                    history.push(MAIN_GLOBAL_ADMIN_PAGE);
                }
            } else if (isGlobalAdminPage || (!!prevAssignedAccountId && !!assignedAccountId)) {
                history.push(DEFAULT_USER_PAGE);
            }
        }
    }, [isLoading, prevAssignedAccountId, assignedAccountId, history]);

    useEffect(() => {
        if (!isLoading && assignedAccountId !== prevAssignedAccountId) {
            const {apiSecurity, accountTier, permissionsMode, serverlessSecurity, usageStatus} = accountState;

            const {apiSecurity: apiSecurityAssigned, serverlessSecurity: serverlessSecurityAssigned, accountTier: accountTierAssigned,
                permissionsMode: permissionsModeAssigned, usageStatus: usageStatusAssigned} = assignedAccountState;

            displayConfigDispatch({
                type: DISPLAY_CONFIG_ACTIONS.SET_DISPLAY_CONFIG,
                payload: !assignedAccountId ? {apiSecurity, accountTier, permissionsMode, serverlessSecurity, usageStatus} :
                    {
                        apiSecurity: apiSecurityAssigned,
                        accountTier: accountTierAssigned,
                        permissionsMode: permissionsModeAssigned,
                        serverlessSecurity: serverlessSecurityAssigned,
                        usageStatus: usageStatusAssigned
                    }
            })
        }
    }, [isLoading, assignedAccountId, prevAssignedAccountId, accountState, assignedAccountState, displayConfigDispatch]);

    if (isLoading) {
        return null;
    }

    const selected = !assignedAccountId ? MAIN_GLOBAL_ADMIN_SELECT_ITEM : getAccountItem(assignedAccountId, assignedAccountName);

    return (
        <AsyncDropdownSelect
            selected={selected}
            setSelected={onSelect}
        />
    )
}

export default GlobalAdminSelectWrapper;