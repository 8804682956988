import React from 'react';
import classnames from 'classnames';

export const DEFAULT_RULE_NAME = "Default rule";

export const DEFAULT_RULE_TYPES = {
    ALLOW_ALL: "ALLOW_ALL",
    DENY_ALL: "DENY_ALL",
    ENVIRONMENT_ONLY: "ENVIRONMENT_ONLY"
}

export const DEFAULT_RULE_DENY_TYPES = [
    DEFAULT_RULE_TYPES.DENY_ALL,
    DEFAULT_RULE_TYPES.ENVIRONMENT_ONLY
];

export const getRuleValueByType = (type, isBlocked) => {
    if (type === DEFAULT_RULE_TYPES.ALLOW_ALL) {
        return (
            <span><span className="relationship allowed">All communications</span> between workloads are allowed unless specifically denied by a previous rule</span>
        );
    }

    if (type === DEFAULT_RULE_TYPES.DENY_ALL) {
        return (
            <span><span className={classnames("relationship", {blocked: isBlocked})}>No communication</span> between workloads is permitted unless specifically allowed in a previous rule</span>
        );
    }

    return (
        <span>All communications between workloads running on <span className="relationship allowed">the same environment</span> are allowed unless specifically denied by a previous rule. Communication between apps running on <span className={classnames("relationship", {blocked: isBlocked})}>different environments is denied</span> unless specifically allowed by previous rule.</span>
    );
}