import React from 'react';
import { useMountMultiFetch } from 'hooks';
import DropdownSelect from 'components/DropdownSelect';
import Advisor, { ContentItemDisplay, ADVISOR_TYEPS } from 'layout/Advisor';

const RECOMMENDED_RULES_ACTION_ITEMS = [
    {value: "DETECT", label: "Detect Kubernetes APIs invoked on the specified environments", labelShort: "Detect"},
    {value: "BLOCK", label: "Block Kubernetes APIs from being invoked on the specified environments", labelShort: "Block"}
];

const IGNORE_RULE_ACTION_ITEM = {value: "IGNORE", label: "Ignore events by user and resource", labelShort: "Ignore"};

const isRecommendedRule = type => type === "KubernetesApiRecommendedRule"; //UPDATE!!

const AdvisorActionSelector = ({data, onItemEdit}) => (
    <div className="suggestion-action-content" style={{marginBottom: "15px"}}>
        <div className="suggestion-content-title">Action</div>
        <div className="suggestion-content-label">Select action</div>
        <DropdownSelect
            items={isRecommendedRule(data.kubernetesApiRuleType) ? RECOMMENDED_RULES_ACTION_ITEMS : [IGNORE_RULE_ACTION_ITEM]}
            defaultselected={data.action}
            name="action"
            onChange={action => onItemEdit({...data, action})}
            className="action-select"
        />
    </div>
);

const AdvisorHeaderActionSelector = ({data, onItemEdit}) => {
    const isRecommended = isRecommendedRule(data.kubernetesApiRuleType);
    const actionItems = isRecommended ? RECOMMENDED_RULES_ACTION_ITEMS : [IGNORE_RULE_ACTION_ITEM];

    return (
        <div className="suggestion-action-header" style={{width: "105px"}}>
            <DropdownSelect
                items={actionItems.map(({value, labelShort}) => ({value, label: labelShort}))}
                defaultselected={data.action}
                name="action"
                onChange={action => onItemEdit({...data, action})}
                className="action-select"
                disabled={!isRecommended}
            />
        </div>
    );
}

const AdvisorWorkloadDisplay = ({data, recommendedRules}) => {
    const {kubernetesApiRuleType, recommendedRuleId, users, resources} = data;

    if (isRecommendedRule(kubernetesApiRuleType)) {
        const recommendedRuleItem = recommendedRules.find(item => item.id === recommendedRuleId);
        const ruleDescription = !!recommendedRuleItem ? recommendedRuleItem.description : null;
    
        return (
            <div style={{fontSize: "11px", marginBottom: "10px"}}>{ruleDescription}</div>
        )
    }

    const userName = users[0].userName;
    const resourceKind = resources[0].kind;

    return (
        <div style={{display: "flex"}}>
            <ContentItemDisplay
                data={data}
                title="User"
                displayData={[
                    {
                        title: userName,
                        valueKey: "users",
                        isList: true,
                        formatListItem: ({userType}) => userType
                    }
                ]}
            />
            <ContentItemDisplay
                data={data}
                title="Resource"
                displayData={[
                    {title: resourceKind, valueKey: "resources", isList: true, formatListItem: ({group}) => group}
                ]}
            />
        </div>
    ) 
}

const formatData = ({data, clusters}) => {
    return data.map(item => ({
        recommendations: item.clusterEventRuleRecommendations.map(recommendation => {
            const {id, clusterEventRule, numberOfAffectedEvents} = recommendation;

            return {
                id,
                affected: numberOfAffectedEvents,
                recommendation: clusterEventRule,
                customSubTitleComponent: ({data}) => {
                    const {kubernetesApiRuleType, ruleScope} = data;

                    if (isRecommendedRule(kubernetesApiRuleType)) {
                        const {clusters: clustersIds} = ruleScope;
                        const clusterItem = clusters.find(item => item.id === clustersIds[0]);
                        const clusterName = !!clusterItem ? clusterItem.name : null;

                        return (
                            <div className="affected-title">
                                By Cluster <span style={{fontWeight: "normal"}}>{clusterName}</span>
                            </div>
                        )
                    }

                    return null;
                }
            }
        }),
        total: item.totalEvents || 0,
        timePeriod: item.timePeriod
    }));
}

const KubernetesAdvisor = ({onAddSuggestions, initialExpanded, isReadOnly}) => {
    const [{loading, data, error}] = useMountMultiFetch([
        {key: "clusters", url: "leanKubernetesClusters"},
        {key: "recommendedRules", url: "kubernetesApiPolicy/recommendedRules"}
    ]);
    
    if (loading || error) {
        return null;
    }

    const {clusters, recommendedRules} = data;

    return (
        <Advisor
            emptyMessage="No rules suggestions"
            applyTitle="Apply Rule"
            buttonTitle="Policy Advisor"
            advisorType={ADVISOR_TYEPS.API_RULES}
            onItemsAdd={onAddSuggestions}
            url="advisor/clusterEventRules"
            title="events"
            formatData={data => formatData({data, clusters})}
            contentDisplayItems={[
                AdvisorActionSelector,
                props => <AdvisorWorkloadDisplay {...props} recommendedRules={recommendedRules} />
            ]}
            headerDisplayItem={AdvisorHeaderActionSelector}
            initialExpanded={initialExpanded}
            hideStatsForReccomendation={data => isRecommendedRule(data.kubernetesApiRuleType)}
            isReadOnly={isReadOnly}
        />
    )
}

export default KubernetesAdvisor;


