import React from 'react';
import { useParams } from 'react-router-dom';
import { isNull } from 'lodash';
import { useFetch } from 'hooks';
import DetailsTabbedPage from 'components/DetailsTabbedPage';
import TabDetails from './TabDetails';
import TabVulnerabilities from './TabVulnerabilities';
import TabSecrets from './TabSecrets';
import TabRolePolicies from './TabRolePolicies';

const SERVERLESS_FUNCTION_TABS = {
    DETAILS_AND_RISK: "DETAILS",
    VULNERABILITIES: "VULNERABILITIES",
    SECRETS: "SECRETS",
    ROLE_POLICIES: "ROLE_POLICIES"
}

const FunctionDetails = () => {
    const params = useParams();
    const {id} = params;

    const [{loading, data, error}] = useFetch(`serverless/functions/${id}`);

    const {name, roleDetails} = data || {};
    const {policies} = roleDetails || {};

    return (
        <DetailsTabbedPage
            loading={loading}
            error={error}
            id={id}
            title={name}
            backTitle="Functions"
            tabItems={[
                {id: SERVERLESS_FUNCTION_TABS.DETAILS_AND_RISK, title: "Details & Risks", component: () => <TabDetails data={data} />},
                {id: SERVERLESS_FUNCTION_TABS.VULNERABILITIES, title: "Vulnerabilities", component: () => <TabVulnerabilities functionId={id} />},
                {
                    id: SERVERLESS_FUNCTION_TABS.ROLE_POLICIES,
                    title: "Permissions",
                    component: () => <TabRolePolicies data={data} />,
                    disabled: isNull(policies)
                },
                {id: SERVERLESS_FUNCTION_TABS.SECRETS, title: "Secrets", component: () => <TabSecrets functionId={id} />}
            ]}
            defaultSelectedTab={SERVERLESS_FUNCTION_TABS.DETAILS_AND_RISK}
        />
    )
}

export default FunctionDetails;