import React from 'react';
import { isEmpty } from 'lodash';
import { useFetch } from 'hooks';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import DisplaySection from 'components/DisplaySection';
import KeyValueList from 'components/KeyValueList';
import DoublePaneDisplay from 'components/DoublePaneDisplay';
import Loader from 'components/Loader';
import { formatDate } from 'utils/generalUtils';
import { EMPTY_VALUE } from 'utils/systemConsts';
import { CONNECTIONS_POLICY_URL } from 'layout/Policies/ConnectionsPolicy';
import { TokenNameLink } from 'layout/Runtime/utils';
import RuleNamesLinks from 'layout/Policies/RuleNamesLinks';
import RuleActionsDisplay from 'layout/Runtime/RuleActionsDisplay';
import WorkloadDetails from './WorkloadDetails';

import './tab-details.scss';

const ConnectionInfo = ({state, violation, apiTokens, showApiSecurity}) => {
    const {startTime, lastSeen, count} = state;
    const {defaultRule, directPodRule, encryptRule, userRule, lastViolation, encryptionReason} = violation;

    const [{loading, data: tokensInfo}] = useFetch("tokens/info", {loadOnMount: showApiSecurity && !isEmpty(apiTokens), queryParams: {tokensIds: apiTokens}});
    
    if (loading) {
        return <Loader absolute={false} />;
    }

    return (
        <React.Fragment>
            <KeyValueList items={[
                {label: "Start Time", value: formatDate(startTime)},
                {label: "Last Seen", value: formatDate(lastSeen)},
                {label: "No. Connections", value: count}
            ]} />
            <KeyValueList items={[
                {
                    label: "Rule Names",
                    value: (
                        <RuleNamesLinks
                            pathname={CONNECTIONS_POLICY_URL}
                            defaultRule={defaultRule}
                            implicitRule={directPodRule}
                            userRule={userRule}
                            encryptRule={encryptRule}
                        />
                    )
                },
                {
                    label: "Rule Action",
                    value: (
                        <RuleActionsDisplay
                        tooltipId="connection-modal"
                        defaultRule={defaultRule}
                        implicitRule={directPodRule}
                        userRule={userRule}
                        encryptRule={encryptRule}
                        encryptionReason={encryptionReason}
                    />
                    )
                },
                {label: "Last Violation", value: lastViolation || EMPTY_VALUE}
            ]} />
            {showApiSecurity &&
                <KeyValueList items={[
                    {
                        label: "Tokens",
                        value: (
                            isEmpty(tokensInfo) ? "N/A" : tokensInfo.map(({id, name, isDeleted}) => <div key={id}><TokenNameLink isDeleted={isDeleted} name={name} /></div>)
                        )
                    }
                ]} />
            }
        </React.Fragment>
    )
}

const TabDetails = ({data}) => {
    const {source, target, state, violation, apiTokens} = data;

    const {showApiSecurity, loadingDisplay} = useDisplayConfigState();

    if (loadingDisplay) {
        return <Loader />;
    }

    return (
        <DoublePaneDisplay
            className="connection-details-tab-wrapper"
            leftPane={() => (
                <React.Fragment>
                    <DisplaySection title="Source">
                        <WorkloadDetails {...source} />
                    </DisplaySection>
                    <DisplaySection title="Destination">
                        <WorkloadDetails {...target} />
                    </DisplaySection>
                </React.Fragment>
            )}
            rightPane={() => (
                <DisplaySection title="Connections">
                    <ConnectionInfo state={state} violation={violation} apiTokens={apiTokens} showApiSecurity={showApiSecurity} />
                </DisplaySection>
            )}
        />
    )
}

export default TabDetails;