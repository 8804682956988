import React, { useState, useEffect } from 'react';
import { isNull, isEmpty } from 'lodash';
import { useFetch, useDelete, usePrevious } from 'hooks';
import CloseButton from 'components/CloseButton';
import IconWithTitle from 'components/IconWithTitle';
import IconWithTooltip from 'components/IconWithTooltip';
import { useAuthState } from 'context/AuthProvider';
import { ICON_NAMES } from 'components/Icon';
import Loader from 'components/Loader';
import FormModal from 'components/FormModal';
import SeccompForm from '../SeccompForm';
import SeccompProfileModal, { AssignedDisplay } from './SeccompProfileModal';

import './seccomp-profiles.scss';

const SeccompProfiles = ({onClose}) => {
    const [openProfileData, setOpenProfileData] = useState(null);
    const closeOpenProfile = () => setOpenProfileData(null);
    const {isReadOnlyUser} = useAuthState();

    const [formProfileData, setFormProfileData] = useState(null);
    const closeformModal = () => setFormProfileData(null);

    const [{loading, data}, fetchProfiles] = useFetch("seccompProfiles");

    const [{deleting, error: deleteError}, deleteProfile] = useDelete("seccompProfiles");
    const prevDeleting = usePrevious(deleting);

    useEffect(() => {
        if (prevDeleting && !deleting && isNull(deleteError)) {
            closeOpenProfile();
            fetchProfiles();
        }
    }, [prevDeleting, deleting, deleteError, fetchProfiles]);

    return (
        <div className="seccomp-proriles-container">
            {loading || deleting ? <Loader /> : 
                <React.Fragment>
                    <div className="seccomp-proriles-header">
                        <CloseButton onClick={onClose} />
                        <IconWithTitle
                            className="new-seccomp-button"
                            name={ICON_NAMES.ADD}
                            disabled={isReadOnlyUser}
                            title="New Seccomp profile"
                            onClick={() => setFormProfileData({})}
                        />
                    </div>
                    <div className="seccomp-proriles-content">
                        {
                            data.map(item => {
                                const {id, name, data, podSecurityPolicies} = item;
                                const copyTooltipId = `seccomp-item-copy-${id}`;
                                const editTooltipId = `seccomp-item-edit-${id}`;
                                const deleteTooltipId = `seccomp-item-delete-${id}`;
                                const isAssigned = !isEmpty(podSecurityPolicies);

                                return (
                                    <div key={id} className="seccomp-policy-item" onClick={() => setOpenProfileData(item)}>
                                        <div className="item-actions">
                                            <IconWithTooltip
                                                name={ICON_NAMES.DUPLICATE}
                                                onClick={event => {
                                                    event.preventDefault();
                                                    event.stopPropagation();

                                                    setFormProfileData({data});
                                                }}
                                                tooltipId={copyTooltipId}
                                                tooltipText="Duplicate"
                                            />
                                            <IconWithTooltip
                                                name={ICON_NAMES.EDIT}
                                                onClick={event => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    
                                                    setFormProfileData({...item});
                                                }}
                                                tooltipId={editTooltipId}
                                                tooltipText="Edit"
                                            />
                                            <IconWithTooltip
                                                name={ICON_NAMES.DELETE}
                                                onClick={event => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    
                                                    deleteProfile(id);
                                                }}
                                                tooltipId={deleteTooltipId}
                                                tooltipText={isAssigned ? "Profile in use" : "Delete"}
                                                disabled={isAssigned}
                                            />
                                        </div>
                                        <div className="title">{name}</div>
                                        {isAssigned && <AssignedDisplay podSecurityPolicies={podSecurityPolicies} />}
                                    </div>
                                )
                            })
                        }
                    </div>
                    {!isNull(openProfileData) &&
                        <SeccompProfileModal
                            {...openProfileData}
                            onClose={closeOpenProfile}
                            onDelete={() => deleteProfile(openProfileData.id)}
                            onCopy={() => {
                                setFormProfileData({data: openProfileData.data});
                                closeOpenProfile();
                            }}
                            onEdit={() => {
                                setFormProfileData({...openProfileData});
                                closeOpenProfile();
                            }}
                        />}
                    {!isNull(formProfileData) &&
                        <FormModal
                            onClose={closeformModal}
                            formComponent={SeccompForm}
                            formProps={{
                                initialData: {...formProfileData},
                                onFormSubmitSuccess: () => {
                                    closeformModal();
                                    fetchProfiles();
                                }
                            }}
                        />
                    }
                </React.Fragment>
            }
        </div>
    )
}

export default SeccompProfiles;