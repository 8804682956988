import { ICON_NAMES } from 'components/Icon';

export const FINDINGS_TYPES_MAP = {
    PERMISSIONS: {
        value: "PERMISSIONS",
        label: "Permissions",
        icon: ICON_NAMES.PERMISSIONS
    },
    SECURITY_CONTEXT: {
        value: "SECURITY_CONTEXT",
        label: "Security Context",
        icon: ICON_NAMES.SECURITY_CONTEXT
    },
    SECRETS: {
        value: "SECRETS",
        label: "Secrets",
        icon: ICON_NAMES.LOCK
    }
}