import React from 'react';
import TabbedPageWithRouting from 'components/TabbedPageWithRouting';
import { APP_PATHS } from 'utils/systemConsts';
import CloudAccount, { SERVERLESS_CLOUD_ACCOUNT_PAGE_PATH } from './CloudAccount';
import Functions, { SERVERLESS_FUNCTIONS_PAGE_PATH } from './Functions';

const Serverless = () => (
    <TabbedPageWithRouting
        redirectFrom={APP_PATHS.SERVERLESS.path}
        redirectTo={SERVERLESS_CLOUD_ACCOUNT_PAGE_PATH}
        items={[
            {to: SERVERLESS_CLOUD_ACCOUNT_PAGE_PATH, title: "Cloud Accounts", component: CloudAccount},
            {to: SERVERLESS_FUNCTIONS_PAGE_PATH, title: "Functions", component: Functions},
        ]}
    />
);

export default Serverless;