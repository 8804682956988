import React from 'react';
import TabbedPageWithRouting from 'components/TabbedPageWithRouting';
import Users, { USERS_PAGE_PATH } from '../Users';
import Integrations from './Integrations';
import Settings from './Settings';

const System = () => (
    <TabbedPageWithRouting
        className="system-page"
        items={[
            {to: "/system", exact: true, title: "Settings", component: Settings},
            {to: "/system/integrations", exact: false, title: "Integrations", component: Integrations},
            {to: USERS_PAGE_PATH, exact: false, title: "Manage Users", component: Users}
        ]}
    />
);

export default System;