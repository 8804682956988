import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useFetch, usePrevious } from 'hooks';
import { validators, MultiselectField, SelectField, useFormikContext } from 'components/Form';
import { valueToValueLabel } from 'utils/apiUtils';

const KafkaForm = ({endpoint, clusterNames, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();

    const clusterFieldName = `${endpoint}.clusterId`;
    const clusterId = get(values, clusterFieldName);

    const [{loading, data: brokers}, fetchClusterData] = useFetch("connectionsPolicy/kafka", {loadOnMount: false});
    const previousClusterId = usePrevious(clusterId);

    const brokerItems = valueToValueLabel(brokers || []);
    
    useEffect(() => {
        if (clusterId === previousClusterId) {
            return;
        }

        if (!!clusterId) {
            fetchClusterData({formatUrl: url => `${url}/${clusterId}/brokers`});
        }

        if (!!previousClusterId) {
            setFieldValue(`${endpoint}.brokers`, []);
        }
    }, [clusterId, previousClusterId, fetchClusterData, setFieldValue, endpoint]);
    
    return (
        <React.Fragment>
            <SelectField
                name={clusterFieldName}
                label="Cluster"
                items={clusterNames}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            <MultiselectField
                name={`${endpoint}.brokers`}
                label="Brokers"
                items={brokerItems}
                validate={validators.validateRequired}
                creatable={true}
                loading={loading}
                disabled={!clusterId || isReadOnlyUser}
            />
        </React.Fragment>
    );
}

export default KafkaForm;