import React from "react";

const LicenseText = () => (
    <div className="license-text-container">
        <p>
            THE TERMS OF THIS PARTNER TEST AGREEMENT (“AGREEMENT” or “PTA”) ARE BETWEEN YOU OR THE COMPANY YOU REPRESENT (“YOU” OR “PARTNER”) AND CISCO SYSTEMS, INC. (“CISCO”), AND GOVERN YOUR LIMITED RIGHT TO DOWNLOAD, INSTALL, AND/OR USE THE PRE-RELEASE CISCO TECHNOLOGY PROVIDED UNDER THIS AGREEMENT FOR YOUR INTERNAL DEMONSTRATION, TESTING, AND FEEDBACK PURPOSES ONLY.
        </p>
        <p style={{fontWeight: "bold"}}>
            IMPORTANT – PLEASE READ THE TERMS OF THIS AGREEMENT CAREFULLY BEFORE PROCEEDING. IF YOU DO NOT HAVE AUTHORITY TO ENTER INTO THIS AGREEMENT, OR YOU DO NOT AGREE WITH ITS TERMS, DO NOT USE THE CISCO TECHNOLOGY.
        </p>
        <p>
            BY CLICKING “I AGREE,” “ACCEPT,” OR OTHER SIMILAR BUTTON, OR BY DOWNLOADING, INSTALLING, ACCESSING, AND/OR USING THE CISCO TECHNOLOGY PROVIDED HEREUNDER, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOU ARE ENTERING INTO A LEGAL AGREEMENT WITH CISCO AND HAVE UNDERSTOOD AND AGREE TO COMPLY WITH, AND BE LEGALLY BOUND BY, THE TERMS AND CONDITIONS OF THIS AGREEMENT. DO NOT SELECT "I AGREE," OR INSTALL OR USE THE SOFTWARE OR ACCESS THE SERVICE, UNTIL YOU HAVE CAREFULLY READ, UNDERSTOOD, AND AGREED TO THESE TERMS. YOU HEREBY WAIVE ANY RIGHTS OR REQUIREMENTS UNDER ANY LAWS OR REGULATIONS IN ANY JURISDICTION WHICH REQUIRE AN ORIGINAL (NON-ELECTRONIC) SIGNATURE OR DELIVERY OR RETENTION OF NON-ELECTRONIC RECORDS, TO THE EXTENT PERMITTED UNDER APPLICABLE LAW. IF YOU HAVE ANY OTHER AGREEMENT IN PLACE WITH CISCO, THE TERMS OF THIS AGREEMENT SHALL PREVAIL WITH REGARDS TO ANY CISCO TECHNOLOGY PROVIDED TO YOU UNDER THIS AGREEMENT.
        </p>
        <ol>
            <li>
                <span style={{fontWeight: "bold"}}>DEFINITIONS.</span><br /><br />
                <ol>
                    <li>
                        “Cisco Technology” means the Cisco Software, Hardware, and/or Cloud Services, and all related Documentation, if any, provided by Cisco to Partner for the purposes of testing under this Agreement.
                    </li>
                    <li>
                        “Test Period” means the period of time specified by Cisco or agreed to by the parties in which Partner will test Cisco Technology and provide feedback to Cisco under this Agreement.
                    </li>
                    <li>
                        “Cloud Service” means the Cisco-hosted software-as-a-service offering or other Cisco cloud-enabled features provided by Cisco to Partner to be tested under this Agreement.
                    </li>
                    <li>
                        “Software” means the Cisco computer programs provided in machine-readable object code format and applicable Documentation provided by Cisco to Partner to be tested under this Agreement.
                    </li>
                    <li>
                        “Hardware” means the tangible equipment provided by Cisco to Partner to be tested under this Agreement.
                    </li>
                    <li>
                        “Documentation” means such supporting written materials as Cisco may in its discretion provide to Partner in connection with the use of a Cisco Technology.
                    </li>
                </ol>
            </li>
            <li>
                <span style={{fontWeight: "bold"}}>OBLIGATIONS OF PARTNER.</span><br /><br />
                <ol>
                    <li>
                        <span style={{textDecoration: "underline"}}>Testing.</span> During a Test Period, Partner agrees to run such test suites and other test programs as designated by Cisco and agreed to by the Partner. Partner also agrees to use such special and non-standard operating procedures as may be reasonably required by Cisco to accomplish testing of the Cisco Technology.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Restriction on Use.</span> Partner shall not use the Cisco Technology in any manner to operate or support its own products or business of any kind. In no event shall Partner use the Cisco Technology for the benefit of any third party or for any purpose other than for Partner’s internal purpose of testing and evaluating the Cisco Technology as specifically described in this Agreement.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Reporting.</span> Partner shall provide weekly written or oral reports to Cisco on all test and performance results of the Cisco Technology via phone, email, or other means, as requested by Cisco. Partner shall notify Cisco of any failure, error or other malfunction of any part of the Cisco Technology within twenty-four (24) hours of such occurrence.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Modifications.</span> Partner agrees to implement modifications or changes to the Cisco Technology during the Test Period as requested by Cisco, if any. Otherwise, Partner shall not alter or modify the Cisco Technology during the Test Period without Cisco’s prior written direction or approval. Partner hereby assigns to Cisco Partner’s entire right, title, and interest (including, without limitation, all patent rights, design rights, copyrights and trade secret rights) in any modifications or improvements to the Cisco Technology that Partner proposes or makes during the Test Period.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Access.</span> During the Test Period, Partner will grant Cisco such access to the Cisco Technology as Cisco may reasonably request.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Protection.</span> Partner shall keep the Cisco Technology in good condition and working order and insure the Cisco Technology against loss, theft, or damage to the extent of its full commercial value. If applicable, at the end of a Test Period, or upon Cisco’s request, Partner shall promptly return the Cisco Technology to Cisco.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Acknowledgement.</span> Partner acknowledges that the terms and conditions of this Agreement apply to all Cisco Technologies provided by Cisco to Partner for testing during the term of this Agreement. 
                    </li>
                </ol>
            </li>
            <li>
                <span style={{fontWeight: "bold"}}>OBLIGATIONS OF CISCO.</span><br /><br />
                <ol>
                    <li>
                        <span style={{textDecoration: "underline"}}>Delivery.</span> Cisco will deliver the Cisco Technology to Partner for testing as described in the applicable Addendum.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Technical Assistance.</span> At Cisco’s sole discretion, Cisco may provide Partner such technical assistance as Cisco may deem necessary to properly install, operate, and test the Cisco Technology at the Test Site. However, under no circumstances will Cisco be obligated to provide support or technical assistance under this Agreement.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Modifications.</span> During the Test Period, Cisco will consult with Partner regarding the performance of the Cisco Technology and will evaluate the test data and error reports provided by Partner. Cisco will provide to Partner at no cost such modifications, enhancements, and improvements to the Cisco Technology as deemed appropriate by Cisco, provided, however, Cisco is not obligated to make any modifications, enhancements, or improvements.
                    </li>
                    <li>
                        The Parties anticipate that multiple Cisco Technologies may be tested by Partner from time to time under this Agreement, provided however, that Cisco is under no obligation to make Cisco Technologies available to Partner for testing.
                    </li>
                </ol>
            </li>
            <li>
                <span style={{fontWeight: "bold"}}>DISCLAIMER OF WARRANTY.</span><br /><br />
                <div className="list-item">
                    ALL CISCO TECHNOLOGY PROVIDED UNDER THIS AGREEMENT IS PROVIDED “AS IS” AND WITHOUT SUPPORT OF ANY KIND. TO THE EXTENT PERMITTED BY LAW, CISCO DISCLAIMS ALL WARRANTIES IN CONNECTION WITH SUCH CISCO TECHNOLOGY, INCLUDING MODIFICATIONS AND IMPROVEMENTS THERETO, WHETHER EXPRESS, IMPLIED, OR STATUTORY. CISCO SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE, OR ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE.
                </div>
            </li>
            <li>
                <span style={{fontWeight: "bold"}}>OWNERSHIP AND USE.</span><br />
                <ol>
                    <li>
                        <span style={{textDecoration: "underline"}}>Ownership.</span> Partner acknowledges that the Cisco Technology is temporarily provided to Partner for testing purposes only and that Cisco retains ownership of all right, title, and interest to the Cisco Technology, the Cisco Technology design and Documentation, and the intellectual property rights therein and thereto (including without limitation, all patent rights, design rights, copyrights, and trade secret rights).  Partner agrees not to (i) alter, modify, copy, or in any way reverse engineer, decompile, or disassemble the Cisco Technology or its design, make derivative works based upon the Cisco Technology, or use the Cisco Technology to develop any products, or (ii) sell, license, rent, or transfer the Cisco Technology to any third party.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Permitted Use.</span> Cisco grants to Partner a personal, non-transferable, non-exclusive (a) license (without right to sublicense) to use Software and (b) right to use Cloud Services during the Test Period, solely for Partner’s internal purpose of testing and evaluating the Cisco Technology at the Partner’s Site. Any Software delivered on, in, or as part of Hardware may only be used with the same Hardware with which it is delivered, and no copies of Software shall be made except for one (1) archival copy.
                    </li>
                    <li>
                        <span style={{textDecoration: "underline"}}>Restricted Rights.</span> For U.S. Government Partners: Software and Documentation, when delivered under a contract with a Department of Defense entity, is commercial computer software and commercial computer software documentation, and is provided with the commercial rights and restrictions described herein for non-governmental customers. Such Software (including documentation thereof), when delivered under a contract with other U.S. Government entities, is "RESTRICTED RIGHTS SOFTWARE" pursuant to FAR 52.227-14(g)(3) (Jun 1987), if such clause is included in this contract, and otherwise is "RESTRICTED COMPUTER SOFTWARE" subject to the restrictions set forth in FAR 52.227-19(c) (Jun 1987), which are incorporated herein by reference. Use, reproduction, or disclosure is subject to the restrictions set forth in either the Agreement under which this documentation has been provided or, alternatively, Cisco’s written software license that is provided with Cisco equipment or software.
                    </li>
                </ol>
            </li>
            <li>
                <span style={{fontWeight: "bold"}}>TERM &#38; TERMINATION.</span><br /><br />
                <ol>
                    <li>
                        This Agreement shall begin on the date of Partner’s acceptance and shall continue for two (2) years or until terminated in accordance with the provisions herein.
                    </li>
                    <li>
                        This Agreement may be terminated by either party upon thirty (30) days written notice to the other party.
                    </li>
                    <li>
                        Notwithstanding the foregoing, this Agreement may be terminated immediately by Cisco in the event of Partner’s breach of Section 5, Ownership and Use, or Section 9, Confidentiality.
                    </li>
                    <li>
                        Upon termination of this Agreement, Partner shall immediately cease use of Cisco Technology (including Software, Hardware, and Cloud Services) and shall, to the extent applicable, at its expense, return to Cisco or, at Cisco's discretion, destroy all Proprietary Information and data (including all copies thereof) then in Partner's possession or custody or control, and certify in writing as to such action.
                    </li>
                </ol>
            </li>
            <li>
                <span style={{fontWeight: "bold"}}>LIMITATION OF LIABILITY.</span><br /><br />
                <div className="list-item">
                    Liability under this Agreement is limited to claims arising from (a) Partner’s use of Cisco Technology outside the scope of or in breach of the terms of this Agreement and (b) either party’s breach of Section 8.0 (“Confidentiality”).
                </div>
                <br />
                <div className="list-item">
                    IN NO EVENT SHALL CISCO BE LIABLE TO PARTNER FOR ANY DAMAGES RELATED TO PARTNER’S USE OF CISCO TECHNOLOGY UNDER THIS AGREEMENT, INCLUDING: INDIRECT, SPECIAL, OR CONSEQUENTIAL DAMAGES; LOSS OR DILUTION OF BUSINESS, SALES, OPPORTUNITIES, REVENUES, PROFITS, REPUTATION, OR GOODWILL; LOSS OR CORRUPTION OF DATA; BUSINESS OR SERVICE INTERRUPTION, DISRUPTION, OR DOWNTIME; ANY OTHER DIRECT OR INDIRECT DAMAGES, EVEN IF CISCO HAS BEEN INFORMED OF THE POSSIBILITY THEREOF.
                </div>
            </li>
            <li>
                <span style={{fontWeight: "bold"}}>CONFIDENTIALITY.</span><br /><br />
                <div className="list-item">
                    Partner acknowledges that, in the course of using the Cisco Technology and performing its duties under this Agreement, it may obtain information relating to the Cisco Technology and to Cisco which is of a confidential and proprietary nature ("Proprietary Information"). Such Proprietary Information may include, but is not limited to, trade secrets, know how, invention techniques, processes, programs, schematics, software source documents, data, customer lists, financial information, and sales and marketing plans or information which Partner knows or has reason to know is confidential, proprietary, or trade secret information of Cisco. Partner shall at all times, both during the term of this Agreement and for a period of at least three (3) years after its termination, keep in trust and confidence all such Proprietary Information, and shall not use such Proprietary Information other than as expressly authorized by Cisco under this Agreement, nor shall Partner disclose any such Proprietary Information to third parties without Cisco’s written consent. The obligations of confidentiality shall not apply to information which (i) has entered the public domain except where such entry is the result of Partner’s breach of this Agreement; (ii) prior to disclosure hereunder was already in Partner's possession; or (iii) subsequent to disclosure hereunder is obtained by Partner on a non-confidential basis from a third party who has the right to disclose such information to the Partner. Neither Party shall disclose, advertise, or publish the terms and conditions of this Agreement without the prior written consent of the other Party. Any press release or publication regarding this Agreement is subject to prior review and written approval of the Parties.
                </div>
            </li>
            <li>
                <span style={{fontWeight: "bold"}}>GENERAL.</span><br /><br />
                <ol>
                    <li>
                        The validity, interpretation, and performance of this Agreement shall be controlled by and construed under the laws of the State of California, United States of America, as of performed wholly within the state and without giving effect to the principles of conflict of law.  The parties specifically disclaim the UN Convention on Contracts for the International Sale of Goods.
                    </li>
                    <li>
                        No waiver of rights under this Agreement by either party shall constitute a subsequent waiver of this or any other right under this Agreement. All claims must be brought within twelve (12) months following the date such claim arose.
                    </li>
                    <li>
                        Partner shall not assign, in any manner, its right, obligation or interest in or under this Agreement without the prior written consent of Cisco.
                    </li>
                    <li>
                        Partner hereby acknowledges that the Cisco Technology supplied by Cisco under this Agreement is subject to export or import controls under the laws and regulations of the United States (U.S.). Partner shall comply with such laws and regulations.
                    </li>
                    <li>
                        In the event of a breach, the breaching party will pay to the other party any reasonable attorneys' fees and other costs and expenses incurred by such other party in connection with the enforcement of any provisions of this Agreement.
                    </li>
                    <li>
                        Any notice delivered by Cisco to Partner under this Agreement will be delivered via email, regular mail, or postings on Cisco.com. Notices to Cisco should be sent to Cisco Systems, Office of General Counsel, 170 Tasman Drive, San Jose, CA 95134, unless otherwise agreed to in writing by Cisco.
                    </li>
                    <li>
                        Neither party has the right or authority to, and shall not, assume or create any obligation of any nature whatsoever on behalf of the other party or bind the other party in any respect whatsoever.
                    </li>
                    <li>
                        This Agreement shall bind and inure to the benefit of the successors and permitted assigns of the parties.
                    </li>
                    <li>
                        This Agreement is the complete agreement between the parties hereto concerning the subject matter of this Agreement and replaces any prior oral or written communications between the parties. There are no conditions, understandings, agreements, representations, or warranties, expressed or implied, which are not specified herein. This Agreement may only be modified by a written document executed by the parties hereto.
                    </li>
                </ol>
            </li>
        </ol>
        <p style={{fontWeight: "bold"}}>
        BY CLICKING “I AGREE,” “ACCEPT,” OR OTHER SIMILAR BUTTON, OR BY DOWNLOADING, INSTALLING, ACCESSING, AND/OR USING THE CISCO TECHNOLOGY PROVIDED HEREUNDER, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ THE TERMS OF THIS AGREEMET, UNDERSTAND THE RIGHTS, OBLIGATIONS, AND CONDITIONS SET FORTH HEREIN, AGREE TO BE BOUND BY THEM, AND CONFIRM THAT I HAVE READ AND AGREE TO THE <a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html" target="_blank" rel="noopener noreferrer">CISCO ONLINE PRIVACY STATEMENT</a>.    
        </p>
    </div>
);

export default LicenseText;