import React from 'react';
import classnames from 'classnames';
import Icon, { ICON_NAMES } from 'components/Icon';
import Tooltip from 'components/Tooltip';

import './token-injection-status.scss';

export const INJECTION_STATUSES = {
    NO_INJECTION: "NO_INJECTION",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR"
};

const TokenInjectionStatus = ({status, message, id, withTooltip=true}) => {
    const tooltipId = `token-injection-status-${id}`;

    const isSuccess = status === INJECTION_STATUSES.SUCCESS;
    const isError = status === INJECTION_STATUSES.ERROR;

    return (
        <div className="token-injection-status-wrapper">
            <div className="status-icon-wrapper" data-tip data-for={tooltipId}>
                <Icon
                    name={isSuccess ? ICON_NAMES.SUCCESS : (isError ? ICON_NAMES.ALERT : ICON_NAMES.STROKE)}
                    className={classnames("status-icon", {success: isSuccess}, {error: isError})}
                />
            </div>
            {isError && withTooltip && <Tooltip id={tooltipId} text="An error occurred while trying to inject tokens" placement="top" />}
            {!!message && <div className="injection-message-wrapper">{message}</div>}
        </div>
    )
}

export default TokenInjectionStatus;