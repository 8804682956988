import React from 'react';
import { useFetch } from 'hooks';
import Loader from 'components/Loader';
import { TextField, MultiselectCheckboxField } from 'components/Form';
import { tableUtils, FilterFormWrapper } from 'components/Table';

const FilterForm = ({filters, setFilters}) => {
    const [{loading, data, error}] = useFetch("auditLogs/actions");

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const actions = data.map(item => ({value: item, label: item}));

    const initialValues = {
        ...filters,
        actions: tableUtils.getFilterItemsWithUndefined(actions, {valuesOnly: true, withUndefined: false}),
        objectType: "",
        user: "",
        filterLoading: false
    };
    
    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={setFilters}
        >
            <MultiselectCheckboxField
                name="actions"
                label="Action"
                items={tableUtils.getFilterItemsWithUndefined(actions, {withUndefined: false})}
            />
            <TextField name="user" label="User" />
            <TextField name="objectType" label="Object Type" />
        </FilterFormWrapper>
    )
};

export default FilterForm;