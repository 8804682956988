import React from 'react';
import { isEmpty, isUndefined } from 'lodash';
import Icon, { ICON_NAMES } from 'components/Icon';
import { TooltipWrapper } from 'components/Tooltip';

import COLORS from 'utils/scss_variables.module.scss';

export const RESULTS_ITEMS = {
    PASS: {value: "PASS", label: "Pass", color: COLORS["color-success"], icon: ICON_NAMES.SUCCESS_CIS},
    INFO: {value: "INFO", label: "Info", color: COLORS["color-main-light"], icon: ICON_NAMES.INFO_CIS},
    FAIL: {value: "FAIL", label: "Fail", color: COLORS["color-error"], icon: ICON_NAMES.ALERT_CIS, isViolation: true},
    WARN: {value: "WARN", label: "Action items", color: COLORS["color-warning"], icon: ICON_NAMES.ALERT_CIS, isViolation: true}
}

export const StatusIcon = ({status, count, disabled, tooltipText, tooltipId}) => {
    const {color, icon} = RESULTS_ITEMS[status] || {};
    
    const AlertIcon = () => (
        <div style={{display: "flex", alignItems: "center", color: COLORS["color-text-white"]}}>
            <Icon name={icon} style={{color: disabled ? COLORS["color-disabled"] : color}} />
            {!isUndefined(count) &&
                <div style={{
                    marginLeft: "5px",
                    width: "20px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: disabled ? COLORS["color-disabled"] : undefined
                }}>{count || 0}</div>}
        </div>
    )

    return (
        !!tooltipText ? <TooltipWrapper text={tooltipText} id={tooltipId}><AlertIcon /></TooltipWrapper> : <AlertIcon />
    )
}

export const ActionItemsStatusIcon = ({resultsFilter, count, tooltipId, tooltipText}) => (
    <StatusIcon
        status={RESULTS_ITEMS.WARN.value}
        disabled={!isEmpty(resultsFilter) && !resultsFilter.includes(RESULTS_ITEMS.WARN.value)}
        count={count}
        tooltipId={tooltipId}
        tooltipText={tooltipText}
    />
)

export const ScanResultsCount = ({passedCount, failedCount, infoCount, resultsFilter, tooltipId}) => (
    <div style={{display: "flex", gap: "10px", color: COLORS["color-text-white"]}}>
        {
            [
                {status: RESULTS_ITEMS.PASS, count: passedCount},
                {status: RESULTS_ITEMS.FAIL, count: failedCount},
                {status: RESULTS_ITEMS.INFO, count: infoCount}
            ].map(({status: {value, label}, count}) => (
                <TooltipWrapper key={value} id={`scan-result-${tooltipId}-${value}`} text={`${count} ${label}`}>
                    <StatusIcon
                        status={value}
                        count={count}
                        disabled={!isEmpty(resultsFilter) && !resultsFilter.includes(value)}
                    />
                </TooltipWrapper>
            ))
        }
    </div>
)