import React from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import Icon, { ICON_NAMES } from 'components/Icon';
import InnerTitle from '../InnerTitle';

const StatusIcon = ({success}) => (
    <Icon name={success ? ICON_NAMES.IMAGE_APPROVED : ICON_NAMES.ALERT} className={classnames("identification-status-icon", {success})} />
)

const IdentificationSection = ({title, isIdentified, children}) => (
    <div className="identification-content-item">
        <InnerTitle>{title}</InnerTitle>
        <div className="identification-content-item-wrapper">
            <StatusIcon success={isIdentified} />
            <div>{children}</div>
        </div>
    </div>
);

const SidecarSectionDisplay = ({sidecarItems}) => {
    if (isEmpty(sidecarItems)) {
        return (
            <IdentificationSection title="Sidecars" isIdentified={true}>
                All injected sidecars were allowed
            </IdentificationSection>
        )
    }

    return (
        <div className="sidecar-items-display-wrapper">
            <InnerTitle>Sidecars</InnerTitle>
            {
                sidecarItems.map(({name, isAllowed}, index) => {
                    return (
                        <div key={index} className="sidecar-item-container">
                            <StatusIcon success={isAllowed} />
                            <div className="sidecar-name">{`Unidentified sidecar (${name})`}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const IdentificationContent = ({cdSection, ciSection, injectedSidecarSection, registrySection}) => (
    <div className="identification-content-wrapper">
        <IdentificationSection title="CI" isIdentified={ciSection.isIdentified}>
            {ciSection.isIdentified ? "All workload images were identified" : "The workload has unidentified images"}
        </IdentificationSection>
        <IdentificationSection title="CD" isIdentified={cdSection.isIdentified}>
            {cdSection.isIdentified ? "The workload was identified" : "The workload was not identified by the CD plugin"}
        </IdentificationSection>
        <IdentificationSection title="Registry" isIdentified={registrySection.isIdentified}>
            {`The image registry is ${registrySection.isIdentified ? "trusted" : "untrusted"}`}
        </IdentificationSection>
        <SidecarSectionDisplay sidecarItems={injectedSidecarSection.injectedSidecars} />
    </div>
)

export default IdentificationContent;