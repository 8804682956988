import React from 'react';
import CiPolicy from './CiPolicy';
import CdPolicy from './CdPolicy';

import './ci-cd-scan-policy.scss';

export const CI_CD_POLICY_URL = "/policies/cicdpolicy";

const CiCdScanPolicy = () => (
    <div className="ci-cd-policies-container">
        <CiPolicy />
        <CdPolicy />
    </div>
)

export default CiCdScanPolicy;