import React, { useState, useEffect } from 'react';
import { isNull, isEmpty } from 'lodash';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import { useDelete, useFetch, usePrevious } from 'hooks';
import Table from 'components/Table';
import IconWithTooltip from 'components/IconWithTooltip';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import FormModal from 'components/FormModal';
import Loader from 'components/Loader';
import ContainerTitle from 'components/ContainerTitle';

import './table-settings.scss';

const TableSettings = ({columns, url, itemTitle, containerTitle, tableName, formComponent: FormComponent, formatFetchedData, showServerError}) => {
    const {isReadOnlyUser} = useAuthState();

    const [selectedItemData, setSelectedItemData] = useState(null);
    const closeSelectedItemModal = () => setSelectedItemData(null);

    const [{deleting}, deleteItem] = useDelete(url, {showServerError});
    const prevDeleting = usePrevious(deleting);

    const [{loading, data}, fetchTableData] = useFetch(url);

    const tableData = !!formatFetchedData ? formatFetchedData(data) : data;
    const isEmptyTable = isEmpty(tableData);

    useEffect(() => {
        if (prevDeleting && !deleting) {
            fetchTableData();
        }
    }, [prevDeleting, deleting, fetchTableData]);

    return (
        <div className="table-settings-container">
            <ContainerTitle>{containerTitle}</ContainerTitle>
            {!loading &&
                <div className={classnames("add-icon-wrapper", {"with-border": isEmptyTable})}>
                    <IconWithTitle
                        name={ICON_NAMES.ADD}
                        title={itemTitle}
                        className="add-new-button"
                        onClick={() => setSelectedItemData({})}
                        disabled={isReadOnlyUser || loading || deleting}
                    />
                </div>
            }
            {loading && <Loader absolute={false} />}
            {!isEmptyTable && <Table
                data={tableData}
                name={tableName}
                columns={columns}
                actionsComponent={({original}) => {
                    const {id} = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;

                    return (
                        <div className="settings-row-actions">
                            <IconWithTooltip
                                name={ICON_NAMES.EDIT}
                                onClick={() => setSelectedItemData(original)}
                                tooltipId={editTooltipId}
                                tooltipText="Edit"
                                disabled={isReadOnlyUser}
                            />
                            <IconWithTooltip
                                name={ICON_NAMES.DELETE}
                                onClick={() => deleteItem(original.id)}
                                tooltipId={deleteTooltipId}
                                tooltipText="Delete"
                                disabled={isReadOnlyUser}
                            />
                        </div>
                    );
                }}
                actionsCellWidth={80}
                withPagination={false}
                isLoading={deleting}
                hideColumnControl={true}
            />}
            {!isNull(selectedItemData) &&
                <FormModal
                    onClose={closeSelectedItemModal}
                    formComponent={FormComponent}
                    formProps={{
                        initialData: {...selectedItemData},
                        onFormSubmitSuccess: () => {
                            closeSelectedItemModal();
                            fetchTableData();
                        }
                    }}
                />
            }
        </div>
    )
}

export default TableSettings;