import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { RULE_ACTIONS, RULE_STATUSES, IGNORE_ACTION_ITEM } from 'utils/systemConsts';
import NoUserRulesItem from '../../NoUserRulesItem';
import BaseRuleItem from '../../BaseRuleItem';
import { DEPLOYMENT_USER_RULE_TYPES } from '../../utils';
import DrapAndDropGroupedList from './DrapAndDropGroupedList';

import './base-grouped-rules-list.scss';

const BaseGroupedRulesList = props => {
    const {userRules, isReadOnly, topPanelRuleComponent: TopPanelRuleComponent, markedIds,
        bottomPanelRuleComponent: BottomPanelRuleComponent, policyTypeName, onRuleAdd, onRuleCopy,
        onRuleEdit, onReorder, onGroupNameChange, onGroupStatusToggle, onGroupDelete, onRuleDelete,
        onRemoveFromGroup, onRuleToggleStatus, userRuleContentComponent: UserRuleContentComponent,
        namesOfCloseGroups, setNamesOfCloseGroups, toggleGroupOpenInternal, disableViewRule} = props;
        
    return (
        <div className="policy-rules">
            {!!TopPanelRuleComponent &&
                <div className="top-panel-rules">
                    <TopPanelRuleComponent />
                    </div>}
            <div className="main-panel-rules">
                {isEmpty(userRules) ? 
                    <NoUserRulesItem
                        onClick={onRuleAdd}
                        disabled={isReadOnly}
                    /> :
                    <DrapAndDropGroupedList
                        policyTypeName={policyTypeName}
                        items={userRules}
                        disabled={isReadOnly}
                        onReorder={onReorder}
                        onGroupNameChange={onGroupNameChange}
                        onGroupStatusToggle={onGroupStatusToggle}
                        onGroupDelete={onGroupDelete}
                        namesOfCloseGroups={namesOfCloseGroups}
                        setNamesOfCloseGroups={setNamesOfCloseGroups}
                        toggleGroupOpenInternal={toggleGroupOpenInternal}
                        itemComponent={(props) => {
                            const {id, status, ruleTypeProperties, isDragged} = props;
                            let {action} = props;
                            let ruleType = null;
                            
                            if (!!ruleTypeProperties) {
                                action = ruleTypeProperties.action;
                                ruleType = ruleTypeProperties.ruleType;
                            }

                            const enabled = status === RULE_STATUSES.ENABLED;
                            const disabled = status === RULE_STATUSES.DISABLED;
                            const blocked = action === RULE_ACTIONS.BLOCK;
                            const encrypted = action === RULE_ACTIONS.ENCRYPT ||
                                action === RULE_ACTIONS.ENCRYPT_DIRECT;
                            const allowed = action === RULE_ACTIONS.ALLOW;
                            const ignored = action === IGNORE_ACTION_ITEM.value;
                            const injection = ruleType === DEPLOYMENT_USER_RULE_TYPES.INJECTION;
                            
                            return (
                                <BaseRuleItem 
                                    {...props}
                                    onCopy={() => onRuleCopy(props)}
                                    onEdit={() => onRuleEdit(props)}
                                    onDelete={() => onRuleDelete(id)}
                                    onToggleStatus={() => onRuleToggleStatus(id)}
                                    onDoubleClick={() => onRuleEdit(props)}
                                    onRemoveFromGroup={() => onRemoveFromGroup(id)}
                                    contentRenderer={UserRuleContentComponent}
                                    className={classnames(
                                        {selected: !!markedIds && markedIds.includes(id)},
                                        {"is-dragged": isDragged}
                                    )}
                                    enabled={enabled}
                                    disabled={disabled}
                                    blocked={blocked}
                                    allowed={allowed}
                                    encrypted={encrypted}
                                    ignored={ignored}
                                    injection={injection}
                                    isReadOnly={isReadOnly}
                                    disableViewRule={disableViewRule}
                                />
                            );
                        }}
                    />
                }
            </div>
            {!!BottomPanelRuleComponent &&
                <div className="bottom-panel-rules">
                    <BottomPanelRuleComponent />
                </div>}
        </div>
    );
};

export default BaseGroupedRulesList;