import React from 'react';
import { TextField } from 'components/Form';
import { FilterFormWrapper } from 'components/Table';

export const FILTER_KEYS = {
    ZIP_NAME: "zipNameFilter",
    ZIP_HASH: "zipSha256Filter"
}

export const initialData = {
    [FILTER_KEYS.ZIP_NAME]: "",
    [FILTER_KEYS.ZIP_HASH]: ""
}

const FilterForm = ({filters, queryFilters={}, setFilters}) => {
    const initialValues = {
       ...initialData,
       ...filters,
       ...queryFilters
    };

    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={setFilters}
        >
            <TextField name={FILTER_KEYS.ZIP_NAME} label="Name" />
            <TextField name={FILTER_KEYS.ZIP_HASH} label="sha256" />
        </FilterFormWrapper>
    )
};

export default FilterForm;