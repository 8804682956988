import React from 'react';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { useAccountParamsState } from 'context/AccountParamsProvider';
import InstructionsItem from 'components/InstructionsItem';
import Text, { TEXT_TYPES } from 'components/Text';
import TabbedPageNoRouting from 'components/TabbedPageNoRouting';
import Modal from 'components/Modal';
import Button from 'components/Button';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import { CLUSTER_ORCHESTRATION_TYPES, PRODUCTION_ADDRESS, APPLICATION_NAME } from 'utils/systemConsts';
import { getProtectedLabel } from 'layout/Runtime/Namespaces/utils';
import { UninstallInstructions } from './UninstallData';

export const INSTALLATION_TYPES = {
    SCRIPT: "SCRIPT",
    HELM: "HELM"
}

const InstalledIstioText = () => (
    <React.Fragment>
        <InstructionsItem
            title="Make sure that Istio with the selected version is installed and running on your cluster"
        />
        <InstructionsItem
            title="Add the following to Istio ConfigMap under data.mesh.defaultConfig.proxyMetadata (e.g. kubectl -n istio-system edit configmaps istio):"
            inputText="ISTIO_META_INSECURE_STACKDRIVER_ENDPOINT: portshift-agent.portshift:24227"
            withBullet={false}
            isInnerTitle
        />
    </React.Fragment>
);

const KubernetesInstructions = ({ isMultiCluster, id, name }) => (
    <React.Fragment>
        <InstructionsItem
            title={
                <a href={`${window.location.origin}/api/kubernetesClusters/${id}/download_bundle`} download>
                    <IconWithTitle name={ICON_NAMES.DOWNLOAD} title="Download installer" />
                </a>
            }
        />
        <InstructionsItem
            title="Extract the package:"
            inputText={`tar -xzvf ${name}.tar.gz`}
            withBullet={false}
            isInnerTitle
        />
        <InstructionsItem
            title="Run the extracted installation script:"
            inputText={`./install_bundle.sh ${isMultiCluster ? "-c <path to ca credentials folder>" : ""}`}
            withBullet={false}
            isInnerTitle
        />
    </React.Fragment>
);

const InstallationTab = ({ id, istioInstallationParameters, isMultiCluster, name, enableConnectionsControl, installTracingSupport, isYaml }) => {
    const { showAccountConnections, showApiSecurity } = useDisplayConfigState();
    const protectNamespacesLabel = getProtectedLabel({
        protect: true,
        connectionsEnabled: showAccountConnections && enableConnectionsControl,
        apiEnabled: showApiSecurity && installTracingSupport
    });

    return (
        <div className="install-data-container">
            {isYaml &&
                <>
                    <InstructionsItem title="If our controller is already installed, please uninstall the previous version first:" />
                    <UninstallInstructions isInnerTitle={true} isYaml={isYaml} />
                </>
            }
            {istioInstallationParameters.isIstioAlreadyInstalled && <InstalledIstioText />}
            <InstructionsItem title="Add SecureApplication-protected label to all relevant namespaces" />
            <InstructionsItem
                className="single-namespace"
                title="Single namespace:"
                inputText={`kubectl label namespace <name> ${protectNamespacesLabel} --overwrite`}
                withBullet={false}
                isInnerTitle
            />
            <InstructionsItem
                className="all-namespaces"
                title="All namespaces:"
                inputText={`kubectl label namespace $(kubectl get namespaces | awk '{print$1}' | grep -v -e NAME -e kube-public -e kube-system -e istio-system -e portshift) ${protectNamespacesLabel} --overwrite`}
                withBullet={false}
                isInnerTitle
            />
            <KubernetesInstructions isMultiCluster={isMultiCluster} id={id} name={name} />
        </div>
    );
}

const PrerequisitesTab = ({ orchestrationType, installTracingSupport }) => {
    const { minK8sVersion } = useAccountParamsState();

    const isOpenShift = orchestrationType === CLUSTER_ORCHESTRATION_TYPES.OPENSHIFT.value;

    return (
        <React.Fragment>
            <InstructionsItem title="Helm v3.8.0 or later" />
            <InstructionsItem title={isOpenShift ? "Openshift 4.1+" : `Kubernetes ${minK8sVersion} or later`} />
            {installTracingSupport && <InstructionsItem title="10GB of disk space" />}
            <InstructionsItem
                title="Cluster should have at least three nodes (for full feature set, aggregate of 10vCPU and 15GB RAM across all nodes)"
            />
            <InstructionsItem title="K8s CLI (kubectl) should be installed on the machine or VM from which the deployment is run, with admin permissions to the cluster" />
            <InstructionsItem title="Enable DNS resolution and external access to these domains, on port 443:" />
            <InstructionsItem title={`${APPLICATION_NAME} platform: ${PRODUCTION_ADDRESS.DOMAIN} (${PRODUCTION_ADDRESS.IP})`} isInnerTitle />
            <InstructionsItem title="GCP Container Registry (if not using internal registry): http://gcr.io/eticloud/k8sec" isInnerTitle />
        </React.Fragment>
    );
}

const InstallDataModal = (props) => {
    const { onClose } = props;

    return (
        <Modal className="install-modal" center={true} onClose={onClose}>
            <div className="install-cluster-container">
                <Text type={TEXT_TYPES.TITLE_MEDIUM} className="install-title" withTopMargin>Installation Info</Text>
                <TabbedPageNoRouting
                    className="install-cluster-content"
                    embedded={true}
                    items={[
                        {
                            id: "install",
                            title: "Install",
                            component: () => <InstallationTab {...props} />
                        },
                        {
                            id: "prerequisites",
                            title: "Prerequisites",
                            component: () => <PrerequisitesTab {...props} />
                        }
                    ]}
                />
            </div>
            <Button onClick={onClose}>Done</Button>
        </Modal>
    );
}

export default InstallDataModal;

