import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetch, FETCH_METHODS, usePrevious } from "hooks";
import { isEmpty } from 'lodash';
import BackRouteButton from "components/BackRouteButton";
import { ViolationsFilterField } from 'components/Form';
import { FilterFormWrapper } from 'components/Table';
import Text, { TEXT_TYPES } from "components/Text";
import { ICON_NAMES } from "components/Icon";
import IconWithTitle from "components/IconWithTitle";
import { APP_PATHS } from "utils/systemConsts";
import { RESULTS_ITEMS } from '../utils';
import ClusterDetailsSummery from "./ClusterDetailsSummery";
import ClusterTestsTable from "./ClusterTestsTable";

import COLORS from 'utils/scss_variables.module.scss';

const ClusterDetails = () => {
	const params = useParams();
	const { clusterId } = params;

	const url = `k8sCISBenchmark/${clusterId}`;

	const [{ loading, data }, fetchData] = useFetch(url);
	const prevLoading = usePrevious(loading);

	const [{ loading: loadingUpdate }, updateData] = useFetch(url, { loadOnMount: false });
	const prevLoadingUpdate = usePrevious(loadingUpdate);

	const [expandedIndex, setExpandedIndex] = useState(null);
	const { entries, incompleteTests, nodesWithErrors, ...summary} = data || {};
	
	const [summaryData, setSummaryData] = useState(null);
	
	const updateTests = (index, selectedNodes, status) => {
		setExpandedIndex(index);
		updateData({
			method: FETCH_METHODS.PUT,
			submitData: {
				clusterId,
				index,
				status,
				nodes: selectedNodes.map((x) => ({ id: x })),
			},
		});
	};

	const [filters, setFilters] = useState({result: []});

	useEffect(() => {
		if (prevLoading &&  !loading) {
			setSummaryData(summary);
		}
	}, [prevLoading, loading, summary])

	useEffect(() => {
		if (prevLoadingUpdate && !loadingUpdate) {
			fetchData();
		}
	}, [prevLoadingUpdate, loadingUpdate, fetchData]);

	return (
		<div className="cis-inner-page-content">
			<BackRouteButton
				title={APP_PATHS.CIS_BENCHMARK.title}
				path={APP_PATHS.CIS_BENCHMARK.path}
			/>
			{!!summaryData &&
				<>
					<Text type={TEXT_TYPES.TITLE_MEDIUM}>{summaryData.name}</Text>
					<ClusterDetailsSummery url={url} clusterData={summaryData} updateTable={fetchData} />
					<FilterFormWrapper initialValues={filters} onChange={setFilters}>
						<ViolationsFilterField
							name="result"
							label="Violations only"
							items={Object.values(RESULTS_ITEMS)}
						/>
					</FilterFormWrapper>
					<div style={{marginTop: "15px"}}>
					{incompleteTests && !isEmpty(entries) &&
						<IconWithTitle
							name={ICON_NAMES.EXCLAMATION_MARK_ROUND}
							title="Some of the tests results are not displayed. Check the orchestration link for more info."
							style={{color: COLORS["color-error"]}}
						/>
					}
					{isEmpty(entries) && !isEmpty(nodesWithErrors) &&
						<>
							{
								nodesWithErrors.map(({ip, error}) => (
									<div>
										<IconWithTitle
											name={ICON_NAMES.EXCLAMATION_MARK_ROUND}
											title={`Error on ${ip}: ${error}`}
											style={{color: COLORS["color-error"]}}
										/>
									</div>
								))
							}
						</>
					}
					</div>
				</>
			}
			<ClusterTestsTable
				entries={entries}
				loading={loading}
				expandedIndex={expandedIndex}
				onChange={updateTests}
				resultsFilter={filters.result}
			/>
		</div>
	);
};

export default ClusterDetails;