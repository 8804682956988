import React from 'react';
import { DurationField } from 'components/Form';

const MIN_DURATION = 10;
const MAX_DURATION = 3600;

const AgentDuration = () => {
    return (
        <div className="agent-duration-form-fields">
            <div className="agent-duration-title">Define telemetry frequency for reporting duration</div>
            <DurationField
                name="globalSettings.agentSendTelemetriesIntervalInSeconds"
                min={MIN_DURATION}
                max={MAX_DURATION}
                duration="Seconds"
                validate={value => (
                    value >= MIN_DURATION && value <= MAX_DURATION ? undefined : `Duration must be between ${MIN_DURATION} and ${MAX_DURATION} seconds`
                )}
            />
        </div>
    )
}

export default AgentDuration;