import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import TabbedPageNoRouting from 'components/TabbedPageNoRouting';
import TabbedPageWithRouting from 'components/TabbedPageWithRouting';
import DetailsPageContentWrapper from 'components/DetailsPageContentWrapper';

import './tabbed-page-section.scss';

const TabbedPageSection = ({items, defaultSelected, menual, onChange, withUrlRouting}) => (
    <DetailsPageContentWrapper className="tabbed-page-section-wrapper">
        {withUrlRouting ? <BrowserRouter><TabbedPageWithRouting items={items} customCheckHideTabs={() => false} /></BrowserRouter> :
            <TabbedPageNoRouting
                items={items}
                embedded={true}
                defaultSelected={defaultSelected}
                menual={menual}
                onChange={onChange}
            />
        }
    </DetailsPageContentWrapper>
)

export default TabbedPageSection;