import React, { useEffect } from 'react';
import { useAuthState } from 'context/AuthProvider';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { usePrevious } from 'hooks';
import FormWrapper, { validators, TextField, RadioField, useFormikContext } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';
import { DEFAULT_RULE_NAME, DEFAULT_RULE_ACTIONS, getRuleValueByType } from './utils';

const FormFields = ({isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {type} = values;
    const prevType = usePrevious(type);

    useEffect(() => {
        if (type === prevType) {
            return;
        }

        if (prevType === DEFAULT_RULE_ACTIONS.ALLOW_ALL) {
            setFieldValue("denyType", RULE_ACTIONS.DETECT);
        }
    }, [prevType, type, setFieldValue]);

    return (
        <React.Fragment>
            <TextField name="name" label="Rule Name" disabled />
            <RadioField
                name="type"
                label="Type"
                items={[
                    {
                        value: DEFAULT_RULE_ACTIONS.ALLOW_ALL,
                        label: getRuleValueByType(DEFAULT_RULE_ACTIONS.ALLOW_ALL)
                    },
                    {
                        value: DEFAULT_RULE_ACTIONS.DETECT_ALL,
                        label: getRuleValueByType(DEFAULT_RULE_ACTIONS.DETECT_ALL)
                    }
                ]}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {type === DEFAULT_RULE_ACTIONS.DETECT_ALL &&
                <RadioField
                    name="denyType"
                    label="On Detection"
                    horizontal={true}
                    items={[
                        {value: RULE_ACTIONS.DETECT, label: "Detect", tooltip: "Allow but alert on the violation"},
                        {value: RULE_ACTIONS.BLOCK, label: "Block", tooltip: "Block the violation"}
                    ]}
                    disabled={isReadOnlyUser}
                />
            }
        </React.Fragment>
    );
}

const FormDefaultRule = ({initialData, title, onDone, onDirtyChanage}) => {
    const {isReadOnlyUser} = useAuthState();

    const initialValues = {
        name: DEFAULT_RULE_NAME,
        type: "",
        denyType: "",
        ...initialData
    };

    if (initialValues.type === DEFAULT_RULE_ACTIONS.BLOCK_ALL) {
        initialValues.denyType = RULE_ACTIONS.BLOCK;
        initialValues.type = DEFAULT_RULE_ACTIONS.DETECT_ALL;
    } else if (initialValues.type === DEFAULT_RULE_ACTIONS.DETECT_ALL) {
        initialValues.denyType = RULE_ACTIONS.DETECT;
    }
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{title}</Text>
            <FormWrapper
                initialValues={initialValues}
                onDirtyChanage={onDirtyChanage}
                disableSubmitButton={isReadOnlyUser}
                doCustomSubmit={formValues => {
                    const {type, denyType} = formValues;
            
                    const isBlock = type === DEFAULT_RULE_ACTIONS.DETECT_ALL && denyType === RULE_ACTIONS.BLOCK;

                    onDone(isBlock ? DEFAULT_RULE_ACTIONS.BLOCK_ALL : type);
                }}
            >
                <FormFields isReadOnlyUser={isReadOnlyUser} />
            </FormWrapper>
        </div>
    )
}

export default FormDefaultRule;