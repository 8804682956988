import React from 'react';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import TabbedPageWithRouting from 'components/TabbedPageWithRouting';
import Loader from 'components/Loader';
import DeploymentPolicy, { DEPLOYMENT_POLICY_URL } from './DeploymentPolicy';
import ConnectionsPolicy, { CONNECTIONS_POLICY_URL } from './ConnectionsPolicy';
import KubernetesPolicy, { EVENTS_POLICY_URL } from './KubernetesPolicy';
import PodPolicies from './PodPolicies';
import CiCdScanPolicy, { CI_CD_POLICY_URL } from './CiCdScanPolicy';
import LinkToRule from './LinkToRule';
import ServerlessPolicy, { SERVERLESS_POLICY_URL } from './ServerlessPolicy';

export {
    LinkToRule
}

const Policies = () => {
    const {loadingDisplay, showAccountConnections, showServerlessSecurity} = useDisplayConfigState();

    const tabItems = [
        {to: DEPLOYMENT_POLICY_URL, exact: true, title: "Deployment Rules", component: DeploymentPolicy},
        {
            to: CONNECTIONS_POLICY_URL,
            exact: false,
            title: "Connection Rules",
            component: ConnectionsPolicy,
            disabled: !showAccountConnections
        },
        {to: EVENTS_POLICY_URL, exact: false, title: "Cluster Events Rules", component: KubernetesPolicy},
        {to: "/policies/pspp", exact: false, title: "Pod Standards", component: PodPolicies},
        {to: CI_CD_POLICY_URL, exact: false, title: "CI/CD Policy", component: CiCdScanPolicy}
    ];

    if (showServerlessSecurity) {
        tabItems.push({to: SERVERLESS_POLICY_URL, exact: false, title: "Serverless Rules", component: ServerlessPolicy});
    }

    if (loadingDisplay) {
        return <Loader />;
    }

    return (
        <TabbedPageWithRouting
            className="policies-page"
            items={tabItems}
        />
    );
}

export default Policies;