import React from 'react';
import classnames from 'classnames';
import Text, { TEXT_TYPES } from 'components/Text';

import './checkbox.scss';

const Checkbox = ({checked, name, value, title, onChange, className, halfSelected, disabled, small=false}) => (
    <div className="ps-checkbox-wrapper">
        <label className={classnames("ps-checkbox", {[className]: className}, {disabled}, {"with-title": !!title})}>
            <div className="inner-checkbox-wrapper">
                <input type="checkbox" value={value} name={name} checked={checked} onChange={event => disabled ? null : onChange(event)} />
                <span className={classnames("checkmark", {"half-selected": halfSelected})}></span>
            </div>
            <Text type={small ? TEXT_TYPES.TABLE_BODY : TEXT_TYPES.BODY} className="ps-checkbox-title">{title}</Text>
        </label>
    </div>
);

export default Checkbox;