import React, { Component, useEffect, useRef } from 'react';
import htmlToText from 'html-to-text';
import base64js from 'base64-js';
import { sortBy } from 'lodash';
import { useAdminState } from 'context/AdminProvider';
import { useFetch, useDelete, usePrevious } from 'hooks';
import Text, { TEXT_TYPES } from 'components/Text';
import Modal from 'components/Modal';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { formatDate } from 'utils/generalUtils';
import AgentCommandForm from './AgentCommandForm';

import './command-modal.scss';

const BASE_URL = "admin/agents/runCommand";

const base64Decode = (str, encoding = 'utf-8') => {
    var bytes = base64js.toByteArray(str);
    return new TextDecoder(encoding).decode(bytes).replace(/ /g, "\u00a0");
}

class ResultsRenderer extends Component {
    resultsEndRef = React.createRef()

    componentDidMount() {
        this.resultsEndRef.current.scrollIntoView();
    }

    render() {
        const {data} = this.props;

        return (
            <React.Fragment>
                {
                    sortBy(data, "time").map(({status, command, commandId, response, time}) => (
                        <div key={commandId} className="result-item">
                            <h4 className="title">{formatDate(time)}</h4>
                            <p>Command:</p>
                            <p className="code-text">{command}</p>
                            <p>{`Status: ${status}`}</p>
                            {!!response && <React.Fragment>
                                                <p>Response:</p>
                                                <p className="code-text">
                                                    {base64Decode(response)}
                                                </p>
                                            </React.Fragment>}
                        </div>
                    ))
                }
                <div ref={this.resultsEndRef}></div>
            </React.Fragment>
        )
    }
}

const CommandModal = ({agentId, onClose}) => {
    const {assignedAccountId} = useAdminState();

    const resultsRef = useRef();

    const assignedAccountSuffix = `?accountId=${assignedAccountId}`;
    const [{loading, data}, submitRunCommand] = useFetch(`${BASE_URL}/${agentId}${assignedAccountSuffix}`);

    const [{deleting}, deleteCommands] = useDelete(BASE_URL, {urlSuffix: assignedAccountSuffix});
    const prevDeleting = usePrevious(deleting);

    useEffect(() => {
        if (prevDeleting && !deleting) {
            submitRunCommand();
        }
    }, [deleting, prevDeleting, submitRunCommand]);

    const downloadFile = () => {
        const resultsElement = resultsRef.current;
        const text = htmlToText.fromString(resultsElement.innerHTML, {
            preserveNewlines: true,
            singleNewLineParagraphs: true,
            format: {
                heading: function (elem, fn, options) {
                    const title = fn(elem.children, options);
                    return "\n\n====" + title + "====\n";
                }
            }
        });
        const file = new Blob([text], {type: "text/plain"});

        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = `agent_cmd_${agentId}.txt`;
        element.click();
    }

    return (
        <Modal className="command-modal" onClose={onClose} centerLarge>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>CMD</Text>
            <div className="command-form">
                <AgentCommandForm agentId={agentId} onSubmitSuccess={submitRunCommand} />
            </div>
            <div className="command-results">
                <div className="actions-container">
                    <IconWithTitle name={ICON_NAMES.REFRESH} title="Refresh" onClick={submitRunCommand} />
                    <IconWithTitle name={ICON_NAMES.DOWNLOAD} title="Download" onClick={downloadFile} />
                    <IconWithTitle name={ICON_NAMES.CLEAR} title="Clear" onClick={() => deleteCommands(agentId)} />
                </div>
                <div className="results-display-container">
                    {(loading || deleting) ?
                        <Loader absolute={false} /> : <div ref={resultsRef}><ResultsRenderer data={data} /></div>
                    }
                </div>
            </div>
            <Button className="close-btn" onClick={onClose}>Close</Button>
        </Modal>
    );
}

export default CommandModal;