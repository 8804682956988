import React, { useMemo } from "react";
import { API_TYPE_ITEMS } from "layout/Apis/utils";
import VulnerabilitiesSummary from "components/VulnerabilitiesSummary";
import CustomHeaderWithTooltip from "components/CustomTableHeaderWithTooltip";
import { GATEWAY_TYPES } from "layout/Apis/utils";
import ApiCatalog, {
  COMPLIANCE_WIDTH,
  ComplianceStatusIcon,
  API_FILTER_KEYS,
  WorkloadsDisplay,
} from "../ApiCatalog";
import ApiTestDisplay from "../ApiCatalog/ApiTestDisplay";
import SpecsStatusIcon from "../SpecsStatusIcon";
import { TOOLTIP_PLACEMENTS } from "components/Tooltip";
import Icon, { ICON_NAMES } from "components/Icon";
import COLORS from "utils/scss_variables.module.scss";

export const INTERNAL_API_PAGE_PATH = "/catalog/internal";

export { API_FILTER_KEYS };

const TableEmptyItemPlaceholder = () => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Icon
      name={ICON_NAMES.STROKE}
      style={{
        color: COLORS["color-grey"],
      }}
    />
  </div>
);

const GatewayTableValue = ({ gatewayName, gatewayValue }) =>
  gatewayName && gatewayValue ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <b>{gatewayValue}</b>
      </div>
      &nbsp;|&nbsp;
      <div>{gatewayName}</div>
    </div>
  ) : (
    <TableEmptyItemPlaceholder />
  );

const InternalApis = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Destination Service",
        id: "name",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Port",
        id: "port",
        accessor: "port",
        disableSortBy: true,
        width: 60,
      },
      {
        Header: "Cluster",
        id: "cluster",
        disableSortBy: true,
        Cell: ({ row }) => {
          const { cluster } = row.original;

          return cluster ? <>{cluster}</> : <TableEmptyItemPlaceholder />;
        },
      },
      {
        Header: "Namespace",
        id: "namespace",
        disableSortBy: true,
        Cell: ({ row }) => {
          const { namespace } = row.original;

          return namespace ? <>{namespace}</> : <TableEmptyItemPlaceholder />;
        },
      },
      {
        Header: () => (
          <CustomHeaderWithTooltip
            headerTitle={"GATEWAY"}
            icon={ICON_NAMES.INFO}
            placement={TOOLTIP_PLACEMENTS.RIGHT}
            text={"Public facing"}
            id={"gateway-table-header"}
          />
        ),
        id: "gateway",
        Cell: ({ row }) => {
          const { gatewayInfo } = row.original;

          return (
            <GatewayTableValue
              gatewayName={gatewayInfo?.name}
              gatewayValue={GATEWAY_TYPES[gatewayInfo?.type]?.label || null}
            />
          );
        },
        width: 150,
      },
      {
        Header: "Security Findings",
        id: "risk",
        Cell: ({ row }) => {
          const { vulnerabilitiesSummary } = row.original;

          return (
            <VulnerabilitiesSummary id={row.id} {...vulnerabilitiesSummary} />
          );
        },
        defaultCanSort: true,
        width: 300,
      },
      {
        Header: "Client Workloads",
        id: "workloads",
        Cell: ({ row }) => {
          const { clientWorkloads } = row.original;
          return clientWorkloads && clientWorkloads.length > 0 ? (
            <WorkloadsDisplay workloadItems={clientWorkloads} />
          ) : (
            <TableEmptyItemPlaceholder />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Test",
        id: "test",
        Cell: ({ row }) => {
          const {
            identifier,
            fuzzingStatus,
            fuzzingStartTime,
            fuzzingEndTime,
            fuzzingStatusMessage,
          } = row.original;

          return (
            <ApiTestDisplay
              id={identifier}
              status={fuzzingStatus}
              startTime={fuzzingStartTime}
              endTime={fuzzingEndTime}
              statusMessage={fuzzingStatusMessage}
            />
          );
        },
        disableSortBy: true,
        width: 100,
      },
      {
        Header: "Policy Compliance",
        id: "compliance",
        className: "compliance-cell",
        Cell: ({ row }) => {
          const compliance = row.original.compliance || {};

          return <ComplianceStatusIcon compliance={compliance.compliant} />;
        },
        disableSortBy: true,
        width: COMPLIANCE_WIDTH,
      },
      {
        Header: "Specs",
        id: "specs",
        Cell: ({ row }) => {
          const { identifier, openapi_spec_availablity } = row.original;

          return (
            <SpecsStatusIcon id={identifier} {...openapi_spec_availablity} />
          );
        },
        disableSortBy: true,
        width: COMPLIANCE_WIDTH,
      },
    ],
    []
  );

  return (
    <ApiCatalog
      columns={columns}
      url="apiSecurity/internalCatalog"
      apiNameTitle="Destination Service"
      type={API_TYPE_ITEMS.INTERNAL.value}
    />
  );
};

export default InternalApis;
