import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { RULE_ACTIONS, ENFORCE_ACTION_ITEM } from 'utils/systemConsts';
import { BoldText, AndList } from 'utils/generalUtils';
import { DEPLOYMENT_USER_RULE_TYPES } from '../utils';
import { POD_WORKLOAD_RULE_TYPES_ITEMS, SIDECAR_TYPES_ITEMS } from './UserRuleForm';
import {RULE_SCOPE_ITEMS} from "./UserRuleForm/FormScope";

const LabelsList = (app, prefix) => {
    const phrase = AndList(app.labels.map(({key, value}) => `${key} is ${value}`));

    return (
        <span>
            <span style={{fontWeight: "bold"}}>{prefix}</span>
            {` with label${app.labels.length > 1 ? "s" : ""} `}{phrase}
        </span>
    );
}

const getPodsPhrase = (app) => {
    let phrase = "";
    
    switch (app.workloadRuleType) {
        case POD_WORKLOAD_RULE_TYPES_ITEMS.NAME.value:
            phrase = AndList(app.names.map(name => `${name} pod`));
            break;
        case POD_WORKLOAD_RULE_TYPES_ITEMS.LABEL.value:
            phrase = LabelsList(app, "pods");
            break;
        case POD_WORKLOAD_RULE_TYPES_ITEMS.ANY.value:
            phrase = <span>any pod</span>;
            break;
        default:
            break;
    }

    return phrase;
}

const getPodValidationPhrase = (podValidation, allowed, pspProfiles, apiProfiles) => {
    const {vulnerability, podSecurityPolicy, apiSecurityPolicy, allowedInjectedSidecars} = podValidation || {};
    const {type: sidecarType} = allowedInjectedSidecars || {};
    const withAllowedSidecars = !!sidecarType && sidecarType !== SIDECAR_TYPES_ITEMS.ALLOW_ALL.value;

    if (!vulnerability && !podSecurityPolicy && !apiSecurityPolicy && !withAllowedSidecars) {
        return "";
    }
    
    const {highestVulnerabilityAllowed, onViolationAction: vulnerabilityAction} = vulnerability || {};
    const {podSecurityPolicyId, onViolationAction: pspAction, shouldMutate} = podSecurityPolicy || {};
    const {apiSecurityPolicyId, onViolationAction: apiAction} = apiSecurityPolicy || {};

    const getProfileNameById = (profileId, profiles) => {
        const selectedProfile = profiles.find(item => item.id === profileId);
        return !!selectedProfile ? selectedProfile.name : profileId;
    }
    
    const getDenyVulnerabilityPhrase = (vulnerabilityLevel) => (
        <span>{` has vulnerability level `}<BoldText>{vulnerabilityLevel}</BoldText>{` or higher`}</span>
    );
    const getDenyPspProfilePhrase = (profileId) => (
        <span>{` that doesn't match `}<BoldText>{getProfileNameById(profileId, pspProfiles)}</BoldText>{` PSP profile`}</span>
    );
    const getDenyApiProfilePhrase = (profileId) => (
        <span>{` not compliant to `}<BoldText>{getProfileNameById(profileId, apiProfiles)}</BoldText></span>
    );
    
    if (!allowed) {
        return (
            <React.Fragment>
                {!!highestVulnerabilityAllowed && ` that`}
                {!!highestVulnerabilityAllowed && getDenyVulnerabilityPhrase(highestVulnerabilityAllowed)}
                {(!!highestVulnerabilityAllowed && !!podSecurityPolicyId) ? ` OR ` : (!!podSecurityPolicyId && ` that`)}
                {!!podSecurityPolicyId && getDenyPspProfilePhrase(podSecurityPolicyId)}
                {((!!highestVulnerabilityAllowed || !!podSecurityPolicyId) && !!apiSecurityPolicyId) ? ` OR ` : (!!apiSecurityPolicyId && ` that`)}
                {!!apiSecurityPolicyId && getDenyApiProfilePhrase(apiSecurityPolicyId)}
                {((((!!highestVulnerabilityAllowed || !!podSecurityPolicyId) || !!apiSecurityPolicyId) && withAllowedSidecars) && ' OR ')}
                {withAllowedSidecars && ' that has not allowed injected sidecars'}
            </React.Fragment>
        )
    }

    const getAllowVulnerabilityPhrase = (vulnerabilityLevel, action) => (
        <span>
            <span className={classnames("relationship", {blocked: action === RULE_ACTIONS.BLOCK})}>{action}</span>
            {` if contains vulnerability level `}
            <BoldText>{vulnerabilityLevel}</BoldText>
            {` or higher`}
        </span>
    );
    const getAllowPspProfilePhrase = (profileId, action, shouldMutate) => (
        <span>
            <span className={classnames("relationship", {blocked: action === RULE_ACTIONS.BLOCK})}>{shouldMutate ? ENFORCE_ACTION_ITEM.value : action}</span>
            {` if not matching PSP profile `}
            <BoldText>{getProfileNameById(profileId, pspProfiles)}</BoldText>
        </span>
    );
    const getAllowApiProfilePhrase = (profileId, action) => (
        <span>
            <span className={classnames("relationship", {blocked: action === RULE_ACTIONS.BLOCK})}>{action}</span>
            {` if not compliant to `}
            <BoldText>{getProfileNameById(profileId, apiProfiles)}</BoldText>
        </span>
    );

    return (
        <React.Fragment>
            {(!!highestVulnerabilityAllowed || !!podSecurityPolicyId || !!apiSecurityPolicyId) && `: `}
            {!!highestVulnerabilityAllowed && getAllowVulnerabilityPhrase(highestVulnerabilityAllowed, vulnerabilityAction)}
            {(!!highestVulnerabilityAllowed && !!podSecurityPolicyId) && ` OR `}
            {!!podSecurityPolicyId && getAllowPspProfilePhrase(podSecurityPolicyId, pspAction, shouldMutate)}
            {((!!highestVulnerabilityAllowed || !!podSecurityPolicyId) && !!apiSecurityPolicyId) && ` OR `}
            {!!apiSecurityPolicyId && getAllowApiProfilePhrase(apiSecurityPolicyId, apiAction)}
        </React.Fragment>
    );
}

const getScopePhrase = (scope) => {
    let phrase = "";
    switch (scope.workloadRuleScopeType) {
        case RULE_SCOPE_ITEMS.CLUSTER.value:
            phrase = AndList(scope.names).concat(scope.names.length === 1 ? " cluster" : " clusters");
            break;
        case RULE_SCOPE_ITEMS.ENVIRONMENT.value:
            phrase = AndList(scope.names).concat(scope.names.length === 1 ? " environment" : " environments");
            break;
        case RULE_SCOPE_ITEMS.ANY.value:
            phrase = "any cluster";
            break;
        default:
            break;
    }
    
    return phrase;
}

const getTokenPhrase = (tokens, tokenItems) => {
    if (isEmpty(tokens)) {
        return "";
    }
    
    const tokenNames = tokens.map(({tokenId}) => {
        const tokenItem = tokenItems?.find(token => token.id === tokenId);

        return !!tokenItem ? tokenItem.name : tokenId;
    });
    const withHidden = tokenNames.length > 3;

    return <span>{AndList(tokenNames.slice(0, 3), !withHidden)}{withHidden? "..." : ""}</span>;
}

const ACTION_TEXT_MAP = {
    [RULE_ACTIONS.ALLOW]: "Allow",
    [RULE_ACTIONS.DETECT]: "Detect",
    [RULE_ACTIONS.BLOCK]: "Block",
};

const UserRuleContent = (props) => {
    const {app, scope, ruleTypeProperties, pspProfiles, apiProfiles, tokens: tokenItems} = props;
    const {action, ruleType, tokens} = ruleTypeProperties;
    const allowed = action === RULE_ACTIONS.ALLOW;
    const blocked = action === RULE_ACTIONS.BLOCK;
    const injection = ruleType === DEPLOYMENT_USER_RULE_TYPES.INJECTION;
    const scopePhrase = getScopePhrase(scope);

    return (
        <React.Fragment>
            <span className={classnames("relationship", {allowed}, {blocked}, {injection})}>{injection ? `Inject ` : `${ACTION_TEXT_MAP[action]} `}</span>
            {injection && <span>{getTokenPhrase(tokens, tokenItems)}{` to `}</span>}
            {getPodsPhrase(app)}
            {` on `}
            {scopePhrase}
            {getPodValidationPhrase(app.podValidation, allowed, pspProfiles, apiProfiles)}
        </React.Fragment>
    );
};

export default UserRuleContent;