import React from 'react';
import classnames from 'classnames';
import { isNull } from 'lodash';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Button from 'components/Button';

import './test-connection-button.scss';

const TestConnectionButton = ({doTestConnection, isTesting, loading, error, disabled, className}) => {
    let buttonTitle = "Test connection";
    let buttonIconName = ICON_NAMES.REFRESH;
    if (loading) {
        buttonTitle = "Testing";
        buttonIconName = ICON_NAMES.REFRESH;
    } else if (isTesting) {
        buttonTitle = isNull(error) ? "Tested" : "Failed";
        buttonIconName = isNull(error) ? ICON_NAMES.CHECK_MARK: ICON_NAMES.X_MARK;
    }

    const disableTestConnection = disabled || loading;

    return (
        <div className={classnames("test-connection-button-wrapper", className)}>
            <Button secondary className={classnames("test-connection-button", {error: !isNull(error)})} onClick={doTestConnection} disabled={disableTestConnection}>
                <IconWithTitle name={buttonIconName} title={buttonTitle} disabled={disableTestConnection} />
            </Button>
            {!isNull(error) && isTesting && !!error.message && <div className="test-connection-button-error">{error.message}</div>}
        </div>
    )
}

export default TestConnectionButton;