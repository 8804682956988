import { isEmpty } from 'lodash';

const getRuleRangeValues = ({rule, ranges}) => {
    return [rule, ...ranges.map(({min, max}) => `range: ${min}-${max}`)];
}

const getBooleanValue = value => value ? "True" : "False";

export const getPspDataItems = data => {
    const {privileged, readOnlyRootFileSystem, allowPrivilegeEscalation,
        defaultAllowPrivilegeEscalation, hostPorts, volumes, allowedCapabilities, runAsGroup,
        fsGroup, runAsUser, supplementalGroups, allowedProcMountTypes, requiredDropCapabilities,
        allowedHostPaths, forbiddenSysctls, allowedUnsafeSysctls, hostNetwork, hostPID, hostIPC} = data;
    
    const profileItems = [
        {name: "runAsUser", values: getRuleRangeValues(runAsUser)},
        {name: "fsGroup", values: getRuleRangeValues(fsGroup)},
        {name: "supplementalGroups", values: getRuleRangeValues(supplementalGroups)},
        {name: "privileged", values: [getBooleanValue(privileged)]},
        {name: "readOnlyRootFileSystem", values: [getBooleanValue(readOnlyRootFileSystem)]},
        {name: "allowPrivilegeEscalation", values: [getBooleanValue(allowPrivilegeEscalation)]},
        {name: "defaultAllowPrivilegeEscalation", values: [getBooleanValue(defaultAllowPrivilegeEscalation)]},
        {name: "hostNetwork", values: [getBooleanValue(hostNetwork)]},
        {name: "hostPID", values: [getBooleanValue(hostPID)]},
        {name: "hostIPC", values: [getBooleanValue(hostIPC)]}
    ];

    if (!isEmpty(runAsGroup)) {
        profileItems.push({name: "runAsGroup", values: getRuleRangeValues(runAsGroup)});
    }
    if (!isEmpty(hostPorts)) {
        profileItems.push({name: "hostPorts", values: hostPorts.map(port => {
            const {min, max} = port || {};

            return `${min}-${max}`;
            })
        })
    }
    if (!isEmpty(volumes)) {
        profileItems.push({name: "volumes", values: volumes});
    }
    if (!isEmpty(allowedCapabilities)) {
        profileItems.push({name: "allowedCapabilities", values: allowedCapabilities});
    }
    if (!isEmpty(allowedProcMountTypes)) {
        profileItems.push({name: "allowedProcMountTypes", values: allowedProcMountTypes});
    }
    if (!isEmpty(allowedHostPaths)) {
        profileItems.push({
            name: "allowedHostPaths",
            values: allowedHostPaths.map(({pathPrefix, readOnly}) => `${pathPrefix}${readOnly ? "-ReadOnly" : ""}`)
        });
    }
    if (!isEmpty(requiredDropCapabilities)) {
        profileItems.push({name: "requiredDropCapabilities", values: requiredDropCapabilities});
    }
    if (!isEmpty(forbiddenSysctls)) {
        profileItems.push({name: "forbiddenSysctls", values: forbiddenSysctls});
    }
    if (!isEmpty(allowedUnsafeSysctls)) {
        profileItems.push({name: "allowedUnsafeSysctls", values: allowedUnsafeSysctls});
    }

    return profileItems;
};