import React from 'react';
import { FETCH_METHODS } from 'hooks';
import FormWrapper, { TextField, TextAreaField, validators } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';

const SeccompForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const initialValues = {
        name: "",
        data: "",
        ...initialData
    };

    if (!!initialValues.data) {
        const parsedData = JSON.parse(initialValues.data);

        initialValues.data = JSON.stringify(parsedData, null, 2);
    }

    const isEditForm = initialValues && initialValues.id;
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{`${isEditForm ? "Edit" : "New"} Seccomp Profile`}</Text>
            <FormWrapper
                className="seccomp-form-container"
                initialValues={initialValues}
                submitUrl="seccompProfiles"
                getSubmitParams={formValues => {
                    const {id, ...submitData} = formValues;

                    return !isEditForm ? {submitData} : {
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <TextField
                    name="name"
                    label="Name"
                    placeholder="use only lower case letters and underscores"
                    validate={validators.validateRequired}
                />
                <TextAreaField name="data" label="Data" validate={validators.validateRequired} />
            </FormWrapper>
        </div>
    )
}

export default SeccompForm;