import React, { useMemo, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthState } from 'context/AuthProvider';
import { useFetch, FETCH_METHODS } from 'hooks';
import IgnoreToggle from 'components/IgnoreToggle';
import Table from 'components/Table';
import Icon, { ICON_NAMES } from 'components/Icon';
import Tooltip from 'components/Tooltip';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';
import RiskTag from 'components/RiskTag';
import IconWithTitle from 'components/IconWithTitle';
import { PERMISSIONS_PAGE_PATH, BREADCRUMBS_TITLE, SYSTEM_OWNER_TYPE_MAP } from './utils';

const OwnerPermissions = () => {
    const columns = useMemo(() => [
        {
            Header: "Name",
            id: "name",
            disableSortBy: true,
            Cell: ({row}) => {
                const {name, id, isSystemPermission} = row.original;
                const tooltipId = `role-system-permissions-${id}`;
                const {icon, permissionTitle} = isSystemPermission ? SYSTEM_OWNER_TYPE_MAP.SYSTEM : SYSTEM_OWNER_TYPE_MAP.USER;
                
                return (
                    <div className="cluster-permissions-role">
                        <div data-tip data-for={tooltipId}><Icon name={icon} /></div>
                        <div>{name}</div>
                        <Tooltip id={tooltipId} text={permissionTitle} placement="top" />
                    </div>
                )
            },
            alwaysShow: true
		},
		{
            Header: "Scope",
            id: "scope",
			accessor: "scope",
			disableSortBy: true
		},
		{
            Header: "Namespace",
            id: "namespace",
			accessor: "namespace",
			disableSortBy: true
        },
        {
            Header: "Risk",
            id: "risk",
			Cell: ({row}) => <RiskTag risk={row.original.risk} />,
            defaultCanSort: true
        }
    ], []);
    
    const [showApprovedOnly, setShowApprovedOnly] = useState(false);
	
    const {isReadOnlyUser} = useAuthState();
    
	const [titleNames, setTitleNames] = useState(null);
	const {clusterName, ownerName} = titleNames || {};
    const nameIsSetRef = useRef(false);

    const history = useHistory();

    const params = useParams();
    const {clusterId, ownerId} = params;
    
    const [{loading}, updateIgnorePermissions] = useFetch(`riskAssessment/permissions/${ownerId}/approve`, {loadOnMount: false});
    
    const doUpdateIgnorePermissions = (selectedIds) => updateIgnorePermissions({
        method: FETCH_METHODS.POST,
        submitData: {uuidList: selectedIds},
        formatUrl: url => `${url}?actionType=${showApprovedOnly ? "REMOVE" : "ADD"}`
    });

    const backPath = `/${clusterId}`;

    return (
        <div className="permissions-page">
            <div>
                <BreadcrumbsHeader
                    title={BREADCRUMBS_TITLE}
                    baseUrl={PERMISSIONS_PAGE_PATH}
                    backPath={backPath}
                    breadcrumbs={[
                        {title: `${clusterName} cluster`, path: backPath},
                        {title: `${ownerName} owner`},
                    ]}
                    hideBreadcrumbs={!clusterName}
                />
                <IgnoreToggle
                    isIgnored={showApprovedOnly}
                    onToggle={(isApproved) => setShowApprovedOnly(isApproved)}
                />
            </div>
            <div className="owner-risk-assessment-permissions-wrapper">
                <Table
                    url={`riskAssessment/permissions/${clusterId}/${ownerId}`}
                    name="owner-risk-assessment-permissions"
                    columns={columns}
                    onLineClick={({id}) => history.push({pathname: `${PERMISSIONS_PAGE_PATH}/${clusterId}/${ownerId}/${id}`})}
                    onFetchError={() => history.push({pathname: `${PERMISSIONS_PAGE_PATH}${backPath}`})}
                    formatFetchedData={data => {
                        const {permissions} = data || {};
                        
                        return permissions;
                    }}
                    onFetchedDataChange={data => {
                        const {clusterName, ownerName} = data || {};
                        
                        if (!!clusterName && !nameIsSetRef.current) {
                            setTitleNames({clusterName, ownerName});
                            nameIsSetRef.current = true;
                        }
                    }}
                    withPagination={false}
                    filters={{isApproved: showApprovedOnly}}
                    withMultiSelect={!isReadOnlyUser}
                    rowSelectActionComponent={({selectedIds, disabled}) => (
                        <IconWithTitle
                            name={showApprovedOnly ? ICON_NAMES.BELL : ICON_NAMES.BELL_CROSSED}
                            title={showApprovedOnly ? "Activate" : "Ignore"}
                            onClick={() => doUpdateIgnorePermissions(selectedIds)}
                            disabled={disabled}
                        />
                    )}
                    isLoading={loading}
                />
            </div>
        </div>
    )
}

export default OwnerPermissions;