import React, { useState } from 'react';
import { isEmpty, cloneDeep, get, set } from 'lodash';
import { useMountMultiFetch, FETCH_METHODS } from 'hooks';
import { useAuthState } from 'context/AuthProvider';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { CLUSTER_SOURCES, APPLICATION_NAME } from 'utils/systemConsts';
import { valueToValueLabel } from 'utils/apiUtils';
import Wizard from 'components/Wizard';
import Loader from 'components/Loader';
import { validators } from 'components/Form';
import { INSTALLATION_TYPES } from '../InstallDataModal';
import FormClusterProperties from './FormClusterProperties';
import FormNetworkSecurity, { EMPTY_ANOTATION_ITEM, EMPTY_SIDECAR_RESOURCES, EMPTY_ISTIO_FIELDS } from './FormNetworkSecurity';
import FormAdvanceSettings from './FormAdvanceSettings';
import FormApiSecurity from './FormApiSecurity';
import { showUpdateMessage, FIELD_MAPPING } from './utils';

import './cluster-form.scss';

const CLUSTER_PROPERTIES_STEP = {
    title: "Cluster properties",
    component: FormClusterProperties,
    allowFinish: true,
    finishButtonClass: "cluster-form-cluster-properties-button-finish",
    nextButtonClass: "cluster-form-cluster-properties-button-next"
};
const NETWORK_SETTING_STEP = {
    title: "Network settings",
    component: FormNetworkSecurity,
    allowFinish: true,
    isOptional: true,
    finishButtonClass: "cluster-form-network-security-button-finish",
    nextButtonClass: "cluster-form-network-security-button-next"
};
const API_SECURITY_STEP = {
    title: "API Security",
    component: FormApiSecurity,
    allowFinish: true,
    isOptional: true,
    finishButtonClass: "cluster-form-api-security-button-finish",
    nextButtonClass: "cluster-form-api-security-button-next"
}
const ADCANCED_SETTINS_STEP = {
    title: "Advanced settings",
    component: FormAdvanceSettings,
    isOptional: true,
    finishButtonClass: "cluster-form-advance-settings-button-finish"
}

const validateSidecarResourceValue = ({ sidecarsResources, name, errors }) => {
    if (sidecarsResources[name] !== "" && sidecarsResources[name] < 1) errors.sidecarsResources[name] = "Must be a numnber higher than 1";
}

const HELM_NOTIFICATION = `This is a GitOps installation. You can't edit properties via ${APPLICATION_NAME}`;

const SCAN_CONFIG_TYPES = {
    VULNERABILITIES: "VULNERABILITIES",
    DOCKER_CIS_BENCHMARK: "DOCKER_CIS_BENCHMARK"
}
const SCAN_CONFIG_TYPES_FIELD = "scanConfiguration.scanTypes";

const ClusterForm = ({ initialData, onFormSubmitSuccess, onDirtyChanage, startOnStep }) => {
    const { isReadOnlyUser } = useAuthState();
    const isHelmInstall = initialData?.installationSource === INSTALLATION_TYPES.HELM;
    const isYaml = initialData?.isYaml;

    const [{ loading, data, error }] = useMountMultiFetch([
        { key: "istioVersions", url: "istio/supportedVersions" },
    ]);

    const { showAccountConnections, isAgentAccessAllowed, showApiSecurity } = useDisplayConfigState();

    const initialEnableKubernetesSecurity = get(initialData, FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.name, true);

    const [showNetworkSecurityStep, setShowNetworkSecurityStep] = useState(initialEnableKubernetesSecurity);
    const [showApiSecurityStep, setShowApiSecurityStep] = useState(false);

    const wizardSteps = [
        CLUSTER_PROPERTIES_STEP,
        ...(showNetworkSecurityStep ? [NETWORK_SETTING_STEP] : []),
        ...(showApiSecurityStep ? [API_SECURITY_STEP] : []),
        ADCANCED_SETTINS_STEP
    ]

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const isEditForm = !!initialData && initialData.id;

    const { istioVersions } = data;
    const istioVersionItems = valueToValueLabel(istioVersions);

    const defaultValues = {
        [FIELD_MAPPING.NAME.name]: "",
        [FIELD_MAPPING.ORCHESTRATION.name]: "",
        [FIELD_MAPPING.PERSISTENT_STORAGE.name]: false,
        [FIELD_MAPPING.AUTO_LABEL.name]: false,
        [FIELD_MAPPING.CONTROLLER_REPLICAS.name]: 1,
        clusterPodDefinitionSource: CLUSTER_SOURCES.KUBERNETES.value,
        [FIELD_MAPPING.CONNECTION_CONTROL.name]: false,
        [FIELD_MAPPING.INSTALL_INGRESS.name]: false,
        istioIngressAnnotations: [],
        [FIELD_MAPPING.NAMESPCASES_ISOLATION.name]: false,
        [FIELD_MAPPING.MULTI_CLUSTER.name]: false,
        [FIELD_MAPPING.INSPECT_INCOMMINT_CONNECTIONS.name]: false,
        [FIELD_MAPPING.HOLD_APPLICATION.name]: false,
        [FIELD_MAPPING.ENABLE_SIDECAR_RESOURCES.name]: false,
        sidecarsResources: {},
        [FIELD_MAPPING.TLS_INSPECTION.name]: true,
        [FIELD_MAPPING.ENABLE_API_SECURITY.name]: false,
        [FIELD_MAPPING.API_TESTING.name]: false,
        [FIELD_MAPPING.API_TOKEN_INJECTION.name]: false,
        [FIELD_MAPPING.API_EXTERNAL_GATEWAYS.name]: true,
        [FIELD_MAPPING.API_ISTIO.name]: true,
        [FIELD_MAPPING.CI_HASH_VALIDATION.name]: false,
        [FIELD_MAPPING.CI_SIGNATURE_VALIDATION.name]: false,
        [FIELD_MAPPING.RESTRICT_REGISTRIES.name]: false,
        [FIELD_MAPPING.FAIL_CLOSE.name]: false,
        [FIELD_MAPPING.ENABLE_AUTO_UPDATE.name]: true,
        [FIELD_MAPPING.ENABLE_KUBERNETES_EVENTS.name]: true,
        [FIELD_MAPPING.DISABLE_SSH_PROBING.name]: false
    }

    set(defaultValues, FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.name, false);
    set(defaultValues, FIELD_MAPPING.ISTIO_VERSION.name, "");
    set(defaultValues, FIELD_MAPPING.ENABLE_EXTERNAL_CA.name, false);
    set(defaultValues, FIELD_MAPPING.ENABLE_PROXY.name, false);
    set(defaultValues, FIELD_MAPPING.PROXY_ADDRESS.name, "");
    set(defaultValues, FIELD_MAPPING.ENABLE_INTERNAL_REGISTRY.name, false);
    set(defaultValues, FIELD_MAPPING.INTERNAL_REGISTRY.name, "");
    set(defaultValues, FIELD_MAPPING.SCAN_CONFIG_PARALLEL_SCANNERS.name, 4);

    const initialValues = {
        ...defaultValues,
        ...cloneDeep(initialData),
        [FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.name]: initialEnableKubernetesSecurity
    };

    set(initialValues, FIELD_MAPPING.CD_SCANNING.name, initialValues.clusterPodDefinitionSource === CLUSTER_SOURCES.CD.value);

    set(initialValues, FIELD_MAPPING.PROXY_ADDRESS.name, get(initialValues, FIELD_MAPPING.PROXY_ADDRESS.name) || ""); //text field cannot be null

    set(initialValues, FIELD_MAPPING.INTERNAL_REGISTRY.name, get(initialValues, FIELD_MAPPING.INTERNAL_REGISTRY.name) || ""); //text field cannot be null

    set(initialValues, FIELD_MAPPING.ENABLE_EXTERNAL_CA.name, !!get(initialValues, FIELD_MAPPING.ENABLE_EXTERNAL_CA.name));

    initialValues.istioIngressAnnotations = isEmpty(initialValues.istioIngressAnnotations) ? [EMPTY_ANOTATION_ITEM] : initialValues.istioIngressAnnotations;

    const scanTypes = initialValues.scanConfiguration?.scanTypes || [];
    set(initialValues, FIELD_MAPPING.SCAN_CONFIG_VULNERABILITIES.name, isEditForm ? scanTypes.includes(SCAN_CONFIG_TYPES.VULNERABILITIES) : true);
    set(initialValues, FIELD_MAPPING.SCAN_CONFIG_DOCKER_CIS.name, scanTypes.includes(SCAN_CONFIG_TYPES.DOCKER_CIS_BENCHMARK));

    if (isEmpty(initialValues.sidecarsResources)) {
        set(initialValues, FIELD_MAPPING.ENABLE_SIDECAR_RESOURCES.name, false);
        initialValues.sidecarsResources = {
            ...EMPTY_SIDECAR_RESOURCES
        }
    } else {
        set(initialValues, FIELD_MAPPING.ENABLE_SIDECAR_RESOURCES.name, true);
        initialValues.sidecarsResources = {
            proxyInitRequestsMemory: initialValues.sidecarsResources.proxyInitRequestsMemory || "",
            proxyInitRequestsCpu: initialValues.sidecarsResources.proxyInitRequestsCpu || "",
            proxyInitLimitsMemory: initialValues.sidecarsResources.proxyInitLimitsMemory || "",
            proxyInitLimitsCpu: initialValues.sidecarsResources.proxyInitLimitsCpu || "",
            proxyRequestMemory: initialValues.sidecarsResources.proxyRequestMemory || "",
            proxyRequestCpu: initialValues.sidecarsResources.proxyRequestCpu || "",
            proxyLimitsMemory: initialValues.sidecarsResources.proxyLimitsMemory || "",
            proxyLimitsCpu: initialValues.sidecarsResources.proxyLimitsCpu || ""
        }
    }

    return (
        <Wizard
            className="kubernetes-cluster-form-container"
            title={`Connect Cluster ${isReadOnlyUser ? "(Audit mode)" : ""}`}
            wizardAlert={isHelmInstall ? HELM_NOTIFICATION : null}
            disableStepChangeValidation={isHelmInstall}
            initialValues={initialValues}
            steps={wizardSteps}
            submitUrl="kubernetesClusters"
            disableSubmit={isReadOnlyUser || isHelmInstall}
            disableSubmitTooltip={isHelmInstall ? HELM_NOTIFICATION : null}
            backClickOnlyOnValid
            startOnStep={startOnStep}
            formProps={{
                istioVersionItems,
                allowIngressUpdate: isAgentAccessAllowed,
                accountConnectionsAllowed: showAccountConnections,
                checkShowUpdateMessage: formValues => isEditForm ? showUpdateMessage({ initialValues, formValues }) : false,
                isReadOnlyUser: isReadOnlyUser || isHelmInstall,
                isEditForm,
                apiSecurityEnabledOnAccount: showApiSecurity,
                setShowNetworkSecurityStep,
                setShowApiSecurityStep
            }}
            validate={(values) => {
                const errors = {};

                const { sidecarsResources } = values;

                const enableProxy = get(values, FIELD_MAPPING.ENABLE_PROXY.name);
                const httpsProxy = get(values, FIELD_MAPPING.PROXY_ADDRESS.name);

                if (enableProxy && isEmpty(httpsProxy)) {
                    set(errors, FIELD_MAPPING.PROXY_ADDRESS.name, validators.ERROR_MSG_IS_REQUIRED);
                }

                const internalRegistryEnabled = get(values, FIELD_MAPPING.ENABLE_INTERNAL_REGISTRY.name);
                const internalRegistry = get(values, FIELD_MAPPING.INTERNAL_REGISTRY.name);

                if (internalRegistryEnabled && isEmpty(internalRegistry)) {
                    set(errors, FIELD_MAPPING.INTERNAL_REGISTRY.name, validators.ERROR_MSG_IS_REQUIRED);
                }

                const enableConnectionsControl = get(values, FIELD_MAPPING.CONNECTION_CONTROL.name);
                const isIstioAlreadyInstalled = get(values, FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.name);
                const istioVersion = get(values, FIELD_MAPPING.ISTIO_VERSION.name);

                if (enableConnectionsControl && isIstioAlreadyInstalled && isEmpty(istioVersion)) {
                    set(errors, FIELD_MAPPING.ISTIO_VERSION.name, validators.ERROR_MSG_IS_REQUIRED);
                }

                if (get(values, FIELD_MAPPING.ENABLE_SIDECAR_RESOURCES.name)) {
                    errors.sidecarsResources = {};

                    validateSidecarResourceValue({ sidecarsResources, name: "proxyInitRequestsMemory", errors });
                    validateSidecarResourceValue({ sidecarsResources, name: "proxyInitRequestsCpu", errors });
                    validateSidecarResourceValue({ sidecarsResources, name: "proxyInitLimitsMemory", errors });
                    validateSidecarResourceValue({ sidecarsResources, name: "proxyInitLimitsCpu", errors });
                    validateSidecarResourceValue({ sidecarsResources, name: "proxyRequestMemory", errors });
                    validateSidecarResourceValue({ sidecarsResources, name: "proxyRequestCpu", errors });
                    validateSidecarResourceValue({ sidecarsResources, name: "proxyLimitsMemory", errors });
                    validateSidecarResourceValue({ sidecarsResources, name: "proxyLimitsCpu", errors });

                    if (isEmpty(errors.sidecarsResources)) {
                        delete errors.sidecarsResources;
                    }
                }

                return errors;
            }}
            getSubmitParams={formValues => {
                const { id, ...submitData } = formValues;

                const kubernetesSecurityEnabled = get(submitData, FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.name);
                const apiSecurityEnabled = get(submitData, FIELD_MAPPING.ENABLE_API_SECURITY.name);
                const connectioncontrolEnabled = get(submitData, FIELD_MAPPING.CONNECTION_CONTROL.name);

                if (!kubernetesSecurityEnabled || !connectioncontrolEnabled) {
                    // network settings fields:
                    set(submitData, FIELD_MAPPING.CONNECTION_CONTROL.name, false);
                    set(submitData, FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.name, false);
                    set(submitData, FIELD_MAPPING.ISTIO_VERSION.name, "");
                    Object.keys(EMPTY_ISTIO_FIELDS).forEach(fieldName => set(submitData, fieldName, EMPTY_ISTIO_FIELDS[fieldName]));
                    set(submitData, FIELD_MAPPING.INSPECT_INCOMMINT_CONNECTIONS.name, false);
                }

                if (!kubernetesSecurityEnabled) {
                    // advanced settings fields:
                    set(submitData, FIELD_MAPPING.CI_HASH_VALIDATION.name, false);
                    set(submitData, FIELD_MAPPING.CI_SIGNATURE_VALIDATION.name, false);
                    set(submitData, FIELD_MAPPING.CD_SCANNING.name, false);
                    set(submitData, FIELD_MAPPING.RESTRICT_REGISTRIES.name, false);
                    set(submitData, FIELD_MAPPING.FAIL_CLOSE.name, false);
                }

                if (!apiSecurityEnabled) {
                    // api security fields:
                    set(submitData, FIELD_MAPPING.API_ISTIO.name, false);
                    set(submitData, FIELD_MAPPING.API_EXTERNAL_GATEWAYS.name, false);
                    // advanced settings fields:
                    set(submitData, FIELD_MAPPING.API_TOKEN_INJECTION.name, false);
                    set(submitData, FIELD_MAPPING.API_TESTING.name, false);
                }

                const { istioIngressAnnotations, sidecarsResources } = submitData;
                const cdPodTemplate = get(submitData, FIELD_MAPPING.CD_SCANNING.name);

                submitData.clusterPodDefinitionSource = cdPodTemplate ? "CD" : "KUBERNETES";
                delete submitData.cdPodTemplate;

                submitData.istioIngressAnnotations = istioIngressAnnotations.filter(item => item.key !== "" && item.value !== "");

                delete submitData.sidecarsResourcesEnabled;
                if (!sidecarsResources.proxyInitRequestsMemory) delete submitData.sidecarsResources.proxyInitRequestsMemory;
                if (!sidecarsResources.proxyInitRequestsCpu) delete submitData.sidecarsResources.proxyInitRequestsCpu;
                if (!sidecarsResources.proxyInitLimitsMemory) delete submitData.sidecarsResources.proxyInitLimitsMemory;
                if (!sidecarsResources.proxyInitLimitsCpu) delete submitData.sidecarsResources.proxyInitLimitsCpu;
                if (!sidecarsResources.proxyRequestMemory) delete submitData.sidecarsResources.proxyRequestMemory;
                if (!sidecarsResources.proxyRequestCpu) delete submitData.sidecarsResources.proxyRequestCpu;
                if (!sidecarsResources.proxyLimitsMemory) delete submitData.sidecarsResources.proxyLimitsMemory;
                if (!sidecarsResources.proxyLimitsCpu) delete submitData.sidecarsResources.proxyLimitsCpu;
                if (isEmpty(sidecarsResources)) submitData.sidecarsResources = null;

                const scanConfigScanners = get(submitData, FIELD_MAPPING.SCAN_CONFIG_PARALLEL_SCANNERS.name);
                const scanConfigTypes = [
                    ...(get(submitData, FIELD_MAPPING.SCAN_CONFIG_VULNERABILITIES.name) ? [SCAN_CONFIG_TYPES.VULNERABILITIES] : []),
                    ...(get(submitData, FIELD_MAPPING.SCAN_CONFIG_DOCKER_CIS.name) ? [SCAN_CONFIG_TYPES.DOCKER_CIS_BENCHMARK] : [])
                ]
                submitData.scanConfiguration = {};
                set(submitData, FIELD_MAPPING.SCAN_CONFIG_PARALLEL_SCANNERS.name, scanConfigScanners);
                set(submitData, SCAN_CONFIG_TYPES_FIELD, kubernetesSecurityEnabled ? scanConfigTypes : []);

                delete submitData.isYaml;

                return !isEditForm ? { submitData } : {
                    method: FETCH_METHODS.PUT,
                    formatUrl: url => `${url}/${id}`,
                    submitData
                }
            }}
            onSubmitSuccess={data => onFormSubmitSuccess(data, showUpdateMessage({ initialValues: initialData, formValues: data }), isYaml)}
            onDirtyChanage={onDirtyChanage}
        />
    );
}

export default ClusterForm;
