import React from "react";
import { ICON_NAMES } from 'components/Icon';
import MiniDashboardWrapper, { RiskDashboardItem } from "components/MiniDashboardWrapper";
import { SYSTEM_RISKS } from "utils/systemConsts";

const hasValue = (value) => !!value || value === 0;

const VulnerabilitiesSummary = ({icon=ICON_NAMES.BEETLE_ROUND, total: totalProp, low, medium, high, critical, approved, unknown, id}) => {
	let total = 0;
	
	if (hasValue(critical)) {
		total += critical;
	}
	
	if (hasValue(high)) {
		total += high;
	}
	
	if (hasValue(medium)) {
		total += medium;
	}
	
	if (hasValue(low)) {
		total += low;
	}
	
	if (hasValue(unknown)) {
		total += unknown;
	}
	
	return (
		<MiniDashboardWrapper
			totalCount={isNaN(totalProp) ? total : totalProp}
			totalTooltipId={`${id}total`}
			className="vulnerabilities-summary"
		>
			{hasValue(critical) && <RiskDashboardItem icon={icon} id={id} count={critical} risk={SYSTEM_RISKS.CRITICAL} />}
			<RiskDashboardItem icon={icon} id={id} count={high} risk={SYSTEM_RISKS.HIGH} />
			<RiskDashboardItem icon={icon} id={id} count={medium} risk={SYSTEM_RISKS.MEDIUM} />
			{hasValue(low) && <RiskDashboardItem icon={icon} id={id} count={low} risk={SYSTEM_RISKS.LOW} />}
			{hasValue(unknown) && <RiskDashboardItem icon={icon} id={id} count={unknown} risk={SYSTEM_RISKS.NO_RISK} />}
			{hasValue(approved) && <RiskDashboardItem icon={icon} id={id} count={approved} risk={SYSTEM_RISKS.APPROVED} />}
		</MiniDashboardWrapper>
)};

export default VulnerabilitiesSummary;
