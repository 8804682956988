import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { APP_PATHS } from 'utils/systemConsts';
import TrustedSignersDetails from './TrustedSignersDetails';
import TrustedSignersList from './TrustedSignersList';

export const TRUESTED_SIGNERS_PAGE_PATH = `${APP_PATHS.CI_CD.path}/trustedSigners`;

const TrustedSigners = () => (
    <Switch>
        <Route path={`${TRUESTED_SIGNERS_PAGE_PATH}/:id`} component={TrustedSignersDetails} />
        <Route path={TRUESTED_SIGNERS_PAGE_PATH} component={TrustedSignersList} />
    </Switch>
)

export default TrustedSigners;