import React from 'react';
import { MultiselectField, validators } from 'components/Form';
import { WORKLOAD_RISK_TYPE_ITEMS } from 'utils/systemConsts';

const FormIgnoreRisk = () => (
    <MultiselectField
        name="workloadRisksType"
        label="Risks"
        items={Object.values(WORKLOAD_RISK_TYPE_ITEMS)}
        clearable={false}
        validate={validators.validateRequired}
    />
)

export default FormIgnoreRisk;