import { IP_TYPES_ITEMS } from './RuleSubForms';

export const POLICY_TYPE = "Connection";

export const ENDPOINT_TYPE_ITEMS = {
    POD: {
        value: "POD",
        label: "By Pod"
    },
    EXPANSION: {
        value: "EXPANSION",
        label: "By Expansion"
    },
    ENVIRONMENT: {
        value: "ENVIRONMENT",
        label: "By Environment"
    },
    IP_RANGE: {
        value: "IP_RANGE",
        label: "By Address"
    },
    EXTERNAL: {
        value: "ExternalConnectionRulePart",
        label: "External"
    },
    ANY: {
        value: "AnyConnectionRulePart",
        label: "Any"
    }
}

export const KAFKA_ENPOINT_TYPE = {value: "KafkaConnectionRulePart", label: "By Kafka"};
export const KUBERNETES_SERVICE_TYPE = {value: "ServiceNameConnectionRulePart", label: "By Kubernetes Service"};
export const API_ENPOINT_TYPE = {value: "ApiServiceConnectionRulePart", label: "By API"};

export const getEndpointTypeItems = (ipWithDomain) => {
    if (ipWithDomain) {
        return ENDPOINT_TYPE_ITEMS;
    }

    return {...ENDPOINT_TYPE_ITEMS, IP_RANGE: {value: IP_TYPES_ITEMS.IP_RANGE.value, label: "By IP"}};
}