import React from 'react';
import classnames from 'classnames';
import { VULNERABILITY_LEVELS } from 'utils/systemConsts';
import { ICON_NAMES } from 'components/Icon';
import SelectField from '../SelectField';

import './vulnerability-field.scss';

const VulnerabilityField = (props) => {
    const {name, label="Vulnerability level", clearable=true, disabled=false, tooltipText} = props;

    return (
        <SelectField
            className="vulnerability-field"
            name={name}
            placeholder="Select"
            label={label}
            clearable={clearable}
            items={Object.values(VULNERABILITY_LEVELS).map(({value, label}) => ({
                value,
                label,
                icon: ICON_NAMES.EXCLAMATION_MARK_ROUND,
                iconClass: classnames("vulnerability-field-icon", "global-risk-color", value.toLowerCase())
            }))}
            disabled={disabled}
            tooltipText={tooltipText}
        />
    );
}

export default VulnerabilityField;