import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PERMISSIONS_PAGE_PATH } from './utils';
import GeneralPermissions from './GeneralPermissions';
import ClusterPermissions from './ClusterPermissions';
import OwnerPermissions from './OwnerPermissions';
import RolePermissions from './RolePermissions';

import './permissions.scss';

export {
    PERMISSIONS_PAGE_PATH
}

const RisksScan = () => (
    <Switch>
        <Route path={`${PERMISSIONS_PAGE_PATH}/:clusterId/:ownerId/:roleId`} component={RolePermissions} />
        <Route path={`${PERMISSIONS_PAGE_PATH}/:clusterId/:ownerId`} component={OwnerPermissions} />
        <Route path={`${PERMISSIONS_PAGE_PATH}/:clusterId`} component={ClusterPermissions} />
        <Route path={PERMISSIONS_PAGE_PATH} component={GeneralPermissions} />
    </Switch>
)

export default RisksScan;