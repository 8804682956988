import React from 'react';
import { useAuthState } from 'context/AuthProvider';
import FormWrapper, { validators, TextField, RadioField } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';
import { RULE_ACTIONS } from 'utils/systemConsts';

const PortshiftRuleForm = ({initialData, title, onDone, onDirtyChanage}) => {
    const {isReadOnlyUser} = useAuthState();

    const initialValues = {
        name: "",
        action: "",
        ...initialData
    };

    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{title}</Text>
            <FormWrapper
                initialValues={initialValues}
                onDirtyChanage={onDirtyChanage}
                doCustomSubmit={formValues => onDone(formValues)}
                disableSubmitButton={isReadOnlyUser}
            >
                <TextField name="name" label="Rule Name" disabled />
                <RadioField
                    name="action"
                    label="Action"
                    component={RadioField}
                    validate={validators.validateRequired}
                    items={[
                        {
                            value: RULE_ACTIONS.DETECT,
                            label: "Detect running unidentified workloads"
                                
                        },
                        {
                            value: RULE_ACTIONS.BLOCK,
                            label: "Block unidentified workloads from running"
                        }
                    ]}
                    disabled={isReadOnlyUser}
                />
            </FormWrapper>
        </div>
    )
}

export default PortshiftRuleForm;