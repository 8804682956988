import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { FETCH_METHODS, useFetch } from 'hooks';
import FormWrapper, { validators, TextField, TextAreaField, MultiselectField } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';
import Loader from 'components/Loader';
import { TooltipWrapper, TOOLTIP_PLACEMENTS } from 'components/Tooltip';
import Icon from 'components/Icon';
import InfoIcon from 'components/InfoIcon';
import { DEPLOYMNET_CLUSTERS_PAGE_PATH } from 'layout/Deployments/Clusters'

import './external-ca-form.scss';

const ExternalCaForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const history = useHistory();

    const initialValues = {
        name: "",
        issuerName: "",
        issuerNamespace: "",
        certificate: "",
        clusters: [],
        ...initialData
    };

    const [
        { loading: loadingClusters, data: clusters, error: errorLoadingClusters },
    ] = useFetch("leanKubernetesClusters");

    initialValues.issuerNamespace = initialValues.issuerNamespace || ""; //should not be null
    initialValues.certificate = initialValues.certificate || ""; //should not be null

    const isEditForm = initialValues && initialValues.id;

    const certificateProps = {
        name: "certificate",
        label: "Root CA Certificate (PEM)"
    }
    if (isEditForm) {
        certificateProps.placeholder = "If not edited existing certificate will be used";
    } else {
        certificateProps.validate = validators.validateRequired;
    }

    if (loadingClusters) {
        return <Loader />;
    }

    if (errorLoadingClusters) {
        return null;
    }

    const onClusterClick = name => history.push({pathname: DEPLOYMNET_CLUSTERS_PAGE_PATH, query: {openFormClusterName: name}});

    const clusterItems = clusters.map(
        ({ id, name, useExternalCA }) => ({
            id: id,
            value: {id, name},
            label: name,
            tooltipText: !useExternalCA ? <span>{`The ${name} controller doesn’t have the external CA feature enabled. `}<span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => onClusterClick(name)}>Update the settings</span>{` or select another controller.`}</span> : null,
            isDisabled: !useExternalCA,
            tooltipPlacement: TOOLTIP_PLACEMENTS.RIGHT,
            clickacbleTooltip: true,
            delayHide: 800,
        })
    );
    
    return (
        <div className='external-ca-form-container'>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{`${isEditForm ? "Edit" : "Add"} External CA`}</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl="settings/integrations/ca"
                getSubmitParams={formValues => {
                    const {id, ...submitData} = formValues;

                    return !isEditForm ? {submitData} : {
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <TextField name="name" label="Name" validate={validators.validateRequired} />
                <TextField name="issuerName" label="Cert manager issuer name" validate={validators.validateRequired} />
                <TextField name="issuerNamespace" label="Cert manager issuer namespace" />
                <MultiselectField name="clusters" label="Clusters" 
                    fullObjectValue
                    items={clusterItems}
                    validate={validators.validateRequired}
                    components={{
                        Option: ({ children, innerProps, data, isDisabled }) => {
                            const { id, icon: iconName, iconClass, tooltipText, delayHide=400, clickacbleTooltip=false, tooltipPlacement} = data;

                            return (
                                <div className={classNames("multi-select-option", { disabled: isDisabled })} {...innerProps}>
                                    {!!iconName && <Icon name={iconName} className={iconClass} />}
                                    <div className="multi-select-option-text">{children}</div>
                                    {
                                        !!tooltipText && <TooltipWrapper clickable={clickacbleTooltip} delayHide={delayHide} id={`cluster-tooltip-${id}`} text={tooltipText} placement={tooltipPlacement || TOOLTIP_PLACEMENTS.TOP}>
                                            <InfoIcon />
                                        </TooltipWrapper>
                                    }
                                </div>
                            )
                        },
                    }}
                />
                <TextAreaField {...certificateProps} />
            </FormWrapper>
        </div>
    )
}

export default ExternalCaForm;