import React, { useCallback, useEffect } from 'react';
import { isEqual, isEmpty } from 'lodash';
import { components as reactSelectComponents } from 'react-select';
import { valueToValueLabel } from 'utils/apiUtils';
import { useFetch, usePrevious } from 'hooks';
import { validators, RadioField, MultiselectField, useFormikContext, utils, ArrayField, TextField } from 'components/Form';
import Loader from 'components/Loader';
import RiskTag from 'components/RiskTag';
import { KAFKA_ENPOINT_TYPE } from '../utils';
import { MASTER_TYPE_FIELD_NAME } from './FormEndpointBase';

export const EMPTY_KEY_VALUES_ITEM = {key: "", values: []};

export const L7_PROTOCOLS = {
    ANY: "L7_PROTOCOL_ANY",
    HTTP: "HttpLayer7Part",
    KAFKA: "KafkaLayerPart",
    SPEC: "ApiServiceLayerPart"
}

export const getApiMethodFieldValue = ({tag, method, path}) => `${tag} ${method} ${path}`;

const HeadersAndQueryParamFields = ({isReadOnlyUser}) => (
    <React.Fragment>
        <ArrayField
            name="layer7Settings.headers"
            label="Headers"
            firstFieldProps={{
                component: TextField,
                key: "key",
                placeholder: "Key"
            }}
            secondFieldProps={{
                component: MultiselectField,
                key: "values",
                placeholder: "Values",
                items: [],
                creatable: true,
                emptyValue: []
            }}
            disabled={isReadOnlyUser}
        />
        <ArrayField
            name="layer7Settings.queryParameters"
            label="Query Parameters"
            firstFieldProps={{
                component: TextField,
                key: "key",
                placeholder: "Key"
            }}
            secondFieldProps={{
                component: MultiselectField,
                key: "values",
                placeholder: "Values",
                items: [],
                creatable: true,
                emptyValue: []
            }}
            disabled={isReadOnlyUser}
        />
    </React.Fragment>
)

const InterceptNotification = () => (
    <utils.FormNotificationMessage>
        Https traffic requires interception option (see STEP 1)
    </utils.FormNotificationMessage>
)

const HttpForm = ({isIntercept, isReadOnlyUser}) => (
    <React.Fragment>
        <MultiselectField
            name="layer7Settings.methods"
            label="Method"
            items={[
                {value: "GET", label: "GET"},
                {value: "POST", label: "POST"},
                {value: "PUT", label: "PUT"},
                {value: "DELETE", label: "DELETE"},
                {value: "HEAD", label: "HEAD"},
                {value: "CONNECT", label: "CONNECT"},
                {value: "OPTIONS", label: "OPTIONS"},
                {value: "TRACE", label: "TRACE"},
                {value: "PATCH", label: "PATCH"}
            ]}
            validate={validators.validateRequired}
            disabled={isReadOnlyUser}
        />
        <MultiselectField
            name="layer7Settings.paths"
            label="Path"
            items={[]}
            creatable={true}
            disabled={isReadOnlyUser}
        />
        <HeadersAndQueryParamFields isReadOnlyUser={isReadOnlyUser} />
        {!isIntercept && <InterceptNotification />}
    </React.Fragment>
);

const KafkaForm = ({clusterId, actionNames, isReadOnlyUser}) => {
    const [{loading, data: topics}] = useFetch("connectionsPolicy/kafka", {
        formatUrl: url => `${url}/${clusterId}/topics`
    });

    const topicItems = !topics ? [] : valueToValueLabel(topics);

    return (
        <React.Fragment>
            {loading && <div><Loader absolute={false} /></div>}
            <div style={{display: !!clusterId && !loading ? "block" : "none"}}>
                <MultiselectField
                    name="layer7Settings.topics"
                    label="Topics"
                    items={topicItems}
                    validate={validators.validateRequired}
                    creatable={true}
                    disabled={isReadOnlyUser}
                />
                <MultiselectField
                    name="layer7Settings.actions"
                    label="Actions"
                    items={actionNames}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />
            </div>
        </React.Fragment>
    );
}

const MethodPathDisplay = ({method, path}) => (
    <div style={{display: "flex"}}>
        <div style={{fontWeight: "bold", marginRight: "5px"}}>{method}</div>
        <div>{path}</div>
    </div>
)

const SpecForm = ({isIntercept, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {destination, layer7Settings} = values;
    const {api} = destination;
    const {tags} = layer7Settings;
    const prevTags = usePrevious(tags);

    const [{loading, data: tagsData}] = useFetch(`apiSecurity/${api}/tags`);

    const [{loading: methodLoading, data: methodsData}, fetchMethods] = useFetch(`apiSecurity/${api}/methods`, {loadOnMount: false});
    const doFetchMethods = useCallback(() => fetchMethods({queryParams: {apiServiceSpecTags: tags}}), [fetchMethods, tags]);

    const methods = methodsData || [];
    const methodItems = methods.map((methodData) => ({value: getApiMethodFieldValue(methodData), ...methodData}));
    
    useEffect(() => {
        if (!isEqual(tags, prevTags)) {
            doFetchMethods();
            
            if (!!prevTags) {
                setFieldValue("layer7Settings.methods", []);
            }
        }
    }, [prevTags, tags, doFetchMethods, setFieldValue]);

    return (
        <React.Fragment>
            <MultiselectField
                name="layer7Settings.tags"
                label="Tags"
                items={valueToValueLabel(tagsData || [])}
                loading={loading}
                disabled={isReadOnlyUser}
            />
            <MultiselectField
                name="layer7Settings.methods"
                label="Method + Path"
                items={methodItems}
                validate={validators.validateRequired}
                loading={methodLoading}
                disabled={isEmpty(tags) || isReadOnlyUser}
                components={{
                    Option: (props) => {
                        const {path, method, risk} = props.data;

                        return (
                            <reactSelectComponents.Option {...props} >
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <MethodPathDisplay method={method} path={path} />
                                    <RiskTag risk={risk} />
                                </div>
                            </reactSelectComponents.Option>
                        )
                    },
                    MultiValueLabel: (props) => {
                        const {path, method} = props.data;

                        if (methodLoading) {
                            return null;
                        }
                        
                        return (
                            <reactSelectComponents.MultiValueLabel {...props} >
                                <MethodPathDisplay method={method} path={path} />
                            </reactSelectComponents.MultiValueLabel>
                        )
                    },
                    MultiValueRemove: props => {
                        if (methodLoading) {
                            return null;
                        }
                        
                        return <reactSelectComponents.MultiValueRemove {...props} />;
                    }
                }}
                fullObjectValue={true}
            />
            <HeadersAndQueryParamFields isReadOnlyUser={isReadOnlyUser} />
            {!isIntercept && <InterceptNotification />}
        </React.Fragment>
    )
}

const FormLayer7 = ({actionNames, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {layer7Settings, destination} = values;
    
    const {layer7Protocol, isIntercept} = layer7Settings || {};
    const prevLayer7Protocol = usePrevious(layer7Protocol);

    const {clusterId} = destination;
    const isKafkaDestination = destination[MASTER_TYPE_FIELD_NAME] === KAFKA_ENPOINT_TYPE.value;

    const byApi = !!destination.api;
    
    useEffect(() => {
        if (layer7Protocol !== L7_PROTOCOLS.SPEC && byApi) {
            setFieldValue("layer7Settings.layer7Protocol", L7_PROTOCOLS.SPEC);
        } else if (layer7Protocol === L7_PROTOCOLS.SPEC && !byApi) {
            setFieldValue("layer7Settings.layer7Protocol", L7_PROTOCOLS.HTTP);
        }

        if (isIntercept && ![L7_PROTOCOLS.HTTP, L7_PROTOCOLS.SPEC].includes(layer7Protocol)) {
            setFieldValue("layer7Settings.layer7Protocol", byApi ? L7_PROTOCOLS.SPEC : L7_PROTOCOLS.HTTP);

            return;
        }

        if (layer7Protocol === "") {
            setFieldValue("layer7Settings.layer7Protocol", L7_PROTOCOLS.ANY);
        }

        if (prevLayer7Protocol === layer7Protocol || !prevLayer7Protocol) {
            return;
        }

        setFieldValue("layer7Settings.methods", []);
        setFieldValue("layer7Settings.tags", []);
        setFieldValue("layer7Settings.paths", []);
        setFieldValue("layer7Settings.topics", []);
        setFieldValue("layer7Settings.actions", []);
        setFieldValue("layer7Settings.headers", [EMPTY_KEY_VALUES_ITEM]);
        setFieldValue("layer7Settings.queryParameters", [EMPTY_KEY_VALUES_ITEM]);
    }, [layer7Protocol, prevLayer7Protocol, setFieldValue, isIntercept, byApi]);

    return (
        <React.Fragment>
            <RadioField
                name="layer7Settings.layer7Protocol"
                label="Protocol"
                validate={validators.validateRequired}
                items={[
                    ...(byApi ? [{value: L7_PROTOCOLS.SPEC, label: "Spec method"}] : []),
                    {value: L7_PROTOCOLS.HTTP, label: "Http", disabled: byApi},
                    {value: L7_PROTOCOLS.KAFKA, label: "Kafka", disabled: !isKafkaDestination || byApi},
                    {value: L7_PROTOCOLS.ANY, label: "Any", disabled: isIntercept || byApi}
                ]}
                horizontal={true}
                disabled={isReadOnlyUser}
            />
            {layer7Protocol === L7_PROTOCOLS.KAFKA && <KafkaForm clusterId={clusterId} actionNames={actionNames} isReadOnlyUser={isReadOnlyUser} />}
            {layer7Protocol === L7_PROTOCOLS.HTTP && <HttpForm isIntercept={isIntercept} isReadOnlyUser={isReadOnlyUser} />}
            {layer7Protocol === L7_PROTOCOLS.SPEC && <SpecForm isIntercept={isIntercept} isReadOnlyUser={isReadOnlyUser} />}
        </React.Fragment>
    );
}

export default FormLayer7