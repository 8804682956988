import React, { useMemo } from 'react';
import TableSettings from '../../TableSettings';
import ExternalCaForm from './ExternalCaForm';

const enhanceClustersDataWithLabel = (apiData) => {
    return apiData && apiData.map((apiDataItem) => {
        if (apiDataItem && apiDataItem?.clusters) {
            apiDataItem.clusters = apiDataItem.clusters.map((itemWithCluster) => ({...itemWithCluster, label: itemWithCluster.name}))
        }

        return apiDataItem
        })
}

const ExternalCa = () => {
    const columns = useMemo(() => [
        {
            Header: "Name",
            id: "name",
            accessor: "name",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Issuer Name",
            id: "issuerName",
            accessor: "issuerName",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Issuer Namespace",
            id: "issuerNamespace",
            accessor: "issuerNamespace",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "No. clusters",
            id: 'clusters',
            Cell: ({row}) => {
                return <div>{row.original.clusters?.length}</div>
            },
            disableSortBy: true,
            alwaysShow: true
        },
    ], []);

    return (
        <TableSettings
            columns={columns}
            url="settings/integrations/ca"
            tableName="externalCa"
            formComponent={ExternalCaForm}
            itemTitle="Add external CA"
            containerTitle="External CA Integration"
            showServerError={true}
            formatFetchedData={data => enhanceClustersDataWithLabel(data)}
        />
    );
}

export default ExternalCa;