import React from 'react';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { validators, TextField, ToggleField, useFormikContext } from 'components/Form';
import { ENVIRONMENT_TYPES_ITEMS } from '../RuleSubForms'

const FormRuleProperties = ({isReadOnlyUser}) => {
    const {values} = useFormikContext();
    const {destination} = values;

    const showPodToPod = destination.connectionRulePartType === ENVIRONMENT_TYPES_ITEMS.NAME.value;

    return (
        <React.Fragment>
            <TextField name="name" label="Rule Name" validate={validators.validateRequired} disabled={isReadOnlyUser} />
            {showPodToPod &&
                <ToggleField
                    className="policy-encrypt-direct-field"
                    name={RULE_ACTIONS.ENCRYPT_DIRECT}
                    label="Encrypt direct pod-to-pod communication"
                    tooltipText={<span>
                        Encrypt also direct pod-to-pod communication, where the client connects<br />
                        directly to a destination pod IP, instead of a Kubernetes service<br />
                        (useful for StatefulSet deployments)
                    </span>}
                    disabled={isReadOnlyUser}
                />
            }
        </React.Fragment>
    );
}

export default FormRuleProperties;

