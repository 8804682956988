import React, { useRef } from 'react';
import classnames from 'classnames';
import { VULNERABILITY_LEVELS } from 'utils/systemConsts';
import { useFetch } from 'hooks';
import Tooltip from 'components/Tooltip';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';
import Loader from 'components/Loader';
import VulnerabilitiesSummary from 'components/VulnerabilitiesSummary';

import './image-vulnerabilities-layers.scss';

const LayerHeaderComponent = ({layerId, layerName, vulnerabilitySummary, isSafe, onClick}) => {
    const layerNameRef = useRef();
    const layerTooltipId = `image-layer-tooltip-${layerId}`;

    const copyToClipBoard = event => {
        event.preventDefault();
        event.stopPropagation();
        
        layerNameRef.current.select();
        document.execCommand('copy');
    }
        
    return (
        <div className={classnames("image-layer-item-wrapper", {"is-safe": isSafe})} onClick={onClick}>
            <div className="layer-name-container">
                <IconWithTooltip name={ICON_NAMES.COPY} onClick={copyToClipBoard} tooltipId={`image-layer-copy-tooltip-${layerId}`} tooltipText="Copy layer name" />
                <div className="layer-name-wrapper" data-tip data-for={layerTooltipId}>
                    <input style={{width: `${layerName.length + 4}ch`}} type="text" className="layer-name" ref={layerNameRef} value={layerName} onChange={() => undefined} />
                </div>
            </div>
            <VulnerabilitiesSummary {...vulnerabilitySummary} id={layerId} />
            <Tooltip id={layerTooltipId} text={layerName} placement="top" />
        </div>
    )
}

const getVulnerabilitySeverityLevelCount = (vulnerabilities, severity) => vulnerabilities.filter(item => item.severity === severity).length;

const ImageVulnerabilitiesLayers = ({id, showIgnoredOnly=false, onLayerSelect}) => {
    const [{loading, data, error}] = useFetch(`images/${id}/imageLayers`, {queryParams: {isIgnored: showIgnoredOnly, noPafination: true}});

    if (loading) {
        return <Loader />
    }

    if (error) {
        return null;
    }

    const {safeLayers, vulnerableLayers} = data || {};

    return (
        <div className="image-layers-wrapper">
            <div className="vulnerable-image-layers">
                {
                    vulnerableLayers.map(({id, command, vulnerabilities}) => (
                        <LayerHeaderComponent key={id} layerId={id} layerName={command} onClick={() => onLayerSelect(id)} vulnerabilitySummary={{
                            critical: getVulnerabilitySeverityLevelCount(vulnerabilities, VULNERABILITY_LEVELS.CRITICAL.value),
                            high: getVulnerabilitySeverityLevelCount(vulnerabilities, VULNERABILITY_LEVELS.HIGH.value),
                            medium: getVulnerabilitySeverityLevelCount(vulnerabilities, VULNERABILITY_LEVELS.MEDIUM.value),
                            low: getVulnerabilitySeverityLevelCount(vulnerabilities, VULNERABILITY_LEVELS.LOW.value),
                            unknown: getVulnerabilitySeverityLevelCount(vulnerabilities, VULNERABILITY_LEVELS.UNKNOWN.value)
                        }} />
                    ))
                }
            </div>
            <div className="safe-image-layers">
                {
                    safeLayers.map(({id, command}) => (
                        <LayerHeaderComponent
                            key={id}
                            layerId={id}
                            layerName={command}
                            isSafe={true}
                            vulnerabilitySummary={{critical: 0, high: 0, medium: 0, low: 0, unknown: 0}}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default ImageVulnerabilitiesLayers;