import React from 'react';
import { tableUtils } from 'components/Table';
import { TooltipWrapper } from 'components/Tooltip';
import { formatDate } from 'utils/generalUtils';
import { CONTROLLER_STATUS_ITEMS } from 'utils/systemConsts';

const ControllerStatusDisplay = ({id, status, lastActive}) => {
    const isActive = status === CONTROLLER_STATUS_ITEMS.ACTIVE.value;
    const isAlert = [CONTROLLER_STATUS_ITEMS.INACTIVE.value, CONTROLLER_STATUS_ITEMS.STOPPED.value].includes(status);

    const statusDisplay = (
        <tableUtils.StatusRenderer
            isActive={isActive}
            isWarning={!isActive && !isAlert}
            customText={CONTROLLER_STATUS_ITEMS[status]?.label}
        />
    );

    if (!lastActive) {
        return statusDisplay;
    }
    
    return (
        <TooltipWrapper id={`clustor-controller-status-${id}`} text={`Last active: ${formatDate(lastActive)}`}>
            {statusDisplay}
        </TooltipWrapper>
    )
}

export default ControllerStatusDisplay;