import React, { Component } from 'react';
import classnames from 'classnames';
import ContainerTitle from 'components/ContainerTitle';
import Arrow from 'components/Arrow';

import './container-items.scss';

export default class ContainerItems extends Component {
    state = {
        open: null
    }

    handleItemClick = (id) => {
        const open = this.state.open === id ? null : id;

        this.setState({open});
    }

    render() {
        const {open} = this.state;
        const {className, title, items} = this.props;

        return (
            <div className={classnames("container-items-wrapper", {[className]: className})}>
                <ContainerTitle>{title}</ContainerTitle>
                {
                    items.map(({id, title, componenet: ItemComponenet}) => {
                        const isOpen = open === id;

                        return (
                            <div key={id} className="container-item">
                                <div className="container-item-title" onClick={() => this.handleItemClick(id)}>
                                    <div>{title}</div>
                                    <div><Arrow name={isOpen ? "top" : "bottom"} small /></div>
                                </div>
                                {isOpen && <div className="container-item-content"><ItemComponenet /></div>}
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}