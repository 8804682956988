import React, { useState } from "react";
import Text, { TEXT_TYPES } from "components/Text";
import BenchmarkSummery from "./benchmarkSummery";
import ClustersTable from "./clustersTable";

const CisBenchmarkCompliance = () => {
  const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
  
  return (
    <div className="cis-main-page-content">
      <Text type={TEXT_TYPES.TITLE_MEDIUM}>Kubernetes CIS Benchmark summary</Text>
      <BenchmarkSummery refreshTimestamp={refreshTimestamp} />
      <ClustersTable refreshSummaryData={() => setRefreshTimestamp(Date())} />
    </div>
  );
}

export default CisBenchmarkCompliance;
