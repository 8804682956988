import React from 'react';
import DeleteWizardModal, { TextElement, InnerTitle, LinksList, InfoMessage } from 'components/DeleteWizardModal';
import { CI_CD_POLICY_URL } from 'layout/Policies/CiCdScanPolicy';
import { APPLICATION_NAME } from 'utils/systemConsts';

const CdPoliciesStep = ({stepData: cdPolicies, stepProps}) => {
    const deleteItemName = stepProps.deleteItemName;
    
    return (
        <div className="delete-deployer-cd-policies">
            <React.Fragment>
                <InnerTitle>Deployment Scans</InnerTitle>
                <TextElement>{`${deleteItemName} will be detached from the following Deployment scans.`}</TextElement>
                <InfoMessage>{`If a Deploymnet scan is only attached to ${deleteItemName}, it will be deleted.`}</InfoMessage>
                <LinksList
                    pathname={CI_CD_POLICY_URL}
                    items={cdPolicies.map(({name, id}) => ({name, searchParams: {selectedScanId: id}}))}
                />
            </React.Fragment>
        </div>
    );
}

const DeleteDeployerModal = ({data, onClose, onDeleteSuccess}) => {
    const {id, deployer} = data;
    const deleteItemName = `${deployer} Deployer`;
    
    return (
        <DeleteWizardModal
            className="delete-deployer-modal"
            url={`deployers/${id}/deleteDependencies`}
            deleteUrl="deployers"
            onClose={onClose}
            onDeleteSuccess={onDeleteSuccess}
            deleteSteps={[
                {id: 1, title: "Deployment scans", dataKey: "cdPolicies", component: CdPoliciesStep}
            ]}
            title="Delete deployer"
            description={`Deleting ${deleteItemName} will have an impact on other ${APPLICATION_NAME} elements. Once deleted, the action cannot be reverted`}
            deleteItemName={deleteItemName}
            deleteItemId={id}
        />
    )
}

export default DeleteDeployerModal;