import React, {useMemo, useEffect, useRef} from 'react';
import classnames from "classnames";
import {useLocation, useHistory, useRouteMatch} from 'react-router-dom';
import Table from 'components/Table';
import Button from 'components/Button';
import VulnerabilitiesSummary from 'components/VulnerabilitiesSummary';
import {ICON_NAMES} from "components/Icon";
import IconWithTooltip from "components/IconWithTooltip";
import {formatDate} from 'utils/generalUtils';
import {FiltersForm, FilterContext, CODE_ZIP_FILTER_KEYS} from '../Filter';

import './zips-table.scss';

const ZIP_SOURCES = {
    CI_PLUGIN: "CI plugin",
    RUNTIME: "Runtime"
}

const COLUMN_IDS = {
    VULNERABILITIES: "VULNERABILITIES",
    TIME: "TIME",
    HASH: "HASH"
};

const ZipsTable = () => {
    const {path} = useRouteMatch();
	const history = useHistory();

    const columns = useMemo(() => [
        {
            Header: "Name",
            id: CODE_ZIP_FILTER_KEYS.ICON_NAMES,
            Cell: ({row}) => {
                const {id, name} = row.original;

                return (
                    <Button tertiary className="zip-link-button" onClick={() => history.push(`${path}/${id}`)}>
                        {name}
                    </Button>
                );
            },
            minWidth: 250
        },
        {
            Header: "Sha256",
            id: COLUMN_IDS.HASH,
            accessor: "sha256",
            disableSortBy: true
        },
        {
            Header: "Time Updated",
            id: COLUMN_IDS.TIME,
            accessor: original => formatDate(original.timeUpdated)
        },
        {
            Header: "Source",
            id: "sourceType",
            accessor: original => ZIP_SOURCES[original.sourceType],
            disableSortBy: true
        },
        {
            Header: "Status",
            id: "status",
            Cell: ({row}) => {
                const {isIdentified, id} = row.original;

                return (
                    <IconWithTooltip
                        name={isIdentified ? ICON_NAMES.IMAGE_APPROVED : ICON_NAMES.IMAGE_UNIDENTIFIED}
                        className={classnames("status-icon", {approved: isIdentified})}
                        tooltipId={`status-tooltip-${id}`}
                        tooltipText={isIdentified ? "Code zip identified" : "Code zip unidentified"}
                    />
                );
            },
            disableSortBy: true,
            minWidth: 50,
            maxWidth: 50
        },
        {
            Header: "Vulnerabilities",
            id: COLUMN_IDS.VULNERABILITIES,
            Cell: ({row}) => {
                const {id, vulnerabilitiesSummary: summaryData} = row.original;
                return (
                    <VulnerabilitiesSummary {...summaryData} id={id} />
                );
            },
            defaultCanSort: true,
            minWidth: 330
        }
    ], [history, path]);

    const location = useLocation();
    const {filters: queryFilters} = location.query || {};

    const filters = FilterContext.useFilterState();
    const filterDispatch = FilterContext.useFilterDispatch();

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    return (
        <div className="code-zips-page">
            <div className="table-header-container">
                <FiltersForm
                    filters={filters}
                    queryFilters={queryFilters}
                    setFilters={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }
                        
                        FilterContext.setFilters(filterDispatch, updatedFilters);
                    }}
                />
            </div>
            <Table
                url="serverless/zipFiles"
                name="codeZips"
                columns={columns}
                filters={filters}
                defaultSortBy={[{id: COLUMN_IDS.TIME, desc: true}]}
            />
        </div>
    );
}

export default ZipsTable;