import React, { useState } from 'react';
import { ICON_NAMES } from 'components/Icon';
import IconWithTooltip from 'components/IconWithTooltip';
import { CONTROLLER_TYPES } from 'utils/systemConsts';
import CommandModal from './CommandModal';

const AdminControllerCommandButton = ({id, isReadOnlyUser, type, isAgentAccessAllowed}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <React.Fragment>
            <IconWithTooltip
                name={ICON_NAMES.CMD}
                onClick={() => setIsOpen(true)}
                tooltipId={`${id}-cmd`}
                tooltipText="CMD"
                disabled={isReadOnlyUser || type !== CONTROLLER_TYPES.KUBERNETES || !isAgentAccessAllowed}
            />
            {isOpen && <CommandModal agentId={id} onClose={() => setIsOpen(false)} />}
        </React.Fragment>
    )
}

export default AdminControllerCommandButton;