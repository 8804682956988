import React from 'react';
import classnames from 'classnames';
import { RULE_ACTIONS } from 'utils/systemConsts';
import BaseRuleItem from '../../BaseRuleItem';
import { DEFAULT_RULE_NAME, getRuleValueByType, DEFAULT_RULE_TYPES } from './utils';

const DefaultRuleItem = ({defaultRule, isReadOnly, onRuleEdit, isMarked}) => {
    const {type, action} = defaultRule;
    const isBlock = action === RULE_ACTIONS.BLOCK;

    return (
        <BaseRuleItem 
            name={DEFAULT_RULE_NAME}
            type={type}
            onEdit={onRuleEdit}
            onDoubleClick={onRuleEdit}
            contentRenderer={() => getRuleValueByType(type, isBlock)}
            className={classnames({selected: isMarked})}
            withActions={false}
            blocked={isBlock}
            allowed={type === DEFAULT_RULE_TYPES.ALLOW_ALL}
            isReadOnly={isReadOnly}
        />
    );
}

export default DefaultRuleItem;