import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { isNull } from 'lodash';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useAuthState } from 'context/AuthProvider';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { useDelete, usePrevious } from 'hooks';
import { ICON_NAMES } from 'components/Icon'; 
import IconWithTitle from 'components/IconWithTitle';
import FormModal from 'components/FormModal';
import Table, { tableUtils } from 'components/Table';
import ModalConfirmation from 'components/ModalConfirmation';
import IconWithTooltip from 'components/IconWithTooltip';
import TrustedSignerForm from '../TrustedSignerForm';
import { hasActiveValidation } from '../utils';

import './trusted-signers-list.scss';

const TRUSTED_SIGNERS_URL = "trustedSigners";

const TrustedSignersList = () => {
    const {showServerlessSecurity} = useDisplayConfigState();

    const columns = useMemo(() => [
        {
            Header: "Trust Profile name",
            id: "name",
            accessor: "name",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "No. Development keys",
            id: "keysCount",
            accessor: original => original.keys.length,
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "No. Clusters",
            id: "clustersCount",
            accessor: original => original.trustedSignerClusters.length,
            disableSortBy: true
        },
        ...(!showServerlessSecurity ? [] : [{
            Header: "No. Cloud Accounts",
            id: "cloudAccountsCount",
            accessor: original => original.trustedSignerCloudAccounts.length,
            disableSortBy: true
        }]),
        {
            Header: "Validation",
            id: "validation",
            Cell: ({row}) => {
                const {trustedSignerClusters, trustedSignerCloudAccounts} = row.original;

                const withActiveValidation = trustedSignerClusters.find(({validation}) => hasActiveValidation(validation)) ||
                    trustedSignerCloudAccounts.find(({validation}) => hasActiveValidation(validation));

                return (
                    <tableUtils.StatusRenderer
                        isActive={withActiveValidation}
                        isWarning={!withActiveValidation}
                        disabledText="Not active"
                    />
                )
            },
            alwaysShow: true
        },

    ], [showServerlessSecurity]);

    const [{deleting}, deleteSigner] = useDelete(TRUSTED_SIGNERS_URL);
    const prevDeleting = usePrevious(deleting);

    const {isReadOnlyUser} = useAuthState();

    const [selectedSignerData, setSelectedSignerData] = useState(null);
    const closeSignerModal = () => setSelectedSignerData(null);

    const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationId(null);

    const {path} = useRouteMatch();
    const history = useHistory();

    const onActionClick = (event, action) => {
        event.stopPropagation();
        event.preventDefault();

        action();
    }

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = useCallback(() => setRefreshTimestamp(Date()), []);

    useEffect(() => {
        if (prevDeleting && !deleting) {
            refreshTableData();
        }
    }, [prevDeleting, deleting, refreshTableData]);

    return (
        <div className="trusted-signers-page-wrapper">
            <IconWithTitle
                name={ICON_NAMES.ADD}
                title="New Trust Profile"
                className="add-new-button"
                onClick={() => setSelectedSignerData({})}
                disabled={isReadOnlyUser || deleting}
            />
            <Table
                url={TRUSTED_SIGNERS_URL}
                name="trustedSigners"
                columns={columns}
                actionsComponent={({original}) => {
                    const {id} = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;

                    return (
                        <div className="signers-row-actions">
                            <IconWithTooltip
                                name={ICON_NAMES.EDIT}
                                onClick={event => onActionClick(event, () => setSelectedSignerData(original))}
                                tooltipId={editTooltipId}
                                tooltipText="Edit profile"
                                disabled={isReadOnlyUser}
                            />
                            {!isReadOnlyUser &&
                                <IconWithTooltip
                                    name={ICON_NAMES.DELETE}
                                    onClick={event => onActionClick(event, () => setDeleteConfirmationId(original.id))}
                                    tooltipId={deleteTooltipId}
                                    tooltipText="Delete profile"
                                    disabled={isReadOnlyUser}
                                />
                            }
                        </div>
                    );
                }}
                actionsCellWidth={80}
                withPagination={false}
                refreshTimestamp={refreshTimestamp}
                onLineClick={({id}) => history.push(`${path}/${id}`)}
            />
            {!isNull(selectedSignerData) &&
                <FormModal
                    onClose={closeSignerModal}
                    formComponent={TrustedSignerForm}
                    formProps={{
                        initialData: {...selectedSignerData},
                        onFormSubmitSuccess: () => {
                            closeSignerModal();
                            refreshTableData();
                        }
                    }}
                />
            }
            {!isNull(deleteConfirmationId) &&
                <ModalConfirmation 
                    title="Are you sure?"
                    message="This operation cannot be reverted"
                    confirmTitle="Delete"
                    onCancel={closeDeleteConfirmation}
                    onConfirm={() => {
                        closeDeleteConfirmation();
                        deleteSigner(deleteConfirmationId);
                    }}
                    confirmAlert
                />
            }
        </div>
    )
}

export default TrustedSignersList;