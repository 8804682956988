import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { CI_CD_ENFORCEMENT_OPTIONS, DOCKERFILE_SCAN_SEVERITY } from 'utils/systemConsts';
import { FETCH_METHODS, usePrevious } from 'hooks';
import FormWrapper, { validators, TextField, SelectField, TextAreaField, VulnerabilityField, useFormikContext } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';

import './ci-scan-policy-form.scss';

const ENFORCEMENT_REQUIRED_ERROR = "You must select one of the enforcement options";

const FormFields = () => {
    const {values, setFieldValue} = useFormikContext();
    const {vulnerabilityCiPolicy, dockerfileScanCiPolicy} = values;
    const {permissibleVulnerabilityLevel} = vulnerabilityCiPolicy;
    const prevPermissibleVulnerabilityLevel = usePrevious(permissibleVulnerabilityLevel);
    const {permissibleDockerfileScanSeverity} = dockerfileScanCiPolicy;
    const prevPermissibleDockerfileScanSeverity = usePrevious(permissibleDockerfileScanSeverity);

    useEffect(() => {
        if (permissibleVulnerabilityLevel === prevPermissibleVulnerabilityLevel) {
            return;
        }

        if (permissibleVulnerabilityLevel === "") {
            setFieldValue("vulnerabilityCiPolicy.enforcementOption", "");
        }
    }, [permissibleVulnerabilityLevel, prevPermissibleVulnerabilityLevel, setFieldValue]);

    useEffect(() => {
        if (permissibleDockerfileScanSeverity === prevPermissibleDockerfileScanSeverity) {
            return;
        }

        if (permissibleDockerfileScanSeverity === "") {
            setFieldValue("dockerfileScanCiPolicy.enforcementOption", "");
        }
    }, [permissibleDockerfileScanSeverity, prevPermissibleDockerfileScanSeverity, setFieldValue]);
    
    return (
        <React.Fragment>
            <TextField name="name" label="Name" validate={validators.validateRequired} />
            <TextAreaField name="description" label="Description" />
            <div className="ci-policy-item-container">
                <VulnerabilityField
                    name="vulnerabilityCiPolicy.permissibleVulnerabilityLevel"
                    label="Permissive Vulnerability Level"
                />
                <SelectField
                    name="vulnerabilityCiPolicy.enforcementOption"
                    label="Enforcement Option"
                    items={Object.values(CI_CD_ENFORCEMENT_OPTIONS)}
                    clearable={true}
                    disabled={!permissibleVulnerabilityLevel}
                />
            </div>
            <div className="ci-policy-item-container">
                <SelectField 
                    name="dockerfileScanCiPolicy.permissibleDockerfileScanSeverity"
                    label="Permissive Dockerfile Scan Severity"
                    items={Object.values(DOCKERFILE_SCAN_SEVERITY).map(({label, value}) => ({
                        value,
                        label,
                        icon: "alert-cis",
                        iconClass: classnames("dockerfile-scan-severity-icon", value.toLowerCase())
                    }))}
                    clearable={true}
                />
                <SelectField 
                    name="dockerfileScanCiPolicy.enforcementOption"
                    label="Enforcement Option"
                    items={Object.values(CI_CD_ENFORCEMENT_OPTIONS)}
                    clearable={true}
                    disabled={!permissibleDockerfileScanSeverity}
                />
            </div>
        </React.Fragment>
    )
}

const CiScanPolicyForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const initialValues = {
        name: "",
        description: "",
        ...initialData
    };

    initialValues.name = initialValues.name || ""; //name should not be null
    initialValues.description = initialValues.description || ""; //description should not be null

    initialValues.vulnerabilityCiPolicy = !isEmpty(initialValues.vulnerabilityCiPolicy) ?
        initialValues.vulnerabilityCiPolicy : {permissibleVulnerabilityLevel: "", enforcementOption: ""};

    initialValues.dockerfileScanCiPolicy = !isEmpty(initialValues.dockerfileScanCiPolicy) ?
        initialValues.dockerfileScanCiPolicy : {permissibleDockerfileScanSeverity: "", enforcementOption: ""};
    
    const isEditForm = initialValues && initialValues.id;
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withBottomMargin withTopMargin>{`${isEditForm ? "Edit" : "New"} CI Scan Configuration`}</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl="ciPolicy"
                validate={formValues => {
                    const formErrors = {};

                    const {dockerfileScanCiPolicy, vulnerabilityCiPolicy} = formValues;

                    if (!vulnerabilityCiPolicy.permissibleVulnerabilityLevel && !dockerfileScanCiPolicy.permissibleDockerfileScanSeverity) {
                        formErrors.vulnerabilityCiPolicy = {permissibleVulnerabilityLevel: ENFORCEMENT_REQUIRED_ERROR};
                        formErrors.dockerfileScanCiPolicy = {permissibleDockerfileScanSeverity: ENFORCEMENT_REQUIRED_ERROR};
                    }
                    
                    if (!!vulnerabilityCiPolicy.permissibleVulnerabilityLevel && !vulnerabilityCiPolicy.enforcementOption) {
                        formErrors.vulnerabilityCiPolicy = {enforcementOption: validators.ERROR_MSG_IS_REQUIRED};
                    }

                    if (!!dockerfileScanCiPolicy.permissibleDockerfileScanSeverity && !dockerfileScanCiPolicy.enforcementOption) {
                        formErrors.dockerfileScanCiPolicy = {enforcementOption: validators.ERROR_MSG_IS_REQUIRED};
                    }

                    return formErrors;
                }}
                getSubmitParams={formValues => {
                    const {id, ...submitData} = formValues;

                    const {dockerfileScanCiPolicy, vulnerabilityCiPolicy} = submitData;

                    if (!dockerfileScanCiPolicy.permissibleDockerfileScanSeverity) {
                        submitData.dockerfileScanCiPolicy = null;
                    }

                    if (!vulnerabilityCiPolicy.permissibleVulnerabilityLevel) {
                        submitData.vulnerabilityCiPolicy = null;
                    }

                    return !isEditForm ? {submitData} : {
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <FormFields />
            </FormWrapper>
        </div>
    )
}

export default CiScanPolicyForm;