import React from 'react';
import { TextField, RadioField, validators } from 'components/Form';
import { RULE_ACTIONS } from 'utils/systemConsts';

const FormRuleProperties = ({isReadOnlyUser}) => (
    <React.Fragment>
        <TextField name="name" label="Rule Name" validate={validators.validateRequired} disabled={isReadOnlyUser} />
        <RadioField
            name="action"
            label="Action"
            validate={validators.validateRequired}
            items={[
                {value: RULE_ACTIONS.ALLOW, label: "Allow serverless functions to run"},
                {value: RULE_ACTIONS.DETECT, label: "Detect serverless functions that violate the following rule"},
                {value: RULE_ACTIONS.BLOCK, label: "Block serverless functions that violate the following rule"},
            ]}
            disabled={isReadOnlyUser}
        />
    </React.Fragment>
)

export default FormRuleProperties;