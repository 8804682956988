import React from 'react';
import classnames from 'classnames';
import InputWithCopy from 'components/InputWithCopy';

import './instructions-item.scss';

const InstructionsItem = ({title, isInnerTitle=false, inputText, className, withBullet=true}) => (
    <div className={classnames("instructions-item", {[className]: className})}>
        <div className="title-container">
            {withBullet && <div className={classnames("list-indicator", {"is-inner": isInnerTitle})}></div>}
            <div className={classnames("item-title", {"with-bullet": withBullet})}>{title}</div>
        </div>
        {!!inputText && <InputWithCopy value={inputText} />}
    </div>
);

export default InstructionsItem;