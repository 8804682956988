import React from 'react';
import { TooltipWrapper, TOOLTIP_PLACEMENTS } from 'components/Tooltip';
import { FUZZING_STATUS_ITEMS } from 'layout/Apis/utils';
import FuzzingTestLoaderDisplayWrapper from 'layout/Apis/FuzzingTestLoaderDisplayWrapper';

import './api-test-display.scss';

const ApiTestDisplay = ({id, status, startTime, endTime, statusMessage}) => (
    <FuzzingTestLoaderDisplayWrapper
        id={id}
        status={status}
        startTime={startTime}
        endTime={endTime}
        statusMessage={statusMessage}
        displayComponent={(props) => {
            const {label, color, getTooltip} = FUZZING_STATUS_ITEMS[props.status] || {};

            const StatusText = () => <div className="status-text">{label}</div>;

            return (
                <div className="test-status-wrapper">
                    <div className="status-indecator" style={{backgroundColor: color}}></div>
                    {!getTooltip ? <StatusText /> :
                        <TooltipWrapper id={`test-status-indicator-tooltip-${id}`} text={getTooltip(props)} placement={TOOLTIP_PLACEMENTS.TOP}>
                            <StatusText />
                        </TooltipWrapper>
                    }
                </div>
            )
        }}
    />
)

export default ApiTestDisplay;