import React, { useEffect, useState } from 'react';
import { isEmpty, pickBy, isNull } from 'lodash';
import { REGISTRY_TYPES_ITEMS } from 'utils/systemConsts';
import { FETCH_METHODS, usePrevious, useFetch } from 'hooks';
import FormWrapper, { validators, TextField, SelectField, MultiselectField, useFormikContext } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';
import TestConnectionButton from 'components/TestConnectionButton';
import { nameIdToValueLabel } from 'utils/apiUtils';

const STANDARD_CREDENTIALS_TYPES = [
    REGISTRY_TYPES_ITEMS.GCP.value,
    REGISTRY_TYPES_ITEMS.AZURE.value,
    REGISTRY_TYPES_ITEMS.OTHER.value
];

const formatCredentils = ({credentials, type}) => {
    const {registryCredentialsType, ...cleanCredentials} = pickBy(credentials || {}, (value) => !isEmpty(value));

    if (isEmpty(cleanCredentials)) {
        return null
    }

    if (type === REGISTRY_TYPES_ITEMS.AWS.value) {
        const formattedCredentials = {...cleanCredentials, registryCredentialsType: "AwsRegistryCredentials"};

        if (!!formattedCredentials.accessKeyId && !formattedCredentials.secretAccessKey) {
            formattedCredentials.secretAccessKey = null;
        }

        return formattedCredentials;
    }

    const formattedCredentials = {
        ...cleanCredentials,
        registryCredentialsType: type === REGISTRY_TYPES_ITEMS.JFROG.value ? "JfrogRegistryCredentials" : "StandardRegistryCredentials"
    }

    if (!!formattedCredentials.username && !formattedCredentials.password) {
        formattedCredentials.password = null;
    }

    return formattedCredentials;
}

const StandardCredentials = ({initialValues, credentials}) => {
    const passwordFieldProps = {
        type: "password",
        name: "credentials.password",
        label: "Password",
        withPasswordShow: true
    };
    const userFieldProps = {
        name: "credentials.username",
        label: "Username"
    }

    const initialUsername = initialValues.credentials.username;
    const currUsername = credentials.username;

    if (!!credentials.password) {
        userFieldProps.validate = validators.validateRequired;
    }
    if (!!currUsername) {
        if (initialUsername === currUsername) {
            passwordFieldProps.placeholder = "If not edited existing password will be used";
        } else {
            passwordFieldProps.validate = validators.validateRequired;
        }
    }

    return (
        <React.Fragment>
            <TextField {...userFieldProps} />
            <TextField {...passwordFieldProps} />
        </React.Fragment>
    );
}

const AWSCredentials = ({initialValues, credentials}) => {
    const passwordFieldProps = {
        type: "password",
        name: "credentials.secretAccessKey",
        label: "Secret Access Key"
    };
    const userFieldProps = {
        name: "credentials.accessKeyId",
        label: "Access Key ID"
    }

    const initialAccessKeyId = initialValues.credentials.accessKeyId;
    const currAccessKeyId = credentials.accessKeyId;

    if (!!credentials.secretAccessKey) {
        userFieldProps.validate = validators.validateRequired;
    }
    if (!!currAccessKeyId) {
        if (initialAccessKeyId === currAccessKeyId) {
            passwordFieldProps.placeholder = "If not edited existing secret will be used";
        } else {
            passwordFieldProps.validate = validators.validateRequired;
        }
    }
    
    return (
        <React.Fragment>
            <TextField {...userFieldProps} />
            <TextField {...passwordFieldProps} />
            <TextField name="credentials.region" label="Region" validate={validators.validateRequired} />
        </React.Fragment>
    );
}

const JFrogCredentials = (props) => {
    const {isEditForm} = props;

    const tokenFieldProps = {
        type: "password",
        name: "credentials.xrayToken",
        label: "Token",
        withPasswordShow: true,
        tooltipText: "Enter a JFrog Xray Admin Token to pull Xray information about images from Artifactory"
    };
    if (isEditForm) {
        tokenFieldProps.placeholder = "If not edited existing token will be used";
    }

    return (
        <React.Fragment>
            <StandardCredentials {...props} />
            <TextField {...tokenFieldProps} />
        </React.Fragment>
    );
}

const FormFields = ({clustersData, isEditForm, initialValues}) => {
    const {values: formValues, setFieldValue, isValid, validateForm} = useFormikContext();

    const {type, credentials} = formValues;
    const prevType = usePrevious(type);
    const credentialsJSON = JSON.stringify(credentials);

    const [isTesting, setIsTesting] = useState(false);

    const [{loading, error}, testConnection] = useFetch("registries/testConnection", {loadOnMount: false});
    const doTestConnection = () => {
        const {id, credentials, ...data} = formValues;
        
        setIsTesting(true);

        testConnection({
            formatUrl: url => isEditForm ? `${url}/${id}` : url,
            method: FETCH_METHODS.POST,
            submitData: {
                ...data,
                credentials: formatCredentils({credentials, type})
            }
        });
    };

    useEffect(() => {
        if (!!prevType && type !== prevType) {
            setFieldValue("credentials.secretAccessKey", "");
            setFieldValue("credentials.accessKeyId", "");
            setFieldValue("credentials.region", "");
            setFieldValue("credentials.password", "");
            setFieldValue("credentials.username", "");
            setFieldValue("credentials.xrayToken", "");

            setIsTesting(false);
        }
    }, [type, prevType, setFieldValue]);

    useEffect(() => {
        validateForm();
    }, [validateForm, credentialsJSON]);
    
    return (
        <React.Fragment>
            <TextField name="url" label="Registry URL" validate={validators.validateRequired} />
            <MultiselectField 
                name="clusterIds"
                label="Clusters"
                items={nameIdToValueLabel(clustersData, item => !item.kubernetesSecurity)}
                validate={validators.validateRequired}
            />
            <SelectField
                name="type"
                label="Type"
                items={Object.values(REGISTRY_TYPES_ITEMS)}
                validate={validators.validateRequired}
                clearable={false}
            />
            {type === REGISTRY_TYPES_ITEMS.AWS.value && <AWSCredentials isEditForm={isEditForm} initialValues={initialValues} credentials={credentials} />}
            {type === REGISTRY_TYPES_ITEMS.JFROG.value &&
                <JFrogCredentials
                    isEditForm={isEditForm}
                    initialValues={initialValues}
                    credentials={credentials}
                    isValid={isValid}
                    formValues={formValues}
                />
            }
            {STANDARD_CREDENTIALS_TYPES.includes(type) && <StandardCredentials isEditForm={isEditForm} initialValues={initialValues} credentials={credentials} />}
            <TestConnectionButton
                doTestConnection={doTestConnection}
                isTesting={isTesting}
                loading={loading}
                error={error}
                disabled={!isValid || loading}
            />
        </React.Fragment>
    );
}

const updateNullProps = (initialValues, credentialsKey) => {
    if (isNull(initialValues.credentials[credentialsKey])) {
        initialValues.credentials[credentialsKey] = ""; //text field cannot be null
    }
}

const RegistryForm = ({initialData, clustersData, onFormSubmitSuccess, onDirtyChanage}) => {
    const initialCredentialsValues = {
        secretAccessKey: "",
        accessKeyId: "",
        region: "",
        password: "",
        username: "",
        xrayToken: ""
    };
    const initialValues = {
        url: "",
        clusterIds: [],
        type: "",
        ...initialData,
        credentials: {...initialCredentialsValues, ...initialData.credentials}
    };
    
    const isEditForm = initialValues && initialValues.id;

    updateNullProps(initialValues, "secretAccessKey");
    updateNullProps(initialValues, "accessKeyId");
    updateNullProps(initialValues, "region");
    updateNullProps(initialValues, "password");
    updateNullProps(initialValues, "username");
    updateNullProps(initialValues, "xrayToken");
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withBottomMargin withTopMargin>{`${isEditForm ? "Edit" : "New"} Registry`}</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl="registries"
                getSubmitParams={formValues => {
                    const {id, ...submitData} = formValues;

                    submitData.credentials = formatCredentils({credentials: submitData.credentials, type: submitData.type});

                    return !isEditForm ? {submitData} : {
                        method: FETCH_METHODS.PUT,
                        formatUrl: url => `${url}/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <FormFields
                    clustersData={clustersData}
                    isEditForm={isEditForm}
                    initialValues={initialValues}
                />
            </FormWrapper>
        </div>
    )
}

export default RegistryForm;