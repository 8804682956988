import React, { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import { useFetch, useDelete, usePrevious, FETCH_METHODS } from 'hooks';
import Loader from 'components/Loader';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import FormModal from 'components/FormModal';
import ListValueItemsDisplay from 'components/ListValueItemsDisplay';
import BaseUnorderedPolicyItem from '../BaseUnorderedPolicyItem';
import { getPspDataItems } from './utils';
import PodPolicyAdvisor from './PodPolicyAdvisor';
import SeccompProfiles from './SeccompProfiles';
import PspProfileForm from './PspProfileForm';

import './pod-policies.scss';

const PodPolicies = () => {
    const {isReadOnlyUser} = useAuthState();

    const [selectedProfileData, setSelectedProfileData] = useState(null);
    const closeProfileForm = () => setSelectedProfileData(null);

    const [isSeccompOpen, setIsSeccompOpen] = useState(null);
    const toggleSeccompOpen = () => setIsSeccompOpen(isOpen => !isOpen);

    const [{loading: seccompLoading, data: seccompData}] = useFetch("seccompProfiles");

    const [{loading, data}, fetchProfilesData] = useFetch("podSecurityPolicyProfiles");

    const [{deleting, error: deleteError}, deleteProfile] = useDelete("podSecurityPolicyProfiles");
    const prevDeleting = usePrevious(deleting);

    const [{loading: advisorLoading}, submitAdvisorData] = useFetch("podSecurityPolicyProfiles/batch", {loadOnMount: false});
    const prevAdvisorLoading = usePrevious(advisorLoading);

    useEffect(() => {
        if (prevAdvisorLoading && !advisorLoading) {
            fetchProfilesData();
        }
    }, [advisorLoading, prevAdvisorLoading, fetchProfilesData]);

    useEffect(() => {
        if (prevDeleting && !deleting && isNull(deleteError)) {
            fetchProfilesData();
        }
    }, [deleting, prevDeleting, deleteError, fetchProfilesData]);

    if (loading || deleting || seccompLoading) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <div className="psp-profiles-container">
                <IconWithTitle
                    className="new-psp-button"
                    name={ICON_NAMES.ADD}
                    title="New Pod Security Standards Profile"
                    onClick={() => setSelectedProfileData({})}
                    disabled={isReadOnlyUser}
                />
                <div className="seccomp-button" onClick={toggleSeccompOpen}>Seccomps</div>
                <div className={classnames("psp-profiles-content", {"is-seccomp-open": isSeccompOpen})}>
                    <div className="seccomp-profiles">
                        {isSeccompOpen && <SeccompProfiles onClose={toggleSeccompOpen} />}
                    </div>
                    <div className="psp-profiles">
                        {
                            data.map(item => {
                                const {id, name, description, isSecurecnDefaultProfile, seccompProfile} = item;

                                const displayItems = getPspDataItems(item);
                                if (!!seccompProfile) {
                                    const seccompProfileItem = seccompData.find(item => item.id === seccompProfile) || {};
                                    displayItems.push({name: "Seccomp profile", values: [seccompProfileItem.name]});
                                }

                                return (
                                    <BaseUnorderedPolicyItem
                                        key={id}
                                        id={id}
                                        name={name}
                                        description={description}
                                        disabled={isReadOnlyUser}
                                        canEditDelete={!isSecurecnDefaultProfile}
                                        onEdit={() => setSelectedProfileData(item)}
                                        onCopy={() => {
                                            const {id, name, description, ...copyData} = item;
                                            setSelectedProfileData(copyData)
                                        }}
                                        onDelete={() => deleteProfile(id)}
                                    >
                                        <ListValueItemsDisplay items={displayItems} />
                                    </BaseUnorderedPolicyItem>
                                )
                            })
                        }
                    </div>
                    
                </div>
                {!isNull(selectedProfileData) &&
                    <FormModal
                        onClose={closeProfileForm}
                        formComponent={PspProfileForm}
                        formProps={{
                            initialData: {...selectedProfileData},
                            onFormSubmitSuccess: () => {
                                closeProfileForm();
                                fetchProfilesData();
                            }
                        }}
                    />}
            </div>
            <PodPolicyAdvisor
                isReadOnly={isReadOnlyUser}
                submitAdvisorData={data => submitAdvisorData({submitData: data, method: FETCH_METHODS.POST})}
            />
        </React.Fragment>
    )
}

export default PodPolicies;