import React, { useState } from 'react';
import classnames from 'classnames';
import { useFetch } from 'hooks';
import Text, { TEXT_TYPES } from 'components/Text';
import Modal from 'components/Modal';
import Button from 'components/Button';
import InputWithCopy from 'components/InputWithCopy';
import DropdownSelect from 'components/DropdownSelect';
import Loader from 'components/Loader';
import { ICON_NAMES } from 'components/Icon';
import { DOCUMENTATION_URL } from 'utils/systemConsts';

const INSTALLATION_TYPE_ITEMS = {
    SINGLE: {value: "SINGLE", label: "Single account"},
    NULTI: {value: "NULTI", label: "Multiple accounts"},
}

const ExtenalLinkButton = ({children, href}) => (
    <div style={{marginTop: "20px", float: "right"}}><a href={href} target="_blank" rel="noopener noreferrer"><Button>{children}</Button></a></div>
)

const CopyInputWithTitle = ({value, tooltipText, title}) => (
    <div>
        <div style={{fontWeight: "bold", marginTop: "10px", marginBottom: "5px"}}>{title}</div>
        <InputWithCopy value={value} tooltipId={title} tooltipText={tooltipText} />
    </div>
)

const AwsSingleInstallation = ({installationUrl}) => (
    <React.Fragment>
        <div>Please log in to the relevant cloud account and deploy the CloudFormation stack in order to track security threats.</div>
        <div>The CloudFormation stack grants read-only permission through an IAM Role to provide serverless security for your AWS Account.</div>
        <ExtenalLinkButton href={installationUrl}>Deploy</ExtenalLinkButton>
    </React.Fragment>
)

const AwsMultiInstallation = ({externalId}) => (
    <React.Fragment>
        <div>Please copy the external ID, as it is used in later steps</div>
        <InputWithCopy value={externalId} tooltipText="Copy ID" className="external-id-copy" />
        <div>Follow the steps in the documentation to complete the connection in your AWS account.</div>
        <ExtenalLinkButton href={`${DOCUMENTATION_URL}/docs/serverless-functions#connect-multiple-accounts`}>See documentation</ExtenalLinkButton>
    </React.Fragment>
)

const AwsInstallation = ({installationType}) => {
    const [{loading, data, error}] = useFetch("cloudAccounts/installationDetails");

    if (error) {
        return null;
    }

    const {externalId, installationUrl} = data || {};

    if (loading) {
        return (
            <Loader />
        )
    }
    
    return (
        <div className="aws-installtion-details">
            {installationType === INSTALLATION_TYPE_ITEMS.SINGLE.value ?
                <AwsSingleInstallation installationUrl={installationUrl} /> : <AwsMultiInstallation externalId={externalId} />
            }
        </div>
    )
}

const AzureInstallation = () => {
    const [{loading, data, error}] = useFetch("cloudAccounts/azureInstallationDetails");

    if (error) {
        return null;
    }

    const {serviceAccountId, accessKey, secretAccessKey, templateUrl} = data || {};

    if (loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className="azure-installtion-details">
            <div>Copy and paste the following values in Azure</div>
            <CopyInputWithTitle value={serviceAccountId} title="Service account ID" tooltipText="Copy ID" />
            <CopyInputWithTitle value={accessKey} title="Access key" tooltipText="Copy key" />
            <CopyInputWithTitle value={secretAccessKey} title="Secret access key" tooltipText="Copy key" />
            <ExtenalLinkButton href={templateUrl}>Connect in Azure</ExtenalLinkButton>
        </div>
    )
}

export const PROVIDER_ITEMS = {
    AWS: {value: "AWS", label: "AWS", component: AwsInstallation, icon: ICON_NAMES.FUNCTION},
    AZURE: {value: "AZURE", label: "Azure", component: AzureInstallation, icon: ICON_NAMES.FUNCTION_AZURE}
}

const SelectField = ({title, ...selectProps}) => (
    <div style={{width: "240px"}}>
        <div style={{fontSize: "10px", fontWeight: "bold", marginBottom: "3px"}}>{title}</div>
        <DropdownSelect {...selectProps} />
    </div>
)

const AccountInstallDataModal = ({onClose}) => {
    const [selectedProvider, setSelectedProvider] = useState("");
    const [installationType, setInstallationType] = useState(INSTALLATION_TYPE_ITEMS.SINGLE.value);

    const isAzureProvider = selectedProvider === PROVIDER_ITEMS.AZURE.value;
    const DisplayComponent = PROVIDER_ITEMS[selectedProvider]?.component;
    
    return (
        <Modal className={classnames("cloud-scanner-install-modal", {"is-large": isAzureProvider})} center={true} onClose={onClose}>
            <Text type={TEXT_TYPES.TITLE_MEDIUM} withTopMargin className="modal-title">Connect account</Text>
            <div className="provider-selectors-wrapper">
                <SelectField
                    title="Provider"
                    name="provider"
                    defaultselected={selectedProvider}
                    items={Object.values(PROVIDER_ITEMS).map(({value, label}) => ({value, label}))}
                    onChange={provider => setSelectedProvider(provider)}
                    placeholder="Select provider..."
                />
                {selectedProvider === PROVIDER_ITEMS.AWS.value &&
                    <SelectField
                        title="Configuration type"
                        name="installationType"
                        defaultselected={installationType}
                        items={Object.values(INSTALLATION_TYPE_ITEMS)}
                        onChange={type => setInstallationType(type)}
                    />
                }
            </div>
            {!!DisplayComponent && <DisplayComponent installationType={installationType} />}
        </Modal>
    );
}

export default AccountInstallDataModal;