import { useMemo } from "react";
import Table from "components/Table";
import NodesSubComponent from "./TestNodesTable";
import { StatusIcon } from '../utils';

const ClusterSubTestTable = ({ data, onChange, expandedIndex }) => {
  const tests = data?.tests?.map((t) => ({ ...t, id: t.index.split(".")[2] }));

  const columns = useMemo(
    () => [
      {
        Header: "Recommendation",
        id: "test",
        Cell: ({ row }) => (
          <div className="cluster-test-row">
            <StatusIcon status={row.original.status} />
            <div style={{marginLeft: "5px"}}>{`${row.original.index}. ${row.original.description}`}</div>
          </div>
        ),
        disableSortBy: true,
        minWidth: 250,
      },
      {
        Header: "Compliance",
        id: "compliance",
        Cell: ({ row }) => `${row.original?.compliance}%`,
        disableSortBy: false,
        maxWidth: 50,
      },
      {
        Header: "Type",
        accessor: "type",
        disableSortBy: true,
        maxWidth: 50,
      },
      {
        Header: "Remediation",
        accessor: "remediation",
        disableSortBy: false,
        minWidth: 600,
      },
    ],
    []
  );

  const buildExpandedObject = () =>
    expandedIndex
      ? {
          [expandedIndex.split(".")[2]]: true,
        }
      : {};

  return (
    <div className="sub-test-table">
      <Table
        data={tests || []}
        columns={columns}
        hideRefresh
        hideColumnControl
        withPagination={false}
        innerRowComponenet={NodesSubComponent}
        innerRowComponentProps={{ onChange }}
        initialExpandedRow={buildExpandedObject()}
      />
    </div>
  );
};

export default ClusterSubTestTable;
