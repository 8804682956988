import { create } from 'context/utils';
import { API_TYPE_ITEMS } from './utils';

const FILTER_ACTIONS = {
    SET_INTERNAL_FILTERS: "SET_INTERNAL_FILTERS",
    SET_EXTERNAL_FILTERS: "SET_EXTERNAL_FILTERS"
};

export const API_FILTER_KEYS = {
    NAME: "name",
    NAMESPACES: "namespacesFilter"
}

const initialFilterData = {
    [API_FILTER_KEYS.NAME]: "",
    apiPolicyProfiles: [],
    risk: [],
    [API_FILTER_KEYS.NAMESPACES]: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case FILTER_ACTIONS.SET_INTERNAL_FILTERS: {
            return {
                ...state,
                internalFilters: {...action.payload}
            }
        }
        case FILTER_ACTIONS.SET_EXTERNAL_FILTERS: {
            return {
                ...state,
                externalFilters: {...action.payload}
            }
        }
        default:
            return state;
    }
}

export const setFitlers = (dispatch, {type, filters}) => dispatch({
    type: type === API_TYPE_ITEMS.INTERNAL.value ? FILTER_ACTIONS.SET_INTERNAL_FILTERS :  FILTER_ACTIONS.SET_EXTERNAL_FILTERS,
    payload: filters
});

export const getFitlers = (state, type) => type === API_TYPE_ITEMS.INTERNAL.value ? state.internalFilters : state.externalFilters;

export const [FilterProvider, useFilterState, useFilterDispatch] = create(reducer, {
    internalFilters: {...initialFilterData}, externalFilters: {...initialFilterData}
});

