import { ALL_KEY } from '../utils';

export const EMPTY_USER_ITEM = {userType: ALL_KEY, userName: []};
export const EMPTY_RESOURCE_ITEM = {kind: ALL_KEY, group: []};

export const CUSTOM_RULE_ID = "CUSTOM_RULE_ID";

export const RULE_SCOPE_ITEMS = {
    ENVIRONMENT: {
        value: "KubernetesApiEnvironmentScope",
        label: "By Environment"
    },
    CLUSTER: {
        value: "KubernetesApiClustersScope",
        label: "By Cluster"
    },
    ANY: {
        value: "KubernetesApiAnyScope",
        label: "Any"
    }
};