import React, { useEffect } from 'react';
import { SelectField, MultiselectField, useFormikContext, RadioField, validators, AsyncSelectField } from 'components/Form';
import { usePrevious, useFetch } from 'hooks';
import { valueToValueLabelFromProp } from 'utils/apiUtils';

export const IGNORE_RISK_TYPES = {
    ClusterIgnoredRisk: "ClusterIgnoredRisk",
    AnyClusterIgnoredRisk: "AnyClusterIgnoredRisk",
    AnyEnvironmentIgnoredRisk: "AnyEnvironmentIgnoredRisk",
    EnvironmentIgnoredRisk: "EnvironmentIgnoredRisk",
    WorkloadIgnoredRisk: "WorkloadIgnoredRisk"
}

const SCOPE_ITEMS = {
    [IGNORE_RISK_TYPES.ClusterIgnoredRisk]: {
        value: IGNORE_RISK_TYPES.ClusterIgnoredRisk,
        label: "Cluster",
        subScopeItems: [
            {value: IGNORE_RISK_TYPES.ClusterIgnoredRisk, label: "By name"},
            {value: IGNORE_RISK_TYPES.AnyClusterIgnoredRisk, label: "Any"}
        ]
    },
    [IGNORE_RISK_TYPES.EnvironmentIgnoredRisk]: {
        value: IGNORE_RISK_TYPES.EnvironmentIgnoredRisk,
        label: "Environment",
        subScopeItems: [
            {value: IGNORE_RISK_TYPES.EnvironmentIgnoredRisk, label: "By name"},
            {value: IGNORE_RISK_TYPES.AnyEnvironmentIgnoredRisk, label: "Any"}
        ]
    },
    [IGNORE_RISK_TYPES.WorkloadIgnoredRisk]: {
        value: IGNORE_RISK_TYPES.WorkloadIgnoredRisk,
        label: "Workload"
    }
}

export const getSubScobeFromType = ignoredRiskType => {
    if (ignoredRiskType === IGNORE_RISK_TYPES.AnyClusterIgnoredRisk) {
        return IGNORE_RISK_TYPES.ClusterIgnoredRisk;
    }
    if (ignoredRiskType === IGNORE_RISK_TYPES.AnyClusterIgnoredRisk) {
        return IGNORE_RISK_TYPES.ClusterIgnoredRisk;
    }

    return ignoredRiskType;
}

const WorkloadScopeFormFields = ({clusterItems}) => {
    const {values, setFieldValue} = useFormikContext();
    const {clusterId, namespaceId} = values;
    const prevClusterId = usePrevious(clusterId);

    const [{loading, data}, fetchNamespaces] = useFetch("kubernetesClusters", {loadOnMount: false});
    const namespaceItems = (data || []).map(({name, id}) => ({value: id, label: name}))

    useEffect(() => {
        if (!!clusterId && clusterId !== prevClusterId) {
            fetchNamespaces({formatUrl: url => `${url}/${clusterId}/namespaces`});
        }

        if (!!prevClusterId && clusterId !== prevClusterId && !!namespaceId) {
            setFieldValue("namespaceId", null);
        }
    }, [clusterId, prevClusterId, fetchNamespaces, setFieldValue, namespaceId]);

    return (
        <React.Fragment>
            <SelectField
                name="clusterId"
                label="Cluster"
                items={clusterItems}
            />
            <SelectField
                name="namespaceId"
                label="Namespace"
                items={namespaceItems}
                loading={loading}
                disabled={!clusterId}
            />
            <AsyncSelectField
                isMulti
                name="workloadNames"
                label="Workload Names"
                placeholder="Type to search"
                url="podDefinitions"
                creatable={true}
                formatData={data => valueToValueLabelFromProp(data, "name")}
                getQueryParams={inputValue => ({name: inputValue, offset: 0, maxResults: 50})}
                validate={validators.validateRequired}
            />
        </React.Fragment>
    )
}

const FormScopeDefinition = ({environmentItems, clusterItems}) => {
    const {values, setFieldValue} = useFormikContext();
    const {ignoredRiskType, subScope} = values;
    const prevIgnoredRiskType = usePrevious(ignoredRiskType);
    const prevSubScope = usePrevious(subScope);

    const {subScopeItems} = SCOPE_ITEMS[ignoredRiskType];

    useEffect(() => {
        if (!!prevIgnoredRiskType && prevIgnoredRiskType !== ignoredRiskType) {
            setFieldValue("environmentIds", []);
            setFieldValue("clusterIds", []);
            setFieldValue("workloadNames", []);
            setFieldValue("clusterId", null);
            setFieldValue("namespaceId", null);
            setFieldValue("subScope", !!subScopeItems ? subScopeItems[0].value : ignoredRiskType);
        }

    }, [ignoredRiskType, prevIgnoredRiskType, setFieldValue, subScopeItems]);

    useEffect(() => {
        if (!!prevSubScope && prevSubScope !== subScope){
            setFieldValue("environmentIds", []);
            setFieldValue("clusterIds", []);
        }
    }, [prevSubScope, subScope, setFieldValue]);

    return (
        <React.Fragment>
            <SelectField
                name="ignoredRiskType"
                label="Scope"
                items={Object.values(SCOPE_ITEMS)}
                clearable={false}
                validate={validators.validateRequired}
            />
            {!!subScopeItems &&
                <RadioField
                    name="subScope"
                    label="Select an option"
                    items={subScopeItems}
                    horizontal={true}
                    validate={validators.validateRequired}
                />
            }
            {subScope === IGNORE_RISK_TYPES.EnvironmentIgnoredRisk &&
                <MultiselectField
                    name="environmentIds"
                    label="Environment"
                    items={environmentItems}
                    validate={validators.validateRequired}
                />
            }
            {subScope === IGNORE_RISK_TYPES.ClusterIgnoredRisk &&
                <MultiselectField
                    name="clusterIds"
                    label="Cluster"
                    items={clusterItems}
                    validate={validators.validateRequired}
                />
            }
            {subScope === IGNORE_RISK_TYPES.WorkloadIgnoredRisk && <WorkloadScopeFormFields clusterItems={clusterItems} />}
        </React.Fragment>
    )
}

export default FormScopeDefinition;