import React from 'react';
import { ListField, TextField, validators } from 'components/Form';

const IpForm = ({ endpoint, isReadOnlyUser }) => (
    <ListField
        name={`${endpoint}.networks`}
        fieldComponent={TextField}
        fieldProps={{
            validate: validators.cidrValidator,
            placeholder: "100.101.102.103/24"
        }}
        disabled={isReadOnlyUser}
    />
);

export default IpForm;