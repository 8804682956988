import React from 'react';
import { isEmpty } from 'lodash'
import DisplaySection from 'components/DisplaySection';
import ListValueItemsDisplay from 'components/ListValueItemsDisplay';
import { getBooleanValue } from './utils';

const PodSpecsDisplay = props => {
    const {fsGroup, hostIPC, hostNetwork, hostPID, runAsGroup, runAsNonRoot, runAsUser,
        shareProcessNamespace, supplementalGroups, volumes} = props;

    const items = [
        {name: "runAsNonRoot", values: [getBooleanValue(runAsNonRoot)]},
        {name: "runAsGroup", values: [runAsGroup]},
        {name: "runAsUser", values: [runAsUser]},
        {name: "fsGroup", values: [fsGroup]},
        {name: "hostIPC", values: [getBooleanValue(hostIPC)]},
        {name: "hostNetwork", values: [getBooleanValue(hostNetwork)]},
        {name: "hostPID", values: [getBooleanValue(hostPID)]},
        {name: "shareProcessNamespace", values: [getBooleanValue(shareProcessNamespace)]}
    ];

    if (!isEmpty(supplementalGroups)) {
        items.push({name: "supplementalGroups", values: supplementalGroups});
    }

    if (!isEmpty(volumes)) {
        items.push({name: "volumes", values: volumes});
    }

    return (
        <DisplaySection title="Pod properties">
            <ListValueItemsDisplay items={items} />
        </DisplaySection>
    )
}

export default PodSpecsDisplay;