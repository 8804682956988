import React from 'react';
import { Route, Redirect, useLocation, Switch } from 'react-router-dom';
import classnames from 'classnames';
import Tabs from 'components/Tabs';

import './tabbed-page-with-routing.scss';

const TabbedPageWithRouting = ({items, className, redirectFrom=null, redirectTo=null, customCheckHideTabs=null}) => {
    const {pathname} = useLocation();
    
    const selectedTabByPath = items.find(({to}) => to === pathname); //hide tabs for inner pages

    const displayTabs = !!customCheckHideTabs? !customCheckHideTabs(pathname) : !!selectedTabByPath;
    
    return (
            <div className={classnames("tabbed-page-wrapper", {[className]: className})}>
                {displayTabs && <Tabs items={items} />}
                <div className="page-content">
                    <Switch>
                        {!!redirectFrom && !!redirectTo && <Redirect exact from={redirectFrom} to={redirectTo} />}
                        {
                            items.map(({to, exact, component, path}, index) => (
                                <Route key={index} path={!!path ? path : to} exact={exact} component={component} />
                            ))
                        }
                    </Switch>
                </div>
            </div>
    )
}

export default TabbedPageWithRouting;