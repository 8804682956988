import React, { useEffect } from 'react';
import { useAuthState } from 'context/AuthProvider';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { usePrevious } from 'hooks';
import FormWrapper, { validators, TextField, RadioField, useFormikContext } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';
import { DEFAULT_RULE_TYPES, DEFAULT_RULE_DENY_TYPES, getRuleValueByType, DEFAULT_RULE_NAME } from './utils';

const FormFields = ({isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {type} = values;
    const prevType = usePrevious(type);

    const items = Object.values(DEFAULT_RULE_TYPES).map(type => ({value: type, label: getRuleValueByType(type)}));

    useEffect(() => {
        if (type === prevType) {
            return;
        }

        if (prevType === DEFAULT_RULE_TYPES.ALLOW_ALL) {
            setFieldValue("denyType", RULE_ACTIONS.DETECT);
        }
    }, [prevType, type, setFieldValue]);

    return (
        <React.Fragment>
            <TextField name="name" label="Rule Name" disabled />
            <RadioField
                name="type"
                label="Type"
                items={items}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {DEFAULT_RULE_DENY_TYPES.includes(type) &&
                <RadioField
                    name="denyType"
                    label="On Detection"
                    horizontal={true}
                    items={[
                        {value: RULE_ACTIONS.DETECT, label: "Detect", tooltip: "Allow but alert on the violation"},
                        {value: RULE_ACTIONS.BLOCK, label: "Block", tooltip: "Block the violation"}
                    ]}
                    disabled={isReadOnlyUser}
                />
            }
        </React.Fragment>
    );
}

const FormDefaultRule = ({initialData, title, onDone, onDirtyChanage}) => {
    const {isReadOnlyUser} = useAuthState();

    const initialValues = {
        name: DEFAULT_RULE_NAME,
        type: "",
        denyType: "",
        ...initialData
    };

    initialValues.denyType = initialValues.action === RULE_ACTIONS.BLOCK ? RULE_ACTIONS.BLOCK : RULE_ACTIONS.DETECT;
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{title}</Text>
            <FormWrapper
                initialValues={initialValues}
                onDirtyChanage={onDirtyChanage}
                disableSubmitButton={isReadOnlyUser}
                doCustomSubmit={formValues => {
                    const {type, denyType} = formValues;

                    let action = RULE_ACTIONS.ALLOW;
                    if (type !== DEFAULT_RULE_TYPES.ALLOW_ALL) {
                        action = denyType;
                    }

                    onDone({type, action});
                }}
            >
                <FormFields isReadOnlyUser={isReadOnlyUser} />
            </FormWrapper>
        </div>
    )
}

export default FormDefaultRule;

