import React from 'react';
import classnames from 'classnames';
import { isBoolean } from 'lodash';
import Icon, { ICON_NAMES } from 'components/Icon';

import './compliance-status-icon.scss';

const ComplianceStatusIcon = ({compliance, withLabel=false}) => {
    if(!isBoolean(compliance)) {
        return (
            <div className="compliance-status-icon-wrapper">
                <span className="compliance-icon unknown-compliance"></span>
            </div>
        );
    }

    const iconName = compliance ? ICON_NAMES.SUCCESS : ICON_NAMES.ERROR;
    const label = compliance ? "Compliant" : "Non compliant";

    return (
        <div className={classnames("compliance-status-icon-wrapper", {error: !compliance}, {success: compliance})}>
            <Icon className="compliance-icon" name={iconName}/>
            {withLabel && <span>{label}</span>}
        </div>
    );
}

export default ComplianceStatusIcon;