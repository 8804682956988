import React, { useEffect } from 'react';
import { get } from 'lodash';
import { usePrevious } from 'hooks';
import { validators, MultiselectField, AsyncSelectField, ArrayField, TextField,
    RadioField, useFormikContext, utils } from 'components/Form';
import { valueToValueLabelFromProp } from 'utils/apiUtils';
import { getNoSourceIpEnvAlert, ENDPOINT_TYPE_FIELD_NAME } from './utils';

export const EXPANSION_TYPE_ITEMS = {
    NAME: {
        value: "ExpansionNameConnectionRulePart",
        label: "By Name"
    },
    LABEL: {
        value: "ExpansionLabelsConnectionRulePart",
        label: "By Label"
    },
    ANY: {
        value: "ExpansionAnyConnectionRulePart",
        label: "Any"
    }
}

const ExpansionForm = ({endpoint, displayEnvAlert, environments, environmentNames, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {destination} = values;

    const typeFieldName = `${endpoint}.${ENDPOINT_TYPE_FIELD_NAME}`;
    const typeFieldValue = get(values, typeFieldName);
    const prevTypeFieldValue = usePrevious(typeFieldValue);

    let envAlert = null;
    if (displayEnvAlert) {
        envAlert = getNoSourceIpEnvAlert(destination.environments || [], environments);
    }

    useEffect(() => {
        if (typeFieldValue === "") {
            setFieldValue(typeFieldName, EXPANSION_TYPE_ITEMS.NAME.value);
        }

        if (prevTypeFieldValue === typeFieldValue || !prevTypeFieldValue) {
            return;
        }

        setFieldValue(`${endpoint}.names`, []);
        setFieldValue(`${endpoint}.labels`, [{key: "", value: ""}]);
    }, [typeFieldValue, prevTypeFieldValue, typeFieldName, setFieldValue, endpoint]);

    return (
        <React.Fragment>
            <RadioField
                name={typeFieldName}
                horizontal={true}
                items={Object.values(EXPANSION_TYPE_ITEMS)}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {typeFieldValue === EXPANSION_TYPE_ITEMS.NAME.value &&
                <AsyncSelectField
                    isMulti
                    name={`${endpoint}.names`}
                    label="Names"
                    placeholder="Type to search"
                    url="expansions"
                    formatData={data => valueToValueLabelFromProp(data, "name")}
                    getQueryParams={inputValue => ({name: inputValue, offset: 0, maxResults: 50})}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />}
            {typeFieldValue === EXPANSION_TYPE_ITEMS.LABEL.value &&
                <ArrayField
                    name={`${endpoint}.labels`}
                    label="Labels"
                    firstFieldProps={{
                        component: TextField,
                        key: "key",
                        placeholder: "Key",
                        validate: validators.validateRequired
                    }}
                    secondFieldProps={{
                        component: TextField,
                        key: "value",
                        placeholder: "Value",
                        validate: validators.validateRequired
                    }}
                    disabled={isReadOnlyUser}
                />}
            <MultiselectField
                name={`${endpoint}.environments`}
                label="Runtime environment"
                items={environmentNames}
                disabled={isReadOnlyUser}
            />
            {displayEnvAlert && !!envAlert && <utils.FieldAlert message={envAlert} />}
        </React.Fragment>
    );
}

export default ExpansionForm;