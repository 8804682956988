import React, {useState, useMemo, useRef, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { isNull } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import Table, { tableUtils, FilterFormWrapper } from 'components/Table';
import { TextField, SelectField } from 'components/Form';
import IconWithTitle from 'components/IconWithTitle';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';
import FormModal from 'components/FormModal';
import { formatDate } from 'utils/generalUtils';
import { APP_PATHS, REGULAR_USER_ROLES_ITEMS, GLOBAL_ADMIN_USER_ROLES_ITEMS } from 'utils/systemConsts';
import UserForm, { SERVICE_USER_ROLES, USER_STATUS_ENABLED } from './UserForm';
import UserTokenModal from './UserTokenModal';
import DeleteUserModal from './DeleteUserModal';

import './users.scss';

const USERS_API_PATH = "users";

const ROLE_FILTER_ITEMS = Object.values(REGULAR_USER_ROLES_ITEMS);
const GLOBAL_ADMIN_ROLE_FILTER_ITEMS = [...ROLE_FILTER_ITEMS, ...Object.values(GLOBAL_ADMIN_USER_ROLES_ITEMS)];

export const USERS_PAGE_PATH = "/system/manageUsers";

const Users = () => {
    const location = useLocation();
    const isGlobalAdminPage = location.pathname === APP_PATHS.MANAGE_ADMIN_USERS.path;

    const columns = useMemo(() => [
        {
            Header: "Name",
            id: "fullName",
            accessor: "fullName",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Role",
            id: "role",
            accessor: ({role}) => {
                const {label} = (isGlobalAdminPage ? GLOBAL_ADMIN_USER_ROLES_ITEMS : REGULAR_USER_ROLES_ITEMS)[role] || {};

                return label || role;
            },
            disableSortBy: true
        },
        {
            Header: "Email",
            id: "email",
            accessor: "email",
            disableSortBy: true
        },
        {
            Header: "Status",
            id: "status",
            Cell: ({ row }) => (
                <tableUtils.StatusRenderer isActive={row.original.status === USER_STATUS_ENABLED} disabledText="Disabled" />
            ),
            disableSortBy: true
        },
        {
            Header: "Last Login",
            id: "lastLogin",
            accessor: original => formatDate(original.lastLogin),
            disableSortBy: true
        },
        {
            Header: "Info",
            id: "description",
            accessor: "description",
            disableSortBy: true
        }
    ], [isGlobalAdminPage]);

    const { isReadOnlyUser, id: currUserId } = useAuthState();

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = () => setRefreshTimestamp(Date());

    const [selectedUserData, setSelectedUserData] = useState(null);
    const closeUserModal = () => setSelectedUserData(null);

    const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationData(null);

    const [tokenUserId, setTokenUserId] = useState(null);
    const closeUseTokenModal = () => {
        setTokenUserId(null);
    };

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    const [filters, setFilters] = useState({
        username: "",
        email: "",
        roles: ""
    });
    
    return (
        <div className={classnames("manage-users-page", { "global-admin-page": isGlobalAdminPage })}>
            <div className="table-header-container">
                <IconWithTitle
                    name={ICON_NAMES.ADD}
                    title="New User"
                    className="add-new-button"
                    onClick={() => setSelectedUserData({})}
                    disabled={isReadOnlyUser}
                />
                <FilterFormWrapper
                    initialValues={{...filters}}
                    onChange={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                >
                    <TextField name="username" label="Username" />
                    <TextField name="email" label="Email" />
                    <SelectField
                        name="roles"
                        label="Role"
                        items={isGlobalAdminPage ? GLOBAL_ADMIN_ROLE_FILTER_ITEMS : ROLE_FILTER_ITEMS}
                        small
                    />
                </FilterFormWrapper>
            </div>
            <Table
                url={USERS_API_PATH}
                name="users"
                filters={filters}
                columns={columns}
                actionsComponent={({ original }) => {
                    const { id } = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;
                    const disableUserActions = isReadOnlyUser || id === currUserId;

                    return (
                        <div className="user-row-actions">
                            <IconWithTitle
                                className="token-icon"
                                name={ICON_NAMES.COPY}
                                title="Token"
                                disabled={!SERVICE_USER_ROLES.includes(original.role)}
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    
                                    setTokenUserId(original.id);
                                }}
                            />
                            <IconWithTooltip
                                name={ICON_NAMES.EDIT}
                                onClick={() => setSelectedUserData(original)}
                                tooltipId={editTooltipId}
                                tooltipText="Edit user"
                                disabled={disableUserActions}
                            />
                            <IconWithTooltip
                                name={ICON_NAMES.DELETE}
                                onClick={() => setDeleteConfirmationData(original)}
                                tooltipId={deleteTooltipId}
                                tooltipText="Delete user"
                                disabled={disableUserActions}
                            />
                        </div>
                    );
                }}
                actionsCellWidth={180}
                withPagination={false}
                refreshTimestamp={refreshTimestamp}
            />
            {!isNull(selectedUserData) &&
                <FormModal
                    onClose={closeUserModal}
                    formComponent={UserForm}
                    formProps={{
                        initialData: {...selectedUserData},
                        onFormSubmitSuccess: ({id, role}, isNew) => {
                            closeUserModal();
                            refreshTableData();
                            
                            if (isNew && SERVICE_USER_ROLES.includes(role)) {
                                setTokenUserId(id); //only show for newly created (and not edited) "service" type user
                            }
                        }
                    }}
                />
            }
            {!isNull(tokenUserId) && <UserTokenModal userId={tokenUserId} onClose={closeUseTokenModal} />}
            {!isNull(deleteConfirmationData) &&
                <DeleteUserModal onClose={closeDeleteConfirmation} onDeleteSuccess={refreshTableData} data={deleteConfirmationData} />
            }
        </div>
    )
}

export default Users;