import React from 'react';

const InnerTable = ({data}) =>  (
    <table className="environment-inner-table">
        <thead>
            <tr>
                <th>Cluster</th>
                <th>Namespace</th>
                <th>Labels</th>
            </tr>
        </thead>
        <tbody>
            {
                data.kubernetesEnvironments.map(({kubernetesClusterName, namespaces, namespaceLabels}, index) => 
                    <tr key={index}>
                        <td>{kubernetesClusterName}</td>
                        <td>{namespaces.join(", ")}</td>
                        <td>{!!namespaceLabels && namespaceLabels.map(({key, value}) => <div key={key}>{`${key} : ${value}`}</div>)}</td>
                    </tr>
                )
            }
        </tbody>
    </table>
);

export default InnerTable;