import React from 'react';
import { isEmpty } from 'lodash';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { formatDateBy } from 'utils/generalUtils';
import Colors from 'utils/scss_variables.module.scss';

import './data-graph.scss';

const GRAPH_DATA_KEYS = {
    MAIN: "graph",
    SUB: "subGraph"
};

const GRAPH_COLORS = {
    [GRAPH_DATA_KEYS.MAIN]: Colors["color-main"],
    [GRAPH_DATA_KEYS.SUB]: Colors["color-main-light"]
};

const LINE_PROPS = {
    type: "monotone",
    dot: false,
    strokeWidth: 2
};

const LegendItem = ({title, color}) => (
    <div className="data-graph-legend-item">
        <div className="color-indicator" style={{backgroundColor: color}}></div>
        {title}
    </div>
);

const DataGraph = ({data, title, valueSuffix="", dateFormat="h:mm a", subData, valueTitle, subValueTitle}) => {
    const formattedGraphsData = data.map(({date, graph}) => ({
        date: formatDateBy(date, dateFormat),
        fullDate: formatDateBy(date, "DD.MM h:mm:ss"),
        [GRAPH_DATA_KEYS.MAIN]: graph,
        ...(!subData ? {} : {[GRAPH_DATA_KEYS.SUB]: subData.find(item => item.date === date).graph})
    }));

    if (isEmpty(formattedGraphsData)) {
        return (
            <div>No data available</div>
        )
    }
    
    return (
        <div className="data-graph-container">
            <div className="data-graph-title">{title}</div>
            <div className="data-graph-content">
                <ResponsiveContainer>
                    <LineChart data={formattedGraphsData}>
                        <Line {...LINE_PROPS} dataKey={GRAPH_DATA_KEYS.MAIN} stroke={GRAPH_COLORS[GRAPH_DATA_KEYS.MAIN]} />
                        {!!subData && <Line {...LINE_PROPS} dataKey={GRAPH_DATA_KEYS.SUB} stroke={GRAPH_COLORS[GRAPH_DATA_KEYS.SUB]} />}
                        <CartesianGrid stroke={Colors["color-grey"]} horizontal={false} strokeDasharray="3 3" />
                        <XAxis dataKey="date" interval="preserveStartEnd" />
                        <YAxis />
                        <Tooltip content={({active, label, payload, ...test}) => {
                            if (!active || !payload) {
                                return null;
                            }
                            
                            return (
                                <div className="data-graph-tooltip">
                                    <div>{payload[0].payload.fullDate}</div>
                                    {
                                        payload.map(({name, value, dataKey}) => {
                                            const valueName = dataKey === GRAPH_DATA_KEYS.MAIN ? valueTitle : subValueTitle;

                                            return (
                                                <div key={name}>
                                                    {`${value} ${valueSuffix}${!!valueName ? ` ${valueName}` : ""}`}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }} />
                    </LineChart>
                </ResponsiveContainer>
                {valueTitle && subValueTitle && 
                    <div className="data-graph-legend">
                        <LegendItem title={valueTitle} color={GRAPH_COLORS[GRAPH_DATA_KEYS.MAIN]} />
                        <LegendItem title={subValueTitle} color={GRAPH_COLORS[GRAPH_DATA_KEYS.SUB]} />
                    </div>
                }
            </div>
        </div>
    )
}

export default DataGraph;

