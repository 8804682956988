import React from 'react';
import classnames from 'classnames';

import './double-pane-display.scss';

const DoublePaneDisplay = ({leftPane: LeftPane, rightPane: RightPane, className}) => {
    return (
        <div className={classnames("double-pane-display-wrapper", className)}>
            <div className="left-pane-wrapper"><LeftPane /></div>
            <div className="right-pane-wrapper"><RightPane /></div>
        </div>
    )
}

export default DoublePaneDisplay;