export const ICON_NAMES = {
    API: "api-connect",
    API_FULL: "api-full",
    WORKLOADS: "workloads",
    POLICY: "policy",
    AGENTS: "agents",
    NAVIGATOR: "navigator",
    CI_CD: "ci-cd",
    RUNTIME: "runtime",
    COMPANY: "company",
    COMMUNITY: "community",
    SCAN: "scan",
    SCAN_NOW: "scan-now",
    DASHBOARD: "dashboard",
    AUDIT: "audit",
    DOWNLOAD: "download",
    DOWNLOAD_XML: "download-xml",
    DOWNLOAD_JSON: "json-file",
    DOWNLOAD_PDF: "report",
    CLUSTER: "cluster",
    NAMESPACE: "namespace",
    POD: "pod",
    EXPANSION: "expansion",
    EXCLAMATION_MARK: "exclamation-mark",
    EXCLAMATION_MARK_ROUND: "exclamation-mark-round",
    INFO: "info",
    CONNECTIONS: "connections",
    DIFF: "diff",
    ARROW_TOP: "arrow-top",
    ARROW_DOWN: "arrow-down",
    SMALL_ARROW_LEFT: "small-arrow-left",
    THIN_ARROW_RIGHT: "thin-arrow-right",
    TESTING: "testing",
    TRIANGLE_ARROW_DOWN: "triangle-arrow-down",
    ARROW_DOUBLE_RIGHT: "arrow-double-right",
    TREND_ARROW_STABLE: "trend-arrow-stable",
    TREND_ARROW_DOWN: "trend-arrow-down",
    TREND_ARROW_UP: "trend-arrow-up",
    PROCCESS: "proccess",
    COG: "cog",
    USER: "user",
    USER_SYSTEM: "user-system",
    REPAIR: "repair",
    EVENTS: "events",
    MENU: "menu",
    FILTERS: "filters",
    COLUMNS: "columns",
    REFRESH: "refresh",
    CHECK_MARK: "check-mark",
    X_MARK: "x-mark",
    ERROR: "error",
    SUCCESS: "success",
    SUCCESS_ROUND: "success-round",
    SIGN_OUT: "sign-out",
    HOVER: "hover",
    EDIT: "edit",
    EDIT_UNDERLINE: "edit-underline",
    ADD: "add",
    MINUS: "minus",
    EARTH: "earth",
    EYE: "eye",
    EYE_HIDE: "eye-hide",
    EYE_CLOSED: "eye-closed",
    SEND_TO_TOP: "send-to-top",
    SEND_TO_BOTTOM: "send-to-bottom",
    ENABLE: "enable",
    DISABLE: "disable",
    RESTRICT: "restrict",
    RUN: "run",
    RUN_EMPTY: "run-empty",
    DELETE: "delete",
    PASSWORD: "password",
    KEY_DIAGONAL: "key-diagonal",
    CLOUD: "cloud",
    INSTALL: "install",
    UNINSTALL: "uninstall",
    SETTINGS: "settings",
    COPY: "copy",
    DUPLICATE: "duplicate",
    WAITING: "waiting",
    CLEAR: "clear",
    RESET: "reset",
    START_TIME: "start-time",
    END_TIME: "end-time",
    UPDATE: "update",
    ADD_RULE: "add-rule",
    EXPAND_ALL: "expand-all",
    COLLAPSE_ALL: "collapse-all",
    HISTORY: "history",
    SHIELD_CHECK: "shield-check",
    SHIELD_CROSS: "shield-cross",
    SHIELD_EXCLAMATION: "shield-exclamation",
    EJECT: "eject",
    BEETLE: "beetle",
    BEETLE_ROUND: "beetle-round",
    VULNERABILITY_SCANNER: "vulnerability-scanner",
    QUESTION: "question",
    GRAVE: "grave",
    DATA_ACCESS: "data_access",
    REVERT: "revert",
    IGNORE: "ignore",
    REMOVE_IGNORE: "remove-ignore",
    ALERT: "alert",
    ALERT_CIS: "alert-cis",
    SUCCESS_CIS: "success-cis",
    INFO_CIS: "info-cis",
    PERMISSIONS: "permissions",
    SECURITY_CONTEXT: "security-context",
    LOCK: "lock",
    LOCK_ROUNDED: "encrypted",
    LEGEND: "legend",
    IMAGE_UNIDENTIFIED: "image-unidentified",
    IMAGE_APPROVED: "image-approved",
    FIX: "fix",
    TABLE: "table",
    IMAGE: "image",
    IMAGE_LINK: "image-link",
    CMD: "cmd",
    SECURITY_CONTROL: "security-control",
    STAR_FULL: "star-full",
    STAR: "star",
    MAP_ENV: "map-env",
    MAP_WORKLOAD_INCLUDE: "map-workload-include",
    MAP_WORKLOAD_EXCLUDE: "map-workload-exclude",
    NO_RESULTS: "no-results",
    CLEAR_MITRE: "clear-mitre",
    EMPTY_DATA: "empty-data",
    STROKE: "stroke",
    UNMERGE: "unmerge",
    SWAGGER: "swagger",
    REPLACE: "replace",
    FUNCTION: "function-aws",
    FUNCTION_AZURE: "function-azure",
    BELL: "bell",
    BELL_CROSSED: "bell-crossed",
    SYSTEM: "system",
    IOS: "ios",
    LINUX: "linux",
    SHADOW: "shadow",
    ZOMBIE: "zombie",
    MULTI: "multi",
    INFO_ROUND: 'info-round',
    EXTERNAL_LINK: 'external-link',
    CIS_COMPLIANCE: "cis-compliance",
    CIS_SCANNED: "cis-scanned",
    CIS_ACTION: "cis-action",
    CIS_FAILED: "cis-failed",
    CIS: "cis",
    UPDATING: "updating"
}
