import React from 'react';
import InfoIcon from 'components/InfoIcon';
import RuleActionDisplay from 'components/RuleActionDisplay';
import { ENCRYPT_ACTION_ITEM, RULE_ENCRYPTION_REASON_ITEMS } from 'utils/systemConsts';

import './rule-actions-display.scss';

const RuleActionsDisplay = ({tooltipId, defaultRule, implicitRule, userRule, encryptRule, encryptionReason}) => {
    return (
        <div className="runtime-rule-actions-wrapper">
            {!!defaultRule && <RuleActionDisplay action={defaultRule.action}/>}
            {!!implicitRule && <RuleActionDisplay action={implicitRule.action}/>}
            {!!userRule && <RuleActionDisplay action={userRule.action}/>}
            {!!encryptRule && <RuleActionDisplay action={encryptRule.action}/>}
            {!encryptRule && encryptionReason === RULE_ENCRYPTION_REASON_ITEMS.CLUSTER_OUTBOUND.value && 
                <div className="encrypt-reason-action-wrapper">
                    <RuleActionDisplay action={ENCRYPT_ACTION_ITEM.value}/>
                    <InfoIcon tooltipId={`encrypt-reason-tooltip-${tooltipId}`} text={RULE_ENCRYPTION_REASON_ITEMS.CLUSTER_OUTBOUND.label} />
                </div>
            }    
        </div>
    )
}

export default RuleActionsDisplay;