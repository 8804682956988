import React from 'react';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import TabbedPageWithRouting from 'components/TabbedPageWithRouting';
import Enviornments, { DEPLOYMNET_ENVIRONMENTS_PAGE_PATH } from './Enviornments';
import Clusters, { DEPLOYMNET_CLUSTERS_PAGE_PATH } from './Clusters';
import PodTemplates, { DEPLOYMNET_POD_TEMPLATES_PAGE_PATH } from './PodTemplates';
import Expansions, { DEPLOYMNET_EXPANSIONS_PAGE_PATH } from './Expansions';
import Registries, { DEPLOYMNET_REGISTRIES_PAGE_PATH } from './Registries';
import Deployers, { DEPLOYMNET_DEPLOYERS_PAGE_PATH } from './Deployers';

import './deployments.scss';

const Deployments = () => {
    const {showAccountConnections} = useDisplayConfigState();
    
    return (
        <TabbedPageWithRouting
            className="workloads-page"
            items={[
                {to: DEPLOYMNET_CLUSTERS_PAGE_PATH, exact: true, title: "Cluster controllers", component: Clusters},
                {
                    to: DEPLOYMNET_EXPANSIONS_PAGE_PATH,
                    exact: false,
                    title: "Expansion controllers",
                    component: Expansions,
                    disabled: !showAccountConnections
                },
                {to: DEPLOYMNET_REGISTRIES_PAGE_PATH, exact: false, title: "Registries", component: Registries},
                {to: DEPLOYMNET_ENVIRONMENTS_PAGE_PATH, exact: false, title: "Environments", component: Enviornments},
                {to: DEPLOYMNET_DEPLOYERS_PAGE_PATH, exact: false, title: "Deployers", component: Deployers},
                {to: DEPLOYMNET_POD_TEMPLATES_PAGE_PATH, exact: false, title: "Pod Templates", component: PodTemplates}
            ]}
        />
    );
}

export default Deployments;