import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { cloneDeep, isEmpty, pickBy, isNull } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import Loader from 'components/Loader';
import FormModal from 'components/FormModal';
import { ICON_NAMES } from 'components/Icon';
import { useMountMultiFetch } from 'hooks';
import useGroupedRulesPolicyReducer, { POLICY_ACTIONS, formatUserRulesForSubmit } from 'layout/Policies/policyHooks/useGroupedRulesPolicyReducer';
import useRulesSearchLoaderReducer, { SEARCH_LOADER_ACTIONS } from 'layout/Policies/policyHooks/useRulesSearchLoaderReducer';
import BaseGroupedPolicy, { BaseGroupedRulesList } from '../BaseGroupedPolicy';
import BasePolicyHistoryButtonModal from '../BasePolicyHistoryButtonModal';
import { formatUserRulesForDisplay, DEPLOYMENT_USER_RULE_TYPES } from '../utils';
import { DefaultRuleForm, DefaultRuleItem } from './DefaultRule';
import { PortshiftRuleForm, PortshiftRuleItem } from './PortshiftRule';
import UserRuleForm from './UserRuleForm';
import { POLICY_TYPE } from './utils';
import DeploymentAdvisor from './DeploymentAdvisor';
import UserRuleContent from './UserRuleContent';

export const DEPLOYMENT_POLICY_URL =  "/policies";

const POLICY_URL = "appsPolicy";

const IMPLICIT_RULE_KEY =  "unidentifiedPodsRule";

const formatSubmitData = data => {
    const {userRules, defaultRule, implicitRule} = data;
    const formattedUserRules = formatUserRulesForSubmit(userRules, ["appBy", "environmentBy"]);
    
    return {
        defaultRule,
        [IMPLICIT_RULE_KEY]: {action: implicitRule.action},
        userRules: formattedUserRules.map(userRule => {
            userRule.app = pickBy(userRule.app, (value) => value !== "");

            return userRule;
        })
    }
}

const DeploymentPolicy = ({displayParamsData, showApiSecurity}) => {
    const {isReadOnlyUser} = useAuthState();

    const location = useLocation();
    const {openAdvisor, ruleInitialData} = location.query || {};

    const [showDefaultRuleForm, setShowDefaultRuleForm] = useState(false);
    const closeDefaultRuleForm = () => setShowDefaultRuleForm(false);

    const [showImplicitRuleForm, setShowImplicitRuleForm] = useState(false);
    const closeImplicitRuleForm = () => setShowImplicitRuleForm(false);

    const [userRuleFormData, setUserRuleFormData] = useState(isEmpty(ruleInitialData) ? null : ruleInitialData);
    const closeUserRuleForm = () => setUserRuleFormData(null);

    const [{policyState, loading}, dispatch] = useGroupedRulesPolicyReducer({policyUrl: POLICY_URL, implicitRuleKey: IMPLICIT_RULE_KEY});
    const {userRules, defaultRule, implicitRule, withUnsavedEdits, markedUserRuleIds, markDefault, markImplicit} = cloneDeep(policyState);

    const [{searching, selectedSearchFilter, selectedRuleIDs}, searchDispatch] = useRulesSearchLoaderReducer({policyUrl: POLICY_URL});

    useEffect(() => {
        dispatch({type: POLICY_ACTIONS.SET_MARKED_USER_RULES, payload: selectedRuleIDs});
    }, [selectedRuleIDs, dispatch]);

    const reloadPolicyData = () => dispatch({type: POLICY_ACTIONS.LOAD_POLICY_DATA});

    const newRuleTitle = `New ${POLICY_TYPE} rule`;
    const onAddRuleClick = () => {
        setUserRuleFormData({});
    }

    const newInjectionRuleTitle = "New API token injection rule";
    const onAddInjectionRuleClick = () => {
        setUserRuleFormData({ruleTypeProperties: {ruleType: DEPLOYMENT_USER_RULE_TYPES.INJECTION}});
    }

    const formButtonsItems = [
        {id: "add", icon: ICON_NAMES.ADD, title: newRuleTitle, onClick: onAddRuleClick},
        ...(showApiSecurity ? [{id: "addInjection", icon: ICON_NAMES.KEY_DIAGONAL, title: newInjectionRuleTitle, onClick: onAddInjectionRuleClick}] : []),
        {id: "edit", icon: ICON_NAMES.EDIT, title: "Edit default rule", onClick: () => setShowDefaultRuleForm(true)}
    ];

    if (loading) {
        return <Loader />;
    }

    const defaultRuleTitle = `${POLICY_TYPE} Default Rule`;
    const implicitRuleTitle = `${POLICY_TYPE} Implicit Rule`;

    return (
        <React.Fragment>
            <BaseGroupedPolicy
                policyTypeName={POLICY_TYPE}
                userRules={userRules}
                isReadOnly={isReadOnlyUser}
                formButtonsItems={formButtonsItems}
                markedIds={markedUserRuleIds}
                withUnsavedEdits={withUnsavedEdits}
                topPanelRuleComponent={() =>
                    <PortshiftRuleItem
                        isReadOnly={isReadOnlyUser}
                        implicitRule={implicitRule}
                        onRuleEdit={() => setShowImplicitRuleForm(true)}
                        isMarked={markImplicit}
                    />}
                bottomPanelRuleComponent={() =>
                    <DefaultRuleItem
                        isReadOnly={isReadOnlyUser}
                        defaultRule={defaultRule}
                        onRuleEdit={() => setShowDefaultRuleForm(true)}
                        isMarked={markDefault}
                    />}
                historyButtonComponent={() =>
                    <BasePolicyHistoryButtonModal
                        url={POLICY_URL}
                        formatUserRules={formatUserRulesForDisplay}
                        formatSubmitData={data => formatSubmitData({...data, implicitRule: data[IMPLICIT_RULE_KEY]})}
                        policyDisplayComponent={({data}) => (
                            <BaseGroupedRulesList
                                policyTypeName={POLICY_TYPE}
                                userRules={data.userRules}
                                isReadOnly={true}
                                disableViewRule={true}
                                toggleGroupOpenInternal={true}
                                topPanelRuleComponent={() =>
                                    <PortshiftRuleItem
                                        isReadOnly={true}
                                        implicitRule={data[IMPLICIT_RULE_KEY]}
                                    />
                                }
                                bottomPanelRuleComponent={() =>
                                    <DefaultRuleItem
                                        isReadOnly={true}
                                        defaultRule={data.defaultRule}
                                    />
                                }
                                userRuleContentComponent={props =>
                                    <UserRuleContent {...props} {...displayParamsData} />
                                }
                            />)
                        }
                        onClose={reloadPolicyData}
                    />
                }
                userRuleContentComponent={props =>
                    <UserRuleContent {...props} {...displayParamsData} />}
                selectedSearch={selectedSearchFilter}
                onSelectSearch={selectedSearch => searchDispatch({type: SEARCH_LOADER_ACTIONS.SET_SEARCH_FILTER, payload: selectedSearch})}
                isSearching={searching}
                onRuleCopy={ruleData => {
                    const {id, name, groupName, status, isDragged, inGroup,
                        isRuleActive, ...ruleCopyData} = ruleData;

                        setUserRuleFormData(ruleCopyData);
                }}
                onRuleEdit={ruleData => setUserRuleFormData(ruleData)}
                onReorder={reorderedRules =>
                    dispatch({type: POLICY_ACTIONS.UPDATE_RULES_ORDER, payload: {reorderedRules}})}
                onGroupNameChange={(groupName, newName) =>
                    dispatch({type: POLICY_ACTIONS.UPDATE_GROUP_NAME, payload: {groupName, newName}})}
                onGroupStatusToggle={(groupName, enable) =>
                    dispatch({type: POLICY_ACTIONS.UPDATE_GROUP_STATUS, payload: {groupName, enable}})}
                onGroupDelete={(groupName) =>
                    dispatch({type: POLICY_ACTIONS.DELETE_GROUP, payload: {groupName}})}
                onRuleDelete={ruleId => dispatch({type: POLICY_ACTIONS.DELETE_RULE, payload: {ruleId}})}
                onRuleToggleStatus={ruleId =>
                    dispatch({type: POLICY_ACTIONS.TOGGLE_RULE_STATUS, payload: {ruleId}})}
                onRemoveFromGroup={ruleId =>
                    dispatch({type: POLICY_ACTIONS.REMOVE_FROM_GROUP, payload: {ruleId}})}
                onRevertPolicy={reloadPolicyData}
                onPolicySave={() =>
                    dispatch({type: POLICY_ACTIONS.SUBMIT_DATA, payload: formatSubmitData({userRules, defaultRule, implicitRule})})}
            />
            {showDefaultRuleForm && 
                <FormModal
                    loading={false}
                    onClose={closeDefaultRuleForm}
                    formComponent={DefaultRuleForm}
                    formProps={{
                        initialData: {type: defaultRule},
                        onDone: ruleData => {
                            dispatch({type: POLICY_ACTIONS.EDIT_DEFAULT_RULE, payload: {ruleData}});
                            closeDefaultRuleForm();
                        },
                        title: isReadOnlyUser ? `${defaultRuleTitle} (Audit mode)` : `Edit ${defaultRuleTitle}`
                    }}
                />
            }
            {showImplicitRuleForm && 
                <FormModal
                    loading={false}
                    onClose={closeImplicitRuleForm}
                    formComponent={PortshiftRuleForm}
                    formProps={{
                        initialData: {...implicitRule},
                        onDone: ruleData => {
                            dispatch({type: POLICY_ACTIONS.EDIT_IMPLICIT_RULE, payload: {ruleData}});
                            closeImplicitRuleForm();
                        },
                        title: isReadOnlyUser ? `${implicitRuleTitle} (Audit mode)` : `Edit ${implicitRuleTitle}`
                    }}
                />
            }
            {!isNull(userRuleFormData) &&
                <FormModal
                    onClose={closeUserRuleForm}
                    formComponent={UserRuleForm}
                    formProps={{
                        initialData: {...userRuleFormData},
                        onDone: ruleData => {
                            dispatch({type: POLICY_ACTIONS.ADD_OR_EDIT_RULE, payload: {ruleData}});
                            closeUserRuleForm();
                        }
                    }}
                />
            }
            <DeploymentAdvisor
                initialExpanded={openAdvisor}
                onAddSuggestions={suggested =>
                    dispatch({type: POLICY_ACTIONS.ADD_RULE_SUGGESTIONS, payload: {suggested}})}
                isReadOnly={isReadOnlyUser}
            />
        </React.Fragment>
    );
};

const DeploymentPolicyWrapper = () => {
    const {showApiSecurity} = useDisplayConfigState();

    const [{loading, data}] = useMountMultiFetch([
        {key: "pspProfiles", url: "podSecurityPolicyProfiles"},
        ...(!showApiSecurity ? [] : [
            {key: "apiProfiles", url: "apiSecurityPolicy"},
            {key: "tokens", url: "tokens?noPagination=true"}
        ])
    ]);

    if (loading) {
        return <Loader />;
    }

    return <DeploymentPolicy displayParamsData={data} showApiSecurity={showApiSecurity} />
}

export default DeploymentPolicyWrapper;