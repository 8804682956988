import React from 'react';
import { useFetch } from 'hooks';
import { TextField, SelectField, ViolationsFilterField, MultiselectCheckboxField } from 'components/Form';
import { FilterFormWrapper } from 'components/Table';
import Loader from 'components/Loader';
import { RULE_ACTIONS, RULE_ACTIONS_MAP } from 'utils/systemConsts';
import { nameIdToValueLabel } from 'utils/apiUtils';

import COLORS from 'utils/scss_variables.module.scss';

export const FILTER_KEYS = {
    FUNCTION_NAME: "funcName",
    RISK: "risk",
    ACCOUNT_IDS: "cloudAccountsIdsFilter",
    RESULT: "result"
}

const RESULT_FILTER_ITEMS = [
    {value: RULE_ACTIONS.ALLOW, label: RULE_ACTIONS_MAP[RULE_ACTIONS.ALLOW], color: COLORS["color-success"]},
    {value: RULE_ACTIONS.DETECT, label: RULE_ACTIONS_MAP[RULE_ACTIONS.DETECT], color: COLORS["color-warning"], isViolation: true},
    {value: RULE_ACTIONS.BLOCK, label: RULE_ACTIONS_MAP[RULE_ACTIONS.BLOCK], color: COLORS["color-error"], isViolation: true}
];

export const initialData = {
    [FILTER_KEYS.FUNCTION_NAME]: "",
    [FILTER_KEYS.RISK]: "",
    [FILTER_KEYS.ACCOUNT_IDS]: "",
    [FILTER_KEYS.RESULT]: RESULT_FILTER_ITEMS.map(item => item.value),
    filterLoading: true
}

const FilterForm = ({filters,  queryFilters={}, setFilters}) => {
    const [{loading, data, error}] = useFetch("cloudAccounts");

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const accountItems = nameIdToValueLabel(data);

    const initialValues = {
        ...initialData,
        ...filters,
        ...queryFilters,
        filterLoading: false
    };
    
    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={setFilters}
        >
            <ViolationsFilterField
                name={FILTER_KEYS.RESULT}
                label="Violations only"
                items={RESULT_FILTER_ITEMS}
            />
            <TextField name={FILTER_KEYS.FUNCTION_NAME} label="Function Name" placeholder="Search..."/>
            <SelectField
                name={FILTER_KEYS.RISK}
                label="Function risk"
                items={[
                    {value: "", label: "All"},
                    {value: "CRITICAL", label: "Critical"},
                    {value: "HIGH", label: "High"},
                    {value: "MEDIUM", label: "Medium"},
                    {value: "LOW", label: "Low"}
                ]}
                clearable={false}
                small
            />
            <MultiselectCheckboxField
                name={FILTER_KEYS.ACCOUNT_IDS}
                label="Cloud account"
                items={accountItems}
            />
        </FilterFormWrapper>
    )
};

export default FilterForm;