import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { APP_PATHS } from 'utils/systemConsts';
import ZipDetails from './ZipDetails';
import ZipsTable from './ZipsTable';
import { FilterContext } from './Filter';

export const CODE_ZIPS_PAGE_PATH = `${APP_PATHS.CI_CD.path}/codeZips`;

const CodeZips = () => (
    <FilterContext.FilterProvider>
        <Switch>
            <Route path={`${CODE_ZIPS_PAGE_PATH}/:zipId`} component={ZipDetails} />
            <Route path={CODE_ZIPS_PAGE_PATH} component={ZipsTable} />
        </Switch>
    </FilterContext.FilterProvider>
)

export default CodeZips;