import React, { useMemo } from 'react';
import Table from 'components/Table';
import ProtectedStatus from 'layout/Runtime/ProtectedStatus';
import { TIME_RANGER_MAP } from 'context/TimeRangeProvider';

const ImageExposure = ({id}) => {
    const columns = useMemo(() => [
        {
            Header: 'Workload',
            id: "appName",
            accessor: original => original.pod.name,
            defaultCanSort: true,
            alwaysShow: true
        },
        {
            Header: "Environment",
            id: "environmentName",
            accessor: original => original.environment.name
        },
        {
            Header: "Namespace",
            accessor: original => !!original.namespace && original.namespace.name,
            id: "namespaceName",
            disableSortBy: true
        },
        {
            Header: "Cluster",
            accessor: original => !!original.cluster && original.cluster.name,
            disableSortBy: true
        },
        {
            Header: "Protected",
            Cell: ({row}) => {
                const {isPodProtected} = row.original;
                
                return (
                    <ProtectedStatus isProtected={isPodProtected} />
                );
            },
            disableSortBy: true,
            width: 100
        }
    ], []);

    const {startTime, endTime} = TIME_RANGER_MAP["5MIN"].calc();

    return (
        <Table
            url="appTelemetries"
            name="image-exposure"
            defaultSortBy={[{id: "workloadRisk", desc: true}]}
            columns={columns}
            filters={{imagesId: id, startTime, endTime}}
            hideColumnControl
            hideRefresh
        />
    )
}

export default ImageExposure;