import React from 'react';
import Modal from 'components/Modal';
import Scrollbar from 'components/Scrollbar';
import Text, { TEXT_TYPES } from 'components/Text';
import { NotAvailable, PackageDisplayWrapper } from '../utils';

import './packages-modal.scss';

const TEXT_WIDTH = 200;

const PackagesModal = ({handleClose, data = []}) => (
    <Modal allowClose center className="image-packages-modal" onClose={handleClose} title="Initial Access Content">
        <Text type={TEXT_TYPES.TITLE_MEDIUM} withBottomMargin withTopMargin>Packages ({data.length})</Text>
        <div className="packages-table">
            <div className="table-header">
                <div className="table-row table-header">
                    <div className="table-cell">Package Name</div>
                    <div className="table-cell">Image Package Version</div>
                    <div className="table-cell">Fix Version</div>
                </div>
            </div>
            <Scrollbar  options={{ suppressScrollX: true}}>
                <div className="table-body">
                    {
                        data.map(({ packageName, packageVersion, fixVersion }, index) =>
                            <div key={index} className="table-row">
                                <div className="table-cell">
                                    <PackageDisplayWrapper itemId={`package-${index}-mame`} text={packageName} width={TEXT_WIDTH} />
                                </div>
                                <div className="table-cell">
                                    <PackageDisplayWrapper itemId={`package-${index}-version`} text={packageVersion} width={TEXT_WIDTH} />
                                </div>
                                <div className="table-cell">
                                    {!fixVersion ? <NotAvailable/> : <PackageDisplayWrapper itemId={`package-${index}-fix`} text={fixVersion} width={TEXT_WIDTH} />}
                                </div>
                            </div>
                        )
                    }
                </div>
            </Scrollbar>
        </div>
    </Modal>
);

export default PackagesModal;