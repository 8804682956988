import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import DetailsTabbedPage from 'components/DetailsTabbedPage';
import IgnoreToggle from 'components/IgnoreToggle';
import PackageLicenses from 'layout/CiCd/PackageLicenses';
import ImageVulnerabilities from './ImageVulnerabilities';
import ImageVulnerabilitiesLayers from './ImageVulnerabilitiesLayers';
import ImageExposure from './ImageExposure';
import ImageCIS from './ImageCIS';

import './image-details.scss';

const IMAGES_TABS = {
    VULNERABILITIES: "VULNERABILITIES",
    IMAGE_LAYERS: "IMAGE_LAYERS",
    CIS_BENCHMARK: "CIS_BENCHMARK",
    LICENCES: "LICENCES",
    EXPOSURE: "EXPOSURE"
}

const ImageDetails = ({backTitle="Images", withIgnore, setHideTabs/* provides support for the risk assessment tabs display */}) => {
    const params = useParams();
    const {imageId} = params;

    const [selectedTabData, setSelectedTabData] = useState({tab: IMAGES_TABS.VULNERABILITIES, selectedLayer: ""});

    const [showIgnoredOnly, setShowIgnoredOnly] = useState(false);

    const [{loading, data, error}, fetchImageData] = useFetch(`images/${imageId}`);

    const {imageName, imageHash, imageTags} = data || {};

    useEffect(() => {
        if (!!setHideTabs) {
            setHideTabs(true);
        }

        return function cleanup() {
            if (!!setHideTabs) {
                setHideTabs(false);
            }
        };
    }, [setHideTabs]);

    return (
        <DetailsTabbedPage
            className="cicd-image-details-page"
            loading={loading}
            error={error}
            id={imageId}
            title={imageName}
            subTitle={(
                <div className="image-details-subtitle-wrapper">
                    <div>{`Image Hash: ${imageHash}`}</div>
                    <div>{`Image Tags: ${!!imageTags && imageTags.join(", ")}`}</div>
                </div>
            )}
            backTitle={backTitle}
            menual
            onChange={selectedTab => setSelectedTabData({tab: selectedTab, selectedLayer: ""})}
            tabItems={[
                {
                    id: IMAGES_TABS.VULNERABILITIES,
                    title: "Vulnerabilities",
                    component: () => (
                        <ImageVulnerabilities
                            id={imageId}
                            showIgnoredOnly={showIgnoredOnly}
                            withIgnore={withIgnore}
                            onUpdate={fetchImageData}
                            withLayersFilter
                            selectedLayer={selectedTabData.selectedLayer}
                        />
                    )
                },
                {
                    id: IMAGES_TABS.IMAGE_LAYERS,
                    title: "Image Layers",
                    component: () => (
                        <ImageVulnerabilitiesLayers
                            id={imageId}
                            showIgnoredOnly={showIgnoredOnly}
                            onLayerSelect={selectedLayer => setSelectedTabData({tab: IMAGES_TABS.VULNERABILITIES, selectedLayer})}
                        />
                    )
                },
                {
                    id: IMAGES_TABS.CIS_BENCHMARK,
                    title: "CIS Benchmark",
                    component: () => (
                        <ImageCIS id={imageId} showIgnoredOnly={showIgnoredOnly} withIgnore={withIgnore} onUpdate={fetchImageData} />
                    )
                },
                {
                    id: IMAGES_TABS.LICENCES,
                    title: "Packages & Licenses",
                    component: () => <PackageLicenses id={imageId} url="images" />
                },
                {
                    id: IMAGES_TABS.EXPOSURE,
                    title: "Exposure",
                    component: () => <ImageExposure id={imageId} />
                }
            ]}
            defaultSelectedTab={selectedTabData.tab}
            customHeaderComponent={!withIgnore ? null : () => (
                <IgnoreToggle
                    isIgnored={showIgnoredOnly}
                    onToggle={(isIgnored) => setShowIgnoredOnly(isIgnored)}
                    ignoreText="Acknowledged only"
                    isDark
                />
            )}
        />
    )
}

export default ImageDetails;