import React from 'react';
import { SYSTEM_RISKS } from 'utils/systemConsts';
import { hexToRgba } from 'utils/generalUtils';

import './risk-tag.scss';

const RiskTag = ({risk, style}) => {
    const formattedRisk = risk || SYSTEM_RISKS.UNKNOWN.value;
    const {color} = SYSTEM_RISKS[formattedRisk];

    return (
        <div style={style}>
            <div className="risk-tag-wrapper" style={{backgroundColor: hexToRgba(color, 0.1), borderColor: color, color}}>
                {`${SYSTEM_RISKS[formattedRisk].label} risk`}
            </div>
        </div>
    )
}

export default RiskTag;