import React from 'react';
import { get } from 'lodash';
import { CheckboxField, useFormikContext, FieldLabel, FieldError } from 'components/Form';
import { ReinstallMessage, FIELD_MAPPING } from './utils';

const FormApiSecurity = ({checkShowUpdateMessage, isReadOnlyUser, isEditForm}) => {
    const {values, errors} = useFormikContext();

    const enableKubernetesSecurity = get(values, FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.name);
    const enableConnectionsControl = get(values, FIELD_MAPPING.CONNECTION_CONTROL.name);
    const istioAlreadyInstalled = get(values, FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.name);

    const tracesErrorMessage = get(errors, FIELD_MAPPING.API_EXTERNAL_GATEWAYS.name);

    const installingIstio = enableKubernetesSecurity && enableConnectionsControl && !istioAlreadyInstalled;

    return (
        <React.Fragment>
            <div className="api-security-wrapper">
                <FieldLabel>API trace sources</FieldLabel>
                <CheckboxField
                    name={FIELD_MAPPING.API_ISTIO.name}
                    title={FIELD_MAPPING.API_ISTIO.getLabel({installingIstio})}
                    tooltipText={FIELD_MAPPING.API_ISTIO.getTooltip({installingIstio})}
                    disabled={isReadOnlyUser}
                />
                <CheckboxField
                    name={FIELD_MAPPING.API_EXTERNAL_GATEWAYS.name}
                    title={FIELD_MAPPING.API_EXTERNAL_GATEWAYS.label}
                    tooltipText={FIELD_MAPPING.API_EXTERNAL_GATEWAYS.tooltip}
                    disabled={isReadOnlyUser}
                />
                {!!tracesErrorMessage && <FieldError>{tracesErrorMessage}</FieldError>}
            </div>
            {checkShowUpdateMessage(values) && <ReinstallMessage isReinstall={isEditForm} />}
        </React.Fragment>
    )
}

export default FormApiSecurity;
