import React from 'react';
import classnames from 'classnames';
import { RULE_ACTIONS } from 'utils/systemConsts';
import BaseRuleItem from '../../BaseRuleItem';

const PortshiftRuleItem = ({implicitRule, isReadOnly, onRuleEdit, isMarked, onToggleStatus}) => {
    const {name, action, isDisabled} = implicitRule;
    const blocked = action === RULE_ACTIONS.BLOCK;
    const RuleContent = () => <span><span className={classnames("relationship", {blocked})}>Deny</span> connections not through kubernetes service</span>;
    
    return (
        <BaseRuleItem 
            name={name}
            type={action}
            onEdit={onRuleEdit}
            onDoubleClick={onRuleEdit}
            contentRenderer={RuleContent}
            className={classnames({selected: isMarked})}
            withActions={true}
            allowDelete={false}
            withPortshiftTag={true}
            blocked={blocked}
            allowed={false}
            isReadOnly={isReadOnly}
            onToggleStatus={onToggleStatus}
            enabled={!isDisabled}
            disabled={isDisabled}
        />
    ); 
}

export default PortshiftRuleItem;