import React from 'react';
import { useFetch } from 'hooks';
import Loader from 'components/Loader';
import { TextField, MultiselectCheckboxField, ViolationsFilterField } from 'components/Form';
import { tableUtils, FilterFormWrapper } from 'components/Table';
import { RULE_ACTIONS, RULE_ACTIONS_MAP, RISKY_ACTION_ITEM } from 'utils/systemConsts';

import COLORS from 'utils/scss_variables.module.scss';

export const FILTER_KEYS = {
    RESULT: "result",
    KUBERNETES_AUDIT_ACTIONS: "kubernetesAuditAction",
}

const RESULT_FILTER_ITEMS = [
    {value: RULE_ACTIONS.ALLOW, label: RULE_ACTIONS_MAP[RULE_ACTIONS.ALLOW], color: COLORS["color-success"]},
    RISKY_ACTION_ITEM,
    {value: RULE_ACTIONS.DETECT, label: RULE_ACTIONS_MAP[RULE_ACTIONS.DETECT], color: COLORS["color-warning"], isViolation: true},
    {value: RULE_ACTIONS.BLOCK, label: RULE_ACTIONS_MAP[RULE_ACTIONS.BLOCK], color: COLORS["color-error"], isViolation: true}
];

const FilterForm = ({filters, queryFilters={}, setFilters}) => {
    const [{loading, data, error}] = useFetch("auditLogs/kubernetes/actions");

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const actions = data.map(item => ({value: item, label: item}));

    const initialValues = {
        ...filters,
        [FILTER_KEYS.RESULT]: RESULT_FILTER_ITEMS.map(item => item.value),
        clusterName: "",
        [FILTER_KEYS.KUBERNETES_AUDIT_ACTIONS]: tableUtils.getFilterItemsWithUndefined(actions, {valuesOnly: true, withUndefined: false}),
        resourceName: "",
        user: "",
        resourceKind: "",
        namespaceName: "",
        ...queryFilters,
        filterLoading: false
    };
    
    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={setFilters}
        >
            <ViolationsFilterField
                name={FILTER_KEYS.RESULT}
                label="Violations only"
                items={RESULT_FILTER_ITEMS}
            />
            <MultiselectCheckboxField
                name={FILTER_KEYS.KUBERNETES_AUDIT_ACTIONS}
                label="Action"
                items={tableUtils.getFilterItemsWithUndefined(actions, {withUndefined: false})}
            />
            <TextField name="clusterName" label="Cluster" />
            <TextField name="namespaceName" label="Namespace" />
            <TextField name="resourceName" label="Resource Name" />
            <TextField name="resourceKind" label="Resource Kind" />
            <TextField name="user" label="User" />
        </FilterFormWrapper>
    )
};

export default FilterForm;