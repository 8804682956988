import React, { useMemo, useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import Table from 'components/Table';
import { formatDateBy } from 'utils/generalUtils';
import { usePrevious } from 'hooks';

const VersionsTable = ({data, onSelect}) => {
    const columns = useMemo(() => [
        {
            Header: "Update Date",
            id: "updateTime",
            accessor: original => formatDateBy(original.updateTime, "DD/MM/YY HH:mm:ss"),
            disableSortBy: true
        },
        {
            Header: "Editor Name",
            id: "editor",
            accessor: "editor",
            disableSortBy: true
        }
    ], []);

    const inititalSelectedId = isEmpty(data) ? null : data[0].id;
    const [selectedRowId, setSelectedRowId] = useState(inititalSelectedId);
    const prevSelectedRowId = usePrevious(selectedRowId);

    const dataJson = JSON.stringify(data);
    const mountedAtLeastOnce = useRef(false);

    useEffect(() => {
        if (mountedAtLeastOnce.current) {
            return;
        }

        mountedAtLeastOnce.current = true;

        if (selectedRowId === prevSelectedRowId) {
            return;
        }

        const data = JSON.parse(dataJson);

        onSelect(data.find(item => item.id === selectedRowId));
    }, [dataJson, onSelect, selectedRowId, prevSelectedRowId]);

    return (
        <Table
            name="vestions-history-list"
            data={data}
            columns={columns}
            hideColumnControl={true}
            withPagination={false}
            singleSelectOnly={true}
            onLineClick={(original) => {
                onSelect(original);
                setSelectedRowId(original.id);
            }}
            markedRowIds={!!selectedRowId ? [selectedRowId] : []}
        />
    );
}

export default VersionsTable;