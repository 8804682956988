import React from 'react';
import LogManagement from './LogManagement';
import ExternalCa from './ExternalCa';

const Integrations = () => (
    <div className="integrations-page">
        {/*<LogManagement />*/}
        <ExternalCa />
    </div>
);

export default Integrations;