import React from 'react';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import TabbedPageWithRouting from 'components/TabbedPageWithRouting';
import { APP_PATHS } from 'utils/systemConsts';
import Connections from './Connections';
import Workloads from './Workloads';
import Events from './Events';
import Namespaces from './Namespaces';
import { RUNTIME_WORKLOADS_PAGE_PATH, RUNTIME_CONNECTIONS_PAGE_PATH, RUNTIME_EVENTS_PAGE_PATH } from './utils';

const Runtime = () => {
    const {showAccountConnections} = useDisplayConfigState();

    return (
        <TabbedPageWithRouting
            className="runtime-page"
            redirectFrom={APP_PATHS.RUNTIME.path}
            redirectTo={RUNTIME_WORKLOADS_PAGE_PATH}
            items={[
                {to: RUNTIME_WORKLOADS_PAGE_PATH, title: "Workloads", component: Workloads},
                {to: RUNTIME_CONNECTIONS_PAGE_PATH, title: "Connections", component: Connections, disabled: !showAccountConnections},
                {to: RUNTIME_EVENTS_PAGE_PATH, title: "Events", component: Events},
                {to: "/runtime/namespaces", title: "Namespaces", component: Namespaces}
            ]}
        />
    );
}

export default Runtime;