import React from 'react';
import { Link } from 'react-router-dom';
import Icon, { ICON_NAMES } from 'components/Icon';
import Arrow from 'components/Arrow';

import './breadcrumbs-header.scss';

const BreadcrumbsHeader = ({title, baseUrl, backPath, breadcrumbs=[], hideBreadcrumbs=false}) => (
    <div className="breadcrumbs-title-header">
        {!!backPath &&
            <Link className="back-path-wrapper" to={`${baseUrl}${backPath}`}>
                <Arrow name="left" small />
            </Link>
        }
        <Link to={baseUrl} >
            <div className="title">{title}</div>
        </Link>
        {!hideBreadcrumbs &&
            <div className="breadcrumbs-container">
                {
                    breadcrumbs.map(({title, path}) => (
                        <div key={title} className="breadcrumb-container">
                            <Icon name={ICON_NAMES.ARROW_DOUBLE_RIGHT} />
                            {!path ? title : <Link to={`${baseUrl}${path}`} >{title}</Link>}
                        </div>
                    ))
                }
            </div>
        }
    </div>
)

export default BreadcrumbsHeader;