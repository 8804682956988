import React from 'react';
import classnames from 'classnames';
import BaseRuleItem from '../../BaseRuleItem';
import { DEFAULT_RULE_NAME, DEFAULT_RULE_ACTIONS, getRuleValueByType } from './utils';

const DefaultRuleItem = ({defaultRule, isReadOnly, onRuleEdit, isMarked}) => {
    const isBlock = defaultRule === DEFAULT_RULE_ACTIONS.BLOCK_ALL;

    return (
        <BaseRuleItem 
            name={DEFAULT_RULE_NAME}
            type={defaultRule}
            onEdit={onRuleEdit}
            onDoubleClick={onRuleEdit}
            contentRenderer={() => getRuleValueByType(defaultRule, isBlock)}
            className={classnames({selected: isMarked})}
            withActions={false}
            blocked={isBlock}
            allowed={defaultRule === DEFAULT_RULE_ACTIONS.ALLOW_ALL}
            isReadOnly={isReadOnly}
        />
    );
}

export default DefaultRuleItem;