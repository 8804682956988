import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useMountMultiFetch } from 'hooks';
import Table, { tableUtils, FilterFormWrapper } from 'components/Table';
import Button from 'components/Button';
import Loader from 'components/Loader';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';
import VulnerabilitiesSummary from 'components/VulnerabilitiesSummary';
import { MultiselectCheckboxField } from 'components/Form';
import CisSummary from 'layout/RiskAssessment/CisSummary';
import ErrorIconNotification from 'layout/RiskAssessment/ErrorIconNotification';
import { valueToValueLabelFromProp } from 'utils/apiUtils';
import { RISKS_SCAN_PAGE_PATH, BREADCRUMBS_TITLE } from '../utils';

const ImageLink = ({imageName, onClick}) => (
    <Button tertiary onClick={onClick}>
        {imageName}
    </Button>
);

const ImageError = ({imageName, failures}) => {
    const errorMessage = isEmpty(failures) ? "Image cannot be scanned" : failures.map((item, index) => <p key={index}>{item.message}</p>);

    return (
        <div style={{display: "flex", alignItems: "center"}} >
            <ErrorIconNotification tooltipId={`image-error-${imageName}`} text={<span>{errorMessage}</span>} />
            <div style={{fontSize: "12px", paddingLeft: "6px"}}>{imageName}</div>
        </div>
    )
}

export const ItemsList = ({items}) => (
    <div style={{whiteSpace: "normal"}}>{items.join(", ")}</div>
);

const ClusterRiskAssessment = () => {
	const history = useHistory();
    
    const params = useParams();
    const {clusterId} = params;

    const columns = useMemo(() => [
		{
			Header: "Pod",
			id: "name",
			accessor: "name",
            disableSortBy: true
        },
        {
            Header: "Images",
            id: "images",
            Cell: ({row}) => {
                const {images} = row.original;
                
                return (
                    <div className="cluster-risks-scan-images">
                        {
                            images.map(image => {
                                const {id, name, success, failures} = image;
                                
                                return  (
                                    <div key={name}>
                                        {!success ? <ImageError imageName={name} failures={!!failures ? failures.failures : []}/> :
                                            <ImageLink imageName={name} onClick={() => history.push(`${RISKS_SCAN_PAGE_PATH}/${clusterId}/${id}`)} />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                );
            },
            minWidth: 300
        },
        {
            Header: "Namespace",
			id: "namespace",
			accessor: "namespace",
            disableSortBy: true
        },
        {
            Header: "Labels",
            id: "labels",
            hide: true,
            Cell: ({row}) => <tableUtils.KeyValueRenderer items={row.original.labels || []} />
        },
        {
            Header: "Vulnerabilities",
            id: "RISK",
            Cell: ({row}) => {
                const {vulnerabilitiesSummary, id} = row.original;

                return (
                    <VulnerabilitiesSummary {...vulnerabilitiesSummary} id={id} />
                )
            },
            defaultCanSort: true,
            width: 370,
            disableResizing: true
        },
        {
            Header: "CIS Benchmark",
            id: "cis",
            Cell: ({row}) => {
                const {id, dockerfileScanResultsSummary} = row.original;
                const {total, info, warn, fatal} = dockerfileScanResultsSummary || {};
                
                return (
                    <CisSummary total={total} totalTooltipId={`total-cis-${id}`} info={info} warning={warn} fatal={fatal} />
                );
            },
            disableSortBy: true,
            width: 270,
            disableResizing: true
        }
	], [history, clusterId]);

    const [filters, setFilters] = useState({namespacesNamesFilter: []});

    const [{loading, data}] = useMountMultiFetch([
        {key: "clusters", url: "leanKubernetesClusters"},
        {key: "namespaces", url: `kubernetesClusters/${clusterId}/namespaces`}
    ]);

    if (loading) {
        return <Loader />;
    }

    const {clusters, namespaces} = data || {};

    const clusterItem = clusters.find(item => item.id === clusterId);
    const {name: clusterName} = clusterItem || {};

    return (
        <div className="risks-scan-page">
            <BreadcrumbsHeader
                title={BREADCRUMBS_TITLE}
                baseUrl={RISKS_SCAN_PAGE_PATH}
                backPath=""
                breadcrumbs={[
                    {title: `${clusterName} cluster`}
                ]}
                hideBreadcrumbs={!clusterName}
            />
            <FilterFormWrapper initialValues={filters} onChange={setFilters}>
                <MultiselectCheckboxField
                    name="namespacesNamesFilter"
                    label="Namespaces"
                    listTitle={clusterName}
                    items={valueToValueLabelFromProp(namespaces, "name")}
                    selectAllText="Select all namespaces"
                />
            </FilterFormWrapper>
            <Table
                url={`riskAssessment/${clusterId}/pods`}
                name="cluster-risk-assessment"
                columns={columns}
                onFetchError={() => history.push({pathname: RISKS_SCAN_PAGE_PATH})}
                filters={{namespacesNamesFilter: btoa(JSON.stringify(filters.namespacesNamesFilter))}}
                exportToExcel={true}
            />
        </div>
    )
}

export default ClusterRiskAssessment;