import React from 'react';
import { useHistory } from 'react-router-dom';
import { LabelsDisplay } from 'components/LabelTag';
import Button from 'components/Button';
import TableSimple from 'components/TableSimple';
import { IMAGE_PAGE_PATH } from 'layout/CiCd/Images';

import './tab-licenses.scss';

const TabLicenses = ({imagePackages}) => {
    const history = useHistory();

    return (
        <div className="tab-licenses-content">
            <TableSimple
                rows={imagePackages.map(({imageId, imageName, licenses}) =>
                    ([
                        <Button tertiary onClick={() => history.push(`${IMAGE_PAGE_PATH}/${imageId}`)}>{imageName}</Button>,
                        <LabelsDisplay labels={licenses} wrapLabelsRight />
                    ]))}
                hideBorder
            />
        </div>
    )
}

export default TabLicenses;