import React, { useEffect } from 'react';
import { isUndefined, isNull } from 'lodash';

const ASSIGN_ACCOUNT_URL = "/api/admin/assignedAccount";
const ACCOUNTS_URL = "/api/admin/accounts";

const initialState = {
    //server account data:
    assignedAccountId: null,
    assignedAccountName: null,
    accountTier: null,
    permissionsMode: null,
    pricingUnitType: null,
    usageStatus: {},
    //computed data:
    isLoading: false
};

export const ADMIN_ACTIONS = {
    SET_ASSIGNED_ACCOUNT_DATA: "SET_ASSIGNED_ACCOUNT_DATA",
    RESET_ASSIGNED_ACCOUNT_DATA: "RESET_ASSIGNED_ACCOUNT_DATA",
    SET_IS_LOADING: "SET_IS_LOADING"
};

const reducer = (state, action) => {
    switch (action.type) {
        case ADMIN_ACTIONS.SET_ASSIGNED_ACCOUNT_DATA: {
            const updatedData = action.payload;
            const {assignedAccountId, usageStatus} = updatedData;

            return {
                ...state,
                assignedAccountId: null,
                ...(isNull(assignedAccountId) ? initialState : updatedData),
                usageStatus: usageStatus || {},
                isLoading: false
            };
        }
        case ADMIN_ACTIONS.RESET_ASSIGNED_ACCOUNT_DATA: {
            return {
                ...state,
                ...initialState,
                isLoading: false
            }
        }
        case ADMIN_ACTIONS.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: true
            }
        }
        default:
            return state;
    }
}

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const useAdminState = () => {
    const context = React.useContext(StateContext);

    if (isUndefined(context)) {
        throw Error("useState is not within the AdminProvider provider")
    }

    return context;
}

const useAdminDispatch = () => {
    const context = React.useContext(DispatchContext);

    if (isUndefined(context)) {
        throw Error("useDispatch is not within the useAdminDispatch provider")
    }

    return context;
}

const loadAccountData = (dispatch, adminData) => fetch(`${ACCOUNTS_URL}/${adminData.assignedAccountId}`, {})
    .then(response => {
        if (!response.ok) {
            throw Error("Loading error");
        }

        return response;
    })
    .then(response => response.status === 204 ? {} : response.json())
    .then(data => {
        dispatch({type: ADMIN_ACTIONS.SET_ASSIGNED_ACCOUNT_DATA, payload: {...adminData, assignedAccountName: data?.name}});
    }).catch(() => {
        dispatch({type: ADMIN_ACTIONS.RESET_ASSIGNED_ACCOUNT_DATA});
});

const AdminProvider = ({children, isGlobalAdmin}) => {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
        isLoading: true
    });
    
    const loadUserData = () => fetch(ASSIGN_ACCOUNT_URL, {})
        .then(response => {
            if (!response.ok) {
                throw Error("Loading error");
            }

            return response;
        })
        .then(response => response.status === 204 ? {} : response.json())
        .then(data => {
            if (!!data.assignedAccountId) {
                loadAccountData(dispatch, data); 
            } else {
                dispatch({type: ADMIN_ACTIONS.RESET_ASSIGNED_ACCOUNT_DATA});
            }
        }).catch(() => {
            dispatch({type: ADMIN_ACTIONS.RESET_ASSIGNED_ACCOUNT_DATA});
        });
    
    useEffect(() => {
        if (isGlobalAdmin) {
            loadUserData();
        }
    }, [isGlobalAdmin]);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

const assignAccount = (dispatch, {assignedAccountId, assignedAccountName}) => {
    dispatch({type: ADMIN_ACTIONS.SET_IS_LOADING});
    const options = {
        credentials: 'include',
        method: 'post',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({assignedAccountId})
    };
    
    fetch(ASSIGN_ACCOUNT_URL, options)
        .then(response => {
            if (!response.ok) {
                throw Error("Loading error");
            }

            return response;
        })
        .then(response => response.status === 204 ? {} : response.json())
        .then(data => {
            dispatch({type: ADMIN_ACTIONS.SET_ASSIGNED_ACCOUNT_DATA, payload: {...data, assignedAccountName}});
        }).catch(() => {
            dispatch({type: ADMIN_ACTIONS.RESET_ASSIGNED_ACCOUNT_DATA});
        });
}

export {
    AdminProvider,
    useAdminState,
    useAdminDispatch,
    assignAccount
};