import React from 'react';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

import COLORS from 'utils/scss_variables.module.scss';

import './tooltip.scss';

export const TOOLTIP_PLACEMENTS = {
    TOP: "top",
    BOTTOM: "bottom",
    RIGHT: "right",
    LEFT: "left"
}

const Tooltip = ({id, text, placement=TOOLTIP_PLACEMENTS.TOP, center=true, clickable=false, delayHide=0}) => (
    <ReactTooltip id={id} effect='solid' textColor={COLORS["color-text-white"]} backgroundColor={COLORS["color-tooltip"]} className={classnames("ps-tooltip", {center})} place={placement} clickable={clickable} delayHide={delayHide}>
        <span>{text}</span>
    </ReactTooltip>
)

export const TooltipWrapper = ({id, text, placement, center, children, style={}, clickable, delayHide}) => (
    <React.Fragment>
        <div data-tip data-for={id} style={{display: "inline-block", ...style}}>{children}</div>
        <Tooltip id={id} text={text} placement={placement} center={center} clickable={clickable} delayHide={delayHide} />
    </React.Fragment>
)

export default Tooltip;

