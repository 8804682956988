import React from 'react';
import { CONNECTION_ENDPOINT_TYPES } from 'utils/systemConsts';
import { SelectField } from 'components/Form';
import { EnvironmentForm } from '../RuleSubForms';
import { ENDPOINT_TYPE_ITEMS } from '../utils'
import { MASTER_TYPE_FIELD_NAME } from './utils';

const ENDPOINT = CONNECTION_ENDPOINT_TYPES.SOURCE;

const FormSource = (props) => (
        <React.Fragment>
            <SelectField 
                name={`${ENDPOINT}.${MASTER_TYPE_FIELD_NAME}`}
                label="Select an option to identify the source"
                items={[
                    ENDPOINT_TYPE_ITEMS.ENVIRONMENT
                ]}
                disabled={true}
            />
            <EnvironmentForm {...props} endpoint={ENDPOINT} />
        </React.Fragment>
    );

export default FormSource;