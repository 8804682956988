import React from 'react';
import { isEmpty, pickBy } from 'lodash';
import { valueToValueLabel } from 'utils/apiUtils';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { useAuthState } from 'context/AuthProvider';
import { RULE_ACTIONS, ENFORCE_ACTION_ITEM } from 'utils/systemConsts';
import { useMountMultiFetch } from 'hooks';
import Loader from 'components/Loader';
import Wizard from 'components/Wizard';
import { DEPLOYMENT_USER_RULE_TYPES } from '../../utils';
import { POLICY_TYPE } from '../utils';
import FormRuleProperties from './FormRuleProperties';
import FormWorkloads, { WORKLOAD_RULE_TYPE, POD_WORKLOAD_RULE_TYPES_ITEMS, EMPTY_LABEL_ITEM, EMPTY_VULNERABILIY_VALUE,
    EMPTY_PSP_VALUE, EMPTY_API_PROFILE_VALUE, EMPTY_SIDECAR_VALUE, SIDECAR_TYPES_ITEMS } from './FormWorkloads';
import FormTokens, { EMPTY_TOKEN_ITEM } from './FormTokens';
import {RULE_SCOPE_ITEMS} from "./FormScope";
import FormScope from "./FormScope";
import './user-rule-form.scss';

export {
    POD_WORKLOAD_RULE_TYPES_ITEMS,
    SIDECAR_TYPES_ITEMS
};

const UserRuleForm = ({initialData, onDirtyChanage, onDone}) => {
    const {isReadOnlyUser} = useAuthState();
    const {showApiSecurity} = useDisplayConfigState();

    const [{loading, data, error}] = useMountMultiFetch([
        {key: "environments", url: "environments"},
        {key: "clusters", url: "leanKubernetesClusters"},
        {key: "pspProfiles", url: "podSecurityPolicyProfiles"},
        {key: "tokens", url: "tokens"},
        ...(!showApiSecurity ? [] : [
            {key: "apiSecurityPolicy", url: "apiSecurityPolicy"},
        ])
    ]);
    
    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const isEditForm = !!initialData && initialData.id;
    
    const {environments, clusters, pspProfiles, apiSecurityPolicy, tokens} = data || {};
    const environmentNames = !environments ? [] : valueToValueLabel([...new Set(environments.map(item => item.name))]);
    const clusterNames = !clusters ? [] : clusters.map(({name, kubernetesSecurity}) => ({value: name, label: name, isDisabled: !kubernetesSecurity}));
    const profileNames = !pspProfiles ? [] : pspProfiles.map(({id, name}) => ({value: id, label: name}));
    const apiSecurityPolicyNames = !apiSecurityPolicy ? [] : apiSecurityPolicy.map(({id, name}) => ({value: id, label: name}));
    const tokenNames = !tokens ? [] : tokens.map(({id, name}) => ({value: id, label: name}));
    
    const initialValues = {
        name: "",
        ...(initialData || {})
    };

    initialValues.ruleTypeProperties = {
        ruleType: DEPLOYMENT_USER_RULE_TYPES.VIOLATION,
        tokens: [EMPTY_TOKEN_ITEM],
        action: RULE_ACTIONS.ALLOW,
        ...(initialValues.ruleTypeProperties || {})
    }
    
    const isInjectionRule = initialValues.ruleTypeProperties.ruleType === DEPLOYMENT_USER_RULE_TYPES.INJECTION;

    initialValues.app = {
        [WORKLOAD_RULE_TYPE.name]: WORKLOAD_RULE_TYPE.value, //display only field
        workloadRuleType: POD_WORKLOAD_RULE_TYPES_ITEMS.NAME.value,
        names: [],
        labels: [EMPTY_LABEL_ITEM],
        ...(initialValues.app || {})
    }

    initialValues.scope = {
        workloadRuleScopeType: RULE_SCOPE_ITEMS.CLUSTER.value,
        names: [],
        ...initialValues.scope
    };

    if (!isEmpty(initialValues.app.names)) {
        initialValues.app.names = valueToValueLabel(initialValues.app.names); //AsyncSelectField needs [{value,label}] initital value
    }

    let {vulnerability: initialVulnerability, podSecurityPolicy: initialPodSecurityPolicy, apiSecurityPolicy: initialApiSecurityPolicy, allowedInjectedSidecars: initialAllowedInjectedSidecars} = initialValues.app.podValidation || {};
    initialVulnerability = initialVulnerability || {};
    initialPodSecurityPolicy = initialPodSecurityPolicy || {};
    initialApiSecurityPolicy = initialApiSecurityPolicy || {};
    initialAllowedInjectedSidecars = initialAllowedInjectedSidecars || {};

    const apiPolicyExists = apiSecurityPolicyNames.map(item => item.value).includes(initialApiSecurityPolicy.apiSecurityPolicyId);
    initialValues.app.podValidation = {
        vulnerability: {
            highestVulnerabilityAllowed: initialVulnerability.highestVulnerabilityAllowed || EMPTY_VULNERABILIY_VALUE.highestVulnerabilityAllowed,
            onViolationAction: initialVulnerability.onViolationAction || EMPTY_VULNERABILIY_VALUE.onViolationAction
        },
        podSecurityPolicy: {
            podSecurityPolicyId: initialPodSecurityPolicy.podSecurityPolicyId || EMPTY_PSP_VALUE.podSecurityPolicyId,
            onViolationAction: initialPodSecurityPolicy.onViolationAction || EMPTY_PSP_VALUE.onViolationAction
        },
        apiSecurityPolicy: {
            apiSecurityPolicyId: apiPolicyExists ? initialApiSecurityPolicy.apiSecurityPolicyId : EMPTY_API_PROFILE_VALUE.apiSecurityPolicyId,
            onViolationAction: apiPolicyExists ? (initialApiSecurityPolicy.onViolationAction || EMPTY_API_PROFILE_VALUE.onViolationAction) : EMPTY_API_PROFILE_VALUE.onViolationAction
        },
        allowedInjectedSidecars: !!initialAllowedInjectedSidecars.type ? initialAllowedInjectedSidecars : EMPTY_SIDECAR_VALUE
    }
    
    if (initialPodSecurityPolicy.shouldMutate) {
        initialValues.app.podValidation.podSecurityPolicy.onViolationAction = ENFORCE_ACTION_ITEM.value;
    }

    const ruleTitle = `${isInjectionRule ? "API token injection" : POLICY_TYPE} Rule`;
    
    return (
        <Wizard
            className="deployment-policy-user-rule-form"
            initialValues={initialValues}
            title={isReadOnlyUser ? `${ruleTitle} (Audit mode)` : `${isEditForm ? "Edit" : "New"} ${ruleTitle}`}
            disableSubmit={isReadOnlyUser}
            steps={[
                {
                    title: "Rule Properties",
                    component: FormRuleProperties
                },
                {
                    title: "Workloads",
                    component: FormWorkloads
                },
                {
                    title: "Rule Scope",
                    component: FormScope
                },
                ...(!isInjectionRule ? [] : [{
                    title: "Tokens",
                    component: FormTokens
                }])
            ]}
            formProps={{
                environmentNames,
                clusterNames,
                pspProfiles: profileNames,
                apiSecurityProfiles: apiSecurityPolicyNames,
                tokenNames,
                isInjectionRule,
                isReadOnlyUser
            }}
            doCustomSubmit={formValues => {
                delete formValues.app[WORKLOAD_RULE_TYPE.name]; //remove the display only field
                
                formValues.app.names = formValues.app.names.map(item => item.value); //format AsyncSelectField value from [{value, label}] to [value]
                formValues.app.labels = formValues.app.labels.filter(item => item.key !== "" && item.value !== ""); //clean empty labels

                formValues.app = pickBy(formValues.app, value => !isEmpty(value));
                formValues.scope.names = formValues.scope.names.filter(item => item.value !== "" && item.label !== "")
                if(formValues.scope.workloadRuleScopeType === RULE_SCOPE_ITEMS.ANY.value){
                    delete formValues.scope.names;
                }

                const {vulnerability, podSecurityPolicy, apiSecurityPolicy, allowedInjectedSidecars} = formValues.app.podValidation;

                if (!apiSecurityPolicy.apiSecurityPolicyId) {
                    formValues.app.podValidation.apiSecurityPolicy = null;
                }
                if (!vulnerability.highestVulnerabilityAllowed) {
                    formValues.app.podValidation.vulnerability = null;
                }
                if (!podSecurityPolicy.podSecurityPolicyId) {
                    formValues.app.podValidation.podSecurityPolicy = null;
                } else {
                    formValues.app.podValidation.podSecurityPolicy.shouldMutate = false;

                    if (podSecurityPolicy.onViolationAction === ENFORCE_ACTION_ITEM.value) {
                        formValues.app.podValidation.podSecurityPolicy.onViolationAction = RULE_ACTIONS.DETECT;
                        formValues.app.podValidation.podSecurityPolicy.shouldMutate = true;
                    }
                }

                if (formValues.ruleTypeProperties.action === RULE_ACTIONS.ALLOW && !!allowedInjectedSidecars) {
                    formValues.app.podValidation.allowedInjectedSidecars = null;
                }

                if (isInjectionRule) {
                    delete formValues.ruleTypeProperties.action;
                    delete formValues.app.podValidation;
                } else {
                    delete formValues.ruleTypeProperties.tokens;
                }
                
                onDone(formValues);
            }}
            onDirtyChanage={onDirtyChanage}
        />
    );
};

export default UserRuleForm;