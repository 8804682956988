import React, { useEffect } from 'react';
import { useFetch, usePrevious, FETCH_METHODS } from 'hooks';
import { isNull } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { useNotificationDispatch, showNotification, NOTIFICATION_TYPES } from 'context/NotificationProvider';
import { useAccountParamsState } from 'context/AccountParamsProvider';
import Icon, { ICON_NAMES } from 'components/Icon';
import { TooltipWrapper } from 'components/Tooltip';
import { REGULAR_USER_ROLES_ITEMS, CONTROLLER_STATUS_ITEMS } from 'utils/systemConsts';
import { isYamlController } from 'utils/generalUtils';

import COLORS from 'utils/scss_variables.module.scss';

const UpdateButton = ({id, url, onUpdateSuccess}) => {
    const notificationDispatch = useNotificationDispatch();

    const [{loading, error: updateVersionError}, updateAgentVersion] = useFetch(`${url}/${id}/update`, {loadOnMount: false});
    const prevLoading = usePrevious(loading);

    const onUpdateClick = event => {
        event.preventDefault();
        event.stopPropagation();

        updateAgentVersion({method: FETCH_METHODS.POST});
    }

    useEffect(() => {
        if (prevLoading && !loading) {
            showNotification(notificationDispatch, {
                message: !!updateVersionError ? (updateVersionError?.message || "An error occurred when trying to update the controller") : "Update request sent",
                type: !!updateVersionError ? NOTIFICATION_TYPES.ERROR : NOTIFICATION_TYPES.SUCCESS
            });
            
            if (!updateVersionError) {
                onUpdateSuccess();
            }
        }
    }, [prevLoading, loading, updateVersionError, notificationDispatch, onUpdateSuccess]);

    if (loading) {
        return (
            <div>loading...</div>
        );
    }

    return (
        <div style={{fontWeight: "bold", textDecoration: "underline", cursor: "pointer"}} onClick={onUpdateClick}>Update</div>
    )
}

const ControllerVersionDisplay = ({id, version, status, enabled, isUpdateEnabled, agentsUrl, isAgentAccessAllowed, autoUpgradeControllerVersion, isHelmInstallation, onUpdateSuccess}) => {
    const {isReadOnlyUser, isGlobalAdmin, role} = useAuthState();
    const {latestAgentVersion, minControllerAutoUpdateVersion} = useAccountParamsState();
    
    const isAccountAdmin = role === REGULAR_USER_ROLES_ITEMS.ACCOUNT_ADMIN.value;
    const isAdminUser = isGlobalAdmin || isAccountAdmin;

    const isYaml = isYamlController({version, minControllerAutoUpdateVersion});
    
    const showUpdate = !isYaml && isUpdateEnabled && isAdminUser && version !== latestAgentVersion && enabled &&
        status === CONTROLLER_STATUS_ITEMS.ACTIVE.value && isAgentAccessAllowed && !isReadOnlyUser && !isHelmInstallation;
    
    const tooptipText = (
        <span>Your controller version isn't<br/>{`the latest available (${latestAgentVersion}).`}{!showUpdate ? null : <UpdateButton id={id} url={agentsUrl} onUpdateSuccess={onUpdateSuccess} />}</span>
    );
    
    if (isNull(version)) {
        return null;
    }

    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <div>{version}</div>
            {autoUpgradeControllerVersion &&
                <TooltipWrapper id={`clustor-controller-auto-update-${id}`} text="Controller auto update enabled" style={{marginLeft: "5px"}}>
                    <Icon name={ICON_NAMES.UPDATING} style={{color: COLORS["color-main"]}} />
                </TooltipWrapper>
            }
            {!autoUpgradeControllerVersion && version !== latestAgentVersion &&
                <TooltipWrapper id={`clustor-controller-version-${id}`} text={tooptipText} clickable delayHide={300} style={{marginLeft: "5px"}}>
                    <Icon name={ICON_NAMES.ALERT} style={{color: COLORS["color-warning"]}} />
                </TooltipWrapper>
            }
        </div>
    )
}

export default ControllerVersionDisplay;