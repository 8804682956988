import React from 'react';
import { tableUtils } from 'components/Table';

export const PROTECTED_PODS_ITEMS = {
    ALL: {
        value: "ALL",
        label: "All"
    },
    ONLY_PROTECTED: {
        value: "ONLY_PROTECTED",
        label: "Protected"
    },
    ONLY_UNPROTECTED: {
        value: "ONLY_UNPROTECTED",
        label: "Unprotected"
    },
}; 

const ProtectedStatus = ({isProtected}) => (
    <tableUtils.StatusRenderer
        isActive={isProtected}
        customText={isProtected ? PROTECTED_PODS_ITEMS.ONLY_PROTECTED.label :
            PROTECTED_PODS_ITEMS.ONLY_UNPROTECTED.label}
    />
);

export default ProtectedStatus;