import React, { useEffect, useCallback, useState } from 'react';
import { FieldArray } from 'formik';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { useFetch, usePrevious } from 'hooks';
import { MultiselectField, SelectField, validators, RadioField, TextField, ArrayField, useFormikContext, AddIcon, RemoveIcon } from 'components/Form';
import Arrow from 'components/Arrow';

const KUBERNETES_ENV_BY_ITEMS = {
    BY_NAME: {
        value: "BY_NAME",
        label: "By Name"
    },
    BY_LABEL: {
        value: "BY_LABEL",
        label: "By Label"
    }
};

const NAMESPACES_LABELS_EMPTY_ITEM = {key: "", value: ""};

const INITITAL_KEBERNETES_ENV_DATA = {
    kubernetesCluster: "",
    namespaces: [],
    namespaceLabels: [{...NAMESPACES_LABELS_EMPTY_ITEM}],
    by: KUBERNETES_ENV_BY_ITEMS.BY_NAME.value
};

export {
    KUBERNETES_ENV_BY_ITEMS,
    INITITAL_KEBERNETES_ENV_DATA
}

const KubernetesEnvFormField = ({clusterItems, kubernetesEnvironment, allowRemove, index, remove, push, isOpen, setIsOpen}) => {
    const {setFieldValue} = useFormikContext();

    const [{loading, data: clusterNamespaces}, loadNamespacesData] = useFetch("kubernetesClusters", {loadOnMount: false});

    const loadClusterNamespacesData = clusterId => loadNamespacesData({formatUrl: url => `${url}/${clusterId}/namespaces`});
    const doLoadClusterNamespacesData = useCallback(loadClusterNamespacesData, [loadNamespacesData]);

    const {kubernetesCluster, by} = kubernetesEnvironment;
    const prevKubernetesCluster = usePrevious(kubernetesCluster);
    const prevBy = usePrevious(by);

    useEffect(() => {
        if (!!kubernetesCluster && kubernetesCluster !== prevKubernetesCluster) {
            doLoadClusterNamespacesData(kubernetesCluster);  
        }

        if (kubernetesCluster !== prevKubernetesCluster && !!prevKubernetesCluster) {
            //reset field only if not initital load:
            setFieldValue(`kubernetesEnvironments.${index}.by`, KUBERNETES_ENV_BY_ITEMS.BY_NAME.value);
        }
    }, [kubernetesCluster, prevKubernetesCluster, setFieldValue, doLoadClusterNamespacesData, index]);

    useEffect(() => {
        if(!isEmpty(prevBy) && by !== prevBy) {
            setFieldValue(`kubernetesEnvironments.${index}.namespaces`, []);
            setFieldValue(`kubernetesEnvironments.${index}.namespaceLabels`, [NAMESPACES_LABELS_EMPTY_ITEM]);
        }
    }, [by, prevBy, setFieldValue, index]);

    const namespaceItems = isEmpty(clusterNamespaces) ? [] : clusterNamespaces.map(({name}) => ({value: name, label: name}));
    
    return (
        <div key={index} className="environment-wrapper">
            <div className="environment-content">
                <div className="environment-header-wrapper" onClick={setIsOpen}>
                    <div>Kubernetes Settings</div>
                    <Arrow name={isOpen ? "top" : "bottom"} small={true} />
                </div>
                {isOpen &&
                    <div className="kubernetes-env-input-wrapper">
                        <SelectField
                            name={`kubernetesEnvironments.${index}.kubernetesCluster`}
                            label="Cluster name"
                            items={clusterItems}
                            validate={validators.validateRequired}
                            clearable={false}
                        />
                        <RadioField
                            name={`kubernetesEnvironments.${index}.by`}
                            label="Namespaces"
                            items={Object.values(KUBERNETES_ENV_BY_ITEMS)}
                            horizontal={true}
                            disabled={!kubernetesCluster}
                        />
                        {by === KUBERNETES_ENV_BY_ITEMS.BY_NAME.value &&
                            <MultiselectField
                                name={`kubernetesEnvironments.${index}.namespaces`}
                                label="Names"
                                items={namespaceItems}
                                validate={validators.validateRequired}
                                disabled={!kubernetesCluster}
                                loading={loading}
                                creatable={true}
                            />
                        }
                        {by === KUBERNETES_ENV_BY_ITEMS.BY_LABEL.value &&
                            <ArrayField
                                name={`kubernetesEnvironments.${index}.namespaceLabels`}
                                label="Labels"
                                disabled={!kubernetesCluster}
                                firstFieldProps={{
                                    component: TextField,
                                    key: "key",
                                    placeholder: "Key",
                                    validate: validators.validateRequired
                                }}
                                secondFieldProps={{
                                    component: TextField,
                                    key: "value",
                                    placeholder: "Value",
                                    validate: validators.validateRequired
                                }}
                            />
                        }
                    </div>
                }
            </div>
            <div className={classnames("environment-actions-wrapper", `kubernetes-settings-actions-${index}`)}>
                <AddIcon onClick={() => push({...INITITAL_KEBERNETES_ENV_DATA})} />
                <RemoveIcon onClick={() => remove(index)} disabled={!allowRemove} />
            </div>
        </div>
    )
}

const FormStep2 = ({clusterItems, formValues} ) => {
    const {kubernetesEnvironments} = formValues;
    const envsCount = kubernetesEnvironments.length;
    const allowRemove = envsCount > 1;

    const {validateForm} = useFormikContext();
    const [openKubernetesEnv, setOpenKubernetesEnv] = useState(0);

    useEffect(() => {
        setOpenKubernetesEnv(envsCount - 1);
    }, [envsCount, setOpenKubernetesEnv]);

    return (
        <div className="kubernetes-environments-wrapper">
            <FieldArray name="kubernetesEnvironments">
                {(fieldArrayProps) => kubernetesEnvironments.map((kubernetesEnvironment, index) => (
                        <KubernetesEnvFormField
                            key={index}
                            clusterItems={clusterItems}
                            kubernetesEnvironment={kubernetesEnvironment}
                            allowRemove={allowRemove}
                            index={index}
                            isOpen={openKubernetesEnv === index}
                            setIsOpen={() => {
                                setOpenKubernetesEnv(index);
                                validateForm();
                            }}
                            {...fieldArrayProps}
                        />
                    ))}
            </FieldArray>
            
        </div>
    )
}

export default FormStep2;