import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useFetch } from 'hooks';
import { TIME_RANGER_MAP } from 'context/TimeRangeProvider';
import Loader from 'components/Loader';
import DataGraph from 'components/DataGraph';
import { CONNECTIONS_PROTOCOLS } from 'utils/systemConsts';

import './tab-statistics.scss';

const WRAPPER_CLASS_NAME = "runtime-connection-statistics";

const TableGraphs = ({startTime, endTime, sourceNamespace, sourcePodTemplate, targetNamespace, targetPodTemplate, protocol}) => {
    const [{loading, data: metricsData, error}] = useFetch("performanceMetrics", {
        queryParams: {
            startTime,
            endTime,
            sourceNamespace,
            targetNamespace,
            sourcePodTemplate,
            targetPodTemplate,
            protocol
        }});

    if (loading) {
        return (
            <div className={WRAPPER_CLASS_NAME}><Loader absolute={false} /></div>
        );
    }

    if (!!error) {
        return null;
    }

    const {ops, errorRate, averageResponseTime, connectionProtocol, totalSentBytes, totalReceivedBytes} = metricsData;

    const timeRange = moment(endTime).diff(startTime);
    const daysRange = moment.duration(timeRange).asDays();
    const minutesRange = moment.duration(timeRange).asMinutes();

    let dateFormat = TIME_RANGER_MAP.HOUR.graphDateFormat;
    if (daysRange >= 7) {
        dateFormat = TIME_RANGER_MAP.WEEK.graphDateFormat;
    } else if (minutesRange <= 5) {
        dateFormat = TIME_RANGER_MAP["5MIN"].graphDateFormat;
    }

    return (
        <div className={WRAPPER_CLASS_NAME}>
            <div className="graphs-wrapper">
                <DataGraph
                    title="Total Sent/Received Bytes"
                    data={totalSentBytes}
                    subData={totalReceivedBytes}
                    valueSuffix="bytes"
                    dateFormat={dateFormat}
                    valueTitle="sent"
                    subValueTitle="received"
                />
                {connectionProtocol === CONNECTIONS_PROTOCOLS.HTTP &&
                    <React.Fragment>
                        <DataGraph
                            title="Operations per Second"
                            data={ops}
                            valueSuffix=""
                            dateFormat={dateFormat}
                        />
                        <DataGraph
                            title="Error Rate (%)"
                            data={errorRate}
                            valueSuffix="%"
                            dateFormat={dateFormat}
                        />
                        <DataGraph
                            title="Average Response Time (ms)"
                            data={averageResponseTime}
                            valueSuffix="ms"
                            dateFormat={dateFormat}
                        />
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

const TabStatistics = ({data, startTime, endTime}) => {
    const {source, target, state} = data;
    const {instance: sourceInstance, pod: sourcePod} = source;
    const {instance: targetInstance, pod: targetPod} = target;
    const {namespaceId: sourceNamespace} = sourceInstance || {};
    const {id: sourcePodTemplate} = sourcePod
    const {namespaceId: targetNamespace} = targetInstance || {};
    const {id: targetPodTemplate} = targetPod
    
    if (!sourceNamespace || !sourcePodTemplate || !targetNamespace || !targetPodTemplate) {
        return (
            <div className={classnames(WRAPPER_CLASS_NAME, "empty")}>
                No data available
            </div>
        )
    }

    return (
        <TableGraphs
            startTime={startTime}
            endTime={endTime}
            sourceNamespace={sourceNamespace}
            sourcePodTemplate={sourcePodTemplate}
            targetNamespace={targetNamespace}
            targetPodTemplate={targetPodTemplate}
            protocol={state.protocol}
        />
    )
}

export default TabStatistics;