import React from 'react';
import { useHistory } from 'react-router-dom';
import RuleActionsDisplay from 'layout/Runtime/RuleActionsDisplay';
import { IMAGES_FILTER_KEYS, IMAGE_PAGE_PATH } from 'layout/CiCd/Images';
import InfoIcon from 'components/InfoIcon';

import COLORS from 'utils/scss_variables.module.scss';

const VIOLATION_REASONS_ITEMS = {
    VULNERABILITY: {
        value: "VULNERABILITY",
        label: "Vulnerability: affected images"
    },
    PSP: {
        value: "PSP",
        label: "Pod Security Standards"
    },
    SIDECAR: {
        value: "SIDECAR",
        label: "Injected Sidecar"
    }
};

export const ViolationReasonsDisplay = ({violation, podRuntimeInfo, withViolationLink, onPspClick}) => {
    const history = useHistory();

    violation = violation || {};
    const reasons = violation.violationReasons || [];

    const ReasonDisplay = ({reason, onClick}) => {
        const style = !onClick ? {} : {
            cursor: "pointer",
            textDecoration: "underline",
            color: COLORS["color-main-light"]
        };

        return (
            <div style={style} onClick={onClick}>
                {VIOLATION_REASONS_ITEMS[reason].label}
            </div>
        );
    }

    return (
        <React.Fragment>
            {reasons.map(reason => {
                if (reason === VIOLATION_REASONS_ITEMS.VULNERABILITY.value) {
                    let {containers} = podRuntimeInfo || {};
                    containers = containers || [];

                    const onLinkClick = event => {
                        event.stopPropagation();
                        event.preventDefault();
                
                        history.push({pathname: IMAGE_PAGE_PATH, query: {filters: {
                            [IMAGES_FILTER_KEYS.IMAGE_NAME]: containers.map(({image}) => image.repository).join(", "),
                            [IMAGES_FILTER_KEYS.IMAGE_HASH]: containers.map(({image}) => image.hash).join(", ")
                        }}})
                    }

                    return (
                        <ReasonDisplay key={reason} reason={reason} onClick={withViolationLink ? onLinkClick : undefined} />
                    )
                }
                
                if (!!onPspClick && reason === VIOLATION_REASONS_ITEMS.PSP.value) {
                    return (
                        <ReasonDisplay key={reason} reason={reason} onClick={onPspClick} />
                    )
                }

                return (
                    <ReasonDisplay key={reason} reason={reason} />
                )
            })}
        </React.Fragment>
    );
}

export const WorkloadRuleResultDisplay = ({tooltipId, violation}) => {
    const {defaultRule: defaultRuleAction, unidentifiedPodsRule, userRule, action, comment} = violation || {};
    
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <RuleActionsDisplay
                tooltipId={tooltipId}
                defaultRule={!!defaultRuleAction ? {action} : null}
                implicitRule={unidentifiedPodsRule}
                userRule={(!!userRule && !!userRule.id) ? {...userRule, action} : null}
            />
            {!!comment && <InfoIcon tooltipId="workload-rule-result-commend-tooltip-id" text={comment}/>}
        </div>
    )
}