import React from 'react';

const InnerTable = ({data}) =>  (
    <table className="pod-templates-inner-table">
        <thead>
            <tr>
                <th>Repository</th>
                <th>Tag</th>
            </tr>
        </thead>
        <tbody>
            {
                data.containers.map(({image}, index) => 
                    <tr key={index}>
                        <td>{image.repository}</td>
                        <td>{image.tag}</td>
                    </tr>
                )
            }
        </tbody>
    </table>
);

export default InnerTable;