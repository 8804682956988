import { APPLICATION_NAME } from 'utils/systemConsts';

export const DEPLOYER_TYPE_ITEMS = {
    OperatorDeployer: {
        value: "OperatorDeployer",
        label: "Operator",
        tooltip: "Kubernetes operator related to a specific cluster"
    },
    SecureCnDeployer: {
        value: "SecureCnDeployer",
        label: `${APPLICATION_NAME} Service User`,
        tooltip: `${APPLICATION_NAME} Service user, typically used by a CI/CD plugin`
    }
};

export const isOperatorDeployer = type => type === DEPLOYER_TYPE_ITEMS.OperatorDeployer.value;