import React, { useState } from 'react';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import Icon, { ICON_NAMES } from 'components/Icon';
import { LabelsDisplay } from 'components/LabelTag';
import Arrow, { ARROW_NAMES } from 'components/Arrow';

export const WithSubtitleRenderer = ({title, subTitle}) => (
    <div className="with-subtitile-wrapper">
        <div>{title}</div>
        <div className="cell-sub-title">{subTitle}</div>
    </div>
)
export const KeyValueRenderer = ({items}) => {
    return (
        <React.Fragment>
            {items.map(({key, value}) => <div key={key}>{`${key} : ${value}`}</div>)}
        </React.Fragment>
    )
}

export const StatusRenderer = ({isActive, isWarning, enabledText="Active", disabledText="Inactive", customText}) => (
    <div className="status-indicator-wrapper">
        <div className={classnames("status-indicator", {active: isActive}, {warning: isWarning})}></div>
        {!!customText ? <div>{customText}</div> : <div>{isActive ? enabledText : disabledText}</div>}
    </div>
)

export const getFilterItemsWithUndefined = (items, {valuesOnly=false, exclude=[], withUndefined=true}={}) => {
    let updated = cloneDeep(items);
    if (exclude.length > 0) {
        updated = updated.filter(item => !exclude.includes(item.value));
    }
    if (withUndefined) {
        updated.push({value: "UNDEFINED", label: "Undefined"});
    }
    
    if (!valuesOnly) {
        return updated;
    }

    return updated.map(({value}) => value);
}

export const StatusIcon = ({isSuccess, isError}) => (
    <div className="table-utils-status-icon-wrapper">
        <Icon
            name={isSuccess ? ICON_NAMES.SUCCESS : (isError ? ICON_NAMES.ERROR : ICON_NAMES.STROKE)}
            className={classnames("status-icon", {success: isSuccess}, {error: isError})}
        />
    </div>
)

export const ExpendableTagsCell = ({maxCount=3, ...props}) => {
    const items = props.items || [];
    const minimizedItems = items.slice(0, maxCount);

    const [itemsToDisplay, setItemsToDisplay] = useState(items.length > maxCount ? minimizedItems : items);
    const isOpen = itemsToDisplay.length === items.length;

    return (
        <div>
            <div className="table-utils-expendable-tags-cell">
                <LabelsDisplay labels={itemsToDisplay} />
                {minimizedItems.length !== items.length &&
                    <Arrow
                        name={isOpen ? ARROW_NAMES.TOP : ARROW_NAMES.BOTTOM}
                        onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();
                            
                            setItemsToDisplay(isOpen ? minimizedItems : items);
                        }}
                        small
                    />
                }
            </div>
        </div>
    )
}