import React, { useMemo, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Table from 'components/Table';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';
import RiskTag from 'components/RiskTag';
import { PERMISSIONS_PAGE_PATH, BREADCRUMBS_TITLE } from './utils';

export const ItemsList = ({items}) => (
    <div style={{whiteSpace: "normal"}}>{items.join(", ")}</div>
);

const RolePermissions = () => {
    const columns = useMemo(() => [
		{
			Header: "Resources",
			id: "resources",
			Cell: ({row}) => <ItemsList items={row.original.resources} />
		},
		{
			Header: "Resource Names",
			id: "resourceNames",
			Cell: ({row}) => <ItemsList items={row.original.resourceNames} />
		},
		{
			Header: "API Groups",
			id: "apiGroups",
			Cell: ({row}) => <ItemsList items={row.original.apiGroups} />
		},
		{
			Header: "Verbs",
			id: "verbs",
			Cell: ({row}) => <ItemsList items={row.original.verbs} />
		},
		{
			Header: "Risk",
			id: "risk",
			Cell: ({row}) => <RiskTag risk={row.original.risk} />,
		},
		{
			Header: "Risk Reasons",
			id: "riskReason",
			Cell: ({row}) => (
				<div style={{ whiteSpace: "normal" }}>
					{
						row.original.riskReason.map((item, index) => <div key={index}>{item}</div>)
					}
				</div>
			),
			minWidth: 300
		}
	], []);

	const [titleNames, setTitleNames] = useState(null);
	const {clusterName, ownerName, roleName} = titleNames || {};
    const nameIsSetRef = useRef(false);

    const history = useHistory();

    const params = useParams();
	const {clusterId, ownerId, roleId} = params;
	
	const clusterPath = `/${clusterId}`;
	const backPath = `${clusterPath}/${ownerId}`;

    return (
        <div className="permissions-page">
            <BreadcrumbsHeader
				title={BREADCRUMBS_TITLE}
				baseUrl={PERMISSIONS_PAGE_PATH}
				backPath={backPath}
				breadcrumbs={[
					{title: `${clusterName} cluster`, path: clusterPath},
					{title: `${ownerName} owner`, path: backPath},
					{title: `${roleName} role`}
				]}
				hideBreadcrumbs={!clusterName}
			/>
            <Table
                url={`riskAssessment/permissions/${clusterId}/${ownerId}/${roleId}`}
                name="role-risk-assessment-permissions"
                columns={columns}
                onFetchError={() => history.push({pathname: `${PERMISSIONS_PAGE_PATH}${backPath}`})}
                formatFetchedData={data => {
					const {resources} = data || {};
					
                    return resources;
                }}
                onFetchedDataChange={data => {
					const {clusterName, ownerName, roleName} = data || {};
					
                    if (!!clusterName && !nameIsSetRef.current) {
                        setTitleNames({clusterName, ownerName, roleName});
                        nameIsSetRef.current = true;
                    }
				}}
				withPagination={false}
            />
        </div>
    )
}

export default RolePermissions;