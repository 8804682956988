import React from 'react';
import { isEmpty, has, isNull, pickBy } from 'lodash';
import { useMountMultiFetch } from 'hooks';
import { useAuthState } from 'context/AuthProvider';
import Loader from 'components/Loader';
import Wizard from 'components/Wizard';
import { valueToValueLabel, nameIdToValueLabel } from 'utils/apiUtils';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { POLICY_TYPE, ALL_KEY } from '../utils';
import { CUSTOM_RULE_ID, EMPTY_USER_ITEM, EMPTY_RESOURCE_ITEM, RULE_SCOPE_ITEMS } from './utils';
import FormSelectRule from './FormSelectRule';
import FormKubernetesApi from './FormKubernetesApi';
import FormRuleScope, { ENVIRONMENT_BY_ITEMS } from './FormRuleScope';
import FormRuleProperties from './FormRuleProperties';

export {
    ENVIRONMENT_BY_ITEMS,
    RULE_SCOPE_ITEMS
};

export const RULE_TYPES = {
    CUSTOM: "KubernetesApiCustomRule",
    RECOMMENDED: "KubernetesApiRecommendedRule"
}

const getValueListItemFromItemsList = (items, mainKey, dependentKey) => {
    const formattedItemsObject = items.reduce((acc, curr) => {
        const key = curr[mainKey];
        const value = curr[dependentKey];

        if (has(acc, key)) {
            if (!isNull(value)) {
                acc[key].push(value);
            }
        } else {
            acc[key] = isNull(value) ? [] : [value];
        }

        return acc;
    }, {});

    return Object.keys(formattedItemsObject).map(key => {
        const dependentValue = formattedItemsObject[key];
        
        return {
            [mainKey]: key,
            [dependentKey]: isEmpty(dependentValue) ? [ALL_KEY] : dependentValue
        };
    });
}

const getItemsListFromValueList = (items, mainKey, dependentKey) => {
    const formattedItems = [];

    items.forEach(item => {
        const mainValue = item[mainKey];
        const dependentValue = item[dependentKey];

        dependentValue.forEach(value => {
            formattedItems.push({[mainKey]: mainValue, [dependentKey]: value === ALL_KEY ? null : value});
        })
    });

    return formattedItems;
}

const UserRuleForm = ({initialData, onDirtyChanage, onDone, startOnStep}) => {
    const {isReadOnlyUser} = useAuthState();

    const [{loading, data, error}] = useMountMultiFetch([
        {key: "environments", url: "environments"},
        {key: "clusters", url: "leanKubernetesClusters"},
        {key: "recommendedRules", url: "kubernetesApiPolicy/recommendedRules"},
        {key: "kubernetesResources", url: "kubernetesApiPolicy/kubernetesResources"},
        {key: "kubernetesUsers", url: "kubernetesApiPolicy/kubernetesUsers"}
    ]);

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const isEditForm = !!initialData && initialData.id;
    
    const {environments, clusters, recommendedRules, kubernetesResources, kubernetesUsers} = data || {};
    const environmentNames = !environments ? [] : valueToValueLabel([...new Set(environments.map(item => item.name))]);
    const clusterNames = nameIdToValueLabel(clusters || [], item => !item.kubernetesSecurity)

    const initialValues = {
        name: "",
        action: RULE_ACTIONS.ALLOW,
        apiActions: [],
        ...(initialData || {})
    };

    initialValues.recommendedRuleId = !!initialValues.recommendedRuleId ? initialValues.recommendedRuleId : CUSTOM_RULE_ID;
    initialValues.excludedUsers = !isEmpty(initialValues.excludedUsers) ?
        getValueListItemFromItemsList(initialValues.excludedUsers, "userType", "userName") : [EMPTY_USER_ITEM];
    initialValues.users = !isEmpty(initialValues.users) ?
        getValueListItemFromItemsList(initialValues.users, "userType", "userName") : [EMPTY_USER_ITEM];
    initialValues.resources = !isEmpty(initialValues.resources) ?
        getValueListItemFromItemsList(initialValues.resources, "kind", "group") : [EMPTY_RESOURCE_ITEM];
    
    initialValues.ruleScope = {
        scopePartType: RULE_SCOPE_ITEMS.ENVIRONMENT.value,
        clusters: [],
        ...initialValues.ruleScope
    };

    initialValues.ruleScope.environment = {
        environmentRuleType: "",
        names: [],
        risks: [],
        ...(initialValues.ruleScope.environment || {})
    };

    const isRecommendedRuleClusterScopeOnly = ruleId => {
        const selectedRule = recommendedRules.find(rule => rule.id === ruleId);

        return !!selectedRule && selectedRule.isClusterScopeOnly;
    }

    initialValues.isClusterScopeOnly = isRecommendedRuleClusterScopeOnly(initialValues.recommendedRuleId);

    const ruleTitle = `${POLICY_TYPE} Rule`;

    return (
        <Wizard
            className="kubernetes-policy-user-rule-form"
            initialValues={initialValues}
            title={isReadOnlyUser ? `${ruleTitle} (Audit mode)` : `${isEditForm ? "Edit" : "New"} ${ruleTitle}`}
            disableSubmit={isReadOnlyUser}
            disableStepChangeValidation={isReadOnlyUser}
            steps={[
                {
                    title: "Select Rule",
                    component: FormSelectRule
                },
                {
                    title: "Kubernetes API",
                    component: FormKubernetesApi
                },
                {
                    title: "Rule Scope",
                    component: FormRuleScope
                },
                {
                    title: "Rule Properties",
                    component: FormRuleProperties
                }
            ]}
            startOnStep={startOnStep}
            formProps={{
                environmentNames,
                clusterNames,
                recommendedRules: recommendedRules || [],
                kubernetesResources: kubernetesResources || [],
                kubernetesUsers: kubernetesUsers || [],
                isRecommendedRuleClusterScopeOnly,
                isReadOnlyUser
            }}
            doCustomSubmit={formValues => {
                formValues.excludedUsers = getItemsListFromValueList(formValues.excludedUsers, "userType", "userName");
                formValues.users = getItemsListFromValueList(formValues.users, "userType", "userName");
                formValues.resources = getItemsListFromValueList(formValues.resources, "kind", "group");

                delete formValues.isClusterScopeOnly;

                formValues.kubernetesApiRuleType = RULE_TYPES.RECOMMENDED;
                if (formValues.recommendedRuleId === CUSTOM_RULE_ID) {
                    formValues.kubernetesApiRuleType = RULE_TYPES.CUSTOM;
                    delete formValues.recommendedRuleId;
                }

                formValues = pickBy(formValues, (value) => value !== "" && !(Array.isArray(value) && isEmpty(value)));
                formValues.ruleScope = pickBy(formValues.ruleScope, (value) => value !== "" && !(Array.isArray(value) && isEmpty(value)));
                formValues.ruleScope.environment = pickBy(formValues.ruleScope.environment, (value) => value !== "" && !(Array.isArray(value) && isEmpty(value)));

                if (formValues.ruleScope.scopePartType === RULE_SCOPE_ITEMS.CLUSTER.value) {
                    delete formValues.ruleScope.environment;
                }

                onDone(formValues);
            }}
            onDirtyChanage={onDirtyChanage}
        />
    );
};

export default UserRuleForm;