import React, { useEffect } from 'react';
import { useFetch, usePrevious, FETCH_METHODS } from 'hooks';
import { useNotificationDispatch, showNotification, NOTIFICATION_TYPES } from 'context/NotificationProvider';
import { ICON_NAMES } from 'components/Icon';
import IconWithTooltip from 'components/IconWithTooltip';

const ControllerEnableToggleButton = ({id, enabled, agentsUrl, isReadOnlyUser, onUpdateDone}) => {
    const notificationDispatch = useNotificationDispatch();
    
    const [{loading, error}, updateAgentState] = useFetch(agentsUrl, {loadOnMount: false});
    const prevLoading = usePrevious(loading);
    const doAgentStateUpdate = (agentId, enable) => updateAgentState({
        method: FETCH_METHODS.POST,
        formatUrl: url => `${url}/${agentId}/updateState`,
        submitData: {active: enable}
    });

    useEffect(() => {
        if (prevLoading && !loading) {
            showNotification(notificationDispatch, {
                message: !!error ? "An error occurred when trying to updated the controller's status" : "Controller status updated",
                type: !!error ? NOTIFICATION_TYPES.ERROR : NOTIFICATION_TYPES.SUCCESS
            });
            
            onUpdateDone();
        }
    }, [prevLoading, loading, error, notificationDispatch, onUpdateDone]);
    
    return (
        <IconWithTooltip
            name={enabled ? ICON_NAMES.RESTRICT : ICON_NAMES.ENABLE}
            onClick={() => doAgentStateUpdate(id, !enabled)}
            tooltipId={`${id}-toggle-enable`}
            tooltipText={`${enabled ? "Disable" : "Enable"} Controller`}
            disabled={isReadOnlyUser}
        />
    )
}

export default ControllerEnableToggleButton;