import React from 'react';
import CdPlugins from './CdPlugins';
import CiPlugins from './CiPlugins';

import './plugins.scss';

const Plugins = () => (
    <div className="cicd-plugins-page">
        <CdPlugins />
        <CiPlugins />
    </div>
)

export default Plugins;
