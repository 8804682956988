import React from 'react';
import classnames from 'classnames';
import DropdownButton from 'components/DropdownButton';
import IconWithTitle from 'components/IconWithTitle';
import Icon, { ICON_NAMES } from 'components/Icon';
import IconWithTooltip from 'components/IconWithTooltip';
import RoundIconContainer from 'components/RoundIconContainer';
import { RULE_ORIGINS } from 'utils/systemConsts';

import './base-rule-item.scss';

const ORIGIN_ICONS = {
    [RULE_ORIGINS.USER]: {
        name: ICON_NAMES.USER,
        tooltip: "User defined rule"
    },
    [RULE_ORIGINS.AUTOMATED_POLICY]: {
        name: ICON_NAMES.COG,
        tooltip: "Automation defined rule"
    },
    [RULE_ORIGINS.SYSTEM]: {
        name: ICON_NAMES.REPAIR,
        tooltip: "System defined rule"
    }
}

const RuleIcon = ({id, ruleOrigin}) => {
    const icon = ORIGIN_ICONS[ruleOrigin];
    
    if (!icon) {
        return null;
    }
    
    return (
        <IconWithTooltip
            name={icon.name}
            tooltipText={icon.tooltip}
            tooltipId={`rule-icon-${id}`}
            className="origin-icon"
        />
    )
}

const ActionsMenu = ({enabled, onToggleStatus, onDelete, allowDelete}) => (
    <DropdownButton toggleButton={<Icon name={ICON_NAMES.HOVER} />} className="actions-menu-container">
        <div><IconWithTitle 
                name={enabled ? ICON_NAMES.DISABLE : ICON_NAMES.ENABLE} 
                title={enabled ? "Disable rule" : "Enable rule"} 
                onClick={onToggleStatus}
            /></div>
        {allowDelete &&<div><IconWithTitle name={ICON_NAMES.DELETE} title="Delete" onClick={onDelete}/></div>}
    </DropdownButton>
);

const BaseRuleItem = (props) => {
    const {name, onCopy, onEdit, onDelete, onToggleStatus, onDoubleClick, isReadOnly, withPortshiftTag,
        contentRenderer: ContentRenderer, className, enabled, blocked, allowed, encrypted, ignored, disabled,
        withActions=true, allowDelete=true, inGroup, onRemoveFromGroup, id, ruleOrigin, injection, disableViewRule} = props;

    let iconName = blocked ? ICON_NAMES.RESTRICT : ICON_NAMES.ALERT;
    if (allowed) {
        iconName = ICON_NAMES.SUCCESS;
    } else if (encrypted) {
        iconName = ICON_NAMES.LOCK_ROUNDED;
    } else if (ignored) {
        iconName = ICON_NAMES.EYE_CLOSED;
    } else if (injection) {
        iconName = ICON_NAMES.KEY_DIAGONAL;
    }
    
    const containerClassName = classnames(
        "policy-item-container",
        {encrypted},
        {active: enabled || !withActions},
        {disabled},
        className
    );

    const copyTooltipId = `rule-copy-${id}`;
    const editTooltipId = `rule-edit-${id}`;
    const ejectTooltipId = `rule-eject-${id}`;

    return (
        <div className={containerClassName} onDoubleClick={disableViewRule ? undefined : onDoubleClick}>
            <div className="policy-item-actions">
                {!!onCopy &&
                    <IconWithTooltip
                        name={ICON_NAMES.DUPLICATE}
                        onClick={onCopy}
                        tooltipId={copyTooltipId}
                        tooltipText="Duplicate rule"
                        disabled={isReadOnly}
                    />
                }
                <IconWithTooltip
                    name={isReadOnly ? ICON_NAMES.EYE : ICON_NAMES.EDIT}
                    onClick={onEdit}
                    tooltipId={editTooltipId}
                    tooltipText={`${isReadOnly ? "View" : "Edit"} rule`}
                    disabled={disableViewRule}
                />
                {inGroup &&
                    <IconWithTooltip
                        name={ICON_NAMES.EJECT}
                        onClick={onRemoveFromGroup}
                        tooltipId={ejectTooltipId}
                        tooltipText="Remove rule from group"
                        disabled={isReadOnly}
                    />
                }
                {withActions && !isReadOnly &&
                    <ActionsMenu {...{enabled, onToggleStatus, onDelete, inGroup, onRemoveFromGroup, allowDelete}} />}
            </div>
            <div className="item-title-wrapper">
                <RoundIconContainer
                    name={iconName}
                    large
                    className={classnames(
                        "policy-icon-container",
                        {active: enabled || !withActions},
                        {disabled},
                        {blocked},
                        {allowed},
                        {encrypted},
                        {ignored},
                        {injection}
                    )}
                />
                <div className="policy-item-title">{name}</div>
                {withPortshiftTag && <div className="implicit-rule-tag"><Icon name={ICON_NAMES.STAR_FULL} /><div>Implicit Rule</div></div>}
            </div>
            <div className={classnames("content-container", {disabled})}>
                {ruleOrigin && <RuleIcon ruleOrigin={ruleOrigin} id={id} />}
                <div className="rule-description-wrapper">
                    <ContentRenderer {...props} />
                </div>
            </div>
        </div>
    );
}

export default BaseRuleItem;