import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import 'react-perfect-scrollbar/dist/css/styles.css';

const Scrollbar = ({children, ...props}) => (
    <PerfectScrollbar {...props}>
        {children}
    </PerfectScrollbar>
);

export default Scrollbar;