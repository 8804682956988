import { isEmpty } from 'lodash';

export const getNoSourceIpEnvAlert = (selectedEnvs, allEnvs) => {
    const selectedWithNoSourceIp = selectedEnvs.find(selectedEnv => {
        const noSourceIpEnv = allEnvs.find(env => (
            env.name === selectedEnv &&
            !isEmpty(env.kubernetesEnvironments) &&
            !!env.kubernetesEnvironments.find(kubEnv => !kubEnv.preserveOriginalSourceIp)
            
        ));

        return !!noSourceIpEnv;
    });
    
    return !selectedWithNoSourceIp ? null :
        `Environment '${selectedWithNoSourceIp}' contains one or more Kubernetes clusters, set not to preserve the original source IP. Rules which source is IP address / External are ignored for these clusters`;
}

export const ENDPOINT_TYPE_FIELD_NAME = "connectionRulePartType";