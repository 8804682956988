import React from 'react';
import { isEmpty, pickBy } from 'lodash';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { useAuthState } from 'context/AuthProvider';
import { valueToValueLabel } from 'utils/apiUtils';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { useMountMultiFetch } from 'hooks';
import Loader from 'components/Loader';
import Wizard from 'components/Wizard';
import { POLICY_TYPE, ENDPOINT_TYPE_ITEMS } from '../utils';
import { ENVIRONMENT_TYPES_ITEMS, EXPANSION_TYPE_ITEMS, IP_TYPES_ITEMS, POD_TYPES_ITEMS } from '../RuleSubForms';
import FormSource from './FormSource';
import FormDestination from './FormDestination';
import FormLayer7, { L7_PROTOCOLS, EMPTY_KEY_VALUES_ITEM, getApiMethodFieldValue } from './FormLayer7';
import FormRuleProperties from './FormRuleProperties';
import { MASTER_TYPE_FIELD_NAME, EMPTY_ENDPOINT_DATA } from './FormEndpointBase';

export {
    L7_PROTOCOLS
}

const updateMasterTypeFromRulePartType = (userRuleEndpoint, ipWithDomain) => {
    const {connectionRulePartType} = userRuleEndpoint;

    userRuleEndpoint[MASTER_TYPE_FIELD_NAME] = connectionRulePartType;

    const podTypeItem = Object.values(POD_TYPES_ITEMS).find(item => item.value === connectionRulePartType);
    if (!!podTypeItem) {
        userRuleEndpoint[MASTER_TYPE_FIELD_NAME] = ENDPOINT_TYPE_ITEMS.POD.value;

        return;
    }

    const environmentTypeItem = Object.values(ENVIRONMENT_TYPES_ITEMS).find(item => item.value === connectionRulePartType);
    if (!!environmentTypeItem) {
        userRuleEndpoint[MASTER_TYPE_FIELD_NAME] = ENDPOINT_TYPE_ITEMS.ENVIRONMENT.value;
        
        return;
    }

    const expansionTypeItem = Object.values(EXPANSION_TYPE_ITEMS).find(item => item.value === connectionRulePartType);
    if (!!expansionTypeItem) {
        userRuleEndpoint[MASTER_TYPE_FIELD_NAME] = ENDPOINT_TYPE_ITEMS.EXPANSION.value;
        
        return;
    }

    if (!ipWithDomain) {
        return;
    }

    const ipTypeItem = Object.values(IP_TYPES_ITEMS).find(item => item.value === connectionRulePartType);
    if (!!ipTypeItem) {
        userRuleEndpoint[MASTER_TYPE_FIELD_NAME] = ENDPOINT_TYPE_ITEMS.IP_RANGE.value;
        
        return;
    }
}

const getUpdatedRulePartForSubmit = (userRuleEndpoint) => {
    if (!userRuleEndpoint.connectionRulePartType) {
        userRuleEndpoint.connectionRulePartType = userRuleEndpoint[MASTER_TYPE_FIELD_NAME];
    }
    
    delete userRuleEndpoint[MASTER_TYPE_FIELD_NAME]; //remove display only field

    userRuleEndpoint.names = userRuleEndpoint.names.map(item => item.value); //format AsyncSelectField value from [{value, label}] to [value]
    userRuleEndpoint.labels = userRuleEndpoint.labels.filter(item => item.key !== "" && item.value !== ""); //clean empty labels
    userRuleEndpoint.networks = userRuleEndpoint.networks.filter(item => item !== ""); //clean empty networks
    userRuleEndpoint.fqdnAddresses = userRuleEndpoint.fqdnAddresses.filter(item => item !== ""); //clean empty fqdnAddresses
    
    userRuleEndpoint = pickBy(userRuleEndpoint,
        (value) => value !== "" && !(Array.isArray(value) && (isEmpty(value) || (value.length === 1 && value[0] === ""))));
    
    return userRuleEndpoint;
}

const UserRuleForm = ({initialData, onDirtyChanage, onDone}) => {
    const {isReadOnlyUser} = useAuthState();
    const {showApiSecurity} = useDisplayConfigState();

    const [{loading, data, error}] = useMountMultiFetch([
        {key: "environments", url: "environments"},
        {key: "clusters", url: "leanKubernetesClusters"},
        {key: "actions", url: "connectionsPolicy/kafka/actions"},
        ...(!showApiSecurity ? [] : [{key: "apiSecurityPolicy", url: "apiSecurityPolicy"}])
    ]);

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const isEditForm = !!initialData && initialData.id;
    
    const {environments, clusters, actions, apiSecurityPolicy} = data || {};
    const environmentNames = !environments ? [] :
        valueToValueLabel([...new Set(environments.map(item => item.name))]);
    const clusterNames = !clusters ? [] : clusters.map(item => ({value: item.id, label: item.name}));
    const actionNames = !actions ? [] : valueToValueLabel(actions);
    const apiSecurityPolicyNames = !apiSecurityPolicy ? [] : apiSecurityPolicy.map(({name}) => ({value: name, label: name}));
    
    if (initialData && initialData.source) {
        updateMasterTypeFromRulePartType(initialData.source);
    }

    if (initialData && initialData.destination) {
        updateMasterTypeFromRulePartType(initialData.destination, true);
    }

    const initialValues = {
        name: "",
        action: RULE_ACTIONS.ALLOW,
        ...(initialData || {})
    };

    initialValues.source = {
        [MASTER_TYPE_FIELD_NAME]: ENDPOINT_TYPE_ITEMS.POD.value,
        connectionRulePartType: POD_TYPES_ITEMS.NAME.value,
        ...EMPTY_ENDPOINT_DATA,
        ...initialValues.source
    }

    initialValues.destination = {
        [MASTER_TYPE_FIELD_NAME]: ENDPOINT_TYPE_ITEMS.POD.value,
        connectionRulePartType: POD_TYPES_ITEMS.NAME.value,
        ...EMPTY_ENDPOINT_DATA,
        ...initialValues.destination
    }

    initialValues.layer7Settings = {
        layer7Protocol: L7_PROTOCOLS.ANY,
        topics: [],
        actions: [],
        methods: [],
        paths: [],
        headers: [EMPTY_KEY_VALUES_ITEM],
        queryParameters: [EMPTY_KEY_VALUES_ITEM],
        isIntercept: false,
        tags: [],
        ...(initialValues.layer7Settings || {})
    }
    initialValues.layer7Settings.topics = initialValues.layer7Settings.topics || []; //should not be null
    initialValues.layer7Settings.actions = initialValues.layer7Settings.actions || []; //should not be null
    initialValues.layer7Settings.methods = initialValues.layer7Settings.methods || []; //should not be null
    initialValues.layer7Settings.paths = initialValues.layer7Settings.paths || []; //should not be null
    initialValues.layer7Settings.headers = isEmpty(initialValues.layer7Settings.headers) ? [EMPTY_KEY_VALUES_ITEM] : initialValues.layer7Settings.headers;
    initialValues.layer7Settings.queryParameters = isEmpty(initialValues.layer7Settings.queryParameters) ? [EMPTY_KEY_VALUES_ITEM] : initialValues.layer7Settings.queryParameters;
    
    if (initialValues.layer7Settings.layer7Protocol === L7_PROTOCOLS.SPEC) {
        initialValues.layer7Settings.tags = [...new Set(initialValues.layer7Settings.methods.map(({tag}) => tag))];
        initialValues.layer7Settings.methods =
            initialValues.layer7Settings.methods.map(methodData => ({...methodData, value: getApiMethodFieldValue(methodData)}));
    }

    if (!isEmpty(initialValues.source.names)) {
        initialValues.source.names = valueToValueLabel(initialValues.source.names); //AsyncSelectField needs [{value,label}] initital value
    }
    if (!isEmpty(initialValues.destination.names)) {
        initialValues.destination.names = valueToValueLabel(initialValues.destination.names); //AsyncSelectField needs [{value,label}] initital value
    }

    initialValues.source.vulnerabilitySeverityLevel = initialValues.source.vulnerabilitySeverityLevel || ""; //should not be null
    initialValues.destination.vulnerabilitySeverityLevel = initialValues.destination.vulnerabilitySeverityLevel || ""; //should not be null

    const apiSecurityPolicyNamesList = apiSecurityPolicyNames.map(item => item.value);
    initialValues.source.apiSecurityProfile = apiSecurityPolicyNamesList.includes(initialValues.source.apiSecurityProfile) ? initialValues.source.apiSecurityProfile : ""; //clean deleted profiles
    initialValues.destination.apiSecurityProfile = apiSecurityPolicyNamesList.includes(initialValues.destination.apiSecurityProfile) ? initialValues.destination.apiSecurityProfile : ""; //clean deleted profiles
    
    const ruleTitle = `${POLICY_TYPE} Rule`;

    return (
        <Wizard
            className="connection-policy-user-rule-form"
            initialValues={initialValues}
            title={isReadOnlyUser ? `${ruleTitle} (Audit mode)` : `${isEditForm ? "Edit" : "New"} ${ruleTitle}`}
            disableSubmit={isReadOnlyUser}
            disableStepChangeValidation={isReadOnlyUser}
            steps={[
                {
                    title: "Rule Properties",
                    component: FormRuleProperties
                },
                {
                    title: "Source",
                    component: FormSource
                },
                {
                    title: "Destination",
                    component: FormDestination
                },
                {
                    title: "Layer 7 Protocol (Optional)",
                    component: FormLayer7
                }
            ]}
            formProps={{
                environmentNames,
                environments,
                clusterNames,
                actionNames,
                apiSecurityPolicyNames,
                isReadOnlyUser
            }}
            doCustomSubmit={formValues => {
                formValues.source = getUpdatedRulePartForSubmit(formValues.source);
                formValues.destination = getUpdatedRulePartForSubmit(formValues.destination);
                
                const {layer7Settings} = formValues;
                if (layer7Settings.layer7Protocol === L7_PROTOCOLS.ANY) {
                    formValues.layer7Settings = null;
                } else {
                    formValues.layer7Settings = pickBy(layer7Settings, (value) => value !== "" && !(Array.isArray(value) && isEmpty(value)));
                    formValues.layer7Settings.headers = formValues.layer7Settings.headers.filter(({key, values}) => key !== "" && !isEmpty(values));
                    formValues.layer7Settings.queryParameters = formValues.layer7Settings.queryParameters.filter(({key, values}) => key !== "" && !isEmpty(values));
                    
                    delete formValues.layer7Settings.tags;

                    if (layer7Settings.layer7Protocol === L7_PROTOCOLS.SPEC) {
                        formValues.layer7Settings.methods = formValues.layer7Settings.methods.map(({method, path, tag}) => ({method, path, tag}));
                    }

                    if (![L7_PROTOCOLS.HTTP, L7_PROTOCOLS.SPEC].includes(layer7Settings.layer7Protocol)) {
                        delete formValues.layer7Protocol.isIntercept;
                    }
                }
                
                onDone(formValues);
            }}
            onDirtyChanage={onDirtyChanage}
        />
    );
};

export default UserRuleForm;