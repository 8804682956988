import React from 'react';
import InstructionsItem from 'components/InstructionsItem';
import Text, { TEXT_TYPES } from 'components/Text';
import { PRODUCTION_ADDRESS, APPLICATION_NAME } from 'utils/systemConsts';

const InstallData = ({id}) => (
    <div className="install-data-container">
        <Text className="install-title" type={TEXT_TYPES.TITLE_MEDIUM} withTopMargin>Install</Text>
        <InstructionsItem
            title={`Install ${APPLICATION_NAME} mesh expansion bundle:`}
        />
        <InstructionsItem
            title={<span>Download the package <a href={`${window.location.origin}/api/expansions/${id}/install_expansion.tar.gz`} download>here</a></span>}
            isInnerTitle
        />
        <InstructionsItem
            title="Extract the package:"
            inputText="tar -xzvf portshift_expansion.tar.gz"
            isInnerTitle
        />
        <InstructionsItem
            className="run-estracted"
            title="Run the extracted installation script:"
            inputText='./install_expansion.sh [[-u vm_ssh_username] -d vm_destination_address [-o ssh opts e.g: "-i path_to_key"] [-c certs-path] | [-h]]'
            isInnerTitle
        />
        <InstructionsItem
            title={`Enable network communication from the expansion machine to ${APPLICATION_NAME}`}
        />
        <InstructionsItem
            title={`Management console: ${PRODUCTION_ADDRESS.DOMAIN} (${PRODUCTION_ADDRESS.IP})`}
            isInnerTitle
        />
    </div>
);

export default InstallData;
