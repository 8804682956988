import React, { useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { debounce, isEqual } from 'lodash';
import classnames from 'classnames';
import { usePrevious } from 'hooks';

import './filter-form.scss';

const FormComponent = ({children, className, onChange}) => {
    const {values} = useFormikContext();
	const prevValues = usePrevious(values);

	useEffect(() => {
		if (!!onChange && !isEqual(values, prevValues)) {
			debounce(onChange, 500)(values);
		}
	}, [values, prevValues, onChange]);

	return (
		<Form className={classnames("ps-form", "table-filters-form", {[className]: className})}>
			{children}
		</Form>
	)
}

const FormWrapper = ({children, initialValues, ...props}) => {
	return (
		<Formik initialValues={initialValues}>
			<FormComponent {...props}>
				{children}
			</FormComponent>
		</Formik>
	)
}

export default FormWrapper;