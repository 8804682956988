import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isNull } from 'lodash';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import { TextField, SelectField } from 'components/Form';
import Table, { FilterFormWrapper } from 'components/Table';
import IconWithTitle from 'components/IconWithTitle';
import IconWithTooltip from 'components/IconWithTooltip';
import FormModal from 'components/FormModal';
import Icon, { ICON_NAMES } from 'components/Icon';
import { DEPLOYER_TYPE_ITEMS, isOperatorDeployer } from './utils';
import DeployerForm from './DeployerForm';
import DeleteDeployerModal from './DeleteDeployerModal';

import './deployers.scss';

export const DEPLOYMNET_DEPLOYERS_PAGE_PATH = "/deployments/deployers";

const StatusIcon = ({ isSuccess }) =>
    <Icon name={isSuccess ? ICON_NAMES.SUCCESS : ICON_NAMES.ERROR} className={classnames("deployer-status-icon", { success: isSuccess })} />;

const STATUS_FUIELD_WIDTH = 60;

const STATUS_FILTER_ITEMS = [
    { value: true, label: "Enabled" },
    { value: false, label: "Disabled" }
]

const Deployers = () => {
    const columns = useMemo(() => [
        {
            Header: "Deployer",
            id: "deployer",
            accessor: "deployer",
            disableSortBy: true
        },
        {
            Header: "Deployer Type",
            id: "deployerType",
            accessor: original => {
                const deployerItem = DEPLOYER_TYPE_ITEMS[original.deployerType];

                return !!deployerItem ? deployerItem.label : null;
            },
            disableSortBy: true
        },
        {
            Header: "Namespace",
            id: "namespace",
            accessor: "namespace",
            disableSortBy: true
        },
        {
            Header: "Cluster",
            id: "cluster",
            accessor: "cluster",
            disableSortBy: true
        },
        {
            Header: "Security Checks",
            id: "securityCheck",
            Cell: ({ row }) => isOperatorDeployer(row.original.deployerType) ? <StatusIcon isSuccess={row.original.securityCheck} /> : "",
            disableSortBy: true,
            minWidth: STATUS_FUIELD_WIDTH,
            width: STATUS_FUIELD_WIDTH,
            maxWidth: STATUS_FUIELD_WIDTH
        }
    ], []);

    const { isReadOnlyUser } = useAuthState();

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = () => setRefreshTimestamp(Date());

    const [selectedItemData, setSelectedItemData] = useState(null);
    const closeEditModal = () => setSelectedItemData(null);

    const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationData(null);

    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);

    const [filters, setFilters] = useState({
        name: urlSearchParams.get("name") || "",
        securityCheck: "",
        ruleCreation: ""
    });

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    return (
        <div className="deployments-deployers-page">
            <div className="table-header-container">
                <IconWithTitle
                    name={ICON_NAMES.ADD}
                    title="New Deployer"
                    className="add-new-button"
                    onClick={() => setSelectedItemData({})}
                    disabled={isReadOnlyUser}
                />
                <FilterFormWrapper
                    initialValues={{ ...filters }}
                    onChange={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                >
                    <TextField name="name" label="Deployer" />
                    <SelectField name="securityCheck" label="Security checks" items={STATUS_FILTER_ITEMS} small />
                    <SelectField name="ruleCreation" label="Rules creation" items={STATUS_FILTER_ITEMS} small />
                </FilterFormWrapper>
            </div>
            <Table
                url="deployers"
                name="deployerts"
                columns={columns}
                actionsComponent={({ original }) => {
                    const { id } = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;

                    return (
                        <div className="deployers-row-actions">
                            <IconWithTooltip
                                name={ICON_NAMES.EDIT}
                                onClick={() => setSelectedItemData(original)}
                                tooltipId={editTooltipId}
                                tooltipText="Edit deployer"
                                disabled={isReadOnlyUser}
                            />
                            <IconWithTooltip
                                name={ICON_NAMES.DELETE}
                                onClick={() => setDeleteConfirmationData(original)}
                                tooltipId={deleteTooltipId}
                                tooltipText="Delete deployer"
                                disabled={isReadOnlyUser}
                            />
                        </div>
                    );
                }}
                actionsCellWidth={80}
                refreshTimestamp={refreshTimestamp}
                filters={filters}
            />
            {!isNull(selectedItemData) &&
                <FormModal
                    onClose={closeEditModal}
                    formComponent={DeployerForm}
                    formProps={{
                        initialData: { ...selectedItemData },
                        onFormSubmitSuccess: () => {
                            closeEditModal();
                            refreshTableData();
                        }
                    }}
                />
            }
            {!isNull(deleteConfirmationData) &&
                <DeleteDeployerModal onClose={closeDeleteConfirmation} onDeleteSuccess={refreshTableData} data={deleteConfirmationData} />
            }
        </div>
    )
}

export default Deployers;