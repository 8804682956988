import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import Icon, { ICON_NAMES } from "components/Icon";
import { useFetch, usePrevious } from "hooks";
import COLORS from 'utils/scss_variables.module.scss';

const BenchmarkSummery = ({refreshTimestamp}) => {
  const [displayData, setDisplayData] = useState({});
  const [{ loading, data }, fetchData] = useFetch(
    `k8sCISBenchmark/summary`
  );
  const prevLoading = usePrevious(loading);

  const {compliance=0, numberOfClusters=0, numberOfScannedClusters=0, numberOfClustersWithActionItems=0} = displayData || {};
  const displayItems = [
    {
      title: "Scanned clusters \n compliance",
      icon: ICON_NAMES.CIS_COMPLIANCE,
      count: `${compliance}%`,
    },
    {
      title: `out of ${numberOfClusters} \n clusters scanned`,
      icon: ICON_NAMES.CIS_SCANNED,
      count: numberOfScannedClusters,
      showChart: true
    },
    {
      title: "Clusters with  action items",
      icon: ICON_NAMES.CIS_ACTION,
      count: numberOfClustersWithActionItems,
    },
  ];

  useEffect(() => {
    if (prevLoading && !loading) {
      setDisplayData(data);
    }
  }, [loading, prevLoading, data, setDisplayData]);

  useEffect(() => {
    fetchData();
  }, [refreshTimestamp, fetchData]);

  return (
    <div className="charts-container">
      {
        displayItems.map(({ title, icon, count, showChart = false }) => {
          const chartData = [
            { name: "Scanned", value: numberOfScannedClusters, color: COLORS["color-white"] },
            {
              name: "Not Scanned",
              value: numberOfClusters - numberOfScannedClusters,
              color: COLORS["color-success"],
            },
          ];

          return (
            <div key={title} className="item-container">
              <Icon name={icon} />
              <div className="item-summary">
                <div className="total-count">
                  {count}
                </div>
                <div className="item-title">{title}</div>
              </div>
              {showChart && (
                <div className="protected-chart-container">
                  <PieChart width={50} height={50}>
                    <Pie
                      stroke="none"
                      data={chartData}
                      dataKey="value"
                      innerRadius={18}
                      outerRadius={25}
                    >
                      {chartData.map(({ name, color }) => (
                        <Cell key={name} fill={color} />
                      ))}
                    </Pie>
                  </PieChart>
                  <div className="legend-container">
                    {chartData.map(({ name, value, color }) => (
                      <div key={name} className="legent-item" style={{ color }}>
                        <div
                          className="item-mark"
                          style={{ backgroundColor: color }}
                        ></div>
                        <div className="item-count">{value}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })
      }
    </div>
  );
};

export default BenchmarkSummery;
