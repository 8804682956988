import React, { useState, useRef } from 'react';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import Modal from 'components/Modal';
import Text, { TEXT_TYPES } from 'components/Text';
import Button from 'components/Button';
import IconWithTitle from 'components/IconWithTitle';
import Icon, { ICON_NAMES } from 'components/Icon';
import { getProtectedLabel, checkIsProtected } from './utils';

const ProtectModal = ({name, protectionStatus, onClose}) => {
    const {showAccountConnections} = useDisplayConfigState();
    const isProtected = checkIsProtected(protectionStatus);
    
    const commandRef = useRef();
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipBoard = event => {
        event.preventDefault();
        event.stopPropagation();
        
        commandRef.current.select();
        document.execCommand('copy');
        setIsCopied(true);
    }

    return (
        <Modal className="namespace-protect-modal" center={true} onClose={onClose}>
            <Text type={TEXT_TYPES.TITLE_MEDIUM}>Protection Info</Text>
            <div className="protect-header">
                <div>{`${isProtected ? "Unprotect" : "Protect"} namespace:`}</div>
                <IconWithTitle name={ICON_NAMES.COPY} title="Copy" onClick={copyToClipBoard} />
            </div>
            <input
                className="command-input"
                type="text"
                ref={commandRef}
                onChange={() => undefined}
                value={`kubectl label namespace ${name} ${getProtectedLabel({protect: !isProtected, connectionsEnabled: showAccountConnections})} --overwrite`}
            />
            <div className="protect-message-container">
                <Icon name={ICON_NAMES.EXCLAMATION_MARK_ROUND} />
                <div className="message">Run the command and restart the pod for the changes to be applied</div>
            </div>
            <div className="action-button-wrapper">
                <Button disabled={!isCopied} onClick={onClose}>Finish</Button>
            </div>
        </Modal>
    )
}

export default ProtectModal;