import React from 'react';
import { pickBy, isEmpty } from 'lodash';
import { useMountMultiFetch } from 'hooks';
import { useAuthState } from 'context/AuthProvider';
import Loader from 'components/Loader';
import Wizard from 'components/Wizard';
import { valueToValueLabel } from 'utils/apiUtils';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { ENVIRONMENT_TYPES_ITEMS } from '../RuleSubForms';
import { ENDPOINT_TYPE_ITEMS } from '../utils';
import FormSource from './FormSource';
import FormDestination, { EMPTY_DESTINATION_DATA } from './FormDestination';
import FormRuleProperties from './FormRuleProperties';
import { MASTER_TYPE_FIELD_NAME } from './utils';

const updateMasterTypeFromRulePartType = (userRuleEndpoint) => {
    const {connectionRulePartType} = userRuleEndpoint;

    userRuleEndpoint[MASTER_TYPE_FIELD_NAME] = connectionRulePartType;

    const environmentTypeItem = Object.values(ENVIRONMENT_TYPES_ITEMS).find(item => item.value === connectionRulePartType);
    if (!!environmentTypeItem) {
        userRuleEndpoint[MASTER_TYPE_FIELD_NAME] = ENDPOINT_TYPE_ITEMS.ENVIRONMENT.value;
        
        return;
    }
}

const getUpdatedRulePartForSubmit = (userRuleEndpoint) => {
    if (!userRuleEndpoint.connectionRulePartType) {
        userRuleEndpoint.connectionRulePartType = userRuleEndpoint[MASTER_TYPE_FIELD_NAME];
    }
    
    delete userRuleEndpoint[MASTER_TYPE_FIELD_NAME]; //remove display only field

    userRuleEndpoint = pickBy(userRuleEndpoint,
        (value) => value !== "" && !(Array.isArray(value) && (isEmpty(value) || (value.length === 1 && value[0] === ""))));

    return userRuleEndpoint;
}

const EncryptRuleForm = ({initialData, onDirtyChanage, onDone}) => {
    const {isReadOnlyUser} = useAuthState();
    
    const [{loading, data, error}] = useMountMultiFetch([
        {key: "environments", url: "environments"},
        {key: "clusters", url: "leanKubernetesClusters"},
    ]);

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const isEditForm = !!initialData && initialData.id;
    
    const {environments, clusters} = data || {};
    const environmentNames = !environments ? [] :
        valueToValueLabel([...new Set(environments.map(item => item.name))]);
    const clusterNames = !clusters ? [] : clusters.map(item => ({value: item.id, label: item.name}));
    
    if (initialData && initialData.source) {
        updateMasterTypeFromRulePartType(initialData.source);
    }

    if (initialData && initialData.destination) {
        updateMasterTypeFromRulePartType(initialData.destination);
    }

    const initialValues = {
        name: "",
        action: RULE_ACTIONS.ENCRYPT,
        [RULE_ACTIONS.ENCRYPT_DIRECT]: false,
        ...(initialData || {})
    };

    if (initialValues.action === RULE_ACTIONS.ENCRYPT_DIRECT) {
        initialValues.action = RULE_ACTIONS.ENCRYPT;
        initialValues[RULE_ACTIONS.ENCRYPT_DIRECT] = true;
    }

    initialValues.source = {
        [MASTER_TYPE_FIELD_NAME]: ENDPOINT_TYPE_ITEMS.ENVIRONMENT.value,
        connectionRulePartType: ENVIRONMENT_TYPES_ITEMS.NAME.value,
        environments: [],
        risks: [],
        ...initialValues.source
    }

    initialValues.destination = {
        [MASTER_TYPE_FIELD_NAME]: ENDPOINT_TYPE_ITEMS.ENVIRONMENT.value,
        connectionRulePartType: ENVIRONMENT_TYPES_ITEMS.NAME.value,
        ...EMPTY_DESTINATION_DATA,
        ...initialValues.destination
    }

    const ruleTitle = "Encryption Rule";

    return (
        <Wizard
            className="connection-policy-encrypt-rule-form"
            initialValues={initialValues}
            title={isReadOnlyUser ? `${ruleTitle} (Audit mode)` : `${isEditForm ? "Edit" : "New"} ${ruleTitle}`}
            disableSubmit={isReadOnlyUser}
            steps={[
                {
                    title: "Source",
                    component: FormSource
                },
                {
                    title: "Destination",
                    component: FormDestination
                },
                {
                    title: "Rule Properties",
                    component: FormRuleProperties
                }
            ]}
            formProps={{
                environmentNames,
                environments,
                clusterNames,
                isReadOnlyUser
            }}
            doCustomSubmit={formValues => {
                formValues.source = getUpdatedRulePartForSubmit(formValues.source);
                formValues.destination = getUpdatedRulePartForSubmit(formValues.destination);
                
                formValues.action = RULE_ACTIONS.ENCRYPT;
                if (formValues.destination.connectionRulePartType === ENVIRONMENT_TYPES_ITEMS.NAME.value &&
                    formValues[RULE_ACTIONS.ENCRYPT_DIRECT]) {
                    formValues.action = RULE_ACTIONS.ENCRYPT_DIRECT;
                }
                delete formValues[RULE_ACTIONS.ENCRYPT_DIRECT];
                
                onDone(formValues);
            }}
            onDirtyChanage={onDirtyChanage}
        />
    );
};

export default EncryptRuleForm;