import React from 'react';
import ContainerItems from 'components/ContainerItems';
import InstructionsItem from 'components/InstructionsItem';
import { APPLICATION_NAME, DOCUMENTATION_URL } from 'utils/systemConsts';
import { SERVICE_USER_TITLE } from './utils';

const DockerContent = () => (
    <React.Fragment>
        <InstructionsItem
            title="Perquisites: In the scan environment the scanner should be able to run dockers. If you are running the scanner inside a container you will need to mount the docker socket to that container."
        />
        <InstructionsItem title={SERVICE_USER_TITLE}/>
        <InstructionsItem 
            title={<span>{`Download the ${APPLICATION_NAME} CLI `}<a href={`${window.location.origin}/tools/cli/securecn_deployment_cli`} download>here</a>, and verify it has execute permissions</span>}
        />
        <InstructionsItem
            title="To scan your image run the following command:"
            inputText="securecn_deployment_cli run-vulnerability-scan --access-key $ACCESS_KEY --secret-key $SECRET_KEY --image-name=myRegistry/myImage:myTag"
        />
        <InstructionsItem
            title={<span>For advanced usage, visit our <a href={`${DOCUMENTATION_URL}/docs/docker-plugin`} target="_blank" rel="noopener noreferrer">documentation</a>.</span>}
        />
    </React.Fragment>
);

const CodeScannerContent = () => (
    <React.Fragment>
        <InstructionsItem title={SERVICE_USER_TITLE}/>
        <InstructionsItem 
            title={<span>{`Download the ${APPLICATION_NAME} CLI `}<a href={`${window.location.origin}/tools/cli/securecn_deployment_cli`} download>here</a>, and verify it has execute permissions</span>}
        />
        <InstructionsItem
            title="To scan your code run the following command:"
            inputText="securecn_deployment_cli run-code-vulnerability-scan --access-key $ACCESS_KEY --secret-key $SECRET_KEY –code-identifier $CODE_NAME --zip-path-to-scan $PATH_TO_ZIP_FILE"
        />
        <InstructionsItem
            title={<span>For advanced usage, visit our <a href={`${DOCUMENTATION_URL}/docs/code-scanner-plugin`} target="_blank" rel="noopener noreferrer">documentation</a>.</span>}
        />
    </React.Fragment>
);

const JenkinsContent = () => (
    <React.Fragment>
        <InstructionsItem 
            title={<span>{`Download the ${APPLICATION_NAME} Jenkins plugin `}<a href={`${window.location.origin}/tools/plugins/jenkins-plugin`} download>here</a></span>}
        />
        <InstructionsItem 
            title={<span>Follow <a href="https://github.com/portshift/Portshift-Jenkins-CI-Plugin/blob/master/README.md" target="_blank" rel="noopener noreferrer">these</a> instructions</span>}
        />
    </React.Fragment>
);

const CircleCiContent = () => (
    <React.Fragment>
        <InstructionsItem
            className="circle-orb"
            title={`Add the ${APPLICATION_NAME} Orb:`}
            inputText={"orbs:\n\tportshift-scanner: portshift/portshift-scanner@2.1.1"}
        />
        <InstructionsItem 
            title={<span>Follow the instructions from <a href="https://circleci.com/orbs/registry/orb/portshift/portshift-scanner" target="_blank" rel="noopener noreferrer">here</a></span>}
        />
        <InstructionsItem 
            title={<span>Also available on <a href="https://github.com/Portshift/CircleCI-Portshift-ORB" target="_blank" rel="noopener noreferrer">GitHub</a></span>}
        />
    </React.Fragment>
);

const CiPlugins = () => {
    const items = [
        {id: "docker", title: "DOCKER", componenet: DockerContent},
        {id: "codeScanner", title: "Code scanner", componenet: CodeScannerContent},
        {id: "jenkins", title: "JENKINS", componenet: JenkinsContent},
        {id: "circleCi", title: "CircleCi", componenet: CircleCiContent}
    ];

    return (
        <ContainerItems className="ci-plugins-container" title="CI plugins" items={items} />
    );
}

export default CiPlugins;