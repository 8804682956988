import React, { useEffect } from 'react';
import { get } from 'lodash';
import { usePrevious } from 'hooks';
import { validators, RadioField, useFormikContext, MultiselectField, utils } from 'components/Form';
import { getNoSourceIpEnvAlert, ENDPOINT_TYPE_FIELD_NAME } from './utils';

export const ENVIRONMENT_TYPES_ITEMS = {
    NAME: {
        value: "EnvironmentNameConnectionRulePart",
        label: "By Name"
    },
    ANY: {
        value: "EnvironmentAnyConnectionRulePart",
        label: "Any",
        tooltip: "Any protected namespace"
    }
}

const EnvironmentForm = ({endpoint, displayEnvAlert, environments, environmentNames, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {destination} = values;

    const typeFieldName = `${endpoint}.${ENDPOINT_TYPE_FIELD_NAME}`;
    const typeFieldValue = get(values, typeFieldName);
    const prevTypeFieldValue = usePrevious(typeFieldValue);

    let envAlert = null;
    if (displayEnvAlert) {
        const selectedEnvs = (typeFieldValue === ENVIRONMENT_TYPES_ITEMS.ANY.value) ?
            environments.map(item => item.name) : destination.environments;
        envAlert = getNoSourceIpEnvAlert(selectedEnvs || [], environments);
    }

    useEffect(() => {
        if (typeFieldValue === "") {
            setFieldValue(typeFieldName, ENVIRONMENT_TYPES_ITEMS.NAME.value);
        }

        if (prevTypeFieldValue === typeFieldValue || !prevTypeFieldValue) {
            return;
        }

        setFieldValue(`${endpoint}.environments`, []);
    }, [typeFieldValue, prevTypeFieldValue, typeFieldName, setFieldValue, endpoint]);

    return (
        <React.Fragment>
            <RadioField
                name={typeFieldName}
                horizontal={true}
                items={Object.values(ENVIRONMENT_TYPES_ITEMS)}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {typeFieldValue === ENVIRONMENT_TYPES_ITEMS.NAME.value &&
                <MultiselectField
                    name={`${endpoint}.environments`}
                    label="Names"
                    items={environmentNames}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />}
            {displayEnvAlert && !!envAlert && <utils.FieldAlert message={envAlert} />}
        </React.Fragment>
    );
}

export default EnvironmentForm;