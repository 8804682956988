import React from 'react';
import FormWrapper, { TextField, validators, ListField } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';

//The Image form only supports creating new images (no edit)

const ImageForm = ({onFormSubmitSuccess, onDirtyChanage}) => {
    const initialValues = {
        imageName: "",
        imageHash: "",
        imageTags: [""]
    };
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>New Image</Text>
            <FormWrapper
                className="image-form-container"
                initialValues={initialValues}
                submitUrl="images"
                getSubmitParams={formValues => {
                    return {submitData: formValues}
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <TextField name="imageName" label="Image Name" validate={value => {
                    const requiredError = validators.validateRequired(value);
                    if (!!requiredError) {
                        return requiredError;
                    }

                    const hasInvalidSubstring = value.includes("@sha") || value.includes(":");

                    return hasInvalidSubstring ? "Image name must not include: '@sha' or ':'" : null;
                }} />
                <TextField name="imageHash" label="Image ID" validate={validators.validateRequired} />
                <ListField
                    name="imageTags"
                    label="Image Tags"
                    fieldComponent={TextField}
                    fieldProps={{
                        validate: validators.validateRequired
                    }}
                />
            </FormWrapper>
        </div>
    )
}

export default ImageForm;