import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Button from 'components/Button';
import Loader from 'components/Loader';
import SearchPolicyField from '../SearchPolicyField';
import BaseGroupedRulesList from './BaseGroupedRulesList';

import './base-grouped-policy.scss';

export {
    BaseGroupedRulesList
};

const BaseGroupedPolicy = props => {
    const {formButtonsItems, selectedSearch, onSelectSearch, isSearching, isReadOnly, userRules,
        onRevertPolicy, onPolicySave, withUnsavedEdits, historyButtonComponent: HistoryButtonComponent} = props;
    
    const [namesOfCloseGroups, setNamesOfCloseGroups] = useState([]);
    const onRuleAdd = formButtonsItems.find(item => item.id === "add").onClick;

    return (
        <div className="base-grouped-policy-wrapper">
            <div className="base-grouped-policy">
                <div className="policies-header">
                    <div className="form-buttons-container">
                        {
                            formButtonsItems.map(({id, icon, title, onClick}) =>
                                <IconWithTitle
                                    key={id}
                                    name={icon}
                                    title={title}
                                    onClick={onClick}
                                    disabled={isReadOnly}
                                />)
                        }
                        
                    </div>
                    {!!onSelectSearch &&
                        <SearchPolicyField
                            disabled={isSearching}
                            selectedSearch={selectedSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    }
                    <div className="history-container">
                        <HistoryButtonComponent />
                    </div>
                    <div className="expand-collapse-wrapper">
                        <IconWithTitle
                            name={ICON_NAMES.COLLAPSE_ALL}
                            title="Collapse all"
                            onClick={() =>
                                setNamesOfCloseGroups(userRules.filter(item => item.subItems.length > 1)
                                    .map(item => item.groupName))}
                        />
                        <IconWithTitle name={ICON_NAMES.EXPAND_ALL} title="Expand all" onClick={() => setNamesOfCloseGroups([])} />
                    </div>
                </div>
                <div className="policies-content">
                    {isSearching &&
                        <div className="policies-content-overlay">
                            <Loader />
                        </div>
                    }
                    <BaseGroupedRulesList
                        {...props}
                        namesOfCloseGroups={namesOfCloseGroups}
                        setNamesOfCloseGroups={setNamesOfCloseGroups}
                        onRuleAdd={onRuleAdd}
                    />
                </div>
            </div>
            {(!isReadOnly || withUnsavedEdits) &&
                <div className="policy-buttons">
                    <Button onClick={onRevertPolicy} disabled={!withUnsavedEdits}>
                        <IconWithTitle name={ICON_NAMES.REVERT} title="Revert Changes" disabled={!withUnsavedEdits} />
                    </Button>
                    <Button onClick={onPolicySave} disabled={!withUnsavedEdits}>
                        <IconWithTitle name={ICON_NAMES.SUCCESS} title="Apply Policy" disabled={!withUnsavedEdits} />
                    </Button>
                </div>
            }
            <Prompt
                when={withUnsavedEdits}
                message="You made changes to policy. If you will exit without applying these changes, you will lose them."
            />
        </div>
        
    );
};

export default BaseGroupedPolicy;