import React from 'react';
import { Link } from 'react-router-dom';
import { TOKENS_PAGE_PATH } from 'layout/Apis/Tokens';
import { APP_PATHS } from 'utils/systemConsts';

const RUNTIME_PATH = APP_PATHS.RUNTIME.path;
export const RUNTIME_WORKLOADS_PAGE_PATH = `${RUNTIME_PATH}/workloads`;
export const RUNTIME_CONNECTIONS_PAGE_PATH = `${RUNTIME_PATH}/connections`;
export const RUNTIME_EVENTS_PAGE_PATH = `${RUNTIME_PATH}/events`;

export const TokenNameLink = ({name, isDeleted}) => {
    if (isDeleted) {
        return (<div>{`Deleted: ${name}`}</div>);
    }

    return (
        <Link to={{pathname: TOKENS_PAGE_PATH, query: {filters: {tokenName: name}}}} >
            {name}
        </Link>
    );
}