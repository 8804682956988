import React from 'react';
import InstructionsItem from 'components/InstructionsItem';
import Text, { TEXT_TYPES } from 'components/Text';
import { APPLICATION_NAME } from 'utils/systemConsts';

export const UninstallInstructions = ({isInnerTitle, isYaml}) => (
    <InstructionsItem
        title={`Use the following command to uninstall ${APPLICATION_NAME} from your cluster:`}
        inputText={isYaml ? "kubectl get cm -n portshift portshift-uninstaller -o jsonpath='{.data.config}' | bash" : "./install_bundle.sh --uninstall"}
        isInnerTitle={isInnerTitle}
        withBullet={!isInnerTitle}
    />
)

const UninstallData = ({isYaml}) => (
    <div className="uninstall-data-container">
        <Text type={TEXT_TYPES.TITLE_MEDIUM} className="uninstall-title" withTopMargin>Uninstall</Text>
        <UninstallInstructions isYaml={isYaml} />
    </div>
);

export default UninstallData;


