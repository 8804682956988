import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { isNull, isEmpty } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { useDelete, useFetch } from 'hooks';
import Table, { tableUtils } from 'components/Table';
import IconWithTitle from 'components/IconWithTitle';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';
import FormModal from 'components/FormModal';
import Loader from 'components/Loader';
import ModalConfirmation from 'components/ModalConfirmation';
import { formatDate } from 'utils/generalUtils';
import InnerTable from './InnerTable';
import FiltersForm, { POD_SOURCE_ITEMS } from './FiltersForm';
import PodForm from './PodForm';

import './pod-templates.scss';

export const DEPLOYMNET_POD_TEMPLATES_PAGE_PATH = "/deployments/podtemplates";

const PodTemplates = ({clusters}) => {
    const clustersJSON = JSON.stringify(clusters);

    const columns = useMemo(() => [
        {
            Header: "Pod Template",
            id: "name",
            accessor: "name",
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Deployment Type",
            id: "deploymentType",
            accessor: "kind",
            disableSortBy: true
        },
        {
            Header: "Source",
            id: "templateSource",
            accessor: original => POD_SOURCE_ITEMS[original.podDefinitionSource].label,
            disableSortBy: true
        },
        {
            Header: "Cluster",
            id: "clusterName",
            accessor: original => {
                const {clusterId} = original;
                const clusters = JSON.parse(clustersJSON);
                const clusterItem = clusters.find(cluster => cluster.id === clusterId);

                return isEmpty(clusterItem) ? "" : clusterItem.name;
            },
            disableSortBy: true
        },
        {
            Header: "Labels",
            id: "tag",
            Cell: ({row}) => <tableUtils.KeyValueRenderer items={row.original.labels} />,
            disableSortBy: true
        },
        {
            Header: "Time Added",
            id: "createdAt",
            accessor: original => formatDate(original.createdAt)
        }
    ], [clustersJSON]);

    const {isReadOnlyUser} = useAuthState();

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = useCallback(() => setRefreshTimestamp(Date()), []);

    const [selectedPodData, setSelectedPodData] = useState(null);
    const closePodModal = () => setSelectedPodData(null);

    const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationId(null);

    const [{deleting}, deletePodTemplate] = useDelete("podDefinitions");

    const [filters, setFilters] = useState({});

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    return (
        <div className="deployments-pod-templates-page">
            <div className="table-header-container">
                <IconWithTitle
                    name={ICON_NAMES.ADD}
                    title="New Pod Template"
                    className="add-new-button"
                    onClick={() => setSelectedPodData({})}
                    disabled={isReadOnlyUser}
                />
                <FiltersForm
                    filters={filters}
                    setFilters={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                />
            </div>
            <Table
                url="podDefinitions"
                name="podDefinitions"
                columns={columns}
                actionsComponent={({original}) => {
                    const {id} = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;

                    return (
                        <div className="pod-row-actions">
                            <IconWithTooltip
                                name={ICON_NAMES.EDIT}
                                onClick={() => setSelectedPodData(original)}
                                tooltipId={editTooltipId}
                                tooltipText="Edit pod template"
                                disabled={isReadOnlyUser}
                            />
                            <IconWithTooltip
                                name={ICON_NAMES.DELETE}
                                onClick={() => setDeleteConfirmationId(original.id)}
                                tooltipId={deleteTooltipId}
                                tooltipText="Delete pod template"
                                disabled={isReadOnlyUser}
                            />
                        </div>
                    );
                }}
                actionsCellWidth={80}
                withPagination={false}
                isLoading={deleting}
                refreshTimestamp={refreshTimestamp}
                exportToExcel={true}
                innerRowComponenet={InnerTable}
                filters={filters}
            />
            {!isNull(selectedPodData) &&
                <FormModal
                    onClose={closePodModal}
                    formComponent={PodForm}
                    formProps={{
                        initialData: {...selectedPodData},
                        onFormSubmitSuccess: () => {
                            closePodModal();
                            refreshTableData();
                        }
                    }}
                />
            }
            {!isNull(deleteConfirmationId) &&
                <ModalConfirmation 
                    title="Are you sure?"
                    message="This operation cannot be reverted"
                    confirmTitle="Delete"
                    onCancel={closeDeleteConfirmation}
                    onConfirm={() => {
                        closeDeleteConfirmation();
                        deletePodTemplate(deleteConfirmationId);
                    }}
                    confirmAlert
                />
            }
        </div>
    )
}

const PodTemplatesWrapper = () => {
    const [{loading, data}] = useFetch("leanKubernetesClusters");
    
    if (loading) {
        return <Loader />;
    }
    
    return (
        <PodTemplates clusters={data} />
    )
}

export default PodTemplatesWrapper;