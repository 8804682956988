import React, { useState, useMemo, useEffect, useRef } from 'react';
import { isNull } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { ACCOUNT_MODES_ITEMS } from 'utils/systemConsts';
import { formatDate } from 'utils/generalUtils';
import { useDelete } from 'hooks';
import Table, { tableUtils, FilterFormWrapper } from 'components/Table';
import { TextField } from 'components/Form';
import IconWithTitle from 'components/IconWithTitle';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';
import FormModal from 'components/FormModal';
import ModalConfirmation from 'components/ModalConfirmation';
import AccountForm from './AccountForm';

import './accounts.scss';

const ACCOUNTS_URL = "admin/accounts";

const Accounts = () => {
    const {isReadOnlyUser} = useAuthState();

    const columns = useMemo(() => [
        {
            Header: "Company Name",
            id: "name",
            accessor: "name",
            disableSortBy: true
        },
        {
            Header: "Account Status",
            id: "status",
            Cell: ({row}) => (
                <tableUtils.StatusRenderer isActive={row.original.status === "ENABLED"} disabledText="Disabled" />
            ),
            disableSortBy: true
        },
        {
            Header: "Tier",
            id: "accountTier",
            accessor: "accountTier",
            disableSortBy: true
        },
        {
            Header: "Mode",
            id: "mode",
            accessor: original => ACCOUNT_MODES_ITEMS[original.permissionsMode].label,
            disableSortBy: true
        },
        {
            Header: "Serverless Security",
            id: "serverlessSecurity",
            accessor: "serverlessSecurity",
            disableSortBy: true
        },
        {
            Header: "Created time",
            id: "createdTime",
            accessor: original => formatDate(original.createdTime)
        },
        {
            Header: "Info",
            id: "description",
            accessor: "description",
            disableSortBy: true
        }
    ], []);

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = () => setRefreshTimestamp(Date());

    const [selectedAccountData, setSelectedAccountData] = useState(null);
    const closeAccountModal = () => setSelectedAccountData(null);

    const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationData(null);

    const [{deleting}, deleteAccount] = useDelete(ACCOUNTS_URL);

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    const [filters, setFilters] = useState({
        userEmail: ""
    });

    return (
        <div className="accounts-page">
            <div className="table-header-container">
                <IconWithTitle
                    name={ICON_NAMES.ADD}
                    title="New Account"
                    className="add-new-button"
                    onClick={() => setSelectedAccountData({})}
                    disabled={isReadOnlyUser}
                />
                <FilterFormWrapper
                    initialValues={{...filters}}
                    onChange={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                >
                    <TextField name="userEmail" label="Email" />
                </FilterFormWrapper>
            </div>
            <Table
                url={ACCOUNTS_URL}
                name="accounts"
                columns={columns}
                filters={filters}
                actionsComponent={({original}) => {
                    const {id} = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;

                    return (
                        <div className="account-row-actions">
                            <IconWithTooltip
                                name={ICON_NAMES.EDIT}
                                onClick={() => setSelectedAccountData(original)}
                                tooltipId={editTooltipId}
                                tooltipText="Edit account"
                                disabled={isReadOnlyUser}
                            />
                            <IconWithTooltip
                                name={ICON_NAMES.DELETE}
                                onClick={() => setDeleteConfirmationData(original)}
                                tooltipId={deleteTooltipId}
                                tooltipText="Delete account"
                                disabled={isReadOnlyUser}
                            />
                        </div>
                    );
                }}
                actionsCellWidth={80}
                withPagination={true}
                isLoading={deleting}
                refreshTimestamp={refreshTimestamp}
                exportToExcel={true}
            />
            {!isNull(selectedAccountData) &&
                <FormModal
                    onClose={closeAccountModal}
                    formComponent={AccountForm}
                    formProps={{
                        initialData: {...selectedAccountData},
                        onFormSubmitSuccess: () => {
                            closeAccountModal();
                            refreshTableData();
                        }
                    }}
                />
            }
            {!isNull(deleteConfirmationData) &&
                <ModalConfirmation
                    title="Are you sure?"
                    message={<span>{`You are trying to delete the '${deleteConfirmationData.name}' account.`}<br />This operation cannot be reverted.</span>}
                    confirmTitle="Delete"
                    onCancel={closeDeleteConfirmation}
                    onConfirm={() => {
                        closeDeleteConfirmation();
                        deleteAccount(deleteConfirmationData.id);
                    }}
                    confirmAlert
                />
            }
        </div>
    )
}

export default Accounts;