import React from "react";
import { isEqual } from "lodash";
import InstructionsItem from "components/InstructionsItem";
import Text, {TEXT_TYPES} from "components/Text";

import './unprotected-pod-reason.scss';

const UNPROTECTED_POD_TYPES = {
    NAMESPACE_UNPROTECTED: "NAMESPACE_UNPROTECTED",
    HOST_NETWORK: "HOST_NETWORK",
    ISTIO_INJECT_FALSE_ANNOTATION: "ISTIO_INJECT_FALSE_ANNOTATION",
    ISTIO_INJECT_FALSE_LABEL: "ISTIO_INJECT_FALSE_LABEL",
    PORTSHIFT_INJECT_FALSE_ANNOTATION: "PORTSHIFT_INJECT_FALSE_ANNOTATION",
    PORTSHIFT_INJECT_FALSE_LABEL: "PORTSHIFT_INJECT_FALSE_LABEL",
    MISSING_SIDECARS: "MISSING_SIDECARS"
}

const getTitle = (type, name, value) => {
    switch (type){
        case UNPROTECTED_POD_TYPES.NAMESPACE_UNPROTECTED:
        case UNPROTECTED_POD_TYPES.MISSING_SIDECARS:
            return "Restart pod to get the sidecar protection";
        case UNPROTECTED_POD_TYPES.HOST_NETWORK:
            return `${name}=${value} prevents the pod from being protected`;
        case UNPROTECTED_POD_TYPES.ISTIO_INJECT_FALSE_ANNOTATION:
        case UNPROTECTED_POD_TYPES.PORTSHIFT_INJECT_FALSE_ANNOTATION:
            return `${name}=${value} annotation prevents the pod from being protected`;
        case UNPROTECTED_POD_TYPES.ISTIO_INJECT_FALSE_LABEL:
        case UNPROTECTED_POD_TYPES.PORTSHIFT_INJECT_FALSE_LABEL:
            return `${name}=${value} label prevents the pod from being protected`;
        default:
            return null;
    }
}

const getInputText = (type, podName, namespace) => {
    switch (type){
        case UNPROTECTED_POD_TYPES.NAMESPACE_UNPROTECTED:
        case UNPROTECTED_POD_TYPES.MISSING_SIDECARS:
            return `kubectl rollout restart deployment ${podName} -n ${namespace}`;
        default:
            return null;
    }
}

const UnprotectedPodInstructionsItem = ({title, inputText}) => (
    <InstructionsItem
        className="alt-unprotected-pod-command"
        title={title}
        inputText={inputText}
        withBullet={true}
    />
)

const ProtectInstructions = ({unprotectedPodReason, podName, namespace}) => {
    const {type, name, value} = unprotectedPodReason;
    const unprotectedPodType = UNPROTECTED_POD_TYPES[type];
    return (
        <React.Fragment>
            {isEqual(type, UNPROTECTED_POD_TYPES.NAMESPACE_UNPROTECTED) &&
                <UnprotectedPodInstructionsItem
                    title={"Protect pod:"}
                    inputText={`kubectl label namespace ${value} SecureApplication-protected=full --overwrite`}
                />
            }
            <UnprotectedPodInstructionsItem
                title={getTitle(unprotectedPodType, name, value)}
                inputText={getInputText(unprotectedPodType, podName, namespace)}
            />
        </React.Fragment>);
}

const UnprotectedPodReasonData = ({unprotectedPodReasons, podName, namespace}) => (
    <div className="unprotected-pod-data-container">
        <Text type={TEXT_TYPES.TITLE_MEDIUM} className="unprotected-pod-title" withTopMargin>Protection info</Text>
        {unprotectedPodReasons.map((unprotectedPodReason, index) =>
            (<ProtectInstructions key={index} unprotectedPodReason={unprotectedPodReason} podName={podName} namespace={namespace}/>))
        }
    </div>
)

export default UnprotectedPodReasonData;


