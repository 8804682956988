import React from 'react';
import { TextElement, InnerTitle, LinksList, InfoMessage } from 'components/DeleteWizardModal';

const RulesStep = ({stepData: rules, stepProps, name, pathname, getRuleItems}) => {
    const deleteItemName = stepProps.deleteItemName;
    
    return (
        <div className="delete-cluster-rules">
            <React.Fragment>
                <InnerTitle>{`${name} rules`}</InnerTitle>
                <TextElement>{`${deleteItemName} will be detached from the following ${name} rules.`}</TextElement>
                <InfoMessage>{`If the ${name} rule is only attached to ${deleteItemName}, it will be deleted.`}</InfoMessage>
                <LinksList
                    pathname={pathname}
                    items={getRuleItems(rules)}
                />
            </React.Fragment>
        </div>
    );
}

export default RulesStep;