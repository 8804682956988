import React, { useState, useEffect } from 'react';
import { FieldArray } from 'formik';
import classnames from 'classnames';
import { TextField, validators, useFormikContext, SelectField, AddIcon, RemoveIcon } from 'components/Form';
import Arrow from 'components/Arrow';

export const EMPTY_TOKEN_ITEM = {tokenId: "", envVariable: ""};

const TokenConfigFormField = ({index, token, tokenNames, tokensCount, remove, push, isOpen, setIsOpen, isValid, isReadOnlyUser}) => {
    const {tokenId} = token;

    const tokenItem = tokenNames.find(token => token.value === tokenId);
    const hasMultipleConditions = tokensCount > 1;
    const allowRemove = hasMultipleConditions;
    const allowToogleOpen = isValid && !isOpen;

    return (
        <div key={index} className="token-wrapper">
            {(!isOpen && !!tokenId) &&
                <div className="token-header">
                    <div className="token-title">{!!tokenItem ? tokenItem.label : tokenId}</div>
                    <Arrow
                        className={classnames("toggle-token", {disabled: !allowToogleOpen})}
                        name={isOpen ? "top" : "bottom"}
                        small={true}
                        onClick={allowToogleOpen ? setIsOpen : undefined}
                    />
                </div>
            }
            {isOpen &&
                <div className="token-content">
                    <SelectField
                        name={`ruleTypeProperties.tokens.${index}.tokenId`}
                        label="Token"
                        items={tokenNames}
                        validate={validators.validateRequired}
                        clearable={false}
                        disabled={isReadOnlyUser}
                    />
                    <TextField
                        name={`ruleTypeProperties.tokens.${index}.envVariable`}
                        label="Env variable"
                        validate={validators.validateRequired}
                        disabled={isReadOnlyUser}
                    />
                </div>
            }
            <div className={classnames("token-actions-wrapper", `token-actions-${index}`)}>
                <RemoveIcon onClick={() => remove(index)} disabled={!allowRemove || isReadOnlyUser} />
                <AddIcon onClick={() => push({...EMPTY_TOKEN_ITEM})} disabled={!isValid || isReadOnlyUser} />
            </div>
        </div>
    )
}

const FormTokens = ({tokenNames, isReadOnlyUser}) => {
    const {values, isValid, validateForm} = useFormikContext();
    const {tokens} = values.ruleTypeProperties;
    const tokensCount = tokens.length;
    
    const [openToken, setOpenToken] = useState(0);

    useEffect(() => {
        setOpenToken(tokensCount - 1);
    }, [tokensCount, setOpenToken, validateForm]);

    useEffect(() => {
        validateForm();
    }, [openToken, validateForm]);

    return (
        <div className="tokens-step-wrapper">
            <FieldArray name="ruleTypeProperties.tokens">
                {(fieldArrayProps) => tokens.map((token, index) => (
                    <TokenConfigFormField
                        key={index}
                        token={token}
                        tokensCount={tokensCount}
                        index={index}
                        isOpen={openToken === index}
                        setIsOpen={() => {
                            setOpenToken(index);
                        }}
                        isValid={isValid}
                        tokenNames={tokenNames}
                        isReadOnlyUser={isReadOnlyUser}
                        {...fieldArrayProps}
                    />
                ))}
            </FieldArray>
        </div>
    )
}

export default FormTokens;