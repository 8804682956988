import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { MultiselectField, useFormikContext, validators } from 'components/Form';

const AT_LEAST_ONE_REQUIRED_ERROR_MESSAGE = "You must fill one of the fields";

const FormStep3 = ({clusterItems, cloudAccountsItems, showServerlessSecurity}) => {
    const {values, validateForm} = useFormikContext();
    const {trustedSignerClusters, trustedSignerCloudAccounts} = values;

    useEffect(() => {
        validateForm();
    }, [trustedSignerClusters, trustedSignerCloudAccounts, validateForm]);

    return (
        <React.Fragment>
            <MultiselectField
                name="trustedSignerClusters"
                label="Clusters"
                items={clusterItems}
                validate={value => {
                    if (isEmpty(value) && isEmpty(trustedSignerCloudAccounts)) {
                        return showServerlessSecurity ? AT_LEAST_ONE_REQUIRED_ERROR_MESSAGE : validators.ERROR_MSG_IS_REQUIRED;
                    }
                }}
            />
            {showServerlessSecurity &&
                <MultiselectField
                    name="trustedSignerCloudAccounts"
                    label="Cloud accounts"
                    items={cloudAccountsItems}
                    validate={value => {
                        if (isEmpty(value) && isEmpty(trustedSignerClusters)) {
                            return AT_LEAST_ONE_REQUIRED_ERROR_MESSAGE;
                        }
                    }}
                />
            }
        </React.Fragment>
    )
}

export default FormStep3;