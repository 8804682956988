import React from 'react';
import { TextElement, InnerTitle, LinksList, InfoMessage } from 'components/DeleteWizardModal';
import { DEPLOYMNET_REGISTRIES_PAGE_PATH } from 'layout/Deployments/Registries';

const RegistriesStep = ({stepData: registries, stepProps}) => {
    const deleteItemName = stepProps.deleteItemName;
    
    return (
        <div className="delete-cluster-registries">
            <React.Fragment>
                <InnerTitle>Registries</InnerTitle>
                <TextElement>{`${deleteItemName} will be detached from the following Registries.`}</TextElement>
                <InfoMessage>{`If a Registry is only attached to ${deleteItemName}, it will be deleted.`}</InfoMessage>
                <LinksList
                    pathname={DEPLOYMNET_REGISTRIES_PAGE_PATH}
                    items={registries.map(({name, id}) => ({name, searchParams: {selectedRegistryId: id}}))}
                />
            </React.Fragment>
        </div>
    );
}

export default RegistriesStep;