import React from 'react';

import './list-value-items-display.scss';

const ListValueItemsDisplay = ({items, valuesConnector}) => (
    <div className="list-value-items-display">
        {
            items.map(({name, connector, values}) => {
                return (
                    <div key={name} className="item-prop">
                        <div className="prop-name">{name}</div>
                        {!!connector && <div className="prop-connector">{connector}</div>}
                        <div className="prop-values">
                            {
                                values.map((value, index, values) => {
                                    const isLast = index + 1 === values.length;

                                    return (
                                        <React.Fragment key={value}>
                                            <div className="prop-value">{value}</div>
                                            {(!isLast && !!valuesConnector) && <div className="prop-value-connector">or</div>}
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })
        }
    </div>
);

export default ListValueItemsDisplay;