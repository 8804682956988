import React from 'react';
import classnames from 'classnames';

import './unordered-list.scss';

const UnorderedList = ({items, alert=false}) => (
    <div className="workload-unordered-list">
        {items.map((item, index) => (
            <div key={index} className="workload-unordered-list-item">
                <div className={classnames("list-item-dot", {"with-alert": alert})}></div>
                <div className="list-item-content">{item}</div>
            </div>
        ))}
    </div>
);

export default UnorderedList;