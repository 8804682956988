import React, { useState } from 'react';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import Arrow from 'components/Arrow';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';

import './base-unordered-policy-item.scss';

const BaseUnorderedPolicyItem = (props) => {
    const {alwaysOpen, canCopy=true, id, name, description, disabled, children, canEditDelete, onDelete, onEdit, onCopy, isMarked} = props;
    
    const [isOpen, setIsOpen] = useState(alwaysOpen || false);

    const {isReadOnlyUser} = useAuthState();

    const allowEditDelete = canEditDelete && !isReadOnlyUser;
    const allowCopy = canCopy && !isReadOnlyUser;
    
    const copyTooltipId = `rule-copy-${id}`;
    const editTooltipId = `rule-edit-${id}`;
    const deleteTooltipId = `rule-delete-${id}`;

    const handleAction = (event, id, callback) => {
        event.preventDefault();
        event.stopPropagation();

        callback(id);
    }

    return (
        <div className={classnames("unorderd-policy-item-display-container", {"marked": isMarked})}>
            <div
                className="unorderd-policy-item-header-container"
                onClick={() => alwaysOpen ? undefined : setIsOpen(isOpen => !isOpen)}
                onDoubleClick={allowEditDelete ? onEdit : undefined}
            >
                <div className="unorderd-policy-item-name">{name}</div>
                <div className="unorderd-policy-item-description">{description}</div>
                <div className="unorderd-policy-item-actions">
                    {allowEditDelete &&
                        <IconWithTooltip
                            name={ICON_NAMES.DELETE}
                            onClick={event => handleAction(event, id, onDelete)}
                            disabled={disabled}
                            tooltipId={deleteTooltipId}
                            tooltipText="Delete"
                        />}
                    {allowCopy &&
                        <IconWithTooltip
                            name={ICON_NAMES.DUPLICATE}
                            onClick={event => handleAction(event, id, onCopy)}
                            disabled={disabled}
                            tooltipId={copyTooltipId}
                            tooltipText="Duplicate"
                        />}
                    {allowEditDelete &&
                        <IconWithTooltip
                            name={ICON_NAMES.EDIT}
                            onClick={event => handleAction(event, id, onEdit)} 
                            disabled={disabled}
                            tooltipId={editTooltipId}
                            tooltipText="Edit"
                        />}
                </div>
                {!alwaysOpen && 
                    <div className={classnames("unorderd-policy-item-open-toggle", {open: isOpen})}>
                        <Arrow name={isOpen ? "top" : "bottom"} small />
                    </div>
                }
            </div>
            {isOpen &&
                <div className="unorderd-policy-item-body-container">
                    {children}
                </div>
            }
        </div>
    );
}

export default BaseUnorderedPolicyItem;