import React from 'react';
import { useFetch } from 'hooks';
import Loader from 'components/Loader';
import TableSimple from 'components/TableSimple';

import './tab-secrets.scss';

const TabSecrets = ({functionId}) => {
    const [{loading, data, error}] = useFetch(`serverless/functions/${functionId}/secrets`);

    if (loading) {
        return <Loader />;
    }
    if (error) {
        return null;
    }
    
    return (
        <TableSimple
            className="function-secrets-tab-table"
            headers={["Risk reason", "Name"]}
            rows={data.map(({reason, name}) =>
                ([reason, name]))}
            emptyText="No data to display"
        />
    )
}

export default TabSecrets;