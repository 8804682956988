import React from "react";
import {FilterFormWrapper} from "components/Table";
import {TextField} from "components/Form";

export const SERVERLESS_CLOUD_ACCOUNT_FILTER_KEYS = {
    ACCOUNT_NAME: "name",
    REGION: "region"
}

const FiltersForm = ({filters,  queryFilters={}, setFilters}) => {
    const initialValues = {
        [SERVERLESS_CLOUD_ACCOUNT_FILTER_KEYS.ACCOUNT_NAME]: "",
        [SERVERLESS_CLOUD_ACCOUNT_FILTER_KEYS.REGION]: "",
        ...filters,
        ...queryFilters,
    };

    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={setFilters}
        >
            <TextField name={SERVERLESS_CLOUD_ACCOUNT_FILTER_KEYS.ACCOUNT_NAME} label="Name"  placeholder="Search..."/>
            <TextField name={SERVERLESS_CLOUD_ACCOUNT_FILTER_KEYS.REGION} label="Region"  placeholder="Search..."/>
        </FilterFormWrapper>
    )
};

export default FiltersForm;