import React, { useEffect } from 'react';
import { isUndefined } from 'lodash';
import { usePrevious } from 'hooks';
import { validators, TextField, RadioField, YesNoToggleField, useFormikContext } from 'components/Form';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { ENDPOINT_TYPE_ITEMS } from '../utils';
import { IP_TYPES_ITEMS, POD_TYPES_ITEMS } from '../RuleSubForms';
import { MASTER_TYPE_FIELD_NAME, EMPTY_ENDPOINT_DATA } from './FormEndpointBase';

const FormRuleProperties = ({isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {isIntercept} = values.layer7Settings;
    const prevIsIntercept = usePrevious(isIntercept);

    useEffect(() => {
        if (!isUndefined(prevIsIntercept) && !prevIsIntercept && isIntercept) {
            setFieldValue(`source.${MASTER_TYPE_FIELD_NAME}`, ENDPOINT_TYPE_ITEMS.POD.value);
            setFieldValue("source.connectionRulePartType", POD_TYPES_ITEMS.LABEL.value);
            
            setFieldValue(`destination.${MASTER_TYPE_FIELD_NAME}`, ENDPOINT_TYPE_ITEMS.IP_RANGE.value);
            setFieldValue("destination.connectionRulePartType", IP_TYPES_ITEMS.FQDN.value);

            Object.keys(EMPTY_ENDPOINT_DATA).forEach(key => {
                const emptyValue = EMPTY_ENDPOINT_DATA[key];
    
                setFieldValue(`source.${key}`, emptyValue);
                setFieldValue(`destination.${key}`, emptyValue);
            });
        }
    }, [prevIsIntercept, isIntercept, setFieldValue]);

    return (
        <React.Fragment>
            <TextField name="name" label="Rule Name" validate={validators.validateRequired} disabled={isReadOnlyUser} />
            <RadioField
                name="action"
                label="Action"
                validate={validators.validateRequired}
                items={[
                    {value: RULE_ACTIONS.ALLOW, label: "Allow only the connection between the source and destination"},
                    {value: RULE_ACTIONS.DETECT, label: "Detect the connection between the source and destination"},
                    {value: RULE_ACTIONS.BLOCK, label: "Block the connection between the source and destination"}
                ]}
                disabled={isReadOnlyUser}
            />
            <YesNoToggleField
                name="layer7Settings.isIntercept"
                label="Intercept https traffic"
                tooltipText={<span>
                    Decrypt and re-encrypt https traffic<br />
                    in order for the Connection Rule to inspect it.
                </span>}
                disabled={isReadOnlyUser}
            />
        </React.Fragment>
    );
}

export default FormRuleProperties;