import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useFetch } from 'hooks';
import DetailsTabbedPage from 'components/DetailsTabbedPage';
import TabDetails from './TabDetails';
import TabStatistics from './TabStatistics';

import './connection-details.scss';

const RUNTIME_CONNECTION_TABS = {
    DETAILS: "DETAILS",
    STATISTICS: "STATISTICS"
}

const ConnectionDetails = () => {
    const params = useParams();
    const {telemetryId} = params;

    const {search} = useLocation();
    const query = new URLSearchParams(search);
    const startTime = query.get("startTime");
    const endTime = query.get("endTime");

    const [{loading, data, error}] = useFetch(`connectionTelemetries/${telemetryId}`, {queryParams: {startTime, endTime}});

    return (
        <DetailsTabbedPage
            loading={loading}
            error={error}
            id={telemetryId}
            title="Connection info"
            backTitle="Connections"
            tabItems={[
                {id: RUNTIME_CONNECTION_TABS.DETAILS, title: "Connection Details", component: () => <TabDetails data={data} />},
                {
                    id: RUNTIME_CONNECTION_TABS.STATISTICS,
                    title: "Statistics",
                    component: () => <TabStatistics data={data} startTime={startTime} endTime={endTime} />
                }
            ]}
            defaultSelectedTab={RUNTIME_CONNECTION_TABS.DETAILS}
        />
    )
}

export default ConnectionDetails;
