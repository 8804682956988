import React from 'react';
import { cloneDeep } from 'lodash';
import classnames from 'classnames';
import Icon, { ICON_NAMES } from 'components/Icon';
import Advisor, { ContentItemDisplay, ADVISOR_TYEPS } from 'layout/Advisor';

const AdvisorEndpointsDisplay = ({data}) => {
    const risk = data.risk || "";

    return (
        <div className="environment-advisor-content-wrapper">
            <ContentItemDisplay
                data={data}
                title="Kubernetes Settings"
                displayData={[
                    {title: "Cluster name", valueKey: "kubernetesEnvironments[0].kubernetesClusterName"},
                    {title: "Namespaces", valueKey: "kubernetesEnvironments[0].namespaces", isList: true}
                ]}
            />
            <div className="content-display-item" >
                <div className="suggestion-content-title">Risk</div>
                <div className="risk-icon-container">
                    <Icon name={ICON_NAMES.EXCLAMATION_MARK_ROUND} className={classnames("global-risk-color", {[risk.toLowerCase()]: !!risk})} />
                    <div className="risk-title">{risk}</div>
                </div>
            </div>
        </div>
    );
}

const getFormatData = () => {
    return data => {
        return data.map(item => ({
            recommendations: item.environmentRecommendations.map(recommendation => ({
                id: recommendation.id,
                affected: recommendation.numberOfAffectedWorkloads,
                recommendation: {
                    ...recommendation.environemnt
                }
            })),
            total: item.totalWorkloads,
            timePeriod: item.timePeriod
        }));
    }
}

const EnvironmentsAdvisor = ({submitAdvisorData}) => (
    <Advisor
        className="environments-advisor"
        url="advisor/environment"
        advisorType={ADVISOR_TYEPS.ENVIRONMENT}
        formatData={getFormatData()}
        contentDisplayItems={[
            AdvisorEndpointsDisplay
        ]}
        emptyMessage="No environment suggestions"
        applyTitle="Create Environment"
        buttonTitle="Environment Advisor"
        title="Workloads"
        onItemsAdd={data => {
            const formattedData = cloneDeep(data);
        
            formattedData.forEach(item => {
                delete item.id;
                
                item.kubernetesEnvironments = item.kubernetesEnvironments.map(
                    ({id, kubernetesClusterName, preserveOriginalSourceIp, ...data}) => data);
            });
                
            submitAdvisorData(formattedData);
        }}
    />
);

export default EnvironmentsAdvisor;