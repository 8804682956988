import React from 'react';
import { TextField, MultiselectCheckboxField } from 'components/Form';
import { FilterFormWrapper } from 'components/Table';
import { POD_DEPLOYMENT_TYPE_ITEMS } from './PodForm';

const POD_DEPLOYMENT_TYPE_ITEMS_UPPER = POD_DEPLOYMENT_TYPE_ITEMS.map(
    item => ({...item, value: item.value.toUpperCase()}));

export const POD_SOURCE_ITEMS = {
    KUBERNETES: {
        value: "KUBERNETES",
        label: "Kubernetes"
    },
    HELM: {
        value: "HELM",
        label: "helm"
    },
    CLI: {
        value: "CLI",
        label: "CLI"
    },
    MANUAL: {
        value: "MANUAL",
        label: "Manual"
    }
};

const FilterForm = ({filters, setFilters}) => {
    const initialValues = {
        ...filters,
        name: "",
        clusterName: "",
        deploymentType: POD_DEPLOYMENT_TYPE_ITEMS_UPPER.map(({value}) => value),
        templateSource: Object.keys(POD_SOURCE_ITEMS)
    };
    
    return (
        <FilterFormWrapper
            initialValues={initialValues}
            onChange={setFilters}
        >
            <TextField name="name" label="Pod Template" />
            <TextField name="clusterName" label="Cluster" />
            <MultiselectCheckboxField
                name="deploymentType"
                label="Deployment Type"
                items={POD_DEPLOYMENT_TYPE_ITEMS_UPPER}
            />
            <MultiselectCheckboxField
                name="templateSource"
                label="Source"
                items={Object.values(POD_SOURCE_ITEMS)}
            />
        </FilterFormWrapper>
    )
};

export default FilterForm;