import { useReducer, useEffect } from 'react';
import { useFetch, usePrevious, FETCH_METHODS } from 'hooks';

import './policy-history.scss';

export {
    usePrevious
};

export const HISTORY_ACTIONS = {
    SET_LOADED_DATA: "SET_LOADED_DATA",
    SET_SELECTED_POLICY_DATA: "SET_SELECTED_POLICY_DATA",
    SET_RESTORE_DONE: "SET_RESTORE_DONE",
    TOGGLE_HISTORY_SHOW: "TOGGLE_HISTORY_SHOW",
    RESTORE_VERSION: "RESTORE_VERSION"
};

const inititalState = {
    isLoading: true,
    historyData: [],
    selectedData: null,
    restoreSuccess: false,
    showHistory: false,
    doRestore: false,
    restoreData: null
};

function reducer(state, action) {
    switch (action.type) {
        case HISTORY_ACTIONS.SET_LOADED_DATA:
            return {...state, isLoading: false, historyData: action.payload};
        case HISTORY_ACTIONS.SET_SELECTED_POLICY_DATA:
            return {...state, selectedData: action.payload};
        case HISTORY_ACTIONS.SET_RESTORE_DONE:
            const {success} = action.payload;
            return {...state, restoreSuccess: success, doRestore: false, restoreData: null};
        case HISTORY_ACTIONS.TOGGLE_HISTORY_SHOW:
            const showHistory = action.payload;
            let updatedState = {
                showHistory
            }
            
            if (!showHistory) {
                updatedState = {...inititalState};
            }

            return {...state, ...updatedState};
        case HISTORY_ACTIONS.RESTORE_VERSION:
            return {...state, doRestore: true, restoreData: action.payload};
        default:
            return {...state};
    }
}

function usePolicyHistoryReducer(url) {
    const [{loading, data}, fetchHistory] = useFetch(`${url}/history`, {loadOnMount: false});
    const [{loading: restoring, error: errorRestoring}, restoreVersion] = useFetch(url, {loadOnMount: false});
    const [historyState, dispatch] = useReducer(reducer, inititalState);
    const {showHistory, doRestore, restoreData} = historyState;

    const prevLoading = usePrevious(loading);
    const prevShowHistory = usePrevious(showHistory);
    const prevRestoring = usePrevious(restoring);
    const prevDoRestore = usePrevious(doRestore);

    const dataJson = JSON.stringify(data);
    const restoreDataJson = JSON.stringify(restoreData);

    useEffect(() => {
        //fetch history data on modal open
        if (!prevShowHistory && showHistory) {
            fetchHistory();
        }
    }, [showHistory, prevShowHistory, fetchHistory]);
    
    useEffect(() => {
        //updated history data on load request sucess
        if (prevLoading && !loading) {
            dispatch({type: HISTORY_ACTIONS.SET_LOADED_DATA, payload: JSON.parse(dataJson)});
        }
    }, [loading, prevLoading, dataJson]);

    useEffect(() => {
        //submit restore request
        if (!prevDoRestore && doRestore) {
            restoreVersion({method: FETCH_METHODS.PUT, submitData: JSON.parse(restoreDataJson)});
        }
    }, [doRestore, prevDoRestore, restoreDataJson, restoreVersion]);

    useEffect(() => {
        //update restore state on restore request done
        if (prevRestoring && !restoring) {
            dispatch({type: HISTORY_ACTIONS.SET_RESTORE_DONE, payload: {success: !errorRestoring}});
        }
    }, [restoring, prevRestoring, errorRestoring]);

    return [{
        historyState,
        loading: loading || historyState.isLoading || restoring,
    }, dispatch];
}

export default usePolicyHistoryReducer;