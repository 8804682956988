import React from 'react';
import { SYSTEM_RISKS } from 'utils/systemConsts';
import { ICON_NAMES } from 'components/Icon';
import VulnerabilitiesSummary from 'components/VulnerabilitiesSummary';

export const PermissionsVulnerabilitiesSummary = (props) => (
    <VulnerabilitiesSummary icon={ICON_NAMES.PERMISSIONS} {...props} />
)

export const BREADCRUMBS_TITLE = "Permissions Analysis";

export const getPermissionsInfoCountByRisk = (permissionsInfo, risk) => {
    const riskItem = permissionsInfo.find(item => item.risk === risk);

    return !!riskItem ? riskItem.count : 0;
}

export const PERMISSIONS_RISK_ITEMS = {
    [SYSTEM_RISKS.HIGH.value]: SYSTEM_RISKS.HIGH,
    [SYSTEM_RISKS.MEDIUM.value]: SYSTEM_RISKS.MEDIUM,
    [SYSTEM_RISKS.NO_RISK.value]: SYSTEM_RISKS.NO_RISK,
    [SYSTEM_RISKS.APPROVED.value]: SYSTEM_RISKS.APPROVED,
}

export const FILTER_KEYS = {
    PERMISSION_RISK: "permissionRisk",
    OWNER: "owner"
}
export const PERMISSIONS_PAGE_PATH = "/riskAssessment/permissions";

export const SYSTEM_OWNER_TYPE_MAP = {
    SYSTEM: {
        icon: ICON_NAMES.COG,
        ownerTitle: "System default owner",
        permissionTitle: "System default permission"
    },
    USER: {
        icon: ICON_NAMES.USER,
        ownerTitle: "User owner",
        permissionTitle: "User permission"
    },
    HYBRID: {
        icon: ICON_NAMES.USER_SYSTEM,
        ownerTitle: "Default system owner with user roles"
    }
};