import { useReducer, useEffect, useRef, useCallback } from 'react';
import { isUndefined, isNull } from 'lodash';
import { useAuthDispatch, AUTH_ACTIONS } from 'context/AuthProvider';
import { useNotificationDispatch, showNotification, NOTIFICATION_TYPES } from 'context/NotificationProvider';
import { formatFetchUrl, formatFetchOptions, FETCH_METHODS, AUTHENTICATION_ERROR_CODES,
    AUTHENTICATION_ERROR_INDICATOR } from './fetchUtils';

const LOADING_DATA = "LOADING_DATA";
const LOAD_DATA_SUCCESS = "LOAD_DATA_SUCCESS";
const LOAD_DATA_ERROR = "LOAD_DATA_ERROR";
const UPDATE_FETCH_PARAMS = "UPDATE_FETCH_PARAMS";

const getErrorMessage = (method) => `An error occurred when trying to ${method === FETCH_METHODS.GET ? "fetch" : "submit"} data`;

function reducer(state, action) {
    switch (action.type) {
        case LOADING_DATA:
            return {...state, loading: true, error: null, loadData: false};
        case LOAD_DATA_SUCCESS:
            return {...state, loading: false, data: action.payload, loadData: false};
        case LOAD_DATA_ERROR:
            return {...state, loading: false, error: action.payload, loadData: false, data: null};
        case UPDATE_FETCH_PARAMS:
            const {queryParams, method=FETCH_METHODS.GET, submitData, formatUrl, headers} = action.payload || {};
            
            return {
                ...state,
                url: formatFetchUrl({url: state.baseUrl, queryParams, formatUrl}),
                method: method.toUpperCase(),
                submitData: !!submitData ? JSON.stringify(submitData) : null,
                loadData: true,
                data: undefined,
                headers: headers || {}
            };
        default:
            return {...state};
    }
}

function useFetch(baseUrl, options){
    const authDispatcher = useAuthDispatch();
    const notificationDispatch = useNotificationDispatch();

    const {queryParams, method: initialMethod, submitData: inititalSubmitData, headers: inititalHeaders, formatUrl, loadOnMount=true, customErrorMessage,
        disableLogout=false, disableAuthFlow=false} = options || {};

    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        error: null,
        data: loadOnMount ? undefined : null,
        baseUrl,
        url: formatFetchUrl({url: baseUrl, queryParams, formatUrl}),
        method: !!initialMethod ? initialMethod.toUpperCase() : FETCH_METHODS.GET,
        submitData: !!inititalSubmitData ? JSON.stringify(inititalSubmitData) : null,
        loadData: loadOnMount || false,
        headers: inititalHeaders || {}
    });

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    const {url, method, submitData, loadData, data, error, loading, headers} = state;
    const headersJSON = JSON.stringify(headers);

    const doFetch = useCallback(async () => {
        const options = formatFetchOptions({method, stringifiedSubmitData: submitData, headers: JSON.parse(headersJSON)});

        dispatch({type: LOADING_DATA});

        let isError = false;
        const showErrorMessage = () => showNotification(notificationDispatch, {message: !!customErrorMessage ? customErrorMessage : getErrorMessage(method), type: NOTIFICATION_TYPES.ERROR});

        fetch(url, options)
            .then(response => {
                if (!disableAuthFlow && AUTHENTICATION_ERROR_CODES.includes(response.status)) {
                    throw Error(AUTHENTICATION_ERROR_INDICATOR);
                }
                
                isError = !response.ok;

                return response;
            })
            .then(response => response.status === 204 ? {} : response.json())
            .then(data => {
                if (!mounted.current) {
                    return;
                }

                if (isError) {
                    dispatch({type: LOAD_DATA_ERROR, payload: data});

                    showErrorMessage();

                    return;
                }
                
                dispatch({type: LOAD_DATA_SUCCESS, payload: data});
            })
            .catch(error => {
                if (!mounted.current) {
                    return;
                }
                
                if (!!error && error.message === AUTHENTICATION_ERROR_INDICATOR && !disableLogout) {
                    authDispatcher({type: AUTH_ACTIONS.RESET_AUTHENTICATION});

                    return;
                }

                showErrorMessage();
                
                dispatch({type: LOAD_DATA_ERROR, payload: error});

            });
    }, [url, method, submitData, customErrorMessage, authDispatcher, disableLogout, notificationDispatch, disableAuthFlow, headersJSON]);
    
    useEffect(() => {
        if (!mounted.current) {
            return;
        }
        
        if (!loadData) {
            return;
        }
        
        doFetch();
    }, [doFetch, loadOnMount, loadData]);

    const fetchData = useCallback(fetchParams => dispatch({type: UPDATE_FETCH_PARAMS, payload: fetchParams}), []);
    
    return [{data, error, loading: loading || (isUndefined(data) && isNull(error))}, fetchData];
}

export default useFetch;