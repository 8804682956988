import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { useFetch } from 'hooks';
import { SelectField } from 'components/Form';
import { FilterFormWrapper } from 'components/Table';
import Loader from 'components/Loader';
import Vulnerabilities from 'layout/CiCd/Vulnerabilities';

import './image-vulnerabilities.scss';

const ImageVulnerabilities = props => {
    const {id, withLayersFilter=false, url="images", selectedLayer} = props;
    const [{loading, data, error}] = useFetch(`${url}/${id}/imageLayers`, {loadOnMount: withLayersFilter});

    const [filters, setFilters] = useState({
        layerId: selectedLayer || "",
        showOnlyVulnerabilitiesWithFix: "false"
    })

    const mounted = useRef(true);
    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return null;
    }

    const {vulnerableLayers=[]} = data || {};

    return (
        <div className={classnames("image-vulnerabilities-wrapper", {"with-fitler": withLayersFilter})}>
            {withLayersFilter &&
                <FilterFormWrapper
                    className="image-vulnerabilities-filter"
                    initialValues={{...filters}}
                    onChange={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                >
                    <SelectField
                        name="layerId"
                        label="Image layer"
                        items={vulnerableLayers.map(({id, command}) => ({value: id, label: command}))}
                        small
                        className="layers-filters-select"
                    />
                    <SelectField
                        name="showOnlyVulnerabilitiesWithFix"
                        label="Fixable only"
                        items={[
                            {value: "true", label: "Yes"},
                            {value: "false", label: "No"}
                        ]}
                        small
                        clearable={false}
                    />
                </FilterFormWrapper>
            }
            <Vulnerabilities {...props} url={url} filters={filters} withAcknowledgedStatus />

        </div>
        )
}

export default ImageVulnerabilities;