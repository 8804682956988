import React from 'react';
import SelectPanes from 'components/SelectPanes';
import TitleWithRiskDisplay from 'components/TitleWithRiskDisplay';
import DisplaySection from 'components/DisplaySection';
import KeyValueList from 'components/KeyValueList';
import TableSimple from 'components/TableSimple';
import RiskTag from 'components/RiskTag';
import Arrow, { ARROW_NAMES} from 'components/Arrow';

import './tab-role-policies.scss';

const SelectedRoleTitle = ({title, risk, onBack}) => (
    <div className="selected-role-title">
        <Arrow name={ARROW_NAMES.LEFT} onClick={onBack} />
        <TitleWithRiskDisplay title={`${title}:`} risk={risk} />
    </div>
)

const SelectedRoleContent = ({data, onBack}) => {
    const {policyName, policyArn, policyRisk, riskReasons} = data;
    
    return (
        <div className="selected-role-wrapper">
            <DisplaySection title={<SelectedRoleTitle title={policyName} risk={policyRisk} onBack={onBack} />}>
                <KeyValueList items={[{label: "Identifier", value: policyArn}]}/>
            </DisplaySection>
            <DisplaySection title="Risk reasons">
                <TableSimple
                    headers={["Risk reason", "Risk"]}
                    rows={riskReasons.map(({reason, risk}) =>
                        ([
                            reason,
                            <RiskTag risk={risk} />
                        ]))}
                    emptyText="No known risk"
                />
            </DisplaySection>
        </div>
    )
};

const TabRolePolicies = ({data}) => {
    const {name, roleDetails} = data; 
    const {roleName, roleArn, policies} = roleDetails;
    
    return (
        <SelectPanes
            className="function-role-policies"
            selectItems={policies.map(policyItem => {
                const {policyName} = policyItem;

                return {...policyItem, name: policyName, id: policyName}
            })}
            itemDisplay={({name, policyRisk}) => <TitleWithRiskDisplay title={name} risk={policyRisk} />}
            selectListTitle="Permissions list"
            selectContent={({data, onBack}) => <SelectedRoleContent data={data} onBack={onBack} />}
            emptyContent={() => (
                <div className="empty-policy-role-wrapper">
                    <DisplaySection title={`${name} role details`}>
                        <KeyValueList items={[
                            ...(!!roleName ? [{label: "Role name", value: roleName}] : []),
                            ...(!!roleArn ? [{label: "Role ARN", value: roleArn}] : [])
                        ]}/>
                    </DisplaySection>
                </div>
            )}
        />
    )
}

export default TabRolePolicies;