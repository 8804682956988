import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import { isUndefined } from 'lodash';
import { REGULAR_USER_ROLES_ITEMS, GLOBAL_ADMIN_USER_ROLES_ITEMS } from 'utils/systemConsts';

const READ_ONLY_ROLES = [GLOBAL_ADMIN_USER_ROLES_ITEMS.PORTSHIFT_AUDITOR.value, GLOBAL_ADMIN_USER_ROLES_ITEMS.SELF_PROVISIONING.value, REGULAR_USER_ROLES_ITEMS.ACCOUNT_AUDITOR.value];
const GLOBAL_ADMIN_USERS = Object.keys(GLOBAL_ADMIN_USER_ROLES_ITEMS);

const initialState = {
    //server account data:
    accountId: null,
    accountTier: null,
    apiSecurity: null,
    serverlessSecurity: null,
    description: null,
    permissionsMode: null,
    pricingUnitType: null,
    status: null,
    usageStatus: {},
    //server user data:
    id: null,
    email: null,
    fullName: null,
    role: null,
    lastLogin: null,
    shouldDisplayEula: false,
    hideDemoPortalBanner: false,
    //computed data:
    isAuthenticated: false,
    isAuthenticateError: false,
    isLoading: false,
    isGlobalAdmin: false,
    isReadOnlyUser: false
};

export const AUTH_ACTIONS = {
    AUTHENTICATION_COMPLETE: "AUTHENTICATION_COMPLETE",
    AUTHENTICATION_ERROR: "AUTHENTICATION_ERROR",
    SET_IS_LOADING: "SET_IS_LOADING",
    RESET_AUTHENTICATION: "RESET_AUTHENTICATION",
    HIDE_EULA_DISPLAY: "HIDE_EULA_DISPLAY",
    HIDE_DEMO_PORTAL_BANNER: "HIDE_DEMO_PORTAL_BANNER"
};

const reducer = (state, action) => {
    switch (action.type) {
        case AUTH_ACTIONS.AUTHENTICATION_COMPLETE: {
            const updatedData = action.payload;
            const { id, role, usageStatus, fullName, email } = updatedData;

            LogRocket.identify(id, {
                name: fullName,
                email,
                "Current Clusters usage": usageStatus.currentClustersUsage
            });

            return {
                ...state,
                ...updatedData,
                usageStatus: usageStatus || {},
                isAuthenticated: true,
                isLoading: false,
                isGlobalAdmin: GLOBAL_ADMIN_USERS.includes(role),
                isReadOnlyUser: READ_ONLY_ROLES.includes(role)
            };
        }
        case AUTH_ACTIONS.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: true
            }
        }
        case AUTH_ACTIONS.RESET_AUTHENTICATION: {
            return {
                ...state,
                ...initialState
            }
        }
        case AUTH_ACTIONS.AUTHENTICATION_ERROR: {
            return {
                ...state,
                ...initialState,
                isAuthenticateError: true
            }
        }
        case AUTH_ACTIONS.HIDE_EULA_DISPLAY: {
            return {
                ...state,
                shouldDisplayEula: false
            }
        }
        case AUTH_ACTIONS.HIDE_DEMO_PORTAL_BANNER: {
            return {
                ...state,
                hideDemoPortalBanner: true
            }
        }
        default:
            return state;
    }
}

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const useAuthState = () => {
    const context = React.useContext(StateContext);

    if (isUndefined(context)) {
        throw Error("useState is not within the AuthProvider provider")
    }

    return context;
}

const useAuthDispatch = () => {
    const context = React.useContext(DispatchContext);

    if (isUndefined(context)) {
        throw Error("useDispatch is not within the AuthProvider provider")
    }

    return context;
}

const AuthProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
        isLoading: true
    });

    const loadUserData = () => fetch("/api/me", {})
        .then(response => {
            if (!response.ok) {
                throw Error("Loading error");
            }

            return response;
        })
        .then(response => response.status === 204 ? {} : response.json())
        .then(data => {
            dispatch({ type: AUTH_ACTIONS.AUTHENTICATION_COMPLETE, payload: data });
        })
        .catch(error => {
            dispatch({ type: AUTH_ACTIONS.RESET_AUTHENTICATION });
        });

    useEffect(() => {
        loadUserData();
    }, []);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

const loginUser = (dispatch, token) => {
    dispatch({ type: AUTH_ACTIONS.SET_IS_LOADING });
    const options = {
        credentials: 'include',
        method: 'post',
        headers: {
            'content-type': 'application/json',
            'token': token
        },
        body: JSON.stringify({})
    };

    fetch("/api/login", options)
        .then(response => {
            const { status } = response;

            if (status === 401) {
                throw Error("Auth Error");
            }

            if (status < 500) {
                return response;
            }

            throw Error(response.message);
        })
        .then(response => response.json())
        .then(data => {
            dispatch({ type: AUTH_ACTIONS.AUTHENTICATION_COMPLETE, payload: data });
        })
        .catch(() => {
            dispatch({ type: AUTH_ACTIONS.AUTHENTICATION_ERROR });
        });
}

const logoutUser = (dispatch) => {
    dispatch({ type: AUTH_ACTIONS.SET_IS_LOADING });

    const options = {
        credentials: 'include',
        method: 'post',
        headers: { 'content-type': 'application/json' }
    }

    fetch("/api/logout", options)
        .then(response => {
            if (response.status === 204) {
                dispatch({ type: AUTH_ACTIONS.RESET_AUTHENTICATION });
            }
        });
}

export {
    AuthProvider,
    useAuthState,
    useAuthDispatch,
    loginUser,
    logoutUser
};