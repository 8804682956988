import React from 'react';
import MDSpinner from 'react-md-spinner';
import classnames from 'classnames';

import './loader.scss';

const Loader = ({absolute=true, small=false, alignLeft=false}) => (
    <MDSpinner
        className={classnames("ps-loader", {absolute}, {small}, {"align-left": alignLeft})}
        singleColor="#27446E"
    />
);

export default Loader;