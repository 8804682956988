import React, { useEffect } from 'react';
import { get, sortBy } from 'lodash';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { CONNECTION_ENDPOINT_TYPES } from 'utils/systemConsts';
import { usePrevious } from 'hooks';
import { validators, SelectField, useFormikContext } from 'components/Form';
import { EnvironmentForm, ExpansionForm, IpForm, IpOrDomainForm, PodForm, KafkaForm, ApiForm } from '../RuleSubForms';
import { getEndpointTypeItems, KAFKA_ENPOINT_TYPE, ENDPOINT_TYPE_ITEMS, API_ENPOINT_TYPE } from '../utils';
import { IP_TYPES_ITEMS } from '../RuleSubForms';

export const MASTER_TYPE_FIELD_NAME = "masterType";

export const EMPTY_ENDPOINT_DATA = {
    names: [],
    labels: [{key: "", value: ""}],
    environments: [],
    risks: [],
    networks: [""],
    fqdnAddresses: [""],
    clusterId: "",
    brokers: [],
    vulnerabilitySeverityLevel: "",
    apiSecurityProfile: "",
    api: "",
    type: ""
}

const EXTERNAL_TYPES_VALUES = [
    ...Object.values(IP_TYPES_ITEMS).map(item => item.value),
    ENDPOINT_TYPE_ITEMS.EXTERNAL.value,
    ENDPOINT_TYPE_ITEMS.IP_RANGE.value
];

const FormEndpointBase = (props) => {
    const {endpoint, ipWithDomain, isReadOnlyUser} = props;

    const {showApiSecurity} = useDisplayConfigState();

    const {values, setFieldValue} = useFormikContext();
    const {source, destination, layer7Settings} = values;
    const {isIntercept} = layer7Settings;
    
    const masterType = get(values, `${endpoint}.${MASTER_TYPE_FIELD_NAME}`);
    const prevMasterType = usePrevious(masterType);

    const isDestinationEndpoint = endpoint === CONNECTION_ENDPOINT_TYPES.DESTINATION;

    const ENDPOINT_TYPES = getEndpointTypeItems(ipWithDomain);
    let endpointTypeItems = Object.values(ENDPOINT_TYPES);
    if (isIntercept) {
        if (isDestinationEndpoint) {
            endpointTypeItems = [ENDPOINT_TYPES.IP_RANGE];
        } else {
            endpointTypeItems = [ENDPOINT_TYPES.ENVIRONMENT, ENDPOINT_TYPES.POD];
        }
    } else if (isDestinationEndpoint) {
        endpointTypeItems.push(KAFKA_ENPOINT_TYPE);
    }
    if (isDestinationEndpoint && showApiSecurity) {
        endpointTypeItems.push(API_ENPOINT_TYPE);
    }

    const sourceTypeIsExternal =  EXTERNAL_TYPES_VALUES.includes(source[MASTER_TYPE_FIELD_NAME]);
    const destinationTypeIsExternal =  EXTERNAL_TYPES_VALUES.includes(destination[MASTER_TYPE_FIELD_NAME]);

    const destinationFormSourceIsExternal = isDestinationEndpoint && sourceTypeIsExternal;
    const sourceFormDestinationIsExternal = !isDestinationEndpoint && destinationTypeIsExternal;

    endpointTypeItems = endpointTypeItems.map(typeItem => ({
        ...typeItem,
        isDisabled: EXTERNAL_TYPES_VALUES.includes(typeItem.value) && (sourceFormDestinationIsExternal || destinationFormSourceIsExternal)
    }));

    useEffect(() => {
        if (masterType === prevMasterType || !prevMasterType) {
            return;
        }
        
        setFieldValue(`${endpoint}.connectionRulePartType`, "");
        
        Object.keys(EMPTY_ENDPOINT_DATA).forEach(key => {
            const emptyValue = EMPTY_ENDPOINT_DATA[key];

            setFieldValue(`${endpoint}.${key}`, emptyValue);
        });

    }, [masterType, prevMasterType, setFieldValue, endpoint, isIntercept]);

    return (
        <React.Fragment>
            <SelectField 
                name={`${endpoint}.${MASTER_TYPE_FIELD_NAME}`}
                label={`Select an option to identify the ${endpoint}`}
                items={sortBy(endpointTypeItems, "label")}
                validate={validators.validateRequired}
                clearable={false}
                disabled={isReadOnlyUser}
            />
            {masterType === ENDPOINT_TYPES.POD.value && <PodForm {...props} displayEnvAlert={destinationFormSourceIsExternal} />}
            {masterType === ENDPOINT_TYPES.EXPANSION.value && <ExpansionForm {...props} />}
            {masterType === KAFKA_ENPOINT_TYPE.value && <KafkaForm {...props} />}
            {masterType === ENDPOINT_TYPES.ENVIRONMENT.value && <EnvironmentForm {...props} displayEnvAlert={destinationFormSourceIsExternal} />}
            {masterType === ENDPOINT_TYPES.IP_RANGE.value && (ipWithDomain ? <IpOrDomainForm {...props} /> : <IpForm {...props} />)}
            {masterType === API_ENPOINT_TYPE.value && <ApiForm {...props} />}
        </React.Fragment>
    );
};

export default FormEndpointBase;
