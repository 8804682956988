import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from 'hooks';
import DetailsTabbedPage from 'components/DetailsTabbedPage';
import PackageLicenses from 'layout/CiCd/PackageLicenses';
import Vulnerabilities from 'layout/CiCd/Vulnerabilities';

import './zip-details.scss';

const ZIP_TABS = {
    VULNERABILITIES: "VULNERABILITIES",
    LICENCES: "LICENCES"
}

const ZIP_URL = "serverless/zipFiles";

const ZipDetails = () => {
    const params = useParams();
    const {zipId} = params;

    const [{loading, data, error}] = useFetch(`${ZIP_URL}/${zipId}`);

    const {name, sha256} = data || {};

    return (
        <DetailsTabbedPage
            className="cicd-zip-details-page"
            loading={loading}
            error={error}
            id={zipId}
            title={name}
            subTitle={(
                <div className="zip-details-subtitle-wrapper">
                    <div>{`Image sha256: ${sha256}`}</div>
                </div>
            )}
            backTitle="Code zip"
            tabItems={[
                {
                    id: ZIP_TABS.VULNERABILITIES,
                    title: "Vulnerabilities",
                    component: () => <Vulnerabilities id={zipId} url={ZIP_URL} />
                },
                {
                    id: ZIP_TABS.LICENCES,
                    title: "Packages & Licenses",
                    component: () => <PackageLicenses id={zipId} url={ZIP_URL} />
                }
            ]}
            defaultSelectedTab={ZIP_TABS.VULNERABILITIES}
        />
    )
}

export default ZipDetails;