import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import Icon, { ICON_NAMES } from 'components/Icon';

export const TextElement = ({children, className}) => (
    <div className={classnames("confirmation-wizard-text", {[className]: !!className})}>{children}</div>
);

export const InnerTitle = ({children, className}) => (
    <div className={classnames("confirmation-wizard-inner-title", {[className]: !!className})}>{children}</div>
);

export const InfoMessage = ({children, className}) => (
    <div className={classnames("confirmation-wizard-info-message", {[className]: !!className})}>
        <Icon name={ICON_NAMES.EXCLAMATION_MARK_ROUND} />
        <div className="info-message">{children}</div>
        </div>
);

export const NotificationMessage = ({children, className}) => (
    <div className={classnames("confirmation-wizard-notification-message", {[className]: !!className})}>{children}</div>
);

export const LinksList = ({pathname, items, className}) => (
    <div className={classnames("confirmation-wizard-list", {[className]: !!className})}>
        {
            items.map(({name, searchParams={}, textItems}, index) => {
                const searchParamsUrl = new URLSearchParams(searchParams);
                const search = isEmpty(searchParams) ? "" : `?${searchParamsUrl.toString()}`;
                
                return (
                    <div key={index} className="link-wrapper">
                        <div className="item-bullet"></div>
                        <div className="item-text-wrapper">
                            <Link className="item-link" to={{pathname, search}} target="_blank">{name}</Link>
                            {!!textItems && <span>{`(${textItems.join(", ")})`}</span>}
                        </div>
                    </div>
                )
            })
        }
    </div>
);

export const SectionSeparator = () => <hr className="confirmation-wizard-section-separator" />