import React from "react";
import Icon, {ICON_NAMES} from "../Icon";

import './protected-pod-display.scss';

const PROTECTED_STATUS = {
    [true]: {label: "Protected", icon: ICON_NAMES.SUCCESS, className: "success"},
    [false]: {label: "Unprotected", icon: ICON_NAMES.ERROR, className: "error"},
}
const ProtectedPodDisplay = ({isProtected}) => {
    const {label, className, icon} = PROTECTED_STATUS[isProtected];

    return (
        <div className="protected-pod-display-wrapper">
            <span>{label}</span>
            <Icon name={icon} className={className}/>
        </div>
    )
};

export default ProtectedPodDisplay;