import React, { useEffect } from 'react';
import { sortBy, get } from 'lodash';
import { CLUSTER_ORCHESTRATION_TYPES, APPLICATION_NAME } from 'utils/systemConsts';
import { TextField, SelectField, CheckboxField, validators, useFormikContext, FieldLabel, FieldError } from 'components/Form';
import { usePrevious } from 'hooks';
import { ReinstallMessage, FIELD_MAPPING } from './utils';

const FormClusterProperties = props => {
    const {checkShowUpdateMessage, isReadOnlyUser, isEditForm, apiSecurityEnabledOnAccount,
        setShowNetworkSecurityStep, setShowApiSecurityStep} = props;

    const {values, errors, setFieldValue} = useFormikContext();
    
    const featuresErrorMessage = get(errors, FIELD_MAPPING.ENABLE_API_SECURITY.name);
    
    const enableKubernetesSecurity = get(values, FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.name);
    const prevEnableKubernetesSecurity = usePrevious(enableKubernetesSecurity);

    const enableApiSecurity = get(values, FIELD_MAPPING.ENABLE_API_SECURITY.name);
    const prevEnableApiSecurity = usePrevious(enableApiSecurity);

    useEffect(() => {
        if (prevEnableKubernetesSecurity !== enableKubernetesSecurity) {
            setShowNetworkSecurityStep(enableKubernetesSecurity);

            if (!enableKubernetesSecurity) {
                setFieldValue(FIELD_MAPPING.AUTO_LABEL.name, false);
                setFieldValue(FIELD_MAPPING.API_TOKEN_INJECTION.name, false);
                setFieldValue(FIELD_MAPPING.ENABLE_KUBERNETES_EVENTS.name, false);
            }
        }
    }, [prevEnableKubernetesSecurity, enableKubernetesSecurity, setShowNetworkSecurityStep, setFieldValue]);

    useEffect(() => {
        if (prevEnableApiSecurity !== enableApiSecurity) {
            setShowApiSecurityStep(enableApiSecurity);
        }
    }, [prevEnableApiSecurity, enableApiSecurity, setShowApiSecurityStep]);

    return (
        <React.Fragment>
            <div className="cluster-description-wrapper">
                {`In order to start using ${APPLICATION_NAME}, you'll need to deploy our controller on your Kubernetes environment.`}<br/>
                {`This controller will work as intermediate between ${APPLICATION_NAME} and your workloads.`}
            </div>
            <div className="cluster-name-type-wrapper">
                <TextField
                    name={FIELD_MAPPING.NAME.name}
                    label={FIELD_MAPPING.NAME.label}
                    tooltipText={FIELD_MAPPING.NAME.tooltip}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />
                <SelectField
                    name={FIELD_MAPPING.ORCHESTRATION.name}
                    label={FIELD_MAPPING.ORCHESTRATION.label}
                    tooltipText={FIELD_MAPPING.ORCHESTRATION.tooltip}
                    items={sortBy(Object.values(CLUSTER_ORCHESTRATION_TYPES), "label")}
                    clearable={false}
                    validate={validators.validateRequired}
                    disabled={isReadOnlyUser}
                />
            </div>
                <div className="cluster-features-wrapper">
                    <FieldLabel>Required features</FieldLabel>
                    <CheckboxField
                        name={FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.name}
                        title={FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.label}
                        tooltipText={FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.tooltip}
                        disabled={isReadOnlyUser}
                    />
                    {apiSecurityEnabledOnAccount &&
                        <CheckboxField
                            name={FIELD_MAPPING.ENABLE_API_SECURITY.name}
                            title={FIELD_MAPPING.ENABLE_API_SECURITY.label}
                            tooltipText={FIELD_MAPPING.ENABLE_API_SECURITY.tooltip}
                            disabled={isReadOnlyUser}
                        />
                    }
                    {!!featuresErrorMessage && <FieldError>{featuresErrorMessage}</FieldError>}
                </div>
            {checkShowUpdateMessage(values) && <ReinstallMessage isReinstall={isEditForm} />}
        </React.Fragment>
    )
}

export default FormClusterProperties;
