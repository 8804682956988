import React from 'react';
import { isEmpty } from 'lodash';
import DeleteWizardModal, { TextElement, InnerTitle, LinksList, InfoMessage, NotificationMessage } from 'components/DeleteWizardModal';
import { CI_CD_POLICY_URL } from 'layout/Policies/CiCdScanPolicy';
import { DEPLOYMNET_DEPLOYERS_PAGE_PATH } from 'layout/Deployments/Deployers';
import { APPLICATION_NAME } from 'utils/systemConsts';

const DeployersStep = ({stepData, stepProps}) => {
    const deleteItemName = stepProps.deleteItemName;
    const {deployers, cdPolicies} = stepData;
    
    return (
        <div className="delete-user-deployers">
            <React.Fragment>
                <InnerTitle>Deployers</InnerTitle>
                <TextElement>The following deployers will be deleted.</TextElement>
                <LinksList
                    pathname={DEPLOYMNET_DEPLOYERS_PAGE_PATH}
                    items={deployers.map(({name}) => ({name, searchParams: {name}}))}
                />
            </React.Fragment>
            {!isEmpty(cdPolicies) &&
                <React.Fragment>
                    <NotificationMessage className="cd-policies-message" >
                        Deleting these deployers will have an impact on the following elements:
                    </NotificationMessage>
                    <InnerTitle>Deployment Scans</InnerTitle>
                    <TextElement>{`The deployer${deployers.length > 1 ? "s" : ""} will be detached from the following Deployment scans.`}</TextElement>
                    <InfoMessage>{`If a Deploymnet scan is only attached to ${deleteItemName}, it will be deleted.`}</InfoMessage>
                    <LinksList
                        pathname={CI_CD_POLICY_URL}
                        items={cdPolicies.map(({name, id}) => ({name, searchParams: {selectedScanId: id}}))}
                    />
                </React.Fragment>
            }
        </div>
    )
}

const DeleteUserModal = ({data, onClose, onDeleteSuccess}) => {
    const {id, fullName} = data;
    const deleteItemName = `${fullName} user`;
    
    return (
        <DeleteWizardModal
            className="delete-user-modal"
            url={`users/${id}/deleteDependencies`}
            deleteUrl="users"
            onClose={onClose}
            onDeleteSuccess={onDeleteSuccess}
            deleteSteps={[
                {id: 1, title: "Deployers", dataKey: "deployers", component: DeployersStep}
            ]}
            title="Delete user"
            description={`Deleting ${deleteItemName} will have an impact on other ${APPLICATION_NAME} elements. Once deleted, the action cannot be reverted`}
            deleteItemName={deleteItemName}
            deleteItemId={id}
        />
    )
}

export default DeleteUserModal;