import {ICON_NAMES} from 'components/Icon';
import { SYSTEM_RISKS, SECRET_FINDINGS_RISKS } from 'utils/systemConsts';

export const FUNCTION_RISK_TYPES_MAPPING = {
    SECRETS: {value: "SECRETS", icon: ICON_NAMES.LOCK},
    POLICY: {value: "POLICY", icon: ICON_NAMES.PERMISSIONS},
    PUBLICLY_ACCESSIBLE: {value: "PUBLICLY_ACCESSIBLE", icon: ICON_NAMES.EARTH},
    UNUSED_FUNCTION: {value: "UNUSED_FUNCTION", icon: ICON_NAMES.GRAVE},
    DATA_ACCESS: {value: "DATA_ACCESS", icon: ICON_NAMES.DATA_ACCESS},
    VULNERABILITIES: {value: "VULNERABILITIES", icon: ICON_NAMES.BEETLE_ROUND},
    UNIDENTIFIED: {value: "UNIDENTIFIED", icon: ICON_NAMES.IMAGE_UNIDENTIFIED}
}

export const getSecretsRisk = risk => risk === SECRET_FINDINGS_RISKS.RISK_IDENTIFIED.value ? SYSTEM_RISKS.HIGH.value : null;
export const getUnusedRisk = isUnusedFunction => isUnusedFunction ? SYSTEM_RISKS.LOW.value : null;
