import React, { useState, useEffect } from 'react';
import { useFetch, usePrevious } from 'hooks';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Loader from 'components/Loader';
import Button from 'components/Button';
import FormModal from 'components/FormModal';
import { TextField, validators, ArrayField, SelectField, useFormikContext, MultiselectField, ToggleField,
    CheckboxField } from 'components/Form';
import SeccompForm from '../SeccompForm';
import { RULE_VALUES, GROUP_ITEMS, VOLUME_ITEMS, DEFAULT_RULE, CAPABILITY_ITEMS } from './utils';

export const EMPTY_RANGE_ITEM = {min: "", max: ""};

const TOGGLE_WIDTH = "250px";

const MIN_PORT = 0;
const MAX_PORT = 65535;

const RANGE_REQUIRE_VALUES = [RULE_VALUES.MustRunAs, RULE_VALUES.MayRunAs];

const MinMaxListFormField = ({name, label, checkRequired, tooltipText}) => {
    const {values} = useFormikContext();

    const parentFieldName = name.split(".")[0];
    const isRangeRequired = RANGE_REQUIRE_VALUES.includes(values[parentFieldName].rule);

    return (
        <ArrayField
            name={name}
            label={label}
            firstFieldProps={{
                component: TextField,
                key: "min",
                type: "number",
                placeholder: "Min",
                validate: value => {
                    const requiredError = validators.validateRequired(value);

                    if (!!requiredError) {
                        return (isRangeRequired && checkRequired) ? requiredError : null;
                    }
    
                    if (value < MIN_PORT) {
                        return `Value must not be lower than ${MIN_PORT}`;
                    }
                    
                    return null;
                }
            }}
            secondFieldProps={{
                component: TextField,
                key: "max",
                type: "number",
                placeholder: "Max",
                validate: value => {
                    const requiredError = validators.validateRequired(value);

                    if (!!requiredError) {
                        return (isRangeRequired && checkRequired) ? requiredError : null;
                    }
    
                    if (value > MAX_PORT) {
                        return `Value must not be higher than ${MAX_PORT}`;
                    }
                    
                    return null;
                }
            }}
            tooltipText={tooltipText}
        />
    );
}

const AdvanceFormFields = ({seccompItems: initiaLoadedSeccompItems}) => {
    const {setFieldValue} = useFormikContext();

    const [isOpenSeccompForm, setIsOpenSeccompForm] = useState(false);
    const closeSeccompForm = () => setIsOpenSeccompForm(false);

    const [newSeccumpId, setNewSeccumpId] = useState(null);

    const [{loading, data}, fetchSeccompProfiles] = useFetch("seccompProfiles", {loadOnMount: false});
    const prevLoading = usePrevious(loading);

    useEffect(() => {
        if (prevLoading && !loading) {
            setFieldValue("seccompProfile", newSeccumpId);
        }
    }, [prevLoading, loading, newSeccumpId, setFieldValue]);

    const seccompItems = !!data ? data.map(({id, name}) => ({value: id, label: name})) : initiaLoadedSeccompItems;

    return (
        <div style={{padding: "10px"}}>
            <ToggleField
                name="privileged"
                label="privileged"
                width={TOGGLE_WIDTH}
                tooltipText={<span>If set, a container in the pod can enable privileged<br />mode (for example, to access devices)</span>}
            />
            <ToggleField
                name="readOnlyRootFileSystem"
                label="readOnlyRootFileSystem"
                width={TOGGLE_WIDTH}
                tooltipText={<span>If set, containers in the pod must run<br />with a read-only root filesystem only</span>}
            />
            <ToggleField
                name="allowPrivilegeEscalation"
                label="allowPrivilegeEscalation"
                width={TOGGLE_WIDTH}
                tooltipText={<span>If set, containers in the pod can set the<br />security context (escalate privilege).<br />Default is true, if not overridden by the<br />defaultAllowPrivilegeEscalation parameter.</span>}
            />
            <ToggleField
                name="defaultAllowPrivilegeEscalation"
                label="defaultAllowPrivilegeEscalation"
                width={TOGGLE_WIDTH}
                tooltipText="Default value for the allowPrivilegeEscalation option"
            />
            <MultiselectField
                name="volumes"
                label="volumes"
                items={VOLUME_ITEMS}
                tooltipText={<span>Whitelist of allowed volume types. Use 'All' to allow all types.<br />If omitted, no volume types are allowed</span>}
            />
            <MultiselectField
                name="requiredDropCapabilities"
                label="requiredDropCapabilities"
                items={CAPABILITY_ITEMS} 
                creatable
                tooltipText={<span>List of capabilities that should be dropped for a container.<br />Use ‘All’ to drop all capabilities. If Omitted or empty, no capabilities<br />will be dropped.</span>}
            />
            <MultiselectField
                name="allowedCapabilities"
                label="allowedCapabilities"
                items={CAPABILITY_ITEMS} 
                creatable
                tooltipText={<span>A whitelist of capabilities that can be added to a container.<br />Use 'All' to allow all capabilities. If omitted or empty, no capabilities can be added.</span>}
            />
            <MultiselectField
                name="allowedProcMountTypes"
                label="allowedProcMountTypes"
                items={[
                    {value: "Default", label: "Default"},
                    {value: "Unmasked", label: "Unmasked"}
                ]}
                tooltipText={<span>A whitelist of allowed ProcMount types. If omitted or empty, only<br />the DefaultProcMountType is allowed</span>}
            />
            <MinMaxListFormField
                name="hostPorts"
                label="hostPorts"
                tooltipText={<span>A whitelist of ranges of network ports that can be<br />exposed in the host (min to max, inclusive)</span>}
            />
            <ToggleField
                name="hostNetwork"
                label="hostNetwork"
                width={TOGGLE_WIDTH}
                tooltipText={<span>Controls whether the pod may use the node<br />network namespace. Doing so gives the pod access<br />to the loopback device, services listening on<br />localhost, and could be used to snoop on<br />network activity of other pods on the same node.</span>}
            />
            <ToggleField
                name="hostPID"
                label="hostPID"
                width={TOGGLE_WIDTH}
                tooltipText={<span>Controls whether the pod containers can share the<br />host process ID namespace. Note that when paired<br />with ptrace this can be used to escalate privileges<br />outside of the container (ptrace is forbidden<br />by default).</span>}
            />
            <ToggleField
                name="hostIPC"
                label="hostIPC"
                width={TOGGLE_WIDTH}
                tooltipText={<span>Controls whether the pod containers can share the<br />host IPC namespace. Sharing the host’s IPC<br />namespace allows container processes to<br />communicate with processes on the host.</span>}
            />
            <ArrayField
                className="allowedHostPaths-field"
                name="allowedHostPaths"
                label="allowedHostPaths"
                firstFieldProps={{
                    component: SelectField,
                    key: "pathPrefix",
                    creatable: true
                }}
                secondFieldProps={{
                    component: CheckboxField,
                    key: "readOnly",
                    title: "Read only"
                }}
                tooltipText={<span>Specifies a list of host paths that are allowed to be used by hostPath volumes.<br />An empty list means there is no restriction on host paths used.<br />This is defined as a list of objects with a single pathPrefix field, which allows<br />hostPath volumes to mount a path that begins with an allowed prefix, and a readOnly<br />field indicating it must be mounted read-only</span>}
            />
            <MultiselectField
                name="forbiddenSysctls"
                label="forbiddenSysctls"
                items={[]}
                creatable={true}
                tooltipText={<span>Excludes specific sysctls. You can forbid a combination of safe and unsafe<br />sysctls in the list. To forbid setting any sysctls, use * on its own</span>}
            />
            <MultiselectField
                name="allowedUnsafeSysctls"
                label="allowedUnsafeSysctls"
                items={[]}
                creatable={true}
                tooltipText={<span>Allows specific sysctls that had been disallowed by the default<br />list, so long as these are not listed in forbiddenSysctls</span>}
            />
            <div className="psp-profile-seccomp-container">
                {loading && <Loader small={true} />}
                <SelectField
                    name="seccompProfile"
                    label="Seccomp Profile"
                    items={seccompItems}
                />
                <Button tertiary onClick={() => setIsOpenSeccompForm(true)}>Create new</Button>
            </div>
            {isOpenSeccompForm &&
                <FormModal
                    onClose={closeSeccompForm}
                    formComponent={SeccompForm}
                    formProps={{
                        initialData: {},
                        onFormSubmitSuccess: ({id}) => {
                            setNewSeccumpId(id);
                            closeSeccompForm();
                            fetchSeccompProfiles();
                        }
                    }}
                />
            }
        </div>
    );
}

const RuleAndRangesField = ({fieldValue, fieldName, items, tooltipText}) => {
    const {setFieldValue} = useFormikContext();

    const {rule} = fieldValue;
    const prevRule = usePrevious(rule);
    const isRangeRequired = RANGE_REQUIRE_VALUES.includes(rule);

    useEffect(() => {
        if (rule === prevRule) {
            return;
        }
        
        if (!!prevRule) {
            setFieldValue(`${fieldName}.ranges`, isRangeRequired ? [{min: MIN_PORT, max: MAX_PORT}] : [EMPTY_RANGE_ITEM]);
        }
        
    }, [rule, prevRule, isRangeRequired, setFieldValue, fieldName]);

    return (
        <React.Fragment>
            <SelectField
                name={`${fieldName}.rule`}
                label={fieldName}
                validate={validators.validateRequired}
                items={items}
                clearable={false}
                tooltipText={tooltipText}
            />
            {fieldValue.rule !== DEFAULT_RULE && fieldValue.rule !== RULE_VALUES.MustRunAsNonRoot &&
                <MinMaxListFormField name={`${fieldName}.ranges`} label={`${fieldName} ranges`} checkRequired />}
        </React.Fragment>
    )
}
const FormPspProperties = ({seccompItems}) => {
    const {values} = useFormikContext();
    const {runAsUser, fsGroup, supplementalGroups, runAsGroup} = values;

    const [showAdvance, setShowAdvance] = useState(false);

    return (
        <React.Fragment>
            <RuleAndRangesField
                fieldValue={runAsUser}
                fieldName="runAsUser"
                items={[
                    {value: RULE_VALUES.MustRunAs, label: RULE_VALUES.MustRunAs},
                    {value: RULE_VALUES.MustRunAsNonRoot, label: RULE_VALUES.MustRunAsNonRoot},
                    {value: RULE_VALUES.RunAsAny, label: RULE_VALUES.RunAsAny}
                ]}
                tooltipText={<span>The user ID that the containers in the pod are run with.<br />If omitted, can be any ID</span>}
            />
            <RuleAndRangesField
                fieldValue={fsGroup}
                fieldName="fsGroup"
                items={GROUP_ITEMS}
                tooltipText="The supplemental FS Group applied to volumes mounted in the pod"
            />
            <RuleAndRangesField
                fieldValue={supplementalGroups}
                fieldName="supplementalGroups"
                items={GROUP_ITEMS}
                tooltipText="Group IDs that containers can add"
            />
            <RuleAndRangesField
                fieldValue={runAsGroup}
                fieldName="runAsGroup"
                items={GROUP_ITEMS}
                tooltipText={<span>The Group ID that the containers are run with.<br />If omitted, can be any ID</span>}
            />
            <IconWithTitle
                name={showAdvance ? ICON_NAMES.MINUS : ICON_NAMES.ADD}
                title={`${showAdvance ? "Hide" : "Show"} advance options`}
                onClick={() => setShowAdvance(showAdvance => !showAdvance)}
            />
            {showAdvance && <AdvanceFormFields seccompItems={seccompItems} />}
        </React.Fragment>
    )
}

export default FormPspProperties;