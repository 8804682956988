import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pickBy } from 'lodash';
import { FETCH_METHODS, usePrevious } from 'hooks';
import FormWrapper, { validators, TextField, useFormikContext, SelectField, TextAreaField, RadioField } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';
import { APP_PATHS, REGULAR_USER_ROLES_ITEMS, GLOBAL_ADMIN_USER_ROLES_ITEMS } from 'utils/systemConsts';

export const USER_STATUS_ENABLED = "ENABLED";

export const SERVICE_USER_ROLES = [
    REGULAR_USER_ROLES_ITEMS.SERVICE.value,
    REGULAR_USER_ROLES_ITEMS.CI_CD_SCANNER.value,
    GLOBAL_ADMIN_USER_ROLES_ITEMS.SELF_PROVISIONING.value,
    GLOBAL_ADMIN_USER_ROLES_ITEMS.CISCO_SUBSCRIBER.value,
    GLOBAL_ADMIN_USER_ROLES_ITEMS.LIGHTSPIN_ADMIN.value
];

const roleValidator = (role) => {
  if (role === GLOBAL_ADMIN_USER_ROLES_ITEMS.K8S_CLUSTERS_MANAGER.value ||
      role === GLOBAL_ADMIN_USER_ROLES_ITEMS.API_SEC_BACKEND.value ||
      role === GLOBAL_ADMIN_USER_ROLES_ITEMS.LIGHTSPIN_BACKEND.value)
    return validators.validateRequired;
  return validators.validateEmail;
}

const RoleForm = ({role, isEditForm}) => (
    <React.Fragment>
        <TextField name="fullName" label="Name" validate={validators.validateRequired} />
        {!SERVICE_USER_ROLES.includes(role) &&
            <TextField
                type="email"
                name="email"
                label="Email"
                validate={roleValidator(role)}
                disabled={isEditForm}
        />}
        <TextAreaField name="description" label="Info (optional)" />
        <RadioField
            name="status"
            label="Status"
            items={[
                {value: USER_STATUS_ENABLED, label: "Active"},
                {value: "DISABLED", label: "Inactive"}
            ]}
            horizontal={true}
        />
    </React.Fragment>
);

const FormFields = ({isEditForm, isGlobalAdminPage}) => {
    const {values, setFieldValue} = useFormikContext();

    const {role} = values;
    const prevRole = usePrevious(role);

    const roleItems = Object.values(isGlobalAdminPage ? GLOBAL_ADMIN_USER_ROLES_ITEMS : REGULAR_USER_ROLES_ITEMS);

    useEffect(() => {
        if (role !== prevRole && SERVICE_USER_ROLES.includes(role)) {
            setFieldValue("email", "");
        }
    }, [role, prevRole, setFieldValue]);

    return (
        <React.Fragment>
            <SelectField 
                name="role"
                label="Role"
                items={roleItems}
                validate={validators.validateRequired}
                disabled={isEditForm && SERVICE_USER_ROLES.includes(role)}
                clearable={false}
            />
            {!!role && <RoleForm role={role} isEditForm={isEditForm} />}
        </React.Fragment>
    )
}

const UserForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const initialValues = {
        role: "",
        fullName: "",
        email: "",
        description: "",
        status: USER_STATUS_ENABLED,
        ...initialData
    };

    initialValues.description = initialValues.description || ""; //should not be null

    const isEditForm = initialValues && initialValues.id;

    const location = useLocation();
    const isGlobalAdminPage = location.pathname === APP_PATHS.MANAGE_ADMIN_USERS.path;
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>{`${isEditForm ? "Edit" : "New"} User`}</Text>
            <FormWrapper
                initialValues={initialValues}
                submitUrl={isGlobalAdminPage ? "admin/users" : "users"}
                getSubmitParams={formValues => {
                    const {id, ...formData} = formValues;

                    delete formData.accountId;

                    if (isEditForm) {
                        delete formData.email;
                        delete formData.normalizedRole;
                        delete formData.lastLogin;
                    }

                    const submitData = pickBy(formData, value => value !== "");

                    return !isEditForm ? {submitData} : {
                        method: FETCH_METHODS.PUT,
                        formatUrl: () => `users/${id}`,
                        submitData
                    }
                }}
                onSubmitSuccess={data => onFormSubmitSuccess(data, !isEditForm)}
                onDirtyChanage={onDirtyChanage}
            >
                <FormFields isEditForm={isEditForm} isGlobalAdminPage={isGlobalAdminPage} />
            </FormWrapper>
        </div>
    )
}

export default UserForm;