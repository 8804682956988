import React from 'react';
import { isEmpty } from 'lodash';
import DisplaySection from 'components/DisplaySection';
import KeyValueList from 'components/KeyValueList';
import TitleWithRiskDisplay from 'components/TitleWithRiskDisplay';
import DoublePaneDisplay from 'components/DoublePaneDisplay';
import ProtectedPodDisplay from "components/ProtectedPodDisplay";
import UnprotectedPodReason from "components/UnprotectedPodReason";
import { LabelsDisplay } from 'components/LabelTag';
import RuleNamesLinks from 'layout/Policies/RuleNamesLinks';
import { WorkloadRuleResultDisplay, ViolationReasonsDisplay } from 'layout/Runtime/Workloads/utils';
import { DEPLOYMENT_POLICY_URL } from 'layout/Policies/DeploymentPolicy';
import RiskReasonsDisplay from './RiskReasonsDisplay';
import IdentificationContent from './IdentificationContent';

import './tab-details.scss';

const TabDetails = ({data, telemetryId, openSecurityContextTab}) => {
    const {cluster, namespace, environment, risk, podIdentification, pod, violation, podRuntimeInfo, isPodProtected, unprotectedReasons, podsLicenses} = data;
    const {level: riskLevel} = risk;
    const {defaultRule, userRule, unidentifiedPodsRule} = violation || {};
    const {name: podName, permissionOwnerName} = pod || {};
    
    return (
        <DoublePaneDisplay
            className="workload-details-tab-wrapper"
            leftPane={() => (
                <React.Fragment>
                    <DisplaySection title={<TitleWithRiskDisplay title="Workload risk:" risk={riskLevel} />}>
                        <RiskReasonsDisplay
                            permissionOwnerName={permissionOwnerName}
                            clusterId={cluster.id}
                            podName={podName}
                            telemetryId={telemetryId}
                        />
                    </DisplaySection>
                    {!isEmpty(podsLicenses) &&
                        <DisplaySection title="Workload licenses:">
                            <LabelsDisplay labels={podsLicenses} wrapLabels />
                        </DisplaySection>
                    }
                </React.Fragment>
            )}
            rightPane={() => (
                <React.Fragment>
                    <DisplaySection title="Workload details">
                        <KeyValueList items={[
                            {label: "Cluster", value: !!cluster ? cluster.name : ""},
                            {label: "Namespace", value: !!namespace ? namespace.name : ""},
                            {label: "Environment", value: !!environment ? environment.name : ""}
                        ]} />
                        <KeyValueList items={[
                            {
                                label: "Rule name",
                                value: (
                                    <RuleNamesLinks
                                        pathname={DEPLOYMENT_POLICY_URL}
                                        defaultRule={defaultRule}
                                        implicitRule={unidentifiedPodsRule}
                                        userRule={userRule}
                                    />
                                )
                            },
                            {
                                label: "Rule action",
                                value: <WorkloadRuleResultDisplay tooltipId="runtime-workload-action" violation={violation} />
                            },
                            {
                                label: "Violation reason",
                                value: <ViolationReasonsDisplay violation={violation} podRuntimeInfo={podRuntimeInfo} withViolationLink onPspClick={openSecurityContextTab} />
                            }
                        ]} />
                        <KeyValueList items={[
                            {
                                label: <ProtectedPodDisplay isProtected={isPodProtected}/>,
                                value: isPodProtected ? " " : <UnprotectedPodReason unprotectedPodReasons={unprotectedReasons} podName={podName} namespace={namespace.name}/>}
                        ]} />
                    </DisplaySection>
                    <DisplaySection title="Workload trust">
                        <IdentificationContent {...podIdentification} />
                    </DisplaySection>
                </React.Fragment>
            )}
        />
    )
}

export default TabDetails;