import React, { Component } from 'react';
import classnames from 'classnames';
import IconWithTitle from 'components/IconWithTitle';
import CloseButton from 'components/CloseButton';

import './modal-button.scss';

export default class ModalButton extends Component {
    state = {
        expanded: this.props.initialExpanded || false
    }

    toggleExpanded = () => {
        const {expanded} = this.state;
        const updated = !expanded;

        this.setState({expanded: updated}, () => {
            const {onExandToggle} = this.props;
            if (!!onExandToggle) {
                onExandToggle(updated);
            }
        });
    }

    render() {
        const {expanded} = this.state;
        const {className, buttonName, buttonTitle, expandedContent: ExpandedContent, notificationText} = this.props;
        
        return (
            <div className={classnames("button-modal-container", {"expanded": expanded}, {[className]: !!className})}>
                {!!notificationText && !expanded && <div className="notification-container">{notificationText}</div>}
                <IconWithTitle className="main-button" name={buttonName} title={buttonTitle} onClick={this.toggleExpanded} />
                {expanded &&
                    <React.Fragment>
                        <CloseButton onClick={this.toggleExpanded} />
                        <ExpandedContent toggleExpanded={this.toggleExpanded} />
                    </React.Fragment>
                }
            </div>
        );
    }
}