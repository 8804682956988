import React, { useState } from 'react';
import TabbedPageWithRouting from 'components/TabbedPageWithRouting';
import RisksScan, { RISKS_SCAN_PAGE_PATH } from './RisksScan';
import Permissions, { PERMISSIONS_PAGE_PATH } from './Permissions';
import RiskManagement, { RISK_MANAGEMENT_PAGE_PATH } from './RiskManagement';

const RiskAssessment = () => {
    const [hideTabs, setHideTabs] = useState(false);

    return (
        <TabbedPageWithRouting
            redirectFrom="/riskAssessment"
            redirectTo={RISKS_SCAN_PAGE_PATH}
            items={[
                {to: RISKS_SCAN_PAGE_PATH, title: "Runtime Scans", component: () => <RisksScan setHideTabs={setHideTabs} />},
                {to: PERMISSIONS_PAGE_PATH, title: "Permissions (RBAC)", component: Permissions},
                {to: RISK_MANAGEMENT_PAGE_PATH, title: "Risk Management", component: RiskManagement}
            ]}
            customCheckHideTabs={() => hideTabs}
        />
    );
}

export default RiskAssessment;