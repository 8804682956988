import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useExpanded,
} from "react-table";
import { isEmpty, pickBy, isNull, isEqual, isArray } from "lodash";
import classnames from "classnames";
import { useAuthState } from "context/AuthProvider";
import { useFetch, usePrevious } from "hooks";
import Loader from "components/Loader";
import Icon, { ICON_NAMES } from "components/Icon";
import IconWithTitle from "components/IconWithTitle";
import DropdownButton from "components/DropdownButton";
import Checkbox from "components/Checkbox";
import Arrow from "components/Arrow";
import { queryString } from "utils/apiUtils";
import { saveInLocalStorage, getFromLocalStorage } from "utils/generalUtils";
import Pagination from "./Pagination";
import ColumnsSelectPanel from "./ColumnsSelectPanel";
import * as tableUtils from "./utils";
import TimeFilter, {
  useTimeFilter,
  useStanaloneTimeFilter,
} from "./TimeFilter";
import FilterFormWrapper from "./FilterForm";

import "./table.scss";

export {
  tableUtils,
  TimeFilter,
  useTimeFilter,
  useStanaloneTimeFilter,
  FilterFormWrapper
};

const RowSelectCheckbox = React.memo(
  ({ checked, indeterminate, onChange }) => (
    <Checkbox
      className="row-select-checkbox"
      checked={checked}
      halfSelected={indeterminate}
      onChange={onChange}
    />
  ),
  (prevProps, nextProps) => {
    const { checked: prevChecked, indeterminate: prevIndeterminate } =
      prevProps;
    const { checked, indeterminate } = nextProps;

    const shouldRefresh =
      checked !== prevChecked || indeterminate !== prevIndeterminate;

    return !shouldRefresh;
  }
);

const COLUMNS_ICON_CLASSNAME = "table-columns-class";

const EXPANDER_COLUMN_ID = "EXPANDER";
const SELECTOR_COLUMN_ID = "SELECTOR";
const ACTIONS_COLUMN_ID = "ACTIONS";

const EXPANDER_WIDTH = 40;
const SELECTOR_WIDTH = 30;
const ACTIONS_WIDTH = 40;
const ACTIONS_DRILLDOWN_WIDTH = 40;

const STATIC_COLUMN_IDS = [
  EXPANDER_COLUMN_ID,
  SELECTOR_COLUMN_ID,
  ACTIONS_COLUMN_ID,
];
const STATIC_HEADER_IDS = STATIC_COLUMN_IDS.map((id) => `${id}_placeholder`);

const Table = (props) => {
  const {
    columns,
    url,
    defaultSortBy: defaultSortByItems,
    filters,
    customOnRefresh,
    isLoading,
    innerRowComponenet: InnerRowComponenet,
    innerRowComponentProps,
    itemActions = [],
    actionsComponent: ActionsComponent,
    actionsCellWidth,
    exportToExcel = false,
    exportToExcelCustomUrl,
    withMultiSelect = false,
    name,
    onLineClick,
    onLineDrilldown,
    refreshTimestamp,
    rowSelectActionComponent: RowSelectActionComponent,
    formatFetchedData,
    onFetchedDataChange,
    onFetchError,
    withPagination = true,
    data: externalData,
    isDrilldownDisabled,
    emptyMessage = "No results",
    hideColumnControl,
    markedRowIds = [],
    onRowSelect,
    initialSelectedIds = [],
    hideRefresh,
    emptyTableCustomDisplayComponent: EmptyTableCustomDisplayComponent,
    initialExpandedRow = {},
  } = props;

  const [{ loading, data, error }, fetchData] = useFetch(url, {
    loadOnMount: false,
    formatFetchedData,
  });

  const prevData = usePrevious(data);

  const fetchedData = !!formatFetchedData ? formatFetchedData(data) : data;
  const dataJson = JSON.stringify(fetchedData || []);
  const externalDataJson = JSON.stringify(externalData);
  const tableData = useMemo(
    () => (!!url ? JSON.parse(dataJson) : JSON.parse(externalDataJson)),
    [url, dataJson, externalDataJson]
  );

  const defaultSortBy = useMemo(
    () => defaultSortByItems || [],
    [defaultSortByItems]
  );

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing,
      width: 100,
    }),
    []
  );

  const withRowActions =
    !isEmpty(itemActions) || !!onLineDrilldown || !!ActionsComponent;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    allColumns,
    canPreviousPage,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    toggleSortBy,
    setHiddenColumns,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = useTable(
    {
      autoResetPage: false,
      columns,
      getRowId: (rowData, rowIndex) => !!rowData.id ? rowData.id : rowIndex,
      data: tableData,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: defaultSortBy,
        selectedRowIds: initialSelectedIds.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {}),
        expanded: initialExpandedRow,
      },
      manualPagination: true,
      pageCount: -1,
      manualSortBy: true,
      disableMultiSort: true,
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the framework columns to not be resizable 
        headerGroups[0].headers.forEach((header) => {
          if (STATIC_HEADER_IDS.includes(header.originalId)) {
            header.canResize = false;
          }
        });
      });

      hooks.visibleColumns.push((columns) => {
        const updatedColumns = columns;

        if (withMultiSelect) {
          updatedColumns.unshift({
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <RowSelectCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            id: SELECTOR_COLUMN_ID,
            Cell: ({ row }) => (
              <RowSelectCheckbox {...row.getToggleRowSelectedProps()} />
            ),
            disableResizing: true,
            minWidth: SELECTOR_WIDTH,
            width: SELECTOR_WIDTH,
            maxWidth: SELECTOR_WIDTH,
            className: "center",
          });
        }

        if (!!InnerRowComponenet) {
          updatedColumns.unshift({
            Header: () => null, // No header
            id: EXPANDER_COLUMN_ID,
            Cell: ({ row }) => {
              const { onClick } = row.getToggleRowExpandedProps();

              return (
                <div style={{ paddingLeft: `${row.depth * 2}rem` }}>
                  <Arrow
                    name={row.isExpanded ? "top" : "bottom"}
                    className="row-expand-icon"
                    onClick={onClick}
                    small
                  />
                </div>
              );
            },
            disableResizing: true,
            minWidth: EXPANDER_WIDTH,
            width: EXPANDER_WIDTH,
            maxWidth: EXPANDER_WIDTH,
          });
        }

        if (withRowActions) {
          let actionsWidth =
            !!onLineDrilldown && !isEmpty(itemActions)
              ? ACTIONS_DRILLDOWN_WIDTH
              : ACTIONS_WIDTH;
          actionsWidth = !!actionsCellWidth ? actionsCellWidth : actionsWidth;

          updatedColumns.push({
            Header: () => null, // No header
            id: ACTIONS_COLUMN_ID,
            accessor: (original) => (
              <div className="actions-column-container">
                {!!ActionsComponent && <ActionsComponent original={original} />}
                {!isEmpty(itemActions) && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    <DropdownButton
                      toggleButton={
                        <Icon
                          className="cell-actions-icon"
                          name={ICON_NAMES.HOVER}
                        />
                      }
                      className="table-cell-action-container"
                    >
                      {itemActions.map(
                        (
                          { title, getTitle, onClick, checkDisabled },
                          index
                        ) => {
                          const isDisabled =
                            !!checkDisabled && checkDisabled(original);

                          return (
                            <div
                              key={index}
                              className={classnames("table-item-action", {
                                disabled: isDisabled,
                              })}
                              onClick={() => !isDisabled && onClick(original)}
                            >
                              {!!getTitle ? getTitle(original) : title}
                            </div>
                          );
                        }
                      )}
                    </DropdownButton>
                  </div>
                )}
                {!!onLineDrilldown && (
                  <div>
                    <Arrow
                      name="right"
                      onClick={() => onLineDrilldown(original)}
                      disabled={
                        !!isDrilldownDisabled && isDrilldownDisabled(original)
                      }
                    />
                  </div>
                )}
              </div>
            ),
            disableSortBy: true,
            disableResizing: true,
            minWidth: actionsWidth,
            width: actionsWidth,
            maxWidth: actionsWidth,
          });
        }

        return updatedColumns;
      });
    }
  );

  const cleanFilters = pickBy(
    filters,
    (value) => !isNull(value) && value !== ""
  );
  const filtersJson = JSON.stringify(cleanFilters);
  const prevFiltersJson = usePrevious(filtersJson);

  const { id: sortKey, desc: sortDesc } = !isEmpty(sortBy) ? sortBy[0] : {};

  const getQueryParams = useCallback(() => {
    const queryParams = {
      ...(JSON.parse(filtersJson) || {}),
    };

    if (withPagination) {
      queryParams.offset = pageIndex * pageSize;
      queryParams.maxResults = pageSize;
    }

    if (!isEmpty(sortKey)) {
      queryParams.sortKey = sortKey;
      queryParams.sortDir = sortDesc ? "DESC" : "ASC";
    }

    return queryParams;
  }, [pageIndex, pageSize, sortKey, sortDesc, filtersJson, withPagination]);

  const doFetchWithQueryParams = useCallback(() => {
    if (isLoading) {
      return;
    }

    fetchData({ queryParams: getQueryParams() });
  }, [fetchData, getQueryParams, isLoading]);

  useEffect(() => {
    if (prevFiltersJson !== filtersJson && pageIndex !== 0) {
      gotoPage(0);

      return;
    }

    if (!!url) {
      doFetchWithQueryParams();
    }
  }, [
    doFetchWithQueryParams,
    refreshTimestamp,
    url,
    filtersJson,
    prevFiltersJson,
    gotoPage,
    pageIndex,
  ]);

  const initializeColumns = useRef(false);

  const { id: userId } = useAuthState();

  useEffect(() => {
    if (initializeColumns.current) {
      return;
    }

    initializeColumns.current = true;

    const defaultHiddenIds = allColumns
      .filter((column) => column.hide)
      .map((column) => column.id);
    const savedHiddenIds = getFromLocalStorage(userId, name) || [];
    const alwaysShowIds = allColumns
      .filter((column) => column.alwaysShow)
      .map((column) => column.id);

    const hideIds = isEmpty(savedHiddenIds) ? defaultHiddenIds : savedHiddenIds;

    setHiddenColumns(
      hideIds.filter((hideId) => !alwaysShowIds.includes(hideId))
    );

    return function cleanup() {
      if (!initializeColumns.current) {
        return;
      }

      const hideIds = allColumns
        .filter((column) => !column.isVisible)
        .map((column) => column.id);
      saveInLocalStorage(userId, name, hideIds);
    };
  }, [userId, name, allColumns, setHiddenColumns]);

  const [showColumnsPanel, setShowColumnsPanel] = useState(false);

  useEffect(() => {
    if (!isEqual(data, prevData) && !!onFetchedDataChange) {
      onFetchedDataChange(data);
    }
  }, [data, prevData, onFetchedDataChange]);

  useEffect(() => {
    if (!!error && !!onFetchError) {
      onFetchError();
    }
  }, [error, onFetchError]);

  const selectedRowIdsJSON = JSON.stringify(Object.keys(selectedRowIds));
  const prevSelectedRowIdsJSON = usePrevious(selectedRowIdsJSON);
  useEffect(() => {
    if (!!onRowSelect && prevSelectedRowIdsJSON !== selectedRowIdsJSON) {
      onRowSelect(JSON.parse(selectedRowIdsJSON));
    }
  }, [selectedRowIdsJSON, prevSelectedRowIdsJSON, onRowSelect]);

  if (!!error) {
    return null;
  }

  const SortIcon = ({ id, isSorted, isSortedDesc }) => (
    <Icon
      className={classnames(
        "sort-icon",
        { sorted: isSorted },
        { rotate: isSortedDesc && isSorted }
      )}
      name={ICON_NAMES.TRIANGLE_ARROW_DOWN}
      onClick={() => {
        toggleSortBy(id, isSorted ? !isSortedDesc : false);
      }}
    />
  );

  const headerColumnNames = (
    headerGroups.length > 1
      ? headerGroups[0].headers.map((header) => {
        if (STATIC_HEADER_IDS.includes(header.originalId)) {
          return null;
        }

        return header.Header;
      })
      : []
  ).filter((item) => !isNull(item));

  const tableIsLoading = loading || isLoading;

  if (
    !!EmptyTableCustomDisplayComponent &&
    !tableIsLoading &&
    isArray(tableData) &&
    isEmpty(tableData)
  ) {
    return <EmptyTableCustomDisplayComponent />;
  }

  return (
    <React.Fragment>
      <div className="table-wrapper-new">
        <div className="table-action-buttons-container">
          {!!RowSelectActionComponent && (
            <div className="selected-rows-actions-container">
              <RowSelectActionComponent
                selectedIds={Object.keys(selectedRowIds)}
                disabled={isEmpty(selectedRowIds)}
              />
            </div>
          )}
          {!!url && !hideRefresh && (
            <IconWithTitle
              name={ICON_NAMES.REFRESH}
              title="Refresh"
              onClick={() =>
                !!customOnRefresh ? customOnRefresh() : doFetchWithQueryParams()
              }
            />
          )}
          {(exportToExcel || !!exportToExcelCustomUrl) && (
            <a
              href={`/api/${exportToExcelCustomUrl ? exportToExcelCustomUrl : url
                }?${queryString({
                  ...getQueryParams(),
                  ...(exportToExcelCustomUrl ? {} : { downloadAsXlsx: true }),
                })}`}
              className="table-download-xml"
              download
            >
              <IconWithTitle
                name={ICON_NAMES.DOWNLOAD_XML}
                title="Export to Excel"
              />
            </a>
          )}
          {!hideColumnControl && (
            <IconWithTitle
              className={COLUMNS_ICON_CLASSNAME}
              name={ICON_NAMES.COLUMNS}
              title="Columns"
              onClick={() => setShowColumnsPanel(!showColumnsPanel)}
            />
          )}
        </div>
        {showColumnsPanel && (
          <ColumnsSelectPanel
            headerColumnNames={headerColumnNames}
            columns={allColumns.filter(
              (column) => !STATIC_COLUMN_IDS.includes(column.id)
            )}
            onClose={() => setShowColumnsPanel(false)}
            columnsIconClassName={COLUMNS_ICON_CLASSNAME}
          />
        )}
        {isEmpty(page) && tableIsLoading && (
          <div className="table-no-header-loader-container">
            <Loader />
          </div>
        )}
        {isEmpty(page) && !tableIsLoading && (
          <div className="table-empty-results">{emptyMessage}</div>
        )}
        {!isEmpty(page) && (
          <div className="table-container">
            <div className="table" {...getTableProps()}>
              <div className="table-head">
                {headerGroups.map((headerGroup) => {
                  return (
                    <div
                      className={classnames("table-tr", {
                        "with-row-actions": withRowActions,
                      })}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => {
                        return (
                          <div
                            className="table-th"
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                            {(column.canSort || column.defaultCanSort) && (
                              <SortIcon
                                id={column.id}
                                isSorted={column.isSorted}
                                isSortedDesc={column.isSortedDesc}
                              />
                            )}
                            {column.canResize && (
                              <div
                                {...column.getResizerProps()}
                                className={classnames("resizer", {
                                  isResizing: column.isResizing,
                                })}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <div className="table-body" {...getTableBodyProps()}>
                <div className="table-tr-wrapper">
                  {page.map((row) => {
                    prepareRow(row);

                    return (
                      <React.Fragment key={row.id}>
                        <div
                          className={classnames(
                            "table-tr",
                            { "with-row-actions": withRowActions },
                            { clickable: !!onLineClick },
                            { selected: markedRowIds.includes(row.id) },
                            { expanded: row.isExpanded && row.depth > 0 }
                          )}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            const { className, id } = cell.column;
                            const cellClassName = classnames(
                              "table-td",
                              { [className]: className },
                              { "is-static": STATIC_COLUMN_IDS.includes(id) }
                            );

                            const isTextValue = !!cell.column.accessor;
                            const columnId = cell.column.id;

                            return (
                              <div
                                className={cellClassName}
                                {...cell.getCellProps()}
                                onClick={() => {
                                  if (columnId === SELECTOR_COLUMN_ID) {
                                    //disable line click when checkbox select
                                    return;
                                  }

                                  if (!!onLineClick) {
                                    onLineClick(row.original, { columnId });
                                  }
                                }}
                              >
                                {isTextValue ? cell.value : cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                        {row.isExpanded &&
                          !!InnerRowComponenet &&
                          (!row.subRows || row.subRows.length === 0) && (
                            <div className="table-tr">
                              <div className="table-td inner-row-td">
                                <InnerRowComponenet
                                  data={row.original}
                                  tableRowData={row}
                                  {...innerRowComponentProps}
                                />
                              </div>
                            </div>
                          )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {withPagination && (
        <Pagination
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          loading={loading}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(Table, (prevProps, nextProps) => {
  const {
    filters: prevFilters,
    isLoading: prevIsLoading,
    refreshTimestamp: prevRefreshTimestamp,
    data: prevData,
    markedRowIds: prevMarkedRowIds,
  } = prevProps;
  const { filters, isLoading, refreshTimestamp, data, markedRowIds } =
    nextProps;

  const shouldRefresh =
    !isEqual(prevFilters, filters) ||
    prevIsLoading !== isLoading ||
    !isEqual(prevMarkedRowIds, markedRowIds) ||
    prevRefreshTimestamp !== refreshTimestamp ||
    !isEqual(prevData, data);

  return !shouldRefresh;
});
