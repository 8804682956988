
import { useMemo, useEffect, useState, useRef, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { isEmpty } from "lodash";
import Table from "components/Table";
import Button from "components/Button";
import { useAuthState } from "context/AuthProvider";
import { useFetch, FETCH_METHODS, usePrevious } from "hooks";
import { formatDate } from "utils/generalUtils";
import { CLUSTER_ORCHESTRATION_TYPES, CIS_SCAN_STATES, IN_PROGRESS_CIS_SCAN_STATES } from "utils/systemConsts";
import { ScanResultsCount, ActionItemsStatusIcon } from '../utils';
import ScanProgressBar from '../ScanProgressBar';

const ClustersTable = ({refreshSummaryData}) => {
  const url = `k8sCISBenchmark`;

  const [tableData, setTableData] = useState([]);
  const [{loading, data}] = useFetch(url);
  const prevLoading = usePrevious(loading)

  const [{loading: polling, data: pollingData}, pollData] = useFetch(url, {loadOnMount: false});
  const prevPolling = usePrevious(polling);

  const fetcherRef = useRef(null);

  useEffect(() => {
      return function cleanup() {
          if (fetcherRef.current) {
              clearTimeout(fetcherRef.current);
          }
      };
  }, []);

  const updateTableDataAndPolling = useCallback(tableData => {
    setTableData(tableData || []);
    refreshSummaryData();

    if (fetcherRef.current) {
      clearTimeout(fetcherRef.current);
    }

    const pollingIds = tableData?.filter(({scanState, agentActive}) =>
      IN_PROGRESS_CIS_SCAN_STATES.includes(scanState) && agentActive).map(({id}) => id);
    
    if (!isEmpty(pollingIds)) {
      fetcherRef.current = setTimeout(() => pollData({queryParams: {clusterIds: pollingIds}}), 4000);
    }
  }, [pollData, refreshSummaryData]);

  useEffect(() => {
      if (prevLoading && !loading) {
        updateTableDataAndPolling(data || []);
      }
  }, [prevLoading, data, loading, updateTableDataAndPolling]);
  
  useEffect(() => {
    if (prevPolling && !polling) {
        const updatedData =  tableData.map(item => {
            const updatedItem = pollingData.find(resDataItem => item.id === resDataItem.id);
            
            return !!updatedItem ? {...item, ...updatedItem} : item;
        }) || [];

        updateTableDataAndPolling(updatedData);
    }
  }, [prevPolling, polling, pollingData, tableData, updateTableDataAndPolling]);

  const { path } = useRouteMatch();
  const history = useHistory();
  const { isReadOnlyUser } = useAuthState();
  const [{ loading: scanRequestLoading, data: scanRequestData, error: scanRequestError }, scanCluster] = useFetch(url, {
    loadOnMount: false,
  });
  const prevScanRequestLoading = usePrevious(scanRequestLoading);

  useEffect(() => {
    if (prevScanRequestLoading && !scanRequestLoading && !scanRequestError) {
      const rescanItemIndex = tableData.findIndex(({id}) => id === scanRequestData?.id);
      
      updateTableDataAndPolling([
        ...tableData.slice(0, rescanItemIndex),
        {...tableData[rescanItemIndex], scanState: CIS_SCAN_STATES.STARTING.value},
        ...tableData.slice(rescanItemIndex + 1)
      ]);
    }
  }, [prevScanRequestLoading, scanRequestLoading, scanRequestData, scanRequestError, updateTableDataAndPolling, tableData]);

  const columns = useMemo(
    () => [
      {
        Header: "Cluster",
        id: "cluster",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "Scan Time",
        id: "scanTime",
        accessor: (original) => formatDate(original.scanTime),
        disableSortBy: true,
      },
      {
        Header: "Scan Progress",
        id: "scanProgress",
        Cell: ({ row }) => {
          const {scanState, numberOfNodes, numberOfScannedNodes} = row.original;

          return (
            <ScanProgressBar
              scanState={scanState}
              numberOfNodes={numberOfNodes}
              numberOfScannedNodes={numberOfScannedNodes}
            />
          )
        },
        disableSortBy: true,
      },
      {
        Header: "Compliance",
        id: "compliance",
        Cell: ({ row }) => `${row.original?.compliance}%`,
      },
      {
        Header: "Results",
        id: "results",
        Cell: ({ row }) => (
          <ScanResultsCount
            failedCount={row.original.numberOfItemsFailed}
            passedCount={row.original.numberOfItemsPassed}
            infoCount={row.original.numberOfInfoItems}
            tooltipId={row.original.id}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "Action Items",
        id: "actionItems",
        Cell: ({ row }) => (
          <ActionItemsStatusIcon count={row.original.numberOfActionItems} />
        ),
      },
      {
        Header: "Orchestration",
        id: "orchestration",
        Cell: ({ row }) =>
          CLUSTER_ORCHESTRATION_TYPES[row.original.orchestration]?.label,
        disableSortBy: true,
      },
    ],
    []
  );
  
  return (
    <Table
      data={tableData}
      columns={columns}
      hideRefresh
      isLoading={scanRequestLoading || loading}
      hideColumnControl
      onLineClick={(row) => history.push(`${path}/${row.id}`)}
      actionsCellWidth={100}
      actionsComponent={({ original }) => {
        const { id, agentActive } = original;
        
        return (
          <div className="scan-button-wrapper">
            {
              <Button
                tertiary
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  
                  scanCluster({
                    method: FETCH_METHODS.POST,
                    formatUrl: (url) => `${url}/${id}`,
                  });
                }}
                disabled={isReadOnlyUser || !agentActive}
              >
                {"Rescan"}
              </Button>
            }
          </div>
        );
      }}
    />
  );
};

export default ClustersTable;
