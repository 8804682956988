import { ACCOUNT_TIERS_ITEMS, ACCOUNT_MODES_ITEMS } from 'utils/systemConsts';
import { create } from './utils';
import { TIME_RANGER_MAP } from './TimeRangeProvider';

export const DISPLAY_CONFIG_ACTIONS = {
    SET_DISPLAY_CONFIG: "SET_DISPLAY_CONFIG",
    SET_DISPLAY_CONFIG_LOADING: "SET_DISPLAY_CONFIG_LOADING",
    TOGGLE_MAP_LEGEND_DISPLAY: "TOGGLE_MAP_LEGEND_DISPLAY",
    UPDATE_MAP_FILTERS: "UPDATE_MAP_FILTERS",
    TOGGLE_PERMISSIONS_INCLUDE_SYSTEM_OWNERS: "TOGGLE_PERMISSIONS_INCLUDE_SYSTEM_OWNERS",
    HIDE_TRIAL_ERROR_NOTIFICATION: "HIDE_TRIAL_ERROR_NOTIFICATION",
    UPDATE_USAGE_DATA: "UPDATE_USAGE_DATA"
};

const initialState = {
    loadingDisplay: true,
    //permissions/account type display:
    showApiSecurity: false,
    showServerlessSecurity: false,
    showAccountConnections: false,
    isAgentAccessAllowed: false,
    isTrialAccount: false,
    isEnterpriseAccount: false,
    isProAccount: false,
    hideTrialErrorNotification: false,
    usageStatus: {},
    accountTypeDisplay: null,
    //user controlled display:
    showMapLegend: true,
    mapFilters: {
        quickFilters: {
            showOnlyAppsWithViolations: true,
            showOnlyAppsWithConnections: true,
            showOnlyConnectionsBetweenEnvironments: false,
            showOnlyConnectionsWithViolations: false,
            ignoreExternalConnections: false,
            groupAppsOnTheSameEnvironment: true
        },
        workloads: {
            apps: [],
            excludeApps: [],
        },
        environments: [],
        labels: [],
        namespacesFilter: {
            cluster: null,
            namespaces: []
        },
        timeFilter: {
            selected: TIME_RANGER_MAP["5MIN"].value,
            startTime: null,
            endTime: null
        }
    },
    permissionsIncludeSystemOwners: false
};

const AGENT_ALLOWED_MODES = [ACCOUNT_MODES_ITEMS.NORMAL.value, ACCOUNT_MODES_ITEMS.MANAGED.value];

const reducer = (state, action) => {
    switch (action.type) {
        case DISPLAY_CONFIG_ACTIONS.SET_DISPLAY_CONFIG: {
            const {apiSecurity, accountTier, permissionsMode, serverlessSecurity, usageStatus={}} = action.payload;
            const showAccountConnections = accountTier !== ACCOUNT_TIERS_ITEMS.REVEAL.value;
            const isTrialAccount = accountTier === ACCOUNT_TIERS_ITEMS.TRIAL.value;
            const isEnterpriseAccount = accountTier === ACCOUNT_TIERS_ITEMS.ENTERPRISE.value;
            const isProAccount = accountTier === ACCOUNT_TIERS_ITEMS.PRO.value;

            return {
                ...state,
                loadingDisplay: false,
                showApiSecurity: apiSecurity === "ENABLED",
                showServerlessSecurity: serverlessSecurity === "ENABLED",
                showAccountConnections,
                isAgentAccessAllowed: checkIsAgentAccessAllowed(permissionsMode),
                isTrialAccount,
                isEnterpriseAccount,
                isProAccount,
                usageStatus,
                accountTypeDisplay: `${(ACCOUNT_TIERS_ITEMS[accountTier] || {})["label"]} account`
            };
        }
        case DISPLAY_CONFIG_ACTIONS.TOGGLE_MAP_LEGEND_DISPLAY: {
            return {
                ...state,
                showMapLegend: !state.showMapLegend
            }
        }
        case DISPLAY_CONFIG_ACTIONS.UPDATE_MAP_FILTERS: {
            return {
                ...state,
                mapFilters: {...action.payload}
            }
        }
        case DISPLAY_CONFIG_ACTIONS.TOGGLE_PERMISSIONS_INCLUDE_SYSTEM_OWNERS: {
            return {
                ...state,
                permissionsIncludeSystemOwners: !state.permissionsIncludeSystemOwners
            }
        }
        case DISPLAY_CONFIG_ACTIONS.SET_DISPLAY_CONFIG_LOADING: {
            return {
                ...state,
                loadingDisplay: true
            }
        }
        case DISPLAY_CONFIG_ACTIONS.HIDE_TRIAL_ERROR_NOTIFICATION: {
            return {
                ...state,
                hideTrialErrorNotification: true
            }
        }
        case DISPLAY_CONFIG_ACTIONS.UPDATE_USAGE_DATA: {
            return {
                ...state,
                usageStatus: action.payload
            }
        }
        default:
            return state;
    }
}

const [DisplayConfigProvider, useDisplayConfigState, useDisplayConfigDispatch] = create(reducer, initialState);

export const checkIsAgentAccessAllowed = (permissionsMode) => AGENT_ALLOWED_MODES.includes(permissionsMode);

export {
    DisplayConfigProvider,
    useDisplayConfigState,
    useDisplayConfigDispatch
};