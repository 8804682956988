import React, { useEffect } from 'react';
import { get } from 'lodash';
import { SelectField, useFormikContext, YesNoToggleField, utils, ArrayField, TextField } from 'components/Form';
import { usePrevious } from 'hooks';
import { ReinstallMessage, FIELD_MAPPING } from './utils';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Text from 'components/Text';
import { Link } from 'react-router-dom';

export const EMPTY_ANOTATION_ITEM = {key: "", value: ""};
export const EMPTY_SIDECAR_RESOURCES = {
    proxyInitRequestsMemory: "",
    proxyInitRequestsCpu: "",
    proxyInitLimitsMemory: "",
    proxyInitLimitsCpu: "",
    proxyRequestMemory: "",
    proxyRequestCpu: "",
    proxyLimitsMemory: "",
    proxyLimitsCpu: ""
}

export const EMPTY_ISTIO_FIELDS = {
    [FIELD_MAPPING.INSTALL_INGRESS.name]: false,
    [FIELD_MAPPING.MULTI_CLUSTER.name]: false,
    [FIELD_MAPPING.HOLD_APPLICATION.name]: false,
    [FIELD_MAPPING.NAMESPCASES_ISOLATION.name]: false,
    [FIELD_MAPPING.TLS_INSPECTION.name]: false,
    [FIELD_MAPPING.ENABLE_SIDECAR_RESOURCES.name]: false,
    sidecarsResources: EMPTY_SIDECAR_RESOURCES,
    [FIELD_MAPPING.ENABLE_EXTERNAL_CA.name]: false,
}

const SidecarBaseField = ({name, label, placeholder, disabled, tooltipText, validate}) => (
    <TextField
        name={`sidecarsResources.${name}`}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        validate={validate}
        tooltipText={tooltipText}
    />
);

const SidecarMemoryField = (props) => (
    <SidecarBaseField
        label="Memory"
        tooltipText={<span>Enter a value, with one of these units: Ei/Pi/Ti/Gi/Ki<br />ex: 1024Ti</span>}
        {...props}
        validate={value => {
            if (value === "") {
                return undefined;
            }

            const pattern = /^\d+(E|P|T|G|M|K)i?$/;
        
            return pattern.test(value) ? undefined : "Value must be a number, with one of these units: Ei/Pi/Ti/Gi/Mi/Ki";
        }}
    />
);

const SidecarCpuField = (props) => (
    <SidecarBaseField
        label="CPU"
        tooltipText={<span>Enter a value, with the unit m<br />ex: 2000m</span>}
        {...props}
        validate={value => {
            if (value === "") {
                return undefined;
            }
            
            const pattern = /^\d+m?$/;
        
            return pattern.test(value) ? undefined : "Value must be a number, with the unit m";
        }}
    />
);

const IstioControlFields = ({isReadOnlyUser, allowIngressUpdate, isIstioAlreadyInstalled}) => {
    const {values, setFieldValue} = useFormikContext();

    const isIstioIngressEnabled = get(values, FIELD_MAPPING.INSTALL_INGRESS.name);
    const prevIsIstioIngressEnabled = usePrevious(isIstioIngressEnabled);

    useEffect(() => {
        if (prevIsIstioIngressEnabled && !isIstioIngressEnabled) {
            setFieldValue("istioIngressAnnotations", [EMPTY_ANOTATION_ITEM]);
        }
    }, [prevIsIstioIngressEnabled, isIstioIngressEnabled, setFieldValue]);

    return (
        <utils.FieldsCategoryContainer title="Istio configuration">
            {!isIstioAlreadyInstalled &&
                <React.Fragment>
                    <YesNoToggleField
                        name={FIELD_MAPPING.INSTALL_INGRESS.name}
                        label={FIELD_MAPPING.INSTALL_INGRESS.label}
                        tooltipText={FIELD_MAPPING.INSTALL_INGRESS.tooltip}
                        disabled={isReadOnlyUser}
                    />
                    <utils.InnerFieldWrapper title="Istio ingress annotations" isVisable={isIstioIngressEnabled} isSecondLayer>
                        <div className="ingress-annotations-wrapper">
                            <ArrayField
                                name="istioIngressAnnotations"
                                firstFieldProps={{
                                    component: TextField,
                                    key: "key",
                                    label: "Key"
                                }}
                                secondFieldProps={{
                                    component: TextField,
                                    key: "value",
                                    label: "Value"
                                }}
                                disabled={isReadOnlyUser}
                            />
                        </div>
                    </utils.InnerFieldWrapper>
                    <YesNoToggleField
                        name={FIELD_MAPPING.NAMESPCASES_ISOLATION.name}
                        label={FIELD_MAPPING.NAMESPCASES_ISOLATION.label}
                        tooltipText={FIELD_MAPPING.NAMESPCASES_ISOLATION.tooltip}
                        disabled={isReadOnlyUser}
                    />
                    <YesNoToggleField
                        name={FIELD_MAPPING.MULTI_CLUSTER.name}
                        label={FIELD_MAPPING.MULTI_CLUSTER.label}
                        tooltipText={FIELD_MAPPING.MULTI_CLUSTER.tooltip}
                        disabled={!allowIngressUpdate || isReadOnlyUser}
                    />
                </React.Fragment>
            }
            <YesNoToggleField
                name={FIELD_MAPPING.INSPECT_INCOMMINT_CONNECTIONS.name}
                label={FIELD_MAPPING.INSPECT_INCOMMINT_CONNECTIONS.label}
                tooltipText={FIELD_MAPPING.INSPECT_INCOMMINT_CONNECTIONS.tooltip}
                disabled={!allowIngressUpdate || isReadOnlyUser}
            />
        </utils.FieldsCategoryContainer>
    );
};

const EnvoyProxyFields = ({isReadOnlyUser, isEditForm}) => {
    const {values, setFieldValue} = useFormikContext();

    const sidecarsResourcesEnabled = get(values, FIELD_MAPPING.ENABLE_SIDECAR_RESOURCES.name);
    const prevSidecarsResourcesEnabled = usePrevious(sidecarsResourcesEnabled);

    useEffect(() => {
        if ((prevSidecarsResourcesEnabled !== sidecarsResourcesEnabled) && !sidecarsResourcesEnabled) {
            setFieldValue("sidecarsResources", EMPTY_SIDECAR_RESOURCES);
        }
    }, [prevSidecarsResourcesEnabled, sidecarsResourcesEnabled, setFieldValue]);


    return (
        <utils.FieldsCategoryContainer title="Envoy configuration">
            <YesNoToggleField
                name={FIELD_MAPPING.HOLD_APPLICATION.name}
                label={FIELD_MAPPING.HOLD_APPLICATION.label}
                tooltipText={FIELD_MAPPING.HOLD_APPLICATION.tooltip}
                disabled={isReadOnlyUser}
            />
            <YesNoToggleField
                name={FIELD_MAPPING.ENABLE_SIDECAR_RESOURCES.name}
                label={FIELD_MAPPING.ENABLE_SIDECAR_RESOURCES.label}
                tooltipText={FIELD_MAPPING.ENABLE_SIDECAR_RESOURCES.tooltip}
                disabled={isReadOnlyUser}
            />
            <utils.InnerFieldWrapper title="Istio init" isVisable={sidecarsResourcesEnabled} isSecondLayer>
                <div className="sidecars-resources-wrapper">
                    <div className="inner-title">Requests</div>
                        <div className="inner-double-field">
                            <SidecarMemoryField name="proxyInitRequestsMemory" placeholder="10Mi" disabled={isReadOnlyUser} />
                            <SidecarCpuField name="proxyInitRequestsCpu" placeholder="10m" disabled={isReadOnlyUser} />
                        </div>
                        <div className="inner-title">Limits</div>
                        <div className="inner-double-field">
                            <SidecarMemoryField name="proxyInitLimitsMemory" placeholder="1024Mi" disabled={isReadOnlyUser} />
                            <SidecarCpuField name="proxyInitLimitsCpu" placeholder="2000m" disabled={isReadOnlyUser} />
                        </div>
                    </div>
            </utils.InnerFieldWrapper>
            <utils.InnerFieldWrapper title="Istio proxy" isVisable={sidecarsResourcesEnabled} isSecondLayer>
                <div className="sidecars-resources-wrapper">
                    <div className="inner-title">Requests</div>
                        <div className="inner-double-field">
                            <SidecarMemoryField name="proxyRequestMemory" placeholder="10Mi" disabled={isReadOnlyUser} />
                            <SidecarCpuField name="proxyRequestCpu" placeholder="10m" disabled={isReadOnlyUser} />
                        </div>
                        <div className="inner-title">Limits</div>
                        <div className="inner-double-field">
                            <SidecarMemoryField name="proxyLimitsMemory" placeholder="1024Mi" disabled={isReadOnlyUser} />
                            <SidecarCpuField name="proxyLimitsCpu" placeholder="2000m" disabled={isReadOnlyUser} />
                        </div>
                    </div>
            </utils.InnerFieldWrapper>
            <YesNoToggleField
                name={FIELD_MAPPING.TLS_INSPECTION.name}
                label={FIELD_MAPPING.TLS_INSPECTION.label}
                tooltipText={FIELD_MAPPING.TLS_INSPECTION.tooltip}
                disabled={isReadOnlyUser}
            />
            <YesNoToggleField
                name={FIELD_MAPPING.ENABLE_EXTERNAL_CA.name}
                label={FIELD_MAPPING.ENABLE_EXTERNAL_CA.label}
                tooltipText={FIELD_MAPPING.ENABLE_EXTERNAL_CA.tooltip}
                disabled={isReadOnlyUser}
            />
            {isEditForm ?  
                <IconWithTitle 
                    name={ICON_NAMES.INFO_ROUND}
                    title="When disabling this option, the external CAs added to this cluster will be removed"
                /> 
                : 
                <IconWithTitle 
                    name={ICON_NAMES.INFO_ROUND}
                    title={
                        <Text >
                            External CAs can be added in 
                            {" "}<Link to={'/system/integrations'}>{"System > Integrations"}</Link>
                        </Text>
                    }
                /> }
        </utils.FieldsCategoryContainer>
    );
}

const cleanIstioFields = (setFieldValue) => (
    Object.keys(EMPTY_ISTIO_FIELDS).forEach(fieldName => setFieldValue(fieldName, EMPTY_ISTIO_FIELDS[fieldName]))
);

const FormNetworkSecurity = ({allowIngressUpdate, accountConnectionsAllowed, istioVersionItems, checkShowUpdateMessage, isReadOnlyUser, isEditForm}) => {
    const {values, setFieldValue} = useFormikContext();
    
    const enableConnectionsControl = get(values, FIELD_MAPPING.CONNECTION_CONTROL.name);
    const prevEnableConnectionsControl = usePrevious(enableConnectionsControl);

    const isIstioAlreadyInstalled = get(values, FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.name);
    const prevIsIstioAlreadyInstalled = usePrevious(isIstioAlreadyInstalled);

    useEffect(() => {
        if (prevIsIstioAlreadyInstalled && !isIstioAlreadyInstalled) {
            setFieldValue(FIELD_MAPPING.ISTIO_VERSION.name, "");
        } else if (!prevIsIstioAlreadyInstalled && isIstioAlreadyInstalled) {
            cleanIstioFields(setFieldValue);
        }
    }, [prevIsIstioAlreadyInstalled, isIstioAlreadyInstalled, setFieldValue]);

    useEffect(() => {
        if (prevEnableConnectionsControl && !enableConnectionsControl) {
            cleanIstioFields(setFieldValue);
            setFieldValue(FIELD_MAPPING.INSPECT_INCOMMINT_CONNECTIONS.name, false);
        }
    }, [prevEnableConnectionsControl, enableConnectionsControl, setFieldValue]);

    return (
        <React.Fragment>
            <YesNoToggleField
                name={FIELD_MAPPING.CONNECTION_CONTROL.name}
                label={FIELD_MAPPING.CONNECTION_CONTROL.label}
                tooltipText={FIELD_MAPPING.CONNECTION_CONTROL.tooltip}
                disabled={!accountConnectionsAllowed || isReadOnlyUser}
            />
            {enableConnectionsControl &&
                <React.Fragment>
                    <YesNoToggleField
                        name={FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.name}
                        label={FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.label}
                        tooltipText={FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.tooltip}
                        disabled={isReadOnlyUser}
                    />
                    {isIstioAlreadyInstalled &&
                        <SelectField
                            name={FIELD_MAPPING.ISTIO_VERSION.name}
                            label={FIELD_MAPPING.ISTIO_VERSION.label}
                            tooltipText={FIELD_MAPPING.ISTIO_VERSION.tooltip}
                            items={istioVersionItems}
                            disabled={isReadOnlyUser}
                        />
                    }
                    <IstioControlFields
                        isReadOnlyUser={isReadOnlyUser}
                        allowIngressUpdate={allowIngressUpdate}
                        isIstioAlreadyInstalled={isIstioAlreadyInstalled}
                    />
                    {!isIstioAlreadyInstalled && <EnvoyProxyFields isReadOnlyUser={isReadOnlyUser} isEditForm={isEditForm}/>}
                </React.Fragment>
            }
            {checkShowUpdateMessage(values) && <ReinstallMessage isReinstall={isEditForm} />}
        </React.Fragment>
    )
}

export default FormNetworkSecurity;