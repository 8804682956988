import React, { useEffect } from 'react';
import { isInteger, get } from 'lodash';
import { usePrevious } from 'hooks';
import { useFormikContext, TextField, YesNoToggleField, utils, FieldLabel, CheckboxField } from 'components/Form';
import { ReinstallMessage, FIELD_MAPPING } from './utils';

const MIN_NUMBER_OF_SCANNERS = 1;

const validateControllersReplicas = value => {
    const MIN_VALUE = 1;
    const MAX_VALUE = 5;
    const ERROR_MASSAGE = `Please enter a value between ${MIN_VALUE} and ${MAX_VALUE}`;

    if (!isInteger(value)) {
        return ERROR_MASSAGE;
    }

    return (value < MIN_VALUE || value > MAX_VALUE) ? ERROR_MASSAGE : null;
}

const FormAdvanceSettings = ({ allowIngressUpdate, checkShowUpdateMessage, isReadOnlyUser, isEditForm }) => {
    const { values, setFieldValue } = useFormikContext();

    const enableKubernetesSecurity = get(values, FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.name);
    const enableApiSecurity = get(values, FIELD_MAPPING.ENABLE_API_SECURITY.name);

    const connectionControlEnabled = get(values, FIELD_MAPPING.CONNECTION_CONTROL.name);

    const internalRegistryEnabled = get(values, FIELD_MAPPING.ENABLE_INTERNAL_REGISTRY.name);
    const prevInternalRegistryEnabled = usePrevious(internalRegistryEnabled);

    const enableProxy = get(values, FIELD_MAPPING.ENABLE_PROXY.name);
    const prevEnableProxy = usePrevious(enableProxy);

    useEffect(() => {
        if (prevEnableProxy && !enableProxy) {
            setFieldValue(FIELD_MAPPING.PROXY_ADDRESS.name, "");
        }
    }, [prevEnableProxy, enableProxy, setFieldValue]);

    useEffect(() => {
        if (prevInternalRegistryEnabled && !internalRegistryEnabled) {
            setFieldValue(FIELD_MAPPING.INTERNAL_REGISTRY.name, "");
        }
    }, [prevInternalRegistryEnabled, internalRegistryEnabled, setFieldValue]);

    return (
        <React.Fragment>
            {(enableKubernetesSecurity || enableApiSecurity) &&
                <utils.FieldsCategoryContainer title="Pre-deployment settings">
                    {enableKubernetesSecurity &&
                        <React.Fragment>
                            <YesNoToggleField
                                name={FIELD_MAPPING.CI_HASH_VALIDATION.name}
                                label={FIELD_MAPPING.CI_HASH_VALIDATION.label}
                                tooltipText={FIELD_MAPPING.CI_HASH_VALIDATION.tooltip}
                                disabled={!allowIngressUpdate || isReadOnlyUser}
                            />
                            <YesNoToggleField
                                name={FIELD_MAPPING.CI_SIGNATURE_VALIDATION.name}
                                label={FIELD_MAPPING.CI_SIGNATURE_VALIDATION.label}
                                tooltipText={FIELD_MAPPING.CI_SIGNATURE_VALIDATION.tooltip}
                                disabled={!allowIngressUpdate || isReadOnlyUser}
                            />
                            <YesNoToggleField
                                name={FIELD_MAPPING.CD_SCANNING.name}
                                label={FIELD_MAPPING.CD_SCANNING.label}
                                tooltipText={FIELD_MAPPING.CD_SCANNING.tooltip}
                                disabled={isReadOnlyUser}
                            />
                            <YesNoToggleField
                                name={FIELD_MAPPING.RESTRICT_REGISTRIES.name}
                                label={FIELD_MAPPING.RESTRICT_REGISTRIES.label}
                                tooltipText={FIELD_MAPPING.RESTRICT_REGISTRIES.tooltip}
                                disabled={isReadOnlyUser}
                            />
                        </React.Fragment>
                    }
                    {enableApiSecurity &&
                        <YesNoToggleField
                            name={FIELD_MAPPING.API_TOKEN_INJECTION.name}
                            label={FIELD_MAPPING.API_TOKEN_INJECTION.label}
                            tooltipText={FIELD_MAPPING.API_TOKEN_INJECTION.getTooltip({ enableKubernetesSecurity })}
                            disabled={isReadOnlyUser || !enableKubernetesSecurity}
                        />
                    }
                </utils.FieldsCategoryContainer>
            }
            <utils.FieldsCategoryContainer title="Controller deployment settings">
                <YesNoToggleField
                    name={FIELD_MAPPING.PERSISTENT_STORAGE.name}
                    label={FIELD_MAPPING.PERSISTENT_STORAGE.label}
                    tooltipText={FIELD_MAPPING.PERSISTENT_STORAGE.tooltip}
                    disabled={isReadOnlyUser}
                />
                <TextField
                    type="number"
                    name={FIELD_MAPPING.CONTROLLER_REPLICAS.name}
                    label={FIELD_MAPPING.CONTROLLER_REPLICAS.label}
                    tooltipText={FIELD_MAPPING.CONTROLLER_REPLICAS.tooltip}
                    disabled={isReadOnlyUser}
                    validate={validateControllersReplicas}
                />
                <YesNoToggleField
                    name={FIELD_MAPPING.ENABLE_INTERNAL_REGISTRY.name}
                    label={FIELD_MAPPING.ENABLE_INTERNAL_REGISTRY.label}
                    tooltipText={FIELD_MAPPING.ENABLE_INTERNAL_REGISTRY.tooltip}
                    disabled={isReadOnlyUser}
                />
                <utils.InnerFieldWrapper title="Internal registry settings" isVisable={internalRegistryEnabled} isSecondLayer>
                    <TextField
                        name={FIELD_MAPPING.INTERNAL_REGISTRY.name}
                        label={FIELD_MAPPING.INTERNAL_REGISTRY.label}
                        tooltipText={FIELD_MAPPING.INTERNAL_REGISTRY.tooltip}
                        placeholder={FIELD_MAPPING.INTERNAL_REGISTRY.placeholder}
                        disabled={isReadOnlyUser}
                    />
                </utils.InnerFieldWrapper>
                <YesNoToggleField
                    name={FIELD_MAPPING.ENABLE_PROXY.name}
                    label={FIELD_MAPPING.ENABLE_PROXY.label}
                    tooltipText={FIELD_MAPPING.ENABLE_PROXY.tooltip}
                    disabled={isReadOnlyUser}
                />
                <utils.InnerFieldWrapper title="External HTTPS proxy settings" isVisable={enableProxy} isSecondLayer>
                    <TextField
                        name={FIELD_MAPPING.PROXY_ADDRESS.name}
                        label={FIELD_MAPPING.PROXY_ADDRESS.label}
                        tooltipText={FIELD_MAPPING.PROXY_ADDRESS.tooltip}
                        placeholder={FIELD_MAPPING.PROXY_ADDRESS.placeholder}
                        disabled={isReadOnlyUser}
                    />
                </utils.InnerFieldWrapper>
            </utils.FieldsCategoryContainer>
            <utils.FieldsCategoryContainer title="Scan configurations">
                <div className="scan-config-wrapper">
                    <FieldLabel>Scan options</FieldLabel>
                    <CheckboxField
                        name={FIELD_MAPPING.SCAN_CONFIG_VULNERABILITIES.name}
                        title={FIELD_MAPPING.SCAN_CONFIG_VULNERABILITIES.label}
                        disabled={isReadOnlyUser}
                    />
                    <CheckboxField
                        name={FIELD_MAPPING.SCAN_CONFIG_DOCKER_CIS.name}
                        title={FIELD_MAPPING.SCAN_CONFIG_DOCKER_CIS.label}
                        disabled={isReadOnlyUser}
                    />
                </div>
                <TextField
                    type="number"
                    min={MIN_NUMBER_OF_SCANNERS}
                    validate={value => (
                        value >= MIN_NUMBER_OF_SCANNERS ? undefined : `Number or scanners should be ${MIN_NUMBER_OF_SCANNERS} or greater`
                    )}
                    name={FIELD_MAPPING.SCAN_CONFIG_PARALLEL_SCANNERS.name}
                    label={FIELD_MAPPING.SCAN_CONFIG_PARALLEL_SCANNERS.label}
                    disabled={isReadOnlyUser}
                />
            </utils.FieldsCategoryContainer>
            <utils.FieldsCategoryContainer title="Other settings" withButtomMargin={false}>
                {enableApiSecurity &&
                    <YesNoToggleField
                        name={FIELD_MAPPING.API_TESTING.name}
                        label={FIELD_MAPPING.API_TESTING.label}
                        tooltipText={FIELD_MAPPING.API_TESTING.tooltip}
                        disabled={isReadOnlyUser}
                    />
                }
                <YesNoToggleField
                    name={FIELD_MAPPING.AUTO_LABEL.name}
                    label={FIELD_MAPPING.AUTO_LABEL.label}
                    tooltipText={FIELD_MAPPING.AUTO_LABEL.getTooltip({ connectionControlEnabled, enableApiSecurity, enableKubernetesSecurity })}
                    disabled={isReadOnlyUser || !enableKubernetesSecurity}
                />
                {enableKubernetesSecurity &&
                    <YesNoToggleField
                        name={FIELD_MAPPING.FAIL_CLOSE.name}
                        label={FIELD_MAPPING.FAIL_CLOSE.label}
                        tooltipText={FIELD_MAPPING.FAIL_CLOSE.tooltip}
                        disabled={isReadOnlyUser}
                    />
                }
                <YesNoToggleField
                    name={FIELD_MAPPING.ENABLE_AUTO_UPDATE.name}
                    label={FIELD_MAPPING.ENABLE_AUTO_UPDATE.label}
                    tooltipText={FIELD_MAPPING.ENABLE_AUTO_UPDATE.tooltip}
                    disabled={isReadOnlyUser}
                />
                <YesNoToggleField
                    name={FIELD_MAPPING.ENABLE_KUBERNETES_EVENTS.name}
                    label={FIELD_MAPPING.ENABLE_KUBERNETES_EVENTS.label}
                    tooltipText={FIELD_MAPPING.ENABLE_KUBERNETES_EVENTS.getTooltip({ enableKubernetesSecurity })}
                    disabled={isReadOnlyUser || !enableKubernetesSecurity}
                />
                <YesNoToggleField
                    name={FIELD_MAPPING.DISABLE_SSH_PROBING.name}
                    label={FIELD_MAPPING.DISABLE_SSH_PROBING.label}
                    tooltipText={FIELD_MAPPING.DISABLE_SSH_PROBING.tooltip}
                    disabled={isReadOnlyUser}
                />
            </utils.FieldsCategoryContainer>
            {checkShowUpdateMessage(values) && <ReinstallMessage isReinstall={isEditForm} />}
        </React.Fragment>
    )
}

export default FormAdvanceSettings;