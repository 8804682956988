import React from 'react';
import { isUndefined } from 'lodash';

export const create = (reducer, initialState) => {
    const StateContext = React.createContext();
    const DispatchContext = React.createContext();

    const Provider = ({children}) => {
        const [state, dispatch] = React.useReducer(reducer, initialState);

        return (
            <StateContext.Provider value={state}>
                <DispatchContext.Provider value={dispatch}>
                    {children}
                </DispatchContext.Provider>
            </StateContext.Provider>
        )
    }

    const useState = () => {
        const context = React.useContext(StateContext);

        if (isUndefined(context)) {
            throw Error("useState is not within the related provider")
        }

        return context;
    }

    const useDispatch = () => {
        const context = React.useContext(DispatchContext);

        if (isUndefined(context)) {
            throw Error("useDispatch is not within the related provider")
        }

        return context;
    }

    return [Provider, useState, useDispatch];
}

export const createFilter = (initialState) => {
    const FILTER_ACTIONS = {
        SET_FILTERS: "SET_FILTERS"
    };
    
    const reducer = (state, action) => {
        switch (action.type) {
            case FILTER_ACTIONS.SET_FILTERS: {
                return {
                    ...state,
                    ...action.payload
                }
            }
            default:
                return state;
        }
    }
    
    const [FilterProvider, useFilterState, useFilterDispatch] = create(reducer, initialState);
    
    const setFilters = (dispatch, payload) => dispatch({type: FILTER_ACTIONS.SET_FILTERS, payload});

    return [FilterProvider, useFilterState, useFilterDispatch, setFilters];
}