import React from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';

import './icon-with-title.scss';

const IconWithTitle = (props) => {
    const { title, reverseOrder, onClick, className, ...iconProps } = props;
    const { disabled, name } = props;

    return (
        <div
            className={classnames("icon-container", `icon-container-${name}`, { disabled }, { [className]: className }, { clickable: !!onClick && !disabled })}
            onClick={event => !disabled && onClick ? onClick(event) : null}
        >
            {reverseOrder && <div className="icon-title reverse">{title}</div>}
            <Icon {...iconProps} />
            {!reverseOrder && <div className="icon-title">{title}</div>}
        </div>
    );
}

export default IconWithTitle;