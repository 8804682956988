import { useCallback, useState } from 'react';
import { isNull } from 'lodash';
import { TIME_RANGER_MAP, checkIsManualRange } from 'context/TimeRangeProvider';

export {
    TIME_RANGER_MAP
}

const getFreshTimes = selectedValue => {
    const selectedItem = TIME_RANGER_MAP[selectedValue];

    if (selectedItem.isManual) {
        return; //no need to refresh because the start/end times are manually set
    }

    return selectedItem.calc();
} 

const useStanaloneTimeFilter = props => {
    const {selected: inititalSelectedValue=TIME_RANGER_MAP["WEEK"].value, startTime: startTimeInitial, endTime: endTimeInitital} = props || {};
    const {startTime: initialStart, endTime: initialEnd} = getFreshTimes(inititalSelectedValue) || {};
    const [selectedTime, setSelectedTime] = useState({
        selected: inititalSelectedValue,
        startTime: initialStart || startTimeInitial || null,
        endTime: initialEnd || endTimeInitital || null
    });
    const {selected, endTime, startTime} = selectedTime;

    const refreshTimes = useCallback(() => {
        if (checkIsManualRange(selected)) {
            return;
        }
        
        const {startTime, endTime} = getFreshTimes(selected);

        setSelectedTime({selected, startTime, endTime});
    }, [selected]);

    const selectTime = useCallback((selected, startTimeSet, endTimeSet) => {
        const selectedItem = TIME_RANGER_MAP[selected];
        let newState = {selected, startTime: startTimeSet, endTime: endTimeSet};;

        if (!selectedItem.isManual) {
            const {startTime, endTime} = TIME_RANGER_MAP[selected].calc();
            newState = {...newState, startTime, endTime};
        } else if (isNull(endTimeSet)) {
            newState = {...newState, endTime: startTimeSet};
        }

        setSelectedTime(newState);
    }, []);

    return [{selected, endTime, startTime}, refreshTimes, selectTime];
};

export default useStanaloneTimeFilter;