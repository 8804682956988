import React, { useState } from 'react';
import { FETCH_METHODS, useFetch } from 'hooks';
import { TextField, validators, CheckboxField, useFormikContext, utils, FieldLabel } from 'components/Form';
import InfoIcon from 'components/InfoIcon';
import TestConnectionButton from 'components/TestConnectionButton';
import { DOCUMENTATION_URL, APPLICATION_NAME } from 'utils/systemConsts';
import { BoldText } from 'utils/generalUtils';
import { VENDORS } from './utils';

const TestConnection = ({formValues}) => {
    const [isTesting, setIsTesting] = useState(false);

    const {eventsForwardingDetailsType} = formValues;

    const selectedVendor = Object.values(VENDORS).find(vendor => vendor.value === eventsForwardingDetailsType);
    const testData = selectedVendor.testConnectionFields.reduce((accumulator, fieldName) => {
        accumulator[fieldName] = formValues[fieldName];
        return accumulator;
    }, {});
    const isDisabled = Object.values(testData).findIndex(item => item === "") >= 0;
    const [{loading, error}, testConnection] = useFetch("settings/integrations", {loadOnMount: false});
    const doTestConnection = () => {
        setIsTesting(true);
        
        testConnection({
            formatUrl: url =>`${url}/${selectedVendor.key}/testIntegration`,
            method: FETCH_METHODS.POST,
            submitData: testData
        })
    };
    
    return (
        <TestConnectionButton
            className="events-forwarding-test-connection-button"
            doTestConnection={doTestConnection}
            isTesting={isTesting}
            loading={loading}
            error={error}
            disabled={isDisabled}
        />
    )
}

const DocumentationLink = ({path}) => <a href={`${DOCUMENTATION_URL}/${path}`} target="_blank" rel="noopener noreferrer"><InfoIcon /></a>

const UrlForm = ({eventsForwardingDetailsType}) => (
    <React.Fragment>
        <FieldLabel className="url-label-container">
            <label className="form-label">URL</label>
            {eventsForwardingDetailsType === VENDORS.SLACK.value && <DocumentationLink path="docs/slack" />}
            {eventsForwardingDetailsType === VENDORS.TEAMS.value && <DocumentationLink path="docs/microsoft-teams" />}
        </FieldLabel>
        <TextField name="url" validate={validators.validateRequired} />
    </React.Fragment>
)

const SplunkForm = () => (
    <React.Fragment>
        <TextField name="url" label="URL" validate={validators.validateRequired} />
        <TextField name="token" label="Token" validate={validators.validateRequired} />
        <TextField name="sourceName" label="Source Name" validate={validators.validateRequired} />
        <TextField name="port" label="Port" type="number" />
        <CheckboxField name="isCloud" label="Splunk Cloud" title="Splunk Cloud" />
    </React.Fragment>
)

const OpsGenieForm = () => (
    <React.Fragment>
        <TextField name="token" label="Token" validate={validators.validateRequired} />
    </React.Fragment>
)

const SumoLogicForm = () => (
    <React.Fragment>
        <TextField name="url" label="URL" validate={validators.validateRequired} />
    </React.Fragment>
)

const WebexForm = () => (
    <React.Fragment>
        <utils.FormInstructionsTitle>Create a new Space in your cisco Webex</utils.FormInstructionsTitle>
        <TextField
            name="url"
            label="Cisco Webex space link"
            validate={validators.validateRequired}
            tooltipText={<span>Click the gear cog icon at the top of the Messages window,<br />and select <BoldText>Copy space link</BoldText> to retrieve the URL</span>}
        />
        <utils.FormInstructionsTitle>{`Add ${APPLICATION_NAME} bot to the Cisco Webex Space`}</utils.FormInstructionsTitle>
    </React.Fragment>
)

const SecureXForm = () => (
    <React.Fragment>
        <TextField name="url" label="URL" validate={validators.validateRequired} />
    </React.Fragment>
)

const TYPE_TO_FORM_MAPPING = {
    [VENDORS.SPLUNK.value]: SplunkForm,
    [VENDORS.OPS_GENIE.value]: OpsGenieForm,
    [VENDORS.SLACK.value]: UrlForm,
    [VENDORS.SUMO_LOGIC.value]: SumoLogicForm,
    [VENDORS.WEBEX.value]: WebexForm,
    [VENDORS.SECURE_X.value]: SecureXForm,
    [VENDORS.TEAMS.value]: UrlForm,
}

const FormSoftwareConfiguration = () => {
    const {values} = useFormikContext();
    const {eventsForwardingDetailsType} = values;

    const TypeForm = TYPE_TO_FORM_MAPPING[eventsForwardingDetailsType];

    return (
        <React.Fragment>
            {!!TypeForm && <TypeForm eventsForwardingDetailsType={eventsForwardingDetailsType} />}
            <TestConnection formValues={values} />
        </React.Fragment>
    )
}

export default FormSoftwareConfiguration;
