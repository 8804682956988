import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { isEmpty, isNull } from 'lodash';
import { RISK_SCAN_STATUSES } from 'utils/systemConsts';
import { useFetch, usePrevious, useDelete, FETCH_METHODS } from 'hooks';
import { useAuthState } from 'context/AuthProvider';
import { useNotificationDispatch, showNotification, NOTIFICATION_TYPES } from 'context/NotificationProvider';
import Table from 'components/Table';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';
import Tooltip from 'components/Tooltip';
import LineLoader from 'components/LineLoader';
import Icon, { ICON_NAMES } from 'components/Icon';
import Button from 'components/Button';
import VulnerabilitiesSummary from 'components/VulnerabilitiesSummary';
import { formatDate } from 'utils/generalUtils';
import CisSummary from '../CisSummary';
import ErrorIconNotification from '../ErrorIconNotification';
import { RISKS_SCAN_PAGE_PATH, BREADCRUMBS_TITLE } from './utils';

const TooltipWrapper = ({tooltipId, tooltipText, children}) => (
    <div className="tooltip-wrapper">
        <div data-tip data-for={tooltipId}>
            {children}
        </div>
        <Tooltip id={tooltipId} text={tooltipText} placement="right" />
    </div>
);

const GeneralRiskAssessment = () => {
    const notificationDispatch = useNotificationDispatch();

    const [scanRequestClusterId, setScanRequestClusterId] = useState(null);

    const [pollingDataConfig, setPollingDataConfig] = useState({pollItems: [], scanRequestedItems: []});
    const {pollItems, scanRequestedItems} = pollingDataConfig;
    const pollItemsJson = JSON.stringify(pollItems);

    const fetcherRef = useRef(null);

    useEffect(() => {
        return function cleanup() {
            if (fetcherRef.current) {
                clearTimeout(fetcherRef.current);
            }
        };
    }, []);

    const {isReadOnlyUser} = useAuthState();

    
    const [{loading: startScanLoading, error: startScanError}, startScan] = useFetch("riskAssessment", {loadOnMount: false});
    const doStartScan = clusterId => {
        setScanRequestClusterId(clusterId);

        startScan({method: FETCH_METHODS.POST, formatUrl: url => `${url}/${clusterId}/scan`, submitData: {}});
    }
    const prevStartScanLoading = usePrevious(startScanLoading);

    const [{deleting, error: deleteError}, stopScan] = useDelete("riskAssessment", {urlSuffix: "/cancel"});
    const prevDeleting = usePrevious(deleting);

    const doStopScan = clusterId => stopScan(clusterId);

    useEffect(() => {
        if (prevDeleting && !deleting) {
            showNotification(notificationDispatch, {
                message: !!deleteError ? "An error occurred when trying to stop the scan" : "Stop scan request sent",
                type: !!deleteError ? NOTIFICATION_TYPES.ERROR : NOTIFICATION_TYPES.SUCCESS
            });
        }
    }, [prevDeleting, deleting, deleteError, notificationDispatch]);

    useEffect(() => {
        if (prevStartScanLoading && !startScanLoading && !startScanError) {
            showNotification(notificationDispatch, {
                message: "Scan request sent",
                type: NOTIFICATION_TYPES.SUCCESS
            });

            setPollingDataConfig({
                scanRequestedItems: [...scanRequestedItems, scanRequestClusterId],
                pollItems: [...pollItems, scanRequestClusterId]
            });
        }
    }, [startScanLoading, prevStartScanLoading, startScanError, scanRequestClusterId, pollItems, scanRequestedItems, setPollingDataConfig, notificationDispatch])

    const [{loading, data, error}] = useFetch("riskAssessment");
    const prevLoading = usePrevious(loading);
    const [{loading: polling, data: pollingData, error: pollingError}, pollData] = useFetch("riskAssessment/poll", {loadOnMount: false});
    const prevPolling = usePrevious(polling);

    useEffect(() => {
        if ((prevPolling && !polling && !!pollingError) || (prevLoading && !loading && !!error)) {
            showNotification(notificationDispatch, {
                message: "An error occurred when trying to load scans data",
                type: NOTIFICATION_TYPES.ERROR
            });
        }
    }, [prevLoading, loading, error, prevPolling, polling, pollingError, notificationDispatch])

    const [tableData, setTableData] = useState([]);

    const dataJson = JSON.stringify(data);
    const pollingDataJson = JSON.stringify(pollingData);
    const tableDataJson = JSON.stringify(tableData);

    useEffect(() => {
        if (loading) {
            return;
        }

        setTableData(JSON.parse(dataJson));
    }, [dataJson, loading]);

    useEffect(() => {
        if (prevPolling && !polling) {
            const pollingData = JSON.parse(pollingDataJson);
            const tableData = JSON.parse(tableDataJson);

            const updatedData =  tableData.map(item => {
                const updatedItem = pollingData.find(resDataItem => item.clusterId === resDataItem.clusterId);
                
                return !!updatedItem ? {...item, ...updatedItem} : item;
            });

            setTableData(updatedData);
        }
    }, [prevPolling, polling, pollingDataJson, tableDataJson]);

    useEffect(() => {
        const tableData = JSON.parse(tableDataJson);
        const lastPolledClusterIds = tableData.map(item => item.clusterId);
        
        setPollingDataConfig(prevPollingDataConfig => {
            const {scanRequestedItems} = prevPollingDataConfig;
            const updatedScanRequestedItems = scanRequestedItems.filter(item => !lastPolledClusterIds.includes(item));
            const inProgressItemIds = tableData.filter(item => item.status === RISK_SCAN_STATUSES.IN_PROGRESS)
                .map(item => item.clusterId);
            
                return {scanRequestedItems: updatedScanRequestedItems, pollItems: inProgressItemIds};
        })
    }, [tableDataJson])

    const doPollDataWithQueryParams = useCallback(() => {
        pollData({
            queryParams: {riskAssessmentPollKey: JSON.parse(pollItemsJson)}
        });
    }, [pollItemsJson, pollData]);

    useEffect(() => {
        if (polling || loading) {
            return;
        }

        const pollItems = JSON.parse(pollItemsJson);

        if (!isEmpty(pollItems)) {
            fetcherRef.current = setTimeout(doPollDataWithQueryParams, 2000);
        }
    }, [pollItemsJson, polling, loading, doPollDataWithQueryParams]);

    const columns = useMemo(() => [
        {
            Header: "Cluster",
            id: "clusterName",
            accessor: "clusterName",
            disableSortBy: true
        },
        {
            Header: "Namespaces",
            id: "namespaces",
            Cell: ({row}) => {
                const {namespaces} = row.original.scanConfig;

                if (isEmpty(namespaces)) {
                    return "All";
                }

                return (
                    <React.Fragment>
                        {
                            namespaces.map(namespace =>
                                <div key={namespace}>{namespace}</div>)
                        }
                    </React.Fragment>
                );
            }
        },
        {
            Header: "Start Time",
            id: "time",
            accessor: original => isNull(original.time) ? "" : formatDate(original.time),
            disableSortBy: true
        },
        {
            Header: "Progress",
            id: "progress",
            Cell: ({row}) => {
                const {status, scanned, total, time, id} = row.original;

                const formattedTotal = total === 0 ? 1 : total;
                const notScanned = status === RISK_SCAN_STATUSES.NOT_SCANNED;
                const isDone = status === RISK_SCAN_STATUSES.DONE;
                const isNotReady = status === RISK_SCAN_STATUSES.AGENT_NOT_READY;
                const isNotReadyAgain = isNotReady && !isNull(time);
                const isNotReadyInitial = isNotReady && isNull(time);
                const AgentNotReadyText = "Agent not ready";

                let percent = 0;
                let progressClass = "empty";

                if (isDone) {
                    percent = 100;
                    progressClass = "done";
                }

                if (isNotReadyAgain) {
                    percent = 100;
                    progressClass = "done disabled";
                }

                if (status === RISK_SCAN_STATUSES.IN_PROGRESS) {
                    percent = Math.round(scanned/formattedTotal*100);
                    progressClass = "in-progress";
                }
                
                return (
                    <div className={classnames("scan-progress-container", progressClass)}>
                        <LineLoader
                            className="progress-loader"
                            done={isDone || isNotReadyAgain ? formattedTotal : scanned}
                            total={formattedTotal}
                        />
                        {!isDone && !isNotReady && !notScanned && `${percent}%`}
                        {isDone && <Icon name={ICON_NAMES.SUCCESS} />}
                        {isNotReadyAgain &&
                            <TooltipWrapper tooltipId={`progress${id}`} tooltipText={AgentNotReadyText}>
                                <Icon name={ICON_NAMES.SUCCESS} />
                            </TooltipWrapper>
                        }
                        {isNotReadyInitial &&
                            <TooltipWrapper tooltipId={`progress${id}`} tooltipText={AgentNotReadyText}>
                                <Icon name={ICON_NAMES.RESTRICT} />
                            </TooltipWrapper>
                        }
                    </div>
                );
            },
            minWidth: 120
        },
        {
            Header: "Vulnerabilities",
            id: "vulnerabilities",
            Cell: ({row}) => {
                const {status, vulnerabilitiesSummary, clusterId, time, hasFailed} = row.original;
                const notScanned = status === RISK_SCAN_STATUSES.NOT_SCANNED;
                const isNotReadyInitial = (status === RISK_SCAN_STATUSES.AGENT_NOT_READY) && isNull(time);
                const hideSummary = notScanned || isNotReadyInitial;
                
                return (
                    <div className="vulnerabilities-cell-container">
                        <div className="vulnerabilities-wrapper">
                            {hideSummary ? null :
                                <React.Fragment>
                                    <VulnerabilitiesSummary {...vulnerabilitiesSummary} id={clusterId} />
                                    {hasFailed &&
                                        <ErrorIconNotification
                                            tooltipId={`vulnerability-error-${clusterId}`}
                                            text={<span>There are images that<br />cannot be scanned</span>}
                                        />}
                                </React.Fragment>
                            }
                        </div>
                        
                    </div>
                )
            },
            width: 420,
            disableResizing: true
        },
        {
            Header: "CIS Benchmark",
            id: "cis",
            Cell: ({row}) => {
                const {id, dockerfileScanResultsSummary, status, time} = row.original;
                const {total, info, warn, fatal} = dockerfileScanResultsSummary || {};
                const notScanned = status === RISK_SCAN_STATUSES.NOT_SCANNED;
                const isNotReadyInitial = (status === RISK_SCAN_STATUSES.AGENT_NOT_READY) && isNull(time);
                const hideSummary = notScanned || isNotReadyInitial;

                if (hideSummary) {
                    return null;
                }
                
                return (
                    <CisSummary total={total} totalTooltipId={`total-cis-${id}`} info={info} warning={warn} fatal={fatal} />
                );
            },
            disableSortBy: true,
            width: 270,
            disableResizing: true
        }
    ], []);

    const history = useHistory();

    return (
        <div className="risks-scan-page">
            <BreadcrumbsHeader title={BREADCRUMBS_TITLE} baseUrl={RISKS_SCAN_PAGE_PATH} />
            <Table
                data={tableData}
                name="risk-assessment"
                columns={columns}
                onLineClick={({clusterId}) => history.push({pathname: `${RISKS_SCAN_PAGE_PATH}/${clusterId}`})}
                withPagination={false}
                isLoading={loading}
                actionsComponent={({original}) => {
                    const {status, clusterId, time} = original;
                    const requestSent = scanRequestedItems.includes(clusterId);
                    const notScanned = status === RISK_SCAN_STATUSES.NOT_SCANNED;
                    const inProgress = status === RISK_SCAN_STATUSES.IN_PROGRESS;
                    const isNotReadyInitial = (status === RISK_SCAN_STATUSES.AGENT_NOT_READY) && isNull(time);
                    const hideScanButton = inProgress || requestSent || isNotReadyInitial;
                    
                    return (
                        <div className="scan-button-wrapper">
                            {hideScanButton ? null :
                                <Button
                                    tertiary
                                    onClick={event => {
                                        event.stopPropagation();
                                        event.preventDefault();

                                        doStartScan(original.clusterId);
                                    }}
                                    disabled={isReadOnlyUser}
                                >
                                    {notScanned ? "Scan Now" : "Rescan"}
                                </Button>
                            }
                            {inProgress &&
                                <Button
                                    tertiary
                                    onClick={event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        
                                        doStopScan(clusterId);
                                    }}
                                    disabled={isReadOnlyUser}
                                >
                                    Stop Scan
                                </Button>
                            }
                        </div>
                    )
                }}
                isDrilldownDisabled={({status, clusterId, time}) => {
                    const requestSent = scanRequestedItems.includes(clusterId);
                    const isDone = status === RISK_SCAN_STATUSES.DONE;
                    const isNotReadyAgain = (status === RISK_SCAN_STATUSES.AGENT_NOT_READY) && !isNull(time);

                    const showResults = (isDone || isNotReadyAgain) && !requestSent;

                    return !showResults;
                }}
                actionsCellWidth={150}
            />
        </div>
    )
}

export default GeneralRiskAssessment;