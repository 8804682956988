import React, { useEffect } from 'react';
import { usePrevious } from 'hooks';
import { TextField, RadioField, validators, useFormikContext } from 'components/Form';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { EMPTY_SIDECAR_VALUE } from './FormWorkloads';

const FormRuleProperties = ({isInjectionRule, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {ruleTypeProperties} = values;
    const {action} = ruleTypeProperties;
    const prevAction = usePrevious(action);

    useEffect(() => {
        if (prevAction !== action && action === RULE_ACTIONS.ALLOW) {
            setFieldValue("app.podValidation.allowedInjectedSidecars", EMPTY_SIDECAR_VALUE);
        }
    }, [prevAction, action, setFieldValue]);

    return (
        <React.Fragment>
            <TextField name="name" label="Rule Name" validate={validators.validateRequired} disabled={isReadOnlyUser} />
            {!isInjectionRule &&
                <RadioField
                    name="ruleTypeProperties.action"
                    label="Action"
                    validate={validators.validateRequired}
                    items={[
                        {value: RULE_ACTIONS.ALLOW, label: "Allow pods to run only on the specified environments"},
                        {value: RULE_ACTIONS.DETECT, label: "Detect pods running on the specified environments"},
                        {value: RULE_ACTIONS.BLOCK, label: "Block pods from running on the specified environments"},
                    ]}
                    disabled={isReadOnlyUser}
                />}
        </React.Fragment>
    )
}

export default FormRuleProperties;