import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isNull } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { useFetch } from 'hooks';
import { TextField } from 'components/Form';
import Table, { FilterFormWrapper } from 'components/Table';
import IconWithTitle from 'components/IconWithTitle';
import IconWithTooltip from 'components/IconWithTooltip';
import FormModal from 'components/FormModal';
import Loader from 'components/Loader';
import { formatDateBy } from 'utils/generalUtils';
import TokenForm from './TokenForm';
import DeleteTokenModal from "./DeleteTokenModal";

import './tokens.scss';

export const TOKENS_PAGE_PATH = "/catalog/tokens";

const TokensContent = ({catalogItemsData}) => {
    const columns = useMemo(() => [
        {
            Header: "Token name",
            id: "name",
            accessor: "name",
            alwaysShow: true,
            disableSortBy: true
        },
        {
            Header: "APIs",
            id: "apis",
            Cell: ({row}) => (
                <div>
                    {row.original.apis.map(apiId => {
                        const apiItem = catalogItemsData.find(item => item.identifier === apiId);

                        return (
                            <div key={apiId}>{!!apiItem ? apiItem.name : apiId}</div>
                        )
                    })}
                </div>
            ),
            disableSortBy: true
        },
        {
            Header: "Expiration Date",
            id: "expirationDate",
            accessor: original => formatDateBy(original.expirationDate, "MMM Do, YYYY")
        },
        {
            Header: "Path",
            id: "httpPath",
            accessor: "httpPath",
            disableSortBy: true
        },
        {
            Header: "Vault Path",
            id: "vaultSecretPath",
            accessor: "vaultSecretPath",
            disableSortBy: true
        }
    ], [catalogItemsData]);

    const {isReadOnlyUser} = useAuthState();

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = () => setRefreshTimestamp(Date());

    const [selectedTokenData, setSelectedTokenData] = useState(null);
    const closeTokenModal = () => setSelectedTokenData(null);

    const [deleteTokenConfirmationData, setDeleteTokenConfirmationData] = useState(null);
    const closeDeleteConfirmation = () => setDeleteTokenConfirmationData(null);

    const location = useLocation();
    const {filters: queryFilters} = location.query || {};

    const urlSearchParams = new URLSearchParams(location.search);

    const [filters, setFilters] = useState({
        tokenName: urlSearchParams.get("tokenName") || "",
        ...queryFilters
    });

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);
    
    return (
        <div className="api-tokens-page">
            <div className="table-header-container">
                <IconWithTitle
                    name="add"
                    title="New Token"
                    className="add-new-button"
                    onClick={() => setSelectedTokenData({})}
                    disabled={isReadOnlyUser}
                />
                <FilterFormWrapper
                    initialValues={{...filters}}
                    onChange={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                >
                    <TextField name="tokenName" label="Token name" />
                </FilterFormWrapper>
            </div>
            <Table
                url="tokens"
                name="tokens"
                columns={columns}
                actionsComponent={({original}) => {
                    const {id} = original;
                    const deleteTooltipId = `${id}-delete`;
                    const editTooltipId = `${id}-edit`;

                    return (
                        <div className="token-row-actions">
                            <IconWithTooltip
                                name="edit"
                                onClick={() => setSelectedTokenData(original)}
                                tooltipId={editTooltipId}
                                tooltipText="Edit token"
                                disabled={isReadOnlyUser}
                            />
                            <IconWithTooltip
                                name="delete"
                                onClick={() => setDeleteTokenConfirmationData(original)}
                                tooltipId={deleteTooltipId}
                                tooltipText="Delete token"
                                disabled={isReadOnlyUser}
                            />
                        </div>
                    );
                }}
                actionsCellWidth={80}
                refreshTimestamp={refreshTimestamp}
                filters={filters}
            />
            {!isNull(selectedTokenData) &&
                <FormModal
                    onClose={closeTokenModal}
                    formComponent={TokenForm}
                    formProps={{
                        initialData: {...selectedTokenData},
                        onFormSubmitSuccess: () => {
                            closeTokenModal();
                            refreshTableData();
                        }
                    }}
                />
            }
            {!isNull(deleteTokenConfirmationData) &&
            <DeleteTokenModal onClose={closeDeleteConfirmation} onDeleteSuccess={refreshTableData}
                                    data={deleteTokenConfirmationData}/>}
        </div>
    )
}

const Tokens = () => {
    const [{loading, data, error}] = useFetch("apiSecurity/externalCatalog");

	if (loading) {
		return <Loader />;
	}

	if (error) {
		return null;
	}

	return (
		<TokensContent catalogItemsData={!isNull(data) ? data.items : []} />
	)
}

export default Tokens;