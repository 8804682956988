import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RUNTIME_WORKLOADS_PAGE_PATH} from '../utils';
import WorkloadDetails from './WorkloadDetails';
import WorkloadsTable from './WorkloadsTable';
import { FilterContext, RUNTIME_WORKLOAD_FILTER_KEYS } from './Filter';

export {
    RUNTIME_WORKLOAD_FILTER_KEYS
}

const Workloads = () => (
    <FilterContext.FilterProvider>
        <Switch>
            <Route path={`${RUNTIME_WORKLOADS_PAGE_PATH}/:telemetryId`} component={WorkloadDetails} />
            <Route path={RUNTIME_WORKLOADS_PAGE_PATH} component={WorkloadsTable} />
        </Switch>
    </FilterContext.FilterProvider>
)

export default Workloads;