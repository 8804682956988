import React from 'react';
import { CD_PIPELINE_FINDINGS_RISKS, SYSTEM_RISKS } from 'utils/systemConsts';
import MiniDashboardWrapper, { RiskDashboardItem } from 'components/MiniDashboardWrapper';
import { FINDINGS_TYPES_MAP } from './utils';

const getFindingsItemByType = (securityFinding, type) => securityFinding.find(item => item.type === type);

const FindinsIconItem = ({id, risk, count, label, icon}) => (
    <RiskDashboardItem
        id={`${id}-${label}`}
        icon={icon}
        count={count}
        risk={risk === CD_PIPELINE_FINDINGS_RISKS.NO_RISK.value ? "" : SYSTEM_RISKS[risk]}
        customLabel={label}
    />
)

const CdScanSummary = ({id, securityFinding}) => {
    const permissions = getFindingsItemByType(securityFinding, FINDINGS_TYPES_MAP.PERMISSIONS.value).value;
    const securityContext = getFindingsItemByType(securityFinding, FINDINGS_TYPES_MAP.SECURITY_CONTEXT.value).value;
    const secrets = getFindingsItemByType(securityFinding, FINDINGS_TYPES_MAP.SECRETS.value).value;

    return (
        <MiniDashboardWrapper className="cd-scan-summary-container">
            <FindinsIconItem id={id} {...permissions} {...FINDINGS_TYPES_MAP.PERMISSIONS} />
            <FindinsIconItem id={id} {...securityContext} {...FINDINGS_TYPES_MAP.SECURITY_CONTEXT} />
            <FindinsIconItem id={id} {...secrets} {...FINDINGS_TYPES_MAP.SECRETS} />
        </MiniDashboardWrapper>
    )
};

export default CdScanSummary;