import React from 'react';
import classnames from 'classnames';
import { RULE_ACTIONS } from 'utils/systemConsts';

export const DEFAULT_RULE_NAME = "Default rule";

export const getRuleValueByAction = (action, isBlocked) => {
    if (action === RULE_ACTIONS.ALLOW) {
        return (
            <span>Anyone <span className="relationship allowed">can</span> invoke any K8s server API on any environment unless specifically restricted by a previous rule</span>
        );
    }

    return (
        <span><span className={classnames("relationship", {blocked: isBlocked})}>No one can</span> invoke a K8s server API on any environment unless specifically allowed in a previous rule</span>
    );
}