import React, { useEffect } from 'react';
import { useAuthDispatch, AUTH_ACTIONS } from 'context/AuthProvider';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { useFetch, FETCH_METHODS, usePrevious } from 'hooks';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Loader from 'components/Loader';
import LicenseText from './LicenseText';

import "./license-modal.scss";

const LicenseModal = () => {
    const authDispatch = useAuthDispatch();

    const {isTrialAccount, loadingDisplay} = useDisplayConfigState();

    const [{loading, error}, submitAcceptEula] = useFetch("users/acceptEula", {loadOnMount: false});
    const prevLoading = usePrevious(loading);

    useEffect(() => {
        if (prevLoading && !loading && !error) {
            authDispatch({type: AUTH_ACTIONS.HIDE_EULA_DISPLAY});
        }
    }, [prevLoading, loading, error, authDispatch]);

    return (
        <Modal className="license-form-modal" allowClose={false} center={true} loading={loading}>
            {loadingDisplay ? <Loader /> :
                <React.Fragment>
                    <div className="license-icon"></div>
                    <div className="license-title">{isTrialAccount ? "Free tier license" : "End user license agreement"}</div>
                    <LicenseText />
                    <Button onClick={() => submitAcceptEula({method: FETCH_METHODS.POST})}>I Agree</Button>
                </React.Fragment>
            }
        </Modal>
    )
}

export default LicenseModal;