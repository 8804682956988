import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { SelectField } from 'components/Form';
import Table, { FilterFormWrapper } from 'components/Table';
import BreadcrumbsHeader from 'components/BreadcrumbsHeader';
import { PermissionsVulnerabilitiesSummary, PERMISSIONS_PAGE_PATH, getPermissionsInfoCountByRisk,
    PERMISSIONS_RISK_ITEMS, BREADCRUMBS_TITLE, FILTER_KEYS } from './utils';
import SystemOwnersToggle from './SystemOwnersToggle';

const GeneralPermissions = () => {
    const columns = useMemo(() => [
        {
            Header: "Cluster",
            id: "clusterName",
            accessor: "cluster",
            disableSortBy: true,
        },
        {
            Header: "Owners",
            id: "permissionRisk",
            Cell: ({row}) => {
                const {permissionInfo, clusterId} = row.original;
                const high = getPermissionsInfoCountByRisk(permissionInfo, PERMISSIONS_RISK_ITEMS.HIGH.value);
                const medium = getPermissionsInfoCountByRisk(permissionInfo, PERMISSIONS_RISK_ITEMS.MEDIUM.value);
                const no_risk = getPermissionsInfoCountByRisk(permissionInfo, PERMISSIONS_RISK_ITEMS.NO_RISK.value);
                const approved = getPermissionsInfoCountByRisk(permissionInfo, PERMISSIONS_RISK_ITEMS.APPROVED.value);
                const total = high + medium + no_risk + approved;

                return (
                    <PermissionsVulnerabilitiesSummary
                        total={total}
                        high={high}
                        medium={medium}
                        no_risk={no_risk}
                        approved={approved}
                        id={clusterId}
                    />
                );
            },
            defaultCanSort: true,
            minWidth: 250
        }
    ], []);

    const history = useHistory();

    const location = useLocation();
    const {filters: queryFilters} = location.query || {};

    const {permissionsIncludeSystemOwners: includeSystemOwners} = useDisplayConfigState();

    const [filters, setFilters] = useState({[FILTER_KEYS.PERMISSION_RISK]: "", includeSystemOwners, ...queryFilters});

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            return;
        }

        setFilters(filters => ({...filters, includeSystemOwners}));
    }, [includeSystemOwners]);

    return (
        <div className="permissions-page">
            <BreadcrumbsHeader title={BREADCRUMBS_TITLE} baseUrl={PERMISSIONS_PAGE_PATH} />
            <FilterFormWrapper
                initialValues={{...filters}}
                onChange={updatedFilters => {
                    if (!mounted.current) {
                        return;
                    }

                    setFilters(updatedFilters);
                }}
            >
                <SelectField
                    name={FILTER_KEYS.PERMISSION_RISK}
                    label="Permission Risk"
                    items={[
                        {value: "", label: "All"},
                        ...Object.values(PERMISSIONS_RISK_ITEMS)
                    ]}
                    clearable={false}
                    small
                />
            </FilterFormWrapper>
            <SystemOwnersToggle />
            <Table
                url="riskAssessment/permissions"
                name="general-risk-assessment-permissions"
                columns={columns}
                filters={filters}
                onLineClick={({clusterId}) => history.push({pathname: `${PERMISSIONS_PAGE_PATH}/${clusterId}`})}
                withPagination={false}
            />
        </div>
    )
}

export default GeneralPermissions;