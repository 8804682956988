import React from 'react';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';
import { useAuthState, useAuthDispatch, AUTH_ACTIONS } from 'context/AuthProvider';
import { useAccountParamsState } from 'context/AccountParamsProvider';
import Loader from 'components/Loader';
import Button from 'components/Button';
import { FREE_TRIAL_URL, APPLICATION_NAME, LOGIN_REDIRECT_URI, SUPPORT_EMAIL } from 'utils/systemConsts';

import Colors from 'utils/scss_variables.module.scss';
import homepageLogo from './icons/homepage-icon.svg'
import productLogo from './icons/product-logo.svg';

import './login.scss';

const TextLink = ({children, onClick}) => (
    <span style={{textDecoration: "underline", cursor: "pointer", color: Colors["color-main-light"]}} onClick={onClick}>{children}</span>
)

const LoginDescription = ({children, small=false}) => (
    <div className={classnames("login-desctiption-wrapper", {small})}>{children}</div>
)

const LoginContent = () => {
    const {oktaAuth, iamLoginUrl, isDevLogin} = useAccountParamsState();

    return (
        <div className="login-initial-wrapper">
            <LoginDescription>Welcome to a better, faster, and safer development journey.</LoginDescription>
            <div className="login-button-wrapper">
                {isDevLogin ? <Button className="login-button" onClick={() => oktaAuth.token.getWithRedirect()}>Dev Login</Button> :
                    <a href={`${iamLoginUrl}?redirectUri=${LOGIN_REDIRECT_URI}`} rel="noopener noreferrer">
                        <Button className="login-button">Log in / Sign up</Button>
                    </a>
                }
            </div>
        </div>
    );
}

const LoginError = ({authDispatcher}) => {
    const onRetry = () => authDispatcher({type: AUTH_ACTIONS.RESET_AUTHENTICATION});

    return (
        <LoginDescription small>
            {`We don't seem to recognize your ${APPLICATION_NAME} account. Please `}
            <TextLink onClick={onRetry}>try again</TextLink>
            , sign up to a <a href={FREE_TRIAL_URL} target="_blank" rel="noopener noreferrer">free account</a>
            {` or `}<a href={`mailto:${SUPPORT_EMAIL}`}>contact the support</a>.
        </LoginDescription>
    );
}

const Login = ({isAuthenticateError}) => {
    const authDispatcher = useAuthDispatch();

    return (
        <div className="login-page">
            <div className="background">
                <img src={homepageLogo} alt="homepage" className="homepage-icon" />
            </div>
            <div className="content-wrapper">
                <img src={productLogo} alt="product logo" className="product-icon" />
                {isAuthenticateError ? <LoginError authDispatcher={authDispatcher} /> : <LoginContent authDispatcher={authDispatcher} />}
            </div>
        </div>
    )
}

const LoginWrapper = () => {
    const {isAuthenticated, isLoading, isAuthenticateError} = useAuthState();
    const {isLoading: oktaIsLoading, isLoadingError} = useAccountParamsState();

    if (isLoading || oktaIsLoading) {
        return <Loader />;
    }

    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    if (isLoadingError) {
        return <div style={{margin: "20px"}}>The server is not available</div>
    }

    return <Login isAuthenticateError={isAuthenticateError} />
}

export default LoginWrapper;