import React from 'react';
import { Link } from 'react-router-dom';
import { SERVERLESS_CLOUD_ACCOUNT_PAGE_PATH } from 'layout/Serverless/CloudAccount';
import { SERVERLESS_CLOUD_ACCOUNT_FILTER_KEYS } from 'layout/Serverless/CloudAccount/FiltersForm';
import { SERVERLESS_POLICY_URL } from 'layout/Policies/ServerlessPolicy';
import RuleNamesLinks from 'layout/Policies/RuleNamesLinks';
import DisplaySection from 'components/DisplaySection';
import KeyValueList from 'components/KeyValueList';
import RiskTag from 'components/RiskTag';
import DoublePaneDisplay from 'components/DoublePaneDisplay';
import { ModalTitleDataItem } from 'components/Modal';
import RuleActionDisplay from 'components/RuleActionDisplay';
import RiskReasonsDisplay from './RiskReasonsDisplay';

import './tab-details.scss';

const MoreDetailsLink = ({name, pathname, filters}) => (<Link to={{pathname, query: {filters}}}>{name}</Link>);

const TabDetails = ({data}) => {
    const {risk, cloudAccount, arn, region, violation} = data;
    const {functionRisk, reasons: riskReasons} = risk;
    const {name, cloudProvider} = cloudAccount || {};
    const {defaultRule, userRule, ruleAction, unidentifiedServerlessRule} = violation || {};
    
    return (
        <DoublePaneDisplay
            className="function-details-tab-wrapper"
            leftPane={() => (
                <DisplaySection
                    title={<div style={{display: "flex", alignItems: "center"}}><span style={{marginRight: "5px"}}>Function risk:</span><RiskTag risk={functionRisk} style={{marginLeft: "3px"}} /></div>}>
                    <RiskReasonsDisplay riskReasons={riskReasons}/>
                </DisplaySection>
            )}
            rightPane={() => (
                <DisplaySection title="Function details">
                    <KeyValueList items={[
                        {label: "Function Identifier", value: arn || ""}
                    ]}/>
                    <KeyValueList items={[
                        {label: "Cloud provider", value: cloudProvider || ""},
                        {label: "Region", value: region || ""},
                        {
                            label: "Cloud Account name", value: (
                                <ModalTitleDataItem>
                                    <MoreDetailsLink
                                        name={name}
                                        pathname={SERVERLESS_CLOUD_ACCOUNT_PAGE_PATH}
                                        filters={{
                                            [SERVERLESS_CLOUD_ACCOUNT_FILTER_KEYS.ACCOUNT_NAME]: name
                                        }}
                                    />
                                </ModalTitleDataItem>)
                        }
                    ]}/>
                    <KeyValueList items={[
                        {label: "Rule name", value: (
                            <RuleNamesLinks
                                pathname={SERVERLESS_POLICY_URL}
                                defaultRule={defaultRule}
                                userRule={userRule}
                                implicitRule={unidentifiedServerlessRule}
                            />
                        )},
                        {label: "Rule action", value: <RuleActionDisplay action={ruleAction} />},
                        {hideEmpty: true}
                    ]}/>
                </DisplaySection>
            )}
        />
    )
}

export default TabDetails;