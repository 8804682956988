import React, { useEffect } from 'react';
import { useFetch, usePrevious, FETCH_METHODS, useProgressLoaderReducer, PROGRESS_LOADER_ACTIONS } from 'hooks';
import Button from 'components/Button';

export const ADVISOR_TYEPS = {
    ENVIRONMENT: "ENVIRONMENT",
    POD_SECURITY_STANDARD: "POD_SECURITY_STANDARD",
    CONNECTION_RULES: "CONNECTION_RULES",
    DEPLOYMENT_RULES: "DEPLOYMENT_RULES",
    API_RULES: "API_RULES"
}

const SCAN_STATUSES = {
    RUNNING: "RUNNING",
    STOPPED: "STOPPED"
}

const AdvisorScanIndicator = ({advisorType, refreshData}) => {
    const [{status: scanState}, dispatch] = useProgressLoaderReducer({
        statusUrl: `advisor/queue/${advisorType}`,
        formatResponse: (status) => ({status}),
        inititalStatus: null,
        inProgressStatus: SCAN_STATUSES.RUNNING
    });
    const prevScanState = usePrevious(scanState);

    const [{loading: runLoading, error: runError}, runScan] = useFetch("advisor/run", {loadOnMount: false});
    const prevRunLoading = usePrevious(runLoading);

    useEffect(() => {
        if (prevRunLoading && !runLoading && !runError) {
            dispatch({type: PROGRESS_LOADER_ACTIONS.DO_LOAD_STATUS});
        }
    }, [prevRunLoading, runLoading, runError, dispatch]);

    useEffect(() => {
        if (prevScanState === SCAN_STATUSES.RUNNING && scanState === SCAN_STATUSES.STOPPED) {
            refreshData();
        }
    }, [prevScanState, scanState, refreshData]);

    if (scanState === SCAN_STATUSES.STOPPED) {
        return (
            <Button disabled={runLoading} onClick={() => runScan({queryParams: {policyAdvisorType: advisorType}, method: FETCH_METHODS.POST})}>
                Run now
            </Button>
        )
    }

    if (scanState === SCAN_STATUSES.RUNNING) {
        return (
            <div className="advisor-scan-in-progress-wrapper">In progress</div>
        )
    }

    return null;
}

export default AdvisorScanIndicator;