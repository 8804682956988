import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import { isNull, isEmpty, isBoolean } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { useDelete, useFetch, FETCH_METHODS } from 'hooks';
import Table, { tableUtils } from 'components/Table';
import FormModal from 'components/FormModal';
import IconWithTooltip from 'components/IconWithTooltip';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Button from 'components/Button';
import ModalConfirmation from 'components/ModalConfirmation';
import VulnerabilitiesSummary from 'components/VulnerabilitiesSummary';
import { formatDate } from 'utils/generalUtils';
import CisSummary from 'layout/RiskAssessment/CisSummary';
import { FiltersForm, FilterContext } from '../Filter';
import ImageForm from './ImageForm';

import './images-table.scss';

const COLUMN_IDS = {
    RISK: "RISK", 
    CIS : "CIS",
    TIME: "TIME"
}

const IMAGE_SOURCES = {
    JENKINS_PLUGIN_CI: "Jenkins CI plugin",
    DOCKER_PLUGIN_CI: "Docker CI plugin",
    RISK_ASSESSMENT: "Runtime Scan",
    JFROG_XRAY: "JFrog XRay",
    RUNTIME: "Runtime"
}

const Images = () => {
    const {path} = useRouteMatch();
	const history = useHistory();

    const [imageData, setImageData] = useState(null);
    const closeImageForm = () => setImageData(null);

    const columns = useMemo(() => [
        {
            Header: "Image Name",
            id: "IMAGE_NAME",
            Cell: ({row}) => {
                const {id, imageName} = row.original;

                return (
                    <Button tertiary className="image-link-button" onClick={() => history.push(`${path}/${id}`)}>
                        {imageName}
                    </Button>
                );
            },
            minWidth: 250
        },
        {
            Header: "Image Tags",
            id: "imageTags",
            accessor: original => original.imageTags.join(", "),
            disableSortBy: true
        },
        {
            Header: "Licenses",
            id: "licenses",
            Cell: ({row}) => (
                <tableUtils.ExpendableTagsCell items={row.original.licenses} />
            ),
            disableSortBy: true
        },
        {
            Header: "Image ID",
            id: "IMAGE_ID",
            Cell: ({row}) => {
                const {imageHash} = row.original;
                
                return (
                    <div style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{imageHash}</div>
                );
            },
            disableSortBy: true
        },
        {
            Header: "Time Added",
            id: COLUMN_IDS.TIME,
            accessor: original => formatDate(original.timeAdded)
        },
        {
            Header: "Source",
            id: "imageSourceType",
            accessor: original => IMAGE_SOURCES[original.imageSourceType],
            disableSortBy: true
        },
        {
            Header: "Status",
            id: "status",
            Cell: ({row}) => {
                const {isIdentified, id} = row.original;

                return (
                    <IconWithTooltip
                        name={isIdentified ? ICON_NAMES.IMAGE_APPROVED : ICON_NAMES.IMAGE_UNIDENTIFIED}
                        className={classnames("status-icon", {approved: isIdentified})}
                        tooltipId={`status-tooltip-${id}`}
                        tooltipText={isIdentified ? "Image identified" : "Image source unknown"}
                    />
                );
            },
            disableSortBy: true,
            minWidth: 50,
            maxWidth: 50
        },
        {
            Header: "Vulnerabilities",
            id: COLUMN_IDS.RISK,
            Cell: ({row}) => {
                const {id, vulnerabilitiesSummary: summaryData, isScanned} = row.original;

                if (!isScanned) {
                    return <div>Image was not scanned</div>;
                }
                
                return (
                    <VulnerabilitiesSummary {...summaryData} id={id} />
                );
            },
            defaultCanSort: true,
            minWidth: 330
        },
        {
            Header: "CIS Benchmark",
            id: COLUMN_IDS.CIS,
            Cell: ({row}) => {
                const {id, dockerfileScanResultsSummary, isScanned} = row.original;
                const {total, info, warn, fatal} = dockerfileScanResultsSummary || {};
                
                if (!isScanned) {
                    return <div>Image was not scanned</div>;
                }

                return (
                    <CisSummary total={total} totalTooltipId={`total-cis-${id}`} info={info} warning={warn} fatal={fatal} id={id} />
                );
            },
            disableSortBy: true,
            minWidth: 250
        }
    ], [history, path]);

    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = () => setRefreshTimestamp(Date());

    const {isReadOnlyUser} = useAuthState();

    const location = useLocation();
    const {filters: queryFilters} = location.query || {};

    const filters = FilterContext.useFilterState();
    const filterDispatch = FilterContext.useFilterDispatch();

    const [{deleting}, deleteImage] = useDelete("images");

    const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationId(null);

    const [{loading: approveLoading}, updateApprove] = useFetch("images/approve", {loadOnMount: false});

    const doUpdateApprove = (selectedIds, approve) => updateApprove({
        method: FETCH_METHODS.POST,
        submitData: {uuidList: selectedIds},
        formatUrl: url => `${url}?isImageApproved=${approve}`
    });

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);
    
    return (
        <div className="images-page">
            <div className="table-header-container">
                <IconWithTitle
                    name={ICON_NAMES.ADD}
                    title="New Image"
                    className="add-new-button"
                    onClick={() => setImageData({})}
                    disabled={isReadOnlyUser}
                />
                <FiltersForm
                    filters={filters}
                    queryFilters={queryFilters}
                    setFilters={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        FilterContext.setFilters(filterDispatch, updatedFilters);
                    }}
                />
            </div>
            <Table
                url="images"
                name="images"
                columns={columns}
                filters={filters}
                defaultSortBy={[{id: COLUMN_IDS.TIME, desc: true}]}
                exportToExcelCustomUrl="accountVulnerabilitiesXlsx"
                isLoading={deleting || approveLoading || !(isBoolean(filters.filterLoading) && !filters.filterLoading)}
                refreshTimestamp={refreshTimestamp}
                withMultiSelect={!isReadOnlyUser}
                actionsComponent={({original}) => {
                    const {id} = original;
                    const deleteTooltipId = `${id}-delete`;

                    return (
                        <div className="image-row-actions">
                            <IconWithTooltip
                                name={ICON_NAMES.DELETE}
                                onClick={() => setDeleteConfirmationId(id)}
                                disabled={isReadOnlyUser}
                                tooltipId={deleteTooltipId}
                                tooltipText="Delete Image"
                            />
                        </div>
                    );
                }}
                actionsCellWidth={50}
                rowSelectActionComponent={({selectedIds}) => (
                    <div className="image-approve-actions">
                        <IconWithTitle
                            name={ICON_NAMES.IMAGE_APPROVED}
                            title="Approve images"
                            onClick={() => doUpdateApprove(selectedIds, true)}
                            disabled={isEmpty(selectedIds)}
                        />
                        <IconWithTitle
                            name={ICON_NAMES.IMAGE_UNIDENTIFIED}
                            title="Unapprove images"
                            onClick={() => doUpdateApprove(selectedIds, false)}
                            disabled={isEmpty(selectedIds)}
                        />
                    </div>
                )}
            />
            {!isNull(imageData) &&
                <FormModal
                    loading={false}
                    onClose={closeImageForm}
                    formComponent={ImageForm}
                    formProps={{
                        initialData: {...imageData},
                        onFormSubmitSuccess: () => {
                            closeImageForm();
                            refreshTableData();
                        }
                    }}
                />
            }
            {!isNull(deleteConfirmationId) &&
                <ModalConfirmation 
                    title="Are you sure?"
                    message="This operation cannot be reverted"
                    confirmTitle="Delete"
                    onCancel={closeDeleteConfirmation}
                    onConfirm={() => {
                        closeDeleteConfirmation();
                        deleteImage(deleteConfirmationId);
                    }}
                    confirmAlert
                />
            }
        </div>
    );
}

export default Images;