import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { APP_PATHS } from 'utils/systemConsts';
import FunctionDetails from './FunctionDetails';
import FunctionsTable from './FunctionsTable';
import { FilterContext, SERVERLESS_FUNCTION_FILTER_KEYS } from './Filter';

export const SERVERLESS_FUNCTIONS_PAGE_PATH = `${APP_PATHS.SERVERLESS.path}/functions`;

export {
    SERVERLESS_FUNCTION_FILTER_KEYS
}

const Functions = () => (
    <FilterContext.FilterProvider>
        <Switch>
            <Route path={`${SERVERLESS_FUNCTIONS_PAGE_PATH}/:id`} component={FunctionDetails} />
            <Route path={SERVERLESS_FUNCTIONS_PAGE_PATH} component={FunctionsTable} />
        </Switch>
    </FilterContext.FilterProvider>
)

export default Functions;