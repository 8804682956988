import React from 'react';
import classnames from 'classnames';
import { isEmpty, isNull } from 'lodash';
import { RULE_ACTIONS, SYSTEM_RISKS, SECRET_FINDINGS_RISKS } from 'utils/systemConsts';
import { AndList } from 'utils/generalUtils';

const ACTION_TEXT_MAP = {
    [RULE_ACTIONS.ALLOW]: "Allow",
    [RULE_ACTIONS.DETECT]: "Detect",
    [RULE_ACTIONS.BLOCK]: "Block",
};

const getFunctionPrefix = items => (` function${items.length > 1 ? "s" : ""} `);
const getRulePhrase = ({names, arns}) => {
    if (!isEmpty(names)) {
        return <span>{getFunctionPrefix(names)}{AndList(names)}</span>;
    }

    if (!isEmpty(arns)) {
        return <span>{getFunctionPrefix(arns)}{AndList(arns)}</span>;
    }

    return <span>{` any function`}</span>
}

const getScopeConnector = action => {
    if (action === RULE_ACTIONS.ALLOW) {
        return ` to run on `;
    }

    if (action === RULE_ACTIONS.BLOCK) {
        return ` from running on `;
    }

    return ' running on '; //RULE_ACTIONS.DETECT
}

const getRiskLevelPhrase = (risk, title, isAllow) => {
    if (!risk) {
        return null;
    }

    return (
        <span>{title}{` ${isAllow ? "lower" : "higher"} than `}{SYSTEM_RISKS[risk].label.toLowerCase()}</span>
    )
}

const getRuleValidationText = ({isAllow, serverlessFunctionValidation}) => {
    const {dataAccessRisk, functionPermissionRisk, isUnusedFunction, publiclyAccessibleRisk, risk, secretsRisk, vulnerability} = serverlessFunctionValidation || {};
    
    const items = [
        getRiskLevelPhrase(risk, "top risk", isAllow),
        getRiskLevelPhrase(vulnerability, "vulnerability", isAllow),
        !secretsRisk ? null : `${secretsRisk === SECRET_FINDINGS_RISKS.NO_KNOWN_RISK.value ? `doesn't ` : ""}${isAllow ? "reveal secrets" : "have visible secrets"}`,
        getRiskLevelPhrase(functionPermissionRisk, "function permissions risk", isAllow),
        !publiclyAccessibleRisk ? null : `${publiclyAccessibleRisk === SYSTEM_RISKS.NO_RISK.value ? `not ` : ""}${`public accessible`}`,
        !dataAccessRisk ? null : `${dataAccessRisk === SYSTEM_RISKS.NO_RISK.value ? "cannot" : "can"}${` access data`}`,
        ...(isNull(isUnusedFunction) ? [] : [isUnusedFunction ? "not used for a while" : "in use"])
    ]
    
    return (
        <span>{items.map((item, index, items) => (
            <React.Fragment key={index}>
                <span>{!isNull(item) ? ` ` : ""}{item}</span>
                {index < items.length - 1 && !isNull(item) && <span>{` ${isAllow ? "and" : "or"}`}</span>}
            </React.Fragment>
        ))}</span>
    )
}

const getScopePhrase = scope => {
    if (isEmpty(scope)) {
        return "all regions";
    }
    
    return (
        <span>{AndList(scope.map(({cloudAccount, regions}) => <span>{cloudAccount}{isEmpty(regions) ? "" : ` - ${regions.join(", ")}`}</span>))}</span>
    )
}

const UserRuleContent = (props) => {
    const {action, rule, scope} = props;
    const allowed = action === RULE_ACTIONS.ALLOW;
    const blocked = action === RULE_ACTIONS.BLOCK;

    return (
        <React.Fragment>
            <span className={classnames("relationship", {allowed}, {blocked})}>{ACTION_TEXT_MAP[action]}</span>
            {getRulePhrase(rule)}
            {getRuleValidationText({isAllow: allowed, serverlessFunctionValidation: rule.serverlessFunctionValidation})}
            {getScopeConnector(action)}
            {getScopePhrase(scope)}
        </React.Fragment>
    );
};

export default UserRuleContent;