import React from 'react';
import { Link } from 'react-router-dom';
import KeyValueList from 'components/KeyValueList';
import { RUNTIME_WORKLOADS_PAGE_PATH } from 'layout/Runtime/utils';
import { RUNTIME_WORKLOAD_FILTER_KEYS } from 'layout/Runtime/Workloads';
import { EMPTY_VALUE } from 'utils/systemConsts';

const MoreDetailsLink = ({name, pathname, filters}) => (
    <Link className="runtime-connection-more-details-link" to={{pathname, query: {filters}}}>{name}</Link>
);

const PodDetails = ({pod, environment, instance}) => {
    const {name: envName} = environment;
    const {name: podName} = pod;
    const {name: clusterName, namespace, id: clusterId, namespaceId} = instance;

    const PodName = (
        <MoreDetailsLink
            name={podName}
            pathname={RUNTIME_WORKLOADS_PAGE_PATH}
            filters={{
                [RUNTIME_WORKLOAD_FILTER_KEYS.WORKLOAD_NAME]: podName,
                [RUNTIME_WORKLOAD_FILTER_KEYS.ENVIRONMENT_NAME]: [envName],
                [RUNTIME_WORKLOAD_FILTER_KEYS.NAMESPACES]: [{clusterId, namespaceIds: [namespaceId]}]
            }}
        />
    )
    
    return (
        <React.Fragment>
            <KeyValueList items={[
                {label: "Name", value: PodName},
                {label: "Type", value: "Pod"},
                {label: "Environment", value: envName || EMPTY_VALUE}
            ]} />
            <KeyValueList items={[
                {label: "Cluster", value: clusterName},
                {label: "Namespace", value: namespace},
                {label: "", value: ""} //alignment
            ]} />
        </React.Fragment>
    )
}


const ExpansionDetails = ({expansion, environment, instance}) => {
    const {name: envName} = environment;
    const {name: expansionName} = expansion;
    const {name: clusterName, namespace, id: clusterId, namespaceId} = instance;

    const ExpansionName = (
        <MoreDetailsLink
            name={expansionName}
            pathname={RUNTIME_WORKLOADS_PAGE_PATH}
            filters={{
                [RUNTIME_WORKLOAD_FILTER_KEYS.WORKLOAD_NAME]: expansionName,
                [RUNTIME_WORKLOAD_FILTER_KEYS.ENVIRONMENT_NAME]: [envName],
                [RUNTIME_WORKLOAD_FILTER_KEYS.NAMESPACES]: [{clusterId, namespaceIds: [namespaceId]}]
            }}
        />
    )

    return (
        <React.Fragment>
            <KeyValueList items={[
                {label: "Name", value: ExpansionName},
                {label: "Type", value: "Expansion"},
                {label: "Environment", value: envName || EMPTY_VALUE}
            ]} />
            <KeyValueList items={[
                {label: "Cluster", value: clusterName},
                {label: "Namespace", value: namespace},
                {label: "", value: ""} //alignment
            ]} />
        </React.Fragment>
    )
}

const ExternalDetails = ({ip, fqdn}) => {
    return (
        <KeyValueList items={[
            {label: "IP", value: ip},
            {label: "FQDN", value: fqdn || EMPTY_VALUE},
            {label: "", value: ""} //alignment
        ]} />
    )
}

const WorkloadDetails = (props) => {
    const {pod, expansion} = props;

    let WokLoadDetailsComponent = ExternalDetails;
    if (!!pod && !!pod.id) {
        WokLoadDetailsComponent = PodDetails;
    } else if (!!expansion && !!expansion.id) {
        WokLoadDetailsComponent = ExpansionDetails;
    }

    return (
        <WokLoadDetailsComponent {...props} />
    )
}

export default WorkloadDetails;