import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useFetch } from 'hooks';
import { TooltipWrapper } from 'components/Tooltip';

import './tabs.scss';

const TabItem = ({title, notificationUrl, getNotificationText, selected}) => {
    const checkNotification = !!notificationUrl;
    const [{data, loading}, refreshData] = useFetch(notificationUrl, {loadOnMount: checkNotification});
    const notificationText = !!getNotificationText && !loading ? getNotificationText(data) : null;

    const {pathname} = useLocation();

    useEffect(() => {
        if (checkNotification) {
            refreshData();
        }
        
    }, [pathname, selected, checkNotification, refreshData]);
    
    
    const TabDisplay = ({showNotification}) => (
        <div className="tabs-nav-item-display">
            <div>{title}</div>
            {showNotification && <div className="tabs-nav-item-display-indicator"></div>}
        </div>
    )

    if (!!notificationText) {
        return (
            <TooltipWrapper id={`tabs-nav-item-display-${title}`} text={notificationText}>
                <TabDisplay showNotification />
            </TooltipWrapper>
        )
    }

    return (
        <TabDisplay />
    )
}

const Tabs = ({items, isRouter=true, embedded=false, onChange, defaultSelected, secondary}) => {
    const tabWrapperClassName = "tabs-nav-item";

    return (
        <div className={classnames("tabs-container", {embedded}, {secondary})}>
            {
                items.map(({id, to, exact, title, disabled, notificationUrl, getNotificationText}) => isRouter ? 
                    <NavLink key={to} className={classnames(tabWrapperClassName, {disabled})} to={to} exact={exact}>
                        <TabItem title={title} notificationUrl={notificationUrl} getNotificationText={getNotificationText} />
                    </NavLink> :
                    <div
                        key={id}
                        className={classnames(tabWrapperClassName, {active: id === defaultSelected}, {disabled})}
                        onClick={() => disabled ? null : onChange(id)}
                    >
                        <TabItem title={title} notificationUrl={notificationUrl} getNotificationText={getNotificationText} selected={defaultSelected} />
                    </div>
                )
            }
        </div>
    )
}

export default Tabs;