import { useReducer, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { RULE_STATUSES, RULE_ORIGINS } from 'utils/systemConsts';
import { useFetch, usePrevious, FETCH_METHODS } from 'hooks';
import { getGroupToUpdateByRuleId, getUpdatedList, getUserRulesWithUpdatedItem, getUniqueGroupName, formatUserRulesForSubmit,
    formatUserRulesForDisplay, getMarkedRules, scrollToFirstMarked } from './utils';
import { useLocation } from 'react-router-dom';

export const POLICY_ACTIONS = {
    DATA_LOADED: "DATA_LOADED",
    UPDATE_RULES_ORDER: "UPDATE_RULES_ORDER",
    UPDATE_GROUP_NAME: "UPDATE_GROUP_NAME",
    UPDATE_GROUP_STATUS: "UPDATE_GROUP_STATUS",
    DELETE_GROUP: "DELETE_GROUP",
    REMOVE_FROM_GROUP: "REMOVE_FROM_GROUP",
    ADD_OR_EDIT_RULE: "ADD_OR_EDIT_RULE",
    TOGGLE_RULE_STATUS: "TOGGLE_RULE_STATUS",
    DELETE_RULE: "DELETE_RULE",
    EDIT_DEFAULT_RULE: "EDIT_DEFAULT_RULE",
    EDIT_IMPLICIT_RULE: "EDIT_IMPLICIT_RULE",
    TOGGLE_IMPLICIT_RULE_STATUS: "TOGGLE_IMPLICIT_RULE_STATUS",
    SET_MARKED_USER_RULES: "SET_MARKED_USER_RULES",
    SUBMIT_DATA: "SUBMIT_DATA",
    SUBMIT_DATA_DONE: "SUBMIT_DATA_DONE",
    LOAD_POLICY_DATA: "LOAD_POLICY_DATA",
    ADD_RULE_SUGGESTIONS: "ADD_RULE_SUGGESTIONS"
}

export {
    formatUserRulesForSubmit
}

const initialState = {
    isLoading: true,
    defaultRule: null,
    implicitRule: null,
    userRules: [],
    withUnsavedEdits: false,
    isDoSubmit: false,
    submitData: null,
    isDoLoad: false,
    markedUserRuleIds: null,
    markDefault: false,
    markImplicit: false,
};

const getReducer = ({implicitRuleKey}) => (
    (state, action) => {
        const userRules = state.userRules;

        switch (action.type) {
            case POLICY_ACTIONS.DATA_LOADED: {
                const {userRules, defaultRule, ...loadedData} = action.payload;
                const implicitRule = loadedData[implicitRuleKey];
                
                return {
                    ...state,
                    isLoading: false,
                    isDoLoad: false,
                    withUnsavedEdits: false,
                    userRules: formatUserRulesForDisplay(userRules), 
                    defaultRule,
                    implicitRule
                };
            }
            case POLICY_ACTIONS.UPDATE_RULES_ORDER: {
                const {reorderedRules} = action.payload;

                return {
                    ...state,
                    userRules: reorderedRules.filter(item => !isEmpty(item.subItems)), //remove empty groups
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.UPDATE_GROUP_NAME: {
                const {groupName, newName} = action.payload;
                const groupIndex = userRules.findIndex(item => item.groupName === groupName);
                const groupToUpdate = userRules[groupIndex];

                return {
                    ...state,
                    userRules: getUpdatedList(userRules, groupIndex, [{...groupToUpdate, groupName: newName}]),
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.UPDATE_GROUP_STATUS: {
                const {groupName, enable} = action.payload;
                const groupIndex = userRules.findIndex(item => item.groupName === groupName);
                const groupToUpdate = userRules[groupIndex];
                const newStatus = enable ? RULE_STATUSES.ENABLED : RULE_STATUSES.DISABLED;
                
                const updatedItems = getUpdatedList(
                    userRules,
                    groupIndex,
                    [{...groupToUpdate, subItems: groupToUpdate.subItems.map(item => ({...item, status: newStatus}))}]
                );

                return {
                    ...state,
                    userRules: updatedItems,
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.DELETE_GROUP: {
                const {groupName} = action.payload;
                const groupIndex = userRules.findIndex(item => item.groupName === groupName);

                const updatedItems = getUpdatedList(userRules, groupIndex, []);

                return {
                    ...state,
                    userRules: updatedItems,
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.REMOVE_FROM_GROUP: {
                const {ruleId} = action.payload;
                
                const {groupIndex, groupToUpdate, ruleIndex} = getGroupToUpdateByRuleId(userRules, ruleId);
                const removedRule = groupToUpdate.subItems[ruleIndex];
                const groupName = getUniqueGroupName(userRules, removedRule.name);
                const updatedSubItems = groupToUpdate.subItems.filter(rule => rule.id !== ruleId);

                const updatedGroups = [{groupName, subItems: [removedRule], isActualGroup: false}];
                if (!isEmpty(updatedSubItems)) {
                    //is not last item in group
                    updatedGroups.unshift({...groupToUpdate, subItems: updatedSubItems});
                }

                const updatedItems = getUpdatedList(userRules, groupIndex, updatedGroups);

                return {
                    ...state,
                    userRules: updatedItems,
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.ADD_OR_EDIT_RULE: {
                const {ruleData} = action.payload;
                ruleData.ruleOrigin = RULE_ORIGINS.USER;

                let updatedItems = null;

                if (!!ruleData.id) {
                    updatedItems = getUserRulesWithUpdatedItem(userRules, ruleData.id, ruleData);
                } else {
                    updatedItems = [
                        ...userRules,
                        {
                            groupName: getUniqueGroupName(userRules, ruleData.name),
                            subItems: [{
                                ...ruleData,
                                id: String(Date.now()),
                                isNew: true,
                                status: RULE_STATUSES.ENABLED
                            }]
                        }
                    ];
                }

                return {
                    ...state,
                    userRules: updatedItems,
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.TOGGLE_RULE_STATUS: {
                const {ruleId} = action.payload;
                
                const {groupToUpdate, ruleIndex} = getGroupToUpdateByRuleId(userRules, ruleId);
                const updateRule = groupToUpdate.subItems[ruleIndex];

                const updatedItems = getUserRulesWithUpdatedItem(userRules, ruleId, {
                    ...updateRule,
                    status: updateRule.status === RULE_STATUSES.ENABLED ? RULE_STATUSES.DISABLED : RULE_STATUSES.ENABLED
                });

                return {
                    ...state,
                    userRules: updatedItems,
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.DELETE_RULE: {
                const {ruleId} = action.payload;
                
                const updatedItems = getUserRulesWithUpdatedItem(userRules, ruleId);

                return {
                    ...state,
                    userRules: updatedItems.filter(item => !isEmpty(item.subItems)),
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.EDIT_DEFAULT_RULE: {
                const {ruleData} = action.payload;
                
                return {
                    ...state,
                    defaultRule: ruleData,
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.EDIT_IMPLICIT_RULE: {
                const {ruleData} = action.payload;

                return {
                    ...state,
                    implicitRule: ruleData,
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.TOGGLE_IMPLICIT_RULE_STATUS: {
                const implicitRule = state.implicitRule;
                const ruleData = {...implicitRule, isDisabled: !implicitRule.isDisabled};
                
                return {
                    ...state,
                    implicitRule: ruleData,
                    withUnsavedEdits: true
                }
            }
            case POLICY_ACTIONS.SET_MARKED_USER_RULES: {
                return {
                    ...state,
                    markedUserRuleIds: action.payload,
                    markDefault: null,
                    markImplicit: null
                }
            }
            case POLICY_ACTIONS.SUBMIT_DATA: {
                return {
                    ...state,
                    isDoSubmit: true,
                    isLoading: true,
                    submitData: action.payload
                };
            }
            case POLICY_ACTIONS.SUBMIT_DATA_DONE: {
                const {success, data} = action.payload;
                const {userRules, ...loadedData} = data || {};

                let newState = {
                    ...state,
                    isDoSubmit: false,
                    submitData: null,
                    isLoading: false,
                    withUnsavedEdits: !success
                };

                if (success) {
                    newState = {
                        ...newState,
                        userRules: formatUserRulesForDisplay(userRules), 
                        ...loadedData
                    };
                }

                return newState;
            }
            case POLICY_ACTIONS.LOAD_POLICY_DATA: {
                return {
                    ...state,
                    isDoLoad: true
                }
            }
            case POLICY_ACTIONS.ADD_RULE_SUGGESTIONS: {
                const {suggested} = action.payload;
            
                const newGroups = suggested.map(ruleData => {
                    ruleData.isNew = true;
                    ruleData.status = RULE_STATUSES.ENABLED;
                    ruleData.ruleOrigin = RULE_ORIGINS.SYSTEM;

                    return {
                        groupName: getUniqueGroupName(userRules, ruleData.name),
                        subItems: [ruleData]
                    }
                });

                return {
                    ...state,
                    userRules: [...userRules, ...newGroups],
                    withUnsavedEdits: true
                }
            }
            default:
                return state;
        }
    }
)

function useGroupedRulesPolicyReducer({policyUrl, implicitRuleKey}) {
    const location = useLocation();
    
    const [{loading, data, error}, doPolicyRequest] = useFetch(policyUrl);
    
    const [policyState, dispatch] = useReducer(getReducer({implicitRuleKey}), {...initialState, ...getMarkedRules(location)});
    const {isDoLoad, isDoSubmit, submitData, isLoading, markedUserRuleIds, markDefault, markImplicit} = policyState;

    const prevLoading = usePrevious(loading);
    const prevIsDoLoad = usePrevious(isDoLoad);
    const prevIsDoSubmit = usePrevious(isDoSubmit);

    useEffect(() => {
        if (!prevIsDoSubmit && isDoSubmit) {
            doPolicyRequest({method: FETCH_METHODS.PUT, submitData});
        }
    }, [isDoSubmit, prevIsDoSubmit, submitData, doPolicyRequest]);

    useEffect(() => {
        if (!prevIsDoLoad && isDoLoad) {
            doPolicyRequest();
        }
    }, [isDoLoad, prevIsDoLoad, doPolicyRequest]);

    useEffect(() => {
        if (prevLoading && !loading) {
            if (isDoSubmit) {
                dispatch({type: POLICY_ACTIONS.SUBMIT_DATA_DONE, payload: {success: !error, data}});
            } else {
                dispatch({type: POLICY_ACTIONS.DATA_LOADED, payload: data});
            }
        }
    }, [loading, prevLoading, isDoSubmit, data, error]);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!markDefault && !markImplicit && isEmpty(markedUserRuleIds)) {
            return;
        }

        scrollToFirstMarked();
    }, [markedUserRuleIds, markDefault, markImplicit, isLoading]);

    return [{policyState, loading: loading || isLoading}, dispatch];
}

export default useGroupedRulesPolicyReducer;