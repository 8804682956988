import React, { useMemo, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import { useFetch, FETCH_METHODS, usePrevious } from 'hooks';
import VulnerabilityIcon from 'components/VulnerabilityIcon';
import Table, { tableUtils } from 'components/Table';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import InfoIcon from 'components/InfoIcon';
import DropdownSelect from 'components/DropdownSelect';
import Button from 'components/Button';
import { formatDate } from 'utils/generalUtils';
import { SYSTEM_RISKS } from 'utils/systemConsts';
import VulnerabilitiesInnerTable from './VulnerabilitiesInnerTable';

import './vulnerabilities.scss';

const FIT_AVAILABILITY_MAP = {
    NO_FIX: "No fix is currently available",
    PARTIAL_FIX: "Partial fix",
    FIX_AVILABLE: "Fix available"
};

const getCvssSeverity = score => {
    if (score >= 9.0) {
        return SYSTEM_RISKS.CRITICAL.value;
    }
    if (score >= 7.0) {
        return SYSTEM_RISKS.HIGH.value;
    }
    if (score >= 4.0) {
        return SYSTEM_RISKS.MEDIUM.value;
    }
    if (score >= 0.1) {
        return SYSTEM_RISKS.LOW.value;
    }

    return SYSTEM_RISKS.UNKNOWN.value;
}

const ActivateAcknowledgeForm = ({showAcknowledgeButton, onSave, disabled}) => {
    const [selectedRange, setSelectedRange] = useState(null);

    if (!showAcknowledgeButton) {
        return (
            <IconWithTitle
                name={ICON_NAMES.BELL}
                title="Activate"
                onClick={() => onSave()}
                disabled={disabled}
            />
        )
    }

    return (
        <div className="acknowledge-vulnerabilities-form">
            <IconWithTitle
                name={ICON_NAMES.BELL_CROSSED}
                title="Acknowledge"
                disabled={disabled}
            />
            {!disabled &&
                <React.Fragment>
                    <DropdownSelect
                        className="acknowledge-vulnerabilities-range-select"
                        items={[
                            {value: "ALWAYS", label: "Always"},
                            {value: "YEAR", label: "For one year"},
                            {value: "MONTH", label: "For one month"},
                            {value: "WEEK", label: "For one week"}
                        ]}
                        defaultselected={selectedRange}
                        name="selectedRange"
                        onChange={selected => setSelectedRange(selected)}
                        small
                    />
                    <Button onClick={() => onSave(selectedRange)} disabled={!selectedRange}>Save</Button>
                </React.Fragment>
            }
        </div>
    )
}

const Vulnerabilities = ({id, url, onUpdate, showIgnoredOnly, withIgnore, filters={}, withAcknowledgedStatus=false}) => {
    const columns = useMemo(() => [
        {
            Header: "Findings",
            id: "severity",
            Cell: ({row}) => {
                const {id, severity = '', cvss = {}} = row.original;
                const {score} = cvss;
                const severityClass= severity.toLowerCase();
                const title = severityClass;
                const cvssSeverity = getCvssSeverity(score);
                
                return (
                    <div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <VulnerabilityIcon severity={severity} showUnknown={true} score={score}/>
                            <div
                                className={classnames("global-risk-color text", {[severityClass]: !!severityClass})}
                                style={{textTransform: "capitalize", fontWeight: "bold", lineHeight: "10px", marginLeft: "5px"}}
                            >{title}</div>
                        </div>
                        <div style={{display: "flex", alignItems: "center", marginLeft: "3px", marginTop: "5px"}}>
                            <div>{`CVSS ${score}`}</div>
                            {cvssSeverity !== severity &&
                                <InfoIcon
                                    tooltipId={`score-severity-tooltip-${id}`}
                                    text={`Although the CVSS base impact score is ${score} (${cvssSeverity}), the linux distribution severity reflects the risk more accurately.`}
                                />
                            }
                        </div>
                    </div>
                )    
            },
            disableSortBy: true
        },
        {
            Header: "Name",
            id: "name",
            Cell: ({row}) => {
                const {name, link} = row.original;
                
                return (
                    <div><a href={link} target="_blank" rel="noopener noreferrer">{name}</a></div>
                );
            },
            disableSortBy: true
        },
        ...((withAcknowledgedStatus && showIgnoredOnly) ? [{
            Header: "Status",
            id: "status",
            Cell: ({row}) => {
                const {snoozedUntil} = row.original;
                
                return (
                    <div className="acknowledged-vulnerability-status-display">
                         <tableUtils.StatusRenderer disabledText="Acknowledged" />
                         {!!snoozedUntil && <div>{`(until ${formatDate(snoozedUntil)})`}</div>}
                    </div>
                );
            }
        }] : []),
        {
            Header: "Fix Availability",
            id: "fixAvailability",
            accessor: original => FIT_AVAILABILITY_MAP[original.vulnerabilitySources.fixAvailability],
            disableSortBy: true
        },
        {
            Header: "Description",
            id: "description",
            Cell: ({row}) => {
                const {description} = row.original;
                
                return (
                    <div style={{whiteSpace: "normal"}}>{description}</div>
                );
            },
            disableSortBy: true
        }
    ], [withAcknowledgedStatus, showIgnoredOnly]);

    const {isReadOnlyUser} = useAuthState();

    const [{loading}, updateIgnoreVulnerabilities] = useFetch(`${url}/${id}/vulnerabilities/ignore`, {loadOnMount: false});
    const preLoading = usePrevious(loading);

    const doVulnerabilitiesUpdate = ({selectedIds, range}) => updateIgnoreVulnerabilities({
        method: FETCH_METHODS.POST,
        submitData: {uuidList: selectedIds},
        queryParams: {actionType: showIgnoredOnly ? "REMOVE" : "ADD", ...(!!range ? {snoozeTime: range} : {})},
    });

    useEffect(() => {
        if (preLoading && !loading) {
            onUpdate();
        }
    }, [loading, preLoading, onUpdate]);


    return (
        <Table
            url={`${url}/${id}/vulnerabilities`}
            name="vulnerabilities"
            columns={columns}
            filters={{isIgnored: showIgnoredOnly, ...filters}}
            withMultiSelect={!isReadOnlyUser && withIgnore}
            innerRowComponenet={VulnerabilitiesInnerTable}
            rowSelectActionComponent={({selectedIds, disabled}) => {
                if (withIgnore) {
                    return (
                        <ActivateAcknowledgeForm
                            showAcknowledgeButton={!showIgnoredOnly}
                            onSave={(range) => doVulnerabilitiesUpdate({selectedIds, range})}
                            disabled={disabled}
                        />
                    )
                }

                return null;
            }}
            isLoading={loading}
        />
    )
}

export default Vulnerabilities;