import React from 'react';
import { isEqual, get } from 'lodash';
import { utils } from 'components/Form';
import { APPLICATION_NAME } from 'utils/systemConsts';

const NO_KUNERNETES_SUPPORT_MESSAGE = "Not supported when Kubernetes Security is disabled";

export const FIELD_MAPPING = {
    NAME: {
        name: "name",
        label: "Cluster name",
        tooltip: `A unique name to identify your cluster controller in ${APPLICATION_NAME}`
    },
    ORCHESTRATION: {
        name: "orchestrationType",
        label: "Orchestration",
        tooltip: "The orchestration of the cluster the controller will be installed on"
    },
    CONNECTION_CONTROL: {
        name: "enableConnectionsControl",
        label: "Add Network Security (using Istio)",
        tooltip: "Track connections to/from pods in the cluster, and enforce the Runtime Connection Policy"
    },
    ISTIO_ALREADY_INSTALLED: {
        name: "istioInstallationParameters.isIstioAlreadyInstalled",
        label: "Istio already installed",
        tooltip: "Do not deploy Istio with controller (use Istio already installed)"
    },
    ISTIO_VERSION: {
        name: "istioInstallationParameters.istioVersion",
        label: "Istio version"
    },
    INSTALL_INGRESS: {
        name: "isIstioIngressEnabled",
        label: "Install Istio Ingress Gateway",
        tooltip: `Istio Ingress Gateway will be installed as part of the Istio installation using the recommended configuration. This setting has no effect on ${APPLICATION_NAME} features.`
    },
    NAMESPCASES_ISOLATION: {
        name: "serviceDiscoveryIsolationEnabled",
        label: "Enable namespaces isolation",
        tooltip: `Service information will be synced only to proxies within the same namespace. This setting has no effect on ${APPLICATION_NAME} features.`
    },
    MULTI_CLUSTER: {
        name: "isMultiCluster",
        label: "Supports multi-cluster communication",
        tooltip: `Enabling this feature will install Istio Ingress Gateway on the cluster and will allow ${APPLICATION_NAME} to identify direct communication between pods on different clusters. When this feature is disabled, pod communication outside the cluster will be identified as external.`
    },
    INSPECT_INCOMMINT_CONNECTIONS: {
        name: "preserveOriginalSourceIp",
        label: "Inspect incoming cluster connections",
        tooltip: `Enabling this feature will allow ${APPLICATION_NAME} to identify the exact IP from which an external connection was made to the cluster. When disabled, the connection will be ignored by ${APPLICATION_NAME}.`
    },
    HOLD_APPLICATION: {
        name: "isHoldApplicationUntilProxyStarts",
        label: "Hold Application till proxy is ready",
        tooltip: `Application containers will not start until the Istio proxy is ready. This will change the default container within a pod and will require you to specify the container name in certain kubectl commands (e.g., logs, exec, etc.). This setting will affect the installation order of the Envoy components and has no effect on Panoptica features.`
    },
    ENABLE_SIDECAR_RESOURCES: {
        name: "sidecarsResourcesEnabled",
        label: "Custom envoy settings",
        tooltip: `This setting allows the customization of the memory and CPU resources allocation. This setting has no effect on ${APPLICATION_NAME} features.`
    },
    TLS_INSPECTION: {
        name: "tlsInspectionEnabled",
        label: "Enable TLS Inspection",
        tooltip: `This feature allows ${APPLICATION_NAME} to decrypt and re-encrypt HTTPS traffic originating from the cluster, in order for the connection to be inspected by the Connection Policy, and to identify its Layer 7 attributes.`
    },
    CI_HASH_VALIDATION: {
        name: "ciImageValidation",
        label: "CI scanning",
        tooltip: "Identify pods only if the image hash matches the value generated by the CI plugin or entered manually in the UI"
    },
    CI_SIGNATURE_VALIDATION: {
        name: "ciImageSignatureValidation",
        label: "Image signing"
    },
    CD_SCANNING: {
        name: "cdPodTemplate",
        label: "CD scanning",
        tooltip: `Identify pods whose template originated from the ${APPLICATION_NAME} CD plugin`
    },
    RESTRICT_REGISTRIES: {
        name: "restrictRegistires",
        label: "Restrict Registries",
        tooltip: `Identify pods whose images originated from trusted registries as defined within ${APPLICATION_NAME}`
    },
    PERSISTENT_STORAGE: {
        name: "isPersistent",
        label: "Persistent storage",
        tooltip: `Allow the controller to save the policy persistently, to be available after a pod restart, instead of pulling it again from ${APPLICATION_NAME}. Requires 128MB storage on the controller pod.`
    },
    CONTROLLER_REPLICAS: {
        name: "minimalNumberOfControllerReplicas",
        label: "Minimal number of controller replicas",
        tooltip: `Consider increasing the number of replicas when installing in a high availability cluster, to avoid ${APPLICATION_NAME} downtime.`
    },
    ENABLE_INTERNAL_REGISTRY: {
        name: "internalRegistryParameters.internalRegistryEnabled",
        label: "Use internal Registry",
        tooltip: `Specify a local registry for the ${APPLICATION_NAME} cluster controller images. This step will require ${APPLICATION_NAME} images to be pulled into the local registry before installing the cluster controller.`
    },
    INTERNAL_REGISTRY: {
        name: "internalRegistryParameters.internalRegistry",
        label: "Internal registry",
        placeholder: "my.repository.org.net/mypath"
    },
    ENABLE_PROXY: {
        name: "proxyConfiguration.enableProxy",
        label: "External HTTPS proxy",
        tooltip: `When your cluster requires communication to pass via an external proxy, specify the HTTP proxy address to be used by ${APPLICATION_NAME} for the controller communication not to be blocked.`
    },
    PROXY_ADDRESS: {
        name: "proxyConfiguration.httpsProxy",
        label: "Proxy Address",
        placeholder: "https://127.0.0.1:3001"
    },
    AUTO_LABEL: {
        name: "autoLabelEnabled",
        label: "Auto-label new namespaces",
        getTooltip: ({ connectionControlEnabled, enableApiSecurity, enableKubernetesSecurity }) => {
            if (!enableKubernetesSecurity) {
                return NO_KUNERNETES_SUPPORT_MESSAGE;
            }

            return (
                `Automatically label new namespaces with ${APPLICATION_NAME}'s 'SecureApplication-protected=${connectionControlEnabled ? "full" : "deployment-only"}' label, used for policy enforcement ${!enableApiSecurity ? "" : " and internal catalog inventory identification"}`
            )
        }
    },
    ENABLE_EXTERNAL_CA: {
        name: "useExternalCA",
        label: "External CA integration",
        tooltip: `Configure the ${APPLICATION_NAME} controller to use the external CA defined in the cluster, instead on the default Istio external CA.`
    },
    FAIL_CLOSE: {
        name: "agentFailClose",
        label: "Fail close",
        tooltip: `When enabled, pods and connections will be blocked if the ${APPLICATION_NAME} controller is not responding.`
    },
    API_TESTING: {
        name: "apiIntelligenceDAST",
        label: "Enable fuzz test option on APIs"
    },
    API_TOKEN_INJECTION: {
        name: "tokenInjectionEnabled",
        label: "API token injection",
        getTooltip: ({ enableKubernetesSecurity }) => enableKubernetesSecurity ? null : NO_KUNERNETES_SUPPORT_MESSAGE
    },
    API_EXTERNAL_GATEWAYS: {
        name: "supportExternalTraceSource",
        label: "External gateways",
        tooltip: "Enable external API gateways to supply traces to the cluster controller"
    },
    API_ISTIO: {
        name: "installEnvoyTracingSupport",
        getLabel: ({ installingIstio }) => `${installingIstio ? "" : "Pre-installed "}Istio`,
        getTooltip: ({ installingIstio }) => installingIstio ? null : "Your istio's version should be higher than 1.11.X"
    },
    ENABLE_KUBERNETES_SECURITY: {
        name: "kubernetesSecurity",
        label: "Kubernetes Security",
        tooltip: "Enable runtime policy enforcement"
    },
    ENABLE_API_SECURITY: {
        name: "installTracingSupport",
        label: "API Security",
        tooltip: "Install the relevant components, which enable API tracing capabilities"
    },
    ENABLE_AUTO_UPDATE: {
        name: "autoUpgradeControllerVersion",
        label: "Auto update",
        tooltip: "The controller will be updated automatically when it's possible"
    },
    ENABLE_KUBERNETES_EVENTS: {
        name: "k8sEventsEnabled",
        label: "Enable sending K8s events",
        getTooltip: ({ enableKubernetesSecurity }) => enableKubernetesSecurity ? null : NO_KUNERNETES_SUPPORT_MESSAGE
    },
    DISABLE_SSH_PROBING: {
        name: "sshMonitorDisabled",
        label: "Disable SSH probing",
        tooltip: `The ${APPLICATION_NAME} controller probes each pod to ensure that the SSH port (22) is not open, which would be a security risk. If you need to disable this probe, select Yes. (Recommended setting: No)`
    },
    SCAN_CONFIG_VULNERABILITIES: {
        name: "scanConfiguration.scanTypes_vulnerabilities",
        label: "Vulnerabilities"
    },
    SCAN_CONFIG_DOCKER_CIS: {
        name: "scanConfiguration.scanTypes_docker_cis",
        label: "Docker CIS Benchmark"
    },
    SCAN_CONFIG_PARALLEL_SCANNERS: {
        name: "scanConfiguration.numberOfScanners",
        label: "Max no. of parallel scanners"
    }
}

export const ReinstallMessage = ({ isReinstall }) => (
    <utils.FormNotificationMessage secondary>
        {`Changes will only be applied after the controller is ${isReinstall ? "re" : ""}installed`}
    </utils.FormNotificationMessage>

);

const getAlwaysUpdateProps = formValues => {
    const isMultiCluster = get(formValues, FIELD_MAPPING.MULTI_CLUSTER.name);
    const orchestrationType = get(formValues, FIELD_MAPPING.ORCHESTRATION.name);
    const tokenInjectionEnabled = get(formValues, FIELD_MAPPING.API_TOKEN_INJECTION.name);
    const enablenternalRegistry = get(formValues, FIELD_MAPPING.ENABLE_INTERNAL_REGISTRY.name);
    const internalRegistry = get(formValues, FIELD_MAPPING.INTERNAL_REGISTRY.name);
    const kubernetesSecurityEnabled = get(formValues, FIELD_MAPPING.ENABLE_KUBERNETES_SECURITY.name);
    const k8sEventsEnabled = get(formValues, FIELD_MAPPING.ENABLE_KUBERNETES_EVENTS.name)

    return { isMultiCluster, tokenInjectionEnabled, orchestrationType, enablenternalRegistry, internalRegistry, kubernetesSecurityEnabled, k8sEventsEnabled };
}

const getConditionUpdateProps = formValues => {
    const { proxyConfiguration, istioIngressAnnotations, sidecarsResources } = formValues;

    const agentFailClose = get(formValues, FIELD_MAPPING.FAIL_CLOSE.name);
    const enableConnectionsControl = get(formValues, FIELD_MAPPING.CONNECTION_CONTROL.name);
    const installTracingSupport = get(formValues, FIELD_MAPPING.ENABLE_API_SECURITY.name);
    const isHoldApplicationUntilProxyStarts = get(formValues, FIELD_MAPPING.HOLD_APPLICATION.name);
    const isIstioIngressEnabled = get(formValues, FIELD_MAPPING.INSTALL_INGRESS.name);
    const serviceDiscoveryIsolationEnabled = get(formValues, FIELD_MAPPING.NAMESPCASES_ISOLATION.name);
    const tlsInspectionEnabled = get(formValues, FIELD_MAPPING.TLS_INSPECTION.name);
    const isPersistent = get(formValues, FIELD_MAPPING.PERSISTENT_STORAGE.name);

    return {
        agentFailClose,
        enableConnectionsControl,
        isPersistent,
        proxyConfiguration,
        installTracingSupport,
        isHoldApplicationUntilProxyStarts,
        isIstioIngressEnabled,
        istioIngressAnnotations,
        serviceDiscoveryIsolationEnabled,
        sidecarsResources,
        tlsInspectionEnabled
    }
}

export const showUpdateMessage = ({ initialValues, formValues }) => {
    const { autoUpgradeControllerVersion } = formValues;

    const isIstioAlreadyInstalled = get(formValues, FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.name);
    const istioVersion = get(formValues, FIELD_MAPPING.ISTIO_VERSION.name);

    const isIstioAlreadyInstalledInitial = get(initialValues, FIELD_MAPPING.ISTIO_ALREADY_INSTALLED.name);
    const istioVersionInitial = get(initialValues, FIELD_MAPPING.ISTIO_VERSION.name);

    const alwaysUpdatePropsCurr = getAlwaysUpdateProps(formValues);
    const alwaysUpdatePropsInitital = getAlwaysUpdateProps(initialValues);

    if (!isEqual(alwaysUpdatePropsCurr, alwaysUpdatePropsInitital)) {
        //always requires an update
        return true;
    }

    if (autoUpgradeControllerVersion) {
        return false;
    }

    const conditionUpdatePropsCurr = getConditionUpdateProps(formValues);
    const conditionUpdatePropsInitital = getConditionUpdateProps(initialValues);

    const istioAlreadyInstalledChanged = isIstioAlreadyInstalled !== isIstioAlreadyInstalledInitial;
    const istioVersionChanged = istioVersion !== istioVersionInitial;
    const istioParamsChanged = istioAlreadyInstalledChanged || (!istioAlreadyInstalledChanged && isIstioAlreadyInstalledInitial && istioVersionChanged);

    if (!isEqual(conditionUpdatePropsCurr, conditionUpdatePropsInitital) || istioParamsChanged) {
        return true
    }

    return false;
}
