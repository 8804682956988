import React from 'react';
import {isEmpty} from 'lodash';
import classnames from 'classnames';
import Icon from 'components/Icon';
import {FUNCTION_RISK_TYPES_MAPPING} from "layout/Serverless/utils";

import './tab-details.scss';

const RiskReasonsDisplay = ({riskReasons}) => (
    <div className="risk-reasons-wrapper">
        {
            isEmpty(riskReasons) ? <div className="empty-risk-reasons">No risk reasons found</div> :
                riskReasons.map(({level, type, text}, index) => {

                    return (
                        <div key={index} className="risk-reason">
                            <Icon className={classnames("global-risk-color", level.toLowerCase())}
                                  name={FUNCTION_RISK_TYPES_MAPPING[type].icon}/>
                            <div className="reason-text">
                                {text}
                            </div>
                        </div>
                    )
                })
        }
    </div>
);

export default RiskReasonsDisplay;