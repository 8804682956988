import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import ListDisplay from 'components/ListDisplay';
import MessageImageDisplay from 'layout/Apis/ApiInventory/MessageImageDisplay';

import emptySelectImage from 'layout/Apis/ApiInventory/images/empty_selection.svg';

import './select-panes.scss';

const SelectPanes = ({ selectItems, itemDisplay: ItemDisplay, selectListTitle, selectContent: SelectContent, emptyMessage, emptyContent: EmptyContent, className, displayArrows }) => {
    const [selectedItemData, setSelectedItemData] = useState(null);

    return (
        <div className={classnames("select-panes-wrapper", className)}>
            <div className="select-pane">
                <div className="select-pane-title">{selectListTitle}</div>
                <div className="select-items-wrapper">
                    <ListDisplay
                        items={selectItems}
                        itemDisplay={ItemDisplay}
                        selectedId={!!selectedItemData ? selectedItemData.id : null}
                        onSelect={selected => setSelectedItemData(selected)}
                        selectUpdatesArrow
                        displayArrows={displayArrows}
                    />
                </div>
            </div>
            <div className="display-pane">
                {
                    isEmpty(selectedItemData) ? (!!EmptyContent ? <EmptyContent /> : <MessageImageDisplay message={emptyMessage} image={emptySelectImage} />) :
                        <SelectContent data={selectedItemData} onBack={() => setSelectedItemData(null)} />
                }
            </div>
        </div>
    )
}

export default SelectPanes;