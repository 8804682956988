import React, { useState } from 'react';
import classnames from 'classnames';
import RoundIconContainer from 'components/RoundIconContainer';
import Arrow, { ARROW_NAMES } from 'components/Arrow';
import Icon, { ICON_NAMES } from 'components/Icon';

const FormTitledSection = ({children, title, className, isDefaultOpen=true, fontSize, withButtomMargin=true}) => {
    const [isOpen, setIsOpen] = useState(isDefaultOpen);

    return (
        <div className={classnames("form-titled-section", {"with-buttom-margin": withButtomMargin}, className)}>
            <div className="form-titled-section-header" onClick={() => setIsOpen(!isOpen)}>
                <div className="form-titled-section-title" style={{fontSize}}>{title}</div>
                <Arrow name={isOpen ? ARROW_NAMES.TOP : ARROW_NAMES.BOTTOM} small={true} />
            </div>
            {isOpen && <div className="form-titled-section-content">{children}</div>}
        </div>
    )
}

export const FieldAlert = ({message}) => (
    <div className="form-field-alert-wrapper">
        <RoundIconContainer name={ICON_NAMES.EXCLAMATION_MARK} />
        <div className="alert-text">{message}</div>
    </div>
);

export const InnerFieldWrapper = ({title, isVisable, children, isSecondLayer=false}) => {
    if (!isVisable) {
        return null;
    }

    return (
        <FormTitledSection title={title} fontSize="10px" className={classnames("form-inner-field-wrapper", {"is-second-layer": isSecondLayer})}>
            {children}
        </FormTitledSection>
    )
}

export const FormNotificationMessage = ({children, className, secondary=false, isError=false}) => (
    <div className={classnames("form-message-container", className, {secondary}, {error: isError})}>
        <Icon name={secondary ? ICON_NAMES.ALERT : ICON_NAMES.EXCLAMATION_MARK_ROUND} />
        <div className="message">{children}</div>
    </div>
);

export const FormInstructionsTitle = ({children}) => (
    <div className="form-insturctions-title-container">
        <div className="title-bullet"></div>
        <div className="title">{children}</div>
    </div>
);

export const FieldsCategoryContainer = ({children, title, withButtomMargin=true}) => (
    <FormTitledSection title={title} className="form-fields-category-container" fontSize="14px" withButtomMargin={withButtomMargin}>
        {children}
    </FormTitledSection>
)