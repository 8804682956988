import React, { useEffect } from 'react';
import { usePrevious } from 'hooks';
import {useFormikContext, validators, MultiselectField, SelectField} from 'components/Form';

export const RULE_SCOPE_ITEMS = {
    ENVIRONMENT: {
        value: "EnvironmentNameRuleType",
        label: "By Environment"
    },
    CLUSTER: {
        value: "ClusterNameRuleType",
        label: "By Cluster"
    },
    ANY: {
        value: "AnyRuleType",
        label: "Any"
    }
};

const FormScope = ({environmentNames, clusterNames, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();
    const {workloadRuleScopeType} = values.scope;
    const prevWorkloadRuleScopeType = usePrevious(workloadRuleScopeType);
    const ruleScopeItems = [RULE_SCOPE_ITEMS.CLUSTER, RULE_SCOPE_ITEMS.ENVIRONMENT, RULE_SCOPE_ITEMS.ANY];

    useEffect(() => {

        if (prevWorkloadRuleScopeType === workloadRuleScopeType || !prevWorkloadRuleScopeType) {
            return;
        }

        setFieldValue("scope.names", []);
    }, [prevWorkloadRuleScopeType, workloadRuleScopeType, setFieldValue]);

    return (
        <React.Fragment>
            <SelectField
                name="scope.workloadRuleScopeType"
                label="Select an option to identify the target of the rule"
                items={ruleScopeItems}
                validate={validators.validateRequired}
                clearable={false}
                disabled={isReadOnlyUser}
            />
            {workloadRuleScopeType === RULE_SCOPE_ITEMS.ENVIRONMENT.value &&
                <MultiselectField name="scope.names" items={environmentNames} validate={validators.validateRequired} disabled={isReadOnlyUser} />}
            {workloadRuleScopeType === RULE_SCOPE_ITEMS.CLUSTER.value &&
                <MultiselectField name="scope.names" items={clusterNames} validate={validators.validateRequired} disabled={isReadOnlyUser} />}
        </React.Fragment>
    )
}

export default FormScope;