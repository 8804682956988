import React, { useEffect } from 'react';
import { isNull } from 'lodash';
import { useAuthState, useAuthDispatch, logoutUser } from 'context/AuthProvider';
import { useDisplayConfigState, useDisplayConfigDispatch, DISPLAY_CONFIG_ACTIONS } from 'context/DisplayConfigProvider';
import { useAccountParamsState } from 'context/AccountParamsProvider';
import { useFetch, usePrevious } from 'hooks';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Arrow from 'components/Arrow';
import DropdownButton from 'components/DropdownButton';
import Loader from 'components/Loader';
import LineLoader from 'components/LineLoader';
import { REGULAR_USER_ROLES_ITEMS, GLOBAL_ADMIN_USER_ROLES_ITEMS } from 'utils/systemConsts';

import COLORS from 'utils/scss_variables.module.scss';

import './user-menu.scss';

const ROLES_MAP = {...REGULAR_USER_ROLES_ITEMS, ...GLOBAL_ADMIN_USER_ROLES_ITEMS};

const UsageBar = ({count, max, title, color, noLimit=false}) => {
    const formattedCount = count > max ? max : count;
    const lineProps = noLimit ? {done: 1, total: 1, color} : {done: formattedCount, total: max, color: max < count ? COLORS["color-error"] : color};
    
    return (
        <div className="usage-bar-container">
            <div className="usage-bar-container-title">{title}</div>
            <LineLoader {...lineProps} small />
            <div className="usage-bar-container-counts">{noLimit ? count : `${count}/${max}`}</div>
        </div>
    )
}

const UserMenuContent = () => {
    const {accountTypeDisplay, isEnterpriseAccount, isProAccount, loadingDisplay} = useDisplayConfigState();
    const displayConfigDispatch = useDisplayConfigDispatch();
    const {upgradeUrl} = useAccountParamsState();

    const [{loading, data}] = useFetch("account/usageStatus");
    const prevLoading = usePrevious(loading);
    const {currentClustersUsage, maxClusters, currentNodesUsage, maxNodes} = data || {};

    const dispatch = useAuthDispatch();
    const logout = () => logoutUser(dispatch);

    useEffect(() => {
        if (prevLoading && !loading) {
            displayConfigDispatch({type: DISPLAY_CONFIG_ACTIONS.UPDATE_USAGE_DATA, payload: data});
        }
    }, [prevLoading, loading, data, displayConfigDispatch]);

    return (
        <div className="user-menu-content">
            <div className="account-data-wrapper">
                {loadingDisplay || loading ? <Loader absolute={false} /> : 
                    <React.Fragment>
                        <div className="account-type">{accountTypeDisplay}</div>
                        <div>
                            <UsageBar title="Clusters" count={currentClustersUsage} max={maxClusters} color={COLORS["color-status-blue"]} noLimit={isNull(maxClusters)} />
                            <UsageBar title="Nodes" count={currentNodesUsage} max={maxNodes} color={COLORS["color-success"]} noLimit={isNull(maxNodes)} />
                        </div>
                        {!isEnterpriseAccount && !isProAccount &&
                            <a className="upgrade-link" href={upgradeUrl} target="_blank" rel="noopener noreferrer">
                                Upgrade account to unlock more capabilities
                            </a>
                        }
                    </React.Fragment>
                }
            </div>
            <IconWithTitle className="sign-out-title-icon" name={ICON_NAMES.SIGN_OUT} title="Sign Out" onClick={logout} />
        </div>
    )
}

const UserMenu = () => {
   const {role, fullName} = useAuthState();

    if (!fullName) {
        return null;
    }
    
    const fullNameArr = fullName.split(" ").filter(item => item !== "");
    const userDisplay = fullNameArr.length > 1 ? `${fullNameArr[0][0]}${fullNameArr[fullNameArr.length - 1][0]}` : fullNameArr[0][0];

    const roleItem = ROLES_MAP[role];
    
    return (
        <div className="user-menu">
            <div className="user-round-icon">{userDisplay.toUpperCase()}</div>
                <div className="user-data-container">
                    <DropdownButton 
                        toggleButton={<div className="user-menu-btn">{fullName}<Arrow name="bottom" small /></div>} 
                        className="user-menu-container"
                    >
                        <UserMenuContent />
                    </DropdownButton>
                <div className="user-role-container">{!!roleItem ? roleItem.label : ""}</div>
            </div>
        </div>
    )
}

export default UserMenu;
