import React from 'react';
import { isEmpty } from 'lodash';
import DoublePaneDisplay from 'components/DoublePaneDisplay';
import PodSpecsDisplay from './PodSpecsDisplay';
import ContainerSpecsDisplay from './ContainerSpecsDisplay';
import PspViolationReasonsDisplay from './PspViolationReasonsDisplay';

import './tab-security-context.scss';

const TabSecurityContext = ({data}) => {
    const {podSpecInfo, violation} = data;
    const {containers, initContainers, podSecurityContext, ...pspInfo} = podSpecInfo;
    const {pspViolationReasons} = violation; 
    const allowContainerClose = [...initContainers, ...containers].length > 1;

    return (
        <DoublePaneDisplay
            leftPane={() => (
                <React.Fragment>
                    {!isEmpty(pspViolationReasons) && <PspViolationReasonsDisplay pspViolationReasons={pspViolationReasons} />}
                    <PodSpecsDisplay {...podSecurityContext} {...pspInfo} />
                </React.Fragment>
            )}
            rightPane={() => (
                <React.Fragment>
                    {
                        containers.map((container, index) =>
                            <ContainerSpecsDisplay key={index} {...container} title="Container" allowClose={allowContainerClose} />)
                    }
                    {
                        initContainers.map((container, index) =>
                            <ContainerSpecsDisplay key={index} {...container} title="Init container" allowClose={allowContainerClose} />)
                    }
                </React.Fragment>
            )}
        />
    )
}

export default TabSecurityContext;