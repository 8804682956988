import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { isNull, isArray, isUndefined, isEmpty } from 'lodash';
import { useFetch, usePrevious } from 'hooks';
import { useAdminState } from 'context/AdminProvider';
import { useAuthState } from 'context/AuthProvider';
import { useDisplayConfigState, checkIsAgentAccessAllowed } from 'context/DisplayConfigProvider';
import { useAccountParamsState } from 'context/AccountParamsProvider';
import { CLUSTER_ORCHESTRATION_TYPES, APPLICATION_NAME } from 'utils/systemConsts';
import Table, { tableUtils, FilterFormWrapper } from 'components/Table';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import Button from 'components/Button';
import Modal from 'components/Modal';
import IconWithTooltip from 'components/IconWithTooltip';
import FormModal from 'components/FormModal';
import Loader from 'components/Loader';
import EmptyDataNotificationMessage from 'components/EmptyDataNotificationMessage';
import { TextField, SelectField } from 'components/Form';
import AdminControllerCommandButton from 'layout/controller-admin-tools/AdminControllerCommandButton';
import AdminControllerGatherReports from 'layout/controller-admin-tools/AdminControllerGatherReports';
import ControllerVersionDisplay from 'layout/controller-tools/ControllerVersionDisplay';
import ControllerStatusDisplay from 'layout/controller-tools//ControllerStatusDisplay';
import ControllerEnableToggleButton from 'layout/controller-tools//ControllerEnableToggleButton';
import { CONTROLLER_STATUS_ITEMS } from 'utils/systemConsts';
import { isYamlController } from 'utils/generalUtils';
import ClusterForm from './ClusterForm';
import InstallDataModal, { INSTALLATION_TYPES } from './InstallDataModal';
import UninstallData from './UninstallData';
import DeleteClusterModal from './DeleteClusterModal';

import './clusters.scss';

export const DEPLOYMNET_CLUSTERS_PAGE_PATH = "/deployments";

const CLUSTER_CONTROLLER_API_PATH = "agents";

const isHelmInstallation = installationSource => installationSource === INSTALLATION_TYPES.HELM;

export {
    isHelmInstallation
}

const Clusters = ({usageStatus: {maxClusters}, clusters, fetchData, loading}) => {
    const initialClustersCount = clusters.length;
    const checkIfQuotaExceeded = currCount => isNull(maxClusters) ? false : currCount >= maxClusters;

    const [clustersCount, setClustersCount] = useState(initialClustersCount);
    const [showLimitNotification, setShowLimitNotification] = useState(false);

    const {isReadOnlyUser, isGlobalAdmin, permissionsMode} = useAuthState();
    const {permissionsMode: assignedPermissionsMode, assignedAccountId} = useAdminState();
    const {upgradeUrl, minControllerAutoUpdateVersion} = useAccountParamsState();

    const isYamlInstallation = agnet => isYamlController({version: agnet.info.version, minControllerAutoUpdateVersion});

    const location = useLocation();
    const {openForm, openFormClusterName, startOnStep} = location.query || {};

    const isAgentAccessAllowed = checkIsAgentAccessAllowed(isNull(assignedAccountId) || isUndefined(assignedAccountId) ? permissionsMode : assignedPermissionsMode);
    
    const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = useCallback(() => setRefreshTimestamp(Date()), []);

    const columns = useMemo(() => [
        {
            Header: "Cluster controller name",
            id: "name",
            Cell: ({row}) => {
                const {name, installationSource} = row.original.KubernetesCluster;

                return (
                    <div className="cluster-controller-name">
                        <div>{name}</div>
                        {isHelmInstallation(installationSource) &&
                            <div className="cluster-controller-name-helm-tab">GitOps installation</div>
                        }
                    </div>
                )
            },
            disableSortBy: true,
            alwaysShow: true
        },
        {
            Header: "Orchestration",
            id: "orchestrationType",
            accessor: original => CLUSTER_ORCHESTRATION_TYPES[original.KubernetesCluster.orchestrationType].label,
            disableSortBy: true
        },
        {
            Header: "Kubernetes Security",
            id: "kubernetesSecurity",
            Cell: ({row}) => {
                const {kubernetesSecurity} = row.original.KubernetesCluster;

                return (
                    <tableUtils.StatusIcon isSuccess={kubernetesSecurity} />
                )
            },
            disableSortBy: true
        },
        {
            Header: "API Security",
            id: "apiSecurity",
            Cell: ({row}) => {
                const {installTracingSupport} = row.original.KubernetesCluster;

                return (
                    <tableUtils.StatusIcon isSuccess={installTracingSupport} />
                )
            },
            disableSortBy: true
        },
        {
            Header: "Nodes",
            id: "nodes",
            accessor: "Agent.nodes",
            disableSortBy: true
        },
        ...(!isGlobalAdmin ? [] : [{
            Header: "vCPUs",
            id: "vcpus",
            accessor: "Agent.vcpus",
            disableSortBy: true
        }]),
        {
            Header: "K8s version",
            id: "kubernetesVersion",
            accessor: "Agent.kubernetesVersion",
            disableSortBy: true
        },
        {
            Header: "Controller version",
            id: "controllerVersion",
            Cell: ({row}) => {
                const {Agent, KubernetesCluster} = row.original;
                const {autoUpgradeControllerVersion, installationSource} = KubernetesCluster;
                const {id, info, isUpdateEnabled} = Agent;
                const {version, status, enabled} = info;
                
                return (
                    <ControllerVersionDisplay
                        id={id}
                        version={version}
                        status={status}
                        enabled={enabled}
                        isUpdateEnabled={isUpdateEnabled}
                        agentsUrl={CLUSTER_CONTROLLER_API_PATH}
                        isAgentAccessAllowed={isAgentAccessAllowed}
                        autoUpgradeControllerVersion={autoUpgradeControllerVersion}
                        isHelmInstallation={isHelmInstallation(installationSource)}
                        onUpdateSuccess={() => refreshTableData()}
                    />
                )
            },
            disableSortBy: true
        },
        {
            Header: "Status",
            id: "startus",
            Cell: ({row}) => {
                const {id, info} = row.original.Agent;
                const {status, lastActive} = info;

                return (
                    <ControllerStatusDisplay id={id} status={status} lastActive={lastActive} />
                )
            },
            disableSortBy: true
        }
    ], [isGlobalAdmin, isAgentAccessAllowed, refreshTableData]);

    const [formStartOnStep, setFormStartOnStep] = useState(startOnStep);

    const openFormCluster = clusters.find(item => item.KubernetesCluster.name === openFormClusterName);
    const [selectedClusterData, setSelectedClusterData] = useState(openForm ? {} :
        (openFormClusterName ? (!!openFormCluster ? {...openFormCluster.KubernetesCluster, isYaml: isYamlInstallation(openFormCluster.Agent)} : null) : null));
    
    const closeClusterModal = () => {
        setSelectedClusterData(null);
        setFormStartOnStep(null);
    }

    const [installClusterData, setInstallClusterData] = useState(null);
    const closeInstallModal = () => setInstallClusterData(null);

    const [uninstallClusterData, setUninstallClusterData] = useState(null);
    const closeUninstallModal = () => setUninstallClusterData(null);

    const [deleteClusterConfirmationData, setDeleteClusterConfirmationData] = useState(null);
    const closeDeleteConfirmation = () => setDeleteClusterConfirmationData(null);

    const onNewClusterClick = () => {
        if (checkIfQuotaExceeded(clustersCount)) {
            setShowLimitNotification(true);

            return;
        }
        
        setSelectedClusterData({});
    }

    const [filters, setFilters] = useState({
        clusterName: "",
        kubernetesVersion: "",
        controllerVersion: "",
        controllerStatus: ""
    });

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    return (
        <div className="deployments-clusters-page">
            {isEmpty(clusters) ?
                (
                    <EmptyDataNotificationMessage
                        message={(
                            <React.Fragment>
                                <div>No clusters controllers detected.</div>
                                <div>{`Connect your first cluster controller to start using ${APPLICATION_NAME}.`}</div>
                            </React.Fragment>
                        )}
                        mainTitle="Connect Cluster"
                        onMainClick={onNewClusterClick}
                    />
                ) :
                (
                    <React.Fragment>
                        <div className="table-header-container">
                            <IconWithTitle
                                name={ICON_NAMES.ADD}
                                title="Connect Cluster"
                                className="add-new-cluster-button"
                                onClick={onNewClusterClick}
                                disabled={isReadOnlyUser}
                            />
                            <FilterFormWrapper
                                initialValues={filters}
                                onChange={updatedFilters => {
                                    if (!mounted.current) {
                                        return;
                                    }
                
                                    setFilters(updatedFilters);
                                }}
                            >
                                <TextField name="clusterName" label="Name" />
                                <TextField name="kubernetesVersion" label="K8s version" />
                                <TextField name="controllerVersion" label="Controller version" />
                                <SelectField
                                    name="controllerStatus"
                                    label="Status"
                                    items={Object.values(CONTROLLER_STATUS_ITEMS)}
                                    small
                                />
                            </FilterFormWrapper>
                        </div>
                        <Table
                            url="kubernetesClusters"
                            name="kubernetesClusters"
                            columns={columns}
                            filters={filters}
                            loading={loading}
                            exportToExcel
                            actionsComponent={({original}) => {
                                const {Agent, KubernetesCluster} = original;
                                const {id, type, status, info, gatherInformationState} = Agent;
                                const {enabled} = info || {};

                                const installTooltipId = `${id}-install`;
                                const uninstallTooltipId = `${id}-uninstall`;
                                const deleteTooltipId = `${id}-delete`;
                                const editTooltipId = `${id}-edit`;

                                const disableEdit = isHelmInstallation(KubernetesCluster.installationSource) || isReadOnlyUser;
                                
                                return (
                                    <div className="clusters-row-actions">
                                        {isGlobalAdmin &&
                                            <AdminControllerGatherReports
                                                agentId={id}
                                                status={status}
                                                type={type}
                                                isReadOnlyUser={isReadOnlyUser}
                                                gatherInformationState={gatherInformationState}
                                            />
                                        }
                                        <IconWithTooltip
                                            name={ICON_NAMES.INSTALL}
                                            onClick={() => setInstallClusterData({...KubernetesCluster, isYaml: isYamlInstallation(Agent)})}
                                            tooltipId={installTooltipId}
                                            tooltipText="Install instructions"
                                        />
                                        <IconWithTooltip
                                            name={ICON_NAMES.UNINSTALL}
                                            onClick={() => setUninstallClusterData({...KubernetesCluster, isYaml: isYamlInstallation(Agent)})}
                                            tooltipId={uninstallTooltipId}
                                            tooltipText="Uninstall instructions"
                                        />
                                        <IconWithTooltip
                                            name={disableEdit ? ICON_NAMES.EYE : ICON_NAMES.EDIT}
                                            onClick={() => setSelectedClusterData({...KubernetesCluster, isYaml: isYamlInstallation(Agent)})}
                                            tooltipId={editTooltipId}
                                            tooltipText={`${disableEdit ? "View" : "Edit"} Cluster Controller`}
                                        />
                                        {!isReadOnlyUser &&
                                            <IconWithTooltip
                                                name={ICON_NAMES.DELETE}
                                                onClick={() => setDeleteClusterConfirmationData(original.KubernetesCluster)}
                                                tooltipId={deleteTooltipId}
                                                tooltipText="Delete Cluster Controller"
                                            />
                                        }
                                        <ControllerEnableToggleButton
                                            id={id}
                                            enabled={enabled}
                                            agentsUrl={CLUSTER_CONTROLLER_API_PATH}
                                            isReadOnlyUser={isReadOnlyUser}
                                            onUpdateDone={() => refreshTableData()}
                                        />
                                        {isGlobalAdmin &&
                                            <AdminControllerCommandButton
                                                id={id}
                                                isReadOnlyUser={isReadOnlyUser}
                                                type={type}
                                                isAgentAccessAllowed={isAgentAccessAllowed}
                                            />
                                        }
                                    </div>
                                );
                            }}
                            actionsCellWidth={(isReadOnlyUser ? 110 : 150) + (isGlobalAdmin ? 180 : 0)}
                            refreshTimestamp={refreshTimestamp}
                            onFetchedDataChange={(data) => {
                                if (isArray(data)) {
                                    setClustersCount(data.length);
                                }

                                return data;
                            }}
                        />
                    </React.Fragment>
                )
            }
            {!isNull(selectedClusterData) &&
                <FormModal
                    onClose={closeClusterModal}
                    formComponent={ClusterForm}
                    formProps={{
                        initialData: {...selectedClusterData},
                        startOnStep: formStartOnStep,
                        onFormSubmitSuccess: (data, reinstallRequired, isYaml) => {
                            closeClusterModal();
                            
                            if (isEmpty(clusters)) {
                                fetchData();
                            } else {
                                refreshTableData();
                            }

                            if (reinstallRequired) {
                                setInstallClusterData({...data, isYaml});
                            }
                        }
                    }}
                />
            }
            {!isNull(installClusterData) &&
                <InstallDataModal {...installClusterData} onClose={closeInstallModal} />
            }
            {!isNull(uninstallClusterData) && 
                <Modal className="uninstall-modal" center={true} onClose={closeUninstallModal}>
                    <UninstallData {...uninstallClusterData} />
                    <Button onClick={closeUninstallModal}>Done</Button>
                </Modal>
            }
            {!isNull(deleteClusterConfirmationData) &&
                <DeleteClusterModal onClose={closeDeleteConfirmation} onDeleteSuccess={refreshTableData} data={deleteClusterConfirmationData} />
            }
            {showLimitNotification &&
                <Modal className="upgrade-account-modal" center={true} onClose={() => setShowLimitNotification(false)} >
                    <div className="upgrade-account-title">You discovered a Premium feature!</div>
                    <div className="upgrade-account-description">You have reached your cluster limitation. Please upgrade your account in order to define more clusters</div>
                    <a href={upgradeUrl} target="_blank" rel="noopener noreferrer">
                        <Button>Select a plan</Button>
                    </a>
                </Modal>
            }
        </div>
    )
}

const ClustersWrapper = () => {
    const {usageStatus, loadingDisplay} = useDisplayConfigState();

    const [{loading, data}, fetchData] = useFetch("kubernetesClusters");
    const prevLoading = usePrevious(loading);

    const [inititalLoaded, setInititalLoaded] = useState(false);

    useEffect(() => {
        if (prevLoading && !loading && !inititalLoaded) {
            setInititalLoaded(true);
        }
    }, [prevLoading, loading, inititalLoaded]);

    if (loadingDisplay || !inititalLoaded) {
        return <Loader />;
    }
    
    return <Clusters usageStatus={usageStatus} clusters={data || []} fetchData={fetchData} loading={loading} />;
}

export default ClustersWrapper;