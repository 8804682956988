import React from 'react';
import PodForm, { formatSubmitParams, POD_TEMPLATE_SUBMIT_URL } from 'layout/Deployments/PodTemplates/PodForm';

export {
    formatSubmitParams,
    POD_TEMPLATE_SUBMIT_URL
}

const PodFormWithDisable = ({initialData, withImageLink, onChange, formEnabled=false}) => (
    <PodForm
        initialData={initialData}
        limitDisplay={!formEnabled}
        disabled={!formEnabled}
        onChange={onChange}
        withImageLink={withImageLink}
        withTitle={false}
        hideSubmitButton={true}
    />
)

export default PodFormWithDisable;