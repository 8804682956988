export const RULE_VALUES = {
    MustRunAs: "MustRunAs",
    MustRunAsNonRoot: "MustRunAsNonRoot",
    RunAsAny: "RunAsAny",
    MayRunAs: "MayRunAs"
}
export const DEFAULT_RULE = RULE_VALUES.RunAsAny;
export const GROUP_ITEMS = [
    {value: RULE_VALUES.MustRunAs, label: RULE_VALUES.MustRunAs},
    {value: RULE_VALUES.MayRunAs, label: RULE_VALUES.MayRunAs},
    {value: RULE_VALUES.RunAsAny, label: RULE_VALUES.RunAsAny}
];

export const VOLUME_ITEMS = [
    {value: "all", label: "All"},
    {value: "awsElasticBlockStore", label: "awsElasticBlockStore"},
    {value: "azureDisk", label: "azureDisk"},
    {value: "azureFile", label: "azureFile"},
    {value: "cinder", label: "cinder"},
    {value: "configMap", label: "configMap"},
    {value: "csi", label: "csi"},
    {value: "downwardAPI", label: "downwardAPI"},
    {value: "emptyDir", label: "emptyDir"},
    {value: "fc", label: "fc"},
    {value: "flexVolume", label: "flexVolume"},
    {value: "flocker", label: "flocker"},
    {value: "gcePersistentDisk", label: "gcePersistentDisk"},
    {value: "glusterfs", label: "glusterfs"},
    {value: "hostPath", label: "hostPath"},
    {value: "iscsi", label: "iscsi"},
    {value: "local", label: "local"},
    {value: "nfs", label: "nfs"},
    {value: "persistentVolumeClaim", label: "persistentVolumeClaim"},
    {value: "projected", label: "projected"},
    {value: "portworxVolume", label: "portworxVolume"},
    {value: "quobyte", label: "quobyte"},
    {value: "rbd", label: "rbd"},
    {value: "scaleIO", label: "scaleIO"},
    {value: "secret", label: "secret"},
    {value: "storageos", label: "storageos"},
    {value: "vsphereVolume", label: "vsphereVolume"}
];

export const CAPABILITY_ITEMS = [
    {value: "ALL", label: "All"},
    {value: "AUDIT_CONTROL", label: "AUDIT_CONTROL"},
    {value: "AUDIT_READ", label: "AUDIT_READ"},
    {value: "AUDIT_WRITE", label: "AUDIT_WRITE"},
    {value: "BLOCK_SUSPEND", label: "BLOCK_SUSPEND"},
    {value: "CHOWN", label: "CHOWN"},
    {value: "DAC_OVERRIDE", label: "DAC_OVERRIDE"},
    {value: "DAC_READ_SEARCH", label: "DAC_READ_SEARCH"},
    {value: "FOWNER", label: "FOWNER"},
    {value: "FSETID", label: "FSETID"},
    {value: "IPC_LOCK", label: "IPC_LOCK"},
    {value: "IPC_OWNER", label: "IPC_OWNER"},
    {value: "KILL", label: "KILL"},
    {value: "LEASE", label: "LEASE"},
    {value: "LINUX_IMMUTABLE", label: "LINUX_IMMUTABLE"},
    {value: "MAC_ADMIN", label: "MAC_ADMIN"},
    {value: "MAC_OVERRIDE", label: "MAC_OVERRIDE"},
    {value: "MKNOD", label: "MKNOD"},
    {value: "NET_ADMIN", label: "NET_ADMIN"},
    {value: "NET_BIND_SERVICE", label: "NET_BIND_SERVICE"},
    {value: "NET_BROADCAST", label: "NET_BROADCAST"},
    {value: "NET_RAW", label: "NET_RAW"},
    {value: "SETGID", label: "SETGID"},
    {value: "SETFCAP", label: "SETFCAP"},
    {value: "SETPCAP", label: "SETPCAP"},
    {value: "SETUID", label: "SETUID"},
    {value: "SYS_ADMIN", label: "SYS_ADMIN"},
    {value: "SYS_BOOT", label: "SYS_BOOT"},
    {value: "SYS_CHROOT", label: "SYS_CHROOT"},
    {value: "SYS_MODULE", label: "SYS_MODULE"},
    {value: "SYS_NICE", label: "SYS_NICE"},
    {value: "SYS_PACCT", label: "SYS_PACCT"},
    {value: "SYS_PTRACE", label: "SYS_PTRACE"},
    {value: "SYS_RAWIO", label: "SYS_RAWIO"},
    {value: "SYS_RESOURCE", label: "SYS_RESOURCE"},
    {value: "SYS_TIME", label: "SYS_TIME"},
    {value: "SYS_TTY_CONFIG", label: "SYS_TTY_CONFIG"},
    {value: "SYSLOG", label: "SYSLOG"},
    {value: "WAKE_ALARM", label: "WAKE_ALARM"}
];