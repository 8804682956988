import { useMemo } from "react";
import { sortBy, isEmpty } from "lodash";
import Table from "components/Table";
import ClusterSubTestTable from "./ClusterSubTestTable";
import { RESULTS_ITEMS, ActionItemsStatusIcon, ScanResultsCount } from '../utils';

const ClusterTestsTable = ({ entries, loading, onChange, expandedIndex, resultsFilter }) => {
  const filterIsOn = !isEmpty(resultsFilter);

  const entriesRows = sortBy(entries?.map((entry) => {
    const { subGroups, index } = entry;
    const subRows = subGroups.map((subGroup, index) => ({
      ...subGroup,
      id: entry.index !== subGroup.index ? subGroup.index : `${subGroup.index}.${index}`,
      tests: subGroup.tests.filter(test => !filterIsOn || resultsFilter.includes(test.status))
    })).filter(subGroup => !filterIsOn || !isEmpty(subGroup.tests));

    return {
      ...entry,
      id: index,
      subRows,
    };
  }), ["index"]).filter(({subRows}) => !filterIsOn || !isEmpty(subRows));

  const buildExpandedObject = () => {
    if (!expandedIndex) {
      return {};
    }
    const expandedIndexByDepth = expandedIndex.split(".");
    return {
      [expandedIndexByDepth[0]]: true,
      [`${expandedIndexByDepth[0]}.${expandedIndexByDepth[1]}`]: true,
    };
  };

  const columns = useMemo(
    () => [
      {
        Header: "Test",
        id: "test",
        Cell: ({ row }) => (
          <div
            className="cluster-test-row"
            style={{ paddingLeft: `${row.depth * 2}rem` }}
          >
            <span>{`${row.original.index}. ${row.original.description}`}</span>
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: "Compliance",
        id: "compliance",
        Cell: ({ row }) => `${row.original?.compliance}%`,
        disableSortBy: false,
        maxWidth: 100,
      },
      {
        Header: "Results",
        id: "results",
        Cell: ({ row }) => {
          const {id, numberOfItemsFailed, numberOfItemsPassed, numberOfInfoItems} = row.original;

          return (
            <ScanResultsCount
              failedCount={numberOfItemsFailed}
              passedCount={numberOfItemsPassed}
              infoCount={numberOfInfoItems}
              resultsFilter={resultsFilter}
              tooltipId={id}
            />
          )
        },
        disableSortBy: true,
      },
      {
        Header: "Action Items",
        id: "actionItems",
        Cell: ({ row }) => (
          <ActionItemsStatusIcon
            status={RESULTS_ITEMS.WARN.value}
            count={row.original.numberOfActionItems}
            resultsFilter={resultsFilter}
          />
        ),
        disableSortBy: false,
      },
    ], [resultsFilter]);
  
  return (
    <div className="tests-table">
      <Table
        innerRowComponenet={ClusterSubTestTable}
        innerRowComponentProps={{ onChange, expandedIndex }}
        isLoading={loading}
        data={entriesRows || []}
        columns={columns}
        hideRefresh
        hideColumnControl
        initialExpandedRow={buildExpandedObject()}
        withPagination={false}
      />
    </div>
  );
};

export default ClusterTestsTable;
