import React, { useState } from 'react';
import Button from 'components/Button';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import FormModal from 'components/FormModal';
import { CONTROLLER_STATUS_ITEMS, CONTROLLER_TYPES } from 'utils/systemConsts';
import GatherInformationForm from './GatherInformationForm';

import './gather-reports.scss';

const GENERATE_REPORT_STATUSES = {
    NEW: "NEW",
    PROCESSING: "PROCESSING",
    FAILED: "FAILED",
    DONE: "DONE",
    NONE: "NONE"
}

const ErrorDisplay = () => (
    <div className="logs-download-container error">Error</div>
);

const GenerateReportStatus = ({agentId, state, onGenerateReportsClick, status, isReadOnlyUser}) => {
    const disabled = status === CONTROLLER_STATUS_ITEMS.PENDING_INSTALL.value || isReadOnlyUser

    if (state === GENERATE_REPORT_STATUSES.NEW || state === GENERATE_REPORT_STATUSES.PROCESSING) {
        return (
            <IconWithTitle
                className="logs-download-container generating"
                name={ICON_NAMES.WAITING}
                title="Generating Reports"
                disabled
            />
        );
    }

    if (state === GENERATE_REPORT_STATUSES.FAILED) {
        return <ErrorDisplay />;
    }

    if (state === GENERATE_REPORT_STATUSES.DONE) {
        return (
            <div className="logs-download-container download">
                <div className="download-items">
                    <Button className="generate-button" disabled={disabled} onClick={onGenerateReportsClick}>
                        Generate Reports
                    </Button>
                    <a href={`${window.location.origin}/api/admin/agents/gatherInformation/results/${agentId}`} download>
                        <IconWithTitle name={ICON_NAMES.DOWNLOAD} title="Download File" />
                    </a>
                </div>
            </div>
        );
    }

    if (state === GENERATE_REPORT_STATUSES.NONE) {
        return (
            <div className="logs-download-container">
                <Button className="generate-button" disabled={disabled} onClick={onGenerateReportsClick}>
                    Generate Reports
                </Button>
            </div>
        );
    }

    return null;

}

const AdminControllerGatherReports = ({agentId, disabled, type, gatherInformationState=GENERATE_REPORT_STATUSES.NONE}) => {
    const [showGenerateReportForm, setShowGenerateReportForm] = useState(false);
    const [gatherInfoState, setGatherInfoState] = useState(gatherInformationState);
    const closeGenerateReportForm = () => setShowGenerateReportForm(false);

    if (type !== CONTROLLER_TYPES.KUBERNETES) {
        return null;
    } 

    return (
        <React.Fragment>
            <GenerateReportStatus
                agentId={agentId}
                state={gatherInfoState}
                onGenerateReportsClick={() => setShowGenerateReportForm(true)}
                disabled={disabled}
            />
            {showGenerateReportForm &&
                <FormModal
                    className="agent-gather-information-modal"
                    center={true}
                    onClose={closeGenerateReportForm}
                    formComponent={GatherInformationForm}
                    formProps={{
                        agentId,
                        onFormSubmitSuccess: () => {
                            closeGenerateReportForm();
                            setGatherInfoState(GENERATE_REPORT_STATUSES.PROCESSING)
                        }
                    }}
                />
            }
        </React.Fragment>
    )
}

export default AdminControllerGatherReports;