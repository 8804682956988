import React from 'react';
import FormWrapper, { TextField, validators } from 'components/Form';
import Text, { TEXT_TYPES } from 'components/Text';

//The API form only supports creating new apis (no edit)

const ApiForm = ({onFormSubmitSuccess, onDirtyChanage}) => {
    const initialValues = {
        name: ""
    };
    
    return (
        <div>
            <Text type={TEXT_TYPES.TITLE_LARGE} withTopMargin withBottomMargin>New Api</Text>
            <FormWrapper
                className="image-form-container"
                initialValues={initialValues}
                submitUrl="apiSecurity/api"
                getSubmitParams={formValues => {
                    return {submitData: formValues}
                }}
                onSubmitSuccess={onFormSubmitSuccess}
                onDirtyChanage={onDirtyChanage}
            >
                <TextField name="name" label="API URL" validate={validators.validateRequired} />
            </FormWrapper>
        </div>
    )
}

export default ApiForm;