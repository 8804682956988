import React, { useMemo } from "react";
import classnames from "classnames";
import IconWithTooltip from "components/IconWithTooltip";
import { ICON_NAMES } from "components/Icon";
import VulnerabilitiesSummary from "components/VulnerabilitiesSummary";
import { API_TYPE_ITEMS } from "layout/Apis/utils";
import ApiCatalog, {
  COMPLIANCE_WIDTH,
  ComplianceStatusIcon,
  API_FILTER_KEYS,
  WorkloadsDisplay,
} from "../ApiCatalog";
import SpecsStatusIcon from "../SpecsStatusIcon";
import ApiForm from "./ApiForm";

export const EXTERNAL_API_PAGE_PATH = "/catalog/external";

export { API_FILTER_KEYS };

const API_STATUSES = {
  VALID: "VALID",
  INVALID: "INVALID",
  PROCESSING: "PROCESSING",
};

const ExternalApis = () => {
  const columns = useMemo(
    () => [
      {
        Header: "API Name",
        id: "name",
        Cell: ({ row }) => {
          const { identifier, name, status } = row.original;
          const isInvalid = status === API_STATUSES.INVALID;
          const isProcessing = status === API_STATUSES.PROCESSING;

          return (
            <div className="api-name-status">
              {(isInvalid || isProcessing) && (
                <IconWithTooltip
                  name={ICON_NAMES.EXCLAMATION_MARK_ROUND}
                  className={classnames("api-status-icon", {
                    error: isInvalid,
                  })}
                  tooltipId={`catalog-status-${identifier}`}
                  tooltipText={isInvalid ? "Unavailable data" : "Processing..."}
                  placement="top"
                />
              )}
              <div>{name}</div>
            </div>
          );
        },
      },
      {
        Header: "Security Findings",
        id: "risk",
        Cell: ({ row }) => {
          const {
            vulnerabilitiesSummary
          } = row.original;

          return (
            <VulnerabilitiesSummary
              id={row.id}
              {...vulnerabilitiesSummary}
            />
          );
        },
        defaultCanSort: true,
        width: 300,
      },
      {
        Header: "Client Workloads",
        id: "workloads",
        Cell: ({ row }) => {
          const { clientWorkloads } = row.original;

          return <WorkloadsDisplay workloadItems={clientWorkloads} />;
        },
        disableSortBy: true,
      },
      {
        Header: "Policy Compliance",
        id: "compliance",
        className: "compliance-cell",
        Cell: ({ row }) => {
          const compliance = row.original.compliance || {};

          return <ComplianceStatusIcon compliance={compliance.compliant} />;
        },
        width: COMPLIANCE_WIDTH,
        minWidth: COMPLIANCE_WIDTH,
        maxWidth: COMPLIANCE_WIDTH,
        disableSortBy: true,
      },
      {
        Header: "Specs",
        id: "specs",
        Cell: ({ row }) => {
          const {identifier, openapi_spec_availablity} = row.original;

          return (
            <SpecsStatusIcon id={identifier} {...openapi_spec_availablity} />
          )
        },
        disableSortBy: true,
        width: COMPLIANCE_WIDTH,
      },
    ],
    []
  );

  return (
    <ApiCatalog
      columns={columns}
      url="apiSecurity/externalCatalog"
      apiNameTitle="API name"
      newForm={ApiForm}
      newTitle="New API"
      type={API_TYPE_ITEMS.EXTERNAL.value}
    />
  );
};

export default ExternalApis;
