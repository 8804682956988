import React from 'react';
import { ICON_NAMES } from 'components/Icon';
import MiniDashboardWrapper, { DashboardItem } from 'components/MiniDashboardWrapper';

import COLORS from 'utils/scss_variables.module.scss';

const ItemDisplay = ({id, title, count, color}) => (
    <DashboardItem
        id={`${title}-cis-${id}`}
        count={count}
        icon={ICON_NAMES.ALERT_CIS}
        tooltipText={`${title}: ${count}`}
        color={color}
    />
)

const CisSummary = ({total, info, warning, fatal, id}) => (
    <MiniDashboardWrapper totalCount={total} totalTooltipId={`total-cis-${id}`} className="cis-summary-container">
        <ItemDisplay id={id} title="Fatal" count={fatal} color={COLORS["color-error"]} />
        <ItemDisplay id={id} title="Warning" count={warning} color={COLORS["color-warning"]} />
        <ItemDisplay id={id} title="Info" count={info} color={COLORS["color-main"]} />
    </MiniDashboardWrapper>
);

export default CisSummary;