import React, { useState, useEffect } from 'react';
import { useAuthState } from 'context/AuthProvider';
import { useFetch, useDelete, usePrevious } from 'hooks';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import FormModal from 'components/FormModal';
import { EmptyDataIcon, ScanPolicyLoader } from '../utils';
import CiScanPolicyForm from './CiScanPolicyForm';
import CiItemDisplay from './CiItemDisplay';

const CiPolicy = () => {
    const [showCiScanForm, setShowCiScanForm] = useState(false);
    const [{loading, data}, fetchPolicies] = useFetch("ciPolicy");
    const [{deleting}, deletePolicy] = useDelete("ciPolicy");
    const prevDeleting = usePrevious(deleting);

    const {isReadOnlyUser} = useAuthState();

    useEffect(() => {
        if (!deleting && prevDeleting) {
            fetchPolicies();
        }
    }, [deleting, prevDeleting, fetchPolicies]);

    if (loading || deleting) {
        return <ScanPolicyLoader />;
    }

    const closeScanForm = () => setShowCiScanForm(false);
    const openScanForm = () => setShowCiScanForm(true);
    
    return (
        <div className="scan-policy-container">
            <IconWithTitle
                className="new-policy-scan-button"
                name={ICON_NAMES.ADD}
                title="New CI Scan"
                onClick={openScanForm}
                disabled={isReadOnlyUser || !!data.id}
            />
            <div className="policy-scans-wrapper">
                {!!data.id ? <CiItemDisplay data={data} onEdit={openScanForm} onDelete={() => deletePolicy(data.id)} /> :
                    <EmptyDataIcon title="No CI Scan Policy Data" />}
            </div>
            {showCiScanForm &&
                <FormModal
                    onClose={closeScanForm}
                    formComponent={CiScanPolicyForm}
                    formProps={{
                        initialData: data,
                        onFormSubmitSuccess: () => {
                            closeScanForm();
                            fetchPolicies();
                        }
                    }}
                />
            }
        </div>
    )
}

export default CiPolicy;