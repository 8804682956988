export const PROTECTION_STATUSES = {
    FULL: {value: "FULL", label: "Protected", displayValue: "full"},
    DEPLOYMENT_ONLY: {value: "DEPLOYMENT_ONLY", label: "Deployment-only", displayValue: "deployment-only"},
    CONNECTION_ONLY: {value: "CONNECTION_ONLY", label: "Connections-only", displayValue: "connections-only"},
    DISABLED: {value: "DISABLED", label: "Unprotected", displayValue: "disabled"}
};

export const checkIsProtected = protectionStatus => protectionStatus !== PROTECTION_STATUSES.DISABLED.value;

export const getProtectedLabel = ({protect, connectionsEnabled, apiEnabled}) => {
    const labelName = "SecureApplication-protected";

    if (!protect) {
        return `${labelName}=${PROTECTION_STATUSES.DISABLED.displayValue}`;
    }

    return `${labelName}=${(connectionsEnabled || apiEnabled) ? PROTECTION_STATUSES.FULL.displayValue : PROTECTION_STATUSES.DEPLOYMENT_ONLY.displayValue}`;
};