import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import CloseButton from 'components/CloseButton';

import './notification.scss';

export const NOTIFICATION_TYPES = {
    INFO: "INFO",
    SUCCESS: "SUCCESS",
    WARNING: "WARNING",
    ERROR: "ERROR"
}

export const NotificationContent = ({message, type, onClose}) => {
    const notificationType = type || NOTIFICATION_TYPES.INFO; 

    return (
        <div className={classnames("notification-wrapper", notificationType.toLowerCase())}>
            <div className="notification-content">{message}</div>
            <CloseButton onClick={onClose} />
        </div>
    )
}

const Notification = props => {
    const [portalContainer, setPortalContainer] = useState(null);

    useEffect(() => {
        const container = document.querySelector("main[role='main']");

        if (!container) {
            return;
        }
        
        setPortalContainer(container);
    }, []);

    if (!portalContainer) {
        return null;
    }

    return ReactDOM.createPortal(<NotificationContent {...props} />, portalContainer);
}

export default Notification;