import React, { useState } from "react";
import { isEmpty } from "lodash";
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Colors from "utils/scss_variables.module.scss";

export const InnerLabelContent = (props) => {
  const {
    viewBox: { cx, cy },
    count,
    title,
  } = props;

  const yDisplacement = cy / 10;

  const svgTextStyle = {
    fontFamily: "Inter",
    fontSize: 12,
    x: cx,
    fill: Colors["color-text-white"],
    textAnchor: "middle",
  };

  return (
    <>
      <text
        {...svgTextStyle}
        y={cy - yDisplacement + yDisplacement}
        fontSize={2 * svgTextStyle.fontSize}
      >
        {count}
      </text>
      <text {...svgTextStyle} y={cy + yDisplacement + yDisplacement}>
        {title}
      </text>
    </>
  );
};

function PieChartWithCenteredText({
  data,
  count,
  title,
  customInnerLabel,
  activeShape: ActiveShape,
  customTooltip: CustomTooltip,
  noDataDisplay: NoDataDisplay,
  innerRadius = 50,
  outerRadius = 60,
  paddingAngle = 0,
  tooltipPosition,
  showTooltip = true,
  onClick,
}) {
  const [activeIndex, setActiveIndex] = useState();

  if (!data || isEmpty(data)) return <span>No data</span>;

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onPieExit = () => setActiveIndex(undefined);

  return (
    <>
      <ResponsiveContainer width="100%" height={170}>
        {count === 0 ? (
          NoDataDisplay ? (
            <NoDataDisplay />
          ) : (
            <div>No data</div>
          )
        ) : (
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              stroke="none"
              data={data}
              dataKey="value"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              onMouseEnter={onPieEnter}
              onMouseLeave={onPieExit}
              paddingAngle={paddingAngle}
              activeShape={ActiveShape}
              onClick={(data) => (onClick ? onClick(data) : null)}
            >
              {data.map(({ name, color }) => (
                <Cell key={name} fill={color} />
              ))}
              <Label
                position="center"
                content={
                  customInnerLabel ? (
                    customInnerLabel
                  ) : (
                    <InnerLabelContent count={count} title={title} />
                  )
                }
              />
            </Pie>
            {showTooltip && (
              <Tooltip
                allowEscapeViewBox={{
                  x: true,
                  y: true,
                }}
                isAnimationActive={true}
                position={tooltipPosition}
                wrapperStyle={{
                  // TODO need a system for modals and tooltips z-index
                  zIndex: "99999",
                }}
                offset={10}
                content={CustomTooltip}
              />
            )}
          </PieChart>
        )}
      </ResponsiveContainer>
    </>
  );
}

export default PieChartWithCenteredText;
