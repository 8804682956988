import React from 'react';
import DeleteWizardModal from 'components/DeleteWizardModal';
import { CONNECTIONS_POLICY_URL } from 'layout/Policies/ConnectionsPolicy';
import { EVENTS_POLICY_URL } from 'layout/Policies/KubernetesPolicy';
import { DEPLOYMENT_POLICY_URL } from 'layout/Policies/DeploymentPolicy';
import { APPLICATION_NAME } from 'utils/systemConsts';
import RegistriesStep from './RegistriesStep';
import EnvironmentsStep from './EnvironmentsStep';
import ExpansionsStep from './ExpansionsStep';
import DeployersStep from './DeployersStep';
import RulesStep from './RulesStep';
import { getConnectionRuleItems, CONNECTION_RULES_NAME, getClusterEventsRuleItems, CLUSTER_EVENTS_RULES_NAME, DEPLOYMENT_RULES_NAME,
    getDeploymentRuleItems } from './utils';

import './delete-cluster-modal.scss';

const ConnectionRulesStep = props =>
    <RulesStep {...props} name={CONNECTION_RULES_NAME} pathname={CONNECTIONS_POLICY_URL} getRuleItems={getConnectionRuleItems} />

const ClusterEventsRulesStep = props =>
    <RulesStep {...props} name={CLUSTER_EVENTS_RULES_NAME} pathname={EVENTS_POLICY_URL} getRuleItems={getClusterEventsRuleItems} />

const DeploymentRulesStep = props =>
    <RulesStep {...props} name={DEPLOYMENT_RULES_NAME} pathname={DEPLOYMENT_POLICY_URL} getRuleItems={getDeploymentRuleItems} />

const DeleteClusterModal = ({data, onClose, onDeleteSuccess}) => {
    const {id, name} = data;
    const deleteItemName = `${name} Cluster`;
    
    return (
        <DeleteWizardModal
            className="delete-cluster-modal"
            url={`kubernetesClusters/${id}/deleteDependencies`}
            deleteUrl="kubernetesClusters"
            onClose={onClose}
            onDeleteSuccess={onDeleteSuccess}
            deleteSteps={[
                {id: 1, title: "Registries", dataKey: "registries", component: RegistriesStep},
                {id: 2, title: "Environments", dataKey: "environments", component: EnvironmentsStep},
                {id: 3, title: "Expansions", dataKey: "expansions", component: ExpansionsStep},
                {id: 4, title: "Deployment rules", dataKey: "deploymentRules", component: DeploymentRulesStep},
                {id: 5, title: "Connection rules", dataKey: "connectionRules", component: ConnectionRulesStep},
                {id: 6, title: "Cluster event rules", dataKey: "clusterEventRules", component: ClusterEventsRulesStep},
                {id: 7, title: "Deployers", dataKey: "deployers", component: DeployersStep},
            ]}
            title="Delete cluster"
            description={`Deleting ${deleteItemName} will have an impact on other ${APPLICATION_NAME} elements. Once deleted, the action cannot be reverted`}
            deleteItemName={deleteItemName}
            deleteItemId={id}
        />
    )
}

export default DeleteClusterModal;