import React, { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { useFetch, useDelete, usePrevious, useMountMultiFetch } from 'hooks';
import { useAuthState } from 'context/AuthProvider';
import Loader from 'components/Loader';
import { ICON_NAMES } from 'components/Icon';
import IconWithTitle from 'components/IconWithTitle';
import FormModal from 'components/FormModal';
import ModalConfirmation from 'components/ModalConfirmation';
import RiskForm from './RiskForm';
import RiskItemDisplay from './RiskItemDisplay';

import './risk-management.scss';

export const RISK_MANAGEMENT_PAGE_PATH = "/riskAssessment/riskManagement"; 

const IGNORE_RISK_URL = "riskAssessment/ignoredRisks";

const RiskManagement = () => {
    const {isReadOnlyUser} = useAuthState();

    const [{loading, data}] = useMountMultiFetch([
        {key: "clusters", url: "leanKubernetesClusters"},
        {key: "environments", url: "environments"}
    ]);

    const [{loading: loadingRisksData, data: risksData}, fetchRisksData] = useFetch(IGNORE_RISK_URL);

    const [{deleting, error}, deleteItem] = useDelete(IGNORE_RISK_URL);
    const prevDeleting = usePrevious(deleting);

    const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationId(null);

    const [selectedRiskData, setSelectedRiskData] = useState(null);
    const closeRiskForm = () => setSelectedRiskData(null);

    useEffect(() => {
        if (prevDeleting && !deleting && !error) {
            fetchRisksData();
        }
    }, [prevDeleting, deleting, error, fetchRisksData]);

    if (loading || deleting || loadingRisksData) {
        return <Loader />;
    }

    const {clusters, environments} = data;

    return (
        <div className="risk-management-page-wrapper">
            <IconWithTitle
                className="new-ignore-risk-button"
                name={ICON_NAMES.ADD}
                title="Ignore Risk"
                onClick={() => setSelectedRiskData({})}
                disabled={isReadOnlyUser}
            />
            <div className="risk-items-wrapper">
                {risksData.map(item => (
                    <RiskItemDisplay
                        key={item.id}
                        {...item}
                        disabled={isReadOnlyUser}
                        onDelete={() => setDeleteConfirmationId(item.id)}
                        onEdit={() => setSelectedRiskData({...item})}
                        clustersData={clusters}
                        environmentsData={environments}
                    />
                ))}
            </div>
            {!isNull(selectedRiskData) &&
                <FormModal
                    onClose={closeRiskForm}
                    formComponent={RiskForm}
                    formProps={{
                        initialData: {...selectedRiskData},
                        onFormSubmitSuccess: () => {
                            closeRiskForm();
                            fetchRisksData();
                        }
                    }}
                />
            }
            {!isNull(deleteConfirmationId) &&
                <ModalConfirmation 
                    title="Are you sure?"
                    message="This operation cannot be reverted"
                    confirmTitle="Delete"
                    onCancel={closeDeleteConfirmation}
                    onConfirm={() => {
                        closeDeleteConfirmation();
                        deleteItem(deleteConfirmationId);
                    }}
                    confirmAlert
                />
            }
        </div>
    )
}

export default RiskManagement;