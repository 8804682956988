import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import Root from 'layout/Root';
import IconTemplates from 'components/Icon/IconTemplates';

import 'utils/fonts/fonts.scss';

const BaseComponent = () => {
    if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
        LogRocket.init('wfco7k/panoptica-product-ui');
    }

    return (
        <React.Fragment>
            <Root />
            <IconTemplates />
        </React.Fragment>
    )
}

ReactDOM.render(<BaseComponent />, document.getElementById('root'));