import React from 'react';
import { useFetch } from 'hooks';
import Modal from 'components/Modal';
import Text, { TEXT_TYPES } from 'components/Text';
import InputWithCopy from 'components/InputWithCopy';

const UserTokenModal = ({userId, onClose}) => {
    const [{loading, data, error}] = useFetch(`users/${userId}/accessTokens`);

    if (error) {
        return null;
    }
    
    const tooltipId = "user-token-copy-tooltip";
    const {accessKey, secretKey, agentSecretKey} = data || {};

    return (
        <Modal className="user-token-modal" onClose={onClose} loading={loading}> 
            <Text type={TEXT_TYPES.TITLE_LARGE} withBottomMargin withTopMargin>Token</Text>
            <div className="form-group field-container has-label">
                <label className="form-label">Access Key</label>
                <InputWithCopy value={accessKey} tooltipId={tooltipId} tooltipText="Copy to clipboard" />
            </div>
            <div className="form-group field-container has-label">
                <label className="form-label">Secret Key</label>
                <InputWithCopy value={secretKey} tooltipId={tooltipId} tooltipText="Copy to clipboard" />
            </div>
            <div className="form-group field-container has-label">
                <label className="form-label">Controller Secret Key</label>
                <InputWithCopy value={agentSecretKey} tooltipId={tooltipId} tooltipText="Copy to clipboard" />
            </div>
        </Modal>
    )
}

export default UserTokenModal;