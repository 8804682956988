import { isEmpty } from 'lodash';

export const FETCH_METHODS = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE"
}

export const AUTHENTICATION_ERROR_CODES = [401, 403];
export const AUTHENTICATION_ERROR_INDICATOR = "AUTHENTICATION_ERROR_INDICATOR";

const queryString = (params) => Object.keys(params).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
}).join('&');

export const formatFetchUrl = ({url, queryParams, formatUrl}) => {
    const formattedUrl = !!formatUrl ? formatUrl(url) : url;

    return isEmpty(queryParams) ? `/api/${formattedUrl}` : `/api/${formattedUrl}?${queryString(queryParams)}`;
}

export const formatFetchOptions = ({method, stringifiedSubmitData, headers}) => {
    const options = {
        credentials: 'include',
        method
    };

    if (method === FETCH_METHODS.POST || method === FETCH_METHODS.PUT) {
        options.headers = {'content-type': 'application/json', ...headers};
        options.body = stringifiedSubmitData;
    }

    return options;
}