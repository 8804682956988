import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { RULE_ACTIONS, IGNORE_ACTION_ITEM } from 'utils/systemConsts';
import { ENVIRONMENT_BY_ITEMS, RULE_SCOPE_ITEMS } from './UserRuleForm';
import { getRecommendedRuleNameById, USER_TYPES } from './utils';

const BoldText = ({children}) => <span style={{fontWeight: "bold"}}>{children}</span>;

const AndList = ({items, separator="and"}) => items.map((item, index, items) => (
    <React.Fragment key={index}>
        <BoldText>{item}</BoldText>
        {index < items.length - 2 ? ", " : ""}
        {index === items.length - 2 && ` ${separator} `}
    </React.Fragment>
));

const getScopePhrase = (ruleScope, clusterItems) => {
    let phrase = "";

    const {scopePartType, clusters, environment} = ruleScope;

    if (scopePartType === RULE_SCOPE_ITEMS.ANY.value) {
        return "any cluster";
    }

    if (scopePartType === RULE_SCOPE_ITEMS.CLUSTER.value) {
        phrase = AndList({items: clusters.map(clusterId => {
            const clusterItem = clusterItems.find(cluster => cluster.id === clusterId);

            return !!clusterItem ? clusterItem.name : clusterId;
        })});

        return (
            <React.Fragment>
                {phrase}
                {` clusters`}
            </React.Fragment>
        );
    }

    switch (environment.environmentRuleType) {
        case ENVIRONMENT_BY_ITEMS.NAME.value:
            phrase = AndList({items: environment.names});
            break;
        case ENVIRONMENT_BY_ITEMS.ANY.value:
            phrase = "any environment";
            break;
        default:
            break;
    }
    
    return phrase;
}

const getUsersListPhrase = (users, capitalize) => {
    if (isEmpty(users)) {
        return `${capitalize ? "Any" : "any"} user, service account or group`;
    }

    const userTypesAll = users.filter(user => isEmpty(user.userName)).map(user => user.userType);
    const userNamesNotAll = users.filter(user => !isEmpty(user.userName)).map(user => user.userName);
    const phraseItems = [];

    if (userTypesAll.includes(USER_TYPES.USER)) {
        phraseItems.push(`${capitalize ? "Any" : "any"} user`);
    }
    if (userTypesAll.includes(USER_TYPES.SERVICEACCOUNT)) {
        phraseItems.push(`${isEmpty(phraseItems) && capitalize ? "Any" : "any"} service account`);
    }
    if (userTypesAll.includes(USER_TYPES.GROUP)) {
        phraseItems.push(`${isEmpty(phraseItems) && capitalize ? "Any" : "any"} group`);
    }
    
    return AndList({items: [...phraseItems, ...userNamesNotAll]});
}

const UserRuleContent = (props) => {
    const {action, users, apiActions, resources, ruleScope, recommendedRuleId, clusters, recommendedRules} = props;

    const allowed = action === RULE_ACTIONS.ALLOW;
    const blocked = action === RULE_ACTIONS.BLOCK;

    const onPhrase = <span>{` on `}{getScopePhrase(ruleScope, clusters)}</span>;

    if (!!recommendedRuleId) {
        const ruleName = getRecommendedRuleNameById(recommendedRules, recommendedRuleId);
        const PREVENT = "Prevent";
        const preventReplacement = blocked ? "Block" : "Detect";
        const preventIndex = ruleName.toLowerCase().indexOf(PREVENT.toLowerCase());
        let ruleNamePhrase = ruleName;

        if (preventIndex >= 0) {
            ruleNamePhrase = (
                <span>
                    {ruleName.slice(0, preventIndex)}
                    <span className={classnames("relationship", {blocked})}>
                        {preventIndex === 0 ? preventReplacement : preventReplacement.toLowerCase()}
                    </span>
                    {` ${ruleName.slice(preventIndex + 7)}`}
                </span>
            );
        }

        return (
            <React.Fragment>
                {ruleNamePhrase}
                {onPhrase}
            </React.Fragment>
        );
    }

    const isIgnore = action === IGNORE_ACTION_ITEM.value;
    const resourcesPhrase = isEmpty(resources) ? "any resources" :
        AndList({items: resources.map(resource => resource.kind), separator: "or"});

    return (
        <React.Fragment>
            {isIgnore && <span className={classnames("relationship", "ignored")}>{`Ignore `}</span>}
            {getUsersListPhrase(users, !isIgnore)}
            {isIgnore ? ` that ` :
                <span className={classnames("relationship", {blocked}, {allowed})}>
                    {allowed ? ` can ` : ` can't `}
                </span>
            }
            {AndList({items: apiActions || []})}
            {` `}
            {resourcesPhrase}
            {onPhrase}
        </React.Fragment>
    );
};

export default UserRuleContent;