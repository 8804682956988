import React from 'react';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useAuthState } from 'context/AuthProvider';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import Icon, { ICON_NAMES } from 'components/Icon';
import Arrow from 'components/Arrow';
import Scrollbar from 'components/Scrollbar';
import { GLOBAL_ADMIN_URLS, APP_PATHS } from 'utils/systemConsts';

import './sidebar.scss';

const NavLinkItem = ({icon, title, isBeta}) => (
    <React.Fragment>
        <Icon name={icon} />
        <span className="navigation-item-title">{title}</span>
        {!!isBeta && <span className="navigation-item-beta" >beta</span>}
    </React.Fragment>
)

const NavLinks = ({items}) => items.map(({id, title, icon, disabled, path, exact, isBeta}) => {
    if (!!disabled) {
        return (
            <div className="navigation-item disabled" key={title} id={id}>
                <NavLinkItem title={title} icon={icon} isBeta={isBeta} />
            </div>
        );
    }

    return (
        <NavLink className="navigation-item" to={path} exact={!!exact} key={title} id={id}>
            <NavLinkItem title={title} icon={icon} isBeta={isBeta} />
        </NavLink>
    )
})

const Sidebar = ({isOpen, onToggleOpen}) => {
    const location = useLocation();
    const history = useHistory();

    const {isReadOnlyUser} = useAuthState();

    const {showApiSecurity, showServerlessSecurity} = useDisplayConfigState();
    
    const mainItems = [
        {
            ...APP_PATHS.DASHBOARD,
            icon: ICON_NAMES.DASHBOARD,
            id: "sidebar-dashboard-tab"
        },
        {
            ...APP_PATHS.NAVIGATOR,
            icon: ICON_NAMES.NAVIGATOR,
            id: "sidebar-navigator-tab"
        },
        {
            ...APP_PATHS.CI_CD,
            icon: ICON_NAMES.CI_CD,
            id: "sidebar-cicd-tab"
        },
        {
            ...APP_PATHS.RUNTIME,
            icon: ICON_NAMES.RUNTIME,
            id: "sidebar-runtime-tab"
        },
        ...(!showApiSecurity ? [] : [{
            ...APP_PATHS.APIS,
            icon: ICON_NAMES.API,
            id: "sidebar-apis-tab"
        }]),
        ...(!showServerlessSecurity ? [] : [{
            ...APP_PATHS.SERVERLESS,
            icon: ICON_NAMES.CLOUD,
            id: "sidebar-serverless-tab"
        }]),
        {
            ...APP_PATHS.DEPLOYMENTS,
            icon: ICON_NAMES.WORKLOADS,
            id: "sidebar-deployments-tab"
        },
        {
            ...APP_PATHS.POLICIES,
            icon: ICON_NAMES.POLICY,
            id: "sidebar-policies-tab"
        },
        {
            ...APP_PATHS.RISK_ASSESSMENT,
            icon: ICON_NAMES.SCAN,
            id: "sidebar-riskassessment-tab"
        },
        {
            ...APP_PATHS.CIS_BENCHMARK,
            icon: ICON_NAMES.CIS,
            id: "sidebar-cisbenchmark-tab"
        }
    ];

    const adminItems = [
        {
            ...APP_PATHS.AUDIT_LOG,
            icon: ICON_NAMES.AUDIT
        },
        {
            ...APP_PATHS.SYSTEM,
            icon: ICON_NAMES.COG,
            disabled: isReadOnlyUser
        }
    ];

    const Menu = () => (
        <React.Fragment>
            <NavLinks items={mainItems} />
            <div className="menu-title admin-title">
                {isOpen ? "Administration" : "Admin"}
            </div>
            <NavLinks items={adminItems} />
        </React.Fragment>
    );

    const GlobalAdminMenu = () => (
        <NavLinks items={[
            {...APP_PATHS.MANAGE_ACCOUNTS, icon: ICON_NAMES.STAR},
            {...APP_PATHS.MANAGE_ADMIN_USERS, icon: ICON_NAMES.COG},
            {...APP_PATHS.GLOBAL_ADMIN_AUDIT, icon: ICON_NAMES.AUDIT},
            {...APP_PATHS.GLOBAL_ADMIN_CONTROLLERS, icon: ICON_NAMES.AGENTS}
        ]} />
    );
    
    return (
        <nav className={classnames("sidebar", {"open": isOpen})}>
            <div className="app-icon-container">
                <div className={classnames("app-icon", {"open": isOpen})} onClick={isOpen ? () => history.push("/") : onToggleOpen}></div>
                {isOpen && <Arrow name="left" className="close-menu-icon" onClick={onToggleOpen} small />}
            </div>
            <Scrollbar className="sidepar-menu-wrapper">
                <div className="navigation-items">
                    {GLOBAL_ADMIN_URLS.includes(location.pathname) ? <GlobalAdminMenu /> : <Menu />}
                </div>
            </Scrollbar>
        </nav>
    );
}

export default Sidebar;