import React, { useState, useMemo, useRef, useEffect } from 'react';
import { isNull, isBoolean } from 'lodash';
import Table, { tableUtils } from 'components/Table';
import IconWithTooltip from 'components/IconWithTooltip';
import { ICON_NAMES } from 'components/Icon';
import FiltersForm from './FiltersForm';
import ProtectModal from './ProtectModal';
import { PROTECTION_STATUSES, checkIsProtected } from './utils';

import './namespaces.scss';

const SORT_KEYS = {
    NAMESPACE_NAME: "namespaceName"
}

const Namespaces = () => {
    const [protectNamespace, setProtectNamespace] = useState(null);
    const closeProtectModal = () => setProtectNamespace(null);

    const columns = useMemo(() => [
        {
            Header: "Name",
            id: "namespaceName",
            accessor: "name"
        },
        {
            Header: "Cluster Name",
            id: "clusterName",
            accessor: "clusterName"
        },
        {
            Header: "Labels",
            id: "sourceLabels",
            Cell: ({row}) => <tableUtils.KeyValueRenderer items={row.original.labels} />,
            disableSortBy: true
        },
        {
            Header: "Running Pods",
            id: "runningPods",
            accessor: "runningPods"
        },
        {
            Header: "Protected",
            id: "protectionStatus",
            Cell: ({row}) => {
                const {protectionStatus} = row.original;
                
                return (
                    <tableUtils.StatusRenderer
                        isActive={protectionStatus === PROTECTION_STATUSES.FULL.value}
                        isWarning={[PROTECTION_STATUSES.CONNECTION_ONLY.value, PROTECTION_STATUSES.DEPLOYMENT_ONLY.value].includes(protectionStatus)}
                        customText={PROTECTION_STATUSES[protectionStatus].label}
                    />
                );
            },
            defaultCanSort: true,
            width: 100
        }
    ], []);

    const [filters, setFilters] = useState({});

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    return (
        <div className="runtime-namespaces-page">
            <div className="table-filters-container">
                <FiltersForm
                    filters={filters}
                    setFilters={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }

                        setFilters(updatedFilters);
                    }}
                />
            </div>
            <Table
                url="namespaces"
                name="runtime-namespaces"
                columns={columns}
                filters={filters}
                defaultSortBy={[{id: SORT_KEYS.NAMESPACE_NAME, desc: true}]}
                exportToExcel={true}
                isLoading={!(isBoolean(filters.filterLoading) && !filters.filterLoading)}
                actionsComponent={({original}) => {
                    const {id, protectionStatus, systemNamespace} = original;
                    const isProtected = checkIsProtected(protectionStatus);

                    if (systemNamespace) {
                        return (
                            <IconWithTooltip
                                className="namespace-protect-icon"
                                name={ICON_NAMES.SHIELD_EXCLAMATION}
                                tooltipId={`${id}-protect`}
                                tooltipText="System namespace"
                            />
                        )
                    }

                    return (
                        <IconWithTooltip
                            className="namespace-protect-icon"
                            name={isProtected ? ICON_NAMES.SHIELD_CROSS : ICON_NAMES.SHIELD_CHECK}
                            onClick={() => setProtectNamespace(original)}
                            tooltipId={`${id}-protect`}
                            tooltipText={`${isProtected ? "Unprotect" : "Protect"} namespace`}
                        />
                    );
                }}
                actionsCellWidth={50}
            />
            {!isNull(protectNamespace) && <ProtectModal {...protectNamespace} onClose={closeProtectModal} />}
        </div>
    )
}

export default Namespaces;
