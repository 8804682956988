import { useState, useEffect, useCallback, useRef } from 'react';
import { isEmpty } from 'lodash';
import { usePrevious } from 'hooks';
import { useTimeRangeState, useTimeRangeDispatch, TIME_RANGE_ACTIONS, checkIsManualRange,
    TIME_RANGER_MAP } from 'context/TimeRangeProvider';

export {
    TIME_RANGER_MAP
}

const useTimeFilter = (inititalSelectedValue) => {
    const [loading, setLoading] = useState(true);

    const {selected, endTime, startTime} = useTimeRangeState();
    const dispatch = useTimeRangeDispatch();
    
    const refreshTimes = () => {
        setLoading(true);
        dispatch({type: TIME_RANGE_ACTIONS.TIME_RANGE_REFRESH})
    };
    const selectTime = (selected, startTime, endTime) =>
        dispatch({type: TIME_RANGE_ACTIONS.TIME_RANGE_SET, payload: {selected, startTime, endTime}});

    const doRefresh = useCallback(refreshTimes, [dispatch]);
    const doSelect = useCallback(selectTime, [dispatch]);
    
    const prevStartTime = usePrevious(startTime);
    const prevEndTime = usePrevious(endTime);

    const inititalValueSet = useRef(isEmpty(inititalSelectedValue));
    const {selected: inititalSelected, startTime: inititalStartTime, endTime: inititalEndTime} = inititalSelectedValue || {};

    useEffect(() => {
        if (!inititalValueSet.current) {
            doSelect(inititalSelected, inititalStartTime, inititalEndTime);

            inititalValueSet.current = true;

            return;
        }

        if (!startTime && !endTime) {
            doRefresh();

            return;
        }

        if (loading && (startTime !== prevStartTime || endTime !== prevEndTime || checkIsManualRange(selected))) {
            setLoading(false);
        }

    }, [loading, startTime, endTime, prevStartTime, prevEndTime, selected,
        doRefresh, doSelect, inititalSelected, inititalStartTime, inititalEndTime]);

    return [{selected, endTime, startTime, loading}, refreshTimes, selectTime];
};

export default useTimeFilter;