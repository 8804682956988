import moment from 'moment';
import { create } from './utils';

export const TIME_RANGER_MAP = {
    "5MIN": {
        value: "5MIN",
        label: "Last 5 minutes",
        graphDateFormat: "h:mm:ss",
        calc: () => ({endTime: moment().toISOString(), startTime: moment().subtract(5, 'minutes').toISOString()})
    },
    "HOUR": {
        value: "HOUR",
        label: "Last hour",
        graphDateFormat: "h:mm a",
        calc: () => ({endTime: moment().toISOString(), startTime: moment().subtract(1, 'hours').toISOString()})
    },
    "DAY": {
        value: "DAY",
        label: "Last day",
        graphDateFormat: "h:mm a",
        calc: () => ({endTime: moment().toISOString(), startTime: moment().subtract(1, 'days').toISOString()})
    },
    "WEEK": {
        value: "WEEK",
        label: "Last week",
        graphDateFormat: "DD.MM",
        calc: () => ({endTime: moment().toISOString(), startTime: moment().subtract(7, 'days').toISOString()})
    },
    "RANGE": {
        value: "RANGE",
        label: "Time range",
        graphDateFormat: "DD.MM",
        isManual: true
    }
}

export const checkIsManualRange = (value) => !!TIME_RANGER_MAP[value] && !!TIME_RANGER_MAP[value].isManual;

export const TIME_RANGE_ACTIONS = {
    TIME_RANGE_REFRESH: "TIME_RANGE_REFRESH",
    TIME_RANGE_SET: "TIME_RANGE_SET"
};

const reducer = (state, action) => {
    switch (action.type) {
        case TIME_RANGE_ACTIONS.TIME_RANGE_REFRESH: {
            const selectedRangeItem = TIME_RANGER_MAP[state.selected];
            const {startTime, endTime} = selectedRangeItem.isManual ? state : selectedRangeItem.calc();

            return {
                ...state,
                startTime,
                endTime
            };
        }
        case TIME_RANGE_ACTIONS.TIME_RANGE_SET: {
            let {selected, startTime, endTime} = action.payload;
            const selectedRangeItem = TIME_RANGER_MAP[selected];
            
            if (!selectedRangeItem.isManual) {
                const {startTime: startTimeCalculated, endTime: endTimeCalculated} = selectedRangeItem.calc();
                startTime = startTimeCalculated;
                endTime = endTimeCalculated;
            } else if (!endTime) {
                endTime = startTime;
            }

            return {
                ...state,
                selected,
                startTime,
                endTime
            };
        }
        default:
            return state;
    }
}

const [TimeRangeProvider, useTimeRangeState, useTimeRangeDispatch] = create(reducer, {
    selected: TIME_RANGER_MAP["5MIN"].value,
    startTime: null,
    endTime: null
});

export {
    TimeRangeProvider,
    useTimeRangeState,
    useTimeRangeDispatch
};