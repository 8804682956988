import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { isEmpty, cloneDeep, debounce } from 'lodash';
import { useField } from 'formik';
import DropdownButton from 'components/DropdownButton';
import Checkbox from 'components/Checkbox';
import Arrow from 'components/Arrow';
import ToggleButton from 'components/ToggleButton';
import { FieldLabel, FieldError } from 'components/Form';
import { usePrevious } from 'hooks';

import COLORS from 'utils/scss_variables.module.scss';

import './violations-filter-field.scss';

const Indicator = ({color=COLORS["color-main"], selected}) => (
    <div className="indicator-item" style={{backgroundColor: selected ? color : "transparent", borderColor: color}}></div>
);

const SelectIndicator = ({value, items}) => (
    <div className="select-indicator">
        <div className="indicator-container">
            {
                items.map(({value: itemKey, color}) => (
                    <Indicator key={itemKey} color={color} selected={isEmpty(value) || value.includes(itemKey)} />
                ))
            }
        </div>
        <Arrow name="bottom" className="open-menu-icon" small />
    </div>
);

const ViolationsFilterField = (props) => {
    const {className, label, items} = props;
    const violationActions = items.filter(({isViolation}) => !!isViolation).map(({value}) => value);
    const [field, meta, helpers] = useField(props);
    const {value} = field; 
    const {setValue} = helpers;
    const [isOpen, setIsOpen] = useState(false);
    const [violationsOnly, setViolationsOnly] = useState(false);
    const prevViolationsOnly = usePrevious(violationsOnly);
    const [fieldItems, setFieldItems] = useState(items);

    const fieldRef = useRef();
    const inititalLoaded = useRef(false);

    const handleClick = ({target}) => {
        if (fieldRef.current.contains(target)) {
            return;
        }

        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClick);
        } else {
            document.removeEventListener("mousedown", handleClick);
        }
    
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [isOpen]);

    useEffect(() => {
        if (!inititalLoaded.current) {
            inititalLoaded.current = true;

            return;
        }

        if (prevViolationsOnly === violationsOnly) {
            return;
        }

        let formattedItems = cloneDeep(items);

        if (violationsOnly) {
            formattedItems.map(item => {
                if (!violationActions.includes(item.value)) {
                    item.disabled = true;
                }

                return item;
            });
        } else {
            formattedItems = formattedItems.map(({value, label}) => ({value, label}));
        }

        setFieldItems(formattedItems);
        debounce(setValue, 150)(violationsOnly ? violationActions : []);

    }, [violationsOnly, prevViolationsOnly, setValue, items, violationActions]);

    const onItemClick = (event) => {
        const {checked, value: clickedValue} = event.target;
        let selectedItems = [...value];

        if (checked) {
            selectedItems.push(clickedValue);
        } else {
            selectedItems = selectedItems.filter(item => item !== clickedValue);
        }
        
        setValue(selectedItems);
    }

    return (
        <div ref={fieldRef} className={classnames("violations-filter-wrapper", {[className]: className})}>
            {!isEmpty(label) && <FieldLabel>{label}</FieldLabel>}
            <div className="selector-wrapper">
                <ToggleButton
                    className="vulnerabilities-ignore-toggle"
                    checked={violationsOnly}
                    onChange={() => setViolationsOnly(!violationsOnly)}
                />
                <DropdownButton
                    toggleButton={<SelectIndicator value={value} items={items} />}
                    isOpen={isOpen}
                    onToggle={() => setIsOpen(!isOpen)}
                    manualOpen
                >
                    <div className="multiselect-content-container">
                        {
                            fieldItems.map(item => (
                                <Checkbox
                                    key={item.value}
                                    name="multiselect-item"
                                    value={item.value}
                                    title={item.label}
                                    checked={value.includes(item.value)}
                                    onChange={onItemClick}
                                    disabled={item.disabled}
                                    small
                                />
                            ))
                        }
                        {fieldItems.length === 0 && "- no match found -"}
                    </div>
                </DropdownButton>
            </div>
            {meta.touched && meta.error && <FieldError>{meta.error}</FieldError>}
        </div>
    )
}

export default ViolationsFilterField;