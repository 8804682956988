import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ImageDetails from 'layout/CiCd/Images/ImageDetails';
import { RISKS_SCAN_PAGE_PATH } from '../utils';
import ClusterRiskAssessment from './ClusterRiskAssessment';

const Images = ({setHideTabs}) => (
    <Switch>
        <Route path={`${RISKS_SCAN_PAGE_PATH}/:clusterId/:imageId`} component={() => <ImageDetails backTitle="Cluster risks scan" setHideTabs={setHideTabs} />} />
        <Route path={`${RISKS_SCAN_PAGE_PATH}/:clusterId`} component={ClusterRiskAssessment} />
    </Switch>
)

export default Images;