import React from 'react';
import { useHistory } from 'react-router-dom';

import './link-to-rule.scss';

const LinkToRule = ({pathname, deleted, ruleName, id, isDefault=false, isPortshitRule=false}) => {
    const history = useHistory();

    if (deleted) {
        return (<div>{`Deleted: ${ruleName}`}</div>);
    }

    const handleLinkClick = event => {
        event.stopPropagation();
        event.preventDefault();

        history.push({pathname, query: {selectedRule: {isDefault, isPortshitRule, id}}});
    }

    return (
        <div className="link-to-rule" onClick={handleLinkClick}>{ruleName}</div>
    );
}

export default LinkToRule;