import React from 'react';
import Button from 'components/Button';
import { useAuthState } from 'context/AuthProvider';

import noData from './images/no-data.svg';

import './empty-data-notification-message.scss';

const EmptyDataNotificationMessage = ({message, mainTitle, onMainClick, subTitle, subHref, onSubClick}) => {
    const {isReadOnlyUser} = useAuthState();

    return (
        <div className="empty-data-notification-wrapper">
            <img src={noData} alt="No data"/> 
            <div className="empty-description">
                {message}
            </div>
            <div className="buttons-container">
                {!!subTitle && ((
                    !!subHref &&
                        <a href={subHref} target="_blank" rel="noopener noreferrer" className="empty-data-notification-sub-link">
                            {subTitle}
                        </a>
                ) || (
                    !!onSubClick &&
                        <Button className="empty-data-notification-sub-button" onClick={onSubClick} disabled={isReadOnlyUser}>
                            {subTitle}
                        </Button>
                ))}
                <Button className="empty-data-notification-main-button" onClick={onMainClick} disabled={isReadOnlyUser}>
                    {mainTitle}
                </Button>
            </div>
        </div>
    );
}

export default EmptyDataNotificationMessage;