import { useMemo } from "react";
import Table from "components/Table";
import { ICON_NAMES } from "components/Icon";
import IconWithTitle from "components/IconWithTitle";
import { isEmpty, partition } from "lodash";
import { CIS_TEST_STATUSES } from "utils/systemConsts";
import { StatusIcon } from '../utils';

const ActionNodesTable = ({ actionNodes, index, onChange }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Action Items",
        id: "actionItems",
        Cell: ({ row }) => {
          return (
            <div className="cluster-test-row">
              <StatusIcon status={row.original.status} />
              <div style={{marginLeft: "5px"}}>{`${row.original.ip}`}</div>
            </div>
          );
        },
        disableSortBy: true,
        minWidth: 250,
      },
    ],
    []
  );

  return (
    <>
      {
        <Table
          withMultiSelect
          data={actionNodes || []}
          columns={columns}
          hideRefresh
          hideColumnControl
          withPagination={false}
          rowSelectActionComponent={({ selectedIds }) => (
            <div>
              <IconWithTitle
                name={ICON_NAMES.SUCCESS_CIS}
                title={`Mark as "${CIS_TEST_STATUSES.PASS.label}"`}
                onClick={() =>
                  onChange(index, selectedIds, CIS_TEST_STATUSES.PASS.value)
                }
                disabled={isEmpty(selectedIds)}
              />
              <IconWithTitle
                name={ICON_NAMES.ALERT_CIS}
                title={`Mark as "${CIS_TEST_STATUSES.FAIL.label}"`}
                onClick={() =>
                  onChange(index, selectedIds, CIS_TEST_STATUSES.FAIL.value)
                }
                disabled={isEmpty(selectedIds)}
              />
            </div>
          )}
        />
      }
    </>
  );
};

const NodesSubComponent = ({ data, onChange }) => {
  const [readOnlyNodes, actionItemNodes] = partition(
    data?.nodes || [],
    (n) => n.status !== CIS_TEST_STATUSES.WARN.value
  );

  return (
    <div className="nodes-list">
      {readOnlyNodes?.length > 0 && (
        <div className="readonly-nodes-list">
          {readOnlyNodes.map((node) => (
            <div key={node.id} style={{display: "flex", alignItems: "center"}}>
              <StatusIcon status={node.status} />
              <div style={{marginLeft: "5px"}}>{node?.ip}</div>
            </div>
          ))}
        </div>
      )}
      {actionItemNodes?.length > 0 && (
        <ActionNodesTable
          index={data?.index}
          actionNodes={actionItemNodes}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default NodesSubComponent;
