export const FORWARD_EVENTS = {
    NOTIFICATION: "NOTIFICATION",
    ADMINISTRATIVE: "ADMINISTRATIVE",
    ALERT: "ALERT",
    VULNERABILITY: "VULNERABILITY"
};
export const VULNERABILITY_SEVERITY_FIELD = "vulnerabilitySeverity";

export const VENDORS = {
    SPLUNK: {
        value: "SplunkEventsForwardingDetails",
        label: "Splunk",
        key: "splunk",
        resetFields: [
            {name: "url", value: ""},
            {name: "token", value: ""},
            {name: "port", value: ""},
            {name: "isCloud", value: false}
        ],
        testConnectionFields: ["url", "token", "port", "isCloud"]
    },
    OPS_GENIE: {
        value: "OpsGenieEventsForwardingDetails",
        label: "Opsgenie",
        key: "opsgenie",
        resetFields: [
            {name: "token", value: ""}
        ],
        testConnectionFields: ["token"]
    },
    SLACK: {
        value: "SlackEventsForwardingDetails",
        label: "Slack",
        key: "slack",
        resetFields: [
            {name: "url", value: ""},
            {name: VULNERABILITY_SEVERITY_FIELD, value: ""},
            {name: FORWARD_EVENTS.VULNERABILITY, value: false}
        ],
        testConnectionFields: ["url"]
    },
    SUMO_LOGIC: {
        value: "SumoLogicEventsForwardingDetails",
        label: "Sumo Logic",
        key: "sumoLogic",
        resetFields: [
            {name: "url", value: ""},
            {name: VULNERABILITY_SEVERITY_FIELD, value: ""},
            {name: FORWARD_EVENTS.VULNERABILITY, value: false}
        ],
        testConnectionFields: ["url"]
    },
    WEBEX: {
        value: "WebexEventsForwardingDetails",
        label: "Cisco Webex",
        key: "webex",
        resetFields: [
            {name: "url", value: ""},
            {name: VULNERABILITY_SEVERITY_FIELD, value: ""},
            {name: FORWARD_EVENTS.VULNERABILITY, value: false}
        ],
        testConnectionFields: ["url"]
    },
    SECURE_X: {
        value: "SecureXEventsForwardingDetails",
        label: "SecureX",
        key: "securex",
        resetFields: [
            {name: "url", value: ""},
            {name: VULNERABILITY_SEVERITY_FIELD, value: ""}
        ],
        testConnectionFields: ["url"]
    },
    TEAMS: {
        value: "TeamsEventsForwardingDetails",
        label: "Microsoft Teams",
        key: "teams",
        resetFields: [
            {name: "url", value: ""},
            {name: VULNERABILITY_SEVERITY_FIELD, value: ""},
            {name: FORWARD_EVENTS.VULNERABILITY, value: false}
        ],
        testConnectionFields: ["url"]
    },
}
export const VENDOR_SLACK_VULNERABILITY = {value: "SlackVulnerabilityEventsForwardingDetails", label: VENDORS.SLACK.label};
export const VENDOR_SUMO_LOGIC_VULNERABILITY = {value: "SumoLogicVulnerabilityEventsForwardingDetails", label: VENDORS.SUMO_LOGIC.label};
export const VENDOR_WEBEX_VULNERABILITY = {value: "WebexVulnerabilityEventsForwardingDetails", label: VENDORS.WEBEX.label};
export const VENDOR_SECURE_X_VULNERABILITY = {value: "SecureXVulnerabilityEventsForwardingDetails", label: VENDORS.SECURE_X.label};
export const VENDOR_TEAMS_VULNERABILITY = {value: "TeamsVulnerabilityEventsForwardingDetails", label: VENDORS.TEAMS.label};

export const VULNERABILITY_VENDORS = [VENDORS.SLACK.value, VENDORS.SUMO_LOGIC.value, VENDORS.WEBEX.value, VENDORS.SECURE_X.value, VENDORS.TEAMS.value];

export const isAlertsRequired = (eventsForwardingDetailsType) => {
    return [VENDORS.SPLUNK.value, VENDORS.OPS_GENIE.value].includes(eventsForwardingDetailsType);
}