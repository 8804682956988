import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { RULE_ACTIONS } from 'utils/systemConsts';
import { ENDPOINT_TYPE_ITEMS, KAFKA_ENPOINT_TYPE, KUBERNETES_SERVICE_TYPE, API_ENPOINT_TYPE } from './utils';
import { ENVIRONMENT_TYPES_ITEMS, EXPANSION_TYPE_ITEMS, IP_TYPES_ITEMS, POD_TYPES_ITEMS, API_TYPES_ITEMS } from './RuleSubForms';
import { L7_PROTOCOLS } from './UserRuleForm';

const DENY_RULE_ACTIONS = [RULE_ACTIONS.DETECT, RULE_ACTIONS.BLOCK];

const ConnectionList = ({items, separator="and", prefix=null, suffix=null}) => (
    <React.Fragment>
        {prefix}
        {!!items && items.map((item, index, items) => (
                <React.Fragment key={index}>
                    <span style={{fontWeight: "bold"}}>{item}</span>
                    {index < items.length - 2 ? ", " : ""}
                    {index === items.length - 2 && ` ${separator} `}
                </React.Fragment>
        ))}
        {suffix}
    </React.Fragment>
);

const appendEnvironmentsIfAvaiable = (phrase, environments) => {
    if (isEmpty(environments)) {
        return phrase;
    }

    const envPhrase = ConnectionList({items: environments, separator: "or"});

    return <span>{phrase} on {envPhrase}</span>
}

const LabelsList = ({endpoint, type}) => {
    const labelsPrefix = (
        <span>
            <span style={{fontWeight: "bold"}}>{type}</span>
            {` with label${endpoint.labels.length > 1 ? "s" : ""} `}
        </span>
    );

    return ConnectionList({items: endpoint.labels.map(
        ({key, value}) => `${key} is ${value}`), prefix: labelsPrefix});
}

const VulnerabilityLevel = ({severity, isAllow}) => {
    if (!severity) {
        return "";
    }

    const vulnerabilityLevelKey = <span style={{fontWeight: "bold"}}>vulnerability level</span>;
    const vulnerabilityLevelValue = <span style={{fontWeight: "bold"}}>{severity}</span>;

    return <span>{` with `}{vulnerabilityLevelKey}{` ${isAllow ? "lower" : "higher"} than `}{vulnerabilityLevelValue}</span>;
}

const ApiPolicy = ({apiPolicy, isAllow}) => {
    if (!apiPolicy) {
        return "";
    }
    
    const apiPolicyValue = <span style={{fontWeight: "bold"}}>{apiPolicy}</span>;

    return <span>{` ${isAllow ? "complies" : "not compliant"} to API policy profile `}{apiPolicyValue}</span>;
}

const VulnerabilityLevelAndApiPolicy = ({severity, apiPolicy, action}) => {
    if (!severity && !apiPolicy) {
        return "";
    }

    const isAllow = action === RULE_ACTIONS.ALLOW;
    const velnerabilityText = VulnerabilityLevel({severity, isAllow});
    const policyText = ApiPolicy({apiPolicy, isAllow});

    if (!!severity && !apiPolicy) {
        return velnerabilityText;
    }

    if (!severity && !!apiPolicy) {
        return policyText;
    }

    return <span>{velnerabilityText}{` ${isAllow ? "and" : "or"}`}{policyText}</span>;
}

const getEndpointPhrase = ({endpoint, layer7Settings, isPrefix, action, internalCatalog, externalCatalog}) => {
    let phrase = "";
    
    switch (endpoint.connectionRulePartType) {
        case POD_TYPES_ITEMS.NAME.value:
            phrase = ConnectionList({
                items: endpoint.names.map(name => `${name} pod`),
                suffix: VulnerabilityLevelAndApiPolicy({severity: endpoint.vulnerabilitySeverityLevel, apiPolicy: endpoint.apiSecurityProfile, action})
            });
            phrase = appendEnvironmentsIfAvaiable(phrase, endpoint.environments);
            break;
        case POD_TYPES_ITEMS.LABEL.value:
            phrase = <span>
                        {LabelsList({endpoint, type: `${isPrefix ? "Pods" : "pods"}`})}
                        {!!endpoint.vulnerabilitySeverityLevel ? ` and ` : ""}
                        {VulnerabilityLevelAndApiPolicy({severity: endpoint.vulnerabilitySeverityLevel, apiPolicy: endpoint.apiSecurityProfile, action})}
                    </span>;
            phrase = appendEnvironmentsIfAvaiable(phrase, endpoint.environments);
            break;
        case POD_TYPES_ITEMS.ANY.value:
            const anyPodText = `${isPrefix ? "Any" : "any"} pod`;
            phrase = <span>
                        <span style={{fontWeight: "bold"}}>{anyPodText}</span>
                        {VulnerabilityLevelAndApiPolicy({severity: endpoint.vulnerabilitySeverityLevel, apiPolicy: endpoint.apiSecurityProfile, action})}
                    </span>;
            phrase = appendEnvironmentsIfAvaiable(phrase, endpoint.environments);
            break;
        case EXPANSION_TYPE_ITEMS.NAME.value:
            phrase = ConnectionList({
                items: endpoint.names.map(name => `${name} expansion`)
            });
            phrase = appendEnvironmentsIfAvaiable(phrase, endpoint.environments);
            break;
        case EXPANSION_TYPE_ITEMS.LABEL.value:
            phrase = LabelsList({endpoint, type: `${isPrefix ? "Expansions" : "expansions"}`});
            phrase = appendEnvironmentsIfAvaiable(phrase, endpoint.environments);
            break;
        case EXPANSION_TYPE_ITEMS.ANY.value:
            phrase = <span style={{fontWeight: "bold"}}>{`${isPrefix ? "Any" : "any"} expansion`}</span>
            phrase = appendEnvironmentsIfAvaiable(phrase, endpoint.environments);
            break;
        case ENVIRONMENT_TYPES_ITEMS.NAME.value:
            const envPrefix = `${isPrefix ? "Workloads" : "workloads"} that are deployed on `;
            phrase = ConnectionList({items: endpoint.environments, separator: "or", prefix: envPrefix});
            break;
        case ENVIRONMENT_TYPES_ITEMS.ANY.value:
            const anyEnvText = `${isPrefix ? "Workloads" : "workloads"} on `;
            phrase = <React.Fragment>{anyEnvText}<span style={{fontWeight: "bold"}}>any environment</span></React.Fragment>;
            break;
        case IP_TYPES_ITEMS.IP_RANGE.value:
            phrase = ConnectionList({items: endpoint.networks});
            break;
        case IP_TYPES_ITEMS.FQDN.value:
            phrase = ConnectionList({items: endpoint.fqdnAddresses});
            break;
        case ENDPOINT_TYPE_ITEMS.EXTERNAL.value:
            phrase = isPrefix ? "Connections from external environments" : "external IPs";
            break;
        case ENDPOINT_TYPE_ITEMS.ANY.value:
            phrase = <span style={{fontWeight: "bold"}}>{isPrefix ? "Any source" : "any destination"}</span>;
            break;
        case KAFKA_ENPOINT_TYPE.value:
            const {brokers} = endpoint;
            const {topics, actions} = layer7Settings || {};
            
            phrase = (
                <React.Fragment>
                    {ConnectionList({items: brokers, prefix: "Kafka brokers: ", suffix: " "})}
                    {!isEmpty(topics) && ConnectionList({items: topics, prefix: "on topics: ", suffix: " "})}
                    {!isEmpty(actions) && ConnectionList({items: actions, prefix: "with actions: "})}
                </React.Fragment>
            );
            break;
        case API_ENPOINT_TYPE.value:
            const {api: apiId, type} = endpoint;
            const catalogItems = type === API_TYPES_ITEMS.INTERNAL.value ? internalCatalog.items : externalCatalog.items;
            const apiItem = catalogItems.find(item => item.identifier === apiId);
            
            phrase = (
                <span>api service <span style={{fontWeight: "bold"}}>{!!apiItem ? apiItem.name : apiId}</span></span>
            );
            break;
        default:
            break;
    }

    return phrase;
}

const getLayer7HttpPhrase = (layer7Settings) => {
    const {layer7Protocol, methods, paths, isIntercept} = layer7Settings || {};

    if (layer7Protocol === L7_PROTOCOLS.HTTP) {
        return (
            <React.Fragment>
                {` with `}
                {isEmpty(paths) ? "all paths" : ConnectionList({items: paths, suffix: ` path${paths.length > 1 ? "s" : ""}`})}
                {` using `}
                {ConnectionList({items: methods, suffix: ` method${methods.length > 1 ? "s" : ""}`})}
                {isIntercept && <span>{`. Https traffic is `}<span style={{fontWeight: "bold"}}>intercepted</span>.</span>}
            </React.Fragment>
        )
    } else if (layer7Protocol === L7_PROTOCOLS.SPEC) {
        return (
            <React.Fragment>
                {ConnectionList({items: methods.map(({method, path}) => `${method} ${path}`), prefix: " using: ", suffix: " "})}
            </React.Fragment>
        )
    }

    return "";
}

const UserRuleContent = ({action, source, destination, layer7Settings, internalCatalog, externalCatalog}) => {
    if (action === RULE_ACTIONS.ENCRYPT || action === RULE_ACTIONS.ENCRYPT_DIRECT)  {
        const encryptedSourcePhrase = getEndpointPhrase({endpoint: source, layer7Settings, isPrefix: false, action});
        let destinationPhrase = null;

        if (destination.connectionRulePartType === KUBERNETES_SERVICE_TYPE.value) {
            const withServices = !isEmpty(destination.services);
            const withEnvs = !isEmpty(destination.environments);
            const servicesPrefix = withServices && destination.services.length > 1 ? "Kubernetes services " : "Kubernetes service ";
            const servicesPhrase = withServices ?
                ConnectionList({items: destination.services || [], separator: "and", prefix: servicesPrefix}) :
                <span style={{fontWeight: "bold"}}>any Kubernetes services</span>;
            destinationPhrase = (
                <React.Fragment>
                    {servicesPhrase}
                    {withEnvs && ` running on `}
                    {ConnectionList({items: destination.environments, separator: "or", prefix: ""})}
                </React.Fragment>
            )
        } else {
            destinationPhrase = getEndpointPhrase({endpoint: destination, layer7Settings, isPrefix: false, action, internalCatalog, externalCatalog});
        }

        return (
            <div>
                {`Connections from `}
                {encryptedSourcePhrase}
                {' to '}
                {destinationPhrase}
                <span className="relationship encrypted">{` are encrypted`}</span>
            </div>
        )
    }

    const allowed = !DENY_RULE_ACTIONS.includes(action);
    const blocked = action === RULE_ACTIONS.BLOCK;
    const relationship = allowed ? "can" : "can't";
    const sourcePhrase = getEndpointPhrase({endpoint: source, layer7Settings, isPrefix: true, action});
    const destinationPhrase = getEndpointPhrase({endpoint: destination, layer7Settings, isPrefix: false, action, internalCatalog, externalCatalog});
    const layer7HttpPhrase = getLayer7HttpPhrase(layer7Settings);

    return (
        <React.Fragment>
            {sourcePhrase}
            <span className={classnames("relationship", {allowed}, {blocked})}>{` ${relationship} communicate`}</span>
            {` with `}
            {destinationPhrase}
            {layer7HttpPhrase}
        </React.Fragment>
        
    );
};

export default UserRuleContent;