import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { useFetch } from 'hooks';
import { TextField, MultiselectCheckboxField, ClustersNamespacesFilterField } from 'components/Form';
import Table, { FilterFormWrapper } from 'components/Table';
import Loader from 'components/Loader';
import IconWithTitle from 'components/IconWithTitle';
import FormModal from 'components/FormModal';
import { ICON_NAMES } from 'components/Icon';
import IconWithTooltip from 'components/IconWithTooltip';
import ColorByRisk from 'components/ColorByRisk';
import ComplianceStatusIcon from 'layout/Apis/ComplianceStatusIcon';
import { useFilterDispatch, useFilterState, setFitlers, getFitlers, API_FILTER_KEYS } from 'layout/Apis/FilterContext';
import { API_TYPE_ITEMS } from "layout/Apis/utils";
import { nameIdToValueLabel } from 'utils/apiUtils';
import { WorkloadsDisplay } from './utils';
import DeleteApiModal from './DeleteApiModal';

import './api-catalog.scss';

export {
	ColorByRisk,
	ComplianceStatusIcon,
	WorkloadsDisplay
}

export const COMPLIANCE_WIDTH = 70;
export const RISK_WIDTH = 70;

export {
	API_FILTER_KEYS
}

export const RISK_SORT_KEY = "risk";

const ApiCatalogContent = ({policyItems, columns, url, apiNameTitle, newForm: NewForm, newTitle, type, clusterFilterItems}) => {
	const {path} = useRouteMatch();
	const history = useHistory();
	const {isReadOnlyUser} = useAuthState();

	const [showNewForm, setShowNewForm] = useState(false);
	const closeNewForm = () => setShowNewForm(false);

	const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
    const closeDeleteConfirmation = () => setDeleteConfirmationData(null);

	const [refreshTimestamp, setRefreshTimestamp] = useState(Date());
    const refreshTableData = () => setRefreshTimestamp(Date());

	const filters = getFitlers(useFilterState(), type);
	
    const filterDispatch = useFilterDispatch();
    const setFilters = (filters) => setFitlers(filterDispatch, {type, filters});

	const location = useLocation();
    const {filters: queryFilters} = location.query || {};
	
	const formattedFilters = {...filters, ...queryFilters};
	const {namespacesFilter} = formattedFilters;

	const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);
	
	return (
		<div className="api-catalog-page">
			<div className="table-header-container">
				{!!NewForm && <IconWithTitle
                    name={ICON_NAMES.ADD}
                    title={newTitle}
                    className="add-new-button"
                    onClick={() => setShowNewForm(true)}
                    disabled={isReadOnlyUser}
                />}
				<FilterFormWrapper
					initialValues={formattedFilters}
					onChange={updatedFilters => {
                        if (!mounted.current) {
                            return;
                        }
						
                        setFilters(updatedFilters);
                    }}
				>
					<TextField name={API_FILTER_KEYS.NAME} label={apiNameTitle} />
					<MultiselectCheckboxField
						name="apiPolicyProfiles"
						label="Policy compliance"
						items={policyItems}
					/>
					{!isNull(clusterFilterItems) &&
						<ClustersNamespacesFilterField
							name={API_FILTER_KEYS.NAMESPACES}
							label="Clusters & Namespaces"
							items={clusterFilterItems}
						/>
					}
				</FilterFormWrapper>
			</div>
			<Table
				url={url}
				columns={columns}
				filters={{...formattedFilters, namespacesFilter: btoa(JSON.stringify(namespacesFilter))}}
				isLoading={isNull(filters)}
				formatFetchedData={data => {
					const {items} = data || {};

					return items || [];
				}}
				onLineClick={({identifier}) => history.push(`${path}/${identifier}`)}
				refreshTimestamp={refreshTimestamp}
				defaultSortBy={[{id: RISK_SORT_KEY, desc: true}]}
				actionsComponent={({original}) => {
                    const {identifier} = original;
                    const deleteTooltipId = `${identifier}-delete`;
                 
                    return (
						<IconWithTooltip
							name={ICON_NAMES.DELETE}
							onClick={event => {
								event.preventDefault();
								event.stopPropagation();

								setDeleteConfirmationData(original);
							}}
							tooltipId={deleteTooltipId}
							tooltipText="Delete API"
							disabled={isReadOnlyUser}
						/>
					)
                }}
			/>
			{showNewForm &&
				<FormModal
					onClose={closeNewForm}
					formComponent={NewForm}
					formProps={{
						onFormSubmitSuccess: () => {
							closeNewForm();
							refreshTableData();
						}
					}}
				/>
			}
			{!isNull(deleteConfirmationData) &&
                <DeleteApiModal onClose={closeDeleteConfirmation} onDeleteSuccess={refreshTableData} data={deleteConfirmationData} />
            }
		</div>
	)
}

const ApiCatalog = ({columns, url, apiNameTitle, newForm, newTitle, type}) => {
	const [{loading, data, error}] = useFetch("apiSecurityPolicy");

	const [{loading: loadingClusters, data: clusters, error: errorLoadingClusters}] =
		useFetch("leanKubernetesClusters", {loadOnMount: type === API_TYPE_ITEMS.INTERNAL.value});
	

	if (loading || loadingClusters) {
		return <Loader />;
	}

	if (error || errorLoadingClusters) {
		return null;
	}
	
	const policyItems = data.map(({name, description}) => ({value: name, label: name, tooltip: description}));
	
	return (
		<ApiCatalogContent
			policyItems={policyItems}
			columns={columns}
			url={url}
			apiNameTitle={apiNameTitle}
			newForm={newForm}
			newTitle={newTitle}
			type={type}
			clusterFilterItems={!!clusters ? nameIdToValueLabel(clusters) : null}
		/>
	)
}

export default ApiCatalog;
