import React, { useEffect, useState, useRef } from 'react';
import { useAuthState } from 'context/AuthProvider';
import { useFetch, usePrevious } from 'hooks';
import DisplaySection from 'components/DisplaySection';
import Button from 'components/Button';
import { ICON_NAMES } from 'components/Icon';
import IconWithTitle from 'components/IconWithTitle';
import Loader from 'components/Loader';
import PodFormWithDisable, { formatSubmitParams, POD_TEMPLATE_SUBMIT_URL } from './PodFormWithDisable';

const DeploymentFormSection = ({initialData, refreshData}) => {
    const {isReadOnlyUser} = useAuthState();

    const [formEnabled, setFormEnabled] = useState(false);

    const [{values, disableSubmit}, setFormData] = useState({values: {}, disableSubmit: true});

    const [{loading, error}, submitFormData] = useFetch(POD_TEMPLATE_SUBMIT_URL, {loadOnMount: false});
	const prevLoading = usePrevious(loading);

    const onSave = () => {
        submitFormData(formatSubmitParams(values, true));
    }

    const mounted = useRef(true);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            return;
        }

        if (prevLoading && !loading && !error) {
            setFormEnabled(false);
            refreshData();
        }
    }, [prevLoading, loading, error, setFormEnabled, refreshData]);

    return (
        <DisplaySection title="Deployment">
            {loading ? <Loader /> :
                <React.Fragment>
                    <div className="deployment-form-buttons-wrapper">
                        {!formEnabled ? <IconWithTitle title="Edit" name={ICON_NAMES.EDIT} onClick={() => setFormEnabled(true)} disabled={isReadOnlyUser} /> :
                            <React.Fragment>
                                <Button tertiary className="cancel-button" onClick={() => setFormEnabled(false)}>Cancel</Button>
                                <Button icon={ICON_NAMES.SUCCESS} onClick={onSave} disabled={disableSubmit}>Save</Button>
                            </React.Fragment> 
                        }
                    </div>
                    <PodFormWithDisable
                        initialData={initialData}
                        formEnabled={formEnabled}
                        onChange={({values, disableSubmit}) => setFormData({values, disableSubmit})}
                        withImageLink
                    />
                </React.Fragment>
            }
        </DisplaySection>
    )
}

export default DeploymentFormSection;