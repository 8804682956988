import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import { useAuthState } from 'context/AuthProvider';
import { useDisplayConfigState } from 'context/DisplayConfigProvider';
import { useFetch, useDelete, usePrevious } from 'hooks';
import IconWithTitle from 'components/IconWithTitle';
import { ICON_NAMES } from 'components/Icon';
import FormModal from 'components/FormModal';
import { EmptyDataIcon, ScanPolicyLoader } from '../utils';
import CdScanPolicyForm from './CdScanPolicyForm';
import CdItemDisplay from './CdItemDisplay';

const scrollToFirstMarked = () => {
    const selectedPolicyItems = document.getElementsByClassName("unorderd-policy-item-display-container marked");

    if (!isEmpty(selectedPolicyItems)) {
        selectedPolicyItems[0].scrollIntoView({behavior: "smooth"});
    }
}

const CdPolicy = () => {
    const {showApiSecurity} = useDisplayConfigState();
    const prevShowApiSecurity = usePrevious(showApiSecurity);

    const {isReadOnlyUser} = useAuthState();

    const [selectedCdScan, setSelectedCdScan] = useState(null);
    const closeScanForm = () => setSelectedCdScan(null);

    const [{loading: deployersLoading, data: deployersData}] = useFetch("deployers");

    const [{loading, data}, fetchPolicies] = useFetch("cdPolicy");

    const [{deleting}, deletePolicy] = useDelete("cdPolicy");
    const prevDeleting = usePrevious(deleting);

    const [{loading: apiSecurityPolicyLoding, data: apiPolicyData}, fetchApiSecurityPolicies] = useFetch("apiSecurityPolicy", {loadOnMount: showApiSecurity});

    const location = useLocation();
    const queryParams = location.query || {};
    const {deploymentPolicyData} = queryParams;
    
    const urlSearchParams = new URLSearchParams(location.search);
    const selectedScanId = urlSearchParams.get("selectedScanId");

    const isLoading = loading || deleting || apiSecurityPolicyLoding || deployersLoading;

    useEffect(() => {
        if (!prevShowApiSecurity && showApiSecurity) {
            fetchApiSecurityPolicies();
        }
    }, [prevShowApiSecurity, showApiSecurity, fetchApiSecurityPolicies]);

    useEffect(() => {
        if (!deleting && prevDeleting) {
            fetchPolicies();
        }
    }, [deleting, prevDeleting, fetchPolicies]);

    useEffect(() => {
        if (!!deploymentPolicyData) {
            setSelectedCdScan(deploymentPolicyData);
        }
    }, [deploymentPolicyData, setSelectedCdScan]);

    useEffect(() => {
        if (isLoading || !selectedScanId) {
            return;
        }

        scrollToFirstMarked();
    }, [selectedScanId, isLoading]);

    if (isLoading) {
        return <ScanPolicyLoader />;
    }

    return (
        <div className="scan-policy-container">
            <IconWithTitle
                className="new-policy-scan-button"
                name={ICON_NAMES.ADD}
                title="New Deployment Scan"
                onClick={() => setSelectedCdScan({})}
                disabled={isReadOnlyUser}
            />
            <div className="policy-scans-wrapper">
                {isEmpty(data) ? <EmptyDataIcon title="No Deployment Scan Policy Data" /> :
                    data.map(item => (
                        <CdItemDisplay
                            key={item.id}
                            isMarked={item.id === selectedScanId}
                            data={item}
                            onEdit={() => setSelectedCdScan(item)}
                            onDelete={() => deletePolicy(item.id)}
                            apiPolicyData={apiPolicyData || []}
                            deployersData={deployersData}
                        />
                    ))
                }
            </div>
            {!isNull(selectedCdScan) &&
                <FormModal
                    onClose={closeScanForm}
                    formComponent={CdScanPolicyForm}
                    formProps={{
                        apiPolicyData: apiPolicyData || [],
                        initialData: selectedCdScan,
                        onFormSubmitSuccess: () => {
                            closeScanForm();
                            fetchPolicies();
                        }
                    }}
                />
            }
        </div>
    )
}

export default CdPolicy;