import React, { Component, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import Icon, { ICON_NAMES } from 'components/Icon';
import Arrow from 'components/Arrow';
import IconWithTitle from 'components/IconWithTitle';
import Tooltip from 'components/Tooltip';
import DropdownButton from 'components/DropdownButton';

const MAIN_ID = "droppable-main";
const OUTER_TYPE = "droppable-outer-type";
const INNER_TYPE = "droppable-inner-type";

class GroupName extends Component {
    state = {
        nameEdit: false
    }

    handleGroupNameChange = newName => {
        this.props.onGroupNameChange(newName);
        this.setState({nameEdit: false});
    }

    onKeyPress = ({target, key}) => {
        if (key === 'Enter') {
            this.handleGroupNameChange(target.value);
        }
    }

    onBlur = ({target}) => {
        this.handleGroupNameChange(target.value);
    }

    render() {
        const {nameEdit} = this.state;
        const {groupName, disabled} = this.props;

        if (!nameEdit || disabled) {
            return (
                <div className="read-only-name" onDoubleClick={() => this.setState({nameEdit: true})}>{groupName}</div>
            )
        }

        return (
            <input className="edit-name" defaultValue={groupName} onBlur={this.onBlur} onKeyPress={this.onKeyPress} />
        )
    }
}

const GroupActionsMenu = ({onGroupEnable, onGroupDisable, onDelete}) => (
    <DropdownButton toggleButton={<Icon name={ICON_NAMES.HOVER} />} className="group-actions-menu-container">
        <div><IconWithTitle name={ICON_NAMES.ENABLE} title="Enable all rules in group" onClick={onGroupEnable} /></div>
        <div><IconWithTitle name={ICON_NAMES.DISABLE} title="Disable all rules in group" onClick={onGroupDisable} /></div>
        <div><IconWithTitle name={ICON_NAMES.DELETE} title="Delete (all rules in group)" onClick={onDelete}/></div>
    </DropdownButton>
);

const GroupItem = (props) => {
    const {groupItems, groupName, disabled=false, itemComponent: ItemComponent, groupHandleProps,
        onGroupNameChange, onGroupEnable, onGroupDisable, onGroupDelete, policyTypeName, isGroupOpen,
        onGroupOpenToggle, toggleGroupOpenInternal, isActualGroup} = props;
    const rulesCount = groupItems.length;

    const [innnerIsGroupOpen, setInnnerIsGroupOpen] = useState(true);

    let formattedIsGroupOpen = isGroupOpen;
    let formattedSetIsGroupOpen = onGroupOpenToggle;
    if (toggleGroupOpenInternal) {
        formattedIsGroupOpen = innnerIsGroupOpen;
        formattedSetIsGroupOpen = () => setInnnerIsGroupOpen(!innnerIsGroupOpen);
    }
    
    return (
        <div className="policy-group-container">
            <Droppable droppableId={groupName} isDropDisabled={disabled} type={INNER_TYPE}>
                {(dropProvided, dropSnapshot) => (
                    <div className="policy-group-content-container" {...groupHandleProps}>
                        {isActualGroup &&
                            <div className="policy-group-header">
                                <Arrow
                                    name={formattedIsGroupOpen ? "top" : "bottom"}
                                    onClick={formattedSetIsGroupOpen}
                                    small
                                />
                                <div className="header-title">
                                    <GroupName groupName={groupName} onGroupNameChange={onGroupNameChange} disabled={disabled} />
                                </div>
                                <div className="header-content"><span>{rulesCount}</span>{` ${policyTypeName} Rules`}</div>
                                {!disabled &&
                                    <GroupActionsMenu
                                        onGroupEnable={onGroupEnable}
                                        onGroupDisable={onGroupDisable}
                                        onDelete={onGroupDelete}
                                    />}
                            </div>}
                        <div
                            ref={dropProvided.innerRef}
                            className={classnames(
                                "policy-group-content",
                                {"with-background": formattedIsGroupOpen && (dropSnapshot.isDraggingOver || isActualGroup)}
                            )}
                        >
                            {formattedIsGroupOpen && groupItems.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={disabled}>
                                    {(dragProvided, dragSnapshot) => (
                                        <div ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
                                            <div className="rule-container">
                                                <div data-tip data-for={item.id} className={classnames("group-reorder-icon-container", {"is-dragged": dragSnapshot.isDragging})} {...dragProvided.dragHandleProps}>
                                                    {!disabled &&
                                                        <React.Fragment>
                                                            <Icon className="group-reorder-icon" name={ICON_NAMES.HOVER} />
                                                            <Tooltip id={item.id} text="Drag to create a group" placement="top" />
                                                        </React.Fragment>}
                                                </div>
                                                <div className="rule-content-container">
                                                    <ItemComponent
                                                        {...item}
                                                        isDragged={dragSnapshot.isDragging}
                                                        inGroup={isActualGroup}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {dropProvided.placeholder}
                        </div>
                    </div>
                )}
            </Droppable>
        </div>
    )
}

export default class DrapAndDropGroupedList extends Component {
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    }

    moveDetweenGroups = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
    
        destClone.splice(droppableDestination.index, 0, removed);
    
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
    
        return result;
    };

    getGroupIndex = name => this.props.items.findIndex(item => item.groupName === name)

    getUpdatedSubItemsAtIndex = (items, index, updatedSubItems) => {
        return [
            ...items.slice(0, index),
            {...items[index], subItems: updatedSubItems, isActualGroup: !isEmpty(updatedSubItems)},
            ...items.slice(index + 1)
        ];
    }

    onDragEnd = ({source, destination, type}) => {
        if (!destination) {
            // dropped outside the list
            return;
        }

        const {items, onReorder} = this.props;
        
        if (type === OUTER_TYPE) {
            //reorder parent items:
            const updatedItems = this.reorder(items, source.index, destination.index);

            onReorder(updatedItems);

            return;
        }

        const sourceGroupIndex = this.getGroupIndex(source.droppableId);
        const sourceGroup = items[sourceGroupIndex];
        
        if (source.droppableId === destination.droppableId) {
            //reorder inside the same parent:
            
            const updatedSubItems = this.reorder(
                sourceGroup.subItems,
                source.index,
                destination.index
            );
            
            onReorder(this.getUpdatedSubItemsAtIndex(items, sourceGroupIndex, updatedSubItems));

            return;
        } 

        //reorder between different parents:
        const destinationGroupIndex = this.getGroupIndex(destination.droppableId);
        const destinationGroup = items[destinationGroupIndex];

        const sourceClone = Array.from(sourceGroup.subItems);
        const destClone = Array.from(destinationGroup.subItems);
        const [removed] = sourceClone.splice(source.index, 1);

        destClone.splice(destination.index, 0, removed);
        
        const withUpdatedSource = this.getUpdatedSubItemsAtIndex(items, sourceGroupIndex, sourceClone);

        onReorder(this.getUpdatedSubItemsAtIndex(withUpdatedSource, destinationGroupIndex, destClone));
    };

    render() {
        const {itemComponent, disabled, items, onGroupNameChange, onGroupStatusToggle,
            onGroupDelete, policyTypeName, namesOfCloseGroups, setNamesOfCloseGroups, toggleGroupOpenInternal=false} = this.props;
        
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId={MAIN_ID} isDropDisabled={disabled} type={OUTER_TYPE}>
                    {provided => (
                        <div ref={provided.innerRef}>
                            {
                                items.map(({groupName, subItems, isActualGroup}, groupIndex) => {
                                    const isGroupOpen = !toggleGroupOpenInternal && !namesOfCloseGroups.includes(groupName);
                                    
                                    return (
                                        <Draggable key={groupName} draggableId={groupName} index={groupIndex} isDragDisabled={disabled}>
                                            {provided => (
                                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                                    {subItems.length > 0 && 
                                                        <GroupItem
                                                            policyTypeName={policyTypeName}
                                                            groupItems={subItems}
                                                            groupName={groupName}
                                                            disabled={disabled}
                                                            itemComponent={itemComponent}
                                                            groupHandleProps={provided.dragHandleProps}
                                                            onGroupNameChange={newName => onGroupNameChange(groupName, newName)}
                                                            onGroupEnable={() => onGroupStatusToggle(groupName, true)}
                                                            onGroupDisable={() => onGroupStatusToggle(groupName, false)}
                                                            onGroupDelete={() => onGroupDelete(groupName)}
                                                            isGroupOpen={isGroupOpen}
                                                            onGroupOpenToggle={() => {
                                                                const updatedNames = isGroupOpen ? [...namesOfCloseGroups, groupName] :
                                                                    namesOfCloseGroups.filter(item => item !== groupName);
                                                                
                                                                setNamesOfCloseGroups(updatedNames);
                                                            }}
                                                            toggleGroupOpenInternal={toggleGroupOpenInternal}
                                                            isActualGroup={isActualGroup}
                                                        />}
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
  }