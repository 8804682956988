import React from 'react';
import { pickBy, isEmpty } from 'lodash';
import { useMountMultiFetch, FETCH_METHODS } from 'hooks';
import Wizard from 'components/Wizard';
import Loader from 'components/Loader';
import { nameIdToValueLabel, valueToValueLabel } from 'utils/apiUtils';
import FormScopeDefinition, { IGNORE_RISK_TYPES, getSubScobeFromType } from './FormScopeDefinition';
import FormIgnoreRisk from './FormIgnoreRisk';

export {
    IGNORE_RISK_TYPES
}

const RiskForm = ({initialData, onFormSubmitSuccess, onDirtyChanage}) => {
    const [{loading, data, error}] = useMountMultiFetch([
        {key: "clusters", url: "leanKubernetesClusters"},
        {key: "environments", url: "environments"}
    ]);

    if (loading) {
        return <Loader absolute={false} />
    }

    if (error) {
        return null;
    }

    const initialValues = {
        ignoredRiskType: IGNORE_RISK_TYPES.WorkloadIgnoredRisk,
        subScope: "",
        workloadNames: [],
        clusterId: null,
        namespaceId: null,
        environmentIds: [],
        clusterIds: [],
        workloadRisksType: [],
        ...initialData
    };

    initialValues.subScope = getSubScobeFromType(initialValues.ignoredRiskType);

    if (!isEmpty(initialValues.workloadNames)) {
        initialValues.workloadNames = valueToValueLabel(initialValues.workloadNames); //AsyncSelectField needs [{value,label}] initital value
    }

    const isEditForm = !!initialData && initialData.id;

    const wizardSteps = [
        {
            title: "Scope Definition",
            component: FormScopeDefinition
        },
        {
            title: "Ignore Risk",
            component: FormIgnoreRisk
        }
    ];

    const {clusters, environments} = data;
    
    return (
        <Wizard
            title="Ignore Risk"
            initialValues={initialValues}
            steps={wizardSteps}
            submitUrl="riskAssessment/ignoredRisks"
            formProps={{
                clusterItems: nameIdToValueLabel(clusters, item => !item.kubernetesSecurity),
                environmentItems: nameIdToValueLabel(environments)
            }}
            getSubmitParams={formValues => {
                const {id, ...formData} = formValues;
                const {subScope} = formData;

                formData.workloadNames = formData.workloadNames.map(item => item.value); //format AsyncSelectField value from [{value, label}] to [value]

                if (!!subScope) {
                    formData.ignoredRiskType = subScope;
                }
                delete formData.subScope;
                
                delete formData.clusterName;
                delete formData.namespaceName;

                const submitData = pickBy(formData, value => !isEmpty(value));

                return !isEditForm ? {submitData} : {
                    method: FETCH_METHODS.PUT,
                    formatUrl: url => `${url}/${id}`,
                    submitData
                }
            }}
            onSubmitSuccess={onFormSubmitSuccess}
            onDirtyChanage={onDirtyChanage}
        />
    );
}

export default RiskForm;
