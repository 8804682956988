import React, { useState } from 'react';
import classnames from 'classnames';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Icon, { ICON_NAMES } from 'components/Icon';
import { TextElement, InfoMessage, InnerTitle, LinksList, NotificationMessage, SectionSeparator } from './utils';

import './confirmation-wizard-modal.scss';

export {
    TextElement,
    InfoMessage,
    InnerTitle,
    LinksList,
    NotificationMessage,
    SectionSeparator
}

export const ConfirmationWizard = ({data, wizardSteps, title, description, stepProps, loading, onConfirm, confirmText="Confirm"}) => {
    const [activeStepId, setActiveStepId] = useState(wizardSteps[0].id);
    const activeStepIndex = wizardSteps.findIndex(({id}) => id === activeStepId);
    const {component: StepComponent, dataKey} = wizardSteps[activeStepIndex];
    const isLastStep = (wizardSteps.length - 1) === activeStepIndex;
    const hideWizardSteps = wizardSteps.length === 1;
    
    return (
        <div className="confirmation-wizard">
            {!hideWizardSteps &&
                <div className="wizard-steps-wrapper">
                    <div className="wizard-steps">
                        {wizardSteps.map(({id, title, stepNumber}, index) => {
                            const isActive = id === activeStepId;
                            const isDone = index < activeStepIndex;

                            return (
                                <div key={id} className={classnames("step-item", {active: isActive}, {done: isDone})} onClick={() => isDone ? setActiveStepId(id) : undefined}>
                                    <div className="step-icon">
                                        {isActive || isDone ? <Icon name={isActive ? ICON_NAMES.EDIT : ICON_NAMES.SUCCESS} /> : stepNumber}
                                    </div>
                                    <div className="step-title">{title}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            <div className={classnames("wizard-content", {"full-width": hideWizardSteps})}>
                <div className="wizard-step-wrapper">
                    <div className="wizard-title">{title}</div>
                    <TextElement className="wizard-description">{description}</TextElement>
                    {loading ? <Loader /> : <StepComponent stepData={!!dataKey ? data[dataKey] : null} stepProps={stepProps} />}
                </div>
                <Button className={classnames("btn-sub-border", "step-button", {submit: isLastStep})} onClick={() => {
                    if (isLastStep) {
                        onConfirm();

                        return;
                    }

                    setActiveStepId(wizardSteps[activeStepIndex + 1].id);
                }}>
                    {isLastStep ? confirmText : "Next"}
                </Button>
            </div>
        </div>
        
    )
}

const ConfirmationWizardModal = ({wizardSteps, modalData, className, ...props}) => (
    <Modal className={classnames("confirmation-modal", {[className]: !!className})} center={true} onClose={props.onClose}>
        <ConfirmationWizard {...props} data={modalData} wizardSteps={wizardSteps} />
    </Modal>
)

export default ConfirmationWizardModal;