import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import BackRouteButton from 'components/BackRouteButton';
import Text, { TEXT_TYPES } from 'components/Text';
import Loader from 'components/Loader';
import TabbedPageSection from 'components/TabbedPageSection';

import COLORS from 'utils/scss_variables.module.scss';

import './details-tabbed-page.scss';

const DetailsTabbedPage = (props) => {
    const {loading, error, id, title, subTitle, backTitle, tabItems, defaultSelectedTab, className,
        customHeaderComponent: CustomHeaderComponent, menual, onChange, withUrlRouting} = props;
    
    const {url} = useRouteMatch();

    if (error) {
        return null;
    }

    return (
        <div className={classnames("details-tabbed-page-wrapper", className)}>
            <BackRouteButton title={backTitle} path={url.replace(`/${id}`, "")} />
            {loading ? <Loader /> :
                <React.Fragment>
                    <Text type={TEXT_TYPES.TITLE_MEDIUM}>{title}</Text>
                    {!!subTitle && <div style={{color: COLORS["color-text-light"]}}><Text type={TEXT_TYPES.TABLE_BODY}>{subTitle}</Text></div>}
                    <div className="deatils-tabbed-page-tabs-wrapper">
                        {!!CustomHeaderComponent && <div className="deatils-tabbed-page-custom-header-wrapper"><CustomHeaderComponent /></div>}
                        <TabbedPageSection
                            items={tabItems}
                            defaultSelected={defaultSelectedTab}
                            menual={menual}
                            onChange={onChange}
                            withUrlRouting={withUrlRouting}
                        />
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

export default DetailsTabbedPage;