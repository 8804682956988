import React, { useState} from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useField } from 'formik';
import Calendar, { MODES } from 'components/Calendar';
import DropdownButton from 'components/DropdownButton';
import { FieldLabel, FieldError } from '../utils';

import './date-field.scss';

const DateField = (props) => {
    const {label, className, tooltipText, displayFormat="MMM Do", isFullWidth=false, placeholder="Date"} = props;
    const [field, meta, helpers] = useField(props);
    const {name, value} = field; 
    const {setValue} = helpers;

    const [calendarOpen, setCalendarOpen] = useState(false);
    const openCalendar = () => setCalendarOpen(true);
    const closeCalendar = () => setCalendarOpen(false);
    
    return (
        <div className={classnames("ps-field-wrapper", "ps-date-field-wrapper", {[className]: className}, {"full-width": isFullWidth})}>
            {!isEmpty(label) && <FieldLabel tooltipId={name} tooltipText={tooltipText}>{label}</FieldLabel>}
            <div className="selector-wrapper">
                <DropdownButton
                    toggleButton={(
                        <input
                            className="date-input"
                            type="text"
                            name={name}
                            value={!!value ? moment(value).format(displayFormat) : value}
                            readOnly
                            onClick={openCalendar}
                            placeholder={placeholder}
                        />
                    )}
                    isOpen={calendarOpen}
                    onToggle={() => setCalendarOpen(!calendarOpen)}
                    manualOpen
                >
                    <Calendar
                        mode={MODES.SINGLE}
                        numberOfMonths={1}
                        singleTime={value}
                        fromDate={new Date()}
                        onChange={selected => {
                            setValue(selected);
                            closeCalendar();
                        }}
                    />
                </DropdownButton>
            </div>
            {meta.touched && meta.error && <FieldError>{meta.error}</FieldError>}
        </div>
    )
}

export default DateField;