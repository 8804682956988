import React from 'react';
import { TextElement, InnerTitle, LinksList } from 'components/DeleteWizardModal';
import { DEPLOYMNET_DEPLOYERS_PAGE_PATH } from 'layout/Deployments/Deployers';

const DeployersStep = ({stepData: deployers}) => (
    <div className="delete-cluster-expansions">
        <React.Fragment>
            <InnerTitle>Deployers</InnerTitle>
            <TextElement>The following deployers will be deleted. If there are CD policies assigned to them, they will be deleted as well.</TextElement>
            <LinksList
                pathname={DEPLOYMNET_DEPLOYERS_PAGE_PATH}
                items={deployers.map(({name}) => ({name, searchParams: {name}}))}
            />
        </React.Fragment>
    </div>
);

export default DeployersStep;