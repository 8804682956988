import React from 'react';
import ToggleButton from 'components/ToggleButton';

import './ignore-toggle.scss';

const IgnoreToggle = ({isIgnored, onToggle, ignoreText="Ignored only", isDark=false}) => (
    <ToggleButton
        className="ignore-toggle"
        title={ignoreText}
        secondaryTitle="Active only"
        checked={isIgnored}
        onChange={() => onToggle(!isIgnored)}
        isDark={isDark}
    />
);

export default IgnoreToggle;