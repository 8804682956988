import React, { useEffect } from 'react';
import { get } from 'lodash';
import { usePrevious } from 'hooks';
import { validators, ListField, RadioField, useFormikContext, TextField } from 'components/Form';
import { ENDPOINT_TYPE_FIELD_NAME } from './utils';

export const IP_TYPES_ITEMS = {
    IP_RANGE: {
        value: "IpRangeConnectionRulePart",
        label: "By IP Range"
    },
    FQDN: {
        value: "FqdnConnectionRulePart",
        label: "By Domain Name"
    }
}

const validateInterceptFqdr = value => {
    const errorMessage = "Invalid domain name";

    if (!value) {
        return errorMessage;
    }

    const startsWithWildcard = value.startsWith("*");
    const FQDNPattern = /^[0-9a-zA-z-_.]+$/ig;

    if (startsWithWildcard) {
        value = value.substring(1); //remove wildcard
    }

    return !!value && FQDNPattern.test(value) ? null : errorMessage;
}

const validatePort = value => (value >= 0 && value <= 65535) ? null : "Invalid port";

const cidrValidatorWithPort = value => {
    const errorMessage = "Invalid CIDR pattern";

    if (!value) {
        return errorMessage;
    }

    const valueArray = value.split(":");

    if (valueArray.length > 2 || !valueArray[0] || (valueArray.length === 2 && !valueArray[1])) {
        return errorMessage;
    }

    const cidrError = validators.cidrValidator(valueArray[0]);
    const portError = !valueArray[1] ? null : validatePort(valueArray[1]);

    return (!!cidrError || portError) ? errorMessage : null;
}

const fqdnValidatorWithPort = value => {
    const errorMessage = "Invalid domain name";

    if (!value) {
        return errorMessage;
    }

    const valueArray = value.split(":");

    if (valueArray.length > 2 || !valueArray[0] || (valueArray.length === 2 && !valueArray[1])) {
        return errorMessage;
    }

    const cidrError = validators.fqdnValidator(valueArray[0]);
    const portError = !valueArray[1] ? null : validatePort(valueArray[1]);

    return (!!cidrError || portError) ? errorMessage : null;
}

const IpOrDomainForm = ({ endpoint, isReadOnlyUser }) => {
    const { values, setFieldValue } = useFormikContext();
    const { isIntercept } = values.layer7Settings;

    const typeFieldName = `${endpoint}.${ENDPOINT_TYPE_FIELD_NAME}`;
    const typeFieldValue = get(values, typeFieldName);
    const prevTypeFieldValue = usePrevious(typeFieldValue);

    useEffect(() => {
        if (typeFieldValue === "") {
            setFieldValue(typeFieldName, isIntercept ? IP_TYPES_ITEMS.FQDN.value : IP_TYPES_ITEMS.IP_RANGE.value);
        }

        if (typeFieldValue === IP_TYPES_ITEMS.IP_RANGE.value && isIntercept) {
            setFieldValue(typeFieldName, IP_TYPES_ITEMS.FQDN.value);
        }

        if (prevTypeFieldValue === typeFieldValue || !prevTypeFieldValue) {
            return;
        }

        setFieldValue(`${endpoint}.networks`, [""]);
        setFieldValue(`${endpoint}.fqdnAddresses`, [""]);
    }, [typeFieldValue, prevTypeFieldValue, typeFieldName, setFieldValue, endpoint, isIntercept]);

    return (
        <React.Fragment>
            <RadioField
                name={typeFieldName}
                horizontal={true}
                items={isIntercept ? [IP_TYPES_ITEMS.FQDN] : Object.values(IP_TYPES_ITEMS)}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {typeFieldValue === IP_TYPES_ITEMS.IP_RANGE.value &&
                <ListField
                    name={`${endpoint}.networks`}
                    fieldComponent={TextField}
                    fieldProps={{
                        validate: cidrValidatorWithPort,
                        placeholder: "100.101.102.103/24 OR 100.101.102.103/24:8080"
                    }}
                    disabled={isReadOnlyUser}
                />}
            {typeFieldValue === IP_TYPES_ITEMS.FQDN.value &&
                <ListField
                    name={`${endpoint}.fqdnAddresses`}
                    fieldComponent={TextField}
                    fieldProps={{
                        validate: isIntercept ? validateInterceptFqdr : fqdnValidatorWithPort,
                        placeholder: "www.mydomain.com OR www.mydomain.com:8080"
                    }}
                    disabled={isReadOnlyUser}
                />}
        </React.Fragment>
    );
}

export default IpOrDomainForm;