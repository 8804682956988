import React from 'react';
import { useFetch } from 'hooks';
import DisplaySection from 'components/DisplaySection';
import DoublePaneDisplay from 'components/DoublePaneDisplay';
import Loader from 'components/Loader';
import DiffsDisplay from './DiffsDisplay';
import DeploymentFormSection from './DeploymentFormSection';
import PodFormWithDisable from './PodFormWithDisable';

import './tab-pod-template.scss';

const TabPodTemplate = ({telemetryId}) => {
    const [{loading, error, data}, fetchData] = useFetch(`appTelemetries/${telemetryId}`);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return null;
    }

    const {pod, podRuntimeInfo, cluster, podIdentification} = data
    const {diffs} = podIdentification.diffSection;
    const {id: clusterId} = cluster;
    
    pod.clusterId = clusterId;
    podRuntimeInfo.clusterId = clusterId;
    podRuntimeInfo.kind = pod.kind;
    
    return (
        <DoublePaneDisplay
            className="workload-pod-template-tab-wrapper"
            leftPane={() => (
                <DeploymentFormSection initialData={{...pod}} refreshData={fetchData} />
            )}
            rightPane={() => (
                <React.Fragment>
                    <DisplaySection title="Runtime">
                        <PodFormWithDisable initialData={{...podRuntimeInfo}} />
                    </DisplaySection>
                    <DiffsDisplay diffs={diffs} />
                </React.Fragment>
            )}
        />
    )
}

export default TabPodTemplate;