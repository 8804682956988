import React from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import UnprotectedPodReasonData from "./UnprotectedPodReasonData";

import './unprotected-pod-reason.scss';

const UnprotectedPodDataModal = ({onClose, unprotectedPodReasons, podName, namespace}) => {
    return (
        <Modal className="unprotected-pod-modal" center={true} onClose={onClose}>
            <UnprotectedPodReasonData unprotectedPodReasons={unprotectedPodReasons} podName={podName} namespace={namespace}/>
            <Button className="button" onClick={onClose}>Done</Button>
        </Modal>
    );
}

export default UnprotectedPodDataModal;
                    
