import React, { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useFetch, usePrevious } from 'hooks';
import { MultiselectField, SelectField, validators, useFormikContext, FieldLabel, FieldDescription } from 'components/Form';
import Loader from 'components/Loader';
import { valueToValueLabel } from 'utils/apiUtils';

const KubernetesServiceForm = ({endpoint, clusterNames, environments, isReadOnlyUser}) => {
    const {values, setFieldValue} = useFormikContext();

    const clusterIdFieldName = `${endpoint}.clusterId`;
    const clusterId = get(values, clusterIdFieldName);

    const [{loading, data: services}, fetchClusterServices] = useFetch("kubernetesClusters", {loadOnMount: false});
    const previousClusterId = usePrevious(clusterId);

    useEffect(() => {
        if (clusterId === previousClusterId) {
            return;
        }

        if (!!clusterId) {
            fetchClusterServices({
                queryParams: {showIstioOnly: true},
                formatUrl: url => `${url}/${clusterId}/services`
            });
        }

        if (!!previousClusterId) {
            setFieldValue(`${endpoint}.services`, []);
            setFieldValue(`${endpoint}.environments`, []);
        }
    }, [clusterId, previousClusterId, fetchClusterServices, setFieldValue, endpoint]);
    
    const clusterEnvs = environments.filter(({kubernetesEnvironments}) => {
        return !isEmpty(kubernetesEnvironments) && 
            !!kubernetesEnvironments.find(kubernetesEnv => kubernetesEnv.kubernetesCluster === clusterId);
    });
    
    const clusterEnvItems = valueToValueLabel([...new Set(clusterEnvs.map(item => item.name))]);
    
    const serviceItems = !services ? [] : valueToValueLabel([...new Set(services.map(item => item.name))])

    return (
        <React.Fragment>
            <SelectField 
                name={clusterIdFieldName}
                label="Cluster"
                items={clusterNames || []}
                validate={validators.validateRequired}
                disabled={isReadOnlyUser}
            />
            {!!clusterId && loading && <div><Loader absolute={false} /></div>}
            <div style={{display: !!clusterId && !loading ? "block" : "none"}}>
                <FieldLabel>Services</FieldLabel>
                <FieldDescription>
                    If no service was selected rule will apply on all services
                </FieldDescription>
                <MultiselectField
                    name={`${endpoint}.services`}
                    items={serviceItems}
                    disabled={isReadOnlyUser}
                />
                <FieldLabel>Runtime Environments</FieldLabel>
                <FieldDescription>
                    If no environment was selected rule will apply on all environments
                </FieldDescription>
                <MultiselectField
                    name={`${endpoint}.environments`}
                    items={clusterEnvItems}
                    disabled={isReadOnlyUser}
                />
            </div>
        </React.Fragment>
    );
}

export default KubernetesServiceForm;